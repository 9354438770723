import React from 'react'
import LabeledInput from '../elements/LabeledInput';
import { AutocompleteInput } from '../elements/AutocompleteInput';
import MaskedLabeledInput from '../elements/MaskedLabeledInput';

export const ManualAddressFields = (props) =>{
    const { values, filteredErrors, handleBlur, ...inputHandlers } = props;
    return (
        <div onBlur={handleBlur}>
            <LabeledInput type="text" style={{ marginTop: '1rem' }} name="lineOne" label="Home Address 1" value={values.lineOne} error={filteredErrors.lineOne} {...inputHandlers} />
            <LabeledInput type="text" name="additionalHomeInfo" label="Home Address 2 (Optional – Apt., Suite, Bldg.)" value={values.additionalHomeInfo} error={filteredErrors.additionalHomeInfo} {...inputHandlers} />
            <LabeledInput type="text" name="city" label="City" value={values.city} error={filteredErrors.city} {...inputHandlers} />
            <AutocompleteInput type="text" name="state" label="State" value={values.state} error={filteredErrors.state} {...inputHandlers} />
            <MaskedLabeledInput mask="99999" maskChar="" type="text" name="zipCode" label="Zip Code" value={values.zipCode} error={filteredErrors.zipCode} {...inputHandlers} />
        </div>
    )
}
