import React from 'react';
import moment from 'moment';
import CardWithTitle from '../../elements/CardWithTitle';
import InfoText from '../../elements/InfoText';
import { removeLeadingZerosForSingleDigitDate, formatPhoneNumber } from '../../common/Helpers';
import { colors } from '../../../styles/common';
import { AdminNotificationTypes } from '../../notifications/AdminNotifications';

const marginBottom = { marginBottom: '1rem' };

const styles = {
    isEmailVerified: { wordBreak: 'break-word', color: colors.darkRed, fontWeight: 500, ...marginBottom }
}

const NonMemberPersonalDetails = ({ searchResult, notifications }) => {
    const dob = searchResult?.dob ? removeLeadingZerosForSingleDigitDate(moment(searchResult.dob).format('MM/DD/YYYY')) : '';
    const isEmailVerified = notifications.find(notification => notification.notificationType === AdminNotificationTypes.VERIFY_EMAIL_ADDRESS)
    return (
        <CardWithTitle firstGrow={true} title='Login & Personal Details'>
            <InfoText>{searchResult.displayName}</InfoText>
            <>
                {isEmailVerified &&
                    <InfoText style={ styles.isEmailVerified }>
                        { `${isEmailVerified?.callToAction?.email} (unconfirmed)` }
                    </InfoText>
                }
                {!isEmailVerified &&
                    <InfoText style={{ wordBreak: 'break-all', ...marginBottom }}>
                        { searchResult.email }
                    </InfoText>
                }
            </>
            {searchResult?.address &&
                <>
                    <InfoText>{searchResult?.address?.lineOne}</InfoText>
                    <InfoText>{searchResult?.address?.lineTwo || ''}</InfoText>
                    <InfoText>{(searchResult?.address?.city || '') + ', ' + (searchResult?.address?.state || '') + ' ' + searchResult?.address?.zipCode}</InfoText>
                </>
            }
            <InfoText style={marginBottom}>{formatPhoneNumber(searchResult?.phone)}</InfoText>
            {searchResult?.dob &&
                <InfoText>Date of Birth: {dob}</InfoText>
            }
        </CardWithTitle>
    )
};

export default NonMemberPersonalDetails;