import { Component } from "react";
import { ContentfulService } from "../configuration/ApplicationContext";
import { capitalizeFirstLetter } from "./common/Helpers";
import StringPlaceholderService from "../services/StringPlaceholderService";

export default class UpgradeSuccess extends Component {

    async loadContent(contentfulId) {
        const response = await ContentfulService.getEntry(contentfulId);
        this.setState({ content: response.fields });
        this.loadDynamicContent(response.fields);
    }

    loadDynamicContent(content) {
        const { productType } = this.context.userDetails.subscription || {};
        const properties = {
            productType: capitalizeFirstLetter(productType),
        }
        content.description.content.forEach(ct => {
            ct.content.forEach(pr => {
                if (pr.value) {
                    pr.value = StringPlaceholderService.replace(pr.value, properties)
                }
            })
        });
        content.subDescription.content.forEach(subDescriptionContent => {
            subDescriptionContent.content.forEach(ct => {
                if (ct.value) {
                    ct.value = StringPlaceholderService.replace(ct.value, properties)
                }
            })
        });
        this.setState({
            description: content.description,
            subDescription: content.subDescription

        });
    }

}