import React, { Component } from 'react';
import StringPlaceholderService from '../../services/StringPlaceholderService';
import Card from '../elements/Card';
import ResponsiveSignupFlowLayout, { SignUpFlowExtraContent, SignUpFlowFormContent } from '../layout/ResponsiveSignupFlowLayout';
import Title from '../elements/Title';
import RichText from '../elements/RichText';
import Content from '../layout/Content';
import Spinner from '../elements/Spinner';
import ContentfulIds from '../../configuration/ContentfulIds';
import { capitalizeFirstLetter } from '../common/Helpers';
import { ContentfulService } from '../../configuration/ApplicationContext';
import AdminSummary from './summary/AdminSummary';
import AdminContext from '../contexts/AdminContext';
import Link from '../elements/Link';
import Breadcrumbs from '../common/Breadcrumbs';

export default class AdminProductUpgradeSuccessPage extends Component {
    static contextType = AdminContext;

    async componentDidMount() {
        await this.props.notificationContext.getNotifications();
        await this.context.getMemberDetails();
        this.loadContent();
        this.setBreadCrumb();
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.Admin.MemberAccountIds.UpgradeSuccessfulPage);
        this.setState({ content: response.fields });
    }

    setBreadCrumb = () => {
        const { personalInformation } = this.context.memberDetails || {};
        const breadCrumbRoutes = [
            {
                to: '/admin/member-account', label: personalInformation.displayName
            },
            {
                to: '#', label: 'Upgrade'
            },
            {
                to: '#', label: 'Payment'
            },
            {
                to: '/admin/product/upgrade/success', label: 'Upgrade Successful'
            }
        ];
        this.setState({ breadCrumbRoutes });
    }


    render() {
        const { subscription, personalInformation } = this.context.memberDetails || {};
        const { content, breadCrumbRoutes } = this.state || {};
        const { product } = this.context || {};
        if (!content || !subscription) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{marginTop: '0rem'}}>
                <Breadcrumbs isCustomerFacing={false} breadCrumbRoutes={breadCrumbRoutes}></Breadcrumbs>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title>{content.title}</Title>
                        <RichText text={content.description} />
                        <Link style={{ marginTop: '2rem' }} text={`Back to ${personalInformation?.displayName}'s Details`} to= '/admin/member-account' />
                        <Link style={{ marginTop: '2rem' }} text={`Back to Dashboard`} onClick={() => this.props.history.push(`/admin/search?login-id=${this.context.getLoginId()}`)} />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        <Card>
                            <AdminSummary flags={{showPaidInvoiceAmount: true}} isPaidTierUpgrade={true} product={product} title={StringPlaceholderService.replace(content.linkText, { productType: capitalizeFirstLetter(product.name) })} subscription={subscription} isProductUpgraded={true} />
                        </Card>
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

