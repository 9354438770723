import React from 'react';
import { AdminApi, ContentfulService } from '../../../configuration/ApplicationContext';
import ContentfulIds from '../../../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, {
    SignUpFlowFormContent,
    SignUpFlowExtraContent
} from '../../layout/ResponsiveSignupFlowLayout';
import Content from '../../layout/Content';
import Title from '../../elements/Title';
import Summary from '../../summary/Summary';
import Spinner from '../../elements/Spinner';
import Card from '../../elements/Card';
import AdminContext from '../../contexts/AdminContext';
import { capitalizeFirstLetter } from '../../common/Helpers';
import withPageTitle from "../../layout/withPageTitle";
import Breadcrumbs from '../../common/Breadcrumbs';
import { createBundleSummaryItems } from '../../summary/BundleSummaryItems';
import InfoText from '../../elements/InfoText';
import AdminBaseBundle from './AdminBaseBundle';
import AdminCheckoutForm from '../forms/AdminCheckoutForm';
import Notification from '../../notifications/Notification';
import { colors } from '../../../styles/common';

declare var Z: any; //Zuora

const contentStyle = { marginTop: '0px' };
const titleStyle = { marginTop: '1rem' };

const notificationStyle = {
    marginBottom: '1rem',
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '0px',
};
const cardStyle = {
    background: colors.snow
}
class AdminBundlePaymentPage extends AdminBaseBundle {
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            zuoraConfiguration: null,
            billingAddressSameAsHomeAddress: true,
            personalInfoPageURL: '/admin/bundle/personal-info',
            chooseProductPageURL: `/admin/bundle/${this.props.match.params.subscriptionApplicationId}/choose-plan`,
            paymentPageURL: `/admin/bundle/${this.props.match.params.subscriptionApplicationId}/checkout/${this.props.match.params.id}/payment`
        };
    }

    componentDidMount() {
        if (this.context.aaaLoginIdentifier === undefined) {
            this.validateAddressAndExistingAccountAndRedirect();
        }
        this.setBreadCrumb();
        this.loadZuoraForm();
        this.loadContent();
        this.loadPersonalInformation();
        this.loadProductsAndSelectProduct();
    }
    setBreadCrumb = () => {
        const breadCrumbRoutes = [
            {
                to: `${this.state.personalInfoPageURL}`, label: 'New AAA Digital + IDC Signup 1: Personal Info'
            },
            {
                to: `${this.state.chooseProductPageURL}`, label: '2: Choose Plan'
            },
            {
                to: `${this.state.paymentPageURL}`, label: '3: Payment'
            }
        ]
        this.setState({ breadCrumbRoutes });
    }

    async loadZuoraForm() {
        const zuoraConfiguration = await AdminApi.get('/api/orders/payment-form');
        this.setState({ zuoraConfiguration })
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.Admin.Bundle.CheckoutPage);
        this.setState({ content: response.fields })
    }

    submit = async (data) => {
        const { id, lastModified, createdOn, ...billingAddress } = this.state.personalInfo?.billingAddress || {};
        if (Object.keys(data.errors).length > 0 || !data.values.billingAddress) {
            this.setState({
                checkoutError: data.errors.billingAddress,
                isSubmitDisabled: false
            });
            return;
        }
        this.setState({ checkoutError: "", isSubmitDisabled: true });

        window.orderSuccess = async (refId) => {
            this.setState({ isLoading: true });
            const ratePlanId = this.context.product.id;
            const aaaLoginIdentifier = this.context.aaaLoginIdentifier;
            const paymentMethodId = refId;
            const response = await AdminApi.post('/api/bundle/orders', { ratePlanId, paymentMethodId, billingAddress, subscriptionApplicationId: this.props.match.params?.subscriptionApplicationId, aaaLoginIdentifier });
            if (!response) {
                this.setState({ isSubmitDisabled: false, isLoading: false });
                return;
            }
            if (response.validationErrors) {
                const apiErrors = response.validationErrors.map(apiError => {
                    return apiError.message
                });
                this.setState({
                    checkoutError: apiErrors,
                    zuoraConfiguration: null,
                    isSubmitDisabled: false,
                    isLoading: false
                }, () => {
                    this.loadZuoraForm();
                });
            } else {
                const searchResult = JSON.parse(localStorage.getItem('searchResult'));
                searchResult.aaaLoginIdentifier = response.aaaLoginIdentifier;
                searchResult.userId = response.userId;
                searchResult.idcMembership = `${this.props.match.params.id}`;
                localStorage.setItem('searchResult', JSON.stringify(searchResult));
                this.props.history.push(`/admin/bundle/${this.props.match.params.id}/subscription-complete`);
            }
        };

        window.orderFailure = (failureMessage) => {
            this.setState({
                checkoutError: failureMessage,
                zuoraConfiguration: null,
                isSubmitDisabled: false,
                isLoading: false
            }, () => {
                this.loadZuoraForm();
            });
        };
        Z.submit();
    };

    render() {
        const { content, selectedProduct, checkoutError, personalInfo, billingAddressSameAsHomeAddress, isSubmitDisabled, zuoraConfiguration, breadCrumbRoutes, isLoading } = this.state;
        const { notifications } = this.props.notificationContext;
        if (!zuoraConfiguration || !selectedProduct || !content || isLoading) {
            return <Spinner />;
        }
        return (
            <Content style={contentStyle}>
                <InfoText style={notificationStyle}>
                    {notifications.map(notification => {
                        return <Notification key={notification.notificationType} {...notification} {...this.props} />
                    }
                    )}
                </InfoText>
                <Breadcrumbs breadCrumbRoutes={breadCrumbRoutes} isCustomerFacing={false}></Breadcrumbs>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title style={titleStyle}>{content.title}</Title>
                        <AdminCheckoutForm zuoraConfiguration={zuoraConfiguration} content={content} onSubmit={this.submit} checkoutError={checkoutError}
                            personalInfo={personalInfo} onSelectAddress={this.onSelectAddress} selectedProduct={selectedProduct} billingAddressSameAsHomeAddress={billingAddressSameAsHomeAddress}
                            isSubmitDisabled={isSubmitDisabled} showDeclineLink={false} />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        <Card style={cardStyle}>
                            <Summary product={selectedProduct} title={`Identity Champion ${capitalizeFirstLetter(selectedProduct.name)}`} items={createBundleSummaryItems(selectedProduct, this.context)}></Summary>
                        </Card>
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageTitle(AdminBundlePaymentPage, "Place Order");
