import React, { useState, useEffect, useContext } from 'react';
import { ContentfulService } from '../../../configuration/ApplicationContext';
import ContentfulIds from '../../../configuration/ContentfulIds';
import Content from '../../layout/Content';
import RichText from '../../elements/RichText';
import Title from '../../elements/Title';
import DynamicParagraphText from '../../elements/DynamicParagraphText';
import UnorderedListText from '../../elements/UnorderedListText';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import Link from '../../elements/Link';
import AdminContext from '../../contexts/AdminContext';
import Spinner from '../../elements/Spinner';
import InfoText from '../../elements/InfoText';
import Span from '../../elements/Span';
import Breadcrumbs from '../../common/Breadcrumbs';
import AdminNotificationContext from '../../contexts/AdminNotificationContext';

export const breadCrumbRoutes = [
    {
        to: '/admin/search/noresults', label: 'No results'
    }
]

const titleStyle = {
    fontSize: '32px',
    marginTop: 'unset'
}

const styles = {
    paragraph: { marginTop: '2rem' }
};
const nonMemberLinkStyle = { marginTop: '2rem', fontWeight: '500', textAlign: 'left' }

const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;

const UnorderedStyle = ({ children, ...props }) => <UnorderedListText {...props} text={children} />;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</ParagraphStyle>,
        [BLOCKS.UL_LIST]: (node, children) => <UnorderedStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</UnorderedStyle>,
        [INLINES.HYPERLINK]: (node, next) => {
            return <Link style={{ display: 'inline' }} to={node.data.uri} text={node.content[0].value} />;
        }
    },
};

const NoResults = (props) => {
    const context = useContext(AdminContext);
    const [content, setContent] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    const notificationContext = useContext(AdminNotificationContext);

    useEffect(() => {
        async function loadContent() {
            const response = await ContentfulService.getEntry(ContentfulIds.NoResultsPage);
            setContent(response.fields);
        }
        loadContent();
    }, []);

    useEffect(() => {
        const { search } = props.location;
        const params = new URLSearchParams(search);
        setSearchTerm(params.get('keyword'));
    }, [props.location, props.location.search]);

    useEffect(() => {
        localStorage.removeItem('searchResult');
        context.updateState({ searchResult: {} });
        notificationContext.clearNotifications();
    }, []);

    const processNonAAAMember = () => {
        localStorage.removeItem('searchResult');
        context.updateState({ searchResult: {} });
    }

    if (!content || context.isSearching) {
        return <Spinner />;
    }

    return (
        <Content
            style={{ marginTop: '1rem' }}>
            <Breadcrumbs
                isCustomerFacing={false}
                breadCrumbRoutes={breadCrumbRoutes}
            />
            <Title
                style={titleStyle}>
                {content.title}
            </Title>
            <InfoText>
                <>
                    Your search - " <Span
                        style={{ fontWeight: 500 }}
                        text={searchTerm}
                    />" - did not match any records in the AAA Digital database.
                </>
            </InfoText>
            <RichText
                text={content.description}
                options={options}
            />
            <DynamicParagraphText
                style={styles.paragraph}
                text={
                    <RichText
                        text={content.subDescription}
                        options={options}
                    />
                }
            />
            <Link
                style={nonMemberLinkStyle}
                to='/admin/personal-info-non-member'
                text={content.linkText}
                textalign="center"
                onClick={processNonAAAMember}
            />
            <DynamicParagraphText
                desktopFontSize='16px'
                mobileFontSize='14px'
                text={content.footerDescription}
            />
        </Content>
    );
}

export default NoResults;
