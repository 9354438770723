import React, { useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled, { css } from 'styled-components'
import { colors, desktop, desktopOnly, mobileOnly } from '../../styles/common';
import CarouselContent from './CarouselContent';

const Layout = styled.div`
    ${props => props.mobileOnly && css`
       ${mobileOnly};
     `}

     ${props => props.desktopOnly && css`
       ${desktopOnly}
     `};
     
     ${desktop`
        background: linear-gradient(to left, ${colors.darkBlue} 0%, ${colors.darkRed} 100%) left bottom no-repeat;
        background-size: 100% 10px;
    `}
}`

const FullWidthCarousel = styled(Carousel)`
    li.slide {
        max-height: 700px;
    }
`;

const MainCarousel = ({ sections, onSignup }) => {
    const [autoPlayValue, setAutoPlay] = useState(true);

    const handleAutoplay = index => {
        if (index === 0) { setAutoPlay(false) };
    }

    const configuration = {
        showThumbs: false,
        infiniteLoop: true,
        autoPlay: autoPlayValue,
        showIndicators: false,
        showStatus: false,
        stopOnHover: false,
        interval: 5000,
        showArrows: false
    };
    return (
        <>
            <Layout
                desktopOnly='true'>
                <FullWidthCarousel
                    {...configuration}
                    onChange={(index) => handleAutoplay(index)}>
                    {
                        sections.map((section, index) =>
                            <CarouselContent
                                key={index}
                                section={section}
                                onSignup={onSignup}
                            />
                        )
                    }
                </FullWidthCarousel>
            </Layout>
            <Layout
                mobileOnly='true'>
                <CarouselContent
                    section={sections[0]}
                    onSignup={onSignup}
                />
            </Layout>
        </>
    );
};

export default MainCarousel;