import React from 'react';
import { colors } from '../../styles/common';
import { UNKNOWN } from '../common/Constants';
import { formatDates, formatPhoneNumber, sentenceCase, convertFirstLetterToUpperCase } from '../common/Helpers';
import CardLineItem from '../elements/CardLineItem';
import CardWithTitle from '../elements/CardWithTitle';

const ExperianCyberAgentPayDayLoanAlert = ({ subscriberName, data }) => {
    const payLoadLoanAndInquirydata = data.details.payLoadLoanAndInquiryDetails;
    const styles = {
        key: { width: '30%', fontWeight: 'bold', textAlign: 'right', marginRight: '2%' },
        value: { width: '68%' },
        cardLine: { marginBottom: 0 },
        unknown: { color: colors.darkRed, fontStyle: 'italic', width: '68%', fontWeight: 500 }
    };

    return (
        payLoadLoanAndInquirydata.map(data =>
            <CardWithTitle
                title={`Payday Loan Monitoring: New ${convertFirstLetterToUpperCase(data.type)}`}>
                <>
                    {
                        subscriberName &&
                        <CardLineItem
                            itemKey='Subscriber:'
                            itemValue={subscriberName}
                            itemKeyStyle={styles.key}
                            itemStyle={styles.value}
                            style={styles.cardLine}
                        />
                    }
                    {
                        data.dateFound &&
                        <>
                            <CardLineItem
                                itemKey='Date Found: '
                                itemValue={
                                    data.dateFound === UNKNOWN
                                        ? UNKNOWN
                                        : formatDates(data.dateFound, 'YYYYMMDD', 'MM/DD/YYYY')
                                }
                                itemKeyStyle={styles.key}
                                itemStyle={
                                    data.dateFound === UNKNOWN
                                        ? styles.unknown
                                        : styles.value
                                }
                                style={styles.cardLine}
                            />
                            <CardLineItem
                            />
                        </>
                    }
                    {
                        data.merchantName &&
                        <CardLineItem
                            itemKey='Merchant Name:'
                            itemValue={sentenceCase(data.merchantName)}
                            itemKeyStyle={styles.key}
                            itemStyle={
                                data.merchantName === UNKNOWN
                                    ? styles.unknown
                                    : styles.value
                            }
                            style={styles.cardLine}
                        />
                    }
                    {
                        data.merchantLocation &&
                        <CardLineItem
                            itemKey='Merchant Location:'
                            itemValue={sentenceCase(data.merchantLocation)}
                            itemKeyStyle={styles.key}
                            itemStyle={
                                data.merchantLocation === UNKNOWN
                                    ? styles.unknown
                                    : styles.value
                            }
                            style={styles.cardLine}
                        />
                    }
                    {
                        data.merchantPhone &&
                        <CardLineItem
                            itemKey='Merchant Phone Number:'
                            itemValue={
                                data.merchantPhone === UNKNOWN
                                    ? UNKNOWN
                                    : formatPhoneNumber(data.merchantPhone)}
                            itemKeyStyle={styles.key}
                            itemStyle={
                                data.merchantPhone === UNKNOWN
                                    ? styles.unknown
                                    : styles.value
                            }
                            style={styles.cardLine}
                        />
                    }
                    {
                        data.dateFound &&
                        <CardLineItem
                            itemKey='Loan Date:'
                            itemValue={data.dateFound === UNKNOWN
                                ? UNKNOWN
                                : formatDates(data.dateFound, 'YYYYMMDD', 'MM/DD/YYYY')}
                            itemKeyStyle={styles.key}
                            itemStyle={
                                data.dateFound === UNKNOWN
                                    ? styles.unknown
                                    : styles.value
                            }
                            style={styles.cardLine}
                        />
                    }
                    {
                        data.amount &&
                        <CardLineItem
                            itemKey='Amount:'
                            itemValue={`$${data.amount}`}
                            itemKeyStyle={styles.key}
                            itemStyle={
                                data.amount === UNKNOWN
                                    ? styles.unknown
                                    : styles.value
                            }
                            style={styles.cardLine}
                        />
                    }
                    {
                        data.status &&
                        <CardLineItem
                            itemKey='Status:'
                            itemValue={sentenceCase(data.status)}
                            itemKeyStyle={styles.key}
                            itemStyle={
                                data.amount === UNKNOWN
                                    ? styles.unknown
                                    : styles.value
                            }
                            style={styles.cardLine}
                        />
                    }
                </>
            </CardWithTitle>
        ));
};

export default ExperianCyberAgentPayDayLoanAlert;