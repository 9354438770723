import { segmentAnalytics } from "../configuration/ApplicationContext";

export const START_SIGNUP_FLOW = 'Start Sign Up Flow';
export const SERVICE_COVERAGE_SELECTED = 'Service Coverage Selected';
export const BASIC_MEMBER_SELECTS_HIGHER_COVERAGE = 'My Account| Basic Member Selects Higher Coverage';
export const PROTECT_MEMBER_SELECTS_COMPLETE_COVERAGE = 'My Account| Protect Member Selects Complete Coverage';
export const UPGRADE_OPTION_BROWSED = 'Upgrade Option Browsed';
export const BASIC_MEMBER_BROWSES_HIGHER_COVERAGE =  'My Account| Basic Member Browses Higher Coverage';
export const SUF_PROMO_CODE_SUCCESSFUL = 'SUF Promo Code Successful';
export const PAYMENT_INFO_SUBMITTED = 'Payment Info Submitted';
export const SETUP_ID_CHAMPION_CLICKED = 'Set Up Id Champion Clicked';
export const AAA_SUF_PAID_START_MONITORING = 'AAA-SUF-Paid-Start-Monitoring';
export const AAA_SUF_PAYMENT_SUCCESSFUL = 'AAA-SUF-Payment-Successful';
export const PAYMENT_EXPIRED_NOTIFICATION_VIEWED = 'My Account| Payment Expired Notification Viewed';
export const TERMS_AND_CONDITIONS_ACCEPTED = 'Terms and Conditions Accepted';
export const ADMIN = {
    SALES_TOOL_START_NEW_SIGN_UP : 'Sales-Tool-Start-New-Signup'
}
export const trackEvent = (name, obj = {}) => {
    return segmentAnalytics.track(name, obj);
}