import React from 'react';

const DataContext = React.createContext({
    product: {},
    userDetails: {},
    isLoggedIn: false,
    personalInformation: null,
    ratePlanIds: {},
    promoCodeData: null,
    variation: {},
    checkUserLoggedIn: () => {},
    updateState: () => {},
    refreshContext: () => {},
    setPromoCodeData: () => {},
    removePromoCodeData: () => {},
    getPersonalInformation: () => {},
    getBundleRatePlanIdBasedOnPlan : (planType) => {},
    location: '',
});

export default DataContext;