import React, {Component} from 'react';
import styled from 'styled-components';

declare var Z: any; //Zuora

const EmbeddedForm = styled.div`
    width: 100%;
    min-height: 400px;
    iframe {
        width: 100%!important;
        border: none!important;
        background-color: transparent!important;
    }
`;

class ZuoraCheckoutForm extends Component {
    componentDidMount() {
        Z.render(this.props.configuration, this.props.prepopulateFields, () => {});
    }

    render() {
        return (
            <EmbeddedForm id="zuora_payment" />
        );
    }
}

export default ZuoraCheckoutForm;
