import React from 'react';
import styled, { css } from "styled-components";
import { colors, commonPropStyles, mobileOnly } from "../../../../styles/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolTip from "../../../tooltip/ToolTip";
import Title from "../../../elements/Title";
import { BASIC, COMPLETE, NON_AAA, PROTECT } from "../../../common/Constants";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from "react";

const Row = styled.tr`
    height: 40px;
    width: 100%;
    ${props => props.mobileOnly && css`
        ${mobileOnly}
    `}
`;
const RowElement = styled.td`
    font-size: 10px;
    line-height: 13px;
    width: 25%;
    ${props => props.background && css`
        background: ${props.background}
    `}
    ${commonPropStyles.mobile}
    ${commonPropStyles.desktop}
`;
const ProductData = styled.span`
    color: ${props => props.color};
    font-size: 10px;
    line-height: 13px;
    text-align: center;
`;
const CheckIcon = styled.span`
    font-weight: 900;
    font-size: 14px;
    line-height: 19px;
    ${props => props.productname === BASIC && css`
        color: ${colors.skyBlue};
    `}
    ${props => props.productname === BASIC && props.membertype === NON_AAA && css`
        color: ${colors.greyborder};
    `}
    ${props => props.productname === PROTECT && css`
        color: ${colors.mediumBlue};
    `}
    ${props => props.productname === COMPLETE && css`
        color: ${colors.darkBlue};
    `}
`
const styles = {
    iconStyle: { color: colors.textColor, height: '0.8rem', width: '0.8rem', paddingRight: '0.5rem', paddingLeft: '2rem', float: 'left' },
    title: { color: colors.textColor, display: 'block', fontSize: '13px', lineHeight: '13px', fontWeight: 500, overflow: 'hidden', margin: 0 },
    productRowElement: { textAlign: 'center' },
    tooltip: { fontSize: '10px' },
    productSubTypeName: { background: colors.greyMedium }
};

const CompareOurPlansDataMobile = (props) => {
    const {
        subSection,
        memberType
    } = props || {};
    return (
        subSection.name!='30GB Secure Cloud Storage' && <Fragment>
            <Row
                mobileOnly='true'>
                <RowElement
                    colSpan='4'
                    style={styles.productSubTypeName}>
                    <FontAwesomeIcon
                        data-tip
                        data-for={subSection.name}
                        style={styles.iconStyle}
                        icon={faQuestionCircle}
                    />
                    <ToolTip
                        id={subSection.name}
                        content={subSection.tooltipText}
                        backgroundcolor={colors.textColor}
                        fontStyle={styles.tooltip}
                        textcolor={colors.pureWhite}
                    />
                    <Title
                        style={styles.title}>
                        {subSection.name}
                    </Title>
                </RowElement>
            </Row>
            <Row
                mobileOnly='true'>
                {
                    subSection.products.map((product, productIndex) =>
                        <RowElement
                            key={`product${productIndex}`}
                            background={
                                product.name === PROTECT
                                    ? colors.searhRowBackgroundColor
                                    : colors.snowLight
                            }
                            style={styles.productRowElement}>
                            {
                                product.showCheckBox === false &&
                                <ProductData
                                    color={
                                        product.name === BASIC && memberType === NON_AAA
                                            ? colors.greyborder
                                            : colors.textColor
                                    }
                                    dangerouslySetInnerHTML={{ __html: product.value }}
                                />
                            }
                            {
                                product.showCheckBox === true &&
                                <CheckIcon
                                    membertype={memberType}
                                    productname={product.name}>
                                    &#10004;
                                </CheckIcon>
                            }
                        </RowElement>
                    )
                }
            </Row>
        </Fragment>
    );
}
export default CompareOurPlansDataMobile;