import { Component } from 'react';
import { Api } from '../configuration/ApplicationContext';
import { capitalizeFirstLetter, buildUrl } from './common/Helpers';
import { CANCELLED, FREE_UPPER_CASE } from './common/Constants';

export default class BaseUpgradeTierCheckout extends Component {
    async loadPlans() {
        let params = {};
        if (!this.context.userDetails.membership || this.context.userDetails.membership.membershipStatus === CANCELLED) {
            params['isNonAaaMember'] = true;
        }
        const url = buildUrl('/api/products/zuora-rate-plans', params);
        const response = await Api.get(url);
        if (!response) {
            return;
        }
        localStorage.setItem('productsWithoutPromoCodes', JSON.stringify(response.products));
        response.products = response.products.find(product => product.name === 'COMPLETE'
            && (product.annualRatePlan.id === this.props.match.params.productId
                || product.monthlyRatePlan.id === this.props.match.params.productId));
        if (!response.products) {
            this.props.history.push("/my-account")
        }
        this.selectProduct(response.products, this.props.match.params.productId);

    }

    selectProduct(product, productId) {
        const selectedProduct = product.annualRatePlan.id === productId ? product.annualRatePlan : product.monthlyRatePlan;
        selectedProduct.paymentMode = product.annualRatePlan.id === productId ? 'Annually' : 'Monthly';
        selectedProduct.name = capitalizeFirstLetter(product.name);
        selectedProduct.contentfulProductOfferingId = product.contentfulProductOfferingId;
        selectedProduct.totalRefundAmount = product.totalRefundAmount;
        this.setState({product: selectedProduct});
        this.context.updateState({ product: selectedProduct });
    }

    onApplyPromoCode = async (promoCodeData) => {
        this.setState({ promoCodeData });
        if (promoCodeData.products) {
            const product = promoCodeData.products.find(product => product.name !== FREE_UPPER_CASE
            && (product.annualRatePlan.id === this.props.match.params.productId
                || product.monthlyRatePlan.id === this.props.match.params.productId));
            this.selectProduct(product, this.props.match.params.productId);
        }
        const originalContent = JSON.parse(JSON.stringify(this.state.originalContent));
        this.setState({ content: originalContent });
    }

    isNonMemberRateApplicable = userDetails =>
        userDetails 
        && Object.keys(userDetails).length > 0 
        && (  !userDetails.membershipId 
             || (!userDetails.membership && userDetails.invalidMembershipNumber)
             || userDetails.membership?.membershipStatus === CANCELLED
           );

}