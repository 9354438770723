import React from 'react';
import styled, {css} from 'styled-components';
import {colors, mobile, desktop, commonPropStyles, desktopOnly, mobileOnly } from '../../styles/common';

const AnchorLinkStyleComponent = styled.a`
  font-size: ${props => props.fontSize && '17px'};
  color: ${colors.darkBlue};
  margin-top: ${props => props.extratopspace};
  cursor:pointer;
  margin-bottom: ${props => props.extrabottomspace};
  font-weight: 500;
  text-decoration: none;
  display: block;
  ${props => props.textalign && css`
    ${mobile`
      text-align: ${props.textalign};
    `}
  `};
  ${props => props.extraleftspace && css`
    ${desktop`
      margin-left: ${props.extraleftspace};
    `}
  `};
  ${props => props.mobileonly && css`
    ${mobileOnly}
  `}
  ${props => props.desktoponly && css`
    ${desktopOnly}
  `}
  ${commonPropStyles.mobile}
  ${commonPropStyles.desktop}
`;

const AnchorLink = ({text, ...props}) => {
  return (
      <AnchorLinkStyleComponent {...props}>{text}</AnchorLinkStyleComponent>
  )
}

export default AnchorLink;