import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { segmentAnalytics } from "./../configuration/ApplicationContext";
import ZipCodeService from './../services/ZipCodeService';
import AskZipCodePage from './AskZipCodePage';
import DataContext from './contexts/DataContext';

class ZipgateWrapper extends Component {
  static contextType = DataContext;
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showError: false
    };
    this.addZipCodeData = this.addZipCodeData.bind(this);
    this.displayModal = this.displayModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openZipForm = this.openZipForm.bind(this);
  }

  componentWillMount() {
    ZipCodeService.onChange(() => {
      this.verifyZipCookieAndModalDisplay();
    });
    this.verifyZipCookieAndModalDisplay();
  }

  verifyZipCookieAndModalDisplay() {
    var isLoggedIn = this.context.isLoggedIn;
    if(isLoggedIn) {
      return;
    }
    
    const zipCode = ZipCodeService.getZipCookie();
    var location = this.props.location.pathname;
    localStorage.setItem('askedZipCodeOnPage', location);
    if (!zipCode) {
      this.setState({ showModal: true });
    } else {
      this.props.checkZipEligibility().then((response) => {
        if (!response.serviceable) {
          this.props.history.push(this.props.outOfAreaPageLink, {response: response});
        }
      });
    }
  }

  displayModal() {
    this.setState({
      showModal: true
    });
  }

  closeModal() {
    this.setState({
      showModal: false
    });
  }

  addZipCodeData(zip) {
    segmentAnalytics.track('Zipgate | ZIP entered');
    this.props.checkZipEligibility().then((response) => {
      if (!response?.serviceable) {
        segmentAnalytics.track('Zipgate | ZIP rejected');
        this.props.history.push(this.props.outOfAreaPageLink, {response: response});
      } else {
        segmentAnalytics.track('Zipgate | ZIP cleared');
        this.closeModal();
      }
    });
  }

  openZipForm() {
    this.setState({
      showModal: true
    });
  }

  render() {
    return (
      <Fragment>
        <AskZipCodePage
          id="zip_code_modal"
          addZipCodeData={(zip) => { this.addZipCodeData(zip); }}
          showModal={this.state.showModal}
          showError={this.state.showError}
          dismissible={ZipCodeService.getZipCookie()}
          closeModal={this.closeModal}
          openZipForm={this.openZipForm}
        />
        {this.props.children}
      </Fragment>
    );
  }
}

ZipgateWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  outOfAreaPageLink: PropTypes.string,
  checkZipEligibility: PropTypes.func
};

ZipgateWrapper.defaultProps = {
  outOfAreaPageLink: '/out-of-area/signup',
  checkZipEligibility: ZipCodeService.checkIdcEligibility
}

export default ZipgateWrapper;
