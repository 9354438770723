import React, { Component } from 'react';
import styled from 'styled-components';
import { BLOCKS } from "@contentful/rich-text-types";
import { ContentfulService } from '../../configuration/ApplicationContext';
import ContentfulIds from '../../configuration/ContentfulIds';
import { desktop, mobile } from '../../styles/common';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import withPageMetaTags from '../layout/withPageMetaTags';
import withPageTitle from '../layout/withPageTitle';
import Title from '../elements/Title';
import RichText from '../elements/RichText';
import LandingContent from '../layout/LandingContent';
import ResponsiveLandingPageLayout from '../layout/ResponsiveLandingPageLayout';
import Spinner from '../elements/Spinner';
import StringPlaceholderService from '../../services/StringPlaceholderService';
import DataContext from '../contexts/DataContext';

const Phase3MigratedUserErrorSecton = styled.div`
    ${desktop`
        padding: 20px 30px;
    `}
    width: 50%;
	${mobile`
        padding: 0.5rem 1rem;
        box-sizing: border-box;
        width: 100%;
	`}
`;
const desktopStyles = {
    title: { lineHeight: '48px' },
    dynamicParagraph: { fontSize: '16px', lineHeight: '25px' }
};

const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText
    desktopStyles={desktopStyles.dynamicParagraph}
    id="rich-text" {...props}
    text={children}
/>;

class Phase3MigratedUserAlreadyExistsPage extends Component {
    static contextType = DataContext;
    state = {};

    componentDidMount() {
        this.loadContent();
        this.setOptions();
    }

    setOptions = () => { 
        const { userDetails: { personalInformation: { email } } = {} } = this.context || {};
            const options = {
            renderText: (text) => {
                const properties = {
                    email
                };
                return StringPlaceholderService.replace(text, properties)
            },
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle style={{ padding: '10px 0px' }}>{children}</DynamicParagraphStyle>
            }
        };
        this.setState({ options });
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.Phase3MigratedUserAlreadyExistsPage);
        this.setState({
            content: response.fields,
        });
    }

    render() {
        const { content, options } = this.state || {};
        if (!content) {
            return <Spinner />;
        }
        return (
            <LandingContent>
                <ResponsiveLandingPageLayout>
                    <Phase3MigratedUserErrorSecton>
                        <Title
                            desktopStyles={desktopStyles.title}>
                            {content.title}
                        </Title>
                        <RichText
                            text={content.description}
                            options={options}
                        />
                    </Phase3MigratedUserErrorSecton>
                </ResponsiveLandingPageLayout>
            </LandingContent>
        );
    }
}
export default withPageMetaTags(withPageTitle(Phase3MigratedUserAlreadyExistsPage, "Phase 3 Migration User Already Exist"), { noindex: true });