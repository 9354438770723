export const OFFLINE_USER = 'offline-user';
export const IDC_USER = 'idc-user';
export const NON_BUNDLE_USER = 'non-bundle-user';
export const KEYCLOAK_USER = 'keycloak-user';
export const CANCELLED_IDC_USER = 'cancelled-idc-user';
export const OUTSIDE_MWG = 'outside-mwg';
export const INSIDE_MWG = 'inside-mwg';
export const FREE = 'Free';
export const FOREVER = 'forever';
export const CANCELLED = 'CANCELLED';
export const PENDING = 'Pending';
export const FREE_UPPER_CASE = 'FREE';
export const RENEWAL_DAYS = 31;
export const PROTECT_UPPER_CASE = 'PROTECT';
export const COMPLETE_UPPER_CASE = 'COMPLETE';
export const ACTIVE = 'ACTIVE';
export const INVALID = 'INVALID';
export const ANNUALLY = 'Annually';
export const MONTHLY = 'Monthly';
export const COMPLETE = 'Complete';
export const PROTECT = 'Protect';
export const BASIC = 'Basic';
export const BASIC_LOWER_CASE = 'basic';
export const COMPARE_PLANS = 'Plan Details';
export const AMOBEE_SUF_NEW_FREE_MEMBER_TAG_URL = 'https://r.turn.com/r/beacon?b2=SbgO7C3LuX2q7FJL_LF6kRoO7PCwBsNAqvm0gsyOpAsctqTka-ec7xXVfiBb6f0apk0DpTJvspYusXxlxPAeJg&cid=';
export const AMOBEE_PAYMENT_INFO_SUBMITTED_TAG_URL = 'https://r.turn.com/r/beacon?b2=cYRLBYVrShKOQv86R4WGarYmhyTdSV1Y15H7M_PZUxgctqTka-ec7xXVfiBb6f0aAltbv6Arp1QHYfd26q1CRA&cid=';
export const AMOBEE_BUNDLE_SUF_SUBMIT_PAYMENT_TAG_URL = 'https://r.turn.com/r/beacon?b2=Cyb-tmJzysc6aJxQ6Ghu_V9AekZpIPTtX8-At6QC3xEctqTka-ec7xXVfiBb6f0aYmqMYOa8a4fJbMpskpV8Ug&cid=';
export const AMOBEE_BASIC_MEMBER_UPGRADES_AND_PAYS_SUCCESSFULLY_TAG_URL = 'https://r.turn.com/r/beacon?b2=d2SW26INRt5P2-ZvUo33NXt5dy5rA_cHpTJt59qzLJ0ctqTka-ec7xXVfiBb6f0aAySm1eNTfvF-XY9T71_CYw&cid=';
export const META_DESCRIPTION = {
    HOME: 'AAA® Identity Champion provides 24/7 identity theft monitoring, alerts & more. Get special AAA Member rates on identity protection, including Basic monitoring at no extra cost. ',
    COMPARE_PLANS: 'Choose the right identity protection plan for your needs. AAA Identity Champion offers credit monitoring, dark web surveillance, insurance, identity restoration support & more. ',
    MY_ACCOUNT: 'Review your identity monitoring alerts & AAA Identity Champion account details . Log in with your AAA username & password to get started. ',
    BUNDLE_PAGE: 'Bundle a AAA Membership with one of our enhanced plans at one low rate. Save $60+ when you bundle today.',
    FAQS: 'Have questions about AAA® Identity Champion? Learn more about our service, identity protection plans, and how to sign up.',
    CONTACT_US: 'Need to get in touch? Contact AAA® Identity Champion any time by calling (844) 432-4267 or emailing support@identitychampion.mwg.aaa.com. ',
    SIGN_UP: 'AAA Members get Basic monitoring at no extra cost. Get 24/7 identity theft monitoring from AAA today. Pick the right monitoring plan for your needs. '
};
export const NONE = 'None';
export const AAA_MEMBER = 'aaa';
export const NON_AAA = 'non_aaa';
export const MEMBER_TYPE = 'memberType';
export const PLAN_TYPE = 'planType';
export const REJOIN = 'rejoin';
export const SIGNUP = 'signup';
export const UPGRADE = 'upgrade';
export const NON_AAA_MEMBER = 'non-aaa-member';
export const ANNUAL = 'annual';
export const INVALID_PROMO_CODE = 'Invalid PromoCode';
export const HUNDRED_PERCENTAGE_PROMO_FOR_UPGRADE = 'Hundred Percentage Promo For Upgrade';
export const PRODUCTS_WITHOUT_PROMO_CODES = 'productsWithoutPromoCodes';
export const NON_AAA_USER = 'NonMember';
export const AAA_USER = 'AAAMember';
export const UNKNOWN = 'Unknown';
export const NOT_AVAILABLE = 'N/A';