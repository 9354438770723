import React from 'react';
import AdminCheckoutForm from './forms/AdminCheckoutForm';
import ResponsiveSignupFlowLayout, {
    SignUpFlowExtraContent,
    SignUpFlowFormContent
} from '../layout/ResponsiveSignupFlowLayout';
import Content from '../layout/Content';
import Title from '../elements/Title';
import Spinner from '../elements/Spinner';
import AdminSummary from './summary/AdminSummary';
import BaseAdminUpgradeTierCheckout from './BaseAdminUpgradeTierCheckout';
import { AdminApi } from '../../configuration/ApplicationContext';
import ContentfulIds from '../../configuration/ContentfulIds';
import Breadcrumbs from '../common/Breadcrumbs';
import AdminContext from '../contexts/AdminContext';
import { AdminNotificationTypes } from '../notifications/AdminNotifications';
import { FREE, FREE_UPPER_CASE } from '../common/Constants';
import Card from '../elements/Card';

declare var Z: any; //Zuora

export default class AdminUpdatePaymentMethodPage extends BaseAdminUpgradeTierCheckout {
    static contextType = AdminContext;

    constructor(props) {
        super(props);
        this.state = {
            zuoraConfiguration: null,
        };
    }

    async componentDidMount() {
        await this.loadMemberDetails();
        this.loadZuoraForm();
        this.setBreadCrumb();
        this.loadContent(ContentfulIds.AdminUpdatePaymentPage)
        this.loadPersonalInformation();
        this.prepopulateFields();
        await this.props.notificationContext.getNotifications();
    }

    prepopulateFields() {
        this.setState({
            prepopulateFields: {
                creditCardHolderName: this.context.memberDetails?.subscription?.defaultPaymentMethod?.cardHolderName || ''
            }
        });
    }

    setBreadCrumb = () => {
        const { personalInformation } = this.context.memberDetails || {};
        const breadCrumbRoutes = [
            {
                to: '/admin/member-account', label: personalInformation?.displayName
            },
            {
                to: `/admin/update-payment`, label: 'Update Payment Method'
            }
        ]
        this.setState({ breadCrumbRoutes });
    }

    submit = async (data) => {
        const { addNotification } = this.props.notificationContext;
        const { lastModified, id, createdOn, ...billingAddress } = this.state.personalInfo?.billingAddress || {};

        if (Object.keys(data.errors).length > 0 || !data.values.billingAddress) {
            this.setState({
                checkoutError: data.errors.billingAddress,
            });
            return;
        }
        this.setState({
            checkoutError: ""
        });

        window.orderSuccess = async (refId) => {
            this.setState({
                isSubmitDisabled: true
            });
            const paymentMethodId = refId;
            await AdminApi.put(`/api/users/${this.context.searchResult.userId}/payment-method`, { paymentMethodId, billingAddress });
            await addNotification(AdminNotificationTypes.PAYMENT_UPDATE_SUCCESS);
            this.props.history.push('/admin/member-account');
        };

        window.orderFailure = (failureMessage) => {
            this.setState({
                checkoutError: failureMessage,
                zuoraConfiguration: null,
                isSubmitDisabled: false
            }, () => {
                this.loadZuoraForm();
            });
        };
        Z.submit();
    };

    render() {
        const { zuoraConfiguration, content, checkoutError, personalInfo, prepopulateFields, billingAddressSameAsHomeAddress, isSubmitDisabled, breadCrumbRoutes } = this.state;
        const { product, memberDetails: { upcomingSubscriptionProductType } = {} } = this.context || {};
        if (!zuoraConfiguration || !content || !product || isSubmitDisabled) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{ marginTop: '0rem' }}>
                <Breadcrumbs
                    isCustomerFacing={false}
                    breadCrumbRoutes={breadCrumbRoutes}
                />
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title
                            desktopStyles={{ marginTop: '0' }}
                            mobileStyles={{ marginTop: '3rem' }}>
                            {content.title}
                        </Title>
                        <AdminCheckoutForm
                            zuoraConfiguration={zuoraConfiguration}
                            prepopulateFields={prepopulateFields}
                            content={content}
                            onSubmit={this.submit}
                            checkoutError={checkoutError}
                            personalInfo={personalInfo}
                            onSelectAddress={this.onSelectAddress}
                            selectedProduct={product}
                            billingAddressSameAsHomeAddress={billingAddressSameAsHomeAddress}
                            isSubmitDisabled={isSubmitDisabled}
                            isUpdatePaymentMethodPage={true}
                            showDeclineLink={false}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        <Card>
                        {
                            product.name !== FREE && upcomingSubscriptionProductType !== FREE_UPPER_CASE &&
                            <AdminSummary
                                isUpdatePaymentMethodPage={true}
                                product={product}
                                title={`Next Payment`}
                            />
                        }
                        </Card>
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

