import React, { Component } from 'react';
import { Api, ContentfulService, segmentAnalytics } from '../configuration/ApplicationContext';
import ContentfulIds from '../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Title from './elements/Title';
import RichText from './elements/RichText';
import Spinner from './elements/Spinner';
import withPageTitle from "./layout/withPageTitle";
import SubmitButton from './elements/SubmitButton';
import SignupFlowStepProgress from './elements/SignUpFlowStepProgress';
import Breadcrumbs from './common/Breadcrumbs';
import InfoText from './elements/InfoText';
import { dynamicSignUpFlowSteps } from './common/Steps';
import Section from './elements/Section';
import { BASIC_LOWER_CASE, NON_AAA } from './common/Constants';
import { colors } from '../styles/common';
import StringPlaceholderService from '../services/StringPlaceholderService';
import withPageMetaTags from './layout/withPageMetaTags';
import { buildUrl, setMemberTypeInSessionStorage } from './common/Helpers';

const styles = {
    notAaaMemberLink: { fontSize: '16px', color: colors.darkBlue, fontWeight: 500, cursor: 'pointer' }
};
const desktopStyles = {
    notAaaMemberLink: { marginLeft: '5rem' }
};
const mobileStyles = {
    notAaaMemberLink: { textAlign: 'center' }
};
class LoginWithAAAPage extends Component {
    componentDidMount() {
        this.getUrlParams();
        this.loadContent();
        this.setBreadCrumbs();
    }

    getUrlParams = async () => {
        const params = new URLSearchParams(this.props.location.search);
        const plan = params.get('plan');
        const paymentMode = params.get('paymentMode');
        this.setState({ plan, paymentMode });
    }
    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.LoginWithAAAPage);
        this.setState({ content: response.fields });
    }

    setBreadCrumbs = () => {
        const breadCrumbRoutes = [
            {
                to: `/product/${this.props.match.params.ratePlanId}`, label: 'Choose Plan'
            }
        ];
        this.setState({
            breadCrumbRoutes
        });
    }

    submit = async () => {
        this.setState({
            isSubmitDisabled: true
        });
        segmentAnalytics.track('SUF Click to AAA Login', {});
        await Api.login('login', {
            'rate-plan': this.props.match.params.ratePlanId
        });
    }

    handleRedirectionOnNotAaaMemberClick = () => {
        const { plan, paymentMode } = this.state || {};
        setMemberTypeInSessionStorage(NON_AAA);
        let params = {};
        params['product'] = plan;
        if (paymentMode) {
            params['payment-mode'] = paymentMode;
        }
        this.props.history.push(buildUrl(`/product/${this.props.match.params.ratePlanId}`, params));
    }
    render() {
        const { content, breadCrumbRoutes, isSubmitDisabled, plan, paymentMode } = this.state || {};
        const options = {
            renderText: (text) => {
                const properties = {
                    plan,
                    paymentMode
                };
                return StringPlaceholderService.replace(text, properties)
            }
        };

        if (!content) {
            return <Spinner />;
        }
        let selectedOptions = null;
        if (plan !== null) {
            selectedOptions = BASIC_LOWER_CASE === plan.toLowerCase() ? content.contactUs : content.subDescription;
        }
        return (
            <Content>
                <InfoText
                    mobileOnly={true}>
                    <Breadcrumbs
                        breadCrumbRoutes={breadCrumbRoutes}
                        showOneBreadcrumb={true}
                    />
                </InfoText>
                <SignupFlowStepProgress
                    currentStep={2}
                    steps={
                        dynamicSignUpFlowSteps(this.props.match.params.ratePlanId === 'basic',
                            true,
                            {
                                productId: this.props.match.params.ratePlanId,
                                params: {
                                    paymentMode,
                                    plan
                                }
                            }
                        )}
                />
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title
                            style={{ marginTop: '2rem' }}>
                            {content.title}
                        </Title>
                        <RichText
                            text={selectedOptions}
                            options={options} />
                        <RichText
                            text={content.description}
                            options={options} />
                        <SubmitButton
                            style={{ marginTop: '2rem' }}
                            disabled={isSubmitDisabled}
                            text={content.linkText}
                            to="#"
                            onClick={this.submit}
                        />
                        <Section
                            style={styles.notAaaMemberLink}
                            desktopStyles={desktopStyles.notAaaMemberLink}
                            mobileStyles={mobileStyles.notAaaMemberLink}
                            onClick={this.handleRedirectionOnNotAaaMemberClick}
                            textalign="center"
                            extratopspace="1rem"
                            extrabottomspace="1rem"
                            extraleftspace="5rem"
                            text="Not a AAA Member?" />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageMetaTags(withPageTitle(LoginWithAAAPage, "Sign In With AAA"), { noindex: true });