import React from 'react';

const AdminContext = React.createContext({
    product: {},
    isSearching: false,
    searchResults: [],
    memberDetails: null,
    searchResult: {},
    ratePlanIds: {},
    loginId: null,
    promoCodeData: null,
    updateState: () => {},
    getMemberDetails: () => {},
    getLoginId: () => {},
    getBundleRatePlanIdBasedOnPlan : (planType) => {},
    setPromoCodeData: () => {},
    removePromoCodeData: () => {},
});

export default AdminContext;