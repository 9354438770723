import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Api, ContentfulService } from '../../configuration/ApplicationContext';
import ContentfulIds from '../../configuration/ContentfulIds';
import StringPlaceholderService from '../../services/StringPlaceholderService';
import { colors, desktopOnly, mobile, mobileOnly } from '../../styles/common';
import { ANNUAL, ANNUALLY, MONTHLY } from '../common/Constants';
import { absoluteValue, buildUrl, getPromoCodeNameFromRedirectParams, redirectParameters, removePromoCodeNameFromRedirectParams } from '../common/Helpers';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import Link from '../elements/Link';
import RichText from '../elements/RichText';

const PromoCodeNotification = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    background: ${colors.infoBackground};
    max-width: 1440px;
    margin: 0 auto;
    padding: 1.5rem;
    color: ${colors.titleText};
    ${mobile`
        color: ${colors.titleText};
        font-size: 14px;
    `}
    ${props => props.mobileonly && css`
        ${mobileOnly}
    `}
    ${props => props.desktoponly && css`
        ${desktopOnly}
    `}
`;
const CloseIcon = styled.span`
    cursor: pointer;
    background: ${colors.infoBackground};
    color: ${colors.titleText};
    width: 10%;
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    &::after {
        content: "\\00d7";
        background: ${colors.infoBackground};
        color: ${colors.titleText};    
        font-weight: 500;
    }        
`;
const styles = {
    dynamicParagraph: { margin: 0 }
};
const mobileStyles = {
    link: { fontSize: '14px' }
};
const DynamicParagraphStyle = ({ children, ...props }) =>
    <DynamicParagraphText
        desktopFontSize='17px'
        mobileFontSize='14px'
        id="rich-text"
        {...props}
        text={children}
    />;
const PromoCodeNotifications = ({ ...props }) => {
    const [content, setContent] = useState();
    const [showNotification, setShowNotification] = useState(true);
    const [options, setOptions] = useState();
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        async function loadContentAndPromoCodeInformation() {
            const promoCodeName = getPromoCodeNameFromRedirectParams();
            if (promoCodeName) {
                const url = buildUrl(`/api/promo-codes/${promoCodeName.toUpperCase()}/information`);
                const response = await Api.get(url);
                if (!response || response.validationErrors) {
                    return;
                } else {
                    setRichTextOptions(response);
                }
            }
            const response = await ContentfulService.getEntry(ContentfulIds.MarketingPages.PromoCodeBanner);
            setContent(response.fields);

        }
        loadContentAndPromoCodeInformation();
    }, []);

    const setRichTextOptions = (response) => {
        const options = {
            renderText: (text) => {
                const properties = {
                    promoCodeName: response.promoCodeName,
                    promoCodeDiscount: response.discountPercentage ? `${absoluteValue(response.discountPercentage)}%` : `$${response.discountAmount}`,
                    productNames: response.productNames.join(' or '),
                    billingFrequency: response.billingFrequency.toLowerCase() === ANNUAL ? ANNUALLY.toLowerCase() : MONTHLY.toLowerCase()
                };
                return StringPlaceholderService.replace(text, properties)
            },
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) =>
                    <DynamicParagraphStyle
                        style={styles.dynamicParagraph}>
                        {children}
                    </DynamicParagraphStyle>,
                [INLINES.HYPERLINK]: (node, next) => {
                    return <Link
                        style={{ display: 'inline' }}
                        mobileStyles={mobileStyles.link}
                        to={node.data.uri}
                        text={node.content[0].value}
                    />;
                }

            },
        };
        setOptions(options);
    }

    const onCloseClick = () => {
        setShowNotification(false);
        removePromoCodeNameFromRedirectParams();
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has(redirectParameters.promoCode)) {
            queryParams.delete(redirectParameters.promoCode);
            history.replace({
                search: queryParams.toString()
            })
        }
        window.location.reload(false);
    }
    if (!content || !options) {
        return null;
    }
    return (
        <>
            {
                showNotification === true && <PromoCodeNotification
                    {...props}>
                    <RichText
                        text={content.description}
                        options={options}
                    />
                    <CloseIcon
                        onClick={onCloseClick}
                    />
                </PromoCodeNotification >
            }
        </>
    );
}

export default PromoCodeNotifications;