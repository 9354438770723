import React, { Component } from 'react';
import LandingContent from './layout/LandingContent';
import ResponsiveLandingPageLayout from './layout/ResponsiveLandingPageLayout';
import withZipGate from './layout/withZipGate';
import withPageTitle from "./layout/withPageTitle";
import { ccpaBaseUrl, segmentAnalytics, CcpaApi } from '../configuration/ApplicationContext';
import styled from 'styled-components';
import { colors } from '../styles/common';
import withPageMetaTags from './layout/withPageMetaTags';
import { getPromoCodeNameFromRedirectParams } from './common/Helpers';


const PrivacyRightsOptout = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	padding-bottom: 45px;
	padding-top: 25px;
	.aaaContainer {
		padding: 10px;
	}

	.button--primary, .content-container__link {
		background-color: ${colors.darkBlue};
		width: 312px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		text-align: center;
		color: #fff;
		font-size: 19px;
		line-height: 50px;
		letter-spacing: -0.3px;
		cursor: pointer;
	}

	.content-container__link {
		text-decoration: none;
		display: block;
		margin: 0 auto;
		margin-top: 40px;
	}

	.button--primary:disabled {
		background-color: ${colors.darkBlue};
		opacity: 0.5;
		cursor: not-allowed;
	}

	.content-container__title {
		font-size: 48px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 50px;
		padding: 0 10%;
		letter-spacing: -0.4px;
		color: #303030;
		text-align: center;
		margin: 10px 0;
	}

	.content-container__subtitle {
	    max-width: 900px;
        margin: 0 auto;
        font-size: 20px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 33px;
        letter-spacing: -.17px;
        text-align: center;
        color: #666;
	}

	.cards-container {
		display: flex;
		justify-content: space-between;
		margin-top: 50px;
	}

	.cards-container .card-container {
		background-color: #f9f9f9;
		padding: 20px;
		width: 25%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1;
		margin: 0 10px;
	
		:first-child {
		  margin: 0 10px 0 0;
		}
		:last-child {
		  margin: 0 0 0 10px;
		}
		* {
		  margin: 10px 0;
		}
		a {
		  text-decoration: none;
		  margin: 0 0 10px 0;
		}
		div:last-child {
		  margin: 10px 0 0 0;
		}
	}

	.cards-container .card-container__title {
		font-weight: 500;
		font-size: 24px;
		color: #2a2a2a;
		text-align: center;
		letter-spacing: 0;
		margin-bottom: 20px;
		text-decoration: none;
	}
	
	.cards-container .card-container__subtitle {
		font-size: 20px;
		font-weight: normal;
		line-height: 33px;
		letter-spacing: -0.17px;
		text-align: center;
		color: #5b5b5b;
	}

	.cards-container .button-container {
		display: flex;
		justify-content: center;
	}

	.accordion {
		display: flex;
		justify-content: space-evenly;
		flex-direction: column;
		margin: 40px 0 20px;
		padding-bottom: 50px;
	}

	.accordion .accordion__item {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
	}

	.accordion > .accordion__item {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0 10px;
		min-height: 50px;
		box-sizing: border-box;
	}

	.accordion .accordion__item span {
		margin-right: 15px;
		align-items: center;
		display: flex;
	}

	.accordion .accordion__item span:before {
		content: "▲";
		color: #d7d8d9;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 30px;
		letter-spacing: normal;
	}

	.accordion .accordion__item span > svg {
		display: none;
	}

	.accordion .accordion__chevron {
		transform: rotate(90deg);
		display: inline-table;
	}

	.accordion .accordion__chevron--up {
		transform: rotate(180deg);
	}

	.accordion .accordion-item__header {
		font-size: 20px;
		line-height: 30px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		margin-top: 10px;
		cursor: pointer;
	}

	.accordion .accordion-item__body {
		color: #666666;
		line-height: 30px;
		font-size: 15px;
		font-weight: normal;
		padding: 10px 0 0 35px;
		letter-spacing: 0;
	}

	.accordion .accordion-item__body ul {
		list-style: initial;
		margin-left: 40px;
	}

	.accordion .accordion-item__body p {
		margin-bottom: 5px;
	}

	.accordion .accordion-item__body p u {
		font-weight: bold;
		text-decoration: none;
	  }
	
	  .accordion-item__body--closed {
		display: none;
		transition: display 0.5s linear;
	  }
	
	  .form-container {
		display: flex;
		flex-direction: column;
		max-width: 650px;
		margin: 0 auto;
		margin-top: 20px;
	  }
	
	  .form-container .input {
		height: 80px;
		margin-bottom: 40px;
	  }
	
	  .form-container .input .input__error {
		color: #da291c;
		font-size: 15px;
		margin-top: 5px;
	  }
	
	  .form-container .input .input__floating {
		display: flex;
		flex-direction: column-reverse;
	  }
	
	  .form-container .input .input__floating input {
		font-size: 17px;
		width: 648px;
		height: 45px;
		box-shadow: 0 2px 4px 0 rgba(203, 203, 203, 0.06);
		border: solid 1px #d7d8d9;
		background-color: #ffffff;
		outline: none;
		color: #666666;
	  }
	
	  .form-container .input .input__floating input::placeholder {
		color: transparent;
	  }
	
	  .form-container .input .input__floating > .input__label {
		color: #2a2a2a;
		font-size: 17px;
		line-height: 30px;
		letter-spacing: 0;
		margin-bottom: 6px;
	  }
	
	  .form-container .form-container__disclaimer {
		font-weight: normal;
		font-size: 20px;
		color: #717171;
		line-height: 33px;
		padding: 10px;
		letter-spacing: -0.17px;
		text-align: center;
		margin: 0 auto;
	  }
	
	  .form-container button {
		margin: 0 auto;
		margin-top: 40px;
		margin-bottom: 20px;
	  }
	
	  .breadcrumb {
		display: flex;
		color: #737373;
		margin-left: 40px;
		font-weight: 600;
		font-size: 1rem !important;
	  }
	
	  .breadcrumb a {
		color: #737373;
		text-decoration: none;
	  }
	
	  .breadcrumb span {
		margin-left: 10px;
	  }
	
	  @media (min-width: 320px) and (max-width: 1024px) {
	
		.content-container {
		  padding: 0;
		}
	
		.aaaContainer {
		  padding: 0;
		}
	
		.content-container__title {
		  padding: 0 20px;
		  font-size: 29px;
		  line-height: 35px;
		  text-align: center;
		  color: #2f2f2f;
		}
		.content-container__subtitle {
		  padding: 0 20px;
		  font-size: 18px;
		  letter-spacing: 0;
		  color: #2a2a2a;
		}
	
		.cards-container {
		  display: flex;
		  flex-direction: column;
		  justify-content: space-between;
		  margin-top: 30px;
		}
	
		.cards-container .card-container {
		  margin: 30px 0 0 0;
		  width: 90%;
		}
	
		.cards-container .card-container:first-child {
		  margin: 0;
		}
	
		.cards-container .card-container:last-child {
		  margin: 30px 0 0 0;
		}
	
		.cards-container .card-container__title {
		  font-size: 24px;
		  font-weight: 500;
		  letter-spacing: 0;
		  text-align: center;
		  color: #252525;
		}
	
		.cards-container .card-container__subtitle {
		  font-size: 17px;
		  font-weight: normal;
		  line-height: 26px;
		  letter-spacing: 0;
		  text-align: center;
		  color: #252525;
		}
	
		.form-container .input .input__floating input {
		  width: 100%;
		}
	
		.form-container .form-container__disclaimer {
		  padding: 10px 28px;
		  margin-bottom: 20px;
		}

		.form-container .input {
		  padding: 0 30px;
		}
	  }
`;

class PrivacyRightPage extends Component {
	componentDidMount() {
		window.scroll(0, 0);
		const script = document.createElement('script');
		script.src = `${ccpaBaseUrl}/privacy-rights/v1/app`;
		script.id = 'privacy-rights-ccpa-script';
		document.body.appendChild(script);
	}

	componentWillUnmount() {
		window.location.reload();
	}

	render() {
		return (
			<LandingContent
				promocodename={getPromoCodeNameFromRedirectParams()}>
				<ResponsiveLandingPageLayout>
					<PrivacyRightsOptout
						id="privacy-rights-optout"
						data-tracking-service="segment.io"
						data-segment-key={segmentAnalytics.segmentApiKey}
						data-home-uri="/privacy-rights"
						data-privacy-rights-home-uri="/privacy-rights"
						data-entity="aaa-idc"
						data-home-label="Privacy Options"
						data-hide-breadcrumb="true"
						data-proxy-uri={CcpaApi.resolve('')}
						data-delete-button-label="Request Delete"
						data-view-button-label="Request View"
					/>
				</ResponsiveLandingPageLayout>
			</LandingContent>
		);
	}
}
export default withPageMetaTags(withPageTitle(withZipGate(PrivacyRightPage), "Privacy Rights"), { noindex: true });
