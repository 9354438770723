import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import withAdminHeader from '../components/layout/withAdminHeader';
import CoverageUpgradePage from './../components/admin/upgrades/CoverageUpgradePage';
import CoverageDowngradePage from './../components/admin/downgrades/CoverageDowngradePage';
import MemberSearchPage from '../components/admin/search/MemberSearchPage';
import SearchResults from '../components/admin/search/SearchResults';
import AdminContext from '../components/contexts/AdminContext';
import AdminProvider from '../components/contexts/AdminProvider';
import NoResults from '../components/admin/search/NoResults';
import PaidTierProductUpgradePage from '../components/admin/PaidTierProductUpgradePage';
import AdminPaidTierProductUpgradeCheckoutPage from '../components/admin/PaidTierProductUpgradeCheckoutPage';
import AdminProductUpgradeSuccessPage from '../components/admin/AdminProductUpgradeSuccessPage';
import AdminMemberAccountPage from '../components/admin/AdminMemberAccountPage';
import PaymentPage from '../components/admin/payments/PaymentPage';
import EditLoginAndPersonalDetailsPage from '../components/admin/EditLoginAndPersonalDetailsPage';
import AdminSubscriptionDowngradePage from '../components/admin/AdminSubscriptionDowngradePage';
import AdminCancelSuccessPage from '../components/admin/AdminCancelSuccessPage';
import AdminPersonalInfoPage from '../components/admin/AdminPersonalInfoPage';
import AdminProductOfferingPage from '../components/admin/AdminProductOfferingPage';
import AdminSubscriptionSuccessPage from '../components/admin/AdminSubscriptionSuccessPage';
import AdminCheckoutPage from '../components/admin/AdminCheckoutPage';
import AdminPersonalNonMemberInfoPage from '../components/admin/AdminPersonalNonMemberInfoPage';
import AdminNonMemberProductOfferingPage from '../components/admin/AdminNonMemberProductOfferingPage';
import AdminNonMemberCheckoutPage from '../components/admin/AdminNonMemberCheckoutPage';
import AdminNotificationProvider from '../components/contexts/AdminNotificationProvider';
import AdminNotificationContext from '../components/contexts/AdminNotificationContext';
import AdminLogoutSuccessPage from '../components/admin/AdminLogoutSuccessPage';
import AdminPayOutstandingBalancePage from '../components/admin/AdminPayOutstandingBalancePage';
import AdminUpdatePaymentMethodPage from '../components/admin/AdminUpdatePaymentMethodPage';
import AdminNonMemberSubscriptionSuccessPage from '../components/admin/AdminNonMemberSubscriptionSuccessPage';
import ExperianAlertsPage from '../components/admin/ExperianAlertsPage';
import AdminBundleRoutes from './AdminBundleRoutes';
import AdminAccountAlreadyExist from '../components/admin/bundle/AdminAccountAlreadyExist';
import AdminCancelledIdcAccountExist from '../components/admin/bundle/AdminCancelledIdcAccountExist';
import AdminIdcAccountAlreadyExist from '../components/admin/bundle/AdminIdcAccountAlreadyExist';
import AdminAAAMembershipExist from '../components/admin/bundle/AdminAAAMembershipExist';
import ExperianCyberAgentReportPage from '../components/admin/ExperianCyberAgentReportPage';
class AdminRoutes extends Component {
    static contextType = AdminNotificationContext;
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false;
    }
    render() {
        return (
            <AdminNotificationProvider>
                <AdminProvider>
                    <Route exact path="/admin" component={() => <Redirect to="/admin/search" />} />
                    <Route exact strict path="/admin/search" component={withAdminHeader((props) => <AdminContext.Consumer>{adminContext => <MemberSearchPage {...props} adminContext={adminContext} />}</AdminContext.Consumer>)} />
                    <Route exact strict path="/admin/search/results" component={withAdminHeader(SearchResults)} />
                    <Route exact strict path="/admin/search/noresults" component={withAdminHeader(NoResults)} />
                    <Route exact strict path="/admin/member-account" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <AdminMemberAccountPage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/edit-login-and-personal-details" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <EditLoginAndPersonalDetailsPage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/basic/upgrade/:id" component={withAdminHeader((props) => <AdminContext.Consumer>{adminContext => <CoverageUpgradePage {...props} adminContext={adminContext} />}</AdminContext.Consumer>)} />
                    <Route exact strict path="/admin/downgrade/:id" component={withAdminHeader((props) => <AdminContext.Consumer>{adminContext => <CoverageDowngradePage {...props} adminContext={adminContext} />}</AdminContext.Consumer>)} />
                    <Route exact strict path="/admin/process-payment/:productId" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <PaymentPage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/product/protect/upgrade/:id" component={withAdminHeader(PaidTierProductUpgradePage)} />
                    <Route exact strict path="/admin/product/protect/upgrade/checkout/:productId" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <AdminPaidTierProductUpgradeCheckoutPage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/product/upgrade/success" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <AdminProductUpgradeSuccessPage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/pay-outstanding-balance" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <AdminPayOutstandingBalancePage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/downgrade-complete" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <AdminSubscriptionDowngradePage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/cancel/success" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <AdminCancelSuccessPage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/personal-info" component={withAdminHeader(AdminPersonalInfoPage)} />
                    <Route exact strict path="/admin/product/:id" component={withAdminHeader(AdminProductOfferingPage)} />
                    <Route exact strict path="/admin/product/checkout/:productId" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <AdminCheckoutPage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/subscription-complete" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <AdminSubscriptionSuccessPage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/personal-info-non-member" component={withAdminHeader(AdminPersonalNonMemberInfoPage)} />
                    <Route exact strict path="/admin/non-member/product/:id" component={withAdminHeader(AdminNonMemberProductOfferingPage)} />
                    <Route exact strict path="/admin/non-member/product/checkout/:productId" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <AdminNonMemberCheckoutPage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/non-member/subscription-complete" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <AdminNonMemberSubscriptionSuccessPage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/logout/success" component={withAdminHeader(AdminLogoutSuccessPage)} />
                    <Route exact strict path="/admin/update-payment" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <AdminUpdatePaymentMethodPage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/experian-alerts" component={withAdminHeader(ExperianAlertsPage)} />
                    <Route exact strict path="/admin/experian/cyber-agent-alert" component={withAdminHeader(ExperianCyberAgentReportPage)} />
                    <Route path="/admin/bundle" component={AdminBundleRoutes} />
                    <Route exact strict path="/admin/:userType/personal-information" component={withAdminHeader(AdminPersonalInfoPage)} />
                    <Route exact strict path="/admin/:userType/checkout/product/:id" component={withAdminHeader(AdminProductOfferingPage)} />
                    <Route exact strict path="/admin/:userType/checkout/:productId/payment" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <AdminCheckoutPage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/:userType/checkout/subscription-complete" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <AdminSubscriptionSuccessPage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/:userType/checkout/:subscriptionApplicationId/account-already-exist" component={withAdminHeader(AdminAccountAlreadyExist)} />
                    <Route exact strict path="/admin/:userType/checkout/:subscriptionApplicationId/cancelled-idc-account-exist" component={withAdminHeader(AdminCancelledIdcAccountExist)} />
                    <Route exact strict path="/admin/:userType/checkout/:subscriptionApplicationId/idc-account-already-exist" component={withAdminHeader(AdminIdcAccountAlreadyExist)} />
                    <Route exact strict path="/admin/:userType/checkout/:subscriptionApplicationId/aaa-membership-exist" component={withAdminHeader(AdminAAAMembershipExist)} />
                </AdminProvider>
            </AdminNotificationProvider>
        );
    }
}

export default AdminRoutes;