import React from 'react';
import moment from 'moment';
import { colors } from '../../styles/common';
import { removeLeadingZerosForSingleDigitDate } from '../common/Helpers';
import Currency from '../common/Currency';
import DynamicParagraphText from '../elements/DynamicParagraphText';

const styles = {
    promoCode: {marginTop: '0px', marginBottom: '0px', fontStyle: 'italic'}
};
export const getPromoCodeString = (promoCodeData) => {
    const { promoCode } = promoCodeData || {};
    return (
        <>
            <DynamicParagraphText desktopFontSize='17px' style={styles.promoCode} text={`${promoCode} Promo`}/>
        </>
    )
}

export function createSummaryItems(product, context, flags = {}) {
    const { userDetails, promoCodeData } = context || {};
    const { creditCardNumber, creditCardType } = userDetails?.subscription?.defaultPaymentMethod || {};
    const lastFourCardNumberDigit = creditCardNumber?.substr(creditCardNumber?.length - 4);
    const { subscription } = userDetails || {};
    const freeTrialAmount = flags.showPaidInvoiceAmount ? subscription?.paidInvoiceAmount?.freeTrialAmount : product?.freeTrialAmount;
    const nextPaymentDate = freeTrialAmount < 0 ? moment(product.freeTrialEndDate)?.format('MM/DD/YYYY') : moment(product.coverageThrough)?.format('MM/DD/YYYY');
    const { lastPaymentAmount } = userDetails?.subscription || {};
    const { basePrice, discount, actualPrice, actualPriceWithoutPromoCode } = flags.showPaidInvoiceAmount ? subscription?.paidInvoiceAmount : product || {};
    const nextPaymentAmount = product?.nextPaymentAmountPreviewWithPromocode ? product?.actualPrice?.total : product?.actualPriceWithoutPromoCode?.total;
    let taxAmount = actualPrice?.tax;
    if (freeTrialAmount < 0) {
        taxAmount = flags.showPaidInvoiceAmount ? subscription?.actualPrice?.tax : actualPriceWithoutPromoCode?.tax;
    }
    let promoCodeAmount = '';
    if (promoCodeData) {
        promoCodeAmount = promoCodeData.discountAmount;
    }
    const items = {
        topSection: [
            {
                key: 'Coverage Plan',
                value: product.name,
            },
            {
                key: 'Payment Term',
                value: product.paymentMode === 'Annually' ? 'Annual' : 'Monthly',
            }
        ],
        middleSection: [
            {
                key: product.paymentMode === 'Monthly' ? 'Base Price (Monthly)' : 'Base Price (Annual)',
                value: <Currency value={basePrice?.price} />,
            }
        ],
        promoCode: [
            {
                key: getPromoCodeString(promoCodeData),
                value: flags.showPromoFromSubscription && subscription?.promoCode ? <Currency value={subscription?.promoCode?.price} /> : <Currency value={promoCodeAmount} />,
                style: { fontStyle: 'italic' }
            },
        ],
        grandTotal: [
            {
                key: flags.isSubscriptionComplete ? 'Paid' : 'Total Due Today',
                value: !flags.showRefundAmount ? <Currency value={actualPrice?.total} /> : <Currency value={actualPrice?.total - product.totalRefundAmount} />,
                style: { color: colors.blue, fontWeight: 'bold', fontSize: '22px' },
            }
        ],
        totalPaidAmount: [
            {
                key: 'Paid',
                value: <Currency value={lastPaymentAmount} /> ,
                style: { color: colors.blue, fontWeight: 'bold', fontSize: '22px' },
            }
        ],
        refundAmount: [
            {
                key: 'Credit from previous subscription',
                value: <>-{!flags.showCreditFromPreviousSubscription ? <Currency value={product.totalRefundAmount} /> : <Currency value={actualPrice?.total - lastPaymentAmount}/>}</>,
                style: { fontStyle: 'italic' }
            }
        ],
        paymentMode: [
            {
                key: 'Payment Method',
                value: `${creditCardType} **** **** **** ${lastFourCardNumberDigit}`
            }
        ],
        bottomSection: [
            {
                key: 'Next Payment Date',
                value: removeLeadingZerosForSingleDigitDate(moment(nextPaymentDate)?.format('MM/DD/YYYY')),
                style: { fontStyle: 'italic' }
            },
            {
                key: 'Next Payment',
                value: <Currency value={nextPaymentAmount} /> ,
                style: { fontStyle: 'italic' }
            }
        ]
    };
    if(taxAmount > 0) {
        items.middleSection.push({
            key: 'Taxes',
            value: <Currency value={taxAmount} />,
        });
    }
    if (Math.abs(discount?.price) > 0) {
        items.middleSection.push({
            key: 'AAA Member Discount',
            value: <Currency value={discount?.price} />,
            style: { fontStyle: 'italic' }
        });
    }
    if (freeTrialAmount < 0) {
        items.middleSection.push({
            key: '1 Month Free Trial',
            value: <Currency value={freeTrialAmount} />,
            style: { fontStyle: 'italic' }
        });
    }
    return items;
}