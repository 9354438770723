import React from 'react';
import { Api, segmentAnalytics } from '../configuration/ApplicationContext';
import ResponsiveSignupFlowLayout, {SignUpFlowFormContent, SignUpFlowExtraContent} from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Title from './elements/Title';
import PersonalInfoForm from './forms/PersonalInfoForm/PersonalInfoForm';
import moment from 'moment';
import DataContext from './contexts/DataContext';
import DynamicParagraphText from './elements/DynamicParagraphText';
import Spinner from './elements/Spinner';
import RedirectionService from '../services/RedirectionService';
import { NotificationTypes } from './notifications/Notifications';
import BasePersonalDetailPage from './BasePersonalDetailPage';
import withPageTitle from "./layout/withPageTitle";
import Breadcrumbs from './common/Breadcrumbs';

const breadCrumbRoutes = [
    {
        to: '/edit-personal-info', label: 'Edit Sign In & Personal Details'
    }
]
class EditPersonalInfoPage extends BasePersonalDetailPage {
    static contextType = DataContext;
    constructor(props) {
        super(props);
        this.state = {
            apiErrors: [],
        };
    }

    componentDidMount() {
        this.checkUserStateAndRedirect();
        this.loadContent();
        this.populateAddress(this.context.userDetails?.personalInformation);
    }

    onSubmit = async (formData) => {
        const { addNotification } = this.props.notificationContext;
        if (Object.keys(formData.errors).length > 0) {
            return;
        }
        const { firstName, middleName, lastName, email, dob, phoneNumber, additionalHomeInfo, additionalBillingInfo} = formData.values;
        const homeAddress = {...this.state.homeAddress, lineTwo: additionalHomeInfo};
        const billingAddress = {...this.state.billingAddress, lineTwo: additionalBillingInfo};
        const billingAddressSameAsHomeAddress = this.state.billingAddressSameAsHomeAddress;
        const apiPayload = {
            firstName,
            middleName,
            lastName,
            email,
            dob: dob !== '' ?  moment(dob).format('YYYY-MM-DD') : '',
            phoneNumber: phoneNumber.replace(/[^\d]/g, ''),
            homeAddress,
            billingAddress: billingAddressSameAsHomeAddress ? homeAddress : billingAddress,
            billingAddressSameAsHomeAddress
        };
        this.setState({
            isSubmitDisabled: true
        });
        const response = await Api.put('/api/personal-information', apiPayload);
        if (!response) {
            this.setState({
                isSubmitDisabled: false
            });
            return;
        }
        if (response.validationErrors) {
            const apiErrors = response.validationErrors.map(apiError => {
                return apiError.message
            });
            this.setState({
                apiErrors,
                isSubmitDisabled: false
            });
        } else if (response.message) {
            this.setState({
                apiErrors: [response.message],
                isSubmitDisabled: false
            });
        } else {
            let userDetails = this.context.userDetails;
            userDetails.personalInformation = response;
            this.context.updateState({ 
                userDetails: userDetails
            });
            await this.props.notificationContext.getNotifications();
            await addNotification(NotificationTypes.PERSONAL_INFORMATION_UPDATE);
            this.props.history.push('/my-account');
            segmentAnalytics.checkForOptOutStatus(email);
        }
    };

    checkUserStateAndRedirect() {
        const { personalInformation, subscription} = this.context.userDetails;
        if (!(personalInformation && subscription)) {
            this.props.history.push(RedirectionService.getConditionalRenderURL(null, this.context.userDetails));
        }
    }

    render() {
        const personalInformation = this.context.userDetails.personalInformation || '';
        const { content, isSubmitDisabled } = this.state;
        if (!content || !personalInformation) {
            return <Spinner />;
        }
        return (
            <>
                <Breadcrumbs breadCrumbRoutes={breadCrumbRoutes}></Breadcrumbs>
                <Content>
                    <ResponsiveSignupFlowLayout>
                        <SignUpFlowFormContent>
                            <Title desktopStyles={{ marginTop: '0'}} mobileStyles={{ marginTop: '3rem' }}>{content.title}</Title>
                            <DynamicParagraphText desktopFontSize='17px' mobileFontSize='14px' text={content.description}></DynamicParagraphText>
                            <PersonalInfoForm onSubmit={this.onSubmit} onSelectAddress={this.onSelectAddress} onSelectBillingAddress={this.onSelectBillingAddress} isEditPersonalInfo='true'
                                                onBillingAddressSameAsHomeAddress={this.onBillingAddressSameAsHomeAddress} 
                                                apiErrors={this.state.apiErrors} personalInformation={personalInformation} 
                                                content={content}
                                                onCancel={() => {this.props.history.push('/my-account'); this.resetMessage() }}
                                                onChangePassword={() => {}}
                                                isSubmitDisabled={isSubmitDisabled}/>
                        </SignUpFlowFormContent>
                        <SignUpFlowExtraContent />
                    </ResponsiveSignupFlowLayout>
                </Content>
            </>
        );
    }
}

export default withPageTitle(EditPersonalInfoPage, "Edit Personal Information");
