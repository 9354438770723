import React from 'react';
import styled from 'styled-components';

const LandingPageLayoutContainer = styled.div`
    display: block;
`;

const ResponsiveLandingPageLayout  = ({children}) => {
    return (
        <LandingPageLayoutContainer>
            {children}
        </LandingPageLayoutContainer>
    )
};

export default ResponsiveLandingPageLayout;
