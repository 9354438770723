import { Component } from "react";
import { Api } from "../configuration/ApplicationContext";
import { buildUrl, capitalizeFirstLetter, getIndividualPromoCodeNameValueFromSessionStorage, getPromoCodeNameFromRedirectParams, subscriptionApplicationIdSessionStorage } from "./common/Helpers";
import UtilsService from '../services/UtilsService';
import { ANNUALLY, CANCELLED, FREE_UPPER_CASE, MONTHLY, PRODUCTS_WITHOUT_PROMO_CODES } from "./common/Constants";

export default class BasePromoCodeComponent extends Component {
    applyPromoCodeFromSessionStorage = async () => {
        let promoCode = getIndividualPromoCodeNameValueFromSessionStorage()?.trim()?.toUpperCase() || '';
        if (!promoCode.match(/^[a-z0-9]+$/i)) {
            this.setState({
                isPromoCodeProcessing: false
            });
            return;
        }
        this.setState({ isPromoCodeProcessing: true });
        const userId = this.context?.userDetails?.userId || null;
        const productId = this.props.match.params.ratePlanId || this.props.match.params.id;
        const apiPayload = {
            promoCode: promoCode,
            productRatePlanId: productId,
            userId
        };
        const { userDetails } = this.context || {};
        const isNonAaaMember = (
            !this.context.isLoggedIn
            || (userDetails?.membership?.membershipStatus === CANCELLED
                || userDetails.invalidMembershipNumber
                || !userDetails.membershipId)
        );
        const promoCodeValidateURL = `/api/promo-codes/validate?isNonAaaMember=${isNonAaaMember}`;
        const response = await Api.post(promoCodeValidateURL, apiPayload);
        if (!response || response.validationErrors) {
            this.context.removePromoCodeData();
            this.setState({ isPromoCodeProcessing: false });
            const productsWithoutPromoCodes = JSON.parse(localStorage.getItem(PRODUCTS_WITHOUT_PROMO_CODES));
            this.handleApplyPromoCode({ promoCode, products: productsWithoutPromoCodes });
            return;
        }
        const params = {
            'promo-code': promoCode,
            isNonAaaMember
        }
        const ratePlanURL = buildUrl(this.generateZuoraPlansURL(), params);
        const ratePlanResponse = await Api.get(ratePlanURL);
        this.setState({ isPromoCodeProcessing: false });
        const { id, type, amount, billingPeriod, effectiveEndDate, customFields } = response || {};
        const product = this.findAppliedPromoCodeProduct(ratePlanResponse.products);
        this.context.setPromoCodeData({
            promoCodeRatePlanId: id,
            promoCode,
            type,
            amount,
            billingPeriod,
            effectiveEndDate,
            promoCodeCustomDescription: customFields?.Description?.value || '',
            discountAmount: product?.promoCode?.price
        });
        this.handleApplyPromoCode({ promoCode, products: ratePlanResponse.products });
    }

    findAppliedPromoCodeProduct(products) {
        const productId = this.props.match.params.ratePlanId || this.props.match.params.id;
        const mappedProducts = UtilsService.mapProductsToArray(products);
        const foundProduct = mappedProducts.find(product => product.id === productId);
        const selectedProduct = foundProduct ? foundProduct : {};
        return selectedProduct;
    }

    handleApplyPromoCode = async (promoCodeData) => {
        this.setState({ promoCodeData });
        const productId = this.props.match.params.ratePlanId || this.props.match.params.id;
        if (promoCodeData.products) {
            const product = promoCodeData.products.find(product => product.name !== FREE_UPPER_CASE
                && (product.annualRatePlan.id === productId
                    || product.monthlyRatePlan.id === productId));
            this.selectProductAndUpdateState(product, productId);
        }
    }
    selectProductAndUpdateState(product, productId) {
        const selectedProduct = product.annualRatePlan.id === productId ? product.annualRatePlan : product.monthlyRatePlan;
        selectedProduct.paymentMode = product.annualRatePlan.id === productId ? ANNUALLY : MONTHLY;
        selectedProduct.name = capitalizeFirstLetter(product.name);
        selectedProduct.contentfulProductOfferingId = product.contentfulProductOfferingId;
        selectedProduct.totalRefundAmount = product.totalRefundAmount;
        this.context.updateState({ product: selectedProduct });
        this.setState({ selectedProduct });
    }
    generateZuoraPlansURL = () => {
        let params = {};
        const { userDetails } = this.context || {};
        const membershipStatus = userDetails?.membership?.membershipStatus;
        if (!this.context.isLoggedIn) {
            params.isNonAaaMember = true;
            const subscriptionApplicationId = sessionStorage.getItem(subscriptionApplicationIdSessionStorage);
            if (subscriptionApplicationId) {
                params['subscription-application-id'] = subscriptionApplicationId;
            }
        } else {
            if (membershipStatus === CANCELLED || userDetails.invalidMembershipNumber || !userDetails.membershipId) {
                params.isNonAaaMember = true;
            }
        }
        return buildUrl('/api/products/zuora-rate-plans', params);
    }
}