import React from 'react';
import styled from 'styled-components';
import { colors, desktop } from '../../styles/common';
import { useHistory } from 'react-router';
import { segmentAnalytics } from '../../configuration/ApplicationContext';
import ProductChartUpperSection from './../v1/product-chart/customer/ProductChartUpperSection';
import BundleProductChartMiddleSection from './../v1/product-chart/BundleProductChartMiddleSection';
import BundleProductChartBottomSection from './../v1/product-chart/BundleProductChartBottomSection';
import { ANNUAL } from './../common/Constants'

const ChooseYourBundleContainer = styled.div`
    text-align: center;
    max-width: 424px;
    margin-right: 20px;
    background: white;
    border-radius: 10px;
    position: relative;
    ${desktop`
        box-shadow: 0px 3px 25px 8px rgba(0, 0, 0, 0.1);
    `}
`;

const ChooseYourBundleContent = ({ ...props }) => {
    const history = useHistory();

    const trackEventsAndRedirect = () => {
        segmentAnalytics.track('Bundle-SUF-Choose-Plan', {
            tierselected: props.title?.toLowerCase(),
            subscriptionoption: ANNUAL
        });
        props.subscriptionApplicationId ? history.push(`/admin/bundle/${props.subscriptionApplicationId}/checkout/${props.title?.toLowerCase()}/payment`) :
            history.push(`/bundle/checkout/${props.title?.toLowerCase()}/personal-info`);
    }

    return (
        <ChooseYourBundleContainer>
            <ProductChartUpperSection
                title={props.title}
                productMonitoringTag={props.productMonitoringTag}
            />
            <BundleProductChartMiddleSection
                title={props.title}
                commonDataDetails={props.commonDataDetails}
                annualAmount={props.annualAmount}
                trackEventsAndRedirect={trackEventsAndRedirect}
            />
            <BundleProductChartBottomSection
                productFeatures={props.productFeatures}
                title={props.title}
            />
        </ChooseYourBundleContainer>
    );
};

export default ChooseYourBundleContent;