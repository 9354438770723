import {createClient} from 'contentful';
import ApiService from '../services/ApiService';
import AdminApiService from '../services/AdminApiService';
import ToastService from '../services/ToastService';
import SegmentAnalytics from '../configuration/SegmentAnalytics';

let variation = {
    productPageType: 'THREE_UP',
    freeTrial: false
};
const {search} = window.location;
const params = new URLSearchParams(search);
if (params.get('productPageType')) {
    variation.productPageType = params.get('productPageType')
}
if(params.get('freeTrial')) {
    variation.freeTrial = params.get('freeTrial') === 'true';
}
const Api = new ApiService(process.env.REACT_APP_API_BASE_URL);
const AdminApi = new AdminApiService(process.env.REACT_APP_API_BASE_URL);
const Toast = new ToastService();
const ccpaBaseUrl = process.env.REACT_APP_CCPA_BASE_URL;
const ContentfulService = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
});
const url = 'calstate.aaa.com';
const sanitizedUrl = encodeURIComponent(url);
const CcpaApi = ccpaBaseUrl.indexOf(sanitizedUrl) >= 0 ? new ApiService(`${ccpaBaseUrl}/endpoints/privacy-rights/v1`) : new ApiService(`${Api.baseUrl}/api/privacy-options`);
const segmentAnalytics = new SegmentAnalytics(process.env.REACT_APP_SEGMENT_API_KEY);
const blogUrl = process.env.REACT_APP_BLOG_URL;
const accessibilityUrl = process.env.REACT_APP_ACCESSIBILY_URL;

export { Api, AdminApi, Toast, ContentfulService, segmentAnalytics, ccpaBaseUrl, CcpaApi, variation, blogUrl, accessibilityUrl }
