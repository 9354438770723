import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';
import ContentfulIds from '../../../configuration/ContentfulIds';
import EmailForm from '../../bundle/EmailForm';
import Breadcrumbs from '../../common/Breadcrumbs';
import AdminContext from '../../contexts/AdminContext';
import DynamicParagraphText from '../../elements/DynamicParagraphText';
import Link from '../../elements/Link';
import RichText from '../../elements/RichText';
import Spinner from '../../elements/Spinner';
import Title from '../../elements/Title';
import Content from '../../layout/Content';
import ResponsiveSignupFlowLayout, { SignUpFlowExtraContent, SignUpFlowFormContent } from '../../layout/ResponsiveSignupFlowLayout';
import withPageTitle from '../../layout/withPageTitle';
import BaseAdminBundleSignup from '../BaseAdminBundleSignup';

const titleStyle = { lineHeight: 'unset', marginTop: 0, fontSize: '32px' };
const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText id="rich-text" {...props} text={children} />;
const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle style={{ fontSize: '17px' }}>{children}</DynamicParagraphStyle>,
    },
};

class AdminAccountAlreadyExist extends BaseAdminBundleSignup {
    static contextType = AdminContext;

    componentDidMount() {
        this.loadContent(ContentfulIds.Admin.Bundle.AccountAlreadyExist);
        this.setBreadCrumb('account-already-exist');
    }

    render() {
        const { content, isSubmitDisabled, apiErrors, breadCrumbRoutes, email, isSearching } = this.state || {};
        if (!content || isSearching) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{ marginTop: '0rem' }}>
                <Breadcrumbs breadCrumbRoutes={breadCrumbRoutes} isCustomerFacing={false} ></Breadcrumbs>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title style={titleStyle}>{content.title}</Title>
                        <RichText text={content.description} options={options} />
                        <EmailForm content={content} isSubmitDisabled={isSubmitDisabled} apiErrors={apiErrors} onSubmit={this.onSubmit} />
                        <Link to='#' onClick={this.getSearchResults} style={{ marginTop: '2rem' }} text={`View Accounts Associated With ${email}`} />
                        <Link style={{ marginTop: '2rem' }} text={`Back to Dashboard`} to={`/admin/search?login-id=${this.context.getLoginId()}`} />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageTitle(AdminAccountAlreadyExist, "Admin Account Already Exist");