import React from 'react';
import styled from 'styled-components';
import { colors, mobile } from '../../styles/common';
import Section from '../elements/Section';
import MemberFeedbackContent from './MemberFeedbackContent';
import JoinNowSection from '../JoinNowSection';

const MemberFeedbackDetailsSection = styled.div`
    padding: 28px 100px;
    background: ${colors.lightGrey};
    text-align: center;
    ${mobile`
        padding: 28px 50px;
    `}
`;

const trustedByAAAMembersStyle = {
    color: `${colors.darkBlue}`,
    padding: '0px 0px 40px 0px'
};

const MemberFeedbackContentSection = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    ${ mobile`
        flex-direction: column;
    `}
`;

const MemberFeedback = ({ fields, onSignup }) => {
    const { title, feedbacks } = fields;
    return (
        <MemberFeedbackDetailsSection>
            <Section text={title} desktopStyles={{ fontSize: '36px', lineHeight: '48px' }} mobileStyles={{ fontSize: '24px', lineHeight: '32px' }} style={trustedByAAAMembersStyle} ></Section>
            <MemberFeedbackContentSection>
                {
                    feedbacks.map(feedback =>
                        <MemberFeedbackContent key={feedback.sys.id} src={`https:${feedback.fields.image.fields.file.url}`} name={feedback.fields.name} title={feedback.fields.title} description={feedback.fields.description} />
                    )
                }
            </MemberFeedbackContentSection>
            <JoinNowSection onSignup={() => onSignup("Home Page | Bottom CTA Clicked")} />
        </MemberFeedbackDetailsSection>
    );
};

export default MemberFeedback;