import React, {Component} from 'react';
import ZipgateWrapper from './../ZipgateWrapper';
import ZipCodeService from "./../../services/ZipCodeService";

const withZipGate = (WrappedComponent) => {
  const ComponentWithZipGate = class extends Component {
    constructor(props) {
      super(props);
      this.ZipgateWrapperRef = React.createRef();
      this.openZipModal = this.openZipModal.bind(this);
    }

    openZipModal() {
      this.ZipgateWrapperRef.current.displayModal();
      ZipCodeService.trackPopUp();
    }

    render() {
      return (
        <ZipgateWrapper ref={this.ZipgateWrapperRef} {...this.props}>
          <WrappedComponent {...this.props} openZipModal={this.openZipModal} />
        </ZipgateWrapper>
      );
    }
  };

  return ComponentWithZipGate;
};

export default withZipGate;
