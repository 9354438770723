import React, {useEffect} from 'react';

const STATIC_TITLE = 'AAA® Identity Champion';
const withPageTitle = (WrappedComponent, title, addStaticTitle = true) => {

    return (props) => {
        useEffect(() => {
            const titleAvailable= addStaticTitle === true ? `${title} | ${STATIC_TITLE}`: title;
            document.title = title ? titleAvailable : STATIC_TITLE ;
        });
        return (
            <WrappedComponent {...props}  />
        );
    };
};

export default withPageTitle;
