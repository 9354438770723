import { Component } from "react";
import { ContentfulService, Api } from "../configuration/ApplicationContext";

export default class BaseLogin extends Component {
    async loadContent(contentfulId) {
        const response = await ContentfulService.getEntries({ 'sys.id': contentfulId, include: 5 });
        this.setState({
            content: response.items[0].fields
        });
        if (this.state.loadUrl) {
            this.props.history.push(this.state.loadUrl);
        }
    }

    async loadDisclaimerContent(contentfulId) {
        const response = await ContentfulService.getEntries({ 'sys.id': contentfulId, include: 5 });
        this.setState({
            disclaimerContent: response.items[0].fields
        });
    }

    onLogin = async () => {
        await this.checkLoggedInUser();
        await Api.login();
    };

    checkLoggedInUser = async () => {
        const response = await Api.get("/api/users/user/logged-in", true);
        if (response && response.status === 200) {
            sessionStorage.setItem("isAlreadyLoggedIn", true);
        }
    };

    onSignup = async () => {
        await this.checkLoggedInUser();
        this.props.history.push(`/product/`);
    };
}