import React from 'react';
import styled, { css } from 'styled-components';
import { colors, mobile } from '../../../../styles/common';
import { COMPLETE, PROTECT } from '../../../common/Constants';

const UpperSection = styled.div`
    height: 50px;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: center;
    position: relative;
    ${props => props.backgroundcolor && css`
        background: ${props.backgroundcolor};
    `}
    ${mobile`
        height: 20px;
    `}
`;

const MonitoringTag = styled.div`
    font-size: 18px;
    line-height: 30px;
    padding: 4px 26px;
    font-weight: 500;
    position: absolute;
    top: 26px;
    max-width: 70%;
    ${props => props.backgroundcolor && css`
        background: ${props.backgroundcolor};
    `}
    ${props => props.color && css`
        color: ${props.color};
    `}
    ${mobile`
        font-size: 8px;
        line-height: 16px;
        top: 13px;
        padding: 0px 10px;
    `}
`;
const ProductChartUpperSection = ({ ...props }) => {
    const { title, productMonitoringTag } = props || {};
    const getBackgroundColorBasedOnTitle = () => {
        if (title === PROTECT) {
            return colors.mediumBlue;
        } else if (title === COMPLETE) {
            return colors.darkBlue;
        }
        return colors.skyBlue;
    }
    const getMonitoringTagTextColor = () => {
        if (title === PROTECT) {
            return colors.darkBlue;
        } else if (title === COMPLETE) {
            return colors.pureWhite;
        }
        return null;
    }
    const getMonitoringTagBackgroundColor = () => {
        if (title === PROTECT) {
            return colors.sandyBrown;
        } else if (title === COMPLETE) {
            return colors.tomato;
        }
        return null;
    }
    return (
        <UpperSection
            backgroundcolor={getBackgroundColorBasedOnTitle()}>
            <MonitoringTag
                color={getMonitoringTagTextColor()}
                backgroundcolor={getMonitoringTagBackgroundColor()}>
                {productMonitoringTag}
            </MonitoringTag>
        </UpperSection>
    );
}
export default ProductChartUpperSection;