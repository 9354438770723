import BaseApiService from './BaseApiService';
class ApiService extends BaseApiService {
  constructor(baseUrl) {
    super();
    this.baseUrl = baseUrl;
  }

  async login(intent = 'login', extraParams = {}) {
    const allParams = {intent, ...extraParams};
    const url = new URL('/api/login', this.baseUrl);
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(allParams)) {
      params.append(key, value);
    }
    window.location.href = this.resolve(`${url.pathname}?${params.toString()}`);
  }
}
export default ApiService;
