import { ACTIVE, CANCELLED, FREE_UPPER_CASE } from "../components/common/Constants";
import { getRedirectValueFromLocalStorage } from "../components/common/Helpers";

class RedirectionService {
    static outstandingBalanceRedirection = (membershipStatus, subscription) => {
        const { subscriptionStatus, outstandingBalance, productType } = subscription || {};
        if (membershipStatus !== CANCELLED && productType !== FREE_UPPER_CASE && outstandingBalance > 0 && subscriptionStatus === ACTIVE) {
            return '/payment-due';
        } else if (membershipStatus !== CANCELLED && productType !== FREE_UPPER_CASE && outstandingBalance > 0 && subscriptionStatus === CANCELLED) {
            return '/my-account';
        } else if (membershipStatus === CANCELLED && productType !== FREE_UPPER_CASE && outstandingBalance > 0 && subscriptionStatus === ACTIVE) {
            return '/payment-due';
        } else if (membershipStatus === CANCELLED && productType !== FREE_UPPER_CASE && outstandingBalance > 0 && subscriptionStatus === CANCELLED) {
            return '/pay-outstanding-balance?notification=true';
        }
        return null;
    }

    static getConditionalRenderURL = (intent, userDetails, ratePlan = null) => {
        const isAlreadyLoggedIn = sessionStorage.getItem('isAlreadyLoggedIn');
        const { subscription, authenticated, membership, aaajoinFlowAutoEnrolledUser, graceMembershipNumber } = userDetails;
        const { membershipStatus } = membership ? membership : {};
        if (authenticated === false) {
            return `/verify-identity`;
        }
        if (aaajoinFlowAutoEnrolledUser) {
            return `/aaa-join-flow/auto-enrollment`;
        }
        const redirectionOutstandingBalance = this.outstandingBalanceRedirection(membershipStatus, subscription);
        if (redirectionOutstandingBalance) {
            return redirectionOutstandingBalance;
        } else if (isAlreadyLoggedIn && subscription) {
            sessionStorage.removeItem('isAlreadyLoggedIn');
            window.location.href = process.env.REACT_APP_EXPERIAN_SSO_URL;
        } else if (intent === 'signup' && subscription) {
            return `/already-activated-subscription`;
        } else if (intent === 'login' && subscription && getRedirectValueFromLocalStorage()?.toLowerCase() === 'upgrade') {
            return `/my-account`;
        } else if (intent === 'login' && subscription && graceMembershipNumber) {
            return `/grace-membership`;
        } else if (intent === 'login' && subscription) {
            window.location.href = process.env.REACT_APP_EXPERIAN_SSO_URL;
        } else if (subscription) {
            window.location.href = process.env.REACT_APP_EXPERIAN_SSO_URL;
        } else {
            switch (membershipStatus) {
                case ACTIVE:
                    return this.generateRedirectURL(ratePlan);
                case CANCELLED:
                    return `/inactive-membership`;
                default:
                    return this.generateRedirectURL(ratePlan);
            }
        }
    }

    static generateRedirectURL = (ratePlan) => {
        return ratePlan ? `/checkout/${ratePlan}/personal-info` : `/product/`;
    }
}

export default RedirectionService;