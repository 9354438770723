import React, { useContext } from 'react';
import RichText from '../elements/RichText';
import Title from '../elements/Title';
import Link from '../elements/Link';
import { BLOCKS } from '@contentful/rich-text-types';
import SubmitButton from '../elements/SubmitButton';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import DataContext from '../contexts/DataContext';

const BasicCoverageDetails = ({ content, redirectToPlan, isSubmitDisabled, onSubmit }) => {
    const context = useContext(DataContext);
    const textStyle = {
        margin: '1.5rem 0rem'
    }

    const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children}/>;

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle desktopFontSize='17px' mobileFontSize='14px'>{children}</ParagraphStyle>,
        },
    };

    return (
        <>
            <Title style={textStyle}>{content.name}</Title>
            <RichText text={content.description} options={options}/>
            <SubmitButton text={content.buttonText} onClick={onSubmit} disabled={isSubmitDisabled}/>
            <Link style={{fontSize: '14px'}} textalign="center" to="#" extratopspace="1rem" extrabottomspace="1rem" extraleftspace="3.5rem" text="More about Protect Coverage" onClick={() => redirectToPlan(context.ratePlanIds.protect.annualRatePlanId)} />
            <Link style={{fontSize: '14px'}} textalign="center" to="#" extratopspace="1rem" extrabottomspace="1rem" extraleftspace="3rem" text="More about Complete Coverage" onClick={() => redirectToPlan(context.ratePlanIds.complete.annualRatePlanId)} />
        </>
    )
};




export default BasicCoverageDetails;