import React from 'react'
import styled, { css }from "styled-components";
import Title from "../elements/Title";
import { colors, mobile, desktop } from "../../styles/common";
import RichText from '../elements/RichText';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import Span from '../elements/Span';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

const TestimonialSection = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    margin-bottom: 30px;
     ${mobile`
        flex-direction: column;
    `}
    ${props => props.headlineBackground && css`
       background: ${colors.lightGrey};
       margin-bottom: 0px;
   `}  
`;
const TestimonialBox = styled.div`
    background: ${colors.snow};
    ${props => props.subHeadlineBackground && css`
       background: ${colors.pureWhite};
   `}
    border: 1px solid ${colors.mediumGrey};
    width: 29.333%;
    ${mobile`
    width: 90%;
    right: 1%;
    `}
    ${desktop`
    padding: 25px 0px;
    `}
    margin: 20px auto;
    box-sizing: border-box;
    position: relative;
`
const BottomDiv = styled.div`
height:10px;
background: linear-gradient(90deg, ${colors.darkRed} 0%, ${colors.darkBlue} 100%);
position: absolute;
left: 0;
right: 0;
bottom: 0;
`
const paragraphStyle = {
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '17px',
    textAlign: 'center',
    padding: '0 45px'
}
const mobileTitleStyle = {
    fontSize: "24px",
};

const desktopTitleStyle = {
    fontSize: "36px",
    marginBottom: '3.5rem',
    marginTop: '50px'
};
const subDescriptionStyle = {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    textAlign: 'center',
    color: colors.greyText
   }
const Testimonials = ({fields, testimonialsRef, ...props}) => {
    const { title, sections } = fields;
    const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText  {...props} text={children} />;
    const SpanStyle = ({children, ...props}) => <Span {...props} text={children}/>

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle style={paragraphStyle} desktopFontSize='16px' mobileFontSize='14px'>{children}</ParagraphStyle>,
        },
        renderMark: {
            [MARKS.BOLD]: text => <SpanStyle style={subDescriptionStyle}>{text}</SpanStyle>
        }
    };
    return (
        <TestimonialSection ref={testimonialsRef} {...props}>
            <Title mobileStyles={mobileTitleStyle} desktopStyles={desktopTitleStyle} style={{ textAlign: "center", fontWeight: "normal", width: "100%", color: `${colors.darkBlue}` }}>{title}</Title>
            {sections.map((section) => {
                return (
                    <TestimonialBox {...props} key={section.fields.title}>
                        <RichText style={{ padding: "15px" }} text={section.fields.description} options={options} />
                        <ParagraphStyle style={{ ...paragraphStyle, color: colors.darkBlue, fontWeight: 500 }}>{section.fields.title}</ParagraphStyle>
                        <RichText text={section.fields.subDescription} options={options} />
                        <BottomDiv />
                    </TestimonialBox>
                )
            })}
        </TestimonialSection>
    )
}

export default Testimonials
