import React, { useContext } from 'react';
import CardWithTitle from '../elements/CardWithTitle';
import InfoText from '../elements/InfoText';
import AddressDisplayComponent from '../elements/AddressDisplayComponent';
import DataContext from '../contexts/DataContext';
import { formatPhoneNumber } from '../common/Helpers';
import Link from '../elements/Link';
import { colors } from '../../styles/common';

const marginBottom = { marginBottom: '1rem' }
const emailStyle = {
    marginBottom: '1rem',
    wordBreak: 'break-all'
}
const marginTop ={ marginTop: '1rem' }
const PersonalDetails = () => {
    const context = useContext(DataContext);
    const personalInformation = context?.userDetails?.personalInformation;
    if (!personalInformation) {
        return null;
    }
    return (
        <>
            <CardWithTitle title='Sign In & Personal Details' callToAction={<Link to='/edit-personal-info' style={{ color: colors.blue }} text='Edit' />}>
                <InfoText>{personalInformation.displayName}</InfoText>
                <InfoText style={emailStyle}>{personalInformation.email}</InfoText>
                <AddressDisplayComponent address={personalInformation.homeAddress} />
                <InfoText>{formatPhoneNumber(personalInformation.phoneNumber)}</InfoText>
                {!personalInformation.billingAddressSameAsHomeAddress &&
                    <>
                        <InfoText style={marginTop}>Billing Address:</InfoText>
                        <AddressDisplayComponent address={personalInformation.billingAddress} style={marginBottom}/>
                    </>
                }
            </CardWithTitle>
        </>
    )
};

export default PersonalDetails;