import React from 'react';
import { colors } from '../../styles/common';
import styled from 'styled-components';

const ListItem = styled.li`
    font-weight: normal;
    font-size: 18px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    a {
        text-decoration: none;
        text-underline: none;
        color: ${colors.darkBlue};
    }
    :first-child {
        margin-right: 0.5rem;
        margin-left: unset;
    }
    :last-child {
        margin-right: unset;
        margin-left: 0.5rem;
    }
`;

const BreadcrumbItem = ({ children, ...props }) => (
    <ListItem className='breadcrumb-item' {...props}>
      {children}
    </ListItem>
)

export default BreadcrumbItem;