import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';
import { AdminApi } from '../../../configuration/ApplicationContext';
import ContentfulIds from '../../../configuration/ContentfulIds';
import EmailForm from '../../bundle/EmailForm';
import Breadcrumbs from '../../common/Breadcrumbs';
import { removeSubscriptionApplicationIdSessionStorage } from '../../common/Helpers';
import AdminContext from '../../contexts/AdminContext';
import DynamicParagraphText from '../../elements/DynamicParagraphText';
import Link from '../../elements/Link';
import RichText from '../../elements/RichText';
import Spinner from '../../elements/Spinner';
import SubmitButton from '../../elements/SubmitButton';
import Title from '../../elements/Title';
import Content from '../../layout/Content';
import ResponsiveSignupFlowLayout, { SignUpFlowExtraContent, SignUpFlowFormContent } from '../../layout/ResponsiveSignupFlowLayout';
import withPageTitle from '../../layout/withPageTitle';
import BaseAdminBundleSignup from '../BaseAdminBundleSignup';

const titleStyle = { lineHeight: 'unset', marginTop: 0, fontSize: '32px' };
const buttonStyle = { margin: '2em 0em' };
const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText id="rich-text" {...props} text={children} />;
const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle style={{ fontSize: '17px' }}>{children}</DynamicParagraphStyle>,
    },
};

class AdminAAAMembershipExist extends BaseAdminBundleSignup {
    static contextType = AdminContext;
    componentDidMount() {
        this.loadContent(ContentfulIds.Admin.Bundle.AAAMembershipExist);
        this.setBreadCrumb('aaa-membership-exist');
    }

    onSignupMemberClick = async () => {
        this.setState({ isSubmitDisabled: true });
        const { email } = this.state || {};
        const { address, dob, firstName, lastName, middleName, phone } = JSON.parse(localStorage.getItem('searchResult'));
        const response = await AdminApi.get(`/api/users?keyword=${email}`);
        if (response) {
            const { aaaLoginIdentifier, attributeMembershipId, attributeNationalId, idcMembership, isClubSupportedForZip, loginEmail, lowConfidenceActivationPending, membershipExpiryDate, membershipId, membershipSince, userId } = response[0];
            const updatedSearchResult = {
                aaaLoginIdentifier, attributeMembershipId, attributeNationalId, email, idcMembership,
                isClubSupportedForZip, loginEmail, lowConfidenceActivationPending, membershipExpiryDate, membershipId,
                membershipSince, userId, address, dob, firstName, lastName, middleName, phone, 
                setPhone: true, 
                setDob: true
            };
            removeSubscriptionApplicationIdSessionStorage();
            this.context.updateState({ searchResult: updatedSearchResult });
            localStorage.setItem('searchResult', JSON.stringify(updatedSearchResult));
            this.props.history.push(`/admin/personal-info`);
        } else {
            this.setState({ isSubmitDisabled: false });
        }
    }
    render() {
        const { content, isSubmitDisabled, apiErrors, breadCrumbRoutes, email, isSearching } = this.state || {};
        if (!content || isSearching) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{ marginTop: '0rem' }}>
                <Breadcrumbs breadCrumbRoutes={breadCrumbRoutes} isCustomerFacing={false} ></Breadcrumbs>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title style={titleStyle}>{content.title}</Title>
                        <RichText text={content.description} options={options} />
                        <SubmitButton disabled={isSubmitDisabled} style={buttonStyle} text={content.subTitle} to="#" onClick={this.onSignupMemberClick} />
                        <RichText text={content.subDescription} options={options} />
                        <EmailForm content={content} isSubmitDisabled={isSubmitDisabled} apiErrors={apiErrors} onSubmit={this.onSubmit} />
                        <Link to='#' onClick={this.getSearchResults} style={{ marginTop: '2rem' }} text={`View Accounts Associated With ${email}`} />
                        <Link style={{ marginTop: '2rem' }} text={`Back to Dashboard`} to={`/admin/search?login-id=${this.context.getLoginId()}`} />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageTitle(AdminAAAMembershipExist, "Admin AAA Membership Exist");