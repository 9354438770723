import { useState, useEffect, useRef } from "react";

const useCustomForm = ({
                           initialValues,
                           validate = () => {},
                           onSubmit,
                           relaxRequiredValidationsForFields= [],
                           updateMembershipNumber = false,
                           clearCustomError,
                           homeAddress = {},
                           manualAddressEntry = false,
                           billingAddressSameAsHomeAddress = true
                       }) => {
    const [originalValues, setOriginalValues] = useState(initialValues || {});
    const [values, setValues] = useState(initialValues || {});
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [dirty, setDirty] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [onBlur, setOnBlur] = useState(false);
    const formRendered = useRef(true);
    useEffect(() => {
        if (formRendered.current) {
            setOriginalValues(initialValues);
            setValues(initialValues);
            setErrors(validate(initialValues, {}, relaxRequiredValidationsForFields, updateMembershipNumber, manualAddressEntry, billingAddressSameAsHomeAddress));
            setTouched({});
            setDirty({})
            setSubmitted(false);
            setOnBlur(false);
        }
        formRendered.current = false;
    }, [initialValues, validate]);

    useEffect(() => {
        const validationErrors = validate(values, dirty, relaxRequiredValidationsForFields, updateMembershipNumber, homeAddress, manualAddressEntry, billingAddressSameAsHomeAddress);
        setErrors(validationErrors);
    }, [manualAddressEntry, billingAddressSameAsHomeAddress]);
    
    const handleChange = (event) => {
        const { target } = event;
        const { name, value } = target;
        event.persist();
        const originalValue = originalValues[name];
        setValues({ ...values, [name]: value });
        setDirty({...dirty, [name]: originalValue !== value});
        value && clearCustomError && clearCustomError(name);
    };

    const handleBlur = (event) => {
        const { target } = event;
        const { name } = target;
        const validationErrors = validate(values, dirty, relaxRequiredValidationsForFields, updateMembershipNumber, homeAddress, manualAddressEntry, billingAddressSameAsHomeAddress);
        setTouched({ ...touched, [name]: true });
        setErrors(validationErrors);
    };

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        const validationErrors = validate(values, dirty, relaxRequiredValidationsForFields, updateMembershipNumber, homeAddress, manualAddressEntry, billingAddressSameAsHomeAddress);
        setSubmitted(true);
        setErrors(validationErrors);
        setErrors({ ...errors });
        onSubmit({ values, errors });
    };

    return {
        values,
        errors,
        touched,
        submitted,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit,
        onBlur
    };
};

export default useCustomForm;
