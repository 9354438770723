import React from 'react';
import InfoText from '../elements/InfoText';

const AddressDisplayComponent = ({ address }) => {
    return (
        <>
            <InfoText>{address.lineOne}</InfoText>
            <InfoText>{address.lineTwo || ''}</InfoText>
            <InfoText>{(address.city || '') + ', ' + (address.state || '') + ' ' + address.zipCode}</InfoText>    
        </>
    )
}

export default AddressDisplayComponent;
