import React, { useState } from "react";
import PlacesAutoComplete, {geocodeByAddress} from 'react-places-autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import LabeledInput from "./LabeledInput";
import {colors} from '../../styles/common';
import styled, {css} from 'styled-components';

const Suggestion = styled.div`
  width: 100%;
  height: 45px;
  background-color: white;
  border-bottom: 1px black solid;
  display: flex;
  align-items: center;
  font-size: 14px;
  ${props => props.active && css`
  background-color: ${colors.lightGrey}; 
  `}
`;

export const LocationSearchInput = (props) => {
  const [addressLine, setAddressLine] = useState(props.value);
  const handleSelect = async (value) => {
    setAddressLine(value);
    const address = await geocodeByAddress(value);
    if (address && address[0]) {
      const addressComponents = Object.values(address[0].address_components);
      const streetNumberComponent = addressComponents.filter(addressComponent => (addressComponent.types.includes('street_number'))) || '';
      const streetNumber = streetNumberComponent ? streetNumberComponent[0]?.long_name : '';
      const routeComponent = addressComponents.filter(addressComponent => (addressComponent.types.includes('route'))) || '';
      const route = routeComponent ? routeComponent[0]?.long_name : '';
      const cityComponent = addressComponents.filter(addressComponent => (addressComponent.types.includes('locality') || addressComponent.types.includes('administrative_area_level_3')));
      const city = cityComponent ? cityComponent[0]?.long_name : '';
      const stateComponent = addressComponents.filter(addressComponent => addressComponent.types.includes('administrative_area_level_1'));
      const state = stateComponent ? stateComponent[0]?.short_name : '';
      const countryComponent = addressComponents.filter(addressComponent => addressComponent.types.includes('country'));
      const countryCode = countryComponent ? countryComponent[0]?.short_name : '';
      const zipCodeComponent = addressComponents.filter(addressComponent => addressComponent.types.includes('postal_code'));
      const zipCode = zipCodeComponent ? zipCodeComponent[0]?.short_name : '';
      props.onSelectAddress({lineOne: `${streetNumber} ${route}`, city, state, countryCode, zipCode});
    }
  }
  const onError = (status, clearSuggestions) => {
    clearSuggestions();
  }

  const handleChange = (value) => {
    setAddressLine(value);
    props.onChange({
      target: {
        name: props.name,
        value
      },
      persist: () => {},
    })
  };
  return (
    <div>
      <PlacesAutoComplete value={addressLine} onChange={handleChange} onSelect={handleSelect} onError={onError}>
        {({getInputProps, suggestions, getSuggestionItemProps, loading}) =>
          (
            <>
              <LabeledInput {...getInputProps({name: props.name, label: props.label, extratoplabelspace: '0.75rem', extratopspace: '0.5rem', placeholder: "Type Address", onBlur: props.onBlur, error: props.error})} />
              {loading && <div>Loading...</div>}
              <div>
              { (suggestions.filter(suggestion => suggestion.types.includes('street_address') || suggestion.types.includes('premise'))).map((suggestion) => {
                  return <Suggestion {...getSuggestionItemProps(suggestion)} active={suggestion.active}>
                    <FontAwesomeIcon icon={ faMapMarkerAlt } />
                    <span style={{marginLeft: '1rem'}}>{suggestion.description}</span>
                  </Suggestion>
                })}
              </div>
            </>
          )
        }
      </PlacesAutoComplete>
    </div>
  );
};