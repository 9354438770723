import React, { Component } from 'react';
import {ContentfulService, Api} from '../configuration/ApplicationContext';
import ContentfulIds from '../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, {SignUpFlowFormContent, SignUpFlowExtraContent} from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Title from './elements/Title';
import Spinner from './elements/Spinner';
import Link from './elements/Link';
import ParagraphText from '../components/elements/ParagraphText';
import styled from 'styled-components';
import {colors} from '../styles/common';
import DataContext from './contexts/DataContext';
import RedirectionService from '../services/RedirectionService';
import withPageTitle from "./layout/withPageTitle";

const HorizontalLine = styled.hr`
    border: 0.1px solid ${colors.mediumGrey};
    margin-top: 1.5rem;
`;

class UpdateHomeAddressInfoPage extends Component {
    static contextType = DataContext;
    constructor(props) {
        super(props)
        this.state = {
        };
    }

    componentDidMount() {
        this.componentLoad();
    }

    async componentLoad() {
        await this.checkUserStateAndRedirect();
        this.loadContent();
        this.getAddress();
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.UpdateHomeAddressInfo);
        this.setState({content: response.fields});
    }

    getAddress = () => {
        this.setState({
            address : this.context.userDetails
        });
    }

    async checkUserStateAndRedirect() {
        const userDetails = this.context.userDetails;
        const response = await Api.get('/api/products/rate-plans');
        if (response?.products?.length) {
            this.props.history.push(RedirectionService.getConditionalRenderURL(null, userDetails));
        }
    } 
    
    render() {
        const {content} = this.state;
        const personalInfo = this.context.userDetails.personalInformation;
        if (!content) {
            return <Spinner />;
        }
        return (
            <Content>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title style={{marginTop: '1rem', lineHeight: '48px'}}>{content.title}</Title>
                        <ParagraphText style={{fontSize: '17px', lineHeight: '30px', fontWeight: '500'}} >{personalInfo?.homeAddress.lineOne}</ParagraphText>
                        <ParagraphText style={{fontSize: '17px', lineHeight: '30px', fontWeight: '500'}} >{(personalInfo?.homeAddress.lineTwo || '') + ' ' +(personalInfo?.homeAddress.state || '') + ' ' + personalInfo?.homeAddress.zipCode }</ParagraphText>
                        <Link style={{marginTop: '2rem'}} text={content.updateLinkText} onClick={() => this.props.history.push(`/checkout/${this.props.match.params.ratePlanId}/personal-info/update-home-address`)}/>
                        <HorizontalLine />
                        <Title style={{marginTop: '1rem', lineHeight: '48px', color: '#003087'}}>{content.eligibleTitle}</Title>
                        <ParagraphText>{content.eligibleDescription}</ParagraphText>
                        <Link style={{marginTop: '2rem'}} text={content.eligibleButtonText} onClick={() => window.open('https://www.aaa.com/experianidtheft/','_blank')} />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        )
    }
}

export default withPageTitle(UpdateHomeAddressInfoPage, "Not Currently Eligible");
