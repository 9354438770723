import React, { useEffect } from 'react';
import { META_DESCRIPTION } from "./../common/Constants";

const withPageMetaTags = (WrappedComponent, meta = {}) => {
    return (props) => {
        useEffect(() => {
            if (meta.description) {
                const description = document.querySelector("meta[name='description']");
                description.setAttribute('content', meta.description);
            }

            if (meta.noindex === true) {
                const robots = document.querySelector("meta[name='robots']");
                if (robots == null) {
                    let metaTag = document.createElement('meta');
                    metaTag.name = "robots";
                    metaTag.content = "noindex";
                    document.getElementsByTagName('head')[0].appendChild(metaTag);
                } else {
                    robots.setAttribute('content', 'noindex');
                }
            } else {
                const robots = document.querySelector("meta[name='robots']");
                if (robots != null) {
                    robots.remove();
                }
            }

            if (meta.faceBookDomainVerification) {
                const facebook = document.querySelector("meta[name='facebook-domain-verification']");
                if (facebook == null) {
                    let metaTag = document.createElement('meta');
                    metaTag.name = "facebook-domain-verification";
                    metaTag.content = "hcruckw4dqus3flug4p0430xsuadqs";
                    document.getElementsByTagName('head')[0].appendChild(metaTag);
                }
            } else {
                const facebook = document.querySelector("meta[name='facebook-domain-verification']");
                if (facebook != null) {
                    facebook.remove();
                }
            }

        });
        return (
            <WrappedComponent {...props} />
        );
    };
};

export default withPageMetaTags;
