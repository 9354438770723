import React, { Component } from 'react';
import Content from "./layout/Content";
import ResponsiveMyAccountLayout from './layout/ResponsiveMyAccountLayout';
import InfoText from './elements/InfoText';
import Title from './elements/Title';
import CoverageDetails from './myaccount/CoverageDetails';
import PaymentDetails from './myaccount/PaymentDetails';
import PersonalDetails from './myaccount/PersonalDetails';
import OutstandingBalance from './myaccount/OutstandingBalance';
import UpgradesAvailable from './myaccount/UpgradesAvailable';
import DataContext from '../components/contexts/DataContext';
import Spinner from './elements/Spinner';
import { Api, ContentfulService } from '../configuration/ApplicationContext';
import ContentfulIds from '../configuration/ContentfulIds';
import { MyAccountPageType } from './myaccount/MyAccountPageType';
import Notification from './notifications/Notification';
import Breadcrumbs from './common/Breadcrumbs';
import withPageTitle from "./layout/withPageTitle";
import styled from 'styled-components';
import { colors, mobile } from '../styles/common';
import { NotificationTypes } from './notifications/Notifications';
import { ACTIVE, CANCELLED, COMPLETE_UPPER_CASE, FREE_UPPER_CASE, META_DESCRIPTION, PROTECT_UPPER_CASE } from './common/Constants';
import { PAYMENT_EXPIRED_NOTIFICATION_VIEWED, trackEvent } from '../services/SegmentService';
import Masonry from 'react-masonry-css';
import withPageMetaTags from './layout/withPageMetaTags';
import { deleteIndividualPromoCodeNameValueFromSessionStorage, getRedirectValueFromLocalStorage, removeMemberTypeFromSessionStorage } from './common/Helpers';

const WrapperDiv = styled.div`
    ${mobile`
        padding-left: 15px;
    `};
`;

const contentMobileStyles = {
    paddingLeft: '0px',
    paddingRight: '0px'
}

const mobilePaddingStyles = {
    paddingLeft: '15px',
    paddingRight: '15px'
}

class MyAccountPage extends Component {
    static contextType = DataContext;
    state = {
        apiErrors: [],
    };

    componentDidMount() {
        this.checkUserStateAndRedirect();
        this.context.removePromoCodeData();
        this.context.refreshContext();
        this.loadContent();
        this.determinePageType();
        this.setExpiredPageType();
        this.setAccountSetupNotification();
        removeMemberTypeFromSessionStorage();
        deleteIndividualPromoCodeNameValueFromSessionStorage();
    }

    determinePageType() {
        const { subscription } = this.context.userDetails;
        let pageType = '';
        if (subscription?.productType === FREE_UPPER_CASE) {
            pageType = MyAccountPageType.BASIC;
        } else if (subscription?.productType === COMPLETE_UPPER_CASE) {
            pageType = MyAccountPageType.COMPLETE;
        } else if (subscription?.productType === PROTECT_UPPER_CASE) {
            pageType = MyAccountPageType.PROTECT;
        }
        this.setState({ pageType });
    }

    checkUserStateAndRedirect() {
        if (!this.context.isLoggedIn) {
            Api.login();
        } else if (Object.keys(this.context.userDetails).length) {
            const { subscription, membership, authenticated } = this.context.userDetails;
            const membershipStatus = membership?.membershipStatus;
            const { subscriptionStatus, outstandingBalance, productType } = subscription || {};
            const redirectToUpgrade = getRedirectValueFromLocalStorage();
            if (!authenticated) {
                this.props.history.push(`/verify-identity`);
            } else if (membershipStatus === CANCELLED && productType !== FREE_UPPER_CASE && outstandingBalance > 0 && subscriptionStatus === ACTIVE) {
                this.props.history.push('/payment-due');
            } else if (membershipStatus === CANCELLED && productType !== FREE_UPPER_CASE && outstandingBalance > 0 && subscriptionStatus === CANCELLED) {
                this.props.history.push('/pay-outstanding-balance?notification=true');
            } else if (!this.context.userDetails.subscription) {
                switch (membershipStatus) {
                    case ACTIVE:
                        this.props.history.push('/product/');
                        break;
                    case CANCELLED:
                        this.props.history.push('/inactive-membership');
                        break;
                    default:
                        this.props.history.push('/product/');
                }
            } else if (redirectToUpgrade?.toLowerCase() === 'upgrade') {
                this.redirectToUpgradeFlow();
            }
        } else {
            this.props.history.push('/membership-number');
        }
    }
    redirectToUpgradeFlow = () => {
        const {
            ratePlanIds: {
                complete,
                protect
            } = {},
            userDetails: {
                subscription
            } = {}
        } = this.context || {};
        if (subscription.outstandingBalance > 0) {
            this.props.history.push('/pay-outstanding-balance?notification=true&upgrade=true');
        } else {
            if (subscription.productType === FREE_UPPER_CASE) {
                this.props.history.push(`/product/basic/upgrade/${protect.monthlyRatePlanId}`);
            } else if (subscription.productType === MyAccountPageType.PROTECT) {
                subscription.outstandingBalance.billingFrequency==='ANNUALLY' ? 
                this.props.history.push(`/product/protect/upgrade/${complete.annualRatePlanId}`) :
                this.props.history.push(`/product/protect/upgrade/${complete.monthlyRatePlanId}`);
            }
        }
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.MyAccountPage);
        this.setState({ content: response.fields });
    }

    setExpiredPageType = () => {
        const { notifications } = this.props.notificationContext;
        const notificationTypes = notifications.map(notification => {
            return notification.notificationType;
        })
        if (notificationTypes.includes('PAYMENT_ABOUT_TO_EXPIRED')) {
            this.setState({
                pageType: MyAccountPageType.PAYMENT_METHOD_EXPIRING,
            });
        } else if (notificationTypes.includes('PAYMENT_EXPIRED')) {
            trackEvent(PAYMENT_EXPIRED_NOTIFICATION_VIEWED, {
                memberId: this.context.userDetails.userId,
                dateOfPaymentExpiration: this.context.userDetails.subscription.defaultPaymentMethod.expirationMonth + "/" + this.context.userDetails.subscription.defaultPaymentMethod.expirationYear
            });
            this.setState({
                pageType: MyAccountPageType.PAYMENT_METHOD_EXPIRED,
            });
        }
    }

    setAccountSetupNotification = async () => {
        const isAccountSetupComplete = localStorage.getItem('isAccountSetupComplete');
        const { addNotification, removeNotification } = this.props.notificationContext;
        if (isAccountSetupComplete === 'true') {
            await addNotification(NotificationTypes.ACCOUNT_SETUP_COMPLETE);
        } else {
            await removeNotification(NotificationTypes.ACCOUNT_SETUP_COMPLETE);
        }
        localStorage.removeItem('isAccountSetupComplete');
    }

    render() {
        const {
            content,
            pageType
        } = this.state || {};
        const {
            notifications
        } = this.props.notificationContext;
        const {
            subscription,
            personalInformation,
            upcomingSubscriptionProductType
        } = this.context.userDetails || {};
        const breakpointColumnsObj = {
            default: 3,
            1100: 3,
            700: 2,
            500: 1
        };
        if (!content || !subscription || !personalInformation || !pageType) {
            return <Spinner />;
        }
        const masonryFragmentSection = <>
            <OutstandingBalance content={content} />
            <CoverageDetails content={content} />
        </>;
        return (
            <>
                <Breadcrumbs />
                <Content
                    mobileStyles={contentMobileStyles}>
                    <InfoText
                        style={{ marginTop: '2rem' }}>
                        {
                            notifications.map(
                                notification => {
                                    return <Notification
                                        key={notification.notificationType}
                                        {...notification}
                                        {...this.props}
                                    />
                                }
                            )}
                    </InfoText>
                    <Title
                        style={{ marginTop: '3rem', color: colors.darkBlue }}
                        mobileStyles={mobilePaddingStyles}>
                        My Account
                    </Title>
                    <WrapperDiv>
                        <ResponsiveMyAccountLayout>
                            {
                                pageType === MyAccountPageType.BASIC &&
                                <Masonry
                                    breakpointCols={breakpointColumnsObj}
                                    className="masonry-grid"
                                    columnClassName="masonry-grid_column">
                                    {masonryFragmentSection}
                                    <PersonalDetails />
                                    {/* <UpgradesAvailable
                                        pageType={this.state.pageType}
                                        content={content}
                                    /> */}
                                    <PaymentDetails
                                        content={content}
                                        pageType={this.state.pageType}
                                        upcomingSubscriptionProductType={upcomingSubscriptionProductType}
                                    />
                                </Masonry>
                            }
                            {
                                pageType !== MyAccountPageType.BASIC &&
                                <Masonry
                                    breakpointCols={breakpointColumnsObj}
                                    className="masonry-grid"
                                    columnClassName="masonry-grid_column">
                                    {masonryFragmentSection}
                                    <PaymentDetails
                                        content={content}
                                        pageType={this.state.pageType}
                                        upcomingSubscriptionProductType={upcomingSubscriptionProductType}
                                    />
                                    <PersonalDetails />
                                    {/* {subscription.outstandingBalance <= 0 && 
                                        // <UpgradesAvailable
                                        //     pageType={this.state.pageType}
                                        //     content={content}
                                        // />
                                    } */}
                                </Masonry>
                            }
                        </ResponsiveMyAccountLayout>
                    </WrapperDiv>
                </Content>
            </>
        )
    }
}

export default withPageMetaTags(withPageTitle(MyAccountPage, 'My Identity Monitoring Account'), { description: META_DESCRIPTION.MY_ACCOUNT });
