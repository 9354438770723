import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/common';
import { mobile } from '../../styles/common';
import ParagraphText from './ParagraphText';
import Currency from '../common/Currency';

const SelectBoxStyle = styled.div`
   display: flex;
   box-sizing: border-box;
   box-shadow: 0px 2px 4px rgba(203, 203, 203, 0.0642267);
   border-radius: 10px;
   text-align:center;
   width: 45%;
   font-size: 12px;
   font-weight: 500;
   cursor: pointer;
   margin: 1rem 1rem 1rem 0rem;
   display: inline-block;
   padding: 0.5rem;
   ${mobile`
  `}
   ${({ active }) => active ? `
     background:  ${colors.pureWhite};
     border: 2px solid ${colors.darkBlue} ;
     color:${colors.darkBlue}
     `: `
     background:${colors.lightGrey};
     border: 1px solid ${colors.darkGrey};
     color:${colors.contentGrey}
     `}
`;

const FreeTrialMessage = styled.span`
    ${mobile`
       display: block; 
    `} 
`;
const SelectBox = ({ product, active, onSelectProduct, freeTrial = false }) => {
    const currencyValue = freeTrial ? Math.abs(product?.freeTrialAmount) : product?.actualPrice?.price;
    const paymentMode = product.paymentMode === 'Monthly' ? 'Month' : 'Year';
    return (
        <SelectBoxStyle active={active} onClick={() => onSelectProduct(product)}>
            <ParagraphText style={{margin: 'unset', fontSize: '12px', fontWeight: '500'}}>{product.displayName}</ParagraphText>
            {product.name !== "Free" && <ParagraphText style={{margin: 'unset', fontSize: '12px'}}>{<><Currency value={currencyValue}/>/{paymentMode} <FreeTrialMessage>{freeTrial ? 'after free trial' : ''}</FreeTrialMessage></>}</ParagraphText>}
            {product.name === "Free" && <ParagraphText style={{margin: 'unset', fontSize: '12px'}}>{<>Included</>}</ParagraphText>}
        </SelectBoxStyle>
    )
}

export default SelectBox;
 