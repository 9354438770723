import React, { Component } from 'react'
import Title from '../elements/Title';
import { AdminApi } from '../../configuration/ApplicationContext';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from '../layout/ResponsiveSignupFlowLayout';
import Content from '../layout/Content';
import AdminContext from '../contexts/AdminContext';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import Card from '../elements/Card';
import AdminSummary from './summary/AdminSummary';
import Spinner from '../elements/Spinner';
import Breadcrumbs from '../common/Breadcrumbs';
import Link from '../elements/Link';
import { CANCELLED } from '../common/Constants';
export class AdminSubscriptionDowngradePage extends Component {
    static contextType = AdminContext;
    async componentDidMount() {
        await this.props.notificationContext.getNotifications();
        await this.context.getMemberDetails();
        this.setLinks();
        this.setBreadCrumb();
        this.getUserDowngradedProduct();
    }

    setLinks = () => {
        this.setState({
            redirectionLinks: {
                memberAccount: '/admin/member-account',
                adminSearch: `/admin/search?login-id=${this.context.getLoginId()}`
            }
        });
    }

    setBreadCrumb = () => {
        const { personalInformation } = this.context.memberDetails || {};
        const breadCrumbRoutes = [
            {
                to: '/admin/member-account', label: personalInformation.displayName
            },
            {
                to: '#', label: 'Downgrade: Options'
            },
            {
                to: '#', label: 'Downgrade Complete'
            }
        ];
        this.setState({ breadCrumbRoutes });
    }

    async getUserDowngradedProduct() {
        const { membership, invalidMembershipNumber, membershipId } = this.context.memberDetails || {};
        const isInvalidOrCancelledMembership = invalidMembershipNumber || (membership && membership?.membershipStatus === CANCELLED);
        const isNonAaaMember = (!membershipId || isInvalidOrCancelledMembership) && this.state.subscriptionAction !== 'CREATE';
        const response = await AdminApi.get(`/api/users/${this.context.searchResult.userId}/downgrade-rate-plan?isNonAaaMember=${isNonAaaMember}`);
        const ratePlan = response.annualRatePlan !== null ? response.annualRatePlan : response.monthlyRatePlan;
        const mappedProduct = {
            name: response.name?.charAt(0) + response.name?.slice(1).toLowerCase(),
            paymentMode: response.annualRatePlan !== null ? 'Annually' : 'Monthly',
            ...ratePlan,
            contentfulProductOfferingId: response.contentfulProductOfferingId,
            displayName: response?.displayName
        };
        this.setState({ downgradeProduct: mappedProduct });
    }

    render() {
        const {
            breadCrumbRoutes,
            downgradeProduct,
            redirectionLinks
        } = this.state || {};
        const {
            subscription,
            personalInformation
        } = this.context.memberDetails || {};
        if (!downgradeProduct || !redirectionLinks) {
            return <Spinner />;
        }
        return (
            <Content
                desktopStyles={{ marginTop: 0 }}>
                <Breadcrumbs
                    isCustomerFacing={false}
                    breadCrumbRoutes={breadCrumbRoutes}
                />
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title
                            desktopStyles={{ marginTop: 0 }}>
                            Downgrade Complete
                        </Title>
                        <DynamicParagraphText
                            desktopFontSize='17px'
                            text="Downgrade has been completed successfully."
                        />
                        <DynamicParagraphText
                            desktopFontSize='17px'
                            text="The customer will receive an e-mail confirming the downgrade details."
                        />
                        <Link
                            style={{ marginTop: '2.5rem' }}
                            text={`Back to ${personalInformation.displayName}'s Details`}
                            to={redirectionLinks.memberAccount}
                        />
                        <Link
                            style={{ marginTop: '1rem' }}
                            text="Back to Dashboard"
                            to={redirectionLinks.adminSearch}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        <Card>
                            <AdminSummary
                                product={downgradeProduct}
                                title={`Identity Champion ${downgradeProduct?.displayName}`}
                                isDowngradeFlow={true}
                                isPaidTierUpgrade={false}
                                subscription={subscription}
                                isProductUpgraded={false}
                            />
                        </Card>
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        )
    }
}

export default AdminSubscriptionDowngradePage
