import React from 'react';
import moment from 'moment';
import { colors } from '../../../styles/common';
import { removeLeadingZerosForSingleDigitDate } from '../../common/Helpers';
import Currency from '../../common/Currency';
import DynamicParagraphText from '../../elements/DynamicParagraphText';

const styles = {
    promoCode: {marginTop: '0px', marginBottom: '0px', fontStyle: 'italic'}
};
const getPromoCodeString = (promoCodeData) => {
    const { promoCode } = promoCodeData || {};
    return (
        <>
            <DynamicParagraphText desktopFontSize='17px' style={styles.promoCode} text={`${promoCode} Promo`}/>
        </>
    )
}
export const createAdminSummaryItems = (product, isPaidTierUpgrade, subscription, isProductUpgraded, isUpdatePaymentMethodPage, showPromoFromSubscription, promoCodeData, flags={}) => {
    const { lastPaymentAmount, outstandingBalance, freeTrialEndDate } = subscription || {};
    const nextPaymentDate = moment(freeTrialEndDate ? subscription.freeTrialEndDate : product.coverageThrough)?.format('MM/DD/YYYY');
    const { basePrice, discount, actualPrice } = flags.showPaidInvoiceAmount ? subscription?.paidInvoiceAmount : product || {};
    let showCreditFromPreviousSubscription = 'none';
    if (isPaidTierUpgrade && actualPrice?.total - lastPaymentAmount !== 0) {
        showCreditFromPreviousSubscription = 'flex';
    }
    let promoCodeAmount = '';
    if (promoCodeData) {
        promoCodeAmount = promoCodeData.discountAmount;
    }
    const nextPaymentAmount = product?.nextPaymentAmountPreviewWithPromocode ? product?.actualPrice?.total : product?.actualPriceWithoutPromoCode?.total;
    const items = {
        topSection: [
            {
                key: 'Coverage Level',
                value: product.name,
                itemStyle: { fontWeight: 'bold' },
            },
            {
                key: 'Payment Term',
                value: product.paymentMode === 'Annually' ? 'Annual' : 'Monthly',
                itemStyle: { fontWeight: 'bold' },
            }
        ],
        basicTopSection: [
            {
                key: 'Coverage Level',
                value: product.displayName,
                itemStyle: { fontWeight: 'bold' },
            }
        ],
        middleSection: [
            {
                key: product.paymentMode === 'Monthly' ? 'Base Price (Monthly)' : 'Base Price (Annual)',
                value: <Currency value={basePrice?.price} />,
            },
            {
                key: 'Taxes',
                value: <Currency value={actualPrice?.tax} />,
            },
            {
                key: 'Credit from previous subscription',
                value: <>-{!isProductUpgraded ? <Currency value={product.totalRefundAmount} /> : <Currency value={actualPrice?.total - lastPaymentAmount} />}</>,
                style: { fontStyle: 'italic', display: `${showCreditFromPreviousSubscription}` },
            }

        ],
        basicMiddleSection : [
            {
                key: 'Base Price',
                value: '$0'
            }
        ],
        promoCode: [
            {
                key: getPromoCodeString(promoCodeData),
                value: showPromoFromSubscription && subscription?.promoCode ? <Currency value={subscription?.promoCode?.price} /> : <Currency value={promoCodeAmount} />,
                style: { fontStyle: 'italic' }
            },
        ],
        totalPaidAmount: [
            {
                key: 'Paid',
                value: <Currency value={lastPaymentAmount} />,
                style: { color: colors.darkBlue, fontWeight: 'bold', fontSize: '22px' },
            }
        ],
        grandTotal: [
            {
                key: isUpdatePaymentMethodPage ? 'Next Payment' : 'Total Due Today',
                value: !isPaidTierUpgrade ? <Currency value={actualPrice?.total} /> : <Currency value={actualPrice?.total - product.totalRefundAmount} />,
                style: { color: colors.darkBlue, fontWeight: 'bold', fontSize: '22px' },
            }
        ],
        bottomSection: [
            {
                key: 'Next Payment Date',
                value: removeLeadingZerosForSingleDigitDate(moment(nextPaymentDate)?.format('MM/DD/YYYY')),
            },
            {
                key: 'Next Payment',
                value: <Currency value={nextPaymentAmount} />,
            }
        ],
        outstandingBalanceSection: [
            {
                key: 'Outstanding Balance',
                value: <Currency value={outstandingBalance} />,
                style: { fontWeight: 'bold'}
            }
        ],
        outstandingBalanceTotalSection: [
            {
                key: 'This Payment',
                value: <Currency value={outstandingBalance} />,
                style: { color: colors.darkBlue, fontWeight: 'bold', fontSize: '22px' }
            }
        ]
    };
    if (Math.abs(discount?.price) > 0) {
        items.middleSection.push({
            key: 'AAA Member Discount',
            value: <Currency value={discount?.price} />,
            style: { fontStyle: 'italic' }
        });
    }
    return items;      
}