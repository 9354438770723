import React from 'react';
import styled, { css } from 'styled-components';
import { colors, desktop, mobile, mobileOnly } from '../../styles/common';
const StepsComponent = styled.div`
    background: ${colors.snow};
    min-height: 100px;
    padding: 2rem;
    display: flex;
    justify-content: space-around;
    ${mobile`	
        flex-direction: column;
    `}

`;
const StepsIcon = styled.span`
    display: inline-block;
    color: ${colors.lightBlue};
    border: 2px solid ${colors.lightBlue};
    font-size: 18px;
    font-weight: bold;
    background: ${colors.pureWhite};
    line-height: 40px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    
`;
const StepsText = styled.div`
    font-size: 16px;
    line-height: 25px;
    color: ${colors.myrtleColor};
    ${mobile`
        font-size: 14px;
    `}
`;
const Steps = styled.div`
    display: block;
    width: 100%;
    text-align: center;
`;
const StepsWrapper = styled.div`
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    width:100%;
    display: inline-block;
    ${desktop`
        ${props => props.hideleftline !== true && css`
            &::before {
                position: absolute;
                top: 51%;
                overflow: hidden;
                width: 50%;
                height: 1px;
                content: '\a0';
                margin-left: -50%;
                text-align: right;
                background-color: rgba(0, 0, 0, 0.15);
                
            }
        `}
        ${props => props.hiderightline !== true && css`
            &::after {
                position: absolute;
                top: 51%;
                overflow: hidden;
                width: 50%;
                height: 1px;
                content: '\a0';
                background-color: rgba(0, 0, 0, 0.15);
                
            }
        `}
    `}
`;
const VerticalLine = styled.div`
    ${props => props.mobileonly && css`
        ${mobileOnly}
    `}
    height: 90px;
    border: 0.5px solid rgba(0, 0, 0, 0.15);
    width: 0px;
    padding: 0;
    margin: 0 auto;
`;
const WelcomeSteps = ({ ...props }) => {
    const { steps } = props || {};
    return (
        <StepsComponent>
            {steps.map(({ number, text }, index) =>
                <>
                    <Steps key={index}>
                        <StepsWrapper {...props}
                            hideleftline={index === 0}
                            hiderightline={steps.length - 1 === index}>
                            <StepsIcon>
                                {number}
                            </StepsIcon>
                        </StepsWrapper>
                        <StepsText>
                            {text}
                        </StepsText>
                    </Steps>
                    {steps.length - 1 !== index && <VerticalLine mobileonly='true' />}
                </>
            )}

        </StepsComponent>
    );
};

export default WelcomeSteps;