import React, { Component } from 'react';
import { segmentAnalytics } from '../configuration/ApplicationContext';
import DataContext from './contexts/DataContext';
import { AAA_MEMBER, CANCELLED } from './common/Constants';
import Spinner from './elements/Spinner';
import RedirectionService from '../services/RedirectionService';
import cookie from 'react-cookies';
import { FREE_UPPER_CASE } from './common/Constants';
import { setMemberTypeInSessionStorage } from './common/Helpers';
export default class LoginSuccessPage extends Component {
    static contextType = DataContext;

    componentDidMount() {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const intent = params.get('intent');
        const ratePlan = params.get('rate-plan');
        const redirectUri = cookie.load('redirect_uri');
        const subscriptionApplicationnId = params.get('subscription-application-id');
        const productId = params.get('product-id');
        setMemberTypeInSessionStorage(AAA_MEMBER);
        if (intent === 'bundle-flow-login' || intent === 'non-member-flow-login') {
            if (!productId) {
                return;
            }
            this.bundleFlowRedirection(subscriptionApplicationnId, productId, intent);
            return;
        }
        if (this.context.isLoggedIn && !this.context.userDetails?.subscription && this.context.userDetails?.invalidMembershipNumber) {
            this.props.history.push('/inactive-membership');
            return;
        }
        if (this.isGraceOrCancelledMembership()) {
            this.props.history.push('/grace-membership');
            return;
        }
        if (redirectUri) {
            this.props.history.push(redirectUri);
        } else {
            this.conditionalRender(intent, this.context.userDetails, ratePlan);
        }

        const { personalInformation, membership } = this.context.userDetails;
        this.trackSuccessfulLogin(personalInformation, membership);
    }

    async trackSuccessfulLogin(personalInformation, membership) {
        if (personalInformation != null && personalInformation.email != null) {
            await segmentAnalytics.checkForOptOutStatus(personalInformation.email);
        }
        const { userId } = this.context.userDetails || {};
        segmentAnalytics.track('Login Successful', {
            aaaMembershipId: membership?.membershipId,
            firstName: personalInformation ? personalInformation.firstName : membership?.firstName,
            lastName: personalInformation ? personalInformation.lastName : membership?.lastName,
            email: personalInformation ? personalInformation.email : membership?.email,
            user_id: userId
        });
    }

    conditionalRender = (intent, userDetails, ratePlan) => {
        this.props.history.push(RedirectionService.getConditionalRenderURL(intent, userDetails, ratePlan));
    }

    bundleFlowRedirection = (subscriptionApplicationId, productId, intent) => {
        subscriptionApplicationId && this.props.history.push(this.generateRedirectionMapUrl(productId, intent));
    }

    generateRedirectionMapUrl = (productId, key) => {
        const redirectionMap = new Map()
            .set('bundle-flow-login', `/bundle/checkout/${productId}/payment`)
            .set('non-member-flow-login', `/checkout/${productId}/payment`);
        return redirectionMap.get(key);
    }

    isGraceOrCancelledMembership = () => {
        return this.context.isLoggedIn
            && this.context.userDetails?.subscription
            && (this.context.userDetails?.graceMembershipNumber
                || (this.context.userDetails?.creationSource !== 'NON_AAA' && this.context.userDetails?.membership?.membershipStatus === CANCELLED)
                || (this.context.userDetails?.subscription?.productType === FREE_UPPER_CASE && this.context.userDetails?.invalidMembershipNumber));
    }

    render() {
        return (
            <Spinner />
        )
    }
}
