import React, { Component } from 'react';
import Content from '../../layout/Content';
import { ContentfulService } from '../../../configuration/ApplicationContext';
import Spinner from '../../elements/Spinner';
import Breadcrumbs from '../../common/Breadcrumbs';
import ContentfulIds from '../../../configuration/ContentfulIds';
import Title from '../../elements/Title';
import AdminContext from '../../contexts/AdminContext';
import RichText from '../../elements/RichText';
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import DynamicParagraphText from '../../elements/DynamicParagraphText';
import Link from '../../elements/Link';
import AnchorLink from '../../elements/AnchorLink';

const paragraphStyle = {
    width: '50%'
}
const linkStyle = {
    fontWeight: '500',
    fontSize: '17px',
    marginTop: '3rem'
}

const displayInline = { display: 'inline-block' };
const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle desktopStyles={paragraphStyle} desktopFontSize='17px' mobileFontSize='14px'>{children}</ParagraphStyle>,
        [INLINES.HYPERLINK]: (node) => <AnchorLink style={displayInline} href={node.data.uri} text={node.content[0].value} />
    },
};
class AdminBundleOutOfAreaWithInMWG extends Component {
    static contextType = AdminContext;

    componentDidMount() {
        this.loadContent();
        this.setBreadCrumb();
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.Admin.Bundle.NotAvailableInAreaWithInMWG);
        this.setState({ content: response.fields });
    }
    setBreadCrumb = () => {
        const breadCrumbRoutes = [
            {
                to: `/admin/bundle/personal-info`, label: 'New AAA Digital + IDC Signup 1: Personal Info'
            },
            {
                to: `/admin/bundle/inside_mwg`, label: ' Not Available in Area'
            }
        ]
        this.setState({ breadCrumbRoutes });
    }
    render() {
        const { content, breadCrumbRoutes } = this.state || {};
        if (!content) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{ marginTop: '0rem' }}>
                <Breadcrumbs breadCrumbRoutes={breadCrumbRoutes} isCustomerFacing={false} ></Breadcrumbs>
                <Title>{content.title}</Title>
                <RichText text={content.description} options={options} />
                <Link to={`/admin/search?login-id=${this.context.getLoginId()}`} style={linkStyle} text={'Back to Dashboard'} ></Link>
            </Content>
        );
    }
}

export default AdminBundleOutOfAreaWithInMWG;
