import React from "react";
import styled from "styled-components";
import { mobile } from '../../styles/common';

const ProductImageContentSection = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  ${ mobile`
      flex-direction: column;
  `} 
`;

const ProductImageContainer = styled.div`
    margin: 0.5rem;
    text-align: center;
    ${ mobile`
       width: 100%;
       :nth-child(2) {
           display: none;
       }
       :nth-child(3) {
           display: none;
        }
    `}
`;

const Image = styled.img`
    width: 100%;
`;

const ProtectElementImages = ({ sections }) => {
    return (
        <ProductImageContentSection>
            {sections.map(section =>
                <ProductImageContainer key={section.sys.id}>            
                    <Image key={section.sys.id} src={`https:${section.fields.image.fields.file.url}`} />
                </ProductImageContainer>
            )}                     
        </ProductImageContentSection>
    );
};

export default ProtectElementImages;
