import React, { Component } from 'react';
import HomeAddressForm from './forms/UpdateHomeAddress/HomeAddressForm';
import {Api, segmentAnalytics} from '../configuration/ApplicationContext';
import ResponsiveSignupFlowLayout, {SignUpFlowFormContent} from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Title from './elements/Title';
import DataContext from './contexts/DataContext';
import RedirectionService from '../services/RedirectionService';
import withPageTitle from "./layout/withPageTitle";
import { addAmobeeTag } from './common/Helpers';
import UtilsService from '../services/UtilsService';
import { AAA_USER, AMOBEE_SUF_NEW_FREE_MEMBER_TAG_URL, FREE } from './common/Constants';

class UpdateHomeAddressPage extends Component {
    static contextType = DataContext;
    constructor(props) {
        super(props)
        this.state = {
            apiErrors: []
        }
    }

    componentDidMount() {
        this.componentLoad();
        this.populateAddress();
    }

    populateAddress() {
        const { homeAddress } = this.context.userDetails?.personalInformation || {};
        this.setState({
            homeAddress
        });
    }

    async componentLoad() {
        await this.checkUserStateAndRedirect();
    }

    async checkUserStateAndRedirect() {
        const userDetails = this.context.userDetails;
        const response = await Api.get('/api/products/rate-plans');
        if (response?.products?.length) {
            this.props.history.push(RedirectionService.getConditionalRenderURL(null, userDetails));
        }
    }

    onSubmit = async (formData) => {
        if (Object.keys(formData.errors).length > 0) {
            return;
        }
        this.setState({
            apiErrors: []
        });
        const {additionalHomeInfo} = formData.values;
        const homeAddress = {...this.state.homeAddress, lineTwo: additionalHomeInfo};
        const apiPayload =this.context.userDetails.personalInformation;
        apiPayload.homeAddress=homeAddress;
        apiPayload.billingAddress=homeAddress;
        const response = await Api.put('/api/personal-information', apiPayload);
        if (!response) {
            return;
        }
        if (response.validationErrors) {
            const apiErrors = response.validationErrors.map(apiError => {
                return apiError.message
            });
            this.setState({
                apiErrors
            });
        }
         else {
            const message = {
                showMessage: true,
                severity: 'success',
                message: 'Success. Your address is updated.'
            };
            let userDetails = this.context.userDetails;
            userDetails.personalInformation = response;
            this.context.updateState({ 
                message: message,
                userDetails: userDetails
            });
            
            const addressResponse = await Api.get('/api/personal-information/validate-address');
            if (!addressResponse) {
                this.props.history.push(`/checkout/${this.props.match.params.ratePlanId}/personal-info/address-info`);
                return;
            }
            if (this.props.match.params.ratePlanId === 'basic') {
                // create basic subscription
                await Api.post('/api/subscriptions/basic-coverage');
                const { personalInformation, userId, membershipId } = this.context.userDetails || {};
                segmentAnalytics.track('SUF New Free Member', {
                    memberId: userId,
                    userName: personalInformation.email,
                    AAA_membership_id: membershipId,
                    name: personalInformation.displayName,
                    phoneNumber: personalInformation.phoneNumber,
                    address: personalInformation.homeAddress,
                    tierSelected: FREE,
                    MemberType : AAA_USER
                });
                addAmobeeTag(AMOBEE_SUF_NEW_FREE_MEMBER_TAG_URL);
                this.props.history.push('/subscription-complete');
            } else {
                const ratePlanResponse = await Api.get(`/api/products/zuora-rate-plans`);
                const mappedProducts = UtilsService.mapProductsToArray(ratePlanResponse.products); 
                const foundProduct = mappedProducts.find(product => product.id === this.props.match.params.ratePlanId);
                const selectedProduct = foundProduct ? foundProduct : {};
                localStorage.setItem('productsWithoutPromoCodes', JSON.stringify(ratePlanResponse.products));
                this.context.updateState({product: selectedProduct}); 
                this.props.history.push(`/checkout/${this.props.match.params.ratePlanId}/payment`);
            }
        }
    };
    onSelectAddress = (homeAddress) => {
        this.setState({
            homeAddress
        });
    }
    render() {
        const { personalInformation } = this.context.userDetails || {};
        if (!personalInformation) {
            return null;
        }
        return (
            <Content>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title style={{marginTop: '1rem'}}>Update your home address</Title>
                        <HomeAddressForm onSubmit={this.onSubmit} personalInformation={personalInformation} onSelectAddress={this.onSelectAddress} apiErrors={this.state.apiErrors} />
                    </SignUpFlowFormContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        )
    }
}

export default withPageTitle(UpdateHomeAddressPage, "Update Home Address");
