import React, { Component } from 'react';
import StringPlaceholderService from '../../services/StringPlaceholderService';
import Card from '../elements/Card';
import ResponsiveSignupFlowLayout, { SignUpFlowExtraContent, SignUpFlowFormContent } from '../layout/ResponsiveSignupFlowLayout';
import Title from '../elements/Title';
import RichText from '../elements/RichText';
import Content from '../layout/Content';
import Spinner from '../elements/Spinner';
import ContentfulIds from '../../configuration/ContentfulIds';
import { capitalizeFirstLetter } from '../common/Helpers';
import { ContentfulService } from '../../configuration/ApplicationContext';
import AdminSummary from './summary/AdminSummary';
import AdminContext from '../contexts/AdminContext';
import Link from '../elements/Link';
import Breadcrumbs from '../common/Breadcrumbs';

export default class AdminNonMemberSubscriptionSuccessPage extends Component {
    static contextType = AdminContext;

    async componentDidMount() {
        await this.context.getMemberDetails();
        await this.props.notificationContext.getNotifications();
        this.loadContent();
        this.setBreadCrumb();
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.Admin.SignUpFlow.SubscriptionSuccessPage);
        this.setState({ content: response.fields });
    }

    setBreadCrumb = () => {
        const breadCrumbRoutes = [
            {
                to: '/admin/search/noresults', label: 'New AAA Digital + IDC Signup'
            },
            { 
                to: '/admin/subscription-complete', label: 'Sign Up Successful' 
            }
        ];
        this.setState({ breadCrumbRoutes });
    }


    render() {
        const { personalInformation, subscription } = this.context.memberDetails || {};
        const { content, breadCrumbRoutes } = this.state || {};
        const { product } = this.context || {};
        if (!subscription || !content){
            return <Spinner />;
        }

        return (
            <Content desktopStyles={{ marginTop: '0rem' }}>
                <Breadcrumbs breadCrumbRoutes={breadCrumbRoutes} isCustomerFacing={false}> 
                </Breadcrumbs>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                            <Title>{content.title}</Title>
                            <RichText text={content.description} />
                            <Link to='/admin/member-account' style={{ marginTop: '2rem' }} text={`Back to ${personalInformation.displayName}'s Details`} />
                            <Link to={`/admin/search?login-id=${this.context.getLoginId()}`} style={{ marginTop: '2rem' }} text={`Back to Dashboard`} />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        {product.name !== 'Free' && <Card>
                            <AdminSummary flags={{showPaidInvoiceAmount: true}} isProductUpgraded={true} product={product} title={StringPlaceholderService.replace(content.linkText, { productType: capitalizeFirstLetter(product.name) })} subscription={subscription} />
                        </Card>}
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

