import styled, { css } from 'styled-components';
import { colors, desktopOnly, mobileOnly } from '../../styles/common';

const HorizontalRule = styled.hr`
    border: none;
    background-color: ${colors.mediumGrey};
    height: 1px;
    ${props => props.desktopOnly && css`
        ${desktopOnly}
    `}
    ${props => props.mobileonly && css`
        ${mobileOnly}
    `}
`;

export default HorizontalRule;