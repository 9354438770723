import React, { Component } from 'react';
import StringPlaceholderService from '../../services/StringPlaceholderService';
import ResponsiveSignupFlowLayout, { SignUpFlowExtraContent, SignUpFlowFormContent } from '../layout/ResponsiveSignupFlowLayout';
import Title from '../elements/Title';
import RichText from '../elements/RichText';
import Content from '../layout/Content';
import Spinner from '../elements/Spinner';
import ContentfulIds from '../../configuration/ContentfulIds';
import { ContentfulService } from '../../configuration/ApplicationContext';
import AdminContext from '../contexts/AdminContext';
import Link from '../elements/Link';
import Breadcrumbs from '../common/Breadcrumbs';

export default class AdminCancelSuccessPage extends Component {
    static contextType = AdminContext;

    async componentDidMount() {
        await this.props.notificationContext.getNotifications();
        await this.context.getMemberDetails();
        this.loadContent();
        this.setBreadCrumb();
    }

    async loadContent() {
        const { personalInformation } = this.context.memberDetails || {};
        const response = await ContentfulService.getEntry(ContentfulIds.AdminCancelSuccessPage);
        const properties = {
            name: personalInformation.displayName
        }
        response.fields.description.content.forEach(ct => {
            ct.content.forEach(pr => {
                if (pr.value) {
                    pr.value = StringPlaceholderService.replace(pr.value, properties)
                }
            })
        });
        this.setState({ content: response.fields });
    }

    setBreadCrumb = () => {
        const { personalInformation } = this.context.memberDetails || {};
        const breadCrumbRoutes = [
            {
                to: '/admin/member-account', label: personalInformation.displayName
            },
            {
                to: '/admin/cancel/success', label: 'Cancel Account'
            }
        ];
        this.setState({ breadCrumbRoutes });
    }


    render() {
        const { content, breadCrumbRoutes } = this.state || {};
        if (!content) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{marginTop: '0rem'}}>
                <Breadcrumbs isCustomerFacing={false} breadCrumbRoutes={breadCrumbRoutes}></Breadcrumbs>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title>{content.title}</Title>
                        <RichText text={content.description} />
                        <Link style={{ marginTop: '2rem' }} text={`Back to Dashboard`} to={`/admin/search?login-id=${this.context.getLoginId()}`} />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

