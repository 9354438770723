import React, { Component } from "react";
import Spinner from './elements/Spinner';
import Title from "./elements/Title";
import withPageTitle from "./layout/withPageTitle";
import { ContentfulService } from "../configuration/ApplicationContext";
import ContentfulIds from "../configuration/ContentfulIds";
import Content from "./layout/Content";
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent } from "./layout/ResponsiveSignupFlowLayout";
import DynamicParagraphText from "./elements/DynamicParagraphText";
import RichText from "./elements/RichText";
import { colors } from "../styles/common";
import Link from "./elements/Link";

const mobileTitleStyle = {
  lineHeight: '25px',
  fontSize: '24px',
}

const desktopTitleStyle = {
  lineHeight: '48px',
}

const descriptionStyle = {
  lineHeight: '26px',
}

const mobileMenuStyle = {
    padding: '15px 0px', 
    color: `${colors.darkBlue}`, 
    fontSize: '16px', 
    lineHeight: '22.6px', 
    cursor: 'pointer',
    fontWeight: '500' 
};

class VerifyIdentitySuccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {
    this.loadContent();
    localStorage.removeItem('verifyIdentity');
  }

  async loadContent() {
      const response = await ContentfulService.getEntry(ContentfulIds.VerifyIdentitySuccessPage);
      this.setState({
          content: response.fields
      });
  }

  onRedirect = async () => {
    localStorage.setItem('isAccountSetupComplete', true);
    window.location.href = process.env.REACT_APP_EXPERIAN_SSO_URL;
  }
  
  render() {
    const {content} = this.state;
    if (!content) {
        return <Spinner />
    }
    return (
      <Content>
        <ResponsiveSignupFlowLayout>
            <SignUpFlowFormContent>
                <Title mobileStyles={mobileTitleStyle} desktopStyles={desktopTitleStyle} style={{marginBottom: '1.5rem'}}>{content.title}</Title>
                <DynamicParagraphText style={descriptionStyle} desktopFontSize='17px' mobileFontSize='14px' text={<RichText text={content.description} />} />    
                <Link text={content.linkText} style={mobileMenuStyle} onClick={this.onRedirect}></Link>
            </SignUpFlowFormContent>
        </ResponsiveSignupFlowLayout>
    </Content>
    );
  }
}

export default withPageTitle(VerifyIdentitySuccessPage, "Verify Identity Success");