import React from 'react';
import { AdminApi } from '../../configuration/ApplicationContext';
import ContentfulIds from '../../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, {
    SignUpFlowExtraContent,
    SignUpFlowFormContent
} from '../layout/ResponsiveSignupFlowLayout';
import Content from '../layout/Content';
import Title from '../elements/Title';
import Spinner from '../elements/Spinner';
import BaseAdminUpgradeTierCheckout from './BaseAdminUpgradeTierCheckout';
import AdminCheckoutForm from './forms/AdminCheckoutForm';
import AdminSummary from './summary/AdminSummary';
import Card from '../elements/Card';
import AdminContext from '../contexts/AdminContext';
import Breadcrumbs from '../common/Breadcrumbs';
import UtilsService from '../../services/UtilsService';
import InfoText from '../elements/InfoText';
import Notification from '../notifications/Notification';
import { CANCELLED } from '../common/Constants';

declare var Z: any; //Zuora

export default class AdminPaidTierProductUpgradeCheckoutPage extends BaseAdminUpgradeTierCheckout {
    static contextType = AdminContext;

    constructor(props) {
        super(props);
        this.state = {
            zuoraConfiguration: null,
            isNewPaymentMethod: false,
            productPageURL: '/admin/product/protect/upgrade',
            checkoutPageURL: '/admin/product/protect/upgrade/checkout',
            subscriptionAction: 'UPGRADE'
            };
        }

    async componentDidMount() {
        await this.context.getMemberDetails();
        this.loadPlans();
        this.setBreadCrumb();
        this.loadZuoraForm();
        this.loadContent(ContentfulIds.Admin.MemberAccountIds.UpgradeTierCheckoutPage)
        this.setSubscription();
        this.loadPersonalInformation();
    }

    setSubscription() {
        this.setState({ subscription: this.context.memberDetails?.subscription });
    }

    onSubmit = (data) => {
        const ratePlanId = this.context.product.id;
        const { ...billingAddress } = this.state.personalInfo?.billingAddress || {};
        const { isNewPaymentMethod } = this.state;
        if (isNewPaymentMethod) {
            if (!data.values.billingAddress || Object.keys(data.errors).length > 0) {
                this.setState({ checkoutError: data.errors.billingAddress });
                return;
            }
            window.orderSuccess = async (referenceId) => {
                this.setState({
                    isSubmitDisabled: true
                }); 
                const paymentMethodId = referenceId;
                this.upgradeOrder(ratePlanId, paymentMethodId, isNewPaymentMethod, billingAddress);
            };

            window.orderFailure = (failureMessage) => {
                this.setState({ checkoutError: failureMessage, zuoraConfiguration: null, isSubmitDisabled: false }, () => { this.loadZuoraForm(); });
            };
            Z.submit();
        } else {
            this.setState({
                isSubmitDisabled: true
            }); 
            const defaultPaymentMethodId = this.state.subscription.defaultPaymentMethod.id;
            this.upgradeOrder(ratePlanId, defaultPaymentMethodId, isNewPaymentMethod, billingAddress);
        }
    };

    upgradeOrder = async (ratePlanId, paymentMethodId, isNewPaymentMethod, billingAddress) => {
        const { promoCodeRatePlanId, promoCode } = this.context.promoCodeData || {};
        const nonAaaMember = !this.context.memberDetails?.membership || this.context.memberDetails?.membership.membershipStatus === CANCELLED;
        let response = await AdminApi.post(`/api/users/${this.context.searchResult.userId}/upgrade-coverage`, { ratePlanId, paymentMethodId, isNewPaymentMethod, billingAddress, promoCodeRatePlanId, promoCode, nonAaaMember });
        if (!response) {
            this.setState({
                isSubmitDisabled: false
            }); 
            return;
        }
        if (response.validationErrors) {
            const apiErrors = response.validationErrors.map(apiError => {
                return apiError.message
            });
            this.setState({ checkoutError: apiErrors, zuoraConfiguration: null, isSubmitDisabled: false }, () => { this.loadZuoraForm(); });
        } else {
            this.trackPaymentSuccessEvent('Sales-Tool-Upgrade-Protect-to-Complete');
            this.props.history.push('/admin/product/upgrade/success');
        }
    }

    setNewPaymentMethod = (value) => {
        if(value){
            this.loadZuoraForm();
        }
        this.setState({ isNewPaymentMethod: value });
    }

    render() {
        const { zuoraConfiguration, content, checkoutError, personalInfo, product, subscription, isNewPaymentMethod, billingAddressSameAsHomeAddress, breadCrumbRoutes, isSubmitDisabled } = this.state;
        const nonAaaMember = !this.context.memberDetails?.membership || this.context.memberDetails?.membership.membershipStatus === CANCELLED;
        const searchResult = JSON.parse(localStorage.getItem('searchResult'));
        const { notifications } = this.props.notificationContext;
        if (!zuoraConfiguration || !content || !product || !subscription || isSubmitDisabled) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{marginTop: '0rem'}}>
                <InfoText>
                    {notifications.map(notification => {
                        return <Notification 
                            key={notification.notificationType} 
                            {...notification} 
                            {...this.props} 
                        />
                    }
                    )}
                </InfoText>
                <Breadcrumbs 
                    isCustomerFacing={false} 
                    breadCrumbRoutes={breadCrumbRoutes} 
                />
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title 
                            style={{ marginTop: '3rem' }}
                        >
                            {content.title}
                        </Title>
                        <AdminCheckoutForm 
                            zuoraConfiguration={zuoraConfiguration} 
                            content={content} 
                            onSubmit={this.onSubmit}
                            checkoutError={checkoutError} 
                            personalInfo={personalInfo}
                            onSelectAddress={this.onSelectAddress} 
                            selectedProduct={product}
                            subscription={subscription} 
                            isNewPaymentMethod={isNewPaymentMethod} 
                            setNewPaymentMethod={this.setNewPaymentMethod}
                            billingAddressSameAsHomeAddress={billingAddressSameAsHomeAddress} 
                            showPaymentMethod={true} 
                            isSubmitDisabled={isSubmitDisabled}
                            onApplyPromoCode={this.onApplyPromoCode}
                            promoCodeOptions={{ validateURL: `/api/promo-codes/users/${searchResult?.userId}/validate`, 
                            zuoraRatePlanURL: `/api/products/users/${searchResult?.userId}/zuora-rate-plans?subscriptionAction=${this.state.subscriptionAction}&&isNonAaaMember=${nonAaaMember}`, mapProductsFunc: UtilsService.mapProductsToArray}}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        <Card>
                            <AdminSummary 
                                isPaidTierUpgrade={true} 
                                product={product} 
                                title={`If Subscriber upgrades to ${product.name}`} 
                            />
                        </Card>
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

