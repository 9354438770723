import { css } from 'styled-components';

export const mobileBreakpoint = 960;

export const headerHeight = '128px';

export const footerDesktopHeight = '224px';

export const footerMobileHeight = '214px';

export const headerMobileHeight  = '60px';

export const notAvailableMessage = 'N/A';
 
export const mobile = (...args) => css`
    @media (max-width: ${mobileBreakpoint}px) {
        ${css(...args)};
    }
`;

export const desktop = (...args) => css`
    @media (min-width: ${mobileBreakpoint+1}px) {
        ${css(...args)};
    }
`;

export const mobileOnly = desktop`
    display: none;
`;

export const desktopOnly = mobile`
    display: none;
`;

export const colors = {
    lightGrey: '#F4F4F4',
    verylightGrey:"#737373",    
    mediumGrey: '#C4C4C4',
    darkGrey: '#888888',
    scarlet: '#CA3C2B',
    pureWhite: '#FFFFFF',
    pureBlack: '#000000',
    lightBlue: '#00A0DF',
    darkBlue: '#003087',
    initialGrey: '#D7D8D9',
    blue: '#0E368D',
    contentGrey: '#b5b1b1',
    textGrey: '#7F7F7F',
    backgroundGrey: '#E8E8E8',
    titleText: '#252525',
    borderGrey: '#C5C5C5',
    red: '#DB2D27',
    errorBackground: '#F9DFDD',
    successBackground: '#e6f0de',
    successMessage: '#458b00',
    myrtleColor: '#5B5B5B',
    skyBlue: '#58B2E7',
    greyborder: '#979797',
    mediumBlue: '#0972CE',
    greyText: '#848484',
    textColor: '#363636',
    searchBorderColor: '#B0B0B0',
    searchResultsHeaderColor: '#8B8B8B',
    searhRowBackgroundColor: '#F2F2F2',
    blackOverlay: '#222222',
    infoBackground: '#D9F1FA',
    outGrey: '#858585',
    silver:'#B9B9B9',
    snow:"#F9F9F9",
    darkRed:"#DA291C",
    greyMedium:'#D2D2D2',
    sandyBrown: '#F2C94C',
    tomato: '#eb5757',
    darkSlateGray: '#395d72',
    snowLight: '#FAFAFA'
};

export const commonPropStyles = {
    mobile : props => props.mobileStyles && css`
        ${mobile`
            ${props.mobileStyles}
            `}
        `,
    desktop: props => props.desktopStyles && css`
        ${desktop`
            ${props.desktopStyles}
            `}
    ` 
};

