import React from 'react';
import AdminContext from '../../contexts/AdminContext';
import Content from '../../layout/Content';
import { AdminApi, ContentfulService } from '../../../configuration/ApplicationContext';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from '../../layout/ResponsiveSignupFlowLayout';
import Title from '../../elements/Title';
import moment from 'moment';
import Spinner from '../../elements/Spinner';
import Breadcrumbs from '../../common/Breadcrumbs';
import ContentfulIds from '../../../configuration/ContentfulIds';
import ParagraphText from '../../elements/ParagraphText';
import AdminPersonalInfoForm from '../forms/AdminPersonalInfoForm';
import Notification from '../../notifications/Notification';
import AdminBaseBundle from './AdminBaseBundle';

const titleStyle = { marginTop: '1rem' };
const contentStyle = { marginTop: '0rem' };

class AdminBundlePersonalInfoPage extends AdminBaseBundle {
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            apiErrors: [],
            billingAddressSameAsHomeAddress: true,
            personalInfoPageURL: '/admin/bundle/personal-info'
        };
    }
    async componentDidMount() {
        this.setBreadCrumb();
        this.loadContent();
    }
    setBreadCrumb = () => {
        const breadCrumbRoutes = [
            {
                to: `${this.state.personalInfoPageURL}`, label: 'New AAA Digital + IDC Signup 1: Personal Info'
            }
        ]
        this.setState({ breadCrumbRoutes });
    }
    onSubmit = async (data) => {
        if (Object.keys(data.errors).length > 0) {
            return;
        }
        this.setState({
            isLoading: true
        });
        const { firstName, middleName, lastName, email, dob, phoneNumber, additionalHomeInfo, additionalBillingInfo } = data.values;
        const homeAddress = { ...this.state.homeAddress, lineTwo: additionalHomeInfo };
        const billingAddress = { ...this.state.billingAddress, lineTwo: additionalBillingInfo };
        const personalInformation = {
            firstName,
            middleName,
            lastName,
            email,
            dob: moment(dob).format('YYYY-MM-DD'),
            phoneNumber: phoneNumber.replace(/[^\d]/g, ''),
            homeAddress: this.state.homeAddress ? homeAddress : this.context.searchResult.address,
            billingAddress,
            billingAddressSameAsHomeAddress: this.state.billingAddressSameAsHomeAddress
        };
        this.setState({
            isSubmitDisabled: true
        });
        const response = await AdminApi.post('/api/bundle/subscription-applications', {
            personalInformation,
        });
        if (!response) {
            this.setState({
                isLoading: false
            });
            return;
        }
        if (response.validationErrors) {
            this.setState({
                isLoading: false
            });
        } else {
            const { personalInformation } = response || {};
            const { firstName, middleName, lastName, dob, homeAddress, phoneNumber, billingAddress, billingAddressSameAsHomeAddress } = personalInformation || {};
            const searchResult = {
                email,
                firstName,
                middleName,
                lastName,
                dob,
                address: homeAddress,
                phone: phoneNumber,
                homeAddress,
                billingAddress,
                billingAddressSameAsHomeAddress
            };
            const memberDetails = {
                personalInformation
            }
            this.context.updateState({
                memberDetails,
                searchResult
            });
            localStorage.setItem('searchResult', JSON.stringify(searchResult));
            this.setState({
                isLoading: false
            });
            this.props.history.push(`/admin/bundle/${response.id}/choose-plan`);
        }
    };

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.Admin.Bundle.PersonalInfoPage);
        this.setState({ content: response?.fields });
    }

    onSelectAddress = (homeAddress) => {
        this.setState({
            homeAddress
        });
    }

    onBillingAddressSameAsHomeAddress = (billingAddressSameAsHomeAddress) => {
        this.setState({ billingAddressSameAsHomeAddress });
    }

    onSelectBillingAddress = (billingAddress) => {
        this.setState({
            billingAddress
        });
    }
    render() {
        const { content, isLoading, notifications, breadCrumbRoutes } = this.state || {};
        if (!content || isLoading) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={contentStyle}>
                <Breadcrumbs breadCrumbRoutes={breadCrumbRoutes} isCustomerFacing={false} ></Breadcrumbs>
                {notifications && notifications.map(notification => {
                    return <Notification key={notification.notificationType} {...notification} {...this.props} />
                })}
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title style={titleStyle}>{content.title}</Title>
                        <ParagraphText>{content.description}</ParagraphText>
                        <AdminPersonalInfoForm onSubmit={this.onSubmit} onSelectAddress={this.onSelectAddress}
                            onBillingAddressSameAsHomeAddress={this.onBillingAddressSameAsHomeAddress}
                            onSelectBillingAddress={this.onSelectBillingAddress}
                            apiErrors={this.state.apiErrors}
                            billingAddressSameAsHomeAddress={this.state.billingAddressSameAsHomeAddress}
                            content={content}
                            showBottomText={true}
                            isSubmitDisabled={isLoading}
                            homeAddressCheck={true}
                            cancelRoute={`/admin/search?login-id=${this.context.getLoginId()}`}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default AdminBundlePersonalInfoPage;
