import React, { Component } from 'react';
import { ContentfulService } from '../configuration/ApplicationContext';
import ResponsiveLandingPageLayout from './layout/ResponsiveLandingPageLayout';
import Spinner from './elements/Spinner';
import ContentfulIds from '../configuration/ContentfulIds';
import Title from './elements/Title';
import RichText from './elements/RichText';
import { colors, mobile, desktop } from '../styles/common';
import styled from 'styled-components';
import { BLOCKS } from "@contentful/rich-text-types";
import DynamicParagraphText from './elements/DynamicParagraphText';
import withPageTitle from './layout/withPageTitle';
import withPageMetaTags from './layout/withPageMetaTags';

const NotFoundSection = styled.div`
    ${desktop`
        padding: 30px 60px;
    `}
    position:fixed
	${mobile`
		padding: 0 20px;
	`}
`
const LandingContent = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    ${mobile`
        top: 59px;
        margin: unset;
        padding: unset;
        margin-top: 60px;
        padding: 0 20px;
    `} 
`;

const titleStyle = { paddingTop: '20px' };

const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText id="rich-text" {...props} text={children} />;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle style={{ padding: '10px 0px', fontSize: '16px' }}>{children}</DynamicParagraphStyle>
    }
};
class NotFound extends Component {
    state = {};
    componentDidMount() {
        this.loadContent();
    }

    loadContent = async () => {
        const response = await ContentfulService.getEntry(ContentfulIds.NotFoundPage);
        this.setState({
            content: response.fields
        });
    }

    render() {
        const { content } = this.state;
        if (!content) {
            return <Spinner />;
        }
        return (
            <LandingContent>
                <ResponsiveLandingPageLayout>
                    <NotFoundSection>
                        <Title
                            style={titleStyle}
                        >
                            {content.title}
                        </Title>
                        <RichText
                            text={content.description}
                            options={options}
                        />
                    </NotFoundSection>
                </ResponsiveLandingPageLayout>
            </LandingContent>
        );
    }
}

export default withPageMetaTags(withPageTitle(NotFound, 'Page Not Found'), { noindex: true });
