import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/common';
import { ContentfulService } from "../../configuration/ApplicationContext";
import ChooseYourBundleContent from './ChooseYourBundleContent';
import ContentfulIds from '../../configuration/ContentfulIds';
import RichText from '../elements/RichText';
import Spinner from '../elements/Spinner';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import AnchorLink from '../elements/AnchorLink';

const ChooseYourBundleStyle = styled.div`
    background: ${colors.pureWhite};
`;
const ChooseYourBundleSectionWrapper = styled.div`
    padding: 15px 40px;
`;

const ChooseYourBundleContentSection = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: center; 
`;

const styles = {
    title: { textAlign: 'center', fontSize: '17px', fontWeight: 500 },
    hr: { backgroundColor: colors.pureBlack, marginBottom: '20px' },
    paragraph: { textAlign: 'justify', margin: 0, color: colors.verylightGrey, lineHeight: '20px', paddingTop: '1rem' },
    link: { fontSize: '16px', paddingTop: '1.5rem', lineHeight: '35px', textAlign: 'center', textDecoration: 'underline', color: `${colors.darkBlue}` }
}

const ChooseYourBundleSection = ({ ...props }) => {
    const [content, setContent] = useState();
    const ParagraphStyle = ({ children, ...props }) =>
        <DynamicParagraphText
            {...props}
            text={children}
            style={styles.paragraph}
        />;

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) =>
                <ParagraphStyle
                    anchor={true}
                    desktopFontSize='12px'
                >
                    {children}
                </ParagraphStyle>,
            [INLINES.HYPERLINK]: (node, next) => {
                return <a
                    href={node.data.uri}>
                    {node.content[0].value}
                </a>;
            }
        },
    };

    useEffect(() => {
        loadContent();
    }, []);

    const loadContent = async () => {
        const response = await ContentfulService.getEntries({ 'sys.id': ContentfulIds.BundleFlow.ChoosePlanSection, include: 5 });
        setContent(response.items[0].fields)
    }
    if (!content) {
        return <Spinner />;
    }
    return (
        <ChooseYourBundleStyle {...props}>
            <ChooseYourBundleSectionWrapper {...props}>
                <ChooseYourBundleContentSection {...props}>
                    {content?.sections?.map(section =>
                        <ChooseYourBundleContent {...props}
                            {...section.fields}
                        />
                    )}
                </ChooseYourBundleContentSection>
                <AnchorLink
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.link}
                    href='/plan-details'
                    desktoponly='true'
                    text={content.linkText}
                />
                <RichText
                    text={content.joinAaaText}
                    options={options}
                />
            </ChooseYourBundleSectionWrapper>
        </ChooseYourBundleStyle>
    );
};

export default ChooseYourBundleSection;