import React from 'react';
import styled from 'styled-components';
import { commonPropStyles } from '../../styles/common';
import SignupFlowStep from './SignUpFlowStep';

const WrappperStyleDiv = styled.div`  
  ${commonPropStyles.mobile}
  padding-bottom: 1.5rem; `
  
const SignupFlowStepProgress = ({ currentStep, mobileStyles, steps = [] }) => {
    return (
        <WrappperStyleDiv
            mobileStyles={mobileStyles}>
            {steps.map(({ number, name, linkUrl, shouldStepsClickable }, index) =>
                <SignupFlowStep
                    key={index}
                    step={number}
                    name={name}
                    currentStep={currentStep}
                    linkUrl={linkUrl}
                    shouldStepsClickable={shouldStepsClickable}
                />
            )}
        </WrappperStyleDiv>
    );
};

export default SignupFlowStepProgress;
