import styled from 'styled-components'
import {colors} from '../../styles/common'

const ContainerWithBorder = styled.div`
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid ${colors.borderGrey};
`;

export default ContainerWithBorder;
