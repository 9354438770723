import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useCustomForm from '../../../hooks/useCustomForm';
import validate from './AddressValidation';
import SubmitButton from '../../elements/SubmitButton';
import LabeledInput from '../../elements/LabeledInput';
import Link from '../../elements/Link';
import { LocationSearchInput } from '../../elements/LocationSearchInput';
import FormError from '../../elements/FormError';


const HomeAddressForm = ({initialValues, onSubmit, personalInformation, onSelectAddress, apiErrors}) => {
    const [showAdditionalHomeInfo, setShowAdditionalHomeInfo] = useState(personalInformation.homeAddress?.lineTwo || false);
    const { homeAddress } = personalInformation;
    initialValues = {
        homeAddress: `${homeAddress?.lineOne}, ${homeAddress?.city}, ${homeAddress?.state}, ${homeAddress?.countryCode}`,
        additionalHomeInfo: homeAddress?.lineTwo || ''
    }
    const {
        values,
        errors,
        touched,
        submitted,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useCustomForm({initialValues, validate, onSubmit});

    const filteredErrors = submitted ? errors : Object.keys(errors)
        .filter(key => touched[key])
        .reduce((obj, key) => {
            obj[key] = errors[key];
            return obj;
        }, {});

    const inputHandlers = {
        onChange: handleChange,
        onBlur: handleBlur,
        extratopspace: '0.5rem',
        extraTopLabelSpace: '0.75rem'
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        values.billingAddress = values.homeAddress;
        delete errors.billingAddress;
        handleSubmit();
      }

    return (
        <form onSubmit={handleFormSubmit} noValidate>
            <FormError showErrors={submitted} errors={filteredErrors} message="Please fill out the fields highlighted below" />
            <FormError showErrors={submitted} errors={apiErrors} />
            <LocationSearchInput label="Home Address" name="homeAddress" value={values.homeAddress} error={filteredErrors.homeAddress} onSelectAddress={onSelectAddress} {...inputHandlers} />
            {(!showAdditionalHomeInfo && !values.additionalHomeInfo) && <Link extratopspace="0.5rem" extrabottomspace="0.5rem" text="+ Add Apt, Suite, Bldg. (optional)" onClick={() => setShowAdditionalHomeInfo(true)} />}
            <LabeledInput label="Apt, Suite, Bldg. (optional)" hide={!showAdditionalHomeInfo && !values.additionalHomeInfo} type="text" name="additionalHomeInfo" value={values.additionalHomeInfo} error={filteredErrors.additionalHomeInfo} {...inputHandlers} />
            <SubmitButton text="Submit Home Address" />
        </form>
    )
}

HomeAddressForm.propTypes = {
    initialValues: PropTypes.shape({})
};

HomeAddressForm.defaultProps = {
    initialValues: {
        homeAddress: '',
        additionalHomeInfo: '',
    }
};

export default HomeAddressForm
