import React from 'react'
import { LocationSearchInput } from '../elements/LocationSearchInput';
import Link from '../elements/Link';
import LabeledInput from '../elements/LabeledInput';
import InfoText from '../elements/InfoText';
import CheckboxInput from '../elements/CheckboxInput';

export const NonManualAddressFields = (props) => {
    const { values, filteredErrors, isEditPersonalInfoFromAdmin, showAdditionalHomeInfo, showAdditionalBillingInfo, isEditPersonalInfo, billingAddressSameAsHomeAddress, setShowAdditionalHomeInfo, onSelectBillingAddress, setshowAdditionalBillingInfo, onBillingAddressSameAsHomeAddress, setBillingAddressSameAsHomeAddress, onSelectAddress, ...inputHandlers } = props;
    return (
        <>
            <LocationSearchInput name="homeAddress" label={isEditPersonalInfoFromAdmin ? "Home Address (This change does not affect ConnectSuite)" : "Home Address"} value={values.homeAddress} error={filteredErrors.homeAddress} onSelectAddress={onSelectAddress} {...inputHandlers} />
            { (!showAdditionalHomeInfo && !values.additionalHomeInfo) && <Link to='#' extratopspace="0.5rem" extrabottomspace="0.5rem" text="+ Add Apt, Suite, Bldg. (optional)" onClick={() => setShowAdditionalHomeInfo(true)} />}
            <LabeledInput type="text" name="additionalHomeInfo" label="Apt, Suite, Bldg. (optional)" hide={!showAdditionalHomeInfo && !values.additionalHomeInfo} value={values.additionalHomeInfo} error={filteredErrors.additionalHomeInfo} {...inputHandlers} />

            { (isEditPersonalInfo || isEditPersonalInfoFromAdmin) &&
                <>
                    <InfoText style={{ marginTop: '1rem' }}>
                        <CheckboxInput name="isHomeAdress" checked={billingAddressSameAsHomeAddress} onChange={() => { setBillingAddressSameAsHomeAddress(!billingAddressSameAsHomeAddress); onBillingAddressSameAsHomeAddress(!billingAddressSameAsHomeAddress) }} />
                     Billing address same as home address
                </InfoText>
                    {
                        !billingAddressSameAsHomeAddress &&
                        <>
                            <LocationSearchInput label="Billing Address" name="billingAddress" value={values.homeAddress === values.billingAddress && values.zipCode === values.billingZipCode ? '' : values.billingAddress} onSelectAddress={onSelectBillingAddress} error={filteredErrors.billingAddress} {...inputHandlers} />
                            {(!showAdditionalBillingInfo && !values.additionalBillingInfo) && <Link to='#' extratopspace="0.5rem" extrabottomspace="0.5rem" text="+ Add Apt, Suite, Bldg. (optional)" onClick={() => setshowAdditionalBillingInfo(true)} />}
                            <LabeledInput label="Apt, Suite, Bldg. (optional)" hide={!showAdditionalBillingInfo && !values.additionalBillingInfo} type="text" name="additionalBillingInfo" value={values.homeAddress === values.billingAddress && values.zipCode === values.billingZipCode ? '' : values.additionalBillingInfo} {...inputHandlers} error={filteredErrors.additionalBillingInfo} />
                        </>
                    }
                </>
            }
        </>
    )
}
