import React from 'react';
import InputMask from 'react-input-mask';
import styled, {css} from 'styled-components';

const Label = styled.label`
    font-weight: normal;
    font-style: normal;
    font-size: 17px;
    padding: 5px 0;
    display: block;   
    margin-top: ${props => props.extraTopLabelSpace};
    ${props => props.hide && css`
        display: none; 
    `}
`;


class ZipCodeComponent extends React.Component {
  render() {
      return (
        <>
            <Label htmlFor="zipcode" {...this.props}>Zip code&nbsp;</Label>
            <InputMask {...this.props} mask="99999" maskChar="" alwaysShowMask="false" /> 
        </>
    )
  }
}
export default ZipCodeComponent;
