import React from 'react';
import styled, { css } from 'styled-components';
import { colors, mobileOnly, desktopOnly, commonPropStyles } from '../../styles/common';

const ButtonInput = styled.input`
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    text-align: center;
    color: ${colors.skyBlue};
    background: ${colors.pureWhite};
    padding: 6px;
    cursor: pointer;
    width: 100%;
    display: block;
    border-radius: 10px;
    margin: 5px 0;
    outline: none;
    border: none;
    ${props => props.disabled && css`
        opacity: 0.5; 
        cursor: default;
    `};
    ${props => props.mobileOnly && css`
        ${mobileOnly}
    `}
    ${props => props.desktopOnly && css`
        ${desktopOnly}
    `}
    ${commonPropStyles.mobile}
    ${commonPropStyles.desktop}
`;

const Button = ({ type, text, ...props }) => {
    return (
        <ButtonInput value={text} type="button" {...props} />
    )
}

export default Button;
