import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Title from '../../../elements/Title';
import Section from '../../../elements/Section';
import { colors } from '../../../../styles/common';
import ProductChartSections from './ProductChartSections';
import Link from '../../../elements/Link';
import ProductChartTabbedView from './ProductChartTabbedView';
import { AAA_MEMBER } from '../../../common/Constants';
import ProductChartToggleButtonSection from './ProductChartToggleButtonSection';
import { getMemberTypeFromSessionStorage, setMemberTypeInSessionStorage } from '../../../common/Helpers';
import AnchorLink from '../../../elements/AnchorLink';

const ProductChartContent = styled.div`
    background: ${colors.lightGrey};
    ${props => props.backgroundcolor && css`
        background: ${props.backgroundcolor};
    `}
`;
const styles = {
    title: { textAlign: 'center', color: `${colors.darkBlue}`, margin: 0, fontWeight: 'normal' },
    description: { color: `${colors.myrtleColor}`, textAlign: 'center' },
    link: { fontSize: '16px', paddingTop: '1.5rem', lineHeight: '35px', textAlign: 'center', textDecoration: 'underline', color: `${colors.darkBlue}` },
    custom: {
        title: { color: `${colors.titleText}`, margin: 0, fontWeight: 'normal' },
        description: { color: `${colors.titleText}` },
    }
};
const desktopStyles = {
    title: { paddingTop: '50px', fontSize: '36px', lineHeight: '48px' },
    description: { fontSize: '20px', lineHeight: '36px' },
    custom: {
        description: { fontSize: '16px', lineHeight: '25px' }
    }
};
const mobileStyles = {
    title: { paddingTop: '1rem', fontSize: '24px', lineHeight: '29px', background: colors.pureWhite, padding: '31px 41px 10px 41px' },
    description: { paddingLeft: '1rem', paddingRight: '1rem', fontSize: '14px', lineHeight: '21px', background: colors.pureWhite },
    custom: {
        title: { paddingTop: '1rem', fontSize: '24px', lineHeight: '29px', background: colors.pureWhite },
        description: { fontSize: '14px', lineHeight: '21px', background: colors.pureWhite },
    }
};

const ProductChart = ({ fields, ...props }) => {
    const { title, description, sections, linkText, insuranceDisclaimer } = fields || {};
    const {
        productChartRef,
        scrollToProductChart,
        customStyle,
        onSignup,
        onProductSelectToCreateSubscription,
        defaultMembership = AAA_MEMBER,
        hideToggleSection = false,
        disableCta = false,
        showDescription = true,
        showTitle = true,
        openComparePlansLinkInNewTab = false
    } = props || {};
    const productChartTitleRef = useRef();
    const selectedMemberTypeInSessionStorageOrDefaultAaaMember = getMemberTypeFromSessionStorage() || defaultMembership;
    const [memberType, setMemberType] = useState(selectedMemberTypeInSessionStorageOrDefaultAaaMember);
    useEffect(() => {
        if (scrollToProductChart === true) {
            productChartTitleRef.current.scrollIntoView(
                {
                    behavior: 'auto',
                    block: 'start',
                }
            );
        }
    }, [scrollToProductChart]);

    const setMemberTypeInSessionStorageAndInState = (memberType) => {
        setMemberTypeInSessionStorage(memberType);
        setMemberType(memberType);
    }
    return (
        <ProductChartContent
            {...props}
            ref={productChartTitleRef}>
            {
                showTitle === true && title &&
                <Title
                    ref={productChartRef}
                    mobileStyles={customStyle === true ? mobileStyles.custom.title : mobileStyles.title}
                    desktopStyles={desktopStyles.title}
                    style={customStyle === true ? styles.custom.title : styles.title}>
                    {title}
                </Title>
            }
            {
                showDescription === true && description &&
                <Section
                    style={customStyle === true ? styles.custom.description : styles.description}
                    desktopStyles={customStyle === true ? desktopStyles.custom.description : desktopStyles.description}
                    mobileStyles={customStyle === true ? mobileStyles.custom.description : mobileStyles.description}
                    text={description}
                />
            }
            {
                !hideToggleSection &&
                <ProductChartToggleButtonSection
                    memberType={memberType}
                    setMemberType={setMemberTypeInSessionStorageAndInState}
                />
            }
            <ProductChartTabbedView
                sections={sections}
                disclaimer={insuranceDisclaimer}
                linkText={linkText}
                memberType={memberType}
                onSignup={onSignup}
                disableCta={disableCta}
                onProductSelectToCreateSubscription={onProductSelectToCreateSubscription}
                choosePlan={props.choosePlan}
                alreadySelectedProduct={props.alreadySelectedProduct}
                selectedProductInTab={props.selectedProductInTab}
                switchTab={props.switchTab}
            />
            <ProductChartSections
                onSignup={onSignup}
                sections={sections}
                productchartsectionpadding={props.productchartsectionpadding}
                choosePlan={props.choosePlan}
                disableCta={disableCta}
                memberType={memberType}
                onProductSelectToCreateSubscription={onProductSelectToCreateSubscription}
            />
            {
                openComparePlansLinkInNewTab === false &&
                <Link
                    desktopOnly='true'
                    to='/plan-details'
                    style={styles.link}
                    text={linkText}
                />
            }
            {
                openComparePlansLinkInNewTab === true &&
                <AnchorLink
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.link}
                    href='/plan-details'
                    desktoponly='true'
                    text={linkText}
                />
            }
        </ProductChartContent>
    );
};

export default ProductChart;