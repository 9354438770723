import React from 'react';
import RichText from '../elements/RichText';
import Title from '../elements/Title';
import SelectBox from '../elements/SelectBox';
import RadioButton from '../elements/RadioButton';
import SubmitButton from '../elements/SubmitButton';
import Link from '../elements/Link';
import Section from '../elements/Section';
import styled from 'styled-components';
import StringPlaceholderService from '../../services/StringPlaceholderService';

const ProductStyle = styled.div`
    width: 100%;
    display: flex;
`;

const AdminProductOffering = ({ products, content, selectedProduct, onSelectProduct, paymentMode, onSelectPaymentMode, onSubmit, hideSelectProduct, subscription, isDowngradeFlow, showBottomText = true, isSignupFlow = false, isSubmitDisabled }) => {
    const options = {
        renderText: (text) => {
            const properties = {
                selectedProduct: selectedProduct?.name
            };
            return StringPlaceholderService.replace(text, properties)
        }
    };
    const productsByPaymentMode = ( isDowngradeFlow && subscription.productType ==='PROTECT') ? null : products.filter(product => product.paymentMode === paymentMode);
    return (
        <>
            <Title style={{ marginTop: '1rem' }}>{content.title.title}</Title>
            <RichText text={content.title.description} options={options}></RichText>
            <ProductStyle>
                {!hideSelectProduct && productsByPaymentMode && <>{productsByPaymentMode.map(product => <SelectBox key={product.id} product={product} onSelectProduct={onSelectProduct} active={product.id === selectedProduct.id} />)}</>}
            </ProductStyle>
            {selectedProduct.name !== 'Free' && 
                    <>
                        { isDowngradeFlow && subscription?.billingFrequency === 'ANNUALLY' &&
                            <Section text="Billed Annually. (Annually-billed customers can’t switch to Monthly billing.)"/>
                        }
                        { isDowngradeFlow && subscription?.billingFrequency !== 'ANNUALLY' && 
                            <>
                                <Title style={{ marginTop: '1rem', fontSize: '16px', marginBottom: '0' }}>Payment Term</Title>
                                <RadioButton name='payment-mode' disabled={subscription?.billingFrequency === 'ANNUALLY'} label='Billed Monthly' paymentMode='Monthly' checked={paymentMode === 'Monthly'} onChange={(event) => onSelectPaymentMode(event, 'Monthly')} />
                            </>
                        }   
                         { !isDowngradeFlow &&
                            <>
                                <Title style={{ marginTop: '1rem', fontSize: '16px', marginBottom: '0' }}>Payment Term</Title>
                                <RadioButton name='payment-mode' disabled={subscription?.billingFrequency === 'ANNUALLY'} label='Billed Monthly' paymentMode='Monthly' checked={paymentMode === 'Monthly'} onChange={(event) => onSelectPaymentMode(event, 'Monthly')} />
                              {  subscription?.billingFrequency === 'ANNUALLY' && <RadioButton name='payment-mode' label='Billed Annually (Save 5%)' paymentMode='Annually' checked={paymentMode === 'Annually'} onChange={(event) => onSelectPaymentMode(event, 'Annually')} />}
                            </>
                        } 
                    </>
                    
            }            
            { showBottomText && selectedProduct.name === 'Free' && <Section text="Downgrading to Basic." />}
            <SubmitButton disabled={isSubmitDisabled} onClick={onSubmit} text={StringPlaceholderService.replace(content.title.linkText, { productType: selectedProduct?.displayName })}></SubmitButton>
            { !isSignupFlow && <Link style={{ fontSize: '14px' }} textalign="center" extraleftspace="5.5rem" extratopspace="2rem" extrabottomspace="1.5rem" text={isDowngradeFlow ? 'Cancel Downgrade' : 'Cancel Upgrade'} to='/admin/member-account' />}
            { isSignupFlow && <Link style={{ fontSize: '14px' }} textalign="center" extraleftspace="5.5rem" extratopspace="2rem" extrabottomspace="1.5rem" text='Cancel Signup' to='/admin/search' />}
        </>
    )
};

export default AdminProductOffering;
