import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import withAdminHeader from '../components/layout/withAdminHeader';
import AdminNotificationProvider from '../components/contexts/AdminNotificationProvider';
import AdminNotificationContext from '../components/contexts/AdminNotificationContext';
import AdminBundleOutOfAreaOutsideMWG from '../components/admin/bundle/AdminBundleOutOfAreaOutsideMWG';
import AdminBundleOutOfAreaWithInMWG from '../components/admin/bundle/AdminBundleOutOfAreaWithInMWG';
import AdminBundlePersonalInfoPage from '../components/admin/bundle/AdminBundlePersonalInfoPage';
import AdminChooseBundlePlanPage from '../components/admin/bundle/AdminChooseBundlePlanPage';
import AdminBundlePaymentPage from '../components/admin/bundle/AdminBundlePaymentPage';
import AdminBundleSubscriptionComplete from '../components/admin/bundle/AdminBundleSubscriptionComplete';
import AdminAccountAlreadyExist from '../components/admin/bundle/AdminAccountAlreadyExist';
import AdminIdcAccountAlreadyExist from '../components/admin/bundle/AdminIdcAccountAlreadyExist';
import AdminAAAMembershipExist from '../components/admin/bundle/AdminAAAMembershipExist';
import AdminCancelledIdcAccountExist from '../components/admin/bundle/AdminCancelledIdcAccountExist';
class AdminBundleRoutes extends Component {
    static contextType = AdminNotificationContext;
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false;
    }
    render() {
        return (
            <AdminNotificationProvider>
                    <Route exact strict path="/admin/bundle/personal-info" component={withAdminHeader(AdminBundlePersonalInfoPage)} />
                    <Route exact strict path="/admin/bundle/:subscriptionApplicationId/choose-plan" component={withAdminHeader(AdminChooseBundlePlanPage)} />
                    <Route exact strict path="/admin/bundle/outside_mwg" component={withAdminHeader(AdminBundleOutOfAreaOutsideMWG)} />
                    <Route exact strict path="/admin/bundle/inside_mwg" component={withAdminHeader(AdminBundleOutOfAreaWithInMWG)} />
                    <Route exact strict path="/admin/bundle/:subscriptionApplicationId/checkout/:id/payment" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <AdminBundlePaymentPage {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/bundle/:id/subscription-complete" component={withAdminHeader((props) => <AdminNotificationContext.Consumer>{notificationContext => <AdminBundleSubscriptionComplete {...props} notificationContext={notificationContext} />}</AdminNotificationContext.Consumer>)} />
                    <Route exact strict path="/admin/bundle/:subscriptionApplicationId/account-already-exist" component={withAdminHeader(AdminAccountAlreadyExist)} />
                    <Route exact strict path="/admin/bundle/:subscriptionApplicationId/cancelled-idc-account-exist" component={withAdminHeader(AdminCancelledIdcAccountExist)} />
                    <Route exact strict path="/admin/bundle/:subscriptionApplicationId/idc-account-already-exist" component={withAdminHeader(AdminIdcAccountAlreadyExist)} />
                    <Route exact strict path="/admin/bundle/:subscriptionApplicationId/aaa-membership-exist" component={withAdminHeader(AdminAAAMembershipExist)} />
            </AdminNotificationProvider>
        );
    }
}

export default AdminBundleRoutes;