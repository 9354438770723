
import React from "react";
import Section from "../elements/Section";
import styled from 'styled-components';
import faqbullet from './../../assets/faq-bullet.png';
import { mobile } from './../../styles/common';
import { colors } from "./../../styles/common";

const AccordionItemArrow=styled.span`
    padding:12px 15px 0 0;
    ${mobile`
    padding:12px 10px 5px 0;
    `}
`;

const CategoryTitle=styled.div`
   display: flex;
   margin: 20px;
   ${mobile`
    top: 0rem;
    background: ${colors.lightGrey};
    width: 100%;
    position: sticky;
    z-index: 1;
    height: auto;
   `} 
`;

const categroryTitleStyle = {
    color: `${colors.darkBlue}`,
    paddingBottom: '0rem',
    paddingTop:'0.5rem',
    letterSpacing: '0.5px',
    fontWeight: '500',
    display: 'inline'
}

const desktopQuestionStyle={
    fontSize: '20px',
}

const mobileQuestionStyle={
    fontSize: '20px',
}

const FaqCategory = ({currentCategory, category}) => {
    return (
        <CategoryTitle>
        <AccordionItemArrow>
          <img style={{transform: (currentCategory === category) ? 'rotate(90deg)': 'rotate(360deg) ',}} src={faqbullet} alt='' />
        </AccordionItemArrow>
        <Section text={category.fields.title} style={categroryTitleStyle} mobileStyles={mobileQuestionStyle} desktopStyles={desktopQuestionStyle}/>
        </CategoryTitle>
    );
  };

  export default FaqCategory;