import React from 'react';
import { AdminApi } from '../../configuration/ApplicationContext';
import ContentfulIds from '../../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, {
    SignUpFlowExtraContent,
    SignUpFlowFormContent
} from '../layout/ResponsiveSignupFlowLayout';
import Content from '../layout/Content';
import Title from '../elements/Title';
import Spinner from '../elements/Spinner';
import BaseAdminUpgradeTierCheckout from './BaseAdminUpgradeTierCheckout';
import AdminCheckoutForm from './forms/AdminCheckoutForm';
import AdminSummary from './summary/AdminSummary';
import Card from '../elements/Card';
import AdminContext from '../contexts/AdminContext';
import Breadcrumbs from '../common/Breadcrumbs';
import UtilsService from '../../services/UtilsService';
import InfoText from '../elements/InfoText';
import Notification from '../notifications/Notification';

declare var Z: any; //Zuora

export default class AdminNonMemberCheckoutPage extends BaseAdminUpgradeTierCheckout {
    static contextType = AdminContext;

    constructor(props) {
        super(props);
        this.state = {
            zuoraConfiguration: null,
            productPageURL: '/admin/non-member/product',
            checkoutPageURL: '/admin/non-member/product/checkout',
            subscriptionAction: 'CREATE'
        };
    }

    async componentDidMount() {
        await this.loadMemberDetails();
        this.loadPlans();
        this.setBreadCrumb();
        this.loadZuoraForm();
        this.loadContent(ContentfulIds.Admin.SignUpFlow.CheckoutPage)
        this.loadPersonalInformation();
    }
    onSubmit = (data) => {
        const ratePlanId = this.context.product.id;
        const { ...billingAddress } = this.state.personalInfo?.billingAddress || {};
        if (!data.values.billingAddress || Object.keys(data.errors).length > 0) {
            this.setState({ checkoutError: data.errors.billingAddress });
            return;
        }
        window.orderSuccess = async (referenceId) => {
            const { promoCodeRatePlanId, promoCode } = this.context.promoCodeData || {};
            this.setState({ isSubmitDisabled: true }); 
            const paymentMethodId = referenceId;
            this.trackPaymentSuccessEvent('Sales-Tool-New-Sale-Submit Payment');
            let response = await AdminApi.post(`/api/users/${this.context.searchResult.userId}/subscription`, { ratePlanId, paymentMethodId, billingAddress, promoCodeRatePlanId, promoCode  });
            if (!response) {
                this.setState({
                    isSubmitDisabled: false
                }); 
                return;
            }
            if (response.validationErrors) {
                const apiErrors = response.validationErrors.map(apiError => {
                    return apiError.message
                });
                this.setState({ checkoutError: apiErrors, zuoraConfiguration: null, isSubmitDisabled: false }, () => { this.loadZuoraForm(); });
            } else {
                this.props.history.push('/admin/non-member/subscription-complete');
            }        
        };
        window.orderFailure = (failureMessage) => {
            this.setState({ checkoutError: failureMessage, zuoraConfiguration: null, isSubmitDisabled: false }, () => { this.loadZuoraForm(); });
        };
        Z.submit();
    };

    setBreadCrumb = () => {
        const breadCrumbRoutes = [
            {
                to: '/admin/search/noresults', label: 'New AAA Digital + IDC Signup'
            },
            {
                to: '/admin/personal-info-non-member', label: '1: Personal Details'
            },
            {
                to: `${this.state.productPageURL}/${this.props?.match?.params?.productId}`, label: '2: Options'
            },
            {
                to: `${this.state.checkoutPageURL}/${this.props?.match?.params?.productId}`, label: '3: Payment'
            }
        ]
        this.setState({ breadCrumbRoutes });
    }

    render() {
        const { zuoraConfiguration, content, checkoutError, product, billingAddressSameAsHomeAddress, personalInfo, breadCrumbRoutes, isSubmitDisabled } = this.state;
        const searchResult = JSON.parse(localStorage.getItem('searchResult'));
        const { notifications } = this.props.notificationContext;
        if (!zuoraConfiguration || !content || !product || isSubmitDisabled) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{ marginTop: '0rem' }}>
                <InfoText>
                    {notifications.map(notification => {
                        return <Notification key={notification.notificationType} {...notification} {...this.props} />
                    }
                    )}
                </InfoText>
                <Breadcrumbs isCustomerFacing={false} breadCrumbRoutes={breadCrumbRoutes}></Breadcrumbs>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title style={{ marginTop: '3rem' }}>{content.title}</Title>
                        <AdminCheckoutForm zuoraConfiguration={zuoraConfiguration} content={content} onSubmit={this.onSubmit}
                            personalInfo={personalInfo} checkoutError={checkoutError}
                            showDeclineLink={false} isSubmitDisabled={isSubmitDisabled}
                            onSelectAddress={this.onSelectAddress} selectedProduct={product}
                            billingAddressSameAsHomeAddress={billingAddressSameAsHomeAddress}
                            onApplyPromoCode={this.onApplyPromoCode}
                            promoCodeOptions={{ validateURL: `/api/promo-codes/users/${searchResult?.userId}/validate`, 
                            zuoraRatePlanURL: `/api/products/users/${searchResult?.userId}/zuora-rate-plans?subscriptionAction=${this.state.subscriptionAction}`, mapProductsFunc: UtilsService.mapProductsToArray}}        
                         />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        <Card>
                            <AdminSummary product={product} title={`If Customer chooses ${product.name}`} />
                        </Card>
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}
