import React from 'react';
import styled, { css } from 'styled-components';
import Section from '../elements/Section';
import { colors, desktop, mobile } from '../../styles/common';
import RichText from '../elements/RichText';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import { BLOCKS } from '@contentful/rich-text-types';
import HorizontalRule from '../elements/HorizontalRule';

const ProductFeatureContainer = styled.div`
    width: 30%;
    padding: 30px 0px;
    text-align: center;
    ${mobile`
       width: 100%;
       ${props => props.additionalPadding && css`
          padding: 10px 0px;
       `};
    `}
`;

const Image = styled.img`
   ${props => props.iconFullWidth && css`
      width : 100%;
  `};
`;

const BackgroundImageSection = styled.div`
    height: 120px;
    width: 120px;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    ${ mobile `
       margin: auto;
    `}
`;

const SectionContainer = styled.div`
    ${ mobile `
       display: flex;
       flex-direction: column;
       width: 100%;
       padding-top: 10px;
    `}
`;

const mobileNameStyle = {
    flex:1,
    fontSize: '25px',
    textAlign: 'center',
    padding: 0
}

const nameStyle = {
    fontWeight: 500,
    fontSize: '27px',
    lineHeight: '36px',
    textAlign: 'center',
    padding: '17px 0px 0px 0px'
};

const DescriptionStyle = styled.div`
    ${props => props.showLine && css`
        ${desktop`
            border-right: 1px solid ${colors.pureWhite};
            padding-right: 2rem;
        `}
        ${mobile`
            padding-top: 0rem;
        `}
    `};
`;
const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle style={{ color: colors.pureWhite, paddingTop: '5px' }} desktopFontSize='16px' mobileFontSize='16px'>{children}</ParagraphStyle>,
    }
};

const ProductFeatureContent = ({ logo, text, lineOne, lineTwo, index, sectionLength, ...props }) => {
    return (
        <ProductFeatureContainer {...props}>
            <BackgroundImageSection>
                <Image src={logo} {...props} />
            </BackgroundImageSection>
            <SectionContainer>
                <Section mobileStyles={mobileNameStyle} style={{ color: `${colors.pureWhite}`, fontWeight: 500}} text={text} desktopStyles={nameStyle}></Section>
                <DescriptionStyle showLine={index < sectionLength - 1}>
                    <RichText text={lineOne} options={options} />
                    <HorizontalRule desktopOnly='true' style={{ width: '25%' }} />
                    <RichText text={lineTwo} options={options} />
                </DescriptionStyle>
            </SectionContainer>
        </ProductFeatureContainer>
    );
};

export default ProductFeatureContent;