import {setValidationNotification} from './Helpers'

export const ApiNotification = {
        digitalAccount: {
            setNotification: setValidationNotification('That e-mail is associated with another IDC account. Please use a different email address, or leave field blank. (Not a mandatory field).', 'error'),
            customErrorMessages: {
                email: 'That e-mail is associated with another IDC account. Please change or leave blank.',
                retypeEmail: ' ',
            }
        },
        cancelledMembershipNumber: {
            setNotification: setValidationNotification('Sorry, the account with that AAA Member Number has been cancelled. Please enter a different AAA Member Number.', 'error'),
            customErrorMessages: {
                membershipNumber: 'This account has been cancelled. Please enter a valid AAA Membership Number.'
            }
        },
        address: {
            setNotification: setValidationNotification('Please enter an Arizona, Northern California, Nevada, Montana, Utah or Wyoming address', 'error'),
            customErrorMessages: {
                homeAddress: 'Please enter an Arizona, Northern California, Nevada, Montana, Utah or Wyoming address'
            }
        },
        alreadyActiveSubscription: {
            setNotification: setValidationNotification('That email address is already associated with an IDC account. The user may login using that account. To continue creating a new account, use a different email address.', 'error'),
            customErrorMessages: {
                email: 'That e-mail is associated with another IDC account.',
                retypeEmail: ' ',
            }
        },
}