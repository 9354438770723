import React from 'react';
import styled, {css} from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToolTip from '../tooltip/ToolTip';
import DynamicParagraphText from './DynamicParagraphText';

const Label = styled.label`
    font-weight: normal;
    font-style: normal;
    font-size: 17px;
    padding: 5px 0;
    display: block;   
    margin-top: ${props => props.extraTopLabelSpace};
    ${props => props.hide && css`
        display: none; 
    `}
`;

const LabelWithTooltip = ({label, ...props}) => {
    return (
        <>
            <Label htmlFor={props.name} {...props}>{label}&nbsp;
                {props.tooltipIcon && <FontAwesomeIcon data-tip data-for="date-tooltip" style={{color: '#D7D8D9'}} icon={ props.tooltipIcon } />}                
                <ToolTip id="date-tooltip" content="We need your Date of Birth to confirm your identity and configure your identity protection services."/> 
            </Label>
            <DynamicParagraphText text={props.value}/>
        </>
    );
};

export default LabelWithTooltip;
