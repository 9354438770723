import React, { Component } from "react";
import LandingContent from './../layout/LandingContent';
import ResponsiveLandingPageLayout from './../layout/ResponsiveLandingPageLayout';
import Spinner from './../elements/Spinner';
import MarketingFaqSection from './MarketingFaqSection';
import ContentfulIds from './../../configuration/ContentfulIds';
import { ContentfulService } from "./../../configuration/ApplicationContext";
import { colors } from "../../styles/common";
import withZipGate from "../layout/withZipGate";
import withPageTitle from "../layout/withPageTitle";
import withPageMetaTags from "../layout/withPageMetaTags";
import { META_DESCRIPTION } from "../common/Constants";
import { getPromoCodeNameFromRedirectParams } from "../common/Helpers";

class FaqsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    await this.loadContent();
  }

  onSignup = async () => {
    this.props.history.push(`/product/`);
  };

  setUrl = () => {
    if (!window.history || !window.history.pushState) { return; }
     if (this.state.currentCategory && this.state.questionIdParam) {
       window.history.pushState('', '', `/faqs/categories/${this.state.currentCategory.sys.id}/questions/${this.state.questionIdParam}`);
     } else 
    if (this.state.currentCategory) {
      window.history.pushState('', '', `/faqs/categories/${this.state.currentCategory.sys.id}`);
    } else {
      window.history.pushState('', '', '/faqs');
    }
  }

  selectQuestion = (event, question) => {
    if (question.sys.id === this.state.questionIdParam) {
      event.stopPropagation();
      this.setState({
        questionIdParam: question.sys.id
      }, () => { this.setUrl(); });
    }
  }

   selectQuestion = (event, question) => {
     if (question.sys.id === this.state.questionIdParam) {
       this.deSelectSection();
       event.stopPropagation();
     } else {
       event.stopPropagation();
       this.setState({
         questionIdParam: question.sys.id
       }, () => { this.setUrl(); });
     }
   }

  selectCategory = (category) => {
    if (category === this.state.currentCategory) {
      this.deSelectCategory();
    } else {
      this.setState({
        currentCategory: category,
        questionIdParam: null
      }, () => { this.setUrl(); });
    }
  }

   deSelectSection = () => {
     this.setState({
       questionIdParam: null
     }, () => { this.setUrl(); });
   }

  deSelectCategory = () => {
    this.setState({
      currentCategory: this.state.content.sections[0],
      questionIdParam: null
    }, () => { this.setUrl(); });
  }

  loadContent = async () => {
    const response = await ContentfulService.getEntries({ 'sys.id': ContentfulIds.FaqsPage, include: 5 });
    this.setState({
      content: response.items[0].fields
    });
    //const { questionId } = this.props.match.params;
   // const category = this.state.content.category;
   // this.setState({
   //     currentCategory: category ,
   //     questionIdParam: questionId,
   //   });
   // }    

     const { categoryId, questionId } = this.props.match.params;
     const sections = this.state.content.sections;
     const category = sections.filter(section => section.sys.id === categoryId).map(section => section)[0];
   this.setState({
       currentCategory: category || sections[0],
       questionIdParam: questionId,
     });
   }

  render() {
    const { content, currentCategory, questionIdParam } = this.state;
    if (!content) {
      return <Spinner />
    }
    return (
      <LandingContent
        promocodename={getPromoCodeNameFromRedirectParams()}
        style={{ background: `${colors.lightGrey}` }}>
        <ResponsiveLandingPageLayout>
          <MarketingFaqSection
            content={content}
            currentCategory={currentCategory}
            questionIdParam={`${questionIdParam}`}
            selectQuestion={this.selectQuestion}
            selectCategory={this.selectCategory}
            onSignup={this.onSignup}
          />
        </ResponsiveLandingPageLayout>
      </LandingContent>
    );
  }
}

export default withPageMetaTags(withPageTitle(withZipGate(FaqsPage), 'Identity Protection FAQs'), { description: META_DESCRIPTION.FAQS });