import React from 'react';
import styled from 'styled-components';
import SubmitButton from '../elements/SubmitButton';
import { colors } from '../../styles/common';
import InfoText from '../elements/InfoText';
import ParagraphText from '../elements/ParagraphText';
import DialogBox from '../elements/DialogBox'
import Span from '../elements/Span';
import { NOT_AVAILABLE } from '../common/Constants';

const Title = styled.div`
    font-size: 1.375rem;
    font-weight: 500;
    padding-top: 1rem;
    padding-bottom: 2rem;
    color: ${colors.titleText};
`;

const styles = {
    bold: { fontWeight: 700 },
    paragraphPromo: { fontSize: '16px', marginLeft: '3.5rem' },
    paragraph: { marginTop: '1rem' },
    paragraphBold: { fontWeight: 500, marginTop: '1.5rem', marginBottom: '0.5rem' },
    customButton: { width: '100%', background: colors.pureWhite, border: `2px solid ${colors.darkBlue}`, color: colors.darkBlue },
    button: { width: '100%' },
    mobileButton: { fontSize: '14px' },
    description: { fontSize: '16px', fontWeight: 400, lineHeight: '1.5rem' }

};

const ReplacePromoCodeModal = (props) => {
    const { isOpen, closeModal, promoCode, promoCodeFromSession, newPromoCodeDescription } = props;

    if (!promoCode || !promoCodeFromSession) {
        return null;
    }

    return (
        <DialogBox
            isOpen={isOpen}
            closeModal={closeModal}>
            <Title>
                Confirm Promo Code Replacement
            </Title>
            <InfoText
                style={styles.description}>
                Replacing this code:
                <ParagraphText
                    style={styles.paragraphPromo}>
                    <Span
                        style={styles.bold}
                        text={promoCodeFromSession.promoCode}
                    />
                    <Span
                        text={`: ${promoCodeFromSession.campaign}`}
                    />
                </ParagraphText>
            </InfoText>
            <InfoText
                style={styles.description}>
                With this code:
                <ParagraphText
                    style={styles.paragraphPromo}>
                    <Span
                        style={styles.bold}
                        text={promoCode.toUpperCase()}
                    />
                    <Span
                        text={`: ${newPromoCodeDescription || NOT_AVAILABLE}`}
                    />
                </ParagraphText>
            </InfoText>
            <InfoText
                style={styles.description}>
                (Only one promo may be applied.)
            </InfoText>
            <ParagraphText
                style={styles.paragraphBold}>
                Are you sure?
            </ParagraphText>
            <SubmitButton
                style={styles.button}
                mobileStyles={styles.mobileButton}
                text={`Yes - Apply ${promoCode}`}
                onClick={props.addPromo}
            />
            <SubmitButton
                style={styles.customButton}
                mobileStyles={styles.mobileButton}
                text={`No - Keep ${promoCodeFromSession.promoCode}`}
                onClick={props.keepPromoCode}
            />
        </DialogBox>
    );
}

export default ReplacePromoCodeModal;
