import React from 'react';
import ContentfulIds from '../../configuration/ContentfulIds';
import Content from '../layout/Content';
import Spinner from '../elements/Spinner';
import FormError from '../elements/FormError';
import BaseAdminProductOffering from './BaseAdminProductOffering';
import AdminContext from '../contexts/AdminContext';
import Breadcrumbs from '../common/Breadcrumbs';
import { AdminApi } from '../../configuration/ApplicationContext';
import { buildUrl } from '../common/Helpers';
import { colors } from '../../styles/common';
import ProductChart from '../v1/product-chart/customer/ProductChart';
import RichText from '../elements/RichText';
import { NON_AAA_MEMBER, BASIC_LOWER_CASE, REJOIN, SIGNUP } from '../common/Constants';
import Title from '../elements/Title';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import DynamicParagraphText from '../elements/DynamicParagraphText';

const styles = {
    title: { color: `${colors.titleText}`, margin: 0, fontWeight: 'normal', fontSize: '32px', lineHeight: '43x', paddingBottom: '2rem' },
};
const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText
    {...props}
    text={children}
    style={{ textAlign: 'justify', margin: 0, color: colors.verylightGrey, lineHeight: '20px', paddingTop: '1rem' }}
/>;
var options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) =>
            <ParagraphStyle
                anchor={true}
                desktopOnly='true'
                desktopFontSize='12px'
                mobileFontSize='12px'
            >
                {children}
            </ParagraphStyle>,
        [INLINES.HYPERLINK]: (node, next) => {
            return <a href={node.data.uri}>{node.content[0].value}</a>;
        }
    },
};
export default class AdminProductOfferingPage extends BaseAdminProductOffering {
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            selectedProduct: null,
            paymentMode: '',
            productPageURL: '/admin/product',
            checkoutPageURL: '/admin/product/checkout',
            subscriptionAction: 'CREATE',
            submitted: false,
            apiErrors: [],
            urlParams: {},
            validateExistingAccount: false
        }
    }

    async componentDidMount() {
        const userType = this.props.match.params.userType;
        this.setState({ userType });
        if (userType !== NON_AAA_MEMBER) {
            await this.context.getMemberDetails();
        }
        this.validateExistingAccountAndRedirect(NON_AAA_MEMBER);
        this.checkRejoinFlow();
        this.setBreadCrumb();
        this.loadProductChartContent(ContentfulIds.Admin.ChooseYourPlanPageV1);
    }
    getContentFulID = (key) => {
        const contentFulMap = new Map()
            .set(REJOIN, ContentfulIds.Admin.RejoinFlow.ProductOfferingPage)
            .set(SIGNUP, ContentfulIds.Admin.SignUpFlow.ProductOfferingPage);
        return contentFulMap.get(key);
    }
    checkRejoinFlow = () => {
        const params = new URLSearchParams(this.props.location.search).get('action') || SIGNUP;
        if (params === REJOIN) {
            this.setState({
                reactivateUser: true,
                urlParams: {
                    action: params
                }
            })
        }
        this.loadPlans(this.getContentFulID(params));

    }

    setBreadCrumb = () => {
        const { personalInformation } = this.context.memberDetails || {};
        const breadCrumbRoutes = [];
        const userType = this.props.match.params.userType;
        if (userType !== NON_AAA_MEMBER) {
            breadCrumbRoutes.push({ to: `/admin/search?login-id=${this.context.getLoginId()}`, label: personalInformation.displayName });
        }
        const personalInformationPageUrl = userType !== NON_AAA_MEMBER
            ? buildUrl('/admin/personal-info', this.state.urlParams)
            : '/admin/non-aaa-member/personal-information';
        const productSelectionPageUrl = userType !== NON_AAA_MEMBER
            ? `${this.state.productPageURL}/${this.props.match.params.id}`
            : `/admin/non-aaa-member/checkout/product/${this.props.match.params.id}`;
        breadCrumbRoutes.push(
            {
                to: personalInformationPageUrl, label: `${this.state?.reactivateUser ? 'Reactivate 1:' : 'Signup 1'} Personal Details`
            },
            {
                to: productSelectionPageUrl, label: '2: Options'
            }
        );
        this.setState({ breadCrumbRoutes });
    }

    onSubmitProductForAaaMember = async (ratePlanId) => {
        if (ratePlanId === BASIC_LOWER_CASE) {
            let response = await AdminApi.post(`/api/users/${this.context.searchResult.userId}/subscription`, { ratePlanId });
            if (!response) {
                this.setState({
                    isSubmitDisabled: false
                });
                return;
            }
            if (response.validationErrors) {
                const apiErrors = response.validationErrors.map(apiError => {
                    return apiError.message
                });
                this.setState({
                    apiErrors,
                    submitted: true,
                    isSubmitDisabled: false
                });
            } else {
                this.props.history.push(buildUrl(`/admin/subscription-complete`, this.state.urlParams));
            }
        } else {
            this.props.history.push(buildUrl(this.state.checkoutPageURL + `/${ratePlanId}`, this.state.urlParams));
        }
    }

    onSubmitProductForNonAaaMember = async (ratePlanId) => {
        this.props.history.push(`/admin/${NON_AAA_MEMBER}/checkout/${ratePlanId}/payment`);
    }
    onSubmitProduct = async (ratePlanId) => {
        const { userType } = this.state || {};
        this.setState({
            isSubmitDisabled: true
        });
        if (userType !== NON_AAA_MEMBER) {
            this.onSubmitProductForAaaMember(ratePlanId);
        } else {
            this.onSubmitProductForNonAaaMember(ratePlanId);
        }
    }
    onProductSelectToCreateSubscription = (productName, paymentMode) => {
        const { products, userType } = this.state || {};
        if (!products) {
            return;
        }
        const selectedProduct = products.find(product =>
            product.displayName.toLowerCase() === productName.toLowerCase()
            && product.paymentMode.toLowerCase() === paymentMode.toLowerCase()
        );
        this.setState({ selectedProduct });
        const productId = productName.toLowerCase() === BASIC_LOWER_CASE ? `${BASIC_LOWER_CASE}` : selectedProduct?.id;
        if (!productId) {
            return;
        }
        const updatePageUrl = userType !== NON_AAA_MEMBER ? `/admin/product/${productId}` : `/admin/${NON_AAA_MEMBER}/checkout/product/${productId}`;
        this.props.history.push(updatePageUrl);
        this.onSubmitProduct(productId);
    }

    render() {
        const {
            products,
            isSubmitDisabled,
            breadCrumbRoutes,
            submitted,
            apiErrors,
            productChartContent,
            validateExistingAccount
        } = this.state;
        if (products.length === 0 || !productChartContent || !validateExistingAccount || isSubmitDisabled) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{ marginTop: 0 }}>
                <Breadcrumbs isCustomerFacing={false} breadCrumbRoutes={breadCrumbRoutes}></Breadcrumbs>
                <FormError showErrors={submitted} errors={apiErrors} />
                <Title
                    style={styles.title}>
                    {productChartContent.title}
                </Title>
                <ProductChart
                    backgroundcolor={colors.pureWhite}
                    showTitle={false}
                    showDescription={false}
                    openComparePlansLinkInNewTab={true}
                    customStyle={true}
                    productchartsectionpadding='0px'
                    fields={productChartContent}
                    choosePlan={true}
                    hideToggleSection={true}
                    onProductSelectToCreateSubscription={this.onProductSelectToCreateSubscription}
                />
                <RichText
                    text={productChartContent.joinAaaText}
                    options={options}
                />
            </Content>
        );
    }
}
