
import React, { Component } from 'react';
import { ContentfulService } from '../configuration/ApplicationContext';
import ContentfulIds from '../configuration/ContentfulIds';
import Content from './layout/Content';
import SignupFlowStepProgress from './elements/SignUpFlowStepProgress';
import BasicCoverageDetails from './products/BasicCoverageDetails';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from './layout/ResponsiveSignupFlowLayout';
import DataContext from './contexts/DataContext';
import Spinner from './elements/Spinner';
import withPageTitle from "./layout/withPageTitle";
import { checkLoginWithAAA } from './common/Helpers';
import { dynamicSignUpFlowSteps } from './common/Steps';
import withPageMetaTags from './layout/withPageMetaTags';

class BasicCoveragePage extends Component {
    static contextType = DataContext;
    constructor(props) {
        super(props);
        this.state = {
            content: null,
        }
    }

    componentDidMount() {
        this.context.updateState({product: null});
        this.loadContent();
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.BasicCoverage);
        this.setState({content: response.fields});
    }

    onSubmit = async () => {
        this.setState({
            isSubmitDisabled: true
        });
        checkLoginWithAAA() ? this.props.history.push('/login-with-aaa/basic') : this.props.history.push('/checkout/basic/personal-info');
    }

    render() {
        const { content, isSubmitDisabled } = this.state;
        if (!content) {
            return <Spinner />;
        }
        return (
            <Content>
                <SignupFlowStepProgress currentStep={1} steps={dynamicSignUpFlowSteps(true)} />
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <BasicCoverageDetails content={content}
                            onSubmit={this.onSubmit}
                            redirectToPlan={(ratePlanId) => this.props.history.push(`/product/${ratePlanId}`)}
                            isSubmitDisabled={isSubmitDisabled}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageMetaTags(withPageTitle(BasicCoveragePage, "Decline Upgrade"), { noindex: true });