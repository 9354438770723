import React from 'react';
import { AdminApi } from '../../configuration/ApplicationContext';
import ContentfulIds from '../../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, {
    SignUpFlowExtraContent,
    SignUpFlowFormContent
} from '../layout/ResponsiveSignupFlowLayout';
import Content from '../layout/Content';
import Title from '../elements/Title';
import Spinner from '../elements/Spinner';
import BaseAdminUpgradeTierCheckout from './BaseAdminUpgradeTierCheckout';
import AdminCheckoutForm from './forms/AdminCheckoutForm';
import AdminSummary from './summary/AdminSummary';
import Card from '../elements/Card';
import AdminContext from '../contexts/AdminContext';
import Breadcrumbs from '../common/Breadcrumbs';
import InfoText from '../elements/InfoText';
import Notification from '../notifications/Notification';
import UtilsService from '../../services/UtilsService';
import { buildUrl, deleteIndividualPromoCodeNameValueFromSessionStorage, subscriptionApplicationIdSessionStorage } from '../common/Helpers';
import { NON_AAA_MEMBER, REJOIN, SIGNUP } from '../common/Constants';

declare var Z: any; //Zuora
export default class AdminCheckoutPage extends BaseAdminUpgradeTierCheckout {
    static contextType = AdminContext;

    constructor(props) {
        super(props);
        this.state = {
            zuoraConfiguration: null,
            productPageURL: '/admin/product',
            checkoutPageURL: '/admin/product/checkout',
            subscriptionAction: 'CREATE',
            reactivateUser: false,
            urlParams: {}
        };
    }

    async componentDidMount() {
        await this.loadMemberDetails();
        this.checkRejoinFlow();
        this.loadPlans();
        this.setBreadCrumb();
        this.loadZuoraForm();
        this.loadPersonalInformation();
        this.context.removePromoCodeData();
    }
    getContentFulID = (key) => {
        const contentFulMap = new Map()
            .set(REJOIN, ContentfulIds.Admin.RejoinFlow.CheckoutPage)
            .set(SIGNUP, ContentfulIds.Admin.SignUpFlow.CheckoutPage);
        return contentFulMap.get(key);
    }
    checkRejoinFlow = () => {
        const params = new URLSearchParams(this.props.location.search).get('action') || SIGNUP;
        if (params === REJOIN) {
            this.setState({
                reactivateUser: true,
                urlParams: {
                    action: params
                }
            })
        }
        this.loadContent(this.getContentFulID(params))
    }

    setBreadCrumb = () => {
        const userType = this.props.match.params.userType;
        this.setState({ userType });
        const { personalInformation } = this.context.memberDetails || {};
        const breadCrumbRoutes = [];
        if (userType !== NON_AAA_MEMBER) {
            breadCrumbRoutes.push({
                to: `/admin/search?login-id=${this.context.getLoginId()}`, label: personalInformation?.displayName
            });
        }
        const personalInformationPageUrl = userType !== NON_AAA_MEMBER
            ? buildUrl('/admin/personal-info', this.state.urlParams)
            : '/admin/non-aaa-member/personal-information';
        const productSelectionPageUrl = userType !== NON_AAA_MEMBER
            ? buildUrl(`${this.state.productPageURL}/${this.props.match.params.productId}`, this.state.urlParams)
            : `/admin/non-aaa-member/checkout/product/${this.props.match.params.id}`;
        const paymentPageUrl = userType !== NON_AAA_MEMBER
            ? `${this.state.checkoutPageURL}/${this.props.match.params.productId}`
            : `/admin/non-aaa-member/checkout/${this.props.match.params.productId}/payment`
        breadCrumbRoutes.push(
            {
                to: personalInformationPageUrl, label: `${this.state?.reactivateUser ? 'Reactivate 1:' : 'Signup 1'} Personal Details`
            },
            {
                to: productSelectionPageUrl, label: `${this.state.reactivateUser ? '2: Choose Plan' : '2: Options'} `
            },
            {
                to: paymentPageUrl, label: '3: Payment'
            });
        this.setState({ breadCrumbRoutes });
    }
    onSubmit = (data) => {
        const ratePlanId = this.context.product.id;
        const { ...billingAddress } = this.state.personalInfo?.billingAddress || {};
        if (!data.values.billingAddress || Object.keys(data.errors).length > 0) {
            this.setState({ checkoutError: data.errors.billingAddress });
            return;
        }
        this.setState({
            checkoutError: ""
        });
        window.orderSuccess = async (referenceId) => {
            const { promoCodeRatePlanId, promoCode } = this.context.promoCodeData || {};
            const { userType } = this.state || {};
            this.setState({
                isSubmitDisabled: true
            });
            const paymentMethodId = referenceId;
            const rejoin = this.state.reactivateUser;
            if (!paymentMethodId || !ratePlanId) {
                this.setState({
                    isSubmitDisabled: false
                });
                return;
            }
            let response = userType !== NON_AAA_MEMBER
                ? await AdminApi.post(`/api/users/${this.context.searchResult.userId}/subscription`, {
                    ratePlanId,
                    paymentMethodId,
                    billingAddress,
                    promoCodeRatePlanId,
                    promoCode,
                    rejoin
                })
                : await AdminApi.post('/api/orders', {
                    ratePlanId,
                    paymentMethodId,
                    billingAddress,
                    promoCodeRatePlanId,
                    promoCode,
                    nonAaaMember: true,
                    subscriptionApplicationId: sessionStorage.getItem(subscriptionApplicationIdSessionStorage)
                });
            if (!response) {
                this.setState({
                    isSubmitDisabled: false
                });
                return;
            }
            if (response.validationErrors) {
                const apiErrors = response.validationErrors.map(apiError => {
                    return apiError.message
                });
                this.setState({
                    checkoutError: apiErrors,
                    zuoraConfiguration: null,
                    isSubmitDisabled: false
                }, () => {
                    this.loadZuoraForm();
                });
            } else {
                const { userType } = this.state || {};
                deleteIndividualPromoCodeNameValueFromSessionStorage();
                const subscriptionCompleteRedirectionUrl = userType !== NON_AAA_MEMBER
                    ? buildUrl(`/admin/subscription-complete`, this.state.urlParams)
                    : `/admin/${NON_AAA_MEMBER}/checkout/subscription-complete`;
                this.props.history.push(subscriptionCompleteRedirectionUrl);
            }
        };

        window.orderFailure = (failureMessage) => {
            this.setState({ checkoutError: failureMessage, zuoraConfiguration: null, isSubmitDisabled: false }, () => { this.loadZuoraForm(); });
        };
        Z.submit();
    };

    render() {
        const {
            zuoraConfiguration,
            content,
            checkoutError,
            personalInfo,
            product,
            billingAddressSameAsHomeAddress,
            breadCrumbRoutes,
            isSubmitDisabled,
            userType
        } = this.state;
        const {
            notifications
        } = this.props.notificationContext;
        const subscriptionApplicationId = sessionStorage.getItem(subscriptionApplicationIdSessionStorage);
        const searchResult = JSON.parse(localStorage.getItem('searchResult'));
        let params = {};
        if (subscriptionApplicationId) {
            params['subscription-application-id'] = subscriptionApplicationId;
        }
        params['isNonAaaMember'] = true;
        const promoCodeOptions = userType === NON_AAA_MEMBER
            ? {
                validateURL: `/api/promo-codes/validate?isNonAaaMember=true`,
                zuoraRatePlanURL: buildUrl('/api/products/zuora-rate-plans', params),
                mapProductsFunc: UtilsService.mapProductsToArray
            }
            : {
                validateURL: `/api/promo-codes/users/${searchResult?.userId}/validate`,
                zuoraRatePlanURL: `/api/products/users/${searchResult?.userId}/zuora-rate-plans?subscriptionAction=${this.state.subscriptionAction}`,
                mapProductsFunc: UtilsService.mapProductsToArray
            };
        if (!zuoraConfiguration || !content || !product || isSubmitDisabled) {
            return <Spinner />;
        }
        return (
            <Content
                desktopStyles={{ marginTop: '0rem' }}>
                <InfoText>
                    {
                        notifications.map(notification => {
                            return <Notification
                                key={notification.notificationType}
                                {...notification}
                                {...this.props}
                            />
                        }
                        )
                    }
                </InfoText>
                <Breadcrumbs
                    breadCrumbRoutes={breadCrumbRoutes}
                    isCustomerFacing={false}
                />
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title
                            style={{ marginTop: '3rem' }}>
                            {content.title}
                        </Title>
                        <AdminCheckoutForm
                            zuoraConfiguration={zuoraConfiguration}
                            content={content}
                            onSubmit={this.onSubmit}
                            personalInfo={personalInfo}
                            checkoutError={checkoutError}
                            billingAddressSameAsHomeAddress={billingAddressSameAsHomeAddress}
                            onSelectAddress={this.onSelectAddress} selectedProduct={product}
                            showDeclineLink={false} isSubmitDisabled={isSubmitDisabled}
                            onApplyPromoCode={this.onApplyPromoCode}
                            promoCodeOptions={promoCodeOptions}
                        />
                    </SignUpFlowFormContent >
                    <SignUpFlowExtraContent>
                        <Card>
                            <AdminSummary
                                product={product}
                                title={`If Customer chooses ${product.name}`}
                            />
                        </Card>
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout >
            </Content >
        );
    }
}
