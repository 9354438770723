import styled, { css } from 'styled-components'
import {desktopOnly, mobileOnly} from '../../styles/common'

const OverlayCard = styled.div`
    position: absolute;
    left: 3rem;
    top: 25%;
    width: 680px;
    height: auto;
    padding: 2rem 2rem 0rem 2rem;
    text-align: left;
    ${props => props.mobileOnly && css`
      ${mobileOnly};
      top: 15%;
   `}

   ${props => props.desktopOnly && css`
      ${desktopOnly}
   `};
  
`;

export default OverlayCard;
