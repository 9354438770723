import React, {Component} from 'react';
import ImageComponent from '../elements/ImageComponent';
import { ContentfulService } from '../../configuration/ApplicationContext';

class ImageAsset extends Component {
  static canUseWebP() {
    if (ImageAsset.webPSupported !== null && ImageAsset.webPSupported !== undefined) {
      return ImageAsset.webPSupported;
    }
    const elem = document.createElement('canvas');
    if (elem.getContext && elem.getContext('2d')) {
      ImageAsset.webPSupported = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    } else {
      ImageAsset.webPSupported = false;
    }
    return ImageAsset.webPSupported;
  }
  constructor() {
    super();
    this.state = {
      src: null,
      alt: null,
    };
  }
  componentDidMount() {
    const params = ImageAsset.canUseWebP() ? '?fm=webp' : '';
    ContentfulService.getAsset(this.props.image.sys.id).then((resp) => {
      this.setState({
        src: `https:${resp.fields.file.url}${params}`,
        alt: resp.fields.title
      });
    });
  }
  render() {
    return (
      <div>
        { this.state.src ? <ImageComponent src={this.state.src} height={this.props.height} width={this.props.width} alt={this.state.alt} {...this.props} /> : '' }
      </div>
    );
  }
}

export default ImageAsset;  