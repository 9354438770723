import React, { Component } from 'react';
import DataContext from './DataContext';
import { Api , segmentAnalytics, variation } from '../../configuration/ApplicationContext';
import { capitalizeFirstLetter, subscriptionApplicationIdSessionStorage } from '../common/Helpers';
import Spinner from '../elements/Spinner';

class DataProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ratePlanIds: null,
            promoCodeData: null,
            isLoggedIn: false,
            variation: false
        };
    }

    async componentDidMount() {
        this.checkUserLoggedIn();
        this.getRatePlanIds(); 
        this.getPromoCodeData();
        this.getUserDetails(); 
        this.getOptimiselyVariations();  
    }

    getOptimiselyVariations = () => {
        this.setState({ variation });
    }

    updateState = (values) => {
        this.setState(values)
    }

    refreshContext = async () => {
        await this.getUserDetails();
    }

    checkUserLoggedIn = async () => {
        if (!this.state.isLoggedIn) {
            const response = await Api.get("/api/users/user/logged-in", true);
            if (response && response.status === 200) {
                this.setState({
                    isLoggedIn: true
                });
                sessionStorage.setItem('isLoginWithAAA', true);
            }   
        }
    }

    getUserDetails = async () => {
        let data = {};
        const response = await Api.get('/api/users/user-details');
        if (response) {
            data.userDetails = response;
            if (response.personalInformation != null && response.personalInformation.email != null) {
                segmentAnalytics.checkForOptOutStatus(response.personalInformation.email);
            }
            const { basePrice, actualPrice, discount, termEndDate, billingFrequency, productType, lastPaymentAmount } = data.userDetails.subscription || {};
            if (data.userDetails && data.userDetails.subscription) {
                data.product = {
                    basePrice,
                    actualPrice,
                    discount,
                    coverageThrough: termEndDate,
                    name: capitalizeFirstLetter(productType),
                    paymentMode: capitalizeFirstLetter(billingFrequency),
                    tax: actualPrice?.tax,
                    nextPaymentAmountPreviewWithPromocode: true,
                    totalRefundAmount: actualPrice?.total - lastPaymentAmount > 0 ? actualPrice?.total - lastPaymentAmount : null
                };
            }
            this.setState(data);
        } else {
            this.setState({
                userDetails: {}
            });
        }
    }

    getPromoCodeData = () => {
        let promoCodeData = localStorage.getItem('promoCodeData');
        if (promoCodeData !== 'undefined' || !promoCodeData) {
            promoCodeData = JSON.parse(promoCodeData);
            this.setState({
                promoCodeData, 
            });
        } else {
            this.setState({
                promoCodeData: null, 
            });
        }
    }

    setPromoCodeData = (promoCodeData) => {
        localStorage.setItem('promoCodeData', JSON.stringify(promoCodeData));
        this.setState({
            promoCodeData, 
        });
    }

    removePromoCodeData = () => {
        localStorage.removeItem('promoCodeData');
        this.setState({
            promoCodeData: null, 
        });
    }

    getRatePlanIds = async () => {
        if (!this.state?.ratePlanIds) {
            const response = await Api.get('/api/products/rate-plans');
            if (response) {
                this.setState({
                    ratePlanIds: response
                });
            }
        }
    }

    getBundleRatePlanIdBasedOnPlan = (plan) => {
        const { protect, complete } = this.state.ratePlanIds || {};
        const bundleRatePlanMap = new Map()
            .set('protect', protect.annualBundleRatePlanId)
            .set('complete', complete.annualBundleRatePlanId);
        return bundleRatePlanMap.get(plan);
    }

    getPersonalInformation = async () => {
        const subscriptionApplicationId = sessionStorage.getItem(subscriptionApplicationIdSessionStorage);
        if (subscriptionApplicationId) {
            const subscriptionApplication = await Api.get(`/api/bundle/subscription-applications/${subscriptionApplicationId}`);
            if (subscriptionApplication) {
                this.setState({
                    personalInformation: subscriptionApplication.personalInformation
                });
                return subscriptionApplication.personalInformation;
            }    
        }
    }

    render() {
        const { personalInformation, product, userDetails, isLoggedIn, promoCodeData, ratePlanIds, variation } = this.state;
        if (!userDetails || !ratePlanIds) {
            return <Spinner />;
        }
        const contextValue = {
            product,
            userDetails,
            ratePlanIds,
            promoCodeData,
            isLoggedIn,
            personalInformation,
            variation,
            updateState: (values) => this.updateState(values),
            checkUserLoggedIn: () => this.checkUserLoggedIn(),
            refreshContext: async () => this.refreshContext(),
            setPromoCodeData: (promoCodeData) => this.setPromoCodeData(promoCodeData),
            removePromoCodeData: () => this.removePromoCodeData(),
            getBundleRatePlanIdBasedOnPlan : (planType) => this.getBundleRatePlanIdBasedOnPlan(planType),
            getPersonalInformation : () => this.getPersonalInformation(),
            location: this.props?.location?.pathname
        };
        return (
            <DataContext.Provider value={contextValue}>
                {this.props.children}
            </DataContext.Provider>
        )
    }
}

export default DataProvider;