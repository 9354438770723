import React from 'react';
import styled from 'styled-components';
import {desktop, mobile} from '../../styles/common';

const SignUpFlowLayoutContainer = styled.div`
    display: flex;
    margin-bottom: 4rem;
`;

const SignUpFlowFormContent = styled.section`
    flex: 1;
    ${desktop`
        padding: 0px 100px 0px 0px;
    `}
    box-sizing: border-box;
    ${mobile`
        input[type="submit"] {
            width: 100%;
        }
    `}
`;

const SignUpFlowExtraContent = styled.section`
    flex: 1;
    min-width: 300px;
    max-width: 500px;
    ${mobile`
        display: none;
    `}
`;

const ResponsiveSignupFlowLayout  = ({children}) => {
    return (
        <SignUpFlowLayoutContainer>
            {children}
        </SignUpFlowLayoutContainer>
    )
};

export {SignUpFlowFormContent, SignUpFlowExtraContent};
export default ResponsiveSignupFlowLayout;
