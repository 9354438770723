import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import withHeaderAndFooter from '../components/layout/withHeaderAndFooter';
import SubscriptionCompletePage from '../components/SubscriptionCompletePage';
import CheckoutPage from '../components/CheckoutPage';
import PersonalInfoPage from '../components/PersonalInfoPage';
import BasicCoveragePage from '../components/BasicCoveragePage';
import InactiveMembershipPage from '../components/InactiveMembershipPage';
import GraceMembershipPage from '../components/GraceMembershipPage';
import LoginSuccessPage from '../components/LoginSuccessPage';
import AlreadyActivatedSubscriptionPage from '../components/AlreadyActivatedSubscriptionPage';
import UpdateHomeAddressInfoPage from '../components/UpdateHomeAddressInfoPage';
import UpdateHomeAddressPage from '../components/UpdateHomeAddressPage';
import MembershipNumberPage from '../components/MembershipNumberPage';
import AAAIdcProductOfferingPage from '../components/merged-aaa-idc-signup-flow/AAAIdcProductOfferingPage';
import AAAIdcSubscriptionCompletePage from '../components/merged-aaa-idc-signup-flow/AAAIdcSubscriptionCompletePage';
import AAAIdcCheckoutPage from '../components/merged-aaa-idc-signup-flow/AAAIdcCheckoutPage';
import NotificationContext from '../components/contexts/NotificationContext';
import NotificationProvider from '../components/contexts/NotificationProvider';
import LoginWithAAAPage from '../components/LoginWithAAAPage';
import AutoEnrollmentWelcomePage from '../components/aaa-join-flow/AutoEnrollmentWelcomePage';
import ChooseYourPlanPage from '../components/v1/product-chart/customer/ChooseYourPlanPage';
import AccountAlreadyExist from '../components/bundle/AccountAlreadyExist';
import IdcAccountAlreadyExist from '../components/bundle/IdcAccountAlreadyExist';
import BundleCreateNewAccount from '../components/bundle/BundleCreateNewAccount';
import AlreadyHaveAAALogin from '../components/bundle/AAALoginExist';
import SubmitNewEmailOrLogin from '../components/bundle/SubmitNewEmailOrLogin';
import AAAMembershipExist from '../components/bundle/AAAMembershipExist';
import SubmitNewEmailOrSignup from '../components/bundle/SubmitNewEmailOrSignup';
import CancelledIdcAccountExist from '../components/bundle/CancelledIdcAccountExist';
import MyAccountPage from '../components/MyAccountPage';
import UpdatePaymentMethodPage from '../components/UpdatePaymentMethodPage';
import PayOutstandingBalancePage from '../components/PayOutstandingBalancePage';
import UpgradeCoveragePage from '../components/UpgradeCoveragePage';
import UpgradeCheckoutPage from '../components/UpgradeCheckoutPage';
import UpgradeSuccesfulPage from '../components/UpgradeSuccesfulPage';
import ProductUpgradePage from '../components/ProductUpgradePage';
import PaymentDuePage from '../components/PaymentDuePage';
import EditPersonalInfoPage from '../components/EditPersonalInfoPage';
import PaidProductUpgradePage from '../components/PaidProductUpgradePage';
import UpgradeTierCheckoutPage from '../components/UpgradeTierCheckoutPage';
import PaidProductUpgradeSuccesfulPage from '../components/PaidProductUpgradeSuccessfulPage';
import WhatsIncludedPage from '../components/WhatsIncludedPage';
import AutoRenewalTermsPage from '../components/AutoRenewalTermsPage';
import NotFound from '../components/NotFound';
import Phase3MigratedUserAlreadyExistsPage from '../components/v1/Phase3MigratedUserAlreadyExistsPage';
class UserRoutes extends Component {
    static contextType = NotificationContext;
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false;
    }
    render() {
        return (
            <NotificationProvider>
                <Switch>
                    <Route exact strict path="/login-success" component={withHeaderAndFooter(LoginSuccessPage, true, false)} />
                    <Route exact strict path="/already-activated-subscription" component={withHeaderAndFooter(AlreadyActivatedSubscriptionPage, true, false)} />
                    <Route exact strict path="/subscription-complete" component={withHeaderAndFooter(SubscriptionCompletePage, true)} />
                    <Route exact strict path="/checkout/2c92c0f972a852300172b962c2a95220/personal-info" component={withHeaderAndFooter(NotFound)} />
                    <Route exact strict path="/checkout/2c92c0f872a83f7a0172a8f09e6f5919/personal-info" component={withHeaderAndFooter(NotFound)} />
                    <Route exact strict path="/checkout/2c92a00d749622dd01749cdf81b60098/personal-info" component={withHeaderAndFooter(NotFound)} />
                    <Route exact strict path="/checkout/2c92a0ff7496233f01749cd14a020ead/personal-info" component={withHeaderAndFooter(NotFound)} />
                    <Route exact path="/product/:id?" component={withHeaderAndFooter(NotFound)} />
                    <Route exact strict path="/checkout/:ratePlanId/personal-info" component={withHeaderAndFooter(NotFound)} />
                    <Route exact strict path="/checkout/:productId/payment" component={withHeaderAndFooter(NotFound)} />
                    <Route exact strict path="/basic-coverage" component={withHeaderAndFooter(BasicCoveragePage, true, false)} />
                    <Route exact strict path="/inactive-membership" component={withHeaderAndFooter(InactiveMembershipPage, true, false)} />
                    <Route exact strict path="/grace-membership" component={withHeaderAndFooter(GraceMembershipPage, false, true, false, false, { showMyAccountOnHeader: true })} />
                    <Route exact strict path="/checkout/:ratePlanId/personal-info/address-info" component={withHeaderAndFooter(UpdateHomeAddressInfoPage, true, false)} />
                    <Route exact strict path="/checkout/:ratePlanId/personal-info/update-home-address" component={withHeaderAndFooter(UpdateHomeAddressPage, true, false)} />
                    <Route exact strict path="/membership-number" component={withHeaderAndFooter(MembershipNumberPage, true, false)} />
                    <Route exact strict path="/choose/product" component={withHeaderAndFooter(AAAIdcProductOfferingPage, true, false)} />
                    <Route exact strict path="/product/checkout/:productId" component={withHeaderAndFooter((props) => <NotificationContext.Consumer>{notificationContext => <AAAIdcCheckoutPage {...props} notificationContext={notificationContext} />}</NotificationContext.Consumer>, true, false)} />
                    <Route exact strict path="/idc-join-complete" component={withHeaderAndFooter(AAAIdcSubscriptionCompletePage, true)} />
                    <Route exact strict path="/login-with-aaa/:ratePlanId" component={withHeaderAndFooter(NotFound)} />
                    <Route exact strict path="/aaa-join-flow/auto-enrollment" component={withHeaderAndFooter(AutoEnrollmentWelcomePage, true, false, true)} />
                    <Route exact strict path="/my-account" component={withHeaderAndFooter((props) => <NotificationContext.Consumer>{notificationContext => <MyAccountPage {...props} notificationContext={notificationContext} />}</NotificationContext.Consumer>, false, true, false, false, { hideBorderBottom: true },{ showTopGradient: true })} />
                    <Route exact strict path="/update-payment" component={withHeaderAndFooter((props) => <NotificationContext.Consumer>{notificationContext => <UpdatePaymentMethodPage {...props} notificationContext={notificationContext} />}</NotificationContext.Consumer>, false, true, false, false, { hideBorderBottom: true })} />
                    <Route exact strict path="/pay-outstanding-balance" component={withHeaderAndFooter((props) => <NotificationContext.Consumer>{notificationContext => <PayOutstandingBalancePage {...props} notificationContext={notificationContext} />}</NotificationContext.Consumer>)} />
                    <Route exact strict path="/product/upgrade/:id" component={withHeaderAndFooter((props) => <NotificationContext.Consumer>{notificationContext => <UpgradeCoveragePage {...props} notificationContext={notificationContext} />}</NotificationContext.Consumer>)} />
                    <Route exact strict path="/edit-personal-info" component={withHeaderAndFooter((props) => <NotificationContext.Consumer>{notificationContext => <EditPersonalInfoPage {...props} notificationContext={notificationContext} />}</NotificationContext.Consumer>, false, true, false, false, { hideBorderBottom: true })} />
                    <Route exact strict path="/product/basic/upgrade/:id" component={withHeaderAndFooter(NotFound)} />
                    <Route exact strict path="/upgrade/checkout/:id" component={withHeaderAndFooter((props) => <NotificationContext.Consumer>{notificationContext => <UpgradeCheckoutPage {...props} notificationContext={notificationContext} />}</NotificationContext.Consumer>, false, true, false, false, { hideBorderBottom: true })} />
                    <Route exact strict path="/upgrade/success" component={withHeaderAndFooter((props) => <NotificationContext.Consumer>{notificationContext => <UpgradeSuccesfulPage {...props} notificationContext={notificationContext} />}</NotificationContext.Consumer>, false, true, false, false, { hideBorderBottom: true })} />
                    <Route exact strict path="/payment-due" component={withHeaderAndFooter(PaymentDuePage)} />
                    <Route exact strict path="/product/protect/upgrade/:id" component={withHeaderAndFooter(NotFound)} />
                    <Route exact strict path="/upgrade/product/checkout/:productId" component={withHeaderAndFooter(NotFound)} />
                    <Route exact strict path="/paid/product/upgrade/success" component={withHeaderAndFooter((props) => <NotificationContext.Consumer>{notificationContext => <PaidProductUpgradeSuccesfulPage {...props} notificationContext={notificationContext} />}</NotificationContext.Consumer>, false, true, false, false, { hideBorderBottom: true })} />
                    <Route exact strict path="/whats-included/:planType" component={withHeaderAndFooter((props) => <NotificationContext.Consumer>{notificationContext => <WhatsIncludedPage {...props} notificationContext={notificationContext} />}</NotificationContext.Consumer>)} />
                    <Route exact strict path="/auto-renewal-terms" component={withHeaderAndFooter(AutoRenewalTermsPage, false, true, false, false, { hideBorderBottom: true })} />
                    <Route exact strict path="/:type/checkout/:id/account-already-exist" component={withHeaderAndFooter(AccountAlreadyExist, true, false)} />
                    <Route exact strict path="/:type/checkout/:id/cancelled-account-already-exist" component={withHeaderAndFooter(CancelledIdcAccountExist, true, false)} />
                    <Route exact strict path="/:type/checkout/:id/idc-account-already-exist" component={withHeaderAndFooter(IdcAccountAlreadyExist, true, false)} />
                    <Route exact strict path="/:type/checkout/:id/create-new-account" component={withHeaderAndFooter(BundleCreateNewAccount, true, false)} />
                    <Route exact strict path="/:type/checkout/:id/aaa-membership-exist" component={withHeaderAndFooter(AAAMembershipExist, true, false)} />
                    <Route exact strict path="/:type/checkout/:id/submit-email-signup" component={withHeaderAndFooter(NotFound)} />
                    <Route exact strict path="/:type/checkout/:id/aaa-login" component={withHeaderAndFooter(AlreadyHaveAAALogin, true, false)} />
                    <Route exact strict path="/:type/checkout/:id/submit-new-email" component={withHeaderAndFooter(SubmitNewEmailOrLogin, true, false)} />
                    <Route exact strict path="/migrated-user-account-already-exist" component={withHeaderAndFooter(Phase3MigratedUserAlreadyExistsPage, false, true, false, false, { showPromoCodeNotification: false })} />
                    <Route path="*" component={withHeaderAndFooter(NotFound)} />
                </Switch>
            </NotificationProvider>
        );
    }
}

export default UserRoutes;