import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/common';

const FormErrorStyleComponent = styled.div`
    background: rgba(218, 41, 28, 0.15);
    border: 1px solid ${colors.darkRed};
    box-sizing: border-box;
    border-radius: 7px;
    font-size: 14px;
    line-height: 1.5;
    color: ${colors.darkRed};
    padding: 1rem;
`;

const ZuoraFormError = ({ error }) => {
    return (
        <>
            {error && <FormErrorStyleComponent>{error}</FormErrorStyleComponent>}
        </>
    );
};

export default ZuoraFormError;
