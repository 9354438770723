import React, { Component } from 'react';
import AdminContext from '../contexts/AdminContext';
import Content from '../layout/Content';
import { AdminApi, ContentfulService, segmentAnalytics } from '../../configuration/ApplicationContext';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from '../layout/ResponsiveSignupFlowLayout';
import Title from '../elements/Title';
import moment from 'moment';
import Spinner from '../elements/Spinner';
import Breadcrumbs from '../common/Breadcrumbs';
import ContentfulIds from '../../configuration/ContentfulIds';
import ParagraphText from '../elements/ParagraphText';
import AdminPersonalInfoForm from './forms/AdminPersonalInfoForm';
import { removeSpacesFromMembershipNumber} from '../common/Helpers';
import Notification from '../notifications/Notification';
import { MembershipNotification } from '../MembershipNotification';
import {ApiNotification} from '../common/ApiNotification'

const breadCrumbRoutes = [
    {
        to: '/admin/personal-info-non-member', label: 'New AAA Digital + IDC Signup'
    },
    {
        to: '/admin/personal-info-non-member', label: '1: Personal Details'
    }
]
class AdminPersonalNonMemberInfoPage extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            apiErrors: [],
            billingAddressSameAsHomeAddress: true
        };
    }

    async componentDidMount() {
        this.loadContent();
    }
  
    onSubmit = async (data) => {
        if (Object.keys(data.errors).length > 0) {
            return;
        }
        this.setState({
            apiErrors: [],
            customErrorMessages: [],
        });
        const { aaaLoginIdentifier, loginEmail } = this.context.searchResult || {};
        const { firstName, middleName, membershipNumber, lastName, email, dob, phoneNumber, additionalHomeInfo, additionalBillingInfo} = data.values;
        const homeAddress = { ...this.state.homeAddress, lineTwo: additionalHomeInfo };
        const billingAddress = { ...this.state.billingAddress, lineTwo: additionalBillingInfo };
        const billingAddressSameAsHomeAddress = this.state.billingAddressSameAsHomeAddress;
        if (Object.keys(data.errors).length > 0) {
            return;
        }
        segmentAnalytics.track('Sales-Tool-New-Signup-Submit-Personal-Details', {
            oneLoginID: this.context.getLoginId(),
            firstName,
            lastName,
            email,
            homeAddress,
            city: homeAddress.city,
            state: homeAddress.state,
            zipCode: homeAddress.zipCode,
            phoneNumber: phoneNumber.replace(/[^\d]/g, ''),
            dob: moment(dob).format('YYYY-MM-DD'),
            aaaMemberNumber: removeSpacesFromMembershipNumber(membershipNumber)
        });
        const apiPayload = {
            personalInformationRequest: {
                firstName,
                middleName,
                lastName,
                email,
                dob: moment(dob).format('YYYY-MM-DD'),
                phoneNumber: phoneNumber.replace(/[^\d]/g, ''),
                homeAddress: this.state.homeAddress ? homeAddress : this.context.searchResult.address,
                billingAddress,
                billingAddressSameAsHomeAddress
            },
            membershipNumber: removeSpacesFromMembershipNumber(membershipNumber),
            aaaLoginIdentifier,
            loginEmail
        };
        this.setState({
            isSubmitDisabled: true
        });
        const response = await AdminApi.post('/api/users', apiPayload);
        if (!response) {
            this.setState({
                isSubmitDisabled: false
            });
            return;
        }
        if (response.validationErrors) {
            let apiErrors = response.validationErrors.filter(apiError => {
                switch (apiError.property) {
                    case 'digitalAccount':
                    case 'subscription': {
                        const {setNotification, customErrorMessages} = ApiNotification.alreadyActiveSubscription;
                        const notifications = setNotification;
                        this.setState({
                            notifications,
                            customErrorMessages
                        });
                        break;
                    }
                    case 'membershipNumber': {
                        const notifications = MembershipNotification(apiError);
                        this.setState({
                            notifications,
                        });
                        break;
                    }
                    case 'address': {
                        const {setNotification, customErrorMessages} = ApiNotification.address;
                        const notifications = setNotification;
                        this.setState({
                            notifications,
                            customErrorMessages
                        });
                        break;
                    }
                    case 'cancelledMembershipNumber': {
                        const {setNotification, customErrorMessages} = ApiNotification.cancelledMembershipNumber;
                        const notifications = setNotification;
                        this.setState({
                            notifications,
                            customErrorMessages
                        });
                        break;
                    }
                    default:
                        return [apiError.message]
                }
            }, null);
            this.setState({
                apiErrors,
                isSubmitDisabled: false
            });
        } else {
            if (response.message && !response.personalInformationRequest) {
                this.setState({
                    apiErrors: [response.message],
                    isSubmitDisabled: false
                });
                return;
            }
            const { personalInformationRequest, aaaLoginIdentifier, userId, membershipExpiryDate, membershipSince } = response || {};
            const { firstName, middleName, lastName, dob, homeAddress, phoneNumber } = personalInformationRequest || {};
            const searchResult = {
                aaaLoginIdentifier,
                idcMembership : 'basic',
                email,
                userId,
                firstName,
                middleName,
                lastName,
                dob,
                address: homeAddress,
                phone: phoneNumber,
                membershipId: removeSpacesFromMembershipNumber(membershipNumber),
                membershipExpiryDate,
                membershipSince
            };
            const memberDetails = {
                personalInformation: personalInformationRequest,
                membershipId: removeSpacesFromMembershipNumber(membershipNumber),
                aaaLoginIdentifier
            }
            this.context.updateState({
                memberDetails,
                searchResult
            });
            localStorage.setItem('searchResult', JSON.stringify(searchResult));
            this.props.history.push(`/admin/non-member/product/${this.context?.ratePlanIds?.complete?.annualRatePlanId}`);
        }
    };

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.Admin.SignUpFlow.NonMemberPersonalInfoPage);
        this.setState({ content: response.fields });
    }

    onSelectAddress = (homeAddress) => {
        this.setState({
            homeAddress
        });
    }

    onBillingAddressSameAsHomeAddress = (billingAddressSameAsHomeAddress) => {
        this.setState({ billingAddressSameAsHomeAddress });
    }

    onSelectBillingAddress = (billingAddress) => {
        this.setState({
            billingAddress
        });
    }
    clearCustomErrorMessage = (fieldName) => {
        this.setState({
            customErrorMessages: {},
            notifications: (fieldName === 'email' && this.state?.customErrorMessages?.email) ? [] :  this.state.notifications
        });
    }
    
    render() {
        const { content, isSubmitDisabled ,notifications, customErrorMessages} = this.state || {};
        if (!content) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{ marginTop: '0rem' }}>
                <Breadcrumbs breadCrumbRoutes={breadCrumbRoutes} isCustomerFacing={false} ></Breadcrumbs>
                {notifications && notifications.map(notification => {
                    return <Notification key={notification.notificationType} {...notification} {...this.props} />
                })}
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title style={{ marginTop: '1rem' }}>{content.title}</Title>
                        <ParagraphText>{content.description}</ParagraphText>
                        <AdminPersonalInfoForm onSubmit={this.onSubmit} onSelectAddress={this.onSelectAddress}
                            onBillingAddressSameAsHomeAddress={this.onBillingAddressSameAsHomeAddress}
                            onSelectBillingAddress={this.onSelectBillingAddress}
                            apiErrors={this.state.apiErrors} personalInformation={this.context.searchResult}
                            billingAddressSameAsHomeAddress={this.state.billingAddressSameAsHomeAddress}
                            content={content}
                            showAAAMembershipNumber={true}
                            updateMembershipNumber={true}
                            isSubmitDisabled={isSubmitDisabled}
                            customErrorMessages={customErrorMessages}
                            isNonMember = {true}
                            showBottomText={true}
                            hideFieldForBackButton={true}
                            clearCustomError={this.clearCustomErrorMessage}
                            cancelRoute={`/admin/search?login-id=${this.context.getLoginId()}`}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default AdminPersonalNonMemberInfoPage;
