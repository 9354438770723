import React from 'react';
import styled from 'styled-components';
import Section from '../elements/Section';
import { colors, mobile } from '../../styles/common';

const MemberFeedbackContainer = styled.div`
    width: 27%;
    text-align: left;
    padding: 10px 10px;
    background-color: ${colors.pureWhite} !important;
    background: linear-gradient(to left, ${colors.darkRed} 0%, ${colors.darkBlue} 100%) left bottom no-repeat;
    background-size: 100% 8px;
    margin-bottom: 21px;
    ${ mobile`
       width: 97%
    `}
`;

const Image = styled.img`
    border-radius: 50%;
    height: 53px;
`;

const MemberFeedbackContent = ({ ...props }) => {
    const {src, title, name, description} = props;
    return (
        <MemberFeedbackContainer>
            <Section style={{ padding: '0px 0px 19px 0px', lineHeight: '23px', color: `${colors.titleText}`, fontSize: '17px' }} text={description}></Section>
                <Image src={src} />
                <Section text={name} desktopStyles={{ fontSize: '18px', lineHeight: '24px' }} style={{ fontWeight: '500', color: `${colors.darkBlue}`, paddingBottom: '0px' }} ></Section>
                <Section text={title} desktopStyles={{ fontSize: '14px', lineHeight: '19px' }} style={{ color: `${colors.greyText}`, paddingTop: '0px' }}></Section>
        </MemberFeedbackContainer >
    );
};

export default MemberFeedbackContent;