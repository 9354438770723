import React from 'react';
import styled, { css } from 'styled-components';

const Image = styled.img.attrs({loading: 'lazy'})`
    ${props => props.width && css`
      width: ${props.width}
    `};
    ${props => props.height && css`
      height: ${props.height}
    `};
`;

const ImageComponent = (props) => {
  return (
      <Image {...props} />
  );
};

export default ImageComponent;
