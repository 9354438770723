import React from 'react';
import { colors } from '../../styles/common';
import { UNKNOWN } from '../common/Constants';
import { formatDates, formatPhoneNumber, formatSsn } from '../common/Helpers';
import CardLineItem from '../elements/CardLineItem';
import CardWithTitle from '../elements/CardWithTitle';

const styles = {
    title: {
        paddingTop: '15px', textAlign: 'center', fontSize: '34px', fontWeight: 'normal', borderBottom: `1px solid ${colors.greyborder}`, lineHeight: '0.1em'
    },
    key: { width: '30%', fontWeight: 'bold', textAlign: 'right', marginRight: '2%' },
    value: { width: '68%' },
    cardLine: { marginBottom: 0 },
    unknown: { color: colors.darkRed, fontStyle: 'italic', width: '68%', fontWeight: 500 },
    error: { color: colors.darkRed, fontWeight: 500 },
    missingData: { justifyContent: 'flex-start' },
    missingDataSubscriber: { justifyContent: 'flex-start' },
    missingKey: { fontWeight: 500 },
    contentPadding: { paddingRight: '0px' },
    marginTop: { marginTop: '0.5rem' }
};

const ExperianCyberAgentMonitoringReport = ({ subscriberName, compromisedProperties, data }) => {
    const emailCompromisedProperties = compromisedProperties.find(property => property.propertyName === 'Email Address');
    const ssnCompromisedProperties = compromisedProperties.find(property => property.propertyName === 'SSN');
    const phoneCompromisedProperties = compromisedProperties.find(property => property.propertyName === 'Phone');
    const title = `${data.type}: Compromised`;
    const compromisedEmailFields = <>
        {
            emailCompromisedProperties &&
            <>
                <CardLineItem
                    itemKey='Subscriber:'
                    itemValue={subscriberName}
                    itemKeyStyle={styles.key}
                    itemStyle={styles.value}
                    style={styles.cardLine}
                />
                <CardLineItem
                    itemKey='Compromised Email:'
                    itemValue={
                        emailCompromisedProperties.email && emailCompromisedProperties.email !== ''
                            ? emailCompromisedProperties.email.toLowerCase()
                            : UNKNOWN
                    }
                    itemKeyStyle={styles.key}
                    itemStyle={
                        emailCompromisedProperties.email && emailCompromisedProperties.email !== ''
                            ? styles.value
                            : styles.unknown
                    }
                    style={styles.cardLine}
                />
                <CardLineItem
                    itemKey='Date Found:'
                    itemValue={
                        emailCompromisedProperties.creationDate && emailCompromisedProperties.creationDate !== ''
                            ? formatDates(emailCompromisedProperties.creationDate, 'YYYYMMDD', 'MM/DD/YYYY')
                            : UNKNOWN
                    }
                    itemKeyStyle={styles.key}
                    itemStyle={
                        emailCompromisedProperties.creationDate && emailCompromisedProperties.creationDate !== ''
                            ? styles.value
                            : styles.unknown
                    }
                    style={styles.cardLine}
                />
                <CardLineItem
                    itemKey='Password Found:'
                    itemValue={
                        emailCompromisedProperties.password && emailCompromisedProperties.password !== ''
                            ? emailCompromisedProperties.password
                            : UNKNOWN
                    }
                    itemKeyStyle={styles.key}
                    itemStyle={
                        emailCompromisedProperties.password && emailCompromisedProperties.password !== ''
                            ? styles.value
                            : styles.unknown
                    }
                    style={styles.cardLine}
                />
                <CardLineItem
                    itemKey='Source:'
                    itemValue={
                        emailCompromisedProperties.source && emailCompromisedProperties.source !== ''
                            ? emailCompromisedProperties.source.toLowerCase()
                            : UNKNOWN
                    }
                    itemKeyStyle={styles.key}
                    itemStyle={
                        emailCompromisedProperties.source && emailCompromisedProperties.source !== ''
                            ? styles.value
                            : styles.unknown
                    }
                    style={styles.cardLine}
                />
                <CardLineItem
                    itemKey='Found With:'
                    itemValue='Email Address'
                    itemKeyStyle={styles.key}
                    itemStyle={styles.value}
                    style={styles.cardLine}
                />
            </>
        }
    </>;
    const compromisedSsnFields = <>
        {
            ssnCompromisedProperties &&
            <>
                <CardLineItem
                    itemKey='Subscriber:'
                    itemValue={subscriberName}
                    itemKeyStyle={styles.key}
                    itemStyle={styles.value}
                    style={styles.cardLine}
                />
                <CardLineItem
                    itemKey='Social Security Number:'
                    itemValue={
                        ssnCompromisedProperties.ssn && ssnCompromisedProperties.ssn !== ''
                            ? formatSsn(ssnCompromisedProperties.ssn)
                            : UNKNOWN
                    }
                    itemKeyStyle={styles.key}
                    itemStyle={
                        ssnCompromisedProperties.ssn && ssnCompromisedProperties.ssn !== ''
                            ? styles.value
                            : styles.unknown
                    }
                    style={styles.cardLine}
                />
                <CardLineItem
                    itemKey='Date Found:'
                    itemValue={
                        ssnCompromisedProperties.creationDate && ssnCompromisedProperties.creationDate !== ''
                            ? formatDates(ssnCompromisedProperties.creationDate, 'YYYYMMDD', 'MM/DD/YYYY')
                            : UNKNOWN
                    }
                    itemKeyStyle={styles.key}
                    itemStyle={
                        ssnCompromisedProperties.creationDate && ssnCompromisedProperties.creationDate !== ''
                            ? styles.value
                            : styles.unknown
                    }
                    style={styles.cardLine}
                />
                <CardLineItem
                    itemKey='Source:'
                    itemValue={
                        ssnCompromisedProperties.source && ssnCompromisedProperties.source !== ''
                            ? ssnCompromisedProperties.source.toLowerCase()
                            : UNKNOWN
                    }
                    itemKeyStyle={styles.key}
                    itemStyle={
                        ssnCompromisedProperties.source && ssnCompromisedProperties.source !== ''
                            ? styles.value
                            : styles.unknown
                    }
                    style={styles.cardLine}
                />
                <CardLineItem
                    itemKey='Found With:'
                    itemValue='Social Security Number'
                    itemKeyStyle={styles.key}
                    itemStyle={styles.value}
                    style={styles.cardLine}
                />
            </>
        }
    </>;
    const compromisedPhoneFields = <>
        {
            phoneCompromisedProperties &&
            <>
                <CardLineItem
                    itemKey='Subscriber:'
                    itemValue={subscriberName}
                    itemKeyStyle={styles.key}
                    itemStyle={styles.value}
                    style={styles.cardLine}
                />
                <CardLineItem
                    itemKey='Compromised Phone:'
                    itemValue={
                        phoneCompromisedProperties.phone && phoneCompromisedProperties.phone !== ''
                            ? formatPhoneNumber(phoneCompromisedProperties.phone)
                            : UNKNOWN
                    }
                    itemKeyStyle={styles.key}
                    itemStyle={
                        phoneCompromisedProperties.phone && phoneCompromisedProperties.phone !== ''
                            ? styles.value
                            : styles.unknown
                    }
                    style={styles.cardLine}
                />
                <CardLineItem
                    itemKey='Date Found:'
                    itemValue={
                        phoneCompromisedProperties.creationDate && phoneCompromisedProperties.creationDate !== ''
                            ? formatDates(phoneCompromisedProperties.creationDate, 'YYYYMMDD', 'MM/DD/YYYY')
                            : UNKNOWN
                    }
                    itemKeyStyle={styles.key}
                    itemStyle={
                        phoneCompromisedProperties.creationDate && phoneCompromisedProperties.creationDate !== ''
                            ? styles.value
                            : styles.unknown
                    }
                    style={styles.cardLine}
                />
            </>
        }
    </>;
    return (
        <>
            {
                emailCompromisedProperties &&
                <CardWithTitle
                    title={`${title} Email`}>
                    {
                        !emailCompromisedProperties.email
                            && !emailCompromisedProperties.creationDate
                            && !emailCompromisedProperties.password
                            && !emailCompromisedProperties.source
                            ?
                            <>
                                <CardLineItem
                                    itemKey='Subscriber:&nbsp;'
                                    itemValue={subscriberName}
                                    itemKeyStyle={styles.missingKey}
                                    style={styles.missingDataSubscriber}
                                />
                                <CardLineItem
                                    itemKey='Error:&nbsp;'
                                    itemValue='All data for this alert is missing.'
                                    style={styles.missingData}
                                    itemKeyStyle={styles.error}
                                />
                            </>
                            : compromisedEmailFields
                    }
                </CardWithTitle>
            }
            {
                ssnCompromisedProperties &&
                <CardWithTitle
                    title={`${title} SSN`}>
                    {
                        !ssnCompromisedProperties.ssn
                            && !ssnCompromisedProperties.creationDate
                            && !ssnCompromisedProperties.source
                            ?
                            <>
                                <CardLineItem
                                    itemKey='Subscriber:&nbsp;'
                                    itemValue={subscriberName}
                                    itemKeyStyle={styles.missingKey}
                                    style={styles.missingDataSubscriber}
                                />
                                <CardLineItem
                                    itemKey='Error:&nbsp;'
                                    itemValue='All data for this alert is missing.'
                                    style={styles.missingData}
                                    itemKeyStyle={styles.error}
                                />
                            </>
                            : compromisedSsnFields
                    }
                </CardWithTitle>
            }
            {
                phoneCompromisedProperties &&
                <CardWithTitle
                    title={`${title} Phone`}>
                    {
                        !phoneCompromisedProperties.phone
                            && !phoneCompromisedProperties.creationDate
                            ?
                            <>
                                <CardLineItem
                                    itemKey='Subscriber:&nbsp;'
                                    itemValue={subscriberName}
                                    itemKeyStyle={styles.missingKey}
                                    style={styles.missingDataSubscriber}
                                />
                                <CardLineItem
                                    itemKey='Error:&nbsp;'
                                    itemValue='All data for this alert is missing.'
                                    style={styles.missingData}
                                    itemKeyStyle={styles.error}
                                />
                            </>
                            : compromisedPhoneFields
                    }
                </CardWithTitle>
            }
            {
                compromisedProperties.length === 0 &&
                <CardWithTitle
                    title={`${data.type} Alerts`}>
                    {
                        <>
                            <CardLineItem
                                itemKey='Subscriber:&nbsp;'
                                itemValue={subscriberName}
                                itemKeyStyle={styles.missingKey}
                                style={styles.missingDataSubscriber}
                            />
                            <CardLineItem
                                itemKey='Error:&nbsp;'
                                itemValue='All data for this alert is missing.'
                                style={styles.missingData}
                                itemKeyStyle={styles.error}
                            />
                        </>
                    }
                </CardWithTitle>
            }
        </>
    );
};

export default ExperianCyberAgentMonitoringReport;