import React, { Component , useEffect } from 'react';
import withPageMetaTags from './layout/withPageMetaTags';
import withPageTitle from './layout/withPageTitle';
import { META_DESCRIPTION } from './common/Constants';

const Content = {
    height: '2250px',
    width: '99.75%',
    frameborder: '1',
}

const AccessibilityPage = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
        return (  
            <div>
                <iframe 
                    src='https://customer-portal.audioeye.com/accessibility-statement.html?domain='accessibilityUrl
                    title="Accessibility Statement"
                    style={Content}>
                </iframe>
            </div>
        );
}

export default withPageMetaTags(withPageTitle(AccessibilityPage, 'Accessibility') , { description: META_DESCRIPTION.ACCESSIBILITY_PAGE });