import React, {Component} from 'react';
import Spinner from './elements/Spinner';

export default class LoginFailurePage extends Component {
    componentDidMount() {
        const {search} = window.location;
        const params = new URLSearchParams(search);
        const reason = params.get('reason');
        const email = params.get('email');
        const code = params.get('verificationCode');
        switch(reason) {
            case 'AAA_ACCOUNT_ALREADY_EXIST':
                this.props.history.push(`/email-in-use?email=${email}&code=${code}`);
                break;
            case 'INVALID_VERIFICATION_CODE':
                this.props.history.push('/something-went-wrong');
                break;
            default:
                return;
        }
    }
    
    render() {
        return (
            <Spinner />
        )
    }
}
