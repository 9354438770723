import React, { Fragment } from "react";
import { ContentfulService, segmentAnalytics, Api } from "../configuration/ApplicationContext";
import ProtectDescriptionPage from "./landing-page/ProtectDesciptionPage";
import ProductFeature from './landing-page/ProductFeature';
import LandingContent from './layout/LandingContent';
import ResponsiveLandingPageLayout from './layout/ResponsiveLandingPageLayout';
import Spinner from './elements/Spinner';
import ContentfulIds from '../configuration/ContentfulIds';
import MainCarousel from "./landing-page/MainCarousel";
import MemberFeedback from "./landing-page/MemberFeedback";
import MemberService from "./landing-page/MemberService";
import Disclaimer from './landing-page/Disclaimer';
import ZipCodeService from "../services/ZipCodeService";
import AskZipCodePage from './AskZipCodePage';
import BaseLogin from "./BaseLogin";
import withPageTitle from "./layout/withPageTitle";
import AAAGuard from "./landing-page/AAAGuard";
import Testimonials from "./landing-page/Testimonials";
import CyberNewsBlog from "./landing-page/CyberNewsBlog";
import withPageMetaTags from "./layout/withPageMetaTags";
import { META_DESCRIPTION } from "./common/Constants";
import ProductChart from "./v1/product-chart/customer/ProductChart";
import {
  checkLoginWithAAA,
  getPromoCodeNameFromRedirectParams,
  setPlanTypeInSessionStorage,
  setRedirectValueInLocalStorage
} from "./common/Helpers";
class LandingPage extends BaseLogin {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showError: false,
      isPlanDetailScrollEventFired: false,
      isTestimonialScrollEventFired: false,
      isBottomEventFired: false
    };
    this.addZipCodeData = this.addZipCodeData.bind(this);
    this.displayModal = this.displayModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.productChartRef = React.createRef();
    this.testimonialsRef = React.createRef();
  }

  componentDidMount() {
    this.loadContent(ContentfulIds.LandingPage);
    this.setContentMap();
    this.setScrollToPlanDetails();
    sessionStorage.setItem('loginProcessCompleted', checkLoginWithAAA());
    this.redirectToLoginPageAsPerRedirectParam();
  }
  redirectToLoginPageAsPerRedirectParam = () => {
    const redirectToUpgradeFlow = new URLSearchParams(this.props.location.search).get('redirect');
    if (redirectToUpgradeFlow) {
      setRedirectValueInLocalStorage(redirectToUpgradeFlow);
      return Api.login();
    }
  }
  setScrollToPlanDetails() {
    const params = new URLSearchParams(this.props.location.search);
    const value = params.get('scrollToPlanDetails') === 'true';
    const verificationCode = params.get('verification-code');
    this.setState({ scrollToProductChart: value, verificationCode });
  }

  setContentMap() {
    const contentTypesMap = {
      sectionsSafeguardYourIdentity: props =>
        <MainCarousel
          onSignup={this.onSignupClick}
          {...props.section.fields}
        />,
      sectionsRiskyOnlineWorld: props =>
        <ProtectDescriptionPage
          {...props.section}
        />,
      sectionsPeaceOfMind: props =>
        <ProductFeature
          {...props.section}
        />,
      sectionsGetTrustedProtection: props =>
        <ProductChart
          {...props.section}
          productChartRef={this.productChartRef}
          scrollToProductChart={this.state.scrollToProductChart}
          onSignup={this.onSignupClick}
        />,
      sectionsCyberNewsBlog: props =>
        <CyberNewsBlog
          {...props.section}
        />,
      sectionsByYourSide: props =>
        <MemberService
          {...props.section}
          onSignup={this.onSignupClick}
        />,
      sectionsTestimonials: props =>
        <Testimonials
          {...props.section}
          testimonialsRef={this.testimonialsRef}
        />,
      sectionsTrustedByAaaMembers: props =>
        <MemberFeedback
          {...props.section}
          onSignup={this.onSignupClick}
        />,
      titleMessageLink: props =>
        <Disclaimer
          {...props}
          showTrialSection={true}
          desktopOnly={true}
        />,
      titleAndReferences: props =>
        <AAAGuard
          {...props}
        />
    }
    this.setState({ contentTypesMap });
  }

  async loadContent() {
    const response = await ContentfulService.getEntries({ 'sys.id': ContentfulIds.LandingPage, include: 5 });
    this.setState({
      content: response.items[0].fields
    });
  }

  onLoginClick = async () => {
    this.setState({
      userLastActionSignUp: false
    });
    if (!ZipCodeService.getZipCookie()) {
      this.displayModal();
    } else {
      this.onLogin();
    }
  };

  onSignupClick = async (eventName, planType = '') => {
    setPlanTypeInSessionStorage(planType);
    if (eventName) {
      segmentAnalytics.track(eventName);
    }
    this.setState({
      userLastActionSignUp: true
    });
    if (!ZipCodeService.getZipCookie()) {
      this.displayModal();
    } else {
      window.location.href = 'https://www.aaa.com/experianidtheft';
    }
  };

  displayModal() {
    this.setState({
      showModal: true
    });
    segmentAnalytics.track('Zipgate | Pops Up');
  }

  closeModal() {
    this.setState({
      showModal: false
    });
  }

  addZipCodeData(zip) {
    segmentAnalytics.track('Zipgate | ZIP entered');
    ZipCodeService.checkIdcEligibility().then((response) => {
      if (!response.serviceable) {
        segmentAnalytics.track('Zipgate | ZIP rejected');
        this.props.history.push('/out-of-area/signup');
      } else {
        segmentAnalytics.track('Zipgate | ZIP cleared');
        this.state.userLastActionSignUp ? this.onSignupClick('', sessionStorage.getItem('planType') || '') : this.onLoginClick();
      }
    });
  }

  getDimensions = ele => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;
    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };

  handleScroll = () => {
    const scrollPosition = window.scrollY;
    const { offsetTop, offsetBottom } = this.getDimensions(this.productChartRef.current);
    if (scrollPosition >= offsetTop && scrollPosition < offsetBottom && !this.state.isPlanDetailScrollEventFired) {
      segmentAnalytics.track('Home Page | View Pricing Section');
      this.setState({
        isPlanDetailScrollEventFired: true
      });
    }
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const docHeight = body.scrollHeight - 150;
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight && !this.state.isBottomEventFired) {
      segmentAnalytics.track('Home Page | Scroll to Bottom');
      this.setState({
        isBottomEventFired: true
      });
    }
    this.handleTestimonialScroll();
  }

  handleTestimonialScroll = () => {
    const scrollPosition = window.scrollY;
    const { offsetTop, offsetBottom } = this.getDimensions(this.testimonialsRef.current);
    if (scrollPosition >= offsetTop && scrollPosition < offsetBottom && !this.state.isTestimonialScrollEventFired) {
      segmentAnalytics.track('Views Testimonial Section');
      this.setState({
        isTestimonialScrollEventFired: true
      });
    }

  }

  render() {
    const { content, contentTypesMap, verificationCode } = this.state;
    if (!content || verificationCode) {
      return <Spinner />
    }
    return (
      <Fragment>
        <LandingContent
          promocodename={getPromoCodeNameFromRedirectParams()}
          onWheel={this.handleScroll}
          style={{ padding: 0 }}>
          <ResponsiveLandingPageLayout>
            {content.sections && content.sections.map((section) => {
              const Content = contentTypesMap[section.sys.contentType.sys.id];
              if (Content) {
                return <Content key={section.sys.contentType.sys.id} section={section} />;
              }
              return <></>;
            })}
            <AskZipCodePage
              id="zip_code_modal"
              addZipCodeData={(zip) => { this.addZipCodeData(zip); }}
              showModal={this.state.showModal}
              showError={this.state.showError}
              dismissible={ZipCodeService.getZipCookie()}
              areaServiceable={this.state.areaServiceable}
              closeModal={this.closeModal}
              openZipForm={this.displayModal}
            />
          </ResponsiveLandingPageLayout>
        </LandingContent>
      </Fragment>
    );
  }
}

export default withPageMetaTags(withPageTitle(LandingPage, "AAA® Identity Champion Official Site | Identity Protection by AAA®", false), { description: META_DESCRIPTION.HOME, faceBookDomainVerification: true });
