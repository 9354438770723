import styled from 'styled-components';
import { mobile} from "../../styles/common";

const LandingContent = styled.div`
    padding: 0px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    max-width: 1440px;
    padding:0 15px;
    ${ mobile`
        top: 59px;
        margin: unset;
        padding: unset;
        margin-top: ${props => props.promocodename ? '165px': '60px'};
    `} 
`;

export default LandingContent;
