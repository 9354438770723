import React, { Fragment, useContext } from 'react';
import { ContentfulService, AdminApi, segmentAnalytics } from '../../configuration/ApplicationContext';
import UtilsService from './../../services/UtilsService';
import BaseAdminComponent from './BaseAdminComponent';
import { buildUrl, getSubscriptionApplicatonIdFromSessionStorage, subscriptionApplicationIdSessionStorage } from '../common/Helpers';
import { NON_AAA_MEMBER, FREE, OFFLINE_USER, IDC_USER, NON_BUNDLE_USER, CANCELLED_IDC_USER, COMPLETE_UPPER_CASE, CANCELLED, FREE_UPPER_CASE  } from '../common/Constants';
export default class BaseAdminProductOffering extends BaseAdminComponent {
    async loadPlans(contentfulId) {
        const { membership, invalidMembershipNumber, membershipId } = this.context.memberDetails || {};
        const userType = this.props.match.params.userType;
        const isInvalidOrCancelledMembership = !membershipId || invalidMembershipNumber || (membership && membership?.membershipStatus === CANCELLED);
        if (userType !== NON_AAA_MEMBER) {
            if ((!this.context.memberDetails || Object.keys(this.context.memberDetails).length <= 0)) {
                this.loadMemberDetails();
            }
            const userId = this.context.searchResult?.userId;
            if (userId) {
                let zuoraRatePlansURL = `/api/products/users/${userId}/zuora-rate-plans?subscriptionAction=${this.state.subscriptionAction}`;
                if (this.state.subscriptionAction !== 'CREATE' && (!membershipId || isInvalidOrCancelledMembership)) {
                    zuoraRatePlansURL += '&isNonAaaMember=true'
                }
                const response = await AdminApi.get(`${zuoraRatePlansURL}`);
                localStorage.setItem('productsWithoutPromoCodes', JSON.stringify(response.products));
                this.mapProductsToArray(response.products, isInvalidOrCancelledMembership);
                await this.loadContent(response.products, contentfulId);
                await this.onLoadSelectProduct(this.props.match.params.id);
            }
        } else {
            let params = {};
            params.isNonAaaMember = true;
            const subscriptionApplicationId = sessionStorage.getItem(subscriptionApplicationIdSessionStorage);
            if (subscriptionApplicationId) {
                params['subscription-application-id'] = subscriptionApplicationId;
            }
            const zuoraRatePlansUrl = buildUrl('/api/products/zuora-rate-plans', params);
            const response = await AdminApi.get(zuoraRatePlansUrl);
            localStorage.setItem('productsWithoutPromoCodes', JSON.stringify(response.products));
            response.products = response.products.filter(product => product.name.toUpperCase() !== FREE.toUpperCase());
            this.mapProductsToArray(response.products, isInvalidOrCancelledMembership);
            this.loadContent(response.products, contentfulId);
            if (this.props.match.params.id) {
                this.onLoadSelectProduct(this.props.match.params.id);
            }
        }
    }

    mapProductsToArray(products, isInvalidOrCancelledMembershipWithCompleteSubscription) {
        if (isInvalidOrCancelledMembershipWithCompleteSubscription) {
            products = products.filter(product => product.name !== FREE_UPPER_CASE);
        }
        const mappedProducts = UtilsService.mapProductsToArray(products);
        this.setState({ products: mappedProducts });
    }

    async loadContent(products, contentfulId) {
        const contentObject = {};
        const title = await ContentfulService.getEntry(contentfulId);
        for await (const product of products) {
            const content = await ContentfulService.getEntry(product.contentfulProductOfferingId);
            contentObject[product.name] = content.fields;
        }
        this.setState({ content: { title: title.fields, main: contentObject } });
    }

    async loadPlanDetailsContent(contentfulId) {
        const landingPageContent = await ContentfulService.getEntries({ 'sys.id': contentfulId, include: 5 });
        const planDetailContent = landingPageContent.items[0].fields.sections.find(section => section.sys.contentType.sys.id === 'sectionsGetTrustedProtection');
        this.setState({
            planDetailsContent: planDetailContent.fields.sections,
            planDetailsLinkText: planDetailContent.fields.linkText
        });
    }
    onLoadSelectProduct(productId) {
        const foundProduct = this.state.products.find(product => product.id === productId);
        let selectedProduct = foundProduct ? foundProduct : {};
        this.setState({ paymentMode: selectedProduct.paymentMode });
        this.setState({ selectedProduct });
        this.context.updateState({ product: selectedProduct });
    }
    onSelectDowngradeProduct = (selectedProduct) => {
        this.setState({ selectedProduct: selectedProduct });
        this.context.updateState({ product: selectedProduct });
        this.props.history.push(`${this.state.productPageURL}/${selectedProduct.id}`);
    }

    onSubmit = async () => {
        this.props.history.push({ pathname: this.state.checkoutPageURL + `/${this.state.selectedProduct.id}` });
    }

    onSelectProduct = (selectedProduct, index = 1) => {
        this.setState({ selectedProduct: selectedProduct });
        this.updateUpgradeLinkInBreadCrumb(selectedProduct, index);
        this.context.updateState({ product: selectedProduct });
        this.props.history.push(buildUrl(`${this.state.productPageURL}/${selectedProduct.id}`, this.state.urlParams));
    }
    onSelectPaymentMode = (event, paymentMode, index = 1) => {
        event.stopPropagation();
        this.setState({ paymentMode });
        const productsByPaymentMode = this.state.products.filter(product => product.paymentMode === paymentMode);
        const selectedProduct = productsByPaymentMode.find(product => product.name === this.state.selectedProduct.name);
        this.onSelectProduct(selectedProduct, index);
    }

    updateUpgradeLinkInBreadCrumb = (selectedProduct, index) => {
        const breadCrumbRoutes = this.state['breadCrumbRoutes'];
        breadCrumbRoutes[index].to = `${this.state.productPageURL}/${selectedProduct.id}`;
        this.setState({ breadCrumbRoutes });
    }

    setBreadCrumb = (label = 'Upgrade') => {
        const { personalInformation } = this.context.memberDetails || {};
        const breadCrumbRoutes = [
            {
                to: '/admin/member-account', label: personalInformation?.displayName
            },
            {
                to: `${this.state.productPageURL}/${this.props.match.params.id}`, label: label
            }
        ];
        this.setState({ breadCrumbRoutes });
    }

    trackDowngradeEvent = (eventName) => {
        const { personalInformation } = this.context.memberDetails || {};
        segmentAnalytics.track(eventName, {
            oneLoginID: this.context.getLoginId(),
            firstName: personalInformation.firstName,
            lastName: personalInformation.lastName,
            memberId: this.context.memberDetails.userId,
            email: personalInformation.email,
            homeAddress: personalInformation.homeAddress,
            city: personalInformation.homeAddress.city,
            state: personalInformation.homeAddress.state,
            zip: personalInformation.homeAddress.zipCode,
            phoneNumber: personalInformation.phoneNumber,
            aaaMemberNumber: this.context.memberDetails.membershipId,
            dob: personalInformation.dob,
            subscriptionOption: `${this.context.product.paymentMode === 'Annually' ? 'Annual' : this.context.product.paymentMode}`,
            tierSelected: this.context.product.name
        });
    }
    loadProductChartContent = async (contentfulId) => {
        const productChart = await ContentfulService.getEntries({ 'sys.id': contentfulId, include: 5 });
        const productChartContent = productChart.items[0].fields;
        this.setState({ productChartContent });
    }

    validateExistingAccountAndRedirect = async (userType) => {
        const subscriptionApplicationId = sessionStorage.getItem(subscriptionApplicationIdSessionStorage);
        if (!subscriptionApplicationId) {
            this.setState({ validateExistingAccount: true });
            return;
        }
        const updatedPersonalInformation = await AdminApi.get(`/api/bundle/subscription-applications/${subscriptionApplicationId}`);
        if (!updatedPersonalInformation) {
            this.setState({ validateExistingAccount: true });
            return;
        }
        const email = updatedPersonalInformation.personalInformation.email;
        const response = await AdminApi.get(`/api/bundle/subscription-applications/${subscriptionApplicationId}/validate`);
        if (!response) {
            this.setState({ validateExistingAccount: true });
            return;
        }
        if (response.validationErrors) {
            response.validationErrors.forEach(apiError => {
                const redirectTo = this.getRedirectionMapUrl(email, userType, apiError.property);
                if (redirectTo) {
                    this.props.history.push(redirectTo);
                } else {
                    this.setState({ validateExistingAccount: true });
                }
            });
        } else {
            this.setState({ validateExistingAccount: true });
        }
    }
    getRedirectionMapUrl = (email, userType, key) => {
        const subscriptionApplicationId = getSubscriptionApplicatonIdFromSessionStorage();
        const redirectionMap = new Map()
            .set(OFFLINE_USER, `/admin/${userType}/checkout/${subscriptionApplicationId}/account-already-exist?email=${email}`)
            .set(IDC_USER, `/admin/${userType}/checkout/${subscriptionApplicationId}/idc-account-already-exist?email=${email}`)
            .set(NON_BUNDLE_USER, `/admin/${userType}/checkout/${subscriptionApplicationId}/aaa-membership-exist?email=${email}`)
            .set(CANCELLED_IDC_USER, `/admin/${userType}/checkout/${subscriptionApplicationId}/cancelled-idc-account-exist?email=${email}`);
        return redirectionMap.get(key);
    }
}