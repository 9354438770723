import styled from 'styled-components';
import {mobile, commonPropStyles} from '../../styles/common';

const Content = styled.div`
    padding: 0px 60px;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 60px;
    box-sizing: border-box;
    ${mobile`
        padding: 0px 15px 20px 15px;
        margin-top: 60px;
        padding-top: 5px;
    `}
    ${commonPropStyles.mobile}
    ${commonPropStyles.desktop}
`;

export default Content;
