import BaseComponent from './BaseComponent';
import { Api, ContentfulService } from '../configuration/ApplicationContext';
import { ANNUALLY, CANCELLED, FREE_UPPER_CASE, MONTHLY } from './common/Constants';
import { buildUrl, capitalizeFirstLetter, subscriptionApplicationIdSessionStorage } from './common/Helpers';
import { validateInputAddress } from './common/Helpers';

export default class BaseCheckout extends BaseComponent {
    async loadPlans() {
        let params = {};
        const { userDetails } = this.context || {};
        const membershipStatus = userDetails?.membership?.membershipStatus;
        if (!this.context.isLoggedIn) {
            params.isNonAaaMember = true;
            const subscriptionApplicationId = sessionStorage.getItem(subscriptionApplicationIdSessionStorage);
            if (subscriptionApplicationId) {
                params['subscription-application-id'] = subscriptionApplicationId;
            }
        } else if (!this.context.userDetails?.subscription) {
            params.isFreeTrialUser = this.context.variation.freeTrial === true;
            params.isNonAaaMember = membershipStatus === CANCELLED || userDetails.invalidMembershipNumber;
        }
        const zuoraRatePlansURL = buildUrl('/api/products/zuora-rate-plans', params);
        const response = await Api.get(zuoraRatePlansURL);
        if (!response) {
            return;
        }
        localStorage.setItem('productsWithoutPromoCodes', JSON.stringify(response.products));
        const products = await this.loadPromoCodeData();
        response.products = products ? products : response.products;
        response.products = response.products.find(product => product.name !== FREE_UPPER_CASE
            && (product.annualRatePlan.id === this.props.match.params.productId
                || product.monthlyRatePlan.id === this.props.match.params.productId));
        this.selectProduct(response.products, this.props.match.params.productId);
    }

    loadPromoCodeData = async () => {
        if (this.context.promoCodeData) {
            const response = await Api.get(`/api/products/zuora-rate-plans?promo-code=${this.context.promoCodeData.promoCode}`);
            return response.products;
        }
        return null;
    }

    selectProduct(product, productId) {
        const selectedProduct = product.annualRatePlan.id === productId ? product.annualRatePlan : product.monthlyRatePlan;
        selectedProduct.paymentMode = product.annualRatePlan.id === productId ? ANNUALLY : MONTHLY;
        selectedProduct.name = capitalizeFirstLetter(product.name);
        selectedProduct.contentfulProductOfferingId = product.contentfulProductOfferingId;
        this.context.updateState({ product: selectedProduct });
    }

    onSelectAddress = (address) => {
        const personalInfo = this.state.personalInfo;
        personalInfo.billingAddress = address
        if (!validateInputAddress(address)) {
            this.setState({
                personalInfo,
                checkoutError: "Please enter valid billing address"
            });
        } else {
            this.setState({
                personalInfo,
                checkoutError: ''
            });
        }
    }

    prepopulateFields() {
        this.setState({
            prepopulateFields: {
                creditCardHolderName: this.context.userDetails.subscription.defaultPaymentMethod.cardHolderName || ''
            }
        });
    }

    async loadContent(contentfulId) {
        const response = await ContentfulService.getEntry(contentfulId);
        this.setState({ content: response.fields })
    }

    loadPersonalInformation = () => {
        const personalInfo = this.context.userDetails.personalInformation || {};
        const billingAddressSameAsHomeAddress = personalInfo.billingAddress ? personalInfo.billingAddressSameAsHomeAddress : true;
        personalInfo.billingAddress = personalInfo.billingAddress ? personalInfo.billingAddress : personalInfo.homeAddress;
        this.setState({
            personalInfo,
            billingAddressSameAsHomeAddress
        });
    }

    loadZuoraForm = async () => {
        const zuoraConfiguration = await Api.get('/api/orders/payment-form');
        this.setState({ zuoraConfiguration })
    }
}