import React, { Component } from 'react';
import ResponsiveSignupFlowLayout, { SignUpFlowExtraContent, SignUpFlowFormContent } from '../layout/ResponsiveSignupFlowLayout';
import Title from '../elements/Title';
import Content from '../layout/Content';
import AdminContext from '../contexts/AdminContext';
import Link from '../elements/Link';
import Breadcrumbs from '../common/Breadcrumbs';
import { formatName, sentenceCase } from '../common/Helpers';
import Spinner from '../elements/Spinner';
import ExperianCyberAgentMonitoringReport from './ExperianCyberAgentMonitoringReport';
import ExperianCyberAgentAlertDetail from './ExperianCyberAgentAlertDetail';
import ExperianCyberAgentPayDayLoanAlert from './ExperianCyberAgentPayDayLoanAlert';

const styles = {
    contentPadding: { paddingRight: '0px' },
    marginTop: { marginTop: '0.5rem' }
};

export default class ExperianCyberAgentReportPage extends Component {
    static contextType = AdminContext;
    state = {
        data: null
    };

    componentDidMount() {
        this.setBreadCrumb();
    }

    setBreadCrumb = () => {
        const data = this.props.location.state;
        const { personalInformation } = this.context.memberDetails || {};
        const breadCrumbRoutes = [
            {
                to: '/admin/member-account', label: formatName(personalInformation?.firstName, personalInformation?.middleName, personalInformation?.lastName)
            },
            {
                to: {
                    pathname: `/admin/experian-alerts`,
                    state: {
                        experianAlerts: data?.experianAlerts
                    },
                },
                label: 'Experian Alert List'
            },
            {
                to: {
                    pathname: `/admin/experian/cyber-agent-alert`,
                    state: {
                        compromisedProperties: data?.compromisedProperties,
                        experianAlerts: data?.experianAlerts,
                        type: data?.type,
                        details: data?.details
                    },
                },
                label: `View Full ${(data.details !== null || data.compromisedProperties?.length <= 1) && !data.details?.payLoadLoanAndInquiryDetails ? 'Alert' : 'Alerts'}`
            }
        ];
        this.setState({ breadCrumbRoutes });
    }


    render() {
        const data = this.props.location.state;
        if (!data || !data.compromisedProperties) {
            return <Spinner />;
        }
        const experianAlerts = data.experianAlerts;
        const { breadCrumbRoutes } = this.state || {};
        const { personalInformation } = this.context.memberDetails || {};
        const subscriberName = sentenceCase(formatName(personalInformation?.firstName, personalInformation?.middleName, personalInformation?.lastName));
        return (
            <Content
                desktopStyles={{ marginTop: '0rem' }}>
                <Breadcrumbs
                    isCustomerFacing={false}
                    breadCrumbRoutes={breadCrumbRoutes}
                />
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent
                        style={styles.contentPadding}>
                        <Title
                            desktopStyles={styles.marginTop}>
                            Experian {(data.details !== null || data.compromisedProperties?.length <= 1) && !data.details?.payLoadLoanAndInquiryDetails ? 'Alert' : 'Alerts'}
                        </Title>
                        {
                            data.details === null &&
                            <ExperianCyberAgentMonitoringReport
                                subscriberName={subscriberName}
                                compromisedProperties={data.compromisedProperties}
                                data={data}
                            />
                        }
                        {
                            data.details !== null && !data.details.payLoadLoanAndInquiryDetails &&
                            <ExperianCyberAgentAlertDetail
                                data={data}
                                subscriberName={subscriberName}
                            />
                        }
                        {
                            data.details !== null && data.details.payLoadLoanAndInquiryDetails &&
                            <ExperianCyberAgentPayDayLoanAlert
                                data={data}
                                subscriberName={subscriberName}
                            />
                        }
                        <Link
                            style={{ marginTop: '2rem' }}
                            text={`Back to Experian Alert List for ${subscriberName}`}
                            to={
                                {
                                    pathname: `/admin/experian-alerts`,
                                    state: {
                                        experianAlerts
                                    }
                                }
                            }
                        />
                        <Link
                            to='/admin/member-account'
                            style={{ marginTop: '1rem' }}
                            text={`Back to ${subscriberName}'s Dashboard`}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

