import React, { Fragment } from 'react';
import Content from './../../layout/Content';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from './../../layout/ResponsiveSignupFlowLayout';
import ContentfulIds from './../../../configuration/ContentfulIds';
import Spinner from './../../elements/Spinner';
import FormError from './../../elements/FormError';
import AdminContext from './../../contexts/AdminContext';
import Card from './../../elements/Card';
import BaseAdminProductOffering from './../BaseAdminProductOffering';
import AdminProductOffering from './../AdminProductOffering';
import AdminSummary from '../summary/AdminSummary';
import Breadcrumbs from '../../common/Breadcrumbs';
import { capitalizeFirstLetter } from '../../common/Helpers';
import { AdminApi } from '../../../configuration/ApplicationContext';
import { AAA_MEMBER, ACTIVE, CANCELLED, INVALID, NON_AAA } from '../../common/Constants';
import { colors } from '../../../styles/common';
import RichText from '../../elements/RichText';
import ProductChart from '../../v1/product-chart/customer/ProductChart';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import DynamicParagraphText from '../../elements/DynamicParagraphText';

const styles = {
    paragraph: { textAlign: 'justify', margin: 0, color: colors.verylightGrey, lineHeight: '20px', paddingTop: '1rem' }
}

const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText
    {...props}
    text={children}
    style={styles.paragraph}
/>;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) =>
            <ParagraphStyle
                anchor={true}
                desktopOnly='true'
                desktopFontSize='12px'
            >
                {children}
            </ParagraphStyle>,
        [INLINES.HYPERLINK]: (node, next) => {
            return <a
                href={node.data.uri}>
                {node.content[0].value}
            </a>;
        }
    },
};
export default class CoverageDowngradePage extends BaseAdminProductOffering {
    static contextType = AdminContext;

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            selectedProduct: null,
            paymentMode: '',
            productPageURL: '/admin/downgrade',
            checkoutPageURL: '',
            subscriptionAction: 'DOWNGRADE',
            submitted: false,
            isSubmitDisabled: false,
            apiErrors: []
        };
    }

    onSubmit = async () => {
        this.setState({
            isSubmitDisabled: true
        });
        const ratePlanId = this.context.product.id;
        const { subscription, membership, invalidMembershipNumber } = this.context.memberDetails || {};
        const { actualPrice, name } = this.state.selectedProduct || {};
        const totalAmount = name === 'Free' ? 0 : actualPrice?.total;
        const membershipStatus = invalidMembershipNumber ? INVALID : (membership && membership?.membershipStatus === CANCELLED ? CANCELLED : ACTIVE)
        let response = await AdminApi.post(`/api/users/${this.context.searchResult.userId}/downgrade-coverage`, { ratePlanId, totalAmount, membershipStatus });
        if (!response) {
            this.setState({
                isSubmitDisabled: false
            });
            return;
        }
        if (response.validationErrors) {
            const apiErrors = response.validationErrors.map(apiError => {
                return apiError.message
            });
            this.setState({ apiErrors, isSubmitDisabled: false, submitted: true });
        } else {
            this.trackDowngradeEvent(`Sales-Tool-Downgrade-${capitalizeFirstLetter(subscription.productType)}-to-${this.state.selectedProduct.displayName}`);
            this.props.history.push('/admin/downgrade-complete');
        }
    }

    async componentDidMount() {
        await this.loadMemberDetails();
        this.setBreadCrumb('Downgrade: Options');
        this.loadPlans(ContentfulIds.Admin.MemberAccountIds.DowngradeCompletePlanPage);
        this.loadProductChartContent(ContentfulIds.Admin.ChooseYourPlanPage);
    }

    render() {
        const {
            products,
            apiErrors,
            isSubmitDisabled,
            breadCrumbRoutes,
            paymentMode,
            selectedProduct,
            content,
            productChartContent,
            submitted
        } = this.state || {};
        const {
            subscription,
            membership
        } = this.context.memberDetails || {};
        if (!selectedProduct || !content || !productChartContent) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{ marginTop: '0rem' }}>
                <Breadcrumbs
                    isCustomerFacing={false}
                    breadCrumbRoutes={breadCrumbRoutes}
                />
                <FormError
                    showErrors={submitted}
                    errors={apiErrors}
                />
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <AdminProductOffering
                            products={products}
                            selectedProduct={selectedProduct}
                            content={content}
                            onSelectProduct={this.onSelectDowngradeProduct}
                            paymentMode={paymentMode}
                            onSelectPaymentMode={this.onSelectPaymentMode}
                            onSubmit={this.onSubmit}
                            hideSelectProduct={!membership}
                            subscription={subscription}
                            isSubmitDisabled={isSubmitDisabled}
                            isDowngradeFlow={true}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        <Card>
                            <AdminSummary
                                product={selectedProduct}
                                title={`If Subscriber downgrades to ${selectedProduct.displayName}`}
                                subscription={subscription}
                                isDowngradeFlow={true}
                            />
                        </Card>
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
                <ProductChart
                    backgroundcolor={colors.pureWhite}
                    showTitle={false}
                    showDescription={false}
                    fields={productChartContent}
                    defaultMembership={membership !== null ? AAA_MEMBER : NON_AAA}
                    hideToggleSection={true}
                    openComparePlansLinkInNewTab={true}
                    disableCta={true}
                />
                <RichText
                    text={productChartContent.joinAaaText}
                    options={options}
                />
            </Content>
        );
    }
}
