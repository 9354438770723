import React from 'react';
import SubmitButton from './elements/SubmitButton';
import ResponsiveSignupFlowLayout, {SignUpFlowFormContent, SignUpFlowExtraContent} from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Summary from './summary/Summary';
import Card from './elements/Card';
import DataContext from './contexts/DataContext';
import DynamicParagraphText from './elements/DynamicParagraphText';
import Title from './elements/Title';
import Spinner from './elements/Spinner';
import { BLOCKS } from '@contentful/rich-text-types';
import RichText from './elements/RichText';
import ContentfulIds from '../configuration/ContentfulIds';
import UpgradeSuccess from './UpgradeSuccess';
import Section from './elements/Section';
import withPageTitle from "./layout/withPageTitle";
import Breadcrumbs from './common/Breadcrumbs';
import ReactMarkdown from 'react-markdown';
import InfoText from './elements/InfoText';
import { createSummaryItems } from './summary/SummaryItems';

const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children}/>;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</ParagraphStyle>,
    },
};

const breadCrumbRoutes = [
    {
        to: '/upgrade/success', label: 'Upgrade'
    }
];

class UpgradeSuccesfulPage extends UpgradeSuccess {
    static contextType = DataContext;

    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.context.refreshContext();
        this.loadContent(ContentfulIds.UpgradeSuccessfulPage);
        await this.props.notificationContext.getNotifications();    
    }

    render() {
        const { content, description, subDescription } = this.state;
        const { product } = this.context;
        if (!content) {
            return <Spinner />;
        }
        return (
            <>
                <Breadcrumbs breadCrumbRoutes={breadCrumbRoutes}></Breadcrumbs>
                <Content>
                    <ResponsiveSignupFlowLayout>
                        <SignUpFlowFormContent>
                            <Title desktopStyles={{ marginTop: '0'}} mobileStyles={{ marginTop: '2rem' }}>{content.title}</Title>
                            <InfoText mobileOnly='true'>
                                <RichText text={description} options={options} />
                            </InfoText>
                            <Section mobileOnly='true' text={<Summary product={product} title={'Identity Champion Plan'} content={content} items={createSummaryItems(product, this.context)} flags={{showBottomSection: true}}></Summary>}></Section>
                            <InfoText desktopOnly='true'>
                                <RichText text={description} options={options} />
                            </InfoText>
                            <RichText text={subDescription} options={options} />
                            <ReactMarkdown source={content.footerDescription} />
                            <SubmitButton style={{marginTop: '2rem', marginBottom: '4rem'}} text="Continue to Dashboard" onClick={() => window.location.href=process.env.REACT_APP_EXPERIAN_SSO_URL} />
                        </SignUpFlowFormContent>
                        <SignUpFlowExtraContent>
                            <Card>
                                <Summary product={product} title={'Identity Champion Plan'} content={content} items={createSummaryItems(product, this.context, { showPromoFromSubscription: true, showPaidInvoiceAmount: true })} flags={{showBottomSection: true, showPaidInvoiceAmount: true}}></Summary>
                            </Card>                       
                        </SignUpFlowExtraContent>
                    </ResponsiveSignupFlowLayout>
                </Content>
            </>
        );
    }
}

export default withPageTitle(UpgradeSuccesfulPage, "Upgrade Successful");

