import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { colors, mobile } from '../../styles/common';
import FieldError from './FieldError';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import AdminContext from '../contexts/AdminContext';

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
`;

const WrapperDiv = styled.div`
  position: relative;
  display: flex;
  max-width: 450px;
  width: 100%;
  margin: 20px 0px;
  padding: 0 50px;
  height: 40px;
  ${mobile`	
      margin: 15px 15px;
      padding: unset;
  `}
`;

const SearchInputStyleComponent = styled.input`
  border: 1px solid ${colors.searchBorderColor};
  padding-left: 10px;
  border-radius: 3px;
  font-size: 16px;
  border-right: none;
  outline: none;
  line-height: 1.5;
  width: 100%;
  box-sizing: border-box;  
  display: block;
  margin-top: ${props => props.extratopspace};
  ${props => props.hide && css`
    display: none; 
  `};
  ${props => props.error && css`
    border-color: red; 
  `};
`;

const SearchButton = styled.button`
  width: 40px;
  cursor: pointer;
  outline: none;
  border: 1px solid ${colors.searchBorderColor};
  background: transparent;
  color: ${colors.searchBorderColor};
  border-left: none;
  ${props => props.error && css`
    border-color: red; 
  `};
`;

const errorStyle = {
  margin: '20px 50px',
}

const SearchInput = ({ ...props }) => {
  const search = useRef();
  const context = useContext(AdminContext);
  useEffect(() => {
    if (search.current) {
      search.current.focus();
      if (props.keyword) {
        search.current.value = props.keyword;
        const { searchResults } = context || {};
        if (searchResults.length <= 0) {
          props.onSubmit(search);
        }
      }
    }
  }, [props.keyword]);


  return (
    <Container>
      <WrapperDiv>
        <SearchInputStyleComponent
          disabled={props.isDisabled}
          {...props}
          ref={search}
          onKeyPress={(event) => props.onKeyPress(event, search)}
        />
        <SearchButton
          error={props.error}>
          <FontAwesomeIcon
            icon={faSearch}
            onClick={() => props.onSubmit(search)}
          />
        </SearchButton>
      </WrapperDiv>
      {
        props.error &&
        <FieldError
          style={errorStyle}>
          {props.error}
        </FieldError>
      }
    </Container>
  );
};

export default SearchInput;
