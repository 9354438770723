import React, { Component } from 'react';
import { ContentfulService, Api } from '../configuration/ApplicationContext';
import ContentfulIds from '../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Title from './elements/Title';
import RichText from './elements/RichText';
import Spinner from './elements/Spinner';
import Link from './elements/Link';
import withPageTitle from "./layout/withPageTitle";
import withPageMetaTags from './layout/withPageMetaTags';
import DynamicParagraphText from './elements/DynamicParagraphText';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { formatDateMMDDYYYY, removeLeadingZerosForSingleDigitDate, setMemberTypeInSessionStorage } from './common/Helpers';
import { CANCELLED, NON_AAA, FREE_UPPER_CASE } from './common/Constants';
import DataContext from './contexts/DataContext';
import StringPlaceholderService from "../services/StringPlaceholderService";


const desktopStyles = {
    paragraph: { fontSize: '16px' },
    title: { lineHeight: '48px' }
};
const mobileStyles = {
    link: { fontSize: '14px' }
};

const DynamicParagraphStyle = ({ children, ...props }) =>
    <DynamicParagraphText
        id="rich-text"
        {...props}
        text={children}
    />;

class InactiveMembershipPage extends Component {
    static contextType = DataContext;
    componentDidMount() {
        this.loadContent();
    }

    async loadContent() {
        const { userDetails } = this.context || {};
        const membershipStatus = userDetails?.membership?.membershipStatus;
        const response = await ContentfulService.getEntry(membershipStatus === CANCELLED
            ? ContentfulIds.InactiveMembershipPage.CancelledMembership
            : ContentfulIds.InactiveMembershipPage.InvalidMembership);
        this.setState({ 
            content: response.fields,
            lastCancelledSubscriptionProductType: userDetails?.lastCancelledSubscriptionProductType,
            lastCancelledSubscriptionTermEndDate: userDetails?.lastCancelledSubscriptionTermEndDate
         });
    }

    setOptions = () => {
        const options = {
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) =>
                    <DynamicParagraphStyle
                        desktopStyles={desktopStyles.paragraph}
                        style={{ padding: '10px 0px' }}>
                        {children}
                    </DynamicParagraphStyle>,
                [INLINES.HYPERLINK]: (node, next) =>
                    <Link
                        to='#'
                        onClick={() => this.handleRedirection(node.data.uri)}
                        mobileStyles={mobileStyles.link}
                        text={node.content[0].value}
                    />
            },
            renderText: (text) => {
                const properties = {
                    subscriptionCancellationDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(this.state.lastCancelledSubscriptionTermEndDate))
                };
                return StringPlaceholderService.replace(text, properties)
            }
        };
        return options;
    }

    handleRedirection = async (url) => {
        if (url.includes('https')) {
            window.open(url, '_self');
            return;
        }
        if (url === '/invalid') {
            await Api.put('/api/users/attach-membership-number');
            await Api.login();
            return;
        }
        if (url === '/product/') {
            setMemberTypeInSessionStorage(NON_AAA);
        }
        this.props.history.push(url);
    }

    render() {
        const { content, lastCancelledSubscriptionProductType } = this.state || {};
        if (!content) {
            return <Spinner />;
        }
        return (
            <Content>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title
                            style={{ marginTop: '1rem' }}
                            desktopStyles={desktopStyles.title}>
                            {lastCancelledSubscriptionProductType === FREE_UPPER_CASE ? content.subTitle : content.title}
                        </Title>
                        <RichText
                            text={lastCancelledSubscriptionProductType === FREE_UPPER_CASE ? content.subDescription : content.description}
                            options={this.setOptions()}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageMetaTags(withPageTitle(InactiveMembershipPage, "Inactive Membership"), { noindex: true });