import React from 'react';
import { colors } from '../../styles/common';
import styled from 'styled-components';
import { Component } from 'react';
import Link from '../elements/Link';
import { Label } from '../elements/LabeledInput';
import Button from '../elements/Button';
import Input from '../elements/Input';
import { NotificationTypes } from '../notifications/Notifications';
import InfoText from '../elements/InfoText';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import Spinner from '../elements/Spinner';
import { redirectParameters, redirectParamSessionStorage, buildUrl, getIndividualPromoCodeNameValueFromSessionStorage, setIndividualPromoCodeNameValueDirectlyInSessionStorage, deleteIndividualPromoCodeNameValueFromSessionStorage } from './Helpers'
import ReplacePromoCodeModal from '../admin/ReplacePromoCodeModal';
import { ANNUALLY, HUNDRED_PERCENTAGE_PROMO_FOR_UPGRADE, INVALID_PROMO_CODE, MONTHLY } from './Constants';

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;
const styles = {
    link: { color: colors.blue, marginTop: '2rem', fontWeight: 'bold' },
    addPromoButton: { fontSize: '17px', fontWeight: 500, background: `${colors.darkBlue}`, width: '32%', color: `${colors.pureWhite}`, boxSizing: 'border-box', margin: '1rem 1rem 1rem 0' },
    label: { marginTop: '0.5rem', fontWeight: 700 },
    paragraph: { fontWeight: 700, marginBottom: '0px' },
    input: { fontWeight: 500, width: '60%', boxSizing: 'border-box', margin: '1rem 1rem 1rem 0' }
}
class PromoCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPromoCodeField: props.showPromoCodeField || false,
            appliedPromoCode: null,
            promoCode: null,
            isOpen: false
        };
    }
    componentDidMount() {
        this.props.notificationContext.clearNotifications();
        this.props.dataContext.removePromoCodeData();
        sessionStorage.getItem(redirectParamSessionStorage) && this.addPromoCodeFromSession()
    }
    addPromoCodeFromSession = () => {
        const redirectParams = sessionStorage.getItem(redirectParamSessionStorage);
        const { paymentMode } = this.context.product || {};
        if (redirectParams) {
            let promoCodeFromSession = '';
            if (paymentMode === ANNUALLY && JSON.parse(redirectParams)[redirectParameters.annualPromo]) {
                promoCodeFromSession = JSON.parse(redirectParams)[redirectParameters.annualPromo];
            } else if (paymentMode === MONTHLY && JSON.parse(redirectParams)[redirectParameters.monthlyPromo]) {
                promoCodeFromSession = JSON.parse(redirectParams)[redirectParameters.monthlyPromo];
            } else if (getIndividualPromoCodeNameValueFromSessionStorage()) {
                promoCodeFromSession = getIndividualPromoCodeNameValueFromSessionStorage();
            }
            promoCodeFromSession && this.setState({
                promoCodeFromSession
            }, () => this.addPromo());
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.ratePlanId !== this.props.ratePlanId) {
            this.addPromoCodeFromSession()
        }
    }
    async componentWillReceiveProps(nextProps) {
        if (this.props.ratePlanId !== nextProps.ratePlanId) {
            const { clearNotifications } = this.props.notificationContext;
            clearNotifications();
            this.props.dataContext.removePromoCodeData();
            if (this.state.appliedPromoCode) {
                const productsWithoutPromoCodes = JSON.parse(localStorage.getItem('productsWithoutPromoCodes'));
                if (productsWithoutPromoCodes) {
                    this.props.onApplyPromoCode({
                        promoCode: null,
                        products: productsWithoutPromoCodes
                    });
                }
            } else {
                this.props.onApplyPromoCode({});
            }
            this.setState({
                showPromoCodeField: false,
                promoCode: null,
                appliedPromoCode: null,
            });
        }
    }

    onAddPromoCodeClick = () => {
        this.setState({
            showPromoCodeField: true
        });
    }

    handlePromoCodeChange = (event) => {
        this.setState({
            promoCode: event.target.value,
        });
    }

    inValidPromoCode = (promoCode) => {
        const { notificationContext: { addNotification } } = this.props;
        this.setState({
            isPromoCodeProcessing: false,
        });
        addNotification(NotificationTypes.INVAID_PROMO_ERROR, {
            promoCode,
        });
    }

    validatePromoCode = async () => {
        const { options, Api, notificationContext: { addNotification, clearNotifications } } = this.props;
        const { promoCodeFromSession } = this.state;
        let promoCode = this.state.promoCode || promoCodeFromSession;
        setIndividualPromoCodeNameValueDirectlyInSessionStorage(promoCode);
        if (!promoCode.match(/^[a-z0-9]+$/i)) {
            this.inValidPromoCode(promoCode);
            return;
        }
        this.setState({
            isPromoCodeProcessing: true
        });
        promoCode = promoCode?.trim().toUpperCase() || '';
        const userId = this.props?.dataContext?.userDetails?.userId || this.props?.dataContext?.searchResult?.userId || null;
        const apiPayload = {
            promoCode,
            productRatePlanId: this.props.ratePlanId,
            userId
        };
        const promoCodeValidateURL = options.validateURL;
        const response = await Api.post(promoCodeValidateURL, apiPayload);
        if (!response || response.validationErrors) {
            clearNotifications();
            deleteIndividualPromoCodeNameValueFromSessionStorage();
            this.props.dataContext.removePromoCodeData();
            if (!promoCodeFromSession || this.state.promoCode) {
                for (const apiError of response.validationErrors) {
                    if (apiError.property === INVALID_PROMO_CODE) {
                        await addNotification(NotificationTypes.INVAID_PROMO_ERROR, {
                            promoCode
                        });
                    } else if (apiError.property === HUNDRED_PERCENTAGE_PROMO_FOR_UPGRADE) {
                        await addNotification(NotificationTypes.HUNDRED_PERCENTAGE_PROMO_FOR_UPGRADE, {
                            promoCode
                        });
                    }
                }
            }
            this.setState({
                appliedPromoCode: null,
                isPromoCodeProcessing: false,
                isOpen: false
            });
            const productsWithoutPromoCodes = JSON.parse(localStorage.getItem('productsWithoutPromoCodes'));
            this.props.onApplyPromoCode({
                promoCode,
                products: productsWithoutPromoCodes
            });
            return null;
        } else {
            return response;
        }
    }
    addPromo = async () => {
        const response = await this.validatePromoCode();
        if (!response) {
            return;
        }
        const { options, Api, notificationContext: { addNotification, clearNotifications } } = this.props;
        clearNotifications();
        const { promoCodeFromSession } = this.state;
        let promoCode = this.state.promoCode || promoCodeFromSession;
        promoCode = promoCode?.trim().toUpperCase() || '';
        const params = {
            'promo-code': promoCode,
        }
        const ratePlanURL = buildUrl(options.zuoraRatePlanURL, params);
        const ratePlanResponse = await Api.get(ratePlanURL);
        this.setState({
            isPromoCodeValid: true,
            promoCodeData: response,
            appliedPromoCode: promoCode,
            promoCode: '',
            isPromoCodeProcessing: false,
            showPromoCodeField: true,
            isOpen: false
        });
        const { id, type, amount, billingPeriod, effectiveEndDate, customFields, campaign } = response || {};
        const product = this.findProduct(ratePlanResponse.products);
        this.props.dataContext.setPromoCodeData({
            promoCodeRatePlanId: id,
            promoCode,
            type,
            amount,
            billingPeriod,
            effectiveEndDate,
            promoCodeCustomDescription: customFields?.Description?.value || '',
            discountAmount: product?.promoCode?.price,
            campaign
        });
        this.props.onApplyPromoCode({
            promoCode,
            products: ratePlanResponse.products
        });
        await addNotification(NotificationTypes.PROMO_CODE_SUCCESS, {
            promoCode,
            campaign
        });
    }

    findProduct(products) {
        const { options } = this.props;
        const mappedProducts = options.mapProductsFunc(products);
        const foundProduct = mappedProducts.find(product => product.id === this.props.ratePlanId);
        const selectedProduct = foundProduct ? foundProduct : {};
        return selectedProduct;
    }
    confirmPromoReplacement = async () => {
        const { promoCodeFromSession } = this.state;
        let promoCode = this.state.promoCode || promoCodeFromSession;
        if (!promoCode.match(/^[a-z0-9]+$/i)) {
            this.inValidPromoCode(promoCode);
            return;
        } else {
            const response = await this.validatePromoCode();
            if (!response) {
                return;
            }
            this.setState({
                newPromoCodeDescription: response.campaign,
                isPromoCodeProcessing: false,
                isOpen: true
            })
        }
    }
    closeModal = () => {
        setIndividualPromoCodeNameValueDirectlyInSessionStorage(this.state.appliedPromoCode);
        this.setState({
            isOpen: false
        })
    }
    keepPromoCode = () => {
        setIndividualPromoCodeNameValueDirectlyInSessionStorage(this.state.appliedPromoCode);
        this.setState({
            isOpen: false,
            promoCode: ''
        })
    }
    render() {
        const { showPromoCodeField, promoCode, appliedPromoCode, isPromoCodeProcessing, isOpen, newPromoCodeDescription } = this.state || {};
        const buttonDisable = promoCode?.trim().toUpperCase() === JSON.parse(localStorage.getItem('promoCodeData'))?.promoCode || promoCode === null || promoCode?.trim() === '' || this.props.isSubmitDisabled === true;
        if (isPromoCodeProcessing) {
            return <Spinner />;
        }
        return (
            <>
                {
                    !showPromoCodeField &&
                    <Link
                        style={styles.link}
                        text={'Add Promo Code'}
                        onClick={this.onAddPromoCodeClick}
                    />
                }
                {
                    isOpen &&
                    <ReplacePromoCodeModal
                        isOpen={isOpen}
                        closeModal={this.closeModal}
                        addPromo={this.addPromo}
                        keepPromoCode={this.keepPromoCode}
                        promoCode={promoCode}
                        newPromoCodeDescription={newPromoCodeDescription}
                        promoCodeFromSession={JSON.parse(localStorage.getItem('promoCodeData'))}
                    />
                }
                {
                    showPromoCodeField &&
                    <>
                        <Label
                            htmlFor="promoCode"
                            style={styles.label}>Promo Code
                        </Label>
                        {
                            appliedPromoCode &&
                            <InfoText>
                                <DynamicParagraphText
                                    desktopFontSize='17px'
                                    style={styles.paragraph}
                                    text={`Applied: ${appliedPromoCode}`}
                                />
                            </InfoText>
                        }
                        <Container>
                            <Input
                                style={styles.input}
                                label="Promo Code"
                                type="text"
                                disabled={this.props.isSubmitDisabled}
                                name="promoCode"
                                value={promoCode}
                                onChange={this.handlePromoCodeChange}
                            />
                            <Button
                                onClick={appliedPromoCode ? this.confirmPromoReplacement : this.addPromo}
                                mobileStyles={{ fontSize: '16px' }}
                                style={styles.addPromoButton}
                                text={`${appliedPromoCode ? 'New Code' : 'Add Code'}`}
                                disabled={buttonDisable}
                            />
                        </Container>
                    </>
                }
            </>
        );
    }
}

export default PromoCode;