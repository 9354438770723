import React from "react";
import styled from "styled-components";
import Title from "../elements/Title";
import { colors, mobile } from "../../styles/common";
import ProtectElementImages from "./ProtectElementImages";
import ProtectElementContent from "./ProtectElementContent";

const ProductDescriptionSection = styled.div`
  min-height: 184px;
  width: 100%;
  padding-bottom: 40px;
  padding-top: 10px;
  ${ mobile`
    background: ${colors.lightGrey};
    padding-bottom: 20px;
  `} 
`;

const mobileTitleStyle = {
  fontSize: "24px",
  lineHeight: "29px",
  letterSpacing: '0.01em'
};

const desktopTitleStyle = {
  fontSize: "36px",
  lineHeight: "48px",
  letterSpacing: '1px',
  marginBottom: '3.5rem',
};

const ProductDescriptionPage = ({ fields }) => {
  const { title, sections } = fields;
  return (
    <ProductDescriptionSection>
      <Title mobileStyles={mobileTitleStyle} desktopStyles={desktopTitleStyle} style={{ textAlign: "center", fontWeight: "normal", width: "100%", color: `${colors.darkBlue}` }}>{title}</Title>
      <ProtectElementImages sections={sections}/>
      <ProtectElementContent sections={sections} />
    </ProductDescriptionSection>
  );
};

export default ProductDescriptionPage;
