import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import useCustomForm from '../../../hooks/useCustomForm';
import LabeledInput from '../../elements/LabeledInput';
import SubmitButton from '../../elements/SubmitButton';
import Input from '../../elements/Input';
import validate from './validations';
import FormError from '../../elements/FormError';
import Link from '../../elements/Link';
import MaskedLabeledInput from '../../elements/MaskedLabeledInput';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { segmentAnalytics } from '../../../configuration/ApplicationContext';
import DataContext from '../../contexts/DataContext';
import LabelWithTooltip from '../../elements/LabelWithTooltip';
import { convertPhoneNumberToMaskedValue, formatDateMMDDYYYY, formatMembershipId, removeLeadingZerosForSingleDigitDate } from '../../common/Helpers';
import ReactMarkdown from 'react-markdown';
import { colors } from '../../../styles/common';
import { AddressFields } from '../../common/AddressFields';

const PersonalInfoForm = ({ initialValues, onSubmit, onSelectAddress, loginEmail, onSelectBillingAddress, onBillingAddressSameAsHomeAddress, apiErrors, isEditPersonalInfo, isEditPersonalInfoFromAdmin, personalInformation, onCancel, content, onChangePassword, membership, isSubmitDisabled, isEmailVerified, isEmailNotAvailable, membershipId, updateMembershipNumber = false, relaxRequiredValidationsForFields = [], customErrorMessages = {}, clearCustomError }) => {
    const contextType = useContext(DataContext);
    const [homeAddress, setHomeAddress] = useState({});
    const [manualAddressEntry, setManualAddressEntry] = useState(false);
    const [billingAddressSameAsHomeAddress, setBillingAddressSameAsHomeAddress] = useState(personalInformation?.billingAddressSameAsHomeAddress);
    if (personalInformation) {
        const { homeAddress, billingAddress, ...rest } = personalInformation;
        rest.dob = removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(rest.dob));
        rest.phoneNumber = convertPhoneNumberToMaskedValue(rest.phoneNumber);
        initialValues = {
            ...rest,
            homeAddress: `${homeAddress?.lineOne}, ${homeAddress?.city}, ${homeAddress?.state}, ${homeAddress?.countryCode}`,
            additionalHomeInfo: homeAddress?.lineTwo || '',
            billingAddress: `${billingAddress?.lineOne}, ${billingAddress?.city}, ${billingAddress?.state}, ${billingAddress?.countryCode}`,
            additionalBillingInfo: billingAddress?.lineTwo || '',
            loginEmail: loginEmail || '',
            lineOne: `${homeAddress?.lineOne}` || '',
            city: `${homeAddress?.city}` || '',
            state: `${homeAddress?.state}` || '',
            zipCode: `${homeAddress?.zipCode}` || '',
            billingLineOne: `${billingAddress?.lineOne}` || '',
            billingCity: `${billingAddress?.city}` || '',
            billingState: `${billingAddress?.state}` || '',
            billingZipCode: `${billingAddress?.zipCode}` || '',
        }
        if (isEditPersonalInfoFromAdmin) {
            initialValues = {
                ...initialValues,
                membershipNumber: membershipId ? formatMembershipId(membershipId) : ''
            }
        }
    }

    if (membership && !personalInformation) {
        const { address, firstName, lastName, email, zipCode } = membership;
        initialValues = {
            firstName,
            lastName,
            email,
            retypeEmail: '',
            homeAddress: `${address?.lineOne}, ${address?.city}, ${address?.state}, ${address?.countryCode}`,
            additionalHomeInfo: address?.lineTwo || '',
            dob: '',
            phoneNumber: '',
            zipCode,
            middleName: '',
            lineOne: `${address?.lineOne}` || '',
            city: `${address?.city}` || '',
            state: `${address?.state}` || '',
        }
    }

    let {
        values,
        errors,
        touched,
        dirty,
        submitted,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useCustomForm({ initialValues, validate, onSubmit, relaxRequiredValidationsForFields, updateMembershipNumber, clearCustomError, homeAddress, manualAddressEntry, billingAddressSameAsHomeAddress });
    if (Object.keys(customErrorMessages).length && !Object.keys(errors).length) {
        errors = {
            ...customErrorMessages
        };
    }

    const [personalFormInfo, setPersonalFormInfo] = useState(values);
    const filteredErrors = submitted ? errors : Object.keys(errors)
        .filter(key => touched[key])
        .reduce((obj, key) => {
            obj[key] = errors[key];
            return obj;
        }, {});
    const inputHandlers = {
        onChange: handleChange,
        onBlur: handleBlur,
        extratopspace: '0.5rem',
        extraTopLabelSpace: '0.75rem'
    };
    const handleBlurEvent = (event) => {
        const { target } = event;
        const { name, value } = target;
        trackFieldChange(name, value);
    }

    const handlerCopyPaste = (event) => {
        event.preventDefault();
    }

    const formatMembershipNumber = (event) => {
        inputHandlers.onBlur(event);
        if (values.membershipNumber.length === 16) {
            values.membershipNumber = formatMembershipId(event.target.value);
        }
    }


    const trackFieldChange = (fieldName, newValue) => {
        if (personalFormInfo[fieldName] && personalFormInfo[fieldName] !== newValue) {
            segmentAnalytics.track('Personal Information Edited', {
                memberId: contextType?.userDetails?.userId,
                fieldName: fieldName,
                oldValue: personalFormInfo[fieldName],
                newValue: newValue
            });
        }
        const obj = {};
        obj[fieldName] = newValue;
        setPersonalFormInfo({ ...personalFormInfo, ...obj });
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if ((isEditPersonalInfo || isEditPersonalInfoFromAdmin) && !initialValues.dob) {
            delete errors.dob;
        }
        if (billingAddressSameAsHomeAddress) {
            if (!values.billingAddress) {
                delete errors.billingAddress
            }
        } else if (!isEditPersonalInfo) {
            delete errors.billingAddress
        }
        handleSubmit();
    }

    const selectAddress = (homeAddress) => {
        setHomeAddress(homeAddress)
        onSelectAddress(homeAddress);
    }
    return (
        <form onSubmit={handleFormSubmit} noValidate onBlur={handleBlurEvent}>
            <FormError showErrors={submitted} errors={filteredErrors || !Object.keys(customErrorMessages).length} message="Please fill out the fields highlighted below" />
            <FormError showErrors={submitted} errors={apiErrors} />
            <LabeledInput label="Full Name" type="text" pattern="[a-zA-Z\s]+" name="firstName" value={values.firstName} placeholder="First" maxLength={31} error={filteredErrors.firstName} {...inputHandlers} />
            <Input label="Middle Name" type="text" pattern="[a-zA-Z\s]+" name="middleName" value={values.middleName} placeholder="Middle (Optional)" maxLength={31} error={filteredErrors.middleName} {...inputHandlers} />
            <Input label="Last Name" type="text" pattern="[a-zA-Z\s'-]+" name="lastName" value={values.lastName} placeholder="Last" maxLength={31} error={filteredErrors.lastName} {...inputHandlers} />
            {isEmailVerified && !isEditPersonalInfoFromAdmin
                &&
                <LabelWithTooltip label="Email" type="text" name="email" value={values.email} error={filteredErrors.dob} placeholder="MM/DD/YYYY"  {...inputHandlers} />
            }
            {isEditPersonalInfoFromAdmin &&
                <>
                    <LabeledInput label="Sign In Email" style={{ border: 'none', background: 'none' }} type="text" name="loginEmail" disabled={true} value={loginEmail} error={filteredErrors.email}  {...inputHandlers} />
                    <LabeledInput label="Email to Receive Communications (Defaults to: Same as Sign In Email)" type="email" name="email" value={values.email} isEmailNotAvailable={isEmailNotAvailable} error={filteredErrors.email} dirty={isEmailNotAvailable} {...inputHandlers} inlineMessage={isEmailNotAvailable ? 'Please ask Member for email address. This field is preferred, but not mandatory.' : null} />
                </>
            }
            <LabeledInput label="Email" type="email" name="email" value={values.email} hide={isEmailVerified || isEditPersonalInfoFromAdmin} isEmailNotAvailable={isEmailNotAvailable} error={filteredErrors.email} dirty={isEmailNotAvailable} {...inputHandlers} inlineMessage={isEmailNotAvailable ? 'Please ask Member for email address. This field is preferred, but not mandatory.' : null} />
            <LabeledInput label="Retype Email" hide={!isEmailNotAvailable && !dirty.email} name="retypeEmail" value={values.retypeEmail} disabled={isEmailVerified} isEmailNotAvailable={isEmailNotAvailable} error={filteredErrors.retypeEmail} onPaste={handlerCopyPaste} dirty={isEmailNotAvailable} {...inputHandlers} inlineMessage={isEmailNotAvailable ? 'Please ask Member to repeat address' : null} />
            <AddressFields values={values} filteredErrors={filteredErrors} {...inputHandlers}
                onSelectAddress={selectAddress}
                onBillingAddressSameAsHomeAddress={onBillingAddressSameAsHomeAddress}
                billingAddressSameAsHomeAddress={billingAddressSameAsHomeAddress}
                isEditPersonalInfo={isEditPersonalInfo}
                manualAddressEntry={manualAddressEntry}
                onSelectBillingAddress={onSelectBillingAddress}
                isEditPersonalInfoFromAdmin={isEditPersonalInfoFromAdmin}
                showBillingCheck={isEditPersonalInfoFromAdmin}
                manualAddressLinkShow={isEditPersonalInfoFromAdmin}
                setBillingAddressSameAsHomeAddress={setBillingAddressSameAsHomeAddress}
                setManualAddressEntry={setManualAddressEntry}
            />
            <MaskedLabeledInput mask="999-999-9999" maskChar="" label="Phone Number" type="text" name="phoneNumber" value={values.phoneNumber} error={filteredErrors.phoneNumber} {...inputHandlers} />
            {(!isEditPersonalInfo && !isEditPersonalInfoFromAdmin) &&
                <>
                    <LabeledInput style={{ marginBottom: '1rem' }} label="Date of Birth" type="text" name="dob" tooltipIcon={faQuestionCircle} value={values.dob} error={filteredErrors.dob} placeholder="MM/DD/YYYY"  {...inputHandlers} />
                    <ReactMarkdown className="react-mark-down" source={content.buttomText}
                        renderers={{ link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children} </a> }} />
                    <SubmitButton
                        text="Submit Personal Information"
                        disabled={isSubmitDisabled}
                    />
                </>
            }
            {isEditPersonalInfoFromAdmin &&
                <>
                    <LabeledInput label="Date of Birth" type="text" name="dob" tooltipIcon={faQuestionCircle} value={values.dob} error={filteredErrors.dob} placeholder="MM/DD/YYYY"  {...inputHandlers} />
                    <LabeledInput id="membershipNumberAdmin" type="text" label="AAA Membership Number" name="membershipNumber" value={values.membershipNumber} placeholder="AAA Membership Number" error={filteredErrors.membershipNumber} {...inputHandlers} onBlur={formatMembershipNumber} />
                    <SubmitButton text="Submit Changes" />
                    <Link textalign="center" to='#' extraleftspace="6rem" extratopspace="1rem" extrabottomspace="1.5rem" text="Cancel Changes" onClick={onCancel} />
                </>
            }
            {isEditPersonalInfo &&
                <>
                    {initialValues.dob !== '' &&
                        <LabelWithTooltip label="Date of Birth" type="text" name="dob" tooltipIcon={faQuestionCircle} value={values.dob} error={filteredErrors.dob} placeholder="MM/DD/YYYY"  {...inputHandlers} />
                    }
                    {initialValues.dob === '' &&
                        <LabeledInput label="Date of Birth" style={{ color: `${colors.greyText}` }} disabled={true} type="text" name="dob" tooltipIcon={faQuestionCircle} value={values.dob} error={filteredErrors.dob} placeholder="MM/DD/YYYY"  {...inputHandlers} />
                    }
                    <SubmitButton text="Submit Changes" disabled={isSubmitDisabled} />
                    <Link textalign="center" to='#' extraleftspace="6rem" extratopspace="1rem" extrabottomspace="1.5rem" text="Cancel Changes" onClick={onCancel} />
                </>
            }
        </form>
    );
};

PersonalInfoForm.propTypes = {
    initialValues: PropTypes.shape({})
};

PersonalInfoForm.defaultProps = {
    initialValues: {
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        retypeEmail: '',
        homeAddress: '',
        billingAddress: '',
        additionalHomeInfo: '',
        additionalBillingInfo: '',
        phoneNumber: '',
        dob: ''
    }
};

export default PersonalInfoForm;
