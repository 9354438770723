import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../styles/common';
import Button from '../../../elements/Button';
import Section from '../../../elements/Section';
import { ANNUALLY, BASIC_LOWER_CASE, BASIC, MONTHLY, AAA_MEMBER } from '../../../common/Constants';

const PriceDetailsSection = styled.div`
    text-align: center;
    padding: 30px;
    display: flex;
    flex-direction: column;
`;

const styles = {
    annualAmountLink: { marginTop: '10px', color: colors.darkBlue, fontSize: '16px', textDecoration: 'underline', fontWeight: 500 },
    amountDescription: { fontStyle: 'Italic', fontSize: '14px', padding: '0px' },
    productDescription: { fontSize: '14px', padding: '0px' },
    monthlyAmountButton: {
        textAlign: 'center', backgroundColor: colors.darkBlue, color: colors.pureWhite,
        fontSize: '22px', lineHeight: 'unset', margin: '15px auto', borderRadius: '10px', padding: '12px 0'
    },
    disabledCtaAmount: { fontSize: '19px', lineHeight: '25px', fontWeight:'500' }
};
const ProductChartTabbedViewPricingSection = ({ ...props }) => {
    const {
        memberType,
        productDescription,
        amountDescription,
        annualAmount,
        monthlyAmount,
        nonAaaMemberDetails,
        selectedProduct,
        disableCta,
        onProductSelect
    } = props || {};
    const pricingDetailsSectionData = {
        annualAmount: memberType === AAA_MEMBER ? annualAmount : nonAaaMemberDetails.annualAmount,
        monthlyAmount: memberType === AAA_MEMBER ? monthlyAmount : nonAaaMemberDetails.monthlyAmount
    };
    const monthlyAmountSection = disableCta ? <Section
        text={`${pricingDetailsSectionData.monthlyAmount} ${selectedProduct !== BASIC ? '/ month' : ''}`}
        style={styles.disabledCtaAmount}
    /> : <Button
        style={styles.monthlyAmountButton}
        text={`${pricingDetailsSectionData.monthlyAmount} ${selectedProduct !== BASIC ? '/ month' : ''}`}
        onClick={() => onProductSelect(MONTHLY, 'Button')}
   />
    return (
        <PriceDetailsSection>
            <Section
                style={styles.productDescription}
                text={productDescription}
            />
            {monthlyAmountSection}
        </PriceDetailsSection>
    );
}
export default ProductChartTabbedViewPricingSection;