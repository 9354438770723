import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/common";
import DynamicParagraphText from "../elements/DynamicParagraphText";

const paragraphStyle = {
    margin: 0,
    textAlign: 'center',
    color: colors.darkBlue,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '34px',
    lineHeight: '47px',
    letterSpacing: '1px',
};
const GuardSection = styled.div`
    padding: 50px 4px;
`;
const AAAGuard = ({ section }) => {
    if (!section.fields) {
        return null;
    }
    return (
        <GuardSection>
            <DynamicParagraphText style={paragraphStyle} mobileFontSize='21px' desktopFontSize='36px' text={section.fields.title}></DynamicParagraphText>
            <DynamicParagraphText style={paragraphStyle} mobileFontSize='21px' desktopFontSize='36px' text={section.fields.sections[0].fields.title}></DynamicParagraphText>
        </GuardSection>
    );
};

export default AAAGuard;
