import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles/common';
import Section from '../../elements/Section';
import ToolTip from '../../tooltip/ToolTip';
import { PROTECT } from './../../common/Constants'

const BottomSectionStyle = styled.div`
    text-align: left;
`;
const BottomSection = styled.div`
    display: flex;
    padding: 0.5rem 0rem 1rem 1rem;
    color: ${colors.textColor}
`;

const UpperSection = styled.div`
    padding-top: 1rem;
    min-height: 44rem;
`;

const LowerSection = styled.div`
    padding-top: 1rem;
    padding-bottom: 1rem;
`;

const style = {
    featureName: { display: 'inline-block', fontSize: '16px', lineHeight: '24px', padding: '0', marginLeft: '0.5rem', fontWeight: 500 },
    icon: { width: '14px', paddingTop: '5px', paddingLeft: '15px', cursor: 'pointer' },
    tooltip: { fontSize: '10px' },
    aaaTitle: { backgroundColor: colors.greyborder, color: colors.pureWhite, textAlign: 'center', paddingTop: '1rem' }
};

const BundleProductChartBottomSection = ({ ...props }) => {
    const { productFeatures, title } = props || {};

    const titleStyles = {
        backgroundColor: title === PROTECT ? colors.mediumBlue : colors.darkBlue,
        color: colors.pureWhite,
        textAlign: 'center',
        paddingTop: '1rem'
    }

    return (
        <BottomSectionStyle>
            <Section
                style={titleStyles}
                text={productFeatures.identityProtection.identityProtectionName}
            />
            <UpperSection>
                {
                    productFeatures.identityProtection.identityProtectionList.map((productFeature, index) =>
                        <BottomSection key={index}>
                            <FontAwesomeIcon
                                data-tip
                                data-for={`identityfeature ${index}`}
                                style={style.icon}
                                icon={faQuestionCircle}
                            />
                            <ToolTip
                                fontStyle={style.tooltip}
                                textcolor={colors.pureWhite}
                                backgroundcolor={colors.textColor}
                                id={`identityfeature ${index}`}
                                content={productFeature.description}
                            />
                            <Section
                                style={style.featureName}
                                dangerouslySetInnerHTML={{ __html: productFeature.name }}
                            />
                        </BottomSection>
                    )
                }
            </UpperSection>
            <Section
                style={style.aaaTitle}
                text={productFeatures.aaaMembership.aaaMembershipName}
            />
            <LowerSection>
                {
                    productFeatures.aaaMembership.aaaMembershipList.map((productFeature, index) =>
                        <BottomSection key={index}>
                            <FontAwesomeIcon
                                data-tip
                                data-for={`aaaMembershipfeature ${index}`}
                                style={style.icon}
                                icon={faQuestionCircle}
                            />
                            <ToolTip
                                fontStyle={style.tooltip}
                                textcolor={colors.pureWhite}
                                backgroundcolor={colors.textColor}
                                id={`aaaMembershipfeature ${index}`}
                                content={productFeature.description}
                            />
                            <Section
                                style={style.featureName}
                                dangerouslySetInnerHTML={{ __html: productFeature.name }}
                            />
                        </BottomSection>
                    )
                }
            </LowerSection>
        </BottomSectionStyle >
    );
}

export default BundleProductChartBottomSection;