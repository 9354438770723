import React from "react";
import ResponsiveLandingPageLayout from './layout/ResponsiveLandingPageLayout';
import ContentfulIds from "../configuration/ContentfulIds";
import Spinner from "./elements/Spinner";
import BaseLogin from "./BaseLogin";
import LandingContent from "./layout/LandingContent";
import withZipGate from "./layout/withZipGate";
import withPageTitle from "./layout/withPageTitle";
import { META_DESCRIPTION } from "./common/Constants";
import withPageMetaTags from "./layout/withPageMetaTags";
import CompareOurPlans from "./v1/plan-details/customer/CompareOurPlans";
import { ContentfulService } from "../configuration/ApplicationContext";
import { getPromoCodeNameFromRedirectParams, setPlanTypeInSessionStorage } from "./common/Helpers";
class PlanDetailsPage extends BaseLogin {
    componentDidMount() {
        this.loadAndFilterProductChartContent(ContentfulIds.CustomerCompareOurPlans);
        this.loadDisclaimerContent(ContentfulIds.CustomerDisclaimer);
    }

    loadAndFilterProductChartContent = async (contentfulId) => {
        const productChart = await ContentfulService.getEntries({ 'sys.id': contentfulId, include: 5 });
        this.setState({ productChartContent: productChart.items[0].fields });
    }

    onProductSelectToCreateSubscription = (planType = '') => {
        setPlanTypeInSessionStorage(planType);
        this.onSignup();
    }

    render() {
        const {
            productChartContent,
            disclaimerContent
        } = this.state || {};
        if (!productChartContent || !disclaimerContent) {
            return <Spinner />
        }
        return (
            <LandingContent
                promocodename={getPromoCodeNameFromRedirectParams()}>
                <ResponsiveLandingPageLayout>
                    <CompareOurPlans
                        productChartContent={productChartContent}
                        disclaimer={disclaimerContent}
                        onProductSelectToCreateSubscription={this.onProductSelectToCreateSubscription}
                        showTrialSection={true}
                    />
                </ResponsiveLandingPageLayout>
            </LandingContent>
        );
    }
}

export default withPageMetaTags(withPageTitle(withZipGate(PlanDetailsPage), "Compare Protection Plans"), { description: META_DESCRIPTION.COMPARE_PLANS });