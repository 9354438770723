import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import RichText from '../elements/RichText';
import Title from '../elements/Title';
import SelectBox from '../elements/SelectBox';
import RadioButton from '../elements/RadioButton';
import SubmitButton from '../elements/SubmitButton';
import Link from '../elements/Link';
import Section from '../elements/Section';
import { colors, desktop } from '../../styles/common';
import { BLOCKS } from '@contentful/rich-text-types';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import Currency from '../common/Currency';
import StringPlaceholderService from '../../services/StringPlaceholderService';
import PlanDetailsTabbedView from '../landing-page/PlanDetailsTabbedView';
import InfoText from '../elements/InfoText';
import DataContext from '../contexts/DataContext';
import PromoCode from '../common/PromoCode';
import NotificationContext from '../contexts/NotificationContext';
import { Api } from '../../configuration/ApplicationContext';
import { removeLeadingZerosForSingleDigitDate } from '../common/Helpers';
import moment from 'moment';
import { MONTHLY, ANNUALLY } from '../common/Constants';

const linkStyle = {
    paddingTop: '3rem',
    paddingBottom: '2rem',
    backgroundColor: `${colors.lightGrey}`,
    textAlign: 'center',
    textDecoration: 'underline',
    display: 'block',
    color: `${colors.darkBlue}`
}

const UpgradeLinkContent = styled.div`
    ${desktop`	
        width: 320px;
    `}
`;

const styles = {
    paymentTerm: { fontSize: '16px', fontWeight: 'bold', paddingBottom: '0px' },
    basePrice: { textDecorationLine: 'line-through', color: colors.scarlet },
    aaaMembership: { fontWeight: 'bold', color: colors.darkBlue },
    updateCoverageText: { fontStyle: 'italic', fontSize: '14px' },
    link: { fontSize: '14px' },
    upgradeLink: { fontSize: '14px', textAlign: 'center', margin: '1rem 0 1.5rem 0' }
};

const ProductOffering = ({
    products,
    content,
    selectedProduct,
    onSelectProduct,
    paymentMode,
    onSelectPaymentMode,
    onSubmit,
    updateCoveragePage,
    onDeclineUpgrade,
    hideSelectProduct,
    declineText,
    subscription,
    planDetailsContent,
    planDetailsLinkText,
    onChooseProduct,
    onSelectProductTab,
    showOptions,
    isSubmitDisabled,
    onApplyPromoCode,
    onDecline,
    isAAAIdcFlow = false,
    hidePromoCode = false,
    promoCodeOptions = {},
    freeTrial = false,
    isNonAaaMember = false
}) => {
    const context = useContext(DataContext);
    const [freeTrialMobileContent, setFreeTrialMobileContent] = useState();
    const notificationsContext = useContext(NotificationContext);
    const productsByPaymentMode = products.filter(product => product.paymentMode === paymentMode);
    const buttonText = freeTrial ? `Start Free Trial - ${selectedProduct?.name} Plan` : content?.main[selectedProduct?.name.toUpperCase()]?.buttonText;
    const buttonTextMobile = freeTrial ? 'Start Free Trial' : content?.main[selectedProduct?.name.toUpperCase()]?.buttonText;
    const contentProperties = {
        paymentStartDate: removeLeadingZerosForSingleDigitDate(moment(selectedProduct?.freeTrialEndDate)?.format('MM/DD/YYYY')),
        actualAmount: Math.abs(selectedProduct?.freeTrialAmount),
        paymentMode: selectedProduct?.paymentMode === MONTHLY ? 'month' : 'year',
    }
    const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;
    const options = {
        renderText: (text) => {
            const properties = {
                selectedProduct: selectedProduct?.name
            };
            return StringPlaceholderService.replace(text, properties)
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle desktopOnly={isNonAaaMember} desktopFontSize='17px' mobileFontSize='14px'>{children}</ParagraphStyle>,
        },
    };
    useEffect(() => {
        function loadFreeTrialMobileDisclaimerContent() {
            const contentObject = JSON.parse(JSON.stringify(content));
            const freeTrialDisclaimerText = contentObject?.main[selectedProduct?.name.toUpperCase()].freeTrialMobileDescription;
            freeTrialDisclaimerText.content.forEach(ct => {
                ct.content.forEach(pr => {
                    if (pr.value) {
                        pr.value = StringPlaceholderService.replace(pr.value, contentProperties)
                    }
                })
            });
            setFreeTrialMobileContent(freeTrialDisclaimerText);
        }
        loadFreeTrialMobileDisclaimerContent();
    }, [selectedProduct]);

    return (
        <>
            {
                !isAAAIdcFlow &&
                <>
                    <Title
                        style={{ marginTop: '2rem' }}>
                        {content.title.title}
                    </Title>
                    <RichText
                        text={content.title.description}
                        options={options}
                    />
                    {
                        !hideSelectProduct &&
                        <>
                            {
                                productsByPaymentMode.map(product =>
                                    <SelectBox
                                        key={product.id}
                                        product={product}
                                        onSelectProduct={onSelectProduct}
                                        active={product.id === selectedProduct.id}
                                        freeTrial={freeTrial}
                                    />
                                )
                            }
                        </>
                    }
                    <Section
                        text='Payment Term'
                        style={styles.paymentTerm}
                    />
                    <RadioButton
                        name='payment-mode'
                        disabled={subscription?.billingFrequency === 'ANNUALLY'}
                        label='Pay Monthly'
                        paymentMode={MONTHLY}
                        checked={paymentMode === MONTHLY}
                        onChange={(event) => onSelectPaymentMode(event, 'Monthly')}
                    />
                    { subscription?.billingFrequency === 'ANNUALLY' && <RadioButton
                        name='payment-mode'
                        label='Pay Annually (5% off)'
                        paymentMode={ANNUALLY}
                        checked={paymentMode === ANNUALLY}
                        onChange={(event) => onSelectPaymentMode(event, 'Annually')}
                    />
                    }
                    {
                        !hidePromoCode &&
                        <PromoCode
                            ratePlanId={selectedProduct.id}
                            onApplyPromoCode={onApplyPromoCode}
                            dataContext={context}
                            options={promoCodeOptions}
                            notificationContext={notificationsContext}
                            Api={Api}
                        />
                    }
                    {
                        !updateCoveragePage &&
                        <>
                            {
                                !freeTrial &&
                                <Section
                                    mobileOnly='true'
                                    style={styles.basePrice}
                                    text={
                                        <>
                                            <Currency
                                                value={selectedProduct?.basePrice?.price}
                                            />
                                            /
                                            {
                                                selectedProduct.paymentMode === MONTHLY ? 'Month' : 'Year'
                                            }
                                            Standard Price
                                        </>
                                    }
                                />
                            }
                            {
                                !freeTrial &&
                                <Section
                                    mobileOnly='true'
                                    style={styles.aaaMembership}
                                    text={
                                        <>
                                            <Currency
                                                value={selectedProduct?.actualPrice?.price}
                                            />
                                            /
                                            {
                                                selectedProduct.paymentMode === MONTHLY ? 'Month' : 'Year'
                                            }
                                            for AAA Members
                                        </>
                                    }
                                />
                            }
                            <Section
                                style={{ fontStyle: 'italic', fontSize: '14px' }}
                                text="You'll be able to review your order before you're charged."
                            />
                            <SubmitButton
                                desktopOnly='true'
                                onClick={onSubmit}
                                text={buttonText}
                            />
                            <SubmitButton
                                mobileOnly='true'
                                onClick={onSubmit}
                                text={buttonTextMobile}
                            />
                            <Link
                                to='#'
                                style={styles.link}
                                textalign="center"
                                href="#"
                                extratopspace="1rem"
                                extrabottomspace="1.5rem"
                                text={declineText}
                                onClick={onDecline}
                            />
                        </>
                    }
                    {
                        updateCoveragePage &&
                        <>
                            <UpgradeLinkContent>
                                <SubmitButton
                                    onClick={onSubmit}
                                    text={StringPlaceholderService.replace(content.title.linkText, { productType: selectedProduct?.name })}
                                />
                                <Link
                                    style={styles.upgradeLink}
                                    textalign="center" href="#"
                                    extraleftspace="3.5rem"
                                    extratopspace="1rem"
                                    extrabottomspace="1.5rem"
                                    text={content.title.subTitle}
                                    to='/my-account'
                                    onClick={onDeclineUpgrade}
                                />
                            </UpgradeLinkContent>
                        </>
                    }
                </>
            }
            {
                isAAAIdcFlow &&
                <>
                    <Title
                        style={{ marginTop: '2rem', padding: '0rem 1rem' }}>
                        {content?.title?.name}
                    </Title>
                    <InfoText
                        style={{ padding: '0rem 1rem' }}>
                        <RichText
                            text={content?.title?.description}
                            options={options}
                        />
                    </InfoText>
                    <InfoText
                        desktopOnly='true'
                        style={{ padding: '0rem 1rem' }}>
                        <RadioButton
                            name='payment-mode'
                            label='Pay Monthly'
                            paymentMode={MONTHLY}
                            checked={paymentMode === MONTHLY}
                            onChange={(event) => onSelectPaymentMode(event, 'Monthly')}
                        />

                      { subscription?.billingFrequency === 'ANNUALLY' && <RadioButton
                            name='payment-mode'
                            label='Pay Annually (2 months free!)'
                            paymentMode={ANNUALLY}
                            checked={paymentMode === ANNUALLY}
                            onChange={(event) => onSelectPaymentMode(event, 'Annually')}
                        />
                      }
                    </InfoText>
                    {
                        showOptions &&
                        <InfoText
                            mobileOnly='true'
                            style={{ padding: '0rem 1rem' }}>
                            <RadioButton
                                name='pay-mode'
                                label='Pay Monthly'
                                paymentMode={MONTHLY}
                                checked={paymentMode === MONTHLY}
                                onChange={(event) => onSelectPaymentMode(event, 'Monthly')}
                            />
                       {    subscription?.billingFrequency === 'ANNUALLY' && <RadioButton
                                name='pay-mode'
                                label='Pay Annually (2 months free!)'
                                paymentMode={ANNUALLY}
                                checked={paymentMode === ANNUALLY}
                                onChange={(event) => onSelectPaymentMode(event, 'Annually')}
                            />
                    }
                        </InfoText>
                    }
                    <InfoText
                        mobileOnly='true'
                        style={{ paddingTop: '2rem' }}>
                        <PlanDetailsTabbedView
                            defaultIndex={0}
                            paymentMode={paymentMode}
                            sections={planDetailsContent}
                            isLandingPage={false}
                            isAAAIdcFlow={true}
                            onChooseProduct={onChooseProduct}
                            onSelectProductTab={onSelectProductTab}
                            isSubmitDisabled={isSubmitDisabled}
                        />
                        <a
                            target="_blank"
                            href='/plan-details'
                            style={linkStyle}>
                            {planDetailsLinkText}
                        </a>
                    </InfoText>
                </>
            }
        </>
    )
};

export default ProductOffering;
