import React from 'react';
import styled, { css } from 'styled-components';
import { mobile, desktop, colors } from '../../styles/common';

const UnorderedListTextComponent = styled.ul`
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    ${props => props.showCustomCheckBullet && css`
        list-style: none;
        text-indent: 2px;
        padding: 0 10% 0 13%;
        list-style-position: outside;
        li:before {
          content: '✓';
          color: ${colors.darkRed};
          margin-left: -1em;
          margin-right: .100em;
        }
        li {
          &:last-child {
            padding-bottom: 0px;
          }
        }
    `}
    ${props => props.ulStyle && css`
        ${props.ulStyle}
  `};

    ${props => props.styles && css`
      styles: ${props.styles};
    `}
    ${props => props.removeListPadding && css`
      li {
        padding: 0px !important;
      }
    `};

    ${props => props.desktopFontSize && css`
    ${desktop`
      font-size: ${props.desktopFontSize};
    `}
  `};
  ${props => props.mobileFontSize && css`
    ${mobile`
      font-size: ${props.mobileFontSize};
      margin-bottom: 0px;
    `}
  `};
  ${props => props.liPadding && css`
    li{
        padding: ${props.liPadding};
    }
  `};
  ${props => props.desktopStyle && css`
     ${props.desktopStyle};
  `};
`;

const UnorderedListText = ({ text, ...props }) => {
  return (
    <UnorderedListTextComponent {...props}>{text}</UnorderedListTextComponent>
  )
}

export default UnorderedListText;