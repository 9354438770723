import { Component } from "react";
import { ContentfulService } from "../configuration/ApplicationContext";
import { MyAccountPageType } from "./myaccount/MyAccountPageType";

export default class BaseMyAccount extends Component {
    determinePageType() {
        const { subscription } = this.context.memberDetails || {};
        let pageType = '';
        if (subscription?.productType === 'FREE') {
            pageType = MyAccountPageType.BASIC;
        } else if (subscription?.productType === 'COMPLETE') {
            pageType = MyAccountPageType.COMPLETE;
        } else if (subscription?.productType === 'PROTECT') {
            pageType = MyAccountPageType.PROTECT;
        }
        this.setState({
            pageType,
        });
    }

    async loadContent(contentfulId) {
        const response = await ContentfulService.getEntry(contentfulId);
        this.setState({ content: response.fields });
    }
}