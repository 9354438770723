import React from 'react';
import { AdminApi, ContentfulService } from '../../configuration/ApplicationContext';
import ContentfulIds from '../../configuration/ContentfulIds';
import AdminNotificationContext from './AdminNotificationContext';
import BaseNotificationProvider from './BaseNotificationProvider';
import AdminContext from './AdminContext';
import { ACTIVE, CANCELLED, INVALID } from '../common/Constants';
import { AdminNotificationTypes } from '../notifications/AdminNotifications';

class AdminNotificationProvider extends BaseNotificationProvider {
    static contextType = AdminContext;

    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            content: {},
            isAdmin: true,
        };
    }

    async componentDidMount() {
        await this.loadContent();
        await this.getNotifications();
    }
    
    loadContent = async () => {
        const response = await ContentfulService.getEntry(ContentfulIds.AdminNotifications);
        this.setState({ content: response.fields });
    }

    getNotifications = async () => {
        const searchResult = JSON.parse(localStorage.getItem('searchResult'));
        const { membershipStatus, membershipId } = searchResult || {};
        const hasNoKnownActiveAAAMembership = (membershipStatus === CANCELLED || !membershipId) ? true : false;
        if (searchResult?.userId) {
            const response = await AdminApi.get(`/api/users/${searchResult.userId}/account-notifications`);
            if (response) {
                response.notifications = response?.notifications?.map(({notificationType, metadata}) => {
                    const additionalProperties = {
                        hasNoKnownActiveAAAMembership
                    }
                    return this.buildNotification(notificationType, metadata, this.state.isAdmin, additionalProperties);
                });
                this.setState({
                    notifications: response.notifications
                });
            }    
        } else if(membershipStatus === CANCELLED || (membershipId && !membershipStatus)) {
           
            const notificationType = AdminNotificationTypes.NO_SUBSCRIPTION_CANCELLED_MEMBERSHIP_NUMBER ;
            this.removeNotification(notificationType)
            const metadata = {
                membershipStatus : membershipStatus ? ( membershipStatus === CANCELLED ? membershipStatus : ACTIVE )  : INVALID
              }
              if(metadata && metadata?.membershipStatus !== 'Active') {
              const { notifications } = this.state;
                this.setState({
                    notifications: [...notifications, this.buildNotification(notificationType, metadata, this.state.isAdmin)],
                })
            }
            
        }
    }

    render() {
        const { notifications } = this.state;
        const contextValue = {
            notifications,
            getNotifications: () => this.getNotifications(),
            addNotification: (notificationType, metadata) => this.addNotification(notificationType, metadata),
            removeNotification: (notificationType) => this.removeNotification(notificationType),
            clearNotifications: () => this.clearNotifications()
        };

        return(
            <AdminNotificationContext.Provider value={contextValue}>
                {this.props.children}
            </AdminNotificationContext.Provider>
        )
    }
}

export default AdminNotificationProvider;