import React from 'react';
import { Api, ContentfulService } from '../configuration/ApplicationContext';
import ProductOffering from './products/ProductOffering';
import Content from './layout/Content';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from './layout/ResponsiveSignupFlowLayout';
import ContentfulIds from '../configuration/ContentfulIds';
import Summary from './summary/Summary';
import Spinner from './elements/Spinner';
import Card from './elements/Card';
import DataContext from './contexts/DataContext';
import Notification from './notifications/Notification';
import { createSummaryItems } from './summary/SummaryItems';
import BaseProductOffering from './BaseProductOffering';

export default class UpgradeCoveragePage extends BaseProductOffering {
    static contextType = DataContext;
    state = {
        products: [],
        selectedProduct: null,
        paymentMode: '',
        productPageURL: '/product/upgrade',
    };
    componentDidMount() {
        this.loadPlansAsync();
    }

    async loadPlansAsync() {
        const response = await Api.get('/api/products/zuora-rate-plans');
        if (!response) {
            return;
        }
        response.products = response.products.find(product => product.name === 'COMPLETE');
        // todo: remove this if we need to make api
        response.products.contentfulProductOfferingId = ContentfulIds.MyAccountIds.ProductOffering.CompleteCoverage;
        this.mapProductsToArray(response.products);
        this.loadContent(response.products);
        this.onLoadSelectProduct(this.props.match.params.id);
    }

    async loadContent(product) {
        const contentObject = {};
        const title = await ContentfulService.getEntry(ContentfulIds.MyAccountIds.ProductOffering.Title);
        const content = await ContentfulService.getEntry(product.contentfulProductOfferingId);
        contentObject[product.name] = content.fields;
        this.setState({ content: { title: title.fields, main: contentObject } });
    }

    onSubmit = () => {
        this.props.history.push({
            pathname: '/checkout'
        });
    }

    onDeclineUpgrade = () =>{
        this.props.notificationContext.clearNotifications();
        this.props.history.push('/my-account');
    }

    render() {
        const { products, paymentMode, selectedProduct, content } = this.state || {};
        const filteredNotification = this.props.notificationContext.notifications.filter(notification => notification.notificationType === 'PAID_OUTSTANDING_BALANCE_UPGRADE');
        if (!selectedProduct || !content) {
            return <Spinner />;
        }
        return (
            <Content>
                {filteredNotification && filteredNotification[0] && <Notification {...filteredNotification[0]} {...this.props} />}
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <ProductOffering products={products} selectedProduct={selectedProduct} hideSelectProduct={true}
                            content={content} onSelectProduct={this.onSelectProduct}
                            paymentMode={paymentMode} onSelectPaymentMode={this.onSelectPaymentMode}
                            onSubmit={this.onSubmit} onDecline={this.onDeclineUpgrade } declineText={'Decline Upgrade'}></ProductOffering>
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        <Card>
                            <Summary product={selectedProduct} title={`If you upgrade to ${selectedProduct.name}`} items={createSummaryItems(selectedProduct, this.context)}></Summary>
                        </Card>
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}
