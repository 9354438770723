import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';
import ContentfulIds from '../../configuration/ContentfulIds';
import StringPlaceholderService from '../../services/StringPlaceholderService';
import DataContext from '../contexts/DataContext';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import RichText from '../elements/RichText';
import Spinner from '../elements/Spinner';
import Title from '../elements/Title';
import Content from '../layout/Content';
import ResponsiveSignupFlowLayout, { SignUpFlowExtraContent, SignUpFlowFormContent } from '../layout/ResponsiveSignupFlowLayout';
import withPageMetaTags from '../layout/withPageMetaTags';
import withPageTitle from '../layout/withPageTitle';
import BaseBundleSignup from './BaseBundleSignup';
import EmailForm from './EmailForm';

const style = {
    title : {lineHeight: 'unset'},
    dyanmicParagraph: {marginBottom: '20px'}
};

const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText id="rich-text" style={style.dyanmicParagraph} {...props} text={children} />;
class CancelledIdcAccountExist extends BaseBundleSignup {
    static contextType = DataContext;
    componentDidMount() {
        this.loadContent(ContentfulIds.BundleFlow.CancelledIdcAccountExist);
        this.setRichTextOptions();
    }

    setRichTextOptions = () => {
        const options = {
            renderText: (text) => {
                const query = new URLSearchParams(this.props.location.search);
                const email = query.get('email');
                const properties = {
                    email
                };
                return StringPlaceholderService.replace(text, properties)
            },
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle >{children}</DynamicParagraphStyle>,
            },
        };
        this.setState({ options });
    }

    render() {
        const { content, isSubmitDisabled, apiErrors, options } = this.state || {};
        if (!content) {
            return <Spinner />;
        }
        return (
            <Content>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title 
                            style={style.title}>{content.title}
                        </Title>
                        <RichText
                            text={content.description} options={options} 
                        />
                        <EmailForm
                            content={content} 
                            isSubmitDisabled={isSubmitDisabled} 
                            apiErrors={apiErrors} 
                            onSubmit={this.onSubmit} 
                        />
                        <RichText
                            text={content.subDescription} 
                            options={options} 
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageMetaTags(withPageTitle(CancelledIdcAccountExist, "Cancelled Account Exist"), { noindex: true });