import styled from 'styled-components'
import {colors} from '../../styles/common'
import { desktop } from '../../styles/common';

const TransparentCard = styled.div`
    box-sizing: border-box;
    background: ${colors.pureWhite};
    ${desktop`
        border: 1px solid ${colors.borderGrey};
    `}
`;

export default TransparentCard;
