import React from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import { colors, desktop, desktopOnly, mobile } from '../../styles/common';

const Step = styled.div`
    display: inline-block;
    font-weight: 500;
    margin-right: 1.5rem;
    ${mobile`
        margin-right: 0.5rem;
        pointer-events: none;
    `}
    color: ${colors.mediumGrey};
    ${props => props.active && css`
        color: ${colors.blue};
    `}
    ${props => props.complete === true && props.shouldStepsClickable === true && css`
        cursor: pointer;
        &:hover {
            color: ${colors.darkBlue};
            .step-icon {
                ${desktop`
                    &:before {
                        content: \"${props.step}\"
                    }
                    border-color: ${colors.darkBlue};
                    background: ${colors.pureWhite};
                    color: ${colors.darkBlue};
                `}
            }
        }
    `}
`;

const StepIcon = styled.span`
    border: 2px solid ${colors.mediumGrey};
    background: ${colors.mediumGrey};
    color: ${colors.pureWhite};
    width: 25px;
    line-height: 25px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    margin: 0 5px 0 0;
    ${props => props.steptext && css`
        &:before {
            content: \"${props.steptext}\"
        }
    `}
    ${props => props.active && css`
        border-color: ${colors.darkBlue};
        background: ${colors.pureWhite};
        color: ${colors.darkBlue};
        ${mobile`
            border-color: ${colors.darkBlue};
            background: ${colors.pureWhite};
            color: ${colors.darkBlue};
        `}
    `}
    ${props => props.complete && css`
        ${mobile`
            border-color: ${colors.darkBlue};
            background: ${colors.darkBlue};
            color: ${colors.pureWhite};
        `}
        ${props => props.desktopHighlightColor && css`
            ${desktop`
                border-color: ${colors.darkBlue};
                background: ${colors.darkBlue};
                color: ${colors.pureWhite};
            `} 
        `}
    `}
`;

const StepText = styled.span`
    ${desktopOnly}
    margin: 0 14px 0 0;
`;

const SignupFlowStep = ({ currentStep, step, name, desktopHighlightColor, ...props }) => {
    const history = useHistory();
    const complete = step < currentStep;
    const active = step === currentStep;

    const redirectToUrl = (url) => {
        if (complete) {
            history.push(url);
        }
    }
    return (
        <Step {...props}
            active={active}
            complete={complete}
            step={step}
            onClick={() => redirectToUrl(props.linkUrl)}>
            <StepIcon className="step-icon"
                complete={complete}
                active={active}
                {...props}
                desktopHighlightColor={desktopHighlightColor}
                steptext={complete ? '✓' : step}
            />
            <StepText>
                {name}
            </StepText>
        </Step>
    );
};

export default SignupFlowStep;
