import React from 'react'
import Content from '../layout/Content';
import ResponsiveSignupFlowLayout, {
    SignUpFlowExtraContent,
    SignUpFlowFormContent
} from '../layout/ResponsiveSignupFlowLayout';
import Title from '../elements/Title';
import AdminContext from '../contexts/AdminContext';
import BaseAdminUpgradeTierCheckout from './BaseAdminUpgradeTierCheckout';
import Breadcrumbs from '../common/Breadcrumbs';
import Spinner from '../elements/Spinner';
import AdminCheckoutForm from './forms/AdminCheckoutForm';
import ContentfulIds from '../../configuration/ContentfulIds';
import AdminSummary from './summary/AdminSummary';
import Card from '../elements/Card';
import { AdminApi } from '../../configuration/ApplicationContext';
import { AdminNotificationTypes } from '../notifications/AdminNotifications';

declare var Z: any; //Zuora
export class AdminPayOutstandingBalancePage extends BaseAdminUpgradeTierCheckout {
    static contextType = AdminContext;

    constructor(props) {
        super(props);
        this.state = {
            zuoraConfiguration: null,
            isNewPaymentMethod: false,
            checkoutPageURL: '/admin/pay-outstanding-balance'
        };
    }

    async componentDidMount() {
        this.checkUserStateAndRedirect();
        this.loadZuoraForm();
        this.setBreadCrumb();
        this.loadContent(ContentfulIds.Admin.MemberAccountIds.PayOutstandingBalancePage);
        this.loadPersonalInformation();
        this.prepopulateFields();
        this.setSubscription();
    }
    checkUserStateAndRedirect() {
        const { outstandingBalance } = this.context.memberDetails?.subscription || {};
        if (outstandingBalance <= 0) {
            this.props.history.push('/admin/member-account');
        }
    }

    setSubscription() {
        const {subscription}=this.context.memberDetails || {};
        this.setState({subscription,isNewPaymentMethod: subscription?.defaultPaymentMethod ? false :true})
    }

    setBreadCrumb = () => {
        const { personalInformation } = this.context.memberDetails || {};
        const breadCrumbRoutes = [
            {
                to: '/admin/member-account', label: personalInformation.displayName
            },
            {
                to: `${this.state.checkoutPageURL}`, label: 'Pay Outstanding Balance'
            }
        ]
        this.setState({ breadCrumbRoutes });
    }

    onSubmit = (data) => {
        const { isNewPaymentMethod } = this.state;
        const { ...billingAddress } = this.state.personalInfo?.billingAddress || {};
        if (isNewPaymentMethod) {
            if (!data.values.billingAddress || Object.keys(data.errors).length > 0) {
                this.setState({ checkoutError: data.errors.billingAddress });
                return;
            }
            window.orderSuccess = async (referenceId) => {
                this.setState({
                    isSubmitDisabled: true
                });
                const paymentMethodId = referenceId;
                this.makePayment(paymentMethodId, billingAddress);
            };

            window.orderFailure = (failureMessage) => {
                this.setState({ checkoutError: failureMessage, zuoraConfiguration: null, isSubmitDisabled: false }, () => { this.loadZuoraForm(); });
            };
            Z.submit();
        } else {
            this.setState({
                isSubmitDisabled: true
            });
            const defaultPaymentMethodId = this.state.subscription.defaultPaymentMethod.id;
            this.makePayment(defaultPaymentMethodId, billingAddress);
        }
    };

    makePayment = async (paymentMethodId, billingAddress) => {
        const { addNotification } = this.props.notificationContext;
        const { userId } = this.context.memberDetails;
        let response = await AdminApi.put(`/api/users/${userId}/payment-method`, { paymentMethodId, billingAddress });
        if(!response) {
            this.setState({
                isSubmitDisabled: false
            }); 
            return;
        }
        if (response.validationErrors) {
            const apiErrors = response.validationErrors.map(apiError => {
                return apiError.message
            });
            this.setState({ checkoutError: apiErrors, zuoraConfiguration: null, isSubmitDisabled: false }, () => { this.loadZuoraForm(); });
        } else {
            await addNotification(AdminNotificationTypes.PAID_OUTSTANDING_BALANCE);
            this.props.history.push('/admin/member-account');
        }
    }

    setNewPaymentMethod = (value) => {
        if (value) {
            this.loadZuoraForm();
        }
        this.setState({ isNewPaymentMethod: value });
    }

    render() {
        const { zuoraConfiguration, content, checkoutError, personalInfo, subscription, isNewPaymentMethod, billingAddressSameAsHomeAddress, breadCrumbRoutes, prepopulateFields, isSubmitDisabled } = this.state;
        const { product } = this.context;
        if (!zuoraConfiguration || !content || !product || !subscription) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{ marginTop: '0rem' }}>
                <Breadcrumbs isCustomerFacing={false} breadCrumbRoutes={breadCrumbRoutes}></Breadcrumbs>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title desktopStyles={{ marginTop: '0' }} mobileStyles={{ marginTop: '3rem' }}>Pay Outstanding Balance</Title>
                        <AdminCheckoutForm zuoraConfiguration={zuoraConfiguration} content={content} onSubmit={this.onSubmit}
                            checkoutError={checkoutError} personalInfo={personalInfo}
                            onSelectAddress={this.onSelectAddress} selectedProduct={product}
                            billingAddressSameAsHomeAddress={billingAddressSameAsHomeAddress} showPaymentMethod={true}
                            isSubmitDisabled={isSubmitDisabled}
                            prepopulateFields={prepopulateFields}
                            subscription={subscription} isNewPaymentMethod={isNewPaymentMethod} setNewPaymentMethod={this.setNewPaymentMethod} />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        <Card>
                            <AdminSummary product={product} title={'This Payment'} subscription={subscription} isOutstandingBalance={true} />
                        </Card>
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        )
    }
}

export default AdminPayOutstandingBalancePage
