import React, { Component } from "react";
import styled from "styled-components";
import { mobile } from "../styles/common";
import Title from "./elements/Title";
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from './layout/ResponsiveSignupFlowLayout';
import DynamicParagraphText from './elements/DynamicParagraphText';
import withPageMetaTags from "./layout/withPageMetaTags";
import withPageTitle from "./layout/withPageTitle";

const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText id="rich-text" {...props} text={children} />;

const Content = styled.div`
    padding: 0px 60px;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 60px;
    box-sizing: border-box;
    ${mobile`
        padding: 0 30px;
        margin-top: 20px;
    `}
`;

const titleStyle = {
  marginTop: '3.5rem',
  paddingTop: '3rem',
}

class SomethingWentWrong extends Component {
  render() {
    return (
      <Content>
        <ResponsiveSignupFlowLayout>
          <SignUpFlowFormContent>
          <Title mobileOnly='true' style={titleStyle} >Something went wrong</Title>
          <Title desktopOnly='true'>Something went wrong</Title>
          <DynamicParagraphStyle >{'Please return to the previous screen and try again.'}</DynamicParagraphStyle>
          </SignUpFlowFormContent>
          <SignUpFlowExtraContent />
        </ResponsiveSignupFlowLayout>
      </Content>
    );
  } 
}

export default withPageMetaTags(withPageTitle(SomethingWentWrong, "Something Went Wrong"), { noindex: true });