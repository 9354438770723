import styled, { css } from 'styled-components'
import { mobileOnly, desktopOnly, commonPropStyles } from '../../styles/common';
import { mobile, desktop } from '../../styles/common';

const InfoText = styled.div`
   font-style: normal;
   font-weight: normal;  
   line-height: 28px;
   
   ${props => props.mobileOnly && css`
      ${mobileOnly}
   `}

   ${props => props.desktopOnly && css`
      ${desktopOnly}
   `}
   ${mobile`
      font-size: 14px;
   `}

   ${desktop`
      font-size: 17px
   `}
   ${commonPropStyles.mobile}
   ${commonPropStyles.desktop}
`;

export default InfoText;
