import React, {Component} from 'react';
import {ContentfulService} from '../configuration/ApplicationContext';
import ContentfulIds from '../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, {SignUpFlowFormContent} from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Title from './elements/Title';
import RichText from './elements/RichText';
import Spinner from './elements/Spinner';
import Link from './elements/Link';
import DataContext from './contexts/DataContext';
import DynamicParagraphText from './elements/DynamicParagraphText';
import InfoText from './elements/InfoText';
import LineItem from './elements/LineItem';
import moment from 'moment';
import { capitalizeFirstLetter } from './common/Helpers';

const mobileTitleStyle = {
    lineHeight: '25px',
    fontSize: '14px',
    textAlign: 'center',
}

const desktopTitleStyle = {
    lineHeight: '48px',
}

const lineItemMobileStyle = {
    fontWeight: '500', 
    margin: '0 0.75rem'
}

const lineItemDesktopStyle = {
    fontWeight: '500', 
    margin: 'unset'
}

export default class AlreadyActivatedSubscriptionPage extends Component {
    static contextType = DataContext;
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.loadContent()
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.AlreadyActivatedSubscriptionPage);
        this.setState({content: response.fields});
    }

    render() {
        const { content} = this.state;
        const { subscription } = this.context.userDetails;
        if (!content || !subscription) {
            return <Spinner />;
        }
        const endDate = moment(subscription.endDate).format('MM/DD/YYYY');
        
        return (
            <Content>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title mobileStyles={mobileTitleStyle} desktopStyles={desktopTitleStyle} style={{marginBottom: '1.5rem'}}>{content.title}</Title>
                        <InfoText mobileOnly='true'>
                            <LineItem itemKey='Covered through' itemValue={endDate} style={lineItemMobileStyle}></LineItem>
                            <LineItem itemKey='Protection Level' itemValue={capitalizeFirstLetter(subscription.productType)} style={lineItemMobileStyle}></LineItem>
                        </InfoText>
                        <InfoText desktopOnly='true'>
                            <DynamicParagraphText style={lineItemDesktopStyle} text={`Covered through: ${endDate}`}/>
                            <DynamicParagraphText style={lineItemDesktopStyle} text={`Protection Level: ${capitalizeFirstLetter(subscription.productType)}`}/>
                        </InfoText>                         
                        <DynamicParagraphText desktopFontSize='17px' mobileFontSize='17px' text={<RichText text={content.description} />} />
                        <Link style={{marginTop: '2rem', fontWeight: 'bold'}} text={content.linkText} textalign="center" to="/my-account" />
                    </SignUpFlowFormContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}