import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';
import ContentfulIds from '../../configuration/ContentfulIds';
import DataContext from '../contexts/DataContext';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import RichText from '../elements/RichText';
import Spinner from '../elements/Spinner';
import Title from '../elements/Title';
import Content from '../layout/Content';
import ResponsiveSignupFlowLayout, { SignUpFlowExtraContent, SignUpFlowFormContent } from '../layout/ResponsiveSignupFlowLayout';
import withPageMetaTags from '../layout/withPageMetaTags';
import withPageTitle from '../layout/withPageTitle';
import BaseBundleSignup from './BaseBundleSignup';
import EmailForm from './EmailForm';

const titleStyle = {
    lineHeight: 'unset'
};
const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText id="rich-text" {...props} text={children} />;
const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle >{children}</DynamicParagraphStyle>,
    },
};

class AccountAlreadyExist extends BaseBundleSignup {
    static contextType = DataContext;
    componentDidMount() {
        this.loadContent(ContentfulIds.BundleFlow.AccountAlreadyExist);
    }

    render() {
        const { content, isSubmitDisabled, apiErrors } = this.state || {};
        if (!content) {
            return <Spinner />;
        }
        return (
            <Content>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title style={titleStyle}>{content.title}</Title>
                        <RichText text={content.description} options={options} />
                        <EmailForm content={content} isSubmitDisabled={isSubmitDisabled} apiErrors={apiErrors} onSubmit={this.onSubmit} />
                        <RichText text={content.subDescription} options={options} />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageMetaTags(withPageTitle(AccountAlreadyExist, "Account Already Exist"), { noindex: true });