import { Component } from 'react';
import { NotificationTypes } from './notifications/Notifications';
import {ContentfulService} from '../configuration/ApplicationContext';
import ContentfulIds from '../configuration/ContentfulIds';

export class BasePersonalDetailPage extends Component {
    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.EditPersonalInformationForm);
        this.setState({content: response.fields});
    }

    onSelectAddress = (homeAddress) => {
        this.setState({
            homeAddress
        });
    }

    onSelectBillingAddress = (billingAddress) => {
        this.setState({
            billingAddress
        });
    }

    onBillingAddressSameAsHomeAddress = (billingAddressSameAsHomeAddress) => {
        this.setState({billingAddressSameAsHomeAddress});
    }

    populateAddress(userInformation) {
        const {homeAddress, billingAddress, billingAddressSameAsHomeAddress} =  userInformation || {};
        this.setState({
            homeAddress,
            billingAddress,
            billingAddressSameAsHomeAddress
        });
    }

    resetMessage() {
        this.context.updateState({ message : ''});
        this.props.notificationContext.removeNotification(NotificationTypes.PERSONAL_INFORMATION_UPDATE);
    }
}

export default BasePersonalDetailPage;
