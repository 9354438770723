import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useCustomForm from '../../hooks/useCustomForm';
import SubmitButton from '../elements/SubmitButton';
import ZuoraCheckoutForm from '../elements/ZuoraCheckoutForm';
import RichText from '../elements/RichText';
import { LocationSearchInput } from '../elements/LocationSearchInput';
import Section from '../elements/Section';
import { colors, mobile } from '../../styles/common'
import StringPlaceholderService from '../../services/StringPlaceholderService';
import CheckboxInput from '../elements/CheckboxInput';
import InfoText from '../elements/InfoText';
import Spinner from '../elements/Spinner';
import Summary from '../summary/Summary';
import validate from './CheckoutForm/checkoutValidation';
import ZuoraFormError from '../elements/ZuoraFormError';
import Link from '../elements/Link';
import Span from '../elements/Span';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import UpdatePaymentSummary from '../update-payment-summary/UpdatePaymentSummary';
import RadioButton from '../elements/RadioButton';
import { MARKS, BLOCKS } from "@contentful/rich-text-types";
import { mapDisclaimerToProperty, removeLeadingZerosForSingleDigitDate } from "../common/Helpers";
import DataContext from '../contexts/DataContext';
import PromoCode from '../common/PromoCode';
import NotificationContext from '../contexts/NotificationContext';
import UtilsService from './../../services/UtilsService';
import { createSummaryItems } from '../summary/SummaryItems';
import { Api } from '../../configuration/ApplicationContext';
import { ANNUALLY, CANCELLED, FOREVER, FREE_UPPER_CASE } from '../common/Constants';
import Title from '../elements/Title';
import styled from 'styled-components';

const SummaryContent = styled.div`
    ${mobile`	
        margin-top: 20px;	
    `}
`;

const styles = {
    homeAddress: { fontSize: '17px', color: colors.textGrey, padding: '0px 36px' },
    header: { fontSize: '24px', marginBottom: '0px' },
    dynamicParagraph: { lineHeight: '24px', marginBottom: '0px' }
}

const CheckoutForm = (props) => {
    const context = useContext(DataContext);
    const notificationsContext = useContext(NotificationContext);
    const { personalInfo, initialValues, onSubmit, onSelectAddress, selectedProduct, zuoraConfiguration, content, onDeclineUpgrade, checkoutError, prepopulateFields, isUpgradeCheckoutPage, upgradePageContent, isPaymentOutstandingBalancePage, subscription, isNewPaymentMethod = true, hidePromoCode = false, setNewPaymentMethod, isUpgradeTierCheckoutPage, billingAddressSameAsHomeAddress, isSubmitDisabled, isUpdatePaymentMethodPage, onApplyPromoCode, promoCodeOptions = {}, freeTrial = false } = props;
    const [billingAddressSelected, setBillingAddressSelected] = useState(!billingAddressSameAsHomeAddress);
    if (billingAddressSameAsHomeAddress === false) {
        const { billingAddress } = personalInfo || {};
        initialValues.billingAddress = `${billingAddress?.lineOne}, ${billingAddress?.city}, ${billingAddress?.state}, ${billingAddress?.countryCode}`;
    }
    const {
        values,
        errors,
        touched,
        submitted,
        handleChange,
        handleBlur,
        handleSubmit
    } = useCustomForm({ initialValues, validate, personalInfo, onSubmit, onSelectAddress, selectedProduct });

    const inputHandlers = {
        onChange: handleChange,
        onBlur: handleBlur,
        extratopspace: '0.5rem',
        extraTopLabelSpace: '0.75rem'
    };
    const form = useRef();
    const [checked, setChecked] = useState(billingAddressSameAsHomeAddress);
    const { id, basePrice, actualPrice, paymentMode, coverageThrough, totalRefundAmount, discount } = selectedProduct || {};
    const { creditCardNumber, creditCardType } = subscription?.defaultPaymentMethod || {};
    const lastFourCardNumberDigit = creditCardNumber?.substr(creditCardNumber.length - 4);
    const productsWithoutPromoCodes = JSON.parse(localStorage.getItem('productsWithoutPromoCodes'));
    const findProduct = (products) => {
        const mappedProducts = UtilsService.mapProductsToArray(products);
        const foundProduct = mappedProducts.find(product => product.id === id);
        const selectedProduct = foundProduct ? foundProduct : {};
        return selectedProduct;
    }
    const selectProductWithoutPromoCode = productsWithoutPromoCodes ? findProduct(productsWithoutPromoCodes) : [];
    const properties = {
        chargeNowWithRefundAmount: totalRefundAmount ? (actualPrice?.total - totalRefundAmount).toFixed(2) : actualPrice?.total.toFixed(2),
        chargeNow: subscription?.outstandingBalance?.toFixed(2),
        chargeToday: actualPrice?.total.toFixed(2),
        basePrice: basePrice?.price.toFixed(2),
        basePriceWithTax: basePrice?.total?.toFixed(2),
        discountedBasePrice: actualPrice?.price.toFixed(2),
        productType: selectedProduct?.name,
        paymentMode: paymentMode === ANNUALLY ? 'year' : 'month',
        subscriptionType: paymentMode === ANNUALLY ? 'annual' : 'monthly',
        everyTimeOn: removeLeadingZerosForSingleDigitDate(moment(coverageThrough)?.format('MM/DD/YYYY')),
        promoCodeExpiry: context?.promoCodeData?.effectiveEndDate === FOREVER ? context?.promoCodeData?.effectiveEndDate : removeLeadingZerosForSingleDigitDate(moment(context?.promoCodeData?.effectiveEndDate)?.format('MM/DD/YYYY')),
        chargeAfterPromoCodeExpired: selectProductWithoutPromoCode?.actualPrice?.total.toFixed(2),
        basePriceAfterPromoCodeExpired: selectProductWithoutPromoCode?.basePrice?.price.toFixed(2),
        basePriceWithTaxAfterPromoCodeExpired: selectProductWithoutPromoCode?.basePrice?.total.toFixed(2),
        discountedbasePriceAfterPromoCodeExpired: selectProductWithoutPromoCode?.actualPrice?.price.toFixed(2),
        chargeAfterPromoCodeExpiredWithAAADiscount: (selectProductWithoutPromoCode?.basePrice?.total + selectProductWithoutPromoCode?.discount?.total).toFixed(2),
        basePriceWithoutTax: (basePrice?.price - actualPrice?.tax).toFixed(2),
        promoRateWithoutAAADiscount: (actualPrice?.total - discount?.price).toFixed(2),
        priceIncludesTax: basePrice?.tax > 0 ? ' (Price includes tax)' : ''
    };

    const disclaimers = [content.disclosures, content.promoCodeDisclosures, content.notForeverDisclosures, content.trialPricingDisclaimer, content.freeTrialDisclosure];
    disclaimers.forEach(disclaimer => mapDisclaimerToProperty(disclaimer, properties));

    content.nonMemberFlowDisclosuresSection && content.nonMemberFlowDisclosuresSection.forEach(section => {
        mapDisclaimerToProperty(section.fields.description, properties);
    });

    const zuoraCheckoutForm = zuoraConfiguration ? <ZuoraCheckoutForm prepopulateFields={prepopulateFields || {}} configuration={zuoraConfiguration} /> : <Spinner />;

    const filteredErrors = submitted ? errors : Object.keys(errors)
        .filter(key => touched[key])
        .reduce((obj, key) => {
            obj[key] = errors[key];
            return obj;
        }, {});

    const onBillingAddressSelect = (address) => {
        delete errors.billingAddress;
        values.billingAddress = address;
        setBillingAddressSelected(true);
        onSelectAddress(address);
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (checked) {
            delete errors.billingAddress
            values.billingAddress = personalInfo?.homeAddress;
        } else {
            if (!(values.billingAddress && billingAddressSelected)) {
                if (form.current) {
                    form.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                }
                errors.billingAddress = 'Please enter Billing Address.';
            }
        }
        handleSubmit();
    }

    const setBilllingAddress = () => {
        if (checked) {
            setBillingAddressSelected(false);
            delete errors.billingAddress;
            values.billingAddress = '';
        } else if (checked && personalInfo?.billingAddress) {
            values.billingAddress = personalInfo?.billingAddress;
            setBillingAddressSelected(true);
            onSelectAddress(personalInfo?.billingAddress);
        } else {
            values.billingAddress = personalInfo?.homeAddress;
            setBillingAddressSelected(true);
            onSelectAddress(personalInfo?.homeAddress);
        }
    }

    const loadDisclosureContent = () => {
        const isNonAaaMember = !context?.isLoggedIn
            || context?.userDetails?.invalidMembershipNumber === true
            || context?.userDetails?.membership?.membershipStatus === CANCELLED
            || !context?.userDetails?.membership;
        if (!context.promoCodeData) {
            if (freeTrial && content.freeTrialDisclosure) {
                return content.freeTrialDisclosure;
            }
            return isNonAaaMember && content.nonMemberFlowDisclosuresSection ? content.nonMemberFlowDisclosuresSection[2].fields.description : content.disclosures;
        } else {
            const nonMemberDiscountApplied = context.promoCodeData.promoCodeCustomDescription.includes('Gig');
            if (content.nonMemberFlowDisclosuresSection && nonMemberDiscountApplied) {
                return isNonAaaMember ? content.nonMemberFlowDisclosuresSection[1].fields.description : content.nonMemberFlowDisclosuresSection[0].fields.description;
            } else {
                const showTrialPromoCodeDisclaimer = content.trialPricingDisclaimer && context?.promoCodeData?.type === 'percent' && context?.promoCodeData?.amount === '100';
                let disclosure = context.promoCodeData.effectiveEndDate === FOREVER ? content.promoCodeDisclosures : showTrialPromoCodeDisclaimer ? content.trialPricingDisclaimer : content.notForeverDisclosures;
                if (isNonAaaMember && content.nonMemberFlowDisclosuresSection) {
                    disclosure = context.promoCodeData.effectiveEndDate === FOREVER
                        ? content.nonMemberFlowDisclosuresSection[3]?.fields.description
                        : (showTrialPromoCodeDisclaimer
                            ? content.nonMemberFlowDisclosuresSection[4].fields.description
                            : content.nonMemberFlowDisclosuresSection[1].fields.description);
                }
                return disclosure;
            }
        }
    }

    const SpanStyle = ({ children, ...props }) => <Span style={{ color: `${colors.darkBlue}`, fontWeight: 'bold' }} text={children} />;
    const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText id="rich-text" {...props} text={children} />;
    const options = {
        renderMark: {
            [MARKS.CODE]: text => <SpanStyle>{text}</SpanStyle>
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle style={styles.dynamicParagraph}>{children}</DynamicParagraphStyle>,
        },
    };
    const disclaimerText = isUpdatePaymentMethodPage && context?.userDetails?.upcomingSubscriptionProductType === FREE_UPPER_CASE ? '' : loadDisclosureContent();
    const isNonAaaMember = !context?.isLoggedIn
            || context?.userDetails?.invalidMembershipNumber === true
            || context?.userDetails?.membership?.membershipStatus === CANCELLED
            || !context?.userDetails?.membership;;
    return (
        <form
            onSubmit={handleFormSubmit}
            noValidate ref={form}>
            <ZuoraFormError
                error={checkoutError}
            />
            {
                isUpgradeTierCheckoutPage &&
                <>
                    <RadioButton
                        name='checkout-mode'
                        checked={!isNewPaymentMethod}
                        onChange={() => { setNewPaymentMethod(false); }}
                        label={`Pay using ${creditCardType} **** **** **** ${lastFourCardNumberDigit}`}
                    />
                    <RadioButton
                        name='checkout-mode'
                        checked={isNewPaymentMethod}
                        onChange={() => { setNewPaymentMethod(true); }}
                        label='Pay using a different Payment Method'
                    />
                </>
            }

            {
                isNewPaymentMethod &&
                <>
                    {zuoraCheckoutForm}
                    <InfoText>
                        <CheckboxInput
                            name="isHomeAdress"
                            checked={checked}
                            onChange={() => { setChecked(!checked); setBilllingAddress(); }}
                        />
                        Billing address same as home address
                    </InfoText>
                    {
                        !checked &&
                        <LocationSearchInput
                            label="Billing Address"
                            name="billingAddress"
                            value={values.billingAddress}
                            onSelectAddress={onBillingAddressSelect}
                            error={filteredErrors.billingAddress}
                            {...inputHandlers}
                            onChange={() => setBillingAddressSelected(false)}
                        />
                    }
                    {
                        checked &&
                        <>
                            <Section
                                style={styles.homeAddress}
                                text={personalInfo?.homeAddress?.lineOne}
                            />
                            <Section
                                style={styles.homeAddress}
                                text={(personalInfo?.homeAddress?.lineTwo || '') + ' ' + (personalInfo?.billingAddress?.city || '') + ' ' + (personalInfo?.homeAddress?.state || '') + ' ' + personalInfo?.homeAddress.zipCode}
                            />
                        </>
                    }</>
            }
            {
                onApplyPromoCode && !hidePromoCode &&
                <PromoCode
                    ratePlanId={selectedProduct.id}
                    isSubmitDisabled={isSubmitDisabled}
                    onApplyPromoCode={onApplyPromoCode}
                    dataContext={context}
                    options={promoCodeOptions}
                    notificationContext={notificationsContext}
                    Api={Api}
                />
            }
            <InfoText
                mobileOnly='true'>
                {
                    !isUpgradeTierCheckoutPage && !isPaymentOutstandingBalancePage && !isUpdatePaymentMethodPage &&
                    <Summary
                        product={selectedProduct}
                        title=''
                        items={createSummaryItems(selectedProduct, context)}
                        flags={{ showBottomSection: true }}
                    />
                }
                {
                    isPaymentOutstandingBalancePage &&
                    <UpdatePaymentSummary
                        page={`payOutstandingBalance`}
                        data={subscription}
                        title={`Your Outstanding Balance`}
                    />
                }
                {
                    isUpgradeTierCheckoutPage &&
                    <SummaryContent>
                        {
                            isNonAaaMember &&
                            <Title
                                mobileOnly='true'
                                mobileStyles={styles.header}>
                                If you upgrade
                            </Title>
                        }
                        <Summary
                            isProtectUpgradePage={true}
                            product={selectedProduct} title=''
                            items={createSummaryItems(selectedProduct, context, { showRefundAmount: true })}
                            flags={{ showBottomSection: true }}>
                        </Summary>
                    </SummaryContent>
                }
                {
                    isUpdatePaymentMethodPage && selectedProduct.name !== 'Free' &&
                    <UpdatePaymentSummary
                        page={`updatePaymentMethod`}
                        data={selectedProduct}
                        title={`Your Next Payment`}
                    />
                }
            </InfoText>
            {
                selectedProduct.name !== 'Free' &&
                <RichText
                    text={disclaimerText ? disclaimerText : ''}
                    options={options}
                />
            }
            {
                !isUpgradeCheckoutPage &&
                <SubmitButton
                    text={freeTrial ? `${content.submitButtonText} Details` : content.submitButtonText}
                    disabled={isSubmitDisabled}
                />
            }
            {
                isUpgradeCheckoutPage &&
                <>
                    <SubmitButton
                        text={StringPlaceholderService.replace(upgradePageContent.linkText, { productType: selectedProduct?.name })}
                        disabled={isSubmitDisabled}
                    />
                    <Link
                        style={{ fontSize: '14px' }}
                        textalign="center"
                        extraleftspace="3.5rem"
                        extratopspace="1rem"
                        extrabottomspace="1.5rem"
                        onClick={onDeclineUpgrade}
                        text="Cancel and Decline Upgrade"
                        to="/my-account"
                    />
                </>
            }
        </form>
    );
};

CheckoutForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onSelectAddress: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({}),
    selectedProduct: PropTypes.shape({}).isRequired
};

CheckoutForm.defaultProps = {
    initialValues: {}
};

export default CheckoutForm;
