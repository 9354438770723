import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import React from 'react';
import ContentfulIds from '../../configuration/ContentfulIds';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import RichText from '../elements/RichText';
import Spinner from '../elements/Spinner';
import SubmitButton from '../elements/SubmitButton';
import Title from '../elements/Title';
import Content from '../layout/Content';
import ResponsiveSignupFlowLayout, { SignUpFlowExtraContent, SignUpFlowFormContent } from '../layout/ResponsiveSignupFlowLayout';
import withPageTitle from '../layout/withPageTitle';
import Section from '../elements/Section';
import BaseBundleSignup from './BaseBundleSignup';
import Link from '../elements/Link';
import withPageMetaTags from '../layout/withPageMetaTags';

const titleStyle = {
    lineHeight: 'unset'
};
const linkStyle = { display: 'inline-block', fontSize: '14px' };
const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText id="rich-text" {...props} text={children} />;
const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle >{children}</DynamicParagraphStyle>
    },
};
class IdcAccountAlreadyExist extends BaseBundleSignup {

    componentDidMount() {
        this.loadContent(ContentfulIds.BundleFlow.IdcAccountAlreadyExist);
    }

    setSubDescriptionOptions = (email, productId) => {
        const subDescriptionOptions = {
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle >{children}</DynamicParagraphStyle>,
                [INLINES.HYPERLINK]: (node) => <Link style={linkStyle} to={`/${this.props.match.params.type}/checkout/${productId}/create-new-account?email=${email}`} text={node.content[0].value} />
            },
        };
        return subDescriptionOptions;
    }

    render() {
        const { content, email, productId } = this.state || {};
        if (!content) {
            return <Spinner />;
        }
        return (
            <Content>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title style={titleStyle}>{content.title}</Title>
                        <RichText text={content.description} options={options} />
                        <SubmitButton style={{ margin: '29px 0px 37px 0px' }} text={content.linkText} onClick={this.onLogin}></SubmitButton>
                        <RichText text={content.subDescription} options={this.setSubDescriptionOptions(email, productId)} />
                        <Section text={<RichText text={content.contactUs} options={options} />}></Section>
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageMetaTags(withPageTitle(IdcAccountAlreadyExist, "IDC Account Already Exist"), { noindex: true });