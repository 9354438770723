import React, { Component } from "react";
import LandingContent from "./layout/LandingContent";
import ResponsiveLandingPageLayout from "./layout/ResponsiveLandingPageLayout";
import NotYetAvailable from "./out-of-area/NotYetAvailable";
import ZipCodeService from "../services/ZipCodeService";
import Spinner from "./elements/Spinner";
import ProtectMyID from "./out-of-area/ProtectMyId";
import OutofAreaSignupSucess from "./out-of-area/OutOfAreaSignupSuccess";
import { ContentfulService } from "../configuration/ApplicationContext";
import ContentfulIds from "../configuration/ContentfulIds";
import withPageTitle from "./layout/withPageTitle";
import { getPromoCodeNameFromRedirectParams } from "./common/Helpers";

class OutofAreaSignupSuccessPage extends Component {

    componentDidMount() {
        this.setContent();
        this.setZipCode();
        this.loadData();
    }

    async setContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.OutOfPage.OutofAreaSignupSuccess);
        this.setState({ content: response.fields.sections });

    }

    setZipCode() {
        const zipCode = ZipCodeService.getZipCookie();
        this.setState({ zipCode: zipCode });
    }

    loadData = () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const email = params.get('email') ? params.get('email') : 'user@gmail.com'
        this.setState({ email });
    }

    render() {
        const { zipCode, content, email } = this.state || {};
        if (!zipCode || !content) {
            return <Spinner />;
        }
        return (
            <LandingContent
                promocodename={getPromoCodeNameFromRedirectParams()}>
                <ResponsiveLandingPageLayout>
                    <NotYetAvailable
                        zipCode={zipCode}
                        content={content[0].fields}
                        isOutOfAreaSignupPage={true}
                    />
                    <OutofAreaSignupSucess
                        email={email}
                        zipCode={zipCode}
                        content={content[1].fields}
                    />
                    <ProtectMyID
                        content={content[2].fields}
                    />
                </ResponsiveLandingPageLayout>
            </LandingContent>
        );
    }
}

export default withPageTitle(OutofAreaSignupSuccessPage, "Out of Area");