import React from 'react';
import { colors } from '../../styles/common';
import Currency from "../common/Currency";
import { getPromoCodeString } from "../summary/SummaryItems";

export function createBundleSummaryItems(product, context) {
    const { basePrice, discount, actualPrice } = product || {};
    const { promoCodeData } = context || {};
    return {
        topSection: [
            {
                key: 'Coverage Plan',
                value: product.name,
            },
            {
                key: 'AAA Membership Level',
                value: 'Classic',
            },
        ],
        middleSection: [
            {
                key: 'Regular Price',
                value: <Currency value={basePrice?.price} />,
            },
            {
                key: 'Taxes',
                value: <Currency value={actualPrice?.tax} />,
            },
            {
                key: 'Bundle Discount',
                value: <Currency value={discount?.price} />,
                style: { fontStyle: 'italic' }
            }
        ],
        promoCode: [
            {
                key: getPromoCodeString(promoCodeData),
                value: <Currency value={promoCodeData?.discountAmount} />,
                style: { fontStyle: 'italic' }
            },
        ],
        grandTotal: [
            {
                key: 'Total Due Today',
                value: <Currency value={actualPrice?.total} />,
                style: { color: colors.blue, fontWeight: 'bold', fontSize: '22px' },
            }
        ],
    }
}