import React, { Component } from 'react';
import StringPlaceholderService from '../../services/StringPlaceholderService';
import Card from '../elements/Card';
import ResponsiveSignupFlowLayout, { SignUpFlowExtraContent, SignUpFlowFormContent } from '../layout/ResponsiveSignupFlowLayout';
import Title from '../elements/Title';
import RichText from '../elements/RichText';
import Content from '../layout/Content';
import Spinner from '../elements/Spinner';
import ContentfulIds from '../../configuration/ContentfulIds';
import { buildUrl, capitalizeFirstLetter } from '../common/Helpers';
import { ContentfulService } from '../../configuration/ApplicationContext';
import AdminSummary from './summary/AdminSummary';
import AdminContext from '../contexts/AdminContext';
import Link from '../elements/Link';
import Breadcrumbs from '../common/Breadcrumbs';
import { FREE, FREE_UPPER_CASE } from '../common/Constants';
export default class AdminSubscriptionSuccessPage extends Component {
    static contextType = AdminContext;
    state = {
        reactivateUser: false,
        urlParams: {}
    }
    async componentDidMount() {
        await this.context.getMemberDetails();
        await this.props.notificationContext.getNotifications();
        this.checkRejoinFlow();
        this.loadContent();
        this.setBreadCrumb();
    }
    getContentFulID = (key) => {
        const contentFulMap = new Map()
            .set('rejoin', ContentfulIds.Admin.RejoinFlow.SubscriptionSuccessPage)
            .set('signup', ContentfulIds.Admin.SignUpFlow.SubscriptionSuccessPage);
        return contentFulMap.get(key);
    }
    checkRejoinFlow = () => {
        const params = new URLSearchParams(this.props.location.search).get('action');
        if (params === 'rejoin') {
            this.setState({
                reactivateUser: true,
                urlParams: {
                    action: params
                }
            })
        }
    }
    async loadContent() {
        const params = new URLSearchParams(this.props.location.search).get('action') || 'signup';
        const response = await ContentfulService.getEntry(this.getContentFulID(params));
        this.setState({ content: response.fields });
    }

    setBreadCrumb = () => {
        const { personalInformation } = this.context.memberDetails || {};
        const breadCrumbRoutes = [
            { to: '/admin/member-account', label: personalInformation.displayName },
            { to: buildUrl('/admin/subscription-complete', this.state.urlParams), label: `${this.state.reactivateUser ? 'Reactivation Successful' : 'Sign Up Successful'}` }
        ];
        this.setState({ breadCrumbRoutes });
    }


    render() {
        const { content, breadCrumbRoutes } = this.state || {};
        const { personalInformation, subscription } = this.context.memberDetails || {};
        const { product } = this.context || {};
        if (!subscription || !content) {
            return <Spinner />;
        }
        return (
            <Content
                desktopStyles={{ marginTop: '0rem' }}>
                <Breadcrumbs
                    breadCrumbRoutes={breadCrumbRoutes}
                    isCustomerFacing={false}
                />
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <>
                            <Title>
                                {content.title}
                            </Title>
                            {
                                subscription.productType !== FREE_UPPER_CASE &&
                                <RichText
                                    text={content.description}
                                />
                            }
                            {
                                subscription.productType === FREE_UPPER_CASE &&
                                <RichText
                                    text={content.subDescription}
                                />
                            }
                            <Link
                                to='/admin/member-account'
                                style={{ marginTop: '2rem' }}
                                text={`Back to ${personalInformation.displayName}'s Details`}
                            />
                            <Link
                                to={`/admin/search?login-id=${this.context.getLoginId()}`}
                                style={{ marginTop: '2rem' }}
                                text={`Back to Dashboard`}
                            />
                        </>
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        {
                            product.name !== FREE &&
                            <Card>
                                <AdminSummary
                                    flags={{ showPaidInvoiceAmount: true }}
                                    isProductUpgraded={true}
                                    product={product}
                                    title={
                                        StringPlaceholderService.replace(
                                            content.linkText,
                                            {
                                                productType: capitalizeFirstLetter(product.name)

                                            }
                                        )
                                    }
                                    subscription={subscription}
                                />
                            </Card>
                        }
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

