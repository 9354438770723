import React from 'react';
import styled, { css } from 'styled-components';
import Section from '../elements/Section';
import Span from '../elements/Span';
import { useHistory } from 'react-router-dom';
import { mobile, colors, mobileOnly, desktopOnly } from '../../styles/common';
import RichText from '../elements/RichText';
import { BLOCKS } from '@contentful/rich-text-types';

const NotYetAvailableSection = styled.div`
    display: flex;
    width: 100%;
    position: relative;
`;
const NotYetAvailableImage = styled.img`
    width: 100%;
    ${props => props.mobileOnly && css`
        ${mobileOnly}
    `}
    ${props => props.desktopOnly && css`
        ${desktopOnly}
    `}
`;

const NotYetAvailableDesktopPadding = '40';
const NotYetAvailableMobilePadding = '10';

const NotYetAvailableContent = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${NotYetAvailableDesktopPadding}px 20px;
    background: rgba(0, 0, 0, 0.615849);
    color: ${colors.pureWhite};
    width: 40%;
    height: calc(100% - ${NotYetAvailableDesktopPadding} * 2px);
    ${mobile`
        width: 100%;
        box-sizing: border-box;
        padding: ${NotYetAvailableMobilePadding}px 10px;
        height: 100%;
    `}
`;

const desktopStyle = {
    fontSize: '40px',
    lineHeight: '48px'
};

const mobileStyle = {
    fontSize: '30px',
    lineHeight: '39px'
};

const tryAnotherZipCodeText = { fontSize: '17px', textDecoration: 'underline', cursor: 'pointer', paddingTop: '1rem', paddingBottom: '1rem' };

const NotYetAvailable = ({ zipCode, content, isOutOfAreaSignupPage }) => {
    const history = useHistory();
    const SectionStyle = ({ children, ...props }) => <Section {...props} text={children} />;
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <SectionStyle style={{ fontWeight: 500 }} desktopStyles={desktopStyle} mobileStyles={mobileStyle}>{children}</SectionStyle>,
        },
    };
    const navigateToPage = () => {
        if (isOutOfAreaSignupPage) {
            history.push('/out-of-area/zip-code');
        } else {
            history.push('/out-of-area/signup');
        }
    }
    return (
        <NotYetAvailableSection>
            <NotYetAvailableImage mobileOnly='true' src={`http:${content.mobileImage.fields.file.url}`} alt={content.mobileImage.fields.file.fileName} />
            <NotYetAvailableImage desktopOnly='true' src={`http:${content.image.fields.file.url}`} alt={content.image.fields.file.fileName} />
            <NotYetAvailableContent>
                <RichText text={content.description} style={desktopStyle} options={options} />
                <Section text={zipCode} desktopStyles={{ fontSize: '62px', padding: '2rem 0rem' }} mobileStyles={{ fontSize: '56px'}} />
                <Span text={content.linkText} style={tryAnotherZipCodeText} onClick={navigateToPage} />
            </NotYetAvailableContent>
        </NotYetAvailableSection>
    );
};

export default NotYetAvailable;