import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../styles/common';
import HorizontalRule from '../../../elements/HorizontalRule';
import Section from '../../../elements/Section';
import ToolTip from '../../../tooltip/ToolTip';

const BottomSectionStyle = styled.div`
    text-align: left;
    padding: 2rem;
`;
const BottomSection = styled.div`
    display: flex;
    padding: 0.5rem 0rem 1rem 0rem;
    color: ${colors.textColor}
`;
const ProductChartBottomSection = ({ ...props }) => {
    const { productFeatures } = props || {};
    const style = {
        featureName: { display: 'inline-block', fontSize: '16px', lineHeight: '24px', padding: '0', marginLeft: '1rem', fontWeight: 500 },
        icon: { width: '14px', paddingTop: '5px', cursor: 'pointer' },
        tooltip: { fontSize: '10px' },
        horizontalRule: { height: '2px', backgroundColor: colors.pureBlack, marginBottom: '2rem' }
    };
    return (
        <BottomSectionStyle>
            <HorizontalRule
                style={style.horizontalRule}
            />
            {
                productFeatures.map((productFeature, index) =>
                productFeature.name !='30GB Secure Cloud Storage' && <BottomSection key={index}>
                        <FontAwesomeIcon
                            data-tip
                            data-for={`feature ${index}`}
                            style={style.icon}
                            icon={faQuestionCircle}
                        />
                        <ToolTip
                            fontStyle={style.tooltip}
                            textcolor={colors.pureWhite}
                            backgroundcolor={colors.textColor}
                            id={`feature ${index}`}
                            content={productFeature.description}
                        />
                        <Section
                            style={style.featureName}
                            dangerouslySetInnerHTML={{ __html: productFeature.name }}
                        />
                    </BottomSection>
                )
            }
        </BottomSectionStyle>
    );
}

export default ProductChartBottomSection;