import React from 'react';
import styled, {css} from 'styled-components';
import FieldError from './FieldError';
import InputMask from 'react-input-mask';

const InputStyleComponent = styled.input`
  border: 1px solid lightgrey;
  padding: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  height: 45px;
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  display: block;
  margin-top: ${props => props.extratopspace};
  ${props => props.error && css`
  border-color: red; 
  `};
`;

const MaskedInput = ({...props}) => {
  return (
      <>
        <InputMask {...props}>
            {(inputProps) => <InputStyleComponent {...inputProps}/>}
        </InputMask>
        {props.error && <FieldError>{props.error}</FieldError>}
      </>
  );
};

export default MaskedInput;
