import React from 'react';
import { Api, segmentAnalytics } from '../configuration/ApplicationContext';
import CheckoutForm from './forms/CheckoutForm';
import ContentfulIds from '../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, {
    SignUpFlowExtraContent,
    SignUpFlowFormContent
} from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Title from './elements/Title';
import Spinner from './elements/Spinner';
import DataContext from './contexts/DataContext';
import UpdatePaymentSummary from './update-payment-summary/UpdatePaymentSummary';
import { NotificationTypes } from './notifications/Notifications';
import BaseCheckout from './BaseCheckout';
import Breadcrumbs from './common/Breadcrumbs';
import { FREE, FREE_UPPER_CASE } from './common/Constants';
declare var Z: any; //Zuora

const breadCrumbRoutes = [
    {
        to: '/update-payment', label: 'Update Payment'
    }
]

export default class UpdatePaymentMethodPage extends BaseCheckout {
    static contextType = DataContext;
    state = {
        zuoraConfiguration: null
    };

    componentDidMount() {
        this.checkUserStateAndRedirect(false);
        this.loadZuoraForm();
        this.loadContent(ContentfulIds.UpdatePaymentMethodPage)
        this.loadPersonalInformation();
        this.prepopulateFields();
    }

    submit = async (data) => {
        const { addNotification, clearNotifications } = this.props.notificationContext;
        const { id, createdOn, lastModified, ...billingAddress } = this.state.personalInfo?.billingAddress || {};
        if (Object.keys(data.errors).length > 0 || !data.values.billingAddress) {
            this.setState({
                checkoutError: data.errors.billingAddress,
            });
            return;
        }

        this.setState({
            checkoutError: ""
        });

        window.orderSuccess = async (refId) => {
            this.setState({
                isSubmitDisabled: true
            });
            const paymentMethodId = refId;
            if (!paymentMethodId) {
                this.setState({
                    isSubmitDisabled: false
                });
                return;
            }
            let response = await Api.put('/api/subscriptions/payment-details', { paymentMethodId, billingAddress });
            if (!response) {
                this.setState({
                    isSubmitDisabled: false
                });
                return;
            }
            if (response.validationErrors) {
                await this.context.refreshContext();
                this.props.history.push('/my-account');
            } else {
                await this.props.notificationContext.getNotifications();
                await clearNotifications();
                await addNotification(NotificationTypes.PAYMENT_UPDATE_SUCCESS);
                segmentAnalytics.track('My Account | Updated Payment Successfull', {
                    memberId: this.context.userDetails.userId,
                    dateOfPaymentUpdate: this.context.userDetails.subscription.lastPaymentDate
                });
                this.props.history.push('/my-account');
            }
        };

        window.orderFailure = (failureMessage) => {
            this.setState({
                checkoutError: failureMessage,
                zuoraConfiguration: null,
                isSubmitDisabled: false
            }, () => {
                this.loadZuoraForm();
            });
        };
        Z.submit();
    };

    render() {
        const {
            zuoraConfiguration,
            content,
            checkoutError,
            personalInfo,
            prepopulateFields,
            billingAddressSameAsHomeAddress,
            isSubmitDisabled
        } = this.state || {};
        const {
            product,
            userDetails: {
                upcomingSubscriptionProductType
            } = {}
        } = this.context || {};
        if (!zuoraConfiguration || !content || !product || isSubmitDisabled) {
            return <Spinner />;
        }
        return (
            <>
                <Breadcrumbs
                    breadCrumbRoutes={breadCrumbRoutes}
                />
                <Content>
                    <ResponsiveSignupFlowLayout>
                        <SignUpFlowFormContent>
                            <Title
                                desktopStyles={{ marginTop: '0' }}
                                mobileStyles={{ marginTop: '3rem' }}>
                                {content.title}
                            </Title>
                            <CheckoutForm
                                zuoraConfiguration={zuoraConfiguration}
                                prepopulateFields={prepopulateFields}
                                content={content}
                                onSubmit={this.submit}
                                checkoutError={checkoutError}
                                personalInfo={personalInfo}
                                onSelectAddress={this.onSelectAddress}
                                selectedProduct={product}
                                hideTermsandConditions={true}
                                billingAddressSameAsHomeAddress={billingAddressSameAsHomeAddress}
                                isSubmitDisabled={isSubmitDisabled}
                                isUpdatePaymentMethodPage={true}
                            />
                        </SignUpFlowFormContent>
                        <SignUpFlowExtraContent>
                            {
                                product.name !== FREE && upcomingSubscriptionProductType !== FREE_UPPER_CASE &&
                                <UpdatePaymentSummary
                                    page={`updatePaymentMethod`}
                                    data={product}
                                    title={`Your Next Payment`}
                                />
                            }
                        </SignUpFlowExtraContent>
                    </ResponsiveSignupFlowLayout>
                </Content>
            </>
        );
    }
}

