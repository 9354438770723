import React from 'react';
import { colors } from '../../styles/common';
import Title from '../elements/Title';

const titleStyle = {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '30px',
    color: colors.pureWhite,
    marginTop: 'unset',
    textAlign: 'center',
    marginBottom: 'unset',
    padding:'20px 0px'
};

const activeTabTitleStyle = {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '30px',
    color: colors.pureWhite,
    marginTop: 'unset',
    textAlign: 'center',
    marginBottom: 'unset',
    padding:'30px 0px 10px 0'
}

const TabHeader = ({title, isActiveTab = false}) => {
    return (
        <>
           {<Title mobileStyles={isActiveTab ? activeTabTitleStyle : titleStyle}>{title}</Title>} 
        </>
    );
};

export default TabHeader;