import styled from 'styled-components';
import {colors} from '../../styles/common';

const FieldError = styled.span`
  font-size: 14px;
  color: ${colors.darkRed};
  line-height: 26px;
`;

export default FieldError;
