import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobile, desktop } from '../../styles/common';
import Modal, { ModalProvider, BaseModalBackground } from "styled-react-modal";
import InfoText from './InfoText';
import AdminContext from '../contexts/AdminContext';

const StyledModal = Modal.styled`
  background-color: ${colors.pureWhite};
  ${desktop`	
    width: 650px;
    height: 641px;
  `}	
  ${mobile`	
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  `}
`;

const FadingBackground = styled(BaseModalBackground)`
  transition: opacity ease 200ms; 
  z-index: 100;
`;

const ModalContent = styled.div`
    padding: 5.125rem 8.375rem 0rem 7.06rem;
    ${mobile`
         padding: 3.125rem 1rem 0rem 1rem;
    `}
`;

const closeIconStyle = {
    cursor: 'pointer',
    paddingRight: '2rem',
    paddingTop: '1rem',
    float: 'right',
    color: colors.searchResultsHeaderColor
};

class DialogBox extends Component {
    static contextType = AdminContext;

    render() {
        const { isOpen, closeModal } = this.props;
        return (
            <ModalProvider backgroundComponent={FadingBackground}>
                <StyledModal isOpen={isOpen}>
                    <InfoText style={closeIconStyle} onClick={closeModal}>X</InfoText>
                    <ModalContent>
                        {this.props.children}
                    </ModalContent>
                </StyledModal>
            </ModalProvider>
        );
    }
}

DialogBox.propTypes = {
    isOpen: PropTypes.bool.isRequired
};

export default DialogBox;
