import React, { useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useCustomForm from '../../../hooks/useCustomForm';
import SubmitButton from '../../elements/SubmitButton';
import ZuoraCheckoutForm from '../../elements/ZuoraCheckoutForm';
import RichText from '../../elements/RichText';
import { LocationSearchInput } from '../../elements/LocationSearchInput';
import Section from '../../elements/Section';
import { colors } from '../../../styles/common'
import StringPlaceholderService from '../../../services/StringPlaceholderService';
import CheckboxInput from '../../elements/CheckboxInput';
import InfoText from '../../elements/InfoText';
import Spinner from '../../elements/Spinner';
import validate from '../../forms/CheckoutForm/checkoutValidation';
import ZuoraFormError from '../../elements/ZuoraFormError';
import Link from '../../elements/Link';
import RadioButton from '../../elements/RadioButton';
import { MARKS, BLOCKS } from "@contentful/rich-text-types";
import Span from '../../elements/Span';
import DynamicParagraphText from '../../elements/DynamicParagraphText';
import { dateSuffix, removeLeadingZerosForSingleDigitDate } from "../../common/Helpers";
import AdminContext from '../../contexts/AdminContext';
import PromoCode from '../../common/PromoCode';
import UtilsService from '../../../services/UtilsService';
import { AdminApi } from '../../../configuration/ApplicationContext';
import AdminNotificationContext from '../../contexts/AdminNotificationContext';
import { FREE, FOREVER, FREE_UPPER_CASE, ANNUALLY } from '../../common/Constants'
import { useHistory } from 'react-router';

const AdminCheckoutForm = ({ personalInfo, initialValues, onSubmit, onSelectAddress, onApplyPromoCode, selectedProduct, zuoraConfiguration, content, checkoutError, prepopulateFields, subscription, isNewPaymentMethod = true, setNewPaymentMethod, billingAddressSameAsHomeAddress, showPaymentMethod = false, showDeclineLink = true, isSubmitDisabled, ...props }) => {
    const context = useContext(AdminContext);
    const notificationContext = useContext(AdminNotificationContext);
    const history = useHistory();
    const [billingAddressSelected, setBillingAddressSelected] = useState(!billingAddressSameAsHomeAddress);
    if (billingAddressSameAsHomeAddress === false) {
        const { billingAddress } = personalInfo || {};
        initialValues.billingAddress = `${billingAddress?.lineOne}, ${billingAddress?.city}, ${billingAddress?.state}, ${billingAddress?.countryCode}`;
    }
    const {
        values,
        touched,
        errors,
        submitted,
        handleChange,
        handleBlur,
        handleSubmit
    } = useCustomForm({ initialValues, validate, personalInfo, onSubmit, onSelectAddress, selectedProduct });

    const inputHandlers = {
        onChange: handleChange,
        onBlur: handleBlur,
        extratopspace: '0.5rem',
        extraTopLabelSpace: '0.75rem'
    };
    const [checked, setChecked] = useState(billingAddressSameAsHomeAddress);
    const form = useRef();
    const { id, basePrice, actualPrice, paymentMode, totalRefundAmount, coverageThrough, discount } = selectedProduct || {};
    const productsWithoutPromoCodes = JSON.parse(localStorage.getItem('productsWithoutPromoCodes'));
    const findProduct = (products) => {
        const mappedProducts = UtilsService.mapProductsToArray(products);
        const foundProduct = mappedProducts.find(product => product.id === id);
        const selectedProduct = foundProduct ? foundProduct : {};
        return selectedProduct;
    }
    const selectProductWithoutPromoCode = productsWithoutPromoCodes ? findProduct(productsWithoutPromoCodes) : [];
    const { creditCardNumber, creditCardType } = subscription?.defaultPaymentMethod || {};
    const lastFourCardNumberDigit = creditCardNumber?.substr(creditCardNumber.length - 4);
    const properties = {
        chargeNow: subscription?.outstandingBalance?.toFixed(2),
        chargeNowWithRefundAmount: totalRefundAmount ? (actualPrice?.total - totalRefundAmount).toFixed(2) : actualPrice?.total.toFixed(2),
        chargeToday: actualPrice?.total.toFixed(2),
        discountedBasePrice: actualPrice?.price.toFixed(2),
        basePrice: basePrice?.price.toFixed(2),
        basePriceWithTax: basePrice?.total.toFixed(2),
        everyTimeOn: removeLeadingZerosForSingleDigitDate(moment(coverageThrough)?.format('MM/DD/YYYY')),
        paymentMode: paymentMode === ANNUALLY ? 'year' : 'month',
        promoCodeExpiry: context?.promoCodeData?.effectiveEndDate === FOREVER ? context?.promoCodeData?.effectiveEndDate : removeLeadingZerosForSingleDigitDate(moment(context?.promoCodeData?.effectiveEndDate)?.format('MM/DD/YYYY')),
        chargeAfterPromoCodeExpired: selectProductWithoutPromoCode?.actualPrice?.total.toFixed(2),
        basePriceAfterPromoCodeExpired: selectProductWithoutPromoCode?.basePrice?.price.toFixed(2),
        basePriceWithTaxAfterPromoCodeExpired: selectProductWithoutPromoCode?.basePrice?.total.toFixed(2),
        discountedbasePriceAfterPromoCodeExpired: selectProductWithoutPromoCode?.actualPrice?.price.toFixed(2),
        chargeAfterPromoCodeExpiredWithAAADiscount: (selectProductWithoutPromoCode?.basePrice?.total + selectProductWithoutPromoCode?.discount?.total).toFixed(2),
        promoRateWithoutAAADiscount: (actualPrice?.total - discount?.price).toFixed(2),
        productType: selectedProduct?.name,
        basePriceWithoutTax: (basePrice?.price - actualPrice?.tax).toFixed(2),
        subscriptionType: paymentMode === ANNUALLY ? 'annual' : 'monthly',
        priceIncludesTax: basePrice?.tax > 0 ? ' (Price includes tax)' : ''
    };
    content.disclosures.content.forEach(section => {
        section.content.forEach(subSection => {
            if (subSection.value) {
                subSection.value = StringPlaceholderService.replace(subSection.value, properties)
            }
        })
    })
    content.promoCodeDisclosures && content.promoCodeDisclosures.content.forEach(ct => {
        ct.content.forEach(pr => {
            if (pr.value) {
                pr.value = StringPlaceholderService.replace(pr.value, properties)
            }
        })
    })
    content.notForeverDisclosures && content.notForeverDisclosures.content.forEach(ct => {
        ct.content.forEach(pr => {
            if (pr.value) {
                pr.value = StringPlaceholderService.replace(pr.value, properties)
            }
        })
    })

    content.trialPricingDisclaimer && content.trialPricingDisclaimer.content.forEach(section => {
        section.content.forEach(subSection => {
            if (subSection.value) {
                subSection.value = StringPlaceholderService.replace(subSection.value, properties)
            }
        })
    });

    content.nonMemberFlowDisclosuresSection && content.nonMemberFlowDisclosuresSection.forEach(section => {
        section.fields.description.content.forEach(ct => {
            ct.content.forEach(pr => {
                if (pr.value) {
                    pr.value = StringPlaceholderService.replace(pr.value, properties)
                }
            })
        })
    });


    const addressStyle = { fontSize: '17px', color: colors.textGrey, padding: '0px 36px' };

    const zuoraForm = zuoraConfiguration ? <ZuoraCheckoutForm prepopulateFields={prepopulateFields || {}} configuration={zuoraConfiguration} /> : <Spinner />;

    const filteredErrors = submitted ? errors : Object.keys(errors)
        .filter(key => touched[key])
        .reduce((errorObj, key) => {
            errorObj[key] = errors[key];
            return errorObj;
        }, {});

    const onBillingAddressSelect = (address) => {
        delete errors.billingAddress;
        values.billingAddress = address;
        setBillingAddressSelected(true);
        onSelectAddress(address);
    }

    const onFormSubmit = (event) => {
        event.preventDefault();
        if (checked) {
            delete errors.billingAddress
            values.billingAddress = personalInfo?.homeAddress;
        } else {
            if (!(values.billingAddress && billingAddressSelected)) {
                if (form.current) {
                    form.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                }
                errors.billingAddress = 'Please enter Billing Address.';
            }
        }
        handleSubmit();
    }

    const setBilllingAddress = () => {
        if (checked) {
            setBillingAddressSelected(false);
            delete errors.billingAddress;
            values.billingAddress = '';
        } else if (checked && personalInfo.billingAddress) {
            values.billingAddress = personalInfo?.billingAddress;
            setBillingAddressSelected(true);
            onSelectAddress(personalInfo.billingAddress);
        } else {
            values.billingAddress = personalInfo?.homeAddress;
            setBillingAddressSelected(true);
            onSelectAddress(personalInfo.homeAddress);
        }
    }

    const loadAdminDisclosureContent = () => {
        const isNonAaaMember = !context?.memberDetails?.membership;
        if (!context.promoCodeData) {
            return isNonAaaMember && content.nonMemberFlowDisclosuresSection ? content.nonMemberFlowDisclosuresSection[2].fields.description : content.disclosures;
        } else {
            const nonMemberDiscountApplied = context.promoCodeData.promoCodeCustomDescription.includes('Gig');
            if (content.nonMemberFlowDisclosuresSection && nonMemberDiscountApplied) {
                return isNonAaaMember ? content.nonMemberFlowDisclosuresSection[1].fields.description : content.nonMemberFlowDisclosuresSection[0].fields.description;
            } else {
                const showTrialPromoCodeDisclaimer = content.trialPricingDisclaimer && context?.promoCodeData?.type === 'percent' && context?.promoCodeData?.amount === '100';
                let disclosure = context.promoCodeData.effectiveEndDate === FOREVER ? content.promoCodeDisclosures : showTrialPromoCodeDisclaimer ? content.trialPricingDisclaimer : content.notForeverDisclosures;
                if (isNonAaaMember && content.nonMemberFlowDisclosuresSection) {
                    disclosure = context.promoCodeData.effectiveEndDate === FOREVER
                        ? content.nonMemberFlowDisclosuresSection[3]?.fields.description
                        : (showTrialPromoCodeDisclaimer
                            ? content?.nonMemberFlowDisclosuresSection[4]?.fields?.description
                            : content.nonMemberFlowDisclosuresSection[1].fields.description);
                }
                return disclosure;
            }
        }
    }
    const onCancelUpgrade = () => {
        notificationContext.clearNotifications();
        history.push('/admin/member-account');
    }

    const disclosures = props?.isUpdatePaymentMethodPage && context?.memberDetails?.upcomingSubscriptionProductType === FREE_UPPER_CASE
        ? ''
        : loadAdminDisclosureContent();
    const SpanStyled = ({ children }) => <Span style={{ color: `${colors.darkBlue}`, fontWeight: 'bold' }} text={children} />;
    const DynamicParagraphStyled = ({ children, ...props }) => <DynamicParagraphText id="rich-text" {...props} text={children} />;
    const options = {
        renderMark: {
            [MARKS.CODE]: text => <SpanStyled>{text}</SpanStyled>
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyled >{children}</DynamicParagraphStyled>,
        },
    };

    return (
        <form
            onSubmit={onFormSubmit}
            noValidate
            ref={form}>
            <ZuoraFormError
                error={checkoutError}
            />
            {
                showPaymentMethod && creditCardNumber &&
                <>
                    <Section
                        text='Payment Method'
                        style={{ fontWeight: 'bold', padding: 0 }}
                    />
                    <RadioButton
                        name='checkout-mode'
                        checked={!isNewPaymentMethod}
                        onChange={() => { setNewPaymentMethod(false); }}
                        label={'Use last payment method'}
                    />
                    {
                        !isNewPaymentMethod &&
                        <Span
                            style={{ marginLeft: '2rem' }}
                            text={`${creditCardType} **** **** **** ${lastFourCardNumberDigit}`}
                        />
                    }
                    <RadioButton
                        name='checkout-mode'
                        checked={isNewPaymentMethod}
                        onChange={() => { setNewPaymentMethod(true); }}
                        label='Add new Payment Method'
                    />
                </>
            }
            {
                isNewPaymentMethod &&
                <>
                    {zuoraForm}
                </>
            }
            <Section
                text='Billing Address'
                style={{ fontWeight: 'bold', padding: 0 }}
            />
            <InfoText>
                <CheckboxInput
                    name="isHomeAdress"
                    checked={checked}
                    onChange={() => { setChecked(!checked); setBilllingAddress(); }}
                />
                Billing address same as home address
            </InfoText>
            {
                !checked &&
                <LocationSearchInput
                    name="billingAddress"
                    value={values.billingAddress}
                    onSelectAddress={onBillingAddressSelect}
                    error={filteredErrors.billingAddress}
                    {...inputHandlers}
                    onChange={() => setBillingAddressSelected(false)}
                />
            }
            {
                checked &&
                <>
                    <Section
                        style={addressStyle}
                        text={personalInfo?.homeAddress?.lineOne}
                    />
                    <Section
                        style={addressStyle}
                        text={(personalInfo?.homeAddress?.lineTwo || '') + ' ' + (personalInfo?.homeAddress?.city || '') + ' ' + (personalInfo?.homeAddress?.state || '') + ' ' + personalInfo?.homeAddress?.zipCode}
                    />
                </>
            }
            {
                onApplyPromoCode &&
                <PromoCode
                    showPromoCodeField={true}
                    ratePlanId={selectedProduct.id}
                    onApplyPromoCode={onApplyPromoCode}
                    dataContext={context}
                    notificationContext={notificationContext}
                    options={props?.promoCodeOptions}
                    Api={AdminApi}
                />
            }
            {
                selectedProduct.name !== FREE &&
                <RichText
                    text={disclosures ? disclosures : ''}
                    options={options}
                />
            }
            <SubmitButton
                text={content.submitButtonText}
                style={{ marginTop: '2rem' }}
                disabled={isSubmitDisabled}
            />
            {
                showDeclineLink &&
                <Link
                    style={{ fontSize: '14px' }}
                    textalign="center"
                    extraleftspace="6rem"
                    extratopspace="1rem"
                    extrabottomspace="1.5rem"
                    text="Cancel Upgrade"
                    to="#"
                    onClick={onCancelUpgrade}
                />
            }
        </form>
    );
};

AdminCheckoutForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onSelectAddress: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({}),
    selectedProduct: PropTypes.shape({}).isRequired
};

AdminCheckoutForm.defaultProps = {
    initialValues: {}
};

export default AdminCheckoutForm;
