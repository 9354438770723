import React from 'react';
import Header from './Header';
import Footer from './Footer';
import FixedButtomButton from './FixedBottomButton';
import { segmentAnalytics } from '../../configuration/ApplicationContext';

const withHeaderAndFooter = (
    WrappedComponent,
    isSignupFlow = false,
    showFooterLinks = true,
    showEnrollNow = false,
    isAaaJoinUpsellFlow = false,
    showTopGradient = true,
    meta = {}) => {
    return (props) => (
        <>
            <Header
                isSignupFlow={isSignupFlow}
                isAaaJoinUpsellFlow={isAaaJoinUpsellFlow}
                showPromoCodeNotification={meta.showPromoCodeNotification}
                hideBorderBottom={meta.hideBorderBottom}
                showTopGradient={meta.showTopGradient}
                showMyAccountOnHeader={meta.showMyAccountOnHeader}
            />
            <WrappedComponent
                {...props}
            />
            <Footer
                showFooterLinks={showFooterLinks}
                applyBottomPadding={showEnrollNow}
            />
            {/* {
                showEnrollNow &&
                <FixedButtomButton
                    text='Enroll Now'
                    to={'/product/'}
                    checkZipCode={true}
                    clickHandler={() => segmentAnalytics.track("Mobile Docked CTA")}
                />
            } */}
        </>
    );
};

export default withHeaderAndFooter;
