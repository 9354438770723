import React from 'react';
import styled, {css} from 'styled-components';
import { mobileOnly, commonPropStyles, desktopOnly } from '../../styles/common';

const SectionStyleComponent = styled.section`
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    line-height: 26px;
    padding-top: 0.5rem;
    padding-bottom:1rem;
    
    ${props => props.styles && css`
        styles: ${props.styles};
    `}
    ${props => props.mobileOnly && css`
        ${mobileOnly}
    `}
    ${props => props.desktopOnly && css`
        ${desktopOnly}
    `}
    ${commonPropStyles.mobile}
    ${commonPropStyles.desktop}
`;

const Section = ({text, ...props}) => {
    return (
        <SectionStyleComponent {...props}>{text}</SectionStyleComponent>
    )
  }

export default Section;