import React from "react";
import styled from "styled-components";
import { colors, mobile } from '../../styles/common';
import Section from '../elements/Section';

const ProductDescriptionContentSection = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  ${ mobile`
      flex-direction: column;
  `} 
`;

const titleStyle = {
    fontSize: '30px',
    paddingBottom: '0.12rem'
};

const titleMobileStyle = {
    fontSize: '20px',
}

const commonTitleStyle = {
    color: `${colors.darkBlue}`,
    fontWeight: 'normal',
    textAlign: 'center',
    paddingBottom: '0.5rem',
    letterSpacing: '0.5px'
}

const descriptionStyle = {
    fontSize: '20px',
    color: `${colors.myrtleColor}`,
    padding: '0px 10px 10px 10px'
};

const descriptionMobileStyle = {
    fontSize: '14px',
    padding: '0px 25px 10px 25px',
    color: `${colors.myrtleColor}`,
};

const ProductDescriptionContainer = styled.div`
    margin: 0.5rem;
    text-align: center;
    ${ mobile`
       width: 100%;
    `}
`;

const ProtectElementContent = ({ sections }) => {
    return (
        <ProductDescriptionContentSection>
            {sections.map(section =>
                    <ProductDescriptionContainer key={section.sys.id}>
                        <Section text={section.fields.title} mobileStyles={titleMobileStyle} desktopStyles={titleStyle} style={commonTitleStyle} />
                        <Section text={section.fields.description} mobileStyles={descriptionMobileStyle} desktopStyles={descriptionStyle} />
                    </ProductDescriptionContainer>
              )}
        </ProductDescriptionContentSection>
    );
};

export default ProtectElementContent;
