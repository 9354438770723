const ContentfulIds = {
    PersonalInformationForm: '61DYlUdVR7jCbx1dI7eWFT',
    CheckoutPage: 'eI7xCQ43HoHAYvFsbvTpx',
    FreeSubscriptionCompletionPage: '5Ce6yBJ1J7T9zxY9chQjoh',
    PaidSubscriptionCompletionPage: '37WNcVJ1cY4urSDfEfPmyT',
    Phase3MigratedUserAlreadyExistsPage: '3tbwqjUMpyKRtzHudWdwaJ',
    BasicCoverage: '36GzTwBWbS5xeCKdK5dFef',
    ProductOfferingTitle: "SJu0pwCRfOiJVzwNRlAYk",
    ProductOfferingVariableB: '4Qjb8vd7aSFzXQqKkhAHpH',
    ChooseYourPlanPage: '2M6PuRltBnKbLn4BS59NgV',
    AlreadyActivatedSubscriptionPage: "6HWGDnf2JvU0o3vuwX8IAO",
    InactiveMembershipPage: {
        CancelledMembership: '1mnP7CiKERPvGmU5WHNZl8',
        InvalidMembership: '7HnMuzDlV63WWUojLQLKXL',
    },
    InterstitialPage: {
        InvalidMembershipBasicSubscription: '5RA5jnzG9GEkDdmAjObKvh',
        RenewMembershiWithPaidSubscription: '1rbFPzodizjdy09SrcgPtJ',
        GraceMembership: '7jNolYHscghyOCyoNq9FQw',
    },
    EditPersonalInformationForm: "2wRpvhIapXPbzRpOpJZZRs",
    LoginWithAAAPage: '7c4u9mIt4cgCGg2doeo99S',
    MyAccountPage: '3OctYKMt3IWyzbE5DvlJ0B',
    UpdatePaymentMethodPage: "512slM2uMDbWcHcKSI8xbS",
    PayOutstandingBalancePage: '4uNXjIHyisRAHg0YeacNG0',
    MyAccountPaymentDuePage: "516jk86ozhGQiSbdtjEdaJ",
    Notifications: "4B8QjajlC5yQpqAkrhgysR",
    AdminNotifications: "08k5kQCEgxUijLAonUMTM",
    EmailAlreadyInUse: '4PaCytRnkoT8r9liFlP6bC',
    MyAccountIds: {
        ProductOffering: {
            Title: '5ID9Fky1l4BKmsgvhJBmzy',
            CompleteCoverage: '2cQNZXdHRDVD11XEAEqnbM'
        },
        UpgradeCompletePlanPage: "4KUmYO6jwbDg70rK3ho26I",
        UpgradeTierCheckoutPage: '1wYZNL7K0614zvYJ2eEXkY'
    },
    UpgradeBasicPlanPage: "3H78ltQjAJPdV4EhmqmRWM",
    LandingPage: '6u6zSEKFkMJy7JxDmsQX5a',
    UpdateHomeAddressInfo: "36j9WefBNU2pnRVSTGlmhC",
    UpgradeSuccessfulPage: "4MBrAlaPS7ksCGYfdtAuX3",
    FaqsPage: "412tEimcXnmEo7w3wRLboU",
    PlanDetailsPage: '3PdlxJKryua0ms64nq0hNr',
    CustomerPlanDetailsPage: '5ybGTNbdftrGNQSGBalFsv',
    CustomerPlansBenefits: '5ua6mSRjFFQ7w5yjuklmqy',
    CustomerCompareOurPlans: '1bZGWHOIGRG0E2LuwZxE4S',
    AAAJoinFlowUpgrade: '6gYWTKDXh8SwUxaHjhRo3E',
    AAAJoinFlowAutoEnrollment: 'QHnPQ1xfneeJIG0zvqE3P',
    WhatsIncludedPages: {
        basic: '4tAsMl5sVRUtgk0j5R642L',
        protect: '6UVL9xF905RT3TNYhRaGJ3',
        complete: '6Av4arkzhAl8W8xftqxw9N'
    },
    EnterZipCode: '1SQAKXwURdU2ZJgmqaIqNM',
    FreeTrialDisclaimer: 'KrcYwEARwIyzmuiiBn6BU',
    OutOfPage: {
        OutOfPageSignupToBeNotified: '16osRjXXH4aycyDnd4AWd4',
        OutofAreaSignupSuccess: 'W03VlCYrzCDfrYvpk9TLp',
        OutofAreaZipCodePage: '5mrb8dJ9JpL1IQw9UDGAaf'
    },
    PrivacyPolicyPage: '4z781ZUr6qBMcT5b42x8PN',
    TermsAndConditionsPage: '78nb6EoWf5R0R8lr8M6tUN',
    InsurancePage: '7gHw6ztZq7fwkFPSbAkXDl',
    MemberSearchPage: '55pofPO7KcxkNlVK2ES3Gy',
    NoResultsPage: '4fnfHRNVdtKA732rpiIxdn',
    ContactUsPage: '6rRzEmr9OgzQlsuooJWsGd',
    Disclaimer: '5iHH38woaX9mx7Ps5ATFsy',
    CustomerDisclaimer: '2HyT7rP1TQcCKINKTHNPVE',
    MembershipNumberPage: '4YbdiCJGy5prVvTliRorXT',
    VerifyIdentityLoginPage: '4Ve6MiNJboB6FhON6KeFNb',
    VerifyIdentitySuccessPage: 'qjgv15V2jPU6NRQ1GYc1G',
    VerifyIdentityFailurePage: '1iA2rGpzvLpqITsXbQ1NDD',
    VerifyIdentityJoinNowPage: '6sRNMhSGPByLW9REACmFm4',
    OldLandingPage: '2wHTVjNNE8fbUa9LB7lUxL',
    NotFoundPage: '4tvZrQuCG1UouP1C6spLsw',
    Admin: {
        ChooseYourPlanPage: '6wvm25qnrpyGT283INTLI0',
        ChooseYourPlanPageV1: '3qroV6cL6kPxlGJuBd6PLJ',
        CoverageDowngradePage: '2wHTVjNNE8fbUa9LB7lUxL',
        ProductOfferingPage: '2wHTVjNNE8fbUa9LB7lUxL',
        ComparePlanSection: 'ZsebnSiML6YkSxkrs8oZ2',
        MemberAccountIds: {
            UpgradeBasicPlanPage: '4x98hPum5jBL8k9IAqeEzR',
            UpgradeCompletePlanPage: 'SZVX9klbhdysVhlYaeDZM',
            UpgradeTierCheckoutPage: '2J4uHGyseXFKZKOljDdtct',
            UpgradeSuccessfulPage: '5ceWnjZW5dXza2jdZwlMxw',
            AdminProcessPaymentPage: '4rL91IfQE33gt3BAfDVqjc',
            PayOutstandingBalancePage: '5IXlFcIzFi8rSM8DvMB4ID',
            DowngradeCompletePlanPage: '254oXqfr1euFpSxM1iwl3B'
        },
        SearchResult: '2PgC5CJ28QzkvhixbPIc2K',
        GetTrustedProtection: '6dnX772aNCxEGkzaTVvWqy',
        PersonalInfoPage: '20Rq3enPQxIm7T1nAkxlU0',
        SignUpFlow: {
            ProductOfferingPage: '0rppiJdPnh7NQOt3R8d7y',
            CheckoutPage: '5YWgdEpUOLrGKEiNwwf88D',
            SubscriptionSuccessPage: '1NVOY1B4JphW6HwGRDm71R',
            NonMemberPersonalInfoPage: '20Rq3enPQxIm7T1nAkxlU0'
        },
        RejoinFlow: {
            PersonalInfoPage: 'oWYpDW8pOvNHByc8b45Gr',
            ProductOfferingPage: 'EYZLtswfvijtY6VHGSkSw',
            CheckoutPage: 'CdXTV3c3ApaHd7bplP21Q',
            SubscriptionSuccessPage: '5iedeEQkY33PEDjAp9fbq1'
        },
        Bundle: {
            NotAvailableInAreaOutsideMWG: '7hZsvzgTSOdto0hju06A2P',
            NotAvailableInAreaWithInMWG: '51Y1vlcuRjPFxVTIZQXWDj',
            PersonalInfoPage: "20Rq3enPQxIm7T1nAkxlU0",
            AccountAlreadyExist: '7yuzV2WeELRz8kLw3HLQuB',
            CancelledIdcAccountExist: '6HI7bR1QsZeqN3kwOSKfq0',
            IdcAccountAlreadyExist: '2NHR0CtLJUm50Ynp1otiv8',
            AAAMembershipExist: '6agZHYrVcENVMqnZwf5ix7',
            CheckoutPage: 'xwNlXSPxEmrGMTroVgV9A',
            SubscriptionSuccessPage: '2yTArHyzouJjJyzH96jt6T',
        }
    },
    AdminMemberAccountPage: '2FIHHmiB2ADrijGmS75oNR',
    AutoRenewalTerms: '19NYE3qHZf1oXkZ4x3V4JX',
    AdminCancelPlanPage: {
        NormalFlow: '7KPpJhyNsteghzM1XlZqfh',
        OverrideDowngradeWithCancel: '6cna32jfnFahSJB6qrTWro'
    },
    AdminDowngradePlanPage: {
        AlreadyDowngradedFlow: '56NwXYWrrEwnPwaPuMilHh',
        AlreadyCancelledFlow: 'BApHpoSL3n6ALWbvyFcfa'
    },
    AdminCancelSuccessPage: 'eKa4sdKetEZgHDXeJyKUs',
    AdminUpdatePaymentPage: '2ooKf30N9w4KZwvn9BDxdH',
    AAAIdcSignupFlow: {
        ProductOfferingPage: '1b99qq6ja43rlHdh35SbuT',
        FreeSubscriptionCompletionPage: '73xZO3WanpTcbfCccIc25o',
        CheckoutPage: '60Uzp35STg5ZBjUlk7XbC'
    },
    BundleFlow: {
        ChoosePlanSection: '2p4db3NkAqQBx6sU6w56yl',
        AccountAlreadyExist: '3j3ZnGViZuDOB5t20qlOWf',
        IdcAccountAlreadyExist: 'ew7bCw25bSPVQa0RPjAn5',
        CancelledIdcAccountExist: '1g0YaMJLUXh8Crlf7hEokr',
        BundleCreateNewAccount: '5gfZ0FC1Ys3hztXYry4fyz',
        AlreadyHaveAAALogin: '18tPsdUJwcCd3L6Rf8WTIO',
        SubmitNewEmail: '43nysWOW9VpHcKuARj8Eeo',
        AAAMembershipExist: '1Q0GjmkHD7RfWjHzI6ETse',
        SubmitNewEmailOrSignup: '1uMXzNKlwJXeRGC0YG7BtR'
    },
    MarketingPages: {
        PromoCodeBanner: '48ha78k5YN6cw4ses5U6Jv'
    }
};

export default ContentfulIds;
