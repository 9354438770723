import React, { Component } from "react";
import LandingContent from "./layout/LandingContent";
import ResponsiveLandingPageLayout from "./layout/ResponsiveLandingPageLayout";
import NotYetAvailable from "./out-of-area/NotYetAvailable";
import ZipCodeService from "../services/ZipCodeService";
import { ContentfulService } from "../configuration/ApplicationContext";
import Spinner from "./elements/Spinner";
import OutofAreaSignupToNotifyForm from "./forms/OutOfAreaForm/OutofAreaSignupToNotifyForm";
import ProtectMyID from "./out-of-area/ProtectMyId";
import ContentfulIds from "../configuration/ContentfulIds";
import withPageTitle from "./layout/withPageTitle";
import withPageMetaTags from "./layout/withPageMetaTags";
import { getPromoCodeNameFromRedirectParams } from "./common/Helpers";
class OutofAreaZipCodePage extends Component {

    constructor() {
        super();
        this.state = {};
    }
    componentDidMount() {
        this.setContent();
        this.setZipCode();
    }


    async setContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.OutOfPage.OutofAreaZipCodePage);
        this.setState({ content: response.fields.sections });
    }

    setZipCode() {
        const zipCode = ZipCodeService.getZipCookie();
        this.setState({ zipCode: zipCode });
    }
    changeZipCode = (data) => {
        this.setState({
            apiErrors: []
        });
        if (Object.keys(data.errors).length > 0) {
            return;
        }
        const { zipCode } = data.values;
        ZipCodeService.setZipCode(zipCode);
        this.addZipCodeData(zipCode);
    }

    addZipCodeData(zipCode) {
        ZipCodeService.checkIdcEligibility().then((response) => {
            if (response.serviceable) {
                if (localStorage.getItem('askedZipCodeOnPage')) {
                    this.props.history.push(localStorage.getItem('askedZipCodeOnPage'));
                } else {
                    this.props.history.replace('/product/');
                }
            } else {
                this.setState({ zipCode });
            }
        });
    }


    render() {
        const { zipCode, apiErrors, content } = this.state;
        if (!zipCode || !content) {
            return <Spinner />;
        }
        return (
            <LandingContent
                promocodename={getPromoCodeNameFromRedirectParams()}>
                <ResponsiveLandingPageLayout>
                    <NotYetAvailable
                        zipCode={zipCode}
                        content={content[0].fields}
                        isOutOfAreaSignupPage={false}
                    />
                    <OutofAreaSignupToNotifyForm
                        zipCode={zipCode}
                        onSubmit={this.changeZipCode}
                        apiErrors={apiErrors}
                        content={content[1].fields}
                    />
                    <ProtectMyID
                        content={content[2].fields}
                    />
                </ResponsiveLandingPageLayout>
            </LandingContent>
        );
    }
}

export default withPageMetaTags(withPageTitle(OutofAreaZipCodePage, "Out of Area"), { noindex: true });