import React, { useContext } from 'react';
import CardWithTitle from '../../elements/CardWithTitle';
import CardLineItem from '../../elements/CardLineItem';
import Link from '../../elements/Link';
import { colors } from '../../../styles/common';
import Currency from '../../common/Currency';
import { removeLeadingZerosForSingleDigitDate, formatDateMMDDYYYY } from '../../common/Helpers';
import { MyAccountPageType } from '../../myaccount/MyAccountPageType';
import HorizontalRule from '../../elements/HorizontalRule';
import AdminContext from '../../contexts/AdminContext';
import { FREE_UPPER_CASE } from '../../common/Constants';

const linkStyle = {
    fontWeight: 'bold',
    fontSize: '16px',
    color: colors.blue,
    display: 'inline'
}
const boldStyle = { fontWeight: 'bold' };
const italicStyle = { fontStyle: 'italic' };
const hrStyle = { width: '50%', marginTop: '2rem', marginBottom: '2rem' };
const paymentStyle = { ...boldStyle, marginLeft: '3px', fontSize: '15px' };
const MemberPaymentDetails = ({ content, pageType, isExpired = false, creditCardExpiryDate , upcomingSubscriptionProductType }) => {
    const context = useContext(AdminContext);
    const subscription = context.memberDetails.subscription;
    const nextPaymentDate = formatDateMMDDYYYY(subscription.termEndDate);
    const lastPaymentDate = formatDateMMDDYYYY(subscription.lastPaymentDate);
    const { creditCardNumber, creditCardType } = subscription.defaultPaymentMethod || {};
    const { lastPaymentAmount, nextPaymentAmount,actualPrice, basePrice, discount, promoCodeName, promoCode, customFields, billingFrequency, upcomingSubscriptionBillingFrequency, cancelledSubscriptionGracePeriod } = subscription || {};
    const planType = upcomingSubscriptionProductType && upcomingSubscriptionProductType !== FREE_UPPER_CASE ? upcomingSubscriptionBillingFrequency : billingFrequency;
    const lastFourCardNumberDigit = creditCardNumber?.substr(creditCardNumber.length - 4);
    const nonMemberDiscountApplied = promoCode && customFields?.Description?.value.includes('Gig');
    const showNextPaymentBreakdown =  cancelledSubscriptionGracePeriod === false && upcomingSubscriptionProductType !== FREE_UPPER_CASE;
    
    return (
        <>
            { pageType !== MyAccountPageType.BASIC &&
                <CardWithTitle firstGrow={true}  title='Payment Details'>
                    {showNextPaymentBreakdown && <>
                        <CardLineItem itemKeyStyle={boldStyle} itemStyle={boldStyle} itemKey='Next Payment Date' itemValue={removeLeadingZerosForSingleDigitDate(nextPaymentDate)} />
                        <HorizontalRule style={hrStyle} />
                        <CardLineItem itemKey={`Base Price ${planType === 'MONTHLY' ? '(Monthly)' : '(Annual)'}`} itemValue={<>{basePrice ? <Currency value={basePrice?.price} /> : 'N/A'}</>} />
                        { actualPrice && actualPrice?.tax > 0 && <CardLineItem itemKey='Taxes' itemValue={<Currency value={actualPrice?.tax} />} /> }
                        { discount && Math.abs(discount?.price) > 0 && <CardLineItem itemKeyStyle={italicStyle} itemStyle={italicStyle} itemKey='AAA Member Discount' itemValue={<>{discount ? <Currency value={discount?.price} /> : 'N/A'}</>} />}
                        { promoCodeName && promoCode && <CardLineItem itemKeyStyle={italicStyle} itemStyle={italicStyle} itemKey={nonMemberDiscountApplied ? `${customFields.Description.value}` : `${promoCodeName} Promo`} itemValue={<><Currency value={promoCode?.price} /></>} /> }
                        <CardLineItem itemKeyStyle={boldStyle} itemStyle={boldStyle} itemKey='Next Payment Total' itemValue={<><Currency value={nextPaymentAmount} /></>} />
                        <HorizontalRule style={hrStyle} />
                    </>}
                    { creditCardNumber && <CardLineItem itemStyle={{...paymentStyle, color: isExpired ? colors.red : colors.pureBlack }} itemKey='Payment Method' itemValue={creditCardType + ' **** **** **** ' + lastFourCardNumberDigit} /> }
                    {isExpired && <CardLineItem itemStyle={{fontWeight: 500, color: colors.red}} itemValue={`Expired ${creditCardExpiryDate}`} />}
                    <CardLineItem itemStyle={boldStyle} itemValue={<Link style={linkStyle} text={content.updatePaymentMethodLink} to='/admin/update-payment' />} />
                    <CardLineItem itemStyle={boldStyle} itemKey='Payment Term' itemValue={billingFrequency === 'MONTHLY' ? 'Monthly' : 'Annual'} />
                    {(lastPaymentAmount != null && lastPaymentAmount > 0) && 
                        <>
                            <HorizontalRule style={hrStyle} />
                            <CardLineItem itemKey='Last Payment Date' itemValue={removeLeadingZerosForSingleDigitDate(lastPaymentDate)} /> 
                            <CardLineItem itemKey='Last Payment' itemValue={<Currency value={lastPaymentAmount}/>} />
                        </>
                    }
                </CardWithTitle>
            }
        </>
    )
};

export default MemberPaymentDetails;