import React from 'react';
import styled, { css } from 'styled-components';
import { colors, mobile, desktop, desktopOnly, mobileOnly } from '../../styles/common';
import logo from '../../assets/footer_logo.png';
import Link from '../elements/Link';
import Title from '../elements/Title';
import { blogUrl, segmentAnalytics } from "../../configuration/ApplicationContext";
import AnchorLink from "../elements/AnchorLink";

const FooterStyle = styled.div`
    width: 100%;
    ${ desktop`
        padding: 4em;
        ${props => props.reducePadding && css`
            padding: 1.75em !important;
        `}
   `}
    ${mobile`
        padding: 2em;
        padding-top: 1em;
    `}
    box-sizing: border-box;
    display: flex;
    position: absolute;
    bottom: 0px;
    background: ${colors.darkBlue};
    color: ${colors.pureWhite};
    ${ mobile`
        flex-direction: column-reverse;
        position: absolute;
        bottom: 0em;
    `}
`;

const Logo = styled.div`
    width: 25%;
    ${ mobile`
        width: auto;
        margin: 0 auto;
        ${props => props.applyBottomPadding && css`
            padding-bottom: 5rem; 
       `}
    `}
   ${props => props.reducePadding && css`
        padding-bottom: 1.5rem;
   `}
`;

const Content = styled.div`
    display : flex;
    flex-direction : column;
    width: 54%;
    ${ mobile`
        width: 100%;
    `}
`;

const LinkSection = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    ${ mobile`
        justify-content: space-around;
    `}
`;

const CopyrightsSection = styled.div`
    flex: 1;
    box-sizing: border-box;
    padding-top: 2rem;
    margin: 0 auto;
    ${ mobile`
        padding: 1.5rem 0rem;
        width: 100%;
        ${props => props.reducePadding && css`
             padding-top: 1rem !important;
        `}
    `}
    ${props => props.mobileOnly && css`
      ${mobileOnly}
   `}
    ${props => props.desktopOnly && css`
      ${desktopOnly}
   `}
`;

const CopyrightsSectionLines = styled.div`
    min-width: 100%;
    font-size: 12px; 
    line-height: 18px;   
    text-align: center;
`;

const Resources = styled.div`
    text-align: left;
    ${ mobile`
        width: 50%;
        padding-left: 1.5em;
    `}
`;

const Company = styled.div`
    text-align: left;
`;

const FooterLogo = styled.img`
    ${ desktop`
        padding-left: 70px;
    `}
`;

const linkStyle = { 
    fontWeight: '200', 
    fontSize: '14px',
    lineHeight: '24px',
    color: colors.pureWhite
}

const titleStyle = {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
    marginBottom: '0px'
}

const Footer = ({ showFooterLinks, applyBottomPadding }) => {
    const {search} = window.location;
    const params = new URLSearchParams(search);
    const verificationCode = params.get('verification-code');
    const trackFaqPageClick = async () => {
        await segmentAnalytics.track("Footer | FAQs Click");
    }

    if(verificationCode) {
        return null;
    }

    return (
        <>
            <FooterStyle reducePadding={!showFooterLinks}>
                <Logo applyBottomPadding={applyBottomPadding} reducePadding={!showFooterLinks}>
                    <FooterLogo src={logo} />
                </Logo>
                <Content>
                    {showFooterLinks &&
                        <LinkSection>
                            <Resources>
                                <Title style={titleStyle}>Resources</Title>
                                <Link style={linkStyle} text="Home" to="/" />
                                {/* <Link style={linkStyle} text="Contact Us" to="/contact-us" />
                                <Link style={linkStyle} text="FAQs" onClick={trackFaqPageClick} to="/faqs" />
                                <AnchorLink style={linkStyle} text="Blog" href={blogUrl} /> */}
                                <Link style={linkStyle} text="Accessibility" to="/accessibility" />
                            </Resources>
                            <Company>
                                <Title style={titleStyle}>Company</Title>
                                <AnchorLink style={linkStyle} text="Privacy Policy" href="https://calstate.aaa.com/privacy-policy" />
                                <Link style={linkStyle} text="Terms & Conditions" to="/terms-and-conditions" />
                                <Link style={linkStyle} text="Do Not Sell or Share My Personal Information" to="/privacy-rights" />
                                <Link style={linkStyle} text="Limit the Use of My Sensitive Personal Information" to="/privacy-rights" />  
                            </Company>
                        </LinkSection>
                    }
                    <CopyrightsSection desktopOnly='true' reducePadding={!showFooterLinks}>
                        <CopyrightsSectionLines>AAA Identity Champion is a service provided by</CopyrightsSectionLines>
                        <CopyrightsSectionLines>AAA Northern California, Nevada & Utah.</CopyrightsSectionLines>
                        <CopyrightsSectionLines>©{new Date().getFullYear()} AAA Northern California, Nevada & Utah. All rights reserved.</CopyrightsSectionLines>
                    </CopyrightsSection>
                    <CopyrightsSection mobileOnly='true' reducePadding={!showFooterLinks}>
                        <CopyrightsSectionLines>AAA Identity Champion is a service provided by</CopyrightsSectionLines>
                        <CopyrightsSectionLines>AAA Northern California, Nevada & Utah.</CopyrightsSectionLines>
                        <CopyrightsSectionLines>©{new Date().getFullYear()} AAA Northern California, Nevada & Utah.</CopyrightsSectionLines>
                        <CopyrightsSectionLines>All rights reserved.</CopyrightsSectionLines>
                    </CopyrightsSection>
                </Content>
            </FooterStyle>
        </>
    )
}

export default Footer;
