import React, { Component } from 'react';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent } from '../layout/ResponsiveSignupFlowLayout';
import Title from '../elements/Title';
import Content from '../layout/Content';

const titleStyle = {
    fontSize: '24px',
    textAlign: 'center',
    marginTop: '3rem'
}

export default class AdminLogoutSuccessPage extends Component {
    render() {
        return (
            <Content desktopStyles={{marginTop: '0rem'}}>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title style={titleStyle}>{'You have logged out'}</Title>
                    </SignUpFlowFormContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

