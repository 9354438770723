import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import useCustomForm from '../../../hooks/useCustomForm';
import LabeledInput from '../../elements/LabeledInput';
import SubmitButton from '../../elements/SubmitButton';
import Input from '../../elements/Input';
import validate from '../../forms/PersonalInfoForm/validations';
import FormError from '../../elements/FormError';
import Link from '../../elements/Link';
import MaskedLabeledInput from '../../elements/MaskedLabeledInput';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import ReactMarkdown from 'react-markdown';
import AdminContext from '../../contexts/AdminContext';
import { formatDateMMDDYYYY, formatMembershipId,formatName } from '../../common/Helpers';
import { segmentAnalytics } from '../../../configuration/ApplicationContext';
import { AddressFields } from '../../common/AddressFields';
import Section from '../../elements/Section';
import DynamicParagraphText from '../../elements/DynamicParagraphText';
import Span from '../../elements/Span';
import { colors } from '../../../styles/common';

const AdminPersonalInfoForm = ({ initialValues, onSubmit, onSelectAddress, onSelectBillingAddress, onBillingAddressSameAsHomeAddress, apiErrors, isEditPersonalInfo, content, billingAddressSameAsHomeAddress, updateMembershipNumber = false, showAAAMembershipNumber, isSubmitDisabled, isNonMember, hideFieldForBackButton, customErrorMessages = {}, clearCustomError, relaxRequiredValidationsForFields = [], ...props }) => {
    const contextType = useContext(AdminContext);
    const [homeAddress, setHomeAddress] = useState({});
    const [manualAddressEntry, setManualAddressEntry] = useState(false);
    const { address, firstName, middleName, lastName, email, dob, phone, membershipId } = contextType.searchResult || {};
    const cancelSignupRoute = props.cancelRoute || '/admin/member-account';
    if (Object.keys(contextType.searchResult).length > 0) {
        initialValues = {
            firstName,
            middleName,
            lastName,
            email: email ? email : '',
            retypeEmail: '',
            homeAddress:  address ? `${address?.lineOne}, ${address?.city}, ${address?.state}, ${address?.countryCode}`:'',
            additionalHomeInfo: address?.lineTwo || '',
            additionalBillingInfo: address?.lineTwo || '',
            dob:  formatDateMMDDYYYY(dob),
            phoneNumber:  phone ,
            zipCode: address?.zipCode || '',
            lineOne: address?.lineOne || '',
            city: address?.city || '',
            state: address?.state || ''
        }
    }
    if (isEditPersonalInfo) {
        initialValues.billingAddress = `${address?.lineOne}, ${address?.city}, ${address?.state}, ${address?.countryCode}`;
    }
    if (showAAAMembershipNumber) {
        initialValues.membershipNumber = membershipId || '';
    }
    let { values, errors, touched, dirty, submitted, handleChange, handleBlur, handleSubmit } = useCustomForm({ initialValues, validate, onSubmit, relaxRequiredValidationsForFields, updateMembershipNumber, clearCustomError, homeAddress, manualAddressEntry });
    if (Object.keys(customErrorMessages).length && !Object.keys(errors).length) {
        errors = {
            ...customErrorMessages
        };
    }

    const filteredErrors = submitted ? errors : Object.keys(errors)
        .filter(key => touched[key])
        .reduce((obj, key) => {
            obj[key] = errors[key];
            return obj;
        }, {});

    const inputHandlers = { onChange: handleChange, onBlur: handleBlur, extratopspace: '0.5rem', extraTopLabelSpace: '0.75rem' };
    const [personalFormInfo, setPersonalFormInfo] = useState(values);
    const handleBlurEvent = (event) => {
        const { target } = event;
        const { name, value } = target;
        trackFieldChange(name, value);
    }

    const handlerCopyPaste = (event) => { event.preventDefault(); }

    const trackFieldChange = (fieldName, newValue) => {
        if (isNonMember) {
            trackFieldChangeEventForNonMember(fieldName, newValue);
        }
        const obj = {};
        obj[fieldName] = newValue;
        setPersonalFormInfo({ ...personalFormInfo, ...obj });
    }

    const trackFieldChangeEventForNonMember = (fieldName, value) => {
        if (!value) {
            return;
        }
        switch (fieldName) {
            case "firstName":
                segmentAnalytics.track('Sales-Tool-New-Signup-Enter-First-Name', {
                    oneLoginID: contextType.getLoginId(),
                    firstName: value
                });
                break;
            case "lastName":
                segmentAnalytics.track('Sales-Tool-New-Signup-Enter-Last-Name', {
                    oneLoginID: contextType.getLoginId(),
                    firstName: values.firstName,
                    lastName: value
                });
                break;
            case "email":
                segmentAnalytics.track('Sales-Tool-New-Signup-Enter-Email', {
                    oneLoginID: contextType.getLoginId(),
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: value
                });
                break;
            case "retypeEmail":
                segmentAnalytics.track('Sales-Tool-New-Signup-Enter-Retype-Email', {
                    oneLoginID: contextType.getLoginId(),
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: value
                });
                break;
            case "homeAddress":
                segmentAnalytics.track('Sales-Tool-New-Signup-Enter-Home-Address', {
                    oneLoginID: contextType.getLoginId(),
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: setSegmentValue(values?.email),
                    homeAddress: setSegmentValue(homeAddress),
                    state: homeAddress?.state,
                    zipCode: homeAddress?.zipCode,
                });
                break;
            case "phoneNumber":
                segmentAnalytics.track('Sales-Tool-New-Signup-Enter-Phone-Number', {
                    oneLoginID: contextType.getLoginId(),
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: setSegmentValue(values?.email),
                    homeAddress: setSegmentValue(homeAddress),
                    city: homeAddress?.city,
                    state: homeAddress?.state,
                    zipCode: homeAddress?.zipCode,
                    phoneNumber: value
                });
                break;
            case "dob":
                segmentAnalytics.track('Sales-Tool-New-Signup-Enter-Date-of-Birth', {
                    oneLoginID: contextType.getLoginId(),
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: setSegmentValue(values?.email),
                    homeAddress: setSegmentValue(homeAddress),
                    city: homeAddress.city,
                    state: homeAddress?.state,
                    zipCode: homeAddress?.zipCode,
                    phoneNumber: setSegmentValue(values?.phoneNumber),
                    dob: value
                });
                break;
            case "membershipNumber":
                segmentAnalytics.track('Sales-Tool-New-Signup-Enter-AAA-Member-Number', {
                    oneLoginID: contextType.getLoginId(),
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: setSegmentValue(values?.email),
                    homeAddress: setSegmentValue(homeAddress),
                    city: homeAddress?.city,
                    state: homeAddress?.state,
                    zipCode: homeAddress?.zipCode,
                    phoneNumber: setSegmentValue(values?.phoneNumber),
                    dob: setSegmentValue(values.dob),
                    aaaMemberNumber: value
                });
                break;
            default:
        }
    }

    const setSegmentValue = (value) => {
        return Object.keys(value).length > 0 ? value : undefined;
    }

    const formatMembershipNumber = (event) => {
        inputHandlers.onBlur(event);
        if (values.membershipNumber.length === 16) {
            values.membershipNumber = formatMembershipId(event.target.value);
        }
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (billingAddressSameAsHomeAddress) {
            if (!values.billingAddress) {
                delete errors.billingAddress
            }
        } else if (!isEditPersonalInfo) {
            delete errors.billingAddress
        }
        handleSubmit();
    }

    const selectAddress = (homeAddress) => {
        setHomeAddress(homeAddress)
        onSelectAddress(homeAddress);
    }
    const activeDobStyle = { marginBottom: '1rem' };
    const disabledDobStyle = { border: 'none', background: 'none', ...activeDobStyle };
    const dobStyle = props.disableDob === true ? disabledDobStyle : activeDobStyle;
    return (
        <form
            onSubmit={handleFormSubmit}
            noValidate
            onBlur={handleBlurEvent}
            >
            <FormError
                showErrors={submitted}
                errors={filteredErrors || !Object.keys(customErrorMessages).length}
                message="Please fill out the fields highlighted below"
            />
            <FormError
                showErrors={submitted}
                errors={apiErrors}
            />
            <LabeledInput
                type="text"
                label="Name"
                name="firstName"
                hide={firstName && hideFieldForBackButton}
                value={values.firstName}
                placeholder="First Name"
                maxLength={31}
                error={filteredErrors.firstName}
                {...inputHandlers}
            />
            <Input
                type="text"
                label="Middle Name"
                placeholder="Middle (Optional)"
                hide={firstName && hideFieldForBackButton}
                name="middleName"
                value={values.middleName}
                maxLength={31}
                error={filteredErrors.middleName}
                {...inputHandlers}
            />
            <Input
                type="text"
                label="Last Name"
                name="lastName"
                hide={firstName && hideFieldForBackButton}
                value={values.lastName}
                placeholder="Last Name"
                maxLength={31}
                error={filteredErrors.lastName}
                {...inputHandlers}
            />
            { 
                firstName && isNonMember &&
                <LabeledInput 
                    label="Name" 
                    placeholder="Name"
                    style={{ border: 'none', background: 'none' }}
                    type="text"
                    name="name" 
                    disabled={true} 
                    value={formatName(firstName, middleName, lastName)}
                    {...inputHandlers}
                 />
            }
            {
                props.showLoginEmail === true &&
                <LabeledInput
                    label="Login Email"
                    style={{ border: 'none', background: 'none' }}
                    type="text"
                    name="loginEmail"
                    disabled={true}
                    value={props.loginEmail}
                    error={filteredErrors.email}
                    {...inputHandlers}
                />
            }
            <LabeledInput
                label={
                    props.showLoginEmail === true 
                        ? 'Email to Receive Communications (Defaults to: Same as Login Email)'
                        : 'Email'
                }
                type="email"
                name="email"
                value={values.email}
                error={filteredErrors.email}
                warning={isNonMember && email && email != values.email ? true : false }
                inlineMessage={
                    isNonMember && email && email != values.email &&
                    <DynamicParagraphText
                        style={{ color: colors.darkRed }}
                        text={
                            <>
                                <Span text={`WARNING: If you change this login email from `} />
                                <Span text={email} style={{ fontWeight: 'bold' }} />
                                <Span text={`, this will create another new Keycloak account.`} />
                            </>
                        }
                    />}
                {...inputHandlers}
                />
                 <LabeledInput
                label="Retype Email"
                name="retypeEmail"
                hide={!dirty.email}
                value={values.retypeEmail}
                error={filteredErrors.retypeEmail}
                onPaste={handlerCopyPaste}
                {...inputHandlers}
            />
            <AddressFields
                values={values}
                filteredErrors={filteredErrors}
                {...inputHandlers}
                onSelectAddress={selectAddress}
                onBillingAddressSameAsHomeAddress={onBillingAddressSameAsHomeAddress}
                billingAddressSameAsHomeAddress={billingAddressSameAsHomeAddress}
                isEditPersonalInfo={isEditPersonalInfo}
                manualAddressEntry={manualAddressEntry}
                manualAddressLinkShow={true}
                onSelectBillingAddress={onSelectBillingAddress}
                setManualAddressEntry={setManualAddressEntry}
            />
            <MaskedLabeledInput
                mask="999-999-9999"
                maskChar=""
                label="Phone Number"
                type="text"
                name="phoneNumber"
                value={values.phoneNumber}
                error={filteredErrors.phoneNumber}
                {...inputHandlers}
            />
            <LabeledInput
                style={dobStyle}
                label="Date of Birth"
                disabled={
                    props.disableDob === true
                        ? true
                        : false
                }
                type="text"
                name="dob"
                tooltipIcon={faQuestionCircle}
                value={values.dob}
                error={filteredErrors.dob}
                placeholder="MM/DD/YYYY"
                {...inputHandlers}
            />
            {
                showAAAMembershipNumber &&
                <LabeledInput
                    type="text"
                    label="AAA Membership Number"
                    hide={membershipId && isNonMember}
                    name="membershipNumber"
                    value={values.membershipNumber}
                    placeholder="AAA Membership Number"
                    error={filteredErrors.membershipNumber}
                    {...inputHandlers}
                    onBlur={formatMembershipNumber}
                />
            }
             { 
               showAAAMembershipNumber && membershipId && isNonMember &&
                <LabeledInput 
                    label="AAA Membership Number" 
                    placeholder="AAA Membership Number"
                    style={{ border: 'none', background: 'none' }}
                    type="text"
                    name="membershipNumber" 
                    disabled={true} 
                    value={formatMembershipId(membershipId)}
                    error={filteredErrors.membershipNumber}
                    {...inputHandlers}
                 />
            }
             {
                props?.showBottomText && isNonMember &&
                <Section
                text={content.jsonContent.disclaimers.line1.text}
                style={{fontSize: '17px', color: content.jsonContent.disclaimers.line1.color,fontWeight:'bold',
                paddingTop: 30,
                paddingBottom: 0.1}}
            />
            }
            {
                props?.showBottomText && isNonMember &&
                <Section
                text={content.jsonContent.disclaimers.line2.text}
                style={{fontSize: '17px', color: '#003087', paddingTop: 0.1,
                paddingBottom: 10}}    
            />
            }
            {
                props?.showBottomText &&
                <ReactMarkdown
                    className="react-mark-down"
                    source={content.buttomText}
                    renderers={
                        {
                            link: props =>
                                <a
                                    href={props.href}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {props.children}
                                </a>
                        }
                    }
                />
            }
            <SubmitButton
                text="Submit Personal Information"
                disabled={isSubmitDisabled}
            />
            <Link
                extratopspace="2rem"
                extraleftspace="5.5rem"
                to={cancelSignupRoute}
                text='Cancel Signup'
            />
        </form >
    );
};

AdminPersonalInfoForm.propTypes = {
    initialValues: PropTypes.shape({})
};

AdminPersonalInfoForm.defaultProps = {
    initialValues: {
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        retypeEmail: '',
        homeAddress: '',
        billingAddress: '',
        additionalHomeInfo: '',
        additionalBillingInfo: '',
        phoneNumber: '',
        dob: ''
    }
};

export default AdminPersonalInfoForm;
