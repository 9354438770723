import React from 'react';
import styled from 'styled-components';
import ProductOffering from './products/ProductOffering';
import Content from './layout/Content';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from './layout/ResponsiveSignupFlowLayout';
import ContentfulIds from '../configuration/ContentfulIds';
import Summary from './summary/Summary';
import Spinner from './elements/Spinner';
import Card from './elements/Card';
import DataContext from './contexts/DataContext';
import BaseProductOffering from './BaseProductOffering';
import withPageTitle from "./layout/withPageTitle";
import Breadcrumbs from "./common/Breadcrumbs";
import { createSummaryItems } from './summary/SummaryItems';
import { AAA_MEMBER, CANCELLED, COMPLETE, NON_AAA, UPGRADE } from './common/Constants';
import ProductChart from './v1/product-chart/customer/ProductChart';
import { colors, mobile } from '../styles/common';
import Section from './elements/Section';
import HorizontalRule from './elements/HorizontalRule';
import RichText from './elements/RichText';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import DynamicParagraphText from './elements/DynamicParagraphText';
import { removeRedirectValueFromLocalStorage } from './common/Helpers';

const ProductChartContainer = styled.div`
    padding-bottom: 40px;
    ${mobile`	
        margin-top: -20px;	
    `}   
`;

const styles = {
    title: { textAlign: 'center', fontSize: '17px', fontWeight: 500 },
    hr: { backgroundColor: colors.pureBlack, marginBottom: '20px' },
    paragraph: { textAlign: 'justify', margin: 0, color: colors.verylightGrey, lineHeight: '20px', paddingTop: '1rem' }
}
const mobileStyles = {
    paragraph: { fontStyle: 'italic', fontSize: '14px', lineHeight: '22px', paddingBottom: '2rem' }
};
const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText
    {...props}
    text={children}
    style={styles.paragraph}
/>;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) =>
            <ParagraphStyle
                anchor={true}
                desktopFontSize='12px'
                mobileStyles={mobileStyles.paragraph}
            >
                {children}
            </ParagraphStyle>,
        [INLINES.HYPERLINK]: (node, next) => {
            return <a
                href={node.data.uri}>
                {node.content[0].value}
            </a>;
        }
    },
};

class PaidProductUpgradePage extends BaseProductOffering {
    static contextType = DataContext;
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            selectedProduct: null,
            paymentMode: '',
            productPageURL: '/product/protect/upgrade',
            checkoutPageURL: '/upgrade/product/checkout',
            updateCoveragePage: true
        };
    }

    componentDidMount() {
        this.setFlowValueInRedirectParamsInSessionStorage(UPGRADE);
        removeRedirectValueFromLocalStorage();
        this.checkUserStateAndRedirect();
        this.setBreadCrumbs();
        this.loadPlans(ContentfulIds.MyAccountIds.UpgradeCompletePlanPage);
        this.loadAndFilterProductChartContent(ContentfulIds.CustomerCompareOurPlans);
        this.applyPromoCodeFromSessionStorage();
    }

    checkUserStateAndRedirect() {
        const { subscription } = this.context.userDetails || {};
        if (!subscription) {
            this.props.history.push('/product/');
        } else if (subscription.productType === 'FREE') {
            this.props.history.push('/my-account');
        }
    }

    setBreadCrumbs = () => {
        const breadCrumbRoutes = [
            {
                to: `/product/protect/upgrade/${this.props.match.params.id}`, label: 'Upgrade'
            }
        ];
        this.setState({
            breadCrumbRoutes
        });
    }

    render() {
        const {
            products,
            paymentMode,
            selectedProduct,
            breadCrumbRoutes,
            content,
            updateCoveragePage,
            productChartContent,
            isPromoCodeProcessing
        } = this.state;
        const { subscription, membership } = this.context.userDetails;
        let zuoraRatePlansURL = '/api/products/zuora-rate-plans';
        if (!this.context.userDetails.membership) {
            zuoraRatePlansURL += '?isNonAaaMember=true';
        }
        if (!selectedProduct || !content || isPromoCodeProcessing) {
            return <Spinner />;
        }
        return (
            <>
                <Breadcrumbs
                    breadCrumbRoutes={breadCrumbRoutes}
                />
                <Content>
                    <ResponsiveSignupFlowLayout>
                        <SignUpFlowFormContent>
                            <ProductOffering
                                products={products}
                                selectedProduct={selectedProduct}
                                content={content} onSelectProduct={this.onSelectProduct}
                                paymentMode={paymentMode} onSelectPaymentMode={this.onSelectPaymentMode}
                                onSubmit={this.onSubmit} updateCoveragePage={updateCoveragePage} hideSelectProduct={true}
                                subscription={subscription}
                                hidePromoCode={true}
                                isNonAaaMember={!membership}
                                onDeclineUpgrade={this.onDeclineUpgrade} >
                            </ProductOffering>
                        </SignUpFlowFormContent>
                        <SignUpFlowExtraContent>
                            <Card>
                                <Summary
                                    product={selectedProduct}
                                    title={`If you upgrade to ${selectedProduct.name}`}
                                    flags={{ showRefundAmount: true, showBottomSection: true }}
                                    items={
                                        createSummaryItems(selectedProduct,
                                            this.context,
                                            {
                                                showRefundAmount: true,
                                                showBottomSection: true
                                            }
                                        )}
                                />
                            </Card>
                        </SignUpFlowExtraContent>
                    </ResponsiveSignupFlowLayout>
                    <ProductChartContainer>
                        <HorizontalRule
                            style={styles.hr}
                            mobileonly='true'
                        />
                        <Section
                            mobileOnly='true'
                            text='Compare Plans'
                            style={styles.title}
                        />
                        <ProductChart
                            showDescription={false}
                            showTitle={false}
                            backgroundcolor={colors.pureWhite}
                            fields={productChartContent}
                            defaultMembership={membership !== null 
                                && membership?.membershipStatus !== CANCELLED ? AAA_MEMBER : NON_AAA}
                            hideToggleSection={true}
                            alreadySelectedProduct={COMPLETE}
                            disableCta={true}
                        />
                        <RichText
                            text={productChartContent.joinAaaText}
                            options={options}
                        />
                    </ProductChartContainer>
                </Content>
            </>
        );
    }
}

export default withPageTitle(PaidProductUpgradePage, "Upgrade");
