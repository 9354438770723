import React from 'react';
import styled from 'styled-components';
import { colors, mobile } from '../../../../styles/common';
import ToggleSwitch from '../../../elements/ToggleSwitch';
import Span from '../../../elements/Span';
import { AAA_MEMBER, NON_AAA } from '../../../common/Constants';

const ToggleButtonSection = styled.div`
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    ${mobile`
        background: ${colors.pureWhite}
    `}
`;
const ToggleButtonData = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
`;
const styles = {
    toggleInput: {
        selected: { fontWeight: 'bold', color: colors.darkBlue },
        notSelected: { color: colors.pureBlack }
    }
};
const desktopStyles = {
    toggleInput: {
        toggleInputCheckedSpanBefore: { left: '41px' },
        sliderSpan: { width: '77px', height: '42px' },
        sliderSpanBefore: { width: '34px', height: '34px', top: '4px' },
        aaaMemberText: { fontSize: '23px', lineHeight: '30px' },
        aaaMemberDescriptionText: { fontSize: '14px', lineHeight: '30px' },
        notAaaMemberText: { fontSize: '23px', lineHeight: '30px' }
    }
};
const mobileStyles = {
    toggleInput: {
        aaaMemberText: { fontSize: '18px', lineHeight: '30px' },
        aaaMemberDescriptionText: { fontSize: '10px' },
        notAaaMemberText: { fontSize: '18px', lineHeight: '22px' },
        toggleInputCheckedSpanBefore: { left: '27px' },
        sliderSpan: { width: '59px', height: '36px' },
        sliderSpanBefore: { width: '28px', height: '28px', top: '4px' },
    }
};

const ProductChartToggleButtonSection = ({ fields, ...props }) => {
    const {
        memberType,
        setMemberType
    } = props || {};
    const onToggleChange = (event) => {
        if (event.target.checked) {
            setMemberType(NON_AAA);
        } else {
            setMemberType(AAA_MEMBER);
        }
    }

    const onMemberTypeTextClick = (memberType) => {
        setMemberType(memberType);
    }

    return (
        <ToggleButtonSection>
            <ToggleButtonData
                style={memberType === AAA_MEMBER ? styles.toggleInput.selected : styles.toggleInput.notSelected}
                onClick={() => onMemberTypeTextClick(AAA_MEMBER)}>
                <Span
                    text="AAA Member"
                    desktopStyles={desktopStyles.toggleInput.aaaMemberText}
                    mobileStyles={mobileStyles.toggleInput.aaaMemberText}
                />
                <Span
                    text="(Save 10% on paid plans)"
                    desktopStyles={desktopStyles.toggleInput.aaaMemberDescriptionText}
                    mobileStyles={mobileStyles.toggleInput.aaaMemberDescriptionText}
                />

            </ToggleButtonData>
            <ToggleSwitch
                isChecked={memberType === AAA_MEMBER ? false : true}
                desktoptoggleinputcheckedspanbeforestyle={desktopStyles.toggleInput.toggleInputCheckedSpanBefore}
                desktopsliderspanstyle={desktopStyles.toggleInput.sliderSpan}
                desktopsliderspanbeforestyle={desktopStyles.toggleInput.sliderSpanBefore}
                mobiletoggleinputcheckedspanbeforestyle={mobileStyles.toggleInput.toggleInputCheckedSpanBefore}
                mobilesliderspanstyle={mobileStyles.toggleInput.sliderSpan}
                mobilesliderspanbeforestyle={mobileStyles.toggleInput.sliderSpanBefore}
                onChange={onToggleChange}
            />
            <ToggleButtonData
                style={memberType === NON_AAA ? styles.toggleInput.selected : styles.toggleInput.notSelected}
                onClick={() => onMemberTypeTextClick(NON_AAA)}>
                <Span
                    text="Not a"
                    desktopStyles={desktopStyles.toggleInput.notAaaMemberText}
                    mobileStyles={mobileStyles.toggleInput.notAaaMemberText}
                />
                <Span
                    text="AAA Member"
                    desktopStyles={desktopStyles.toggleInput.notAaaMemberText}
                    mobileStyles={mobileStyles.toggleInput.notAaaMemberText}
                />
            </ToggleButtonData>
        </ToggleButtonSection>
    );
};

export default ProductChartToggleButtonSection;