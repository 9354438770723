import React from 'react';
import styled from 'styled-components';
import Span from '../elements/Span';
import { mobile } from '../../styles/common';

const LineItemStyleComponent = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
    ${mobile`
        font-size: 14px;
    `}
`;

const LineItem = (props) => {
    return (
        <LineItemStyleComponent style={props.style}>
            <Span text={props.itemKey}></Span>
            <Span style={props.itemStyle} text={props.itemValue}></Span>
        </LineItemStyleComponent>
    );
  };

export default LineItem;