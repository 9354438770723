import React from 'react';
import styled, {css} from 'styled-components';
import {colors, commonPropStyles, desktopOnly, mobileOnly} from '../../styles/common';
import { mobile, desktop } from '../../styles/common';
import {Link as RouterLink} from 'react-router-dom';

const LinkStyleComponent = styled(RouterLink)`
  font-size: 17px;
  color: ${colors.darkBlue};
  font-weight: 500;
  text-decoration: none;
  display: block;
  margin-top: ${props => props.extratopspace};
  cursor:pointer;
  margin-bottom: ${props => props.extrabottomspace};
  ${props => props.extraleftspace && css`
    ${desktop`
      margin-left: ${props.extraleftspace};
    `}
  `};
  ${props => props.textalign && css`
    ${mobile`
      text-align: ${props.textalign};
    `}
  `};
  ${props => props.mobileOnly && css`
    ${mobileOnly}
  `}
  ${props => props.desktopOnly && css`
    ${desktopOnly}
  `}
  ${commonPropStyles.mobile}
  ${commonPropStyles.desktop}
`;

const Link = ({text, ...props}) => {
  return (
      <LinkStyleComponent {...props}>{text}</LinkStyleComponent>
  )
}

export default Link;