import React from 'react';
import styled, { css } from 'styled-components'
import { colors } from '../../styles/common'
import { mobile } from '../../styles/common';

const CardWithTitleStyleComponent = styled.div`
    border: 1px solid ${colors.borderGrey};
    box-sizing: border-box;
    margin-bottom: 1rem;
    margin-right: 0.75rem;
    ${props => props.marginTop && css`
        margin-top: ${props.marginTop};
    `}
    ${props => props.showRedBorder && css`
        border: 1px solid ${colors.red};
    `}
    flex: 1 0 32%;
    :last-of-type { flex-grow: 0; }
    ${props => props.firstGrow && css`
        flex: 0 1 auto;
        align-self: flex-start;
        width: 32%;
        ${mobile`
            width: 100%;
        `}
    `}
    ${props => props.textalign && css`
        text-align: ${props.textalign};
    `}
    ${props => props.backgroundcolor && css`
        background: ${props.backgroundcolor};
    `}
    ${props => props.border && css`
        border: ${props.border}
    `}
`;

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    background: ${colors.backgroundGrey};
    color: ${colors.titleText};
    ${props => props.showWhiteBackgroundWithRedText && css`
        background: ${colors.pureWhite};
        color: ${colors.red};
        font-size: 17px;
        justify-content: center;
    `}
    ${mobile`
        font-size: 17px;
        line-height: 25px;
    `}
`;

const Data = styled.div`
    padding: 1.25rem;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 25px;
    ${mobile`
        font-size: 14px;
    `}
`;

const CardWithTitle = ({ title, children, callToAction, marginTop, showtitle = true, ...props }) => {
    return (
        <CardWithTitleStyleComponent marginTop={marginTop} {...props}>
            {showtitle === true && <Title {...props}>{title}{callToAction}</Title>}
            <Data>{children}</Data>
        </CardWithTitleStyleComponent>
    );
};

export default CardWithTitle;
