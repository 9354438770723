
import React from "react";
import { mobile } from './../../styles/common';
import { colors } from "./../../styles/common";
import styled, { css } from 'styled-components';
import faqbullet from './../../assets/faq-bullet.png';
import {
  AccordionItemButton,
  AccordionItemHeading,
} from 'react-accessible-accordion';
import { getPromoCodeNameFromRedirectParams } from "../common/Helpers";

const AccordionItemArrow = styled.span`
    font-weight: 500;
    font-size: 18px;
    padding-right: 8px;
    ${mobile`
       padding-left: 4px;
       position: 'sticky';
       font-size: 15px;
    `}
`;

const AccordionItemButtonStyled = styled.div`
    font-weight: 500;
    font-size: 18px;
    display: flex;
    padding-top: 0.5rem;
    ${mobile`
       font-size: 15px;
       padding-top: 1.5rem;
       background: ${colors.lightGrey};
       ${props => props.questionIdParam === props.questionId && css`
       padding-bottom: 0.6rem;
       `}
    `}
`;

const accordionItemStyle = {
  fontWeight: '500',
  paddingBottom: '0rem',
  color: `${colors.darkBlue}`,
  outline: 'none'
}

const AccordionItemHeadingStyled = styled.div`
     cursor: pointer;
       ${mobile`
          top: ${props => props.mobiletop};
         ${props => props.questionIdParam === props.questionId && css`
          position: sticky;
          background: ${colors.lightGrey};
        `}
      `}
`;

const FaqQuestionsItem = ({ questionIdParam, question }) => {
  const promoCodeName = getPromoCodeNameFromRedirectParams();
  return (
    <AccordionItemHeadingStyled
      mobiletop={promoCodeName ? '10rem' : '3rem'}
      questionIdParam={questionIdParam}
      questionId={`${question}`}>
      <AccordionItemHeading>
        <AccordionItemButton
          style={accordionItemStyle}>
          <AccordionItemButtonStyled
            questionIdParam={questionIdParam}
            questionId={`${question}`}>
            <AccordionItemArrow>
              <img
                style={{ transform: (questionIdParam === `${question}`) ? 'rotate(90deg)' : 'rotate(360deg)' }}
                src={faqbullet}
                alt={faqbullet}
              />
            </AccordionItemArrow>
            {question?.fields?.title}
          </AccordionItemButtonStyled>
        </AccordionItemButton>
      </AccordionItemHeading>
    </AccordionItemHeadingStyled>
  );
};

export default FaqQuestionsItem;