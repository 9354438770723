import React from "react";
import styled from 'styled-components';
import FaqDetails from './FaqDetails';
import JoinNowSection from './../JoinNowSection';
import FaqHeader from './FaqHeader';
import RichText from "../elements/RichText";

const MarketingFaq =styled.div`
    width:100%;
    flex:1;
    height:auto;
`;

const MarketingFaqSections = ({content, currentCategory ,questionIdParam,text, ...props}) => {
    return (
        <MarketingFaq>
            <FaqHeader content={content} />
            <FaqDetails {...props} content={content} currentCategory={currentCategory}
             questionIdParam={`${questionIdParam}`} selectQuestion={props.selectQuestion} 
             selectCategory={props.selectCategory} />
        </MarketingFaq>       
        
    );
  };

  export default MarketingFaqSections;