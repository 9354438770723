import React from "react";
import styled from "styled-components";
import Title from "../elements/Title";
import { colors, mobile } from "../../styles/common";
import Section from '../elements/Section';

const MemberServiceDescriptionSection = styled.div`
  padding: 5.5rem 2.5rem 0rem 0.5rem;
  ${ mobile`
      padding: 1rem 1.5rem 0rem 1.5rem;
   `} 
`;

const mobileTitleStyle = {
    fontSize: "24px",
    lineHeight: "29px",
    letterSpacing: '0.01em',
    margin: '0px auto',
    marginTop: '0.5rem',
    width: '100%'
};
  
const desktopTitleStyle = {
    fontSize: "36px",
    lineHeight: "48px",
    letterSpacing: '1px',
    marginBottom: '0px',
    marginTop: '0.5rem',
    width: '70%'
};

const descriptionStyle = {
    fontSize: '20px',
    lineHeight: '30px',
    color: `${colors.myrtleColor}`,
}
  
const mobileDescriptionStyles = {
    fontSize: '14px',
    lineHeight: '24px',
    paddingTop: '0.5rem',
    color: `${colors.myrtleColor}`
}

const AaaIconStyle = styled.img`
     ${ mobile`
       width: 40%;
       margin-top: -72px;
    `}
     width: 23%;
`;

const MemberServiceDescription = ({...props}) => {
    const {title, description, src} = props;
    return (
        <MemberServiceDescriptionSection>
          <AaaIconStyle src={src} />
          <Title mobileStyles={mobileTitleStyle} desktopStyles={desktopTitleStyle} style={{fontWeight: "normal", color: `${colors.darkBlue}`}}>{title}</Title>
          <Section desktopStyles={descriptionStyle} mobileStyles={mobileDescriptionStyles} text={description} />
        </MemberServiceDescriptionSection>
    );
  };
  
  export default MemberServiceDescription;