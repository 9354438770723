 import React from 'react';
 
 const MembershipNotification =(apiError)=>{
        const membershipErr = apiError.message.split('?');
        const params = new URLSearchParams(membershipErr[1]);
        const membershipLastName = params.get("membershipLastName");
        const membershipZipCode = params.get("membershipZipCode");
        const notifications = [{
            showNotification: true,
            message:membershipErr&& membershipLastName&& membershipZipCode?
            <div>{membershipErr[0]}<div>Membership last name: {membershipLastName}</div><div>Membership zip code: {membershipZipCode}</div></div>:apiError.message,
            hideCancelIcon: true,
            severity: 'error',
            displayTextAsString: true
        }];
        return notifications;
    }

    export {MembershipNotification}


