import React from 'react';
import styled from 'styled-components';
import {desktop, mobile} from '../../styles/common';

const ResponsiveIncludedPageFlowLayoutContainer = styled.div`
    display: flex;
`;

const ResponsiveIncludedPageContent = styled.section`
    flex: 1;
    ${desktop`
        padding: 0px 50px 0px 0px;
    `}
    box-sizing: border-box;
    ${mobile`
       width: 100%;
    `}
`;

const ResponsiveIncludedPageExtraContent = styled.section`
    flex: 1;
    min-width: 300px;
    max-width: 700px;
    ${mobile`
       display: none;
    `}
`;

const ResponsiveIncludedPageLayout  = ({children}) => {
    return (
        <ResponsiveIncludedPageFlowLayoutContainer>
            {children}
        </ResponsiveIncludedPageFlowLayoutContainer>
    )
};

export {ResponsiveIncludedPageContent, ResponsiveIncludedPageExtraContent};
export default ResponsiveIncludedPageLayout;
