import React, { useState, useContext, useEffect } from 'react';
import Content from '../../layout/Content';
import AdminContext from '../../contexts/AdminContext';
import Spinner from '../../elements/Spinner';
import { colors } from '../../../styles/common';
import styled, { css } from 'styled-components';
import { formatPhoneNumber, formatMembershipId, convertStringToDateAndFormat, capitalizeFirstLetter } from '../../common/Helpers';
import Span from '../../elements/Span';
import { useHistory } from 'react-router-dom';
import AdminNotificationContext from '../../contexts/AdminNotificationContext';
import { BASIC, CANCELLED, COMPLETE_UPPER_CASE, FREE_UPPER_CASE, NONE } from '../../common/Constants';
import RichText from '../../elements/RichText';
import Link from '../../elements/Link';
import DynamicParagraphText from '../../elements/DynamicParagraphText';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { ContentfulService } from '../../../configuration/ApplicationContext';
import ContentfulIds from '../../../configuration/ContentfulIds';
import { ADMIN, trackEvent } from '../../../services/SegmentService';

const Table = styled.table`
    width: 100%;
    border: 1px solid ${colors.silver};
`;

const Header = styled.thead`
    background: ${colors.searchResultsHeaderColor}
`;

const HeaderRow = styled.tr`
    background: ${colors.searchResultsHeaderColor}
`;

const HeaderElement = styled.th`
    color: ${colors.pureWhite};
    font-size: 1rem;
    padding: 5px;
    text-align: left;
`;

const Body = styled.tbody`
`;

const Row = styled.tr`
    :nth-child(odd) {
        background: ${colors.pureWhite}
    }
    :nth-child(even) {
        background: ${colors.searhRowBackgroundColor}
    }
    cursor: pointer;
    vertical-align: top;
`;

const RowElement = styled.td`
    color: ${colors.pureBlack};
    font-size: 1rem;
    padding: 5px;
    text-align: left;
    ${(props) =>
        props.outsideMWGClub && css`
      color: ${colors.outGrey};
    `};
`;

const LineText = styled.div`
     padding-bottom: 0.3rem;
`;

const styles = {
    content: { marginTop: "1.875rem", marginBottom: "3rem" },
    membership: { color: colors.successMessage, fontWeight: 500 },
    outsideMWGClub: { color: colors.outGrey },
    errorMessage: { color: colors.darkRed, fontWeight: 500 },
    link: { marginTop: "3rem", width: '50%', fontWeight: '500', textAlign: 'left' },
    paragraph: { marginTop: '2rem' }
};

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle
            desktopFontSize='16px'
            mobileFontSize='14px'>
            {children}
        </ParagraphStyle>,
        [INLINES.HYPERLINK]: (node, next) => {
            return <Link
                style={{ display: 'inline' }}
                to={node.data.uri}
                text={node.content[0].value}
            />;
        }
    },
};

const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;

const SearchResults = () => {
    const context = useContext(AdminContext);
    const notificationContext = useContext(AdminNotificationContext);
    const [content, setContent] = useState(null);
    const history = useHistory();

    useEffect(() => {
        async function loadContent() {
            const response = await ContentfulService.getEntry(ContentfulIds.Admin.SearchResult);
            setContent(response.fields);
        }
        loadContent();
    }, []);

    const formatAddress = (address) => (
        <>
            <LineText>{address?.lineOne || ""}</LineText>
            <LineText>{address?.lineTwo || ""}</LineText>
            <LineText>
                {`${address?.city || ""}, ${address?.state || ""} 
                ${address?.zipCode || ""}`}
            </LineText>
        </>
    );

    const processRecord = async (searchResult) => {
        context.updateState({ memberDetails: null });
        context.updateState({ searchResult: searchResult });
        localStorage.setItem("searchResult", JSON.stringify(searchResult));
        if (searchResult?.membershipId
            || searchResult?.idcMembership !== NONE
            || searchResult?.idcMembership?.toUpperCase() === CANCELLED) {
            history.push("/admin/member-account");
        } else {
            history.push("/admin/personal-info");
        }
        await context.getMemberDetails();
        await notificationContext.getNotifications();
    };

    const processNonAAAMember = () => {
        trackEvent(ADMIN.SALES_TOOL_START_NEW_SIGN_UP, {
            oneLoginID: context.loginId
        });
        localStorage.removeItem('searchResult');
        context.updateState({ searchResult: {} });
    }

    if (!content || context.isSearching) {
        return <Spinner />;
    }
    return (
        <Content
            style={styles.content}
        >
            {context.searchResults.length > 0 && (
                <Table cellSpacing="0">
                    <Header>
                        <HeaderRow>
                            <HeaderElement>Name</HeaderElement>
                            <HeaderElement>Address</HeaderElement>
                            <HeaderElement>Contact Info</HeaderElement>
                            <HeaderElement>DoB</HeaderElement>
                            <HeaderElement>Memberships</HeaderElement>
                            <HeaderElement>Alerts / Notes</HeaderElement>
                        </HeaderRow>
                    </Header>
                    <Body>
                        {
                            context.searchResults.map((searchResult, index) => {
                                return (
                                    <Row key={index} onClick={() => processRecord(searchResult)}>
                                        <RowElement
                                            outsideMWGClub={!searchResult?.isClubSupportedForZip}>
                                            {searchResult.displayName}
                                        </RowElement>
                                        <RowElement
                                            outsideMWGClub={!searchResult?.isClubSupportedForZip}>
                                            {searchResult?.address
                                                ? formatAddress(searchResult?.address)
                                                : NONE
                                            }
                                        </RowElement>
                                        <RowElement
                                            outsideMWGClub={!searchResult?.isClubSupportedForZip}>
                                            {
                                                searchResult?.phone && (
                                                    <LineText>
                                                        {searchResult?.phone &&
                                                            formatPhoneNumber(searchResult?.phone)
                                                        }
                                                    </LineText>
                                                )
                                            }
                                            {
                                                searchResult?.email && (
                                                    <LineText>{searchResult?.email}</LineText>
                                                )
                                            }
                                            {
                                                searchResult?.loginEmail && (
                                                    <LineText>{searchResult?.loginEmail}</LineText>
                                                )
                                            }
                                        </RowElement>
                                        <RowElement
                                            outsideMWGClub={!searchResult?.isClubSupportedForZip}>
                                            {
                                                searchResult?.dob
                                                    ? convertStringToDateAndFormat(searchResult?.dob)
                                                    : NONE
                                            }
                                        </RowElement>
                                        <RowElement
                                            outsideMWGClub={!searchResult?.isClubSupportedForZip}>
                                            <LineText>
                                                {searchResult?.membershipId && (
                                                    <Span
                                                        style={
                                                            searchResult?.invalidMembershipNumber || searchResult?.membershipStatus === CANCELLED
                                                                ? styles.errorMessage
                                                                : null
                                                        }
                                                        text={`AAA #: ${formatMembershipId(
                                                            searchResult?.membershipId
                                                        )}`}
                                                    />
                                                )}
                                                {!searchResult?.membershipId && (
                                                    <Span
                                                        style={styles.membership}
                                                        text="AAA #: None"
                                                    />
                                                )}
                                            </LineText>
                                            {searchResult?.idcMembership === COMPLETE_UPPER_CASE && (
                                                <LineText>
                                                    IDC Membership: {capitalizeFirstLetter(
                                                        searchResult?.idcMembership !== NONE
                                                            ? searchResult?.idcMembership
                                                            : NONE
                                                    )}
                                                </LineText>
                                            )
                                            }
                                            {
                                                searchResult?.idcMembership !== COMPLETE_UPPER_CASE && (
                                                    <LineText
                                                        style={
                                                            searchResult?.isClubSupportedForZip
                                                                ? styles.membership
                                                                : styles.outsideMWGClub
                                                        }
                                                    >
                                                        IDC Membership: {capitalizeFirstLetter(
                                                            searchResult?.idcMembership === FREE_UPPER_CASE
                                                                ? BASIC
                                                                : searchResult?.idcMembership !== NONE
                                                                    ? searchResult?.idcMembership
                                                                    : NONE
                                                        )}
                                                    </LineText>
                                                )
                                            }
                                        </RowElement>
                                        <RowElement>
                                            {
                                                searchResult?.lowConfidenceActivationPending && (
                                                    <LineText style={styles.errorMessage}>
                                                        Migration Activation Incomplete
                                                    </LineText>
                                                )
                                            }
                                            {
                                                searchResult?.invalidMembershipNumber && (
                                                    <LineText style={styles.errorMessage}>
                                                         AAA Membership Invalid
                                                    </LineText>
                                                )
                                            }
                                            {
                                                searchResult?.membershipStatus?.toUpperCase() === CANCELLED && (
                                                    <LineText style={styles.errorMessage}>
                                                        AAA Membership Cancelled
                                                    </LineText>
                                                )
                                            }
                                            {
                                                !searchResult?.email && !searchResult?.loginEmail && (
                                                    <>
                                                        <LineText>
                                                            No known e-mail address
                                                        </LineText>
                                                        <LineText>
                                                            Click record to add address.
                                                        </LineText>
                                                    </>
                                                )
                                            }
                                        </RowElement>
                                    </Row>
                                );
                            })
                        }
                    </Body>
                </Table>
            )}
            <RichText
                text={content.description}
                options={options}
            />
            <DynamicParagraphText
                style={styles.paragraph}
                text={
                    <RichText
                        text={content.subDescription}
                        options={options}
                    />
                }
            />
            <Link
                style={styles.link}
                to='/admin/personal-info-non-member'
                text={content.linkText}
                textalign="center"
                onClick={processNonAAAMember}
            />
            <DynamicParagraphText
                desktopFontSize='16px'
                mobileFontSize='14px'
                text={content.footerDescription}
            />
        </Content>
    );
};

export default SearchResults;
