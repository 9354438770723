import React, {Component} from 'react';
import {ContentfulService, segmentAnalytics} from '../configuration/ApplicationContext';
import ContentfulIds from '../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, {SignUpFlowFormContent, SignUpFlowExtraContent} from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Title from './elements/Title';
import ReactMarkdown from 'react-markdown';
import Spinner from './elements/Spinner';
import Link from './elements/Link';
import DataContext from './contexts/DataContext';
import SubmitButton from './elements/SubmitButton';
import StringPlaceholderService from '../services/StringPlaceholderService';
import moment from 'moment';
import { capitalizeFirstLetter } from './common/Helpers';
import withPageTitle from "./layout/withPageTitle";
import Breadcrumbs from './common/Breadcrumbs';

const styles = {
    desktopTitle: { marginTop: '0' },
    mobileTitle: { marginTop: '3rem' },
    link: { marginTop:'1rem', color:'#0E368D' }
}
class PaymentDuePage extends Component {
    static contextType = DataContext;
    constructor(props) {
        super(props);
        this.state = {
        };  
    }
    
    componentDidMount() {
        this.loadContent()
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.MyAccountPaymentDuePage);
        this.setState({
            content: response.fields,
        })
        this.loadDynamicContent(response.fields);
    }

    loadDynamicContent(content) {
        const {dueDate, outstandingBalance, coverageDaysLeft, coverageDateUpTo, productType} = this.context.userDetails.subscription || {};
        const {creditCardType, creditCardNumber }  = this.context.userDetails.subscription.defaultPaymentMethod || {};
        const properties = {
            dueDate: moment(dueDate)?.format('M/D/YYYY'),
            outstandingBalance: outstandingBalance?.toFixed(2),
            creditCardType,
            creditCardNumber: creditCardNumber?.substr(creditCardNumber?.length - 4),
            coverageDaysLeft,
            coverageDateUpTo: moment(coverageDateUpTo)?.format('M/D/YYYY'),
            productType: capitalizeFirstLetter(productType)
        }
 
        const description = creditCardNumber ? content.description : content.descriptionWithoutCreditCardDetail;
        this.setState({
            dynamicContent: StringPlaceholderService.replace(description, properties)
        });
    }

    onClick = () =>{
        segmentAnalytics.track('My Account| Outstanding Balance Pay AAA Member', {
            memberId: this.context.userDetails.userId,
            numberOfDaysRecivedAlert: this.context.userDetails.subscription.dueDays,
            AAAMembershipId : this.context.userDetails?.membership?.membershipId,
        });
        this.props.history.push('pay-outstanding-balance');
    }

    onClickDecline = () =>{
        segmentAnalytics.track('My Account| Past Payment Due Notice AAA Member', {
            memberId: this.context.userDetails.userId,
            numberOfDaysRecivedAlert: this.context.userDetails.subscription.dueDays,
            AAAMembershipId : this.context.userDetails?.membership?.membershipId,
            dateRecievedAlert: this.context.userDetails.subscription.dueDate
        });
        this.props.history.push('my-account')
    }

    render() {
        const {membershipStatus} = this.context.userDetails.membership || {};
        const { content, dynamicContent} = this.state;
        if (!content || !dynamicContent) {
            return <Spinner />;
        }
        return (
            <>
                {membershipStatus !== 'CANCELLED' && <Breadcrumbs></Breadcrumbs>}
                <Content>
                    <ResponsiveSignupFlowLayout>
                        <SignUpFlowFormContent>
                            <Title
                                desktopStyles={styles.desktopTitle}
                                mobileStyles={styles.mobileTitle}
                            >
                                {content.title}
                            </Title>
                            <ReactMarkdown
                                source={dynamicContent}
                            />
                            <SubmitButton
                                text={content.buttonText}
                                onClick={this.onClick}
                            />
                            {
                                membershipStatus !== 'CANCELLED' &&
                                <Link
                                    style={styles.link}
                                    text={content.linkText}
                                    onClick={this.onClickDecline}
                                    textalign="center"
                                    extraleftspace="4rem"
                                />
                            }        
                        </SignUpFlowFormContent>
                        <SignUpFlowExtraContent />
                    </ResponsiveSignupFlowLayout>
                </Content>
            </>
        );
    }
}

export default withPageTitle(PaymentDuePage, "Payment Due");
