// eslint-disable-next-line no-unused-vars
import {Toast} from '../configuration/ApplicationContext';

class BaseApiService {

    resolve(inputUrl) {
        return this.baseUrl + inputUrl;
    }
    
    async get(inputUrl, isStatusRequired) {
        const url = this.resolve(inputUrl);
        try {
            const response = await fetch(url, {credentials: 'include', redirect: 'manual'});
            return this.handleResponse(response, isStatusRequired);
        } catch (e) {
            Toast.showErrorNotification('Something went wrong!');
            return;
        }
    }
    
    async handleResponse(response, isStatusRequired) {
      if (response.type === 'opaqueredirect' || response.status === 401) {
          await this.login();
          return;
      }
      if (response.status === 500) {
        Toast.showErrorNotification('Something went wrong!');
        return;
      }
      const text = await response.text();
      if (response.status === 403 && text) {
        Toast.showErrorNotification(JSON.parse(text)?.message);
        return;
      }
      if (isStatusRequired) {
        return {
          status: response.status,
          data: text ? JSON.parse(text) : {},
        }
      }
      return text ? JSON.parse(text) : {};
    }

    async post(inputUrl, requestBody) {
      const url = this.resolve(inputUrl);
      try {
          const response = await fetch(url, {
              method: 'post',
              credentials: 'include',
              headers: {'Content-Type': 'application/json'},
              redirect: 'manual',
              body: JSON.stringify(requestBody)
          });
          return this.handleResponse(response);
      } catch {
          Toast.showErrorNotification('Something went wrong!');
          return;
      }
    }

    async put(inputUrl, requestBody) {
        const url = this.resolve(inputUrl);
        try {
            const response = await fetch(url, {
                method: 'put',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                redirect: 'manual',
                body: JSON.stringify(requestBody)
            });
            return this.handleResponse(response);
        } catch {
            Toast.showErrorNotification('Something went wrong!');
            return;
        }
    }

    async patch(inputUrl, requestBody) {
        const url = this.resolve(inputUrl);
        try {
            const response = await fetch(url, {
                method: 'PATCH',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                redirect: 'manual',
                body: JSON.stringify(requestBody)
            });
            return this.handleResponse(response);
        } catch {
            Toast.showErrorNotification('Something went wrong!');
            return;
        }
      }
}
export default BaseApiService;
