import React, {Component} from 'react';
import Spinner from './elements/Spinner';


export default class OrderCompleteCallbackPage extends Component {
    componentDidMount() {
        const {search} = window.location;
        const params = new URLSearchParams(search);

        if (params.get('success') === 'true') {
            window.parent.orderSuccess(params.get('refId'));
        } else {
            window.parent.orderFailure(params.get('errorMessage'));
        }
    }

    render() {
        return (
            <Spinner />
        );
    }
}
