import React, {Component} from 'react';
import {Api} from '../configuration/ApplicationContext';
import Spinner from './elements/Spinner';

class LogoutPage extends Component {
    componentDidMount() {
        this.logoutUser();
    }

    async logoutUser() {
        try {
            await Api.get("/api/users/logout");
        } finally {
            window.location.href = '/';
        }
    }

    render() {
        return <Spinner />;
    }
}

export default LogoutPage;
