import React, { Component } from 'react';
import ResponsiveSignupFlowLayout, { SignUpFlowExtraContent, SignUpFlowFormContent } from '../layout/ResponsiveSignupFlowLayout';
import Title from '../elements/Title';
import Content from '../layout/Content';
import AdminContext from '../contexts/AdminContext';
import Link from '../elements/Link';
import Breadcrumbs from '../common/Breadcrumbs';
import { appendCommaDelimiterToArray, formatDates, formatName, getSocialMediaNamesMap } from '../common/Helpers';
import styled from 'styled-components';
import { colors } from '../../styles/common';
import InfoText from '../elements/InfoText';
import Spinner from '../elements/Spinner';
import ReactPaginate from 'react-paginate';
import Span from '../elements/Span';

const marginTop = { marginTop: '0.5rem' };
const Container = styled.div`
   border: 1px solid ${colors.borderGrey};
   width: 100%;
`;

const Row = styled.div`
    :nth-child(even) {
        background: ${colors.pureWhite} 
    }
    :nth-child(odd) {
        background: ${colors.searhRowBackgroundColor}
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 26px;
    color: ${colors.pureBlack};
    font-size: 1rem;
    padding: 0.5rem;
    text-align: left;
`;

const styles = {
    contentPadding: { paddingRight: '0px' },
    dateFormat: { paddingTop: '0.2rem 0rem' },
    detailsLink: { textAlign: 'right', fontSize: '16px' },
    backLink: { marginTop: '2rem' }
};

export default class ExperianAlertsPage extends Component {
    static contextType = AdminContext;
    state = {
        data: null,
        pageCount: 0,
        pageData: null,
        recordsPerPage: 15,
    };

    componentDidMount() {
        this.setBreadCrumb();
        this.setExperianAlerts();
    }
    setExperianAlerts = () => {
        const state = this.props.location.state;
        if (!state || !state.experianAlerts) {
            return;
        }
        this.setState({
            data: state.experianAlerts,
            pageCount: Math.ceil(state.experianAlerts.length / this.state.recordsPerPage),
            pageData: state.experianAlerts.slice(0, this.state.recordsPerPage)
        });
    }
    handlePageClick = ({ selected }) => {
        const { data, recordsPerPage } = this.state;
        const pageData = data.slice(selected * recordsPerPage, (selected * recordsPerPage + recordsPerPage));
        this.setState({
            pageData,
        });
    }

    setBreadCrumb = () => {
        const { personalInformation } = this.context.memberDetails || {};
        const breadCrumbRoutes = [
            {
                to: '/admin/member-account', label: formatName(personalInformation?.firstName, personalInformation?.middleName, personalInformation?.lastName)
            },
            {
                to: '/admin/experian-alerts', label: 'Experian Alert List'
            }
        ];
        this.setState({ breadCrumbRoutes });
    }


    render() {
        const { breadCrumbRoutes, pageData, data } = this.state || {};
        const { personalInformation } = this.context.memberDetails || {};

        if (!pageData) {
            return <Spinner />
        }
        return (
            <Content
                desktopStyles={{ marginTop: '0rem' }}>
                <Breadcrumbs
                    isCustomerFacing={false}
                    breadCrumbRoutes={breadCrumbRoutes}
                />
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent
                        style={styles.contentPadding}>
                        <Title
                            desktopStyles={marginTop}>
                            Experian Alert List
                        </Title>
                        {
                            !pageData.length &&
                            <InfoText>
                                No Alerts Reported
                            </InfoText>
                        }
                        <Container>
                            {
                                pageData.length && pageData.map(({ date, type, details, showDetailsLink, compromisedProperties }, index) =>
                                    <Row
                                        key={index}>
                                        <InfoText>
                                            {type}
                                            {
                                                showDetailsLink === true &&
                                                <>
                                                    {
                                                        compromisedProperties && compromisedProperties.length > 0 ||
                                                            details && details.socialMedia
                                                            ? ':'
                                                            : ''
                                                    }
                                                    {
                                                        details && details.socialMedia
                                                            ? ` ${getSocialMediaNamesMap(details.socialMedia)}`
                                                            : ''
                                                    }
                                                    {
                                                        compromisedProperties
                                                        && compromisedProperties.length > 0
                                                        && <InfoText>
                                                            {
                                                                `Compromised ${compromisedProperties
                                                                    && compromisedProperties.length > 0
                                                                    ? appendCommaDelimiterToArray(compromisedProperties.map(({ propertyName }) => propertyName))
                                                                    : ''
                                                                }`
                                                            }
                                                        </InfoText>
                                                    }
                                                </>
                                            }
                                        </InfoText>
                                        <InfoText
                                            style={{ textAlign: 'right', display: 'flex', flexDirection: 'column' }}>
                                            <Span
                                                style={styles.dateFormat}
                                                text={formatDates(date, 'YYYYMMDD', 'MM/DD/YYYY')}
                                            />
                                            {
                                                showDetailsLink === true &&
                                                <Link
                                                    to={
                                                        {
                                                            pathname: `/admin/experian/cyber-agent-alert`,
                                                            state: {
                                                                compromisedProperties,
                                                                experianAlerts: data,
                                                                details,
                                                                type
                                                            }
                                                        }
                                                    }
                                                    style={styles.detailsLink}
                                                    text='View Full Alert'
                                                />
                                            }
                                        </InfoText>
                                    </Row>
                                )
                            }
                        </Container>
                        {
                            data.length > 15 &&
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                disabledClassName="disabled-pagination-buttons"
                                pageCount={this.state.pageCount}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        }
                        <Link
                            to='/admin/member-account'
                            style={styles.backLink}
                            text={`Back to ${formatName(personalInformation?.firstName, personalInformation?.middleName, personalInformation?.lastName)}'s Dashboard`}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

