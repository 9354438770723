import React, { useState } from 'react'
import ReactAutocomplete from 'react-autocomplete'
import { Label } from './LabeledInput';
import FieldError from './FieldError';
import { colors } from '../../styles/common';
import { States } from '../common/StatesList'
import styled from 'styled-components';

const Suggestion = styled.div`
`
const autoCompleteStyle = {
  border: '1px solid lightgrey',
  borderRadius: '4px',
  boxSizing: 'border-box',
  height: '45px',
  fontSize: '16px',
  lineHeight: '1.5',
  width: '100%',
  display: 'block',
  marginTop: '0.5rem'
}
const suggestionStyle = {
  left: '0px',
  top: '460px',
  borderRadius: '3px',
  boxShadow: `${colors.pureBlack} 0px 2px 12px`,
  background: colors.pureWhite,
  padding: '0.5rem',
  position: 'absolute !important',
  overflow: 'auto'
}
const errorStyle = {
  borderColor: colors.red
}
const wrapperStyle = { width: '100%', position: 'relative' };

export const AutocompleteInput = (props) => {
  const [state, setState] = useState(props.value);

  const handleChange = async (e) => {
    onChangeValue(e.target.value);
  };
  const handleSelect = (value) => {
    onChangeValue(value);
  }
  const onChangeValue = (value) => {
    setState(value);
    const foundState = stateVerified(value);
    props.onChange({
      target: {
        name: props.name,
        value: foundState ? foundState.id : ''
      },
      persist: () => { },
    })
  }

  const onBlurInput = (e) => {
    const { value } = e.target;
    const foundState = stateVerified(value);
    setState(foundState ? foundState.name : '');
    props.onBlur({
      target: {
        name: props.name,
        value: foundState ? foundState.id : ''
      }
    });
  }
  const stateVerified = (value) => {
    return States.find(state => state.name === value);
  }
  const autoCompleteErrorStyle = { ...autoCompleteStyle, ...props.error ? errorStyle : '' }
  return (
    <>
      <Label>{props.label}</Label>
      <ReactAutocomplete
        items={States}
        shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
        getItemValue={item => item.name}
        renderMenu={(item) =>
          <Suggestion style={suggestionStyle} children={item} />
        }
        renderItem={(item, highlighted) =>
          <Suggestion
            key={item.id}
            style={{ padding: '0.5rem', backgroundColor: highlighted ? colors.snow : 'transparent' }}
          >
            {item.name}
          </Suggestion>
        }
        wrapperStyle={wrapperStyle}
        inputProps={{ style: autoCompleteErrorStyle, onBlur: onBlurInput }}
        value={state}
        onChange={handleChange}
        onSelect={handleSelect}
      />
      {props.error && <FieldError> {props.error}</FieldError>}
    </>
  )
}
