import React from "react";
import styled from "styled-components";
import { colors, mobile } from "../../styles/common";
import JoinNowSection from "../JoinNowSection";
import MemberServiceDescription from './MemberServiceDescription';

const MemberServiceSection = styled.div`
  display:flex;
  height: 500px;
  width: 100%;
  ${ mobile`
     flex-direction: column;
  `} 
`;

const BackgroundImageSection = styled.div`
    width: auto;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: -webkit-linear-gradient(right, 
      rgba(255,255,255,1) 10%, 
      rgba(255,255,255,0.6) 20%,
      rgba(255,255,255,0) 80%,
      rgba(255,255,255,0) 100%
    ), url(${props => props.src});
    ${ mobile`
      height: 301.64px;
      background-size: auto 100%;
      background-image: -webkit-linear-gradient(bottom, 
        rgba(255,255,255,1) 10%, 
        rgba(255,255,255,0.5) 20%,
        rgba(255,255,255,0) 80%,
        rgba(255,255,255,0) 100%
      ), url(${props => props.src});
     `} 
`;

const MemberServiceContentSection = styled.div`
    margin: 0;
    flex:1;
`;
const mobileStyle = {
  padding: '0 0 120px 0'

}

const MemberService = ({ fields, onSignup }) => {
  const {title, description, titleImage, image} = fields;
  return (
    <>
      <MemberServiceSection>
          <MemberServiceContentSection>
            <BackgroundImageSection src={`https:${image.fields.file.url}?fm=webp`} />
        </MemberServiceContentSection>
        <MemberServiceContentSection>
          <MemberServiceDescription title={title} description={description} src={`https:${titleImage.fields.file.url}`}/>
        </MemberServiceContentSection>
      </MemberServiceSection>
      <JoinNowSection onSignup={() => onSignup("Home Page | Bottom CTA Clicked")} style={{background: colors.lightGrey}} mobileStyles={mobileStyle} desktopStyles={{ padding: '10px 0px' }}/>
    </>
  );
};

export default MemberService;