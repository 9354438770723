import React, { useContext, useState } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Span from '../../elements/Span';
import UnorderedListText from '../../elements/UnorderedListText';
import DynamicParagraphText from '../../elements/DynamicParagraphText';
import { formatDateMMDDYYYY, capitalizeFirstLetter, removeLeadingZerosForSingleDigitDate, formatMembershipId } from '../../common/Helpers';
import { colors, notAvailableMessage } from '../../../styles/common';
import AdminContext from '../../contexts/AdminContext';
import CardWithTitle from '../../elements/CardWithTitle';
import CardLineItem from '../../elements/CardLineItem';
import HorizontalRule from '../../elements/HorizontalRule';
import ContainerWithBorder from '../../elements/ContainerWithBorder';
import RichText from '../../elements/RichText';
import Link from '../../elements/Link';
import CancelPlanModal from '../CancelPlanModal';
import { BASIC, COMPLETE, PROTECT, FREE_UPPER_CASE, CANCELLED, NONE } from '../../common/Constants';
import DowngradePlanModal from '../DowngradePlanModal';

const UL = styled.ul`
    display: flex;
    justify-content: flex-end;
    flex-flow: wrap;
    padding: unset;
`;
const LI = styled.li`
    margin-left: 2rem;
    color: ${colors.greyText};
    &:first-child {
        margin-left: 0rem;
        display: inline-block;
    }
`;

const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;
const UnorderedListStyle = ({ children, ...props }) => <UnorderedListText {...props} text={children} />;
const BoldStyle = ({ children, ...props }) => <Span {...props} text={children} />;
const options = {
    renderMark: {
        [MARKS.BOLD]: text => <BoldStyle style={{ color: `${colors.successMessage}`, display: 'block', fontWeight: 'bold', textAlign: 'center' }}>{text}</BoldStyle>
    },
    renderNode: {
        [BLOCKS.UL_LIST]: (node, children) => <UnorderedListStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</UnorderedListStyle>,
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle style={{ margin: 0 }} desktopFontSize='16px' mobileFontSize='14px'>{children}</ParagraphStyle>,
    },
};

const nonAaaMemberTextOptions = {
    renderMark: {
        [MARKS.BOLD]: text => <BoldStyle style={{ color: `${colors.successMessage}`, display: 'block', fontWeight: 'bold', textAlign: 'center' }}>{text}</BoldStyle>
    },
    renderNode: {
        [BLOCKS.UL_LIST]: (node, children) => <UnorderedListStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</UnorderedListStyle>,
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</ParagraphStyle>,
    },
};

const styles = {
    nonAaaMemberBox: { border: `1px solid ${colors.successMessage}`, marginTop: '10px' },
    disabledLink: { color: colors.greyText, cursor: 'default' },
    cursor: { cursor: 'pointer' },
    hr: { width: '50%', marginTop: '2rem', marginBottom: '2rem' },
    link: { textAlign: 'center', padding: '0.5rem' },
    disabledComparePlan: { color: colors.greyText, cursor: 'default', textAlign: 'right' },
    enabledComparePlan: { cursor: 'pointer', textAlign: 'right' },
    fullWidth: { width: "100%" },
    errorMessage: { color: colors.darkRed, fontWeight: 500 },
    bold: { fontWeight: 'bold' },
    boldWithRed: { fontWeight: 'bold', color: colors.darkRed },
    errorMessageWithBottom: { color: colors.darkRed, fontWeight: 500, marginBottom: '0.5rem' },
    boldColor: { fontWeight: 'bold', color: `${colors.successMessage}` },
    linksDisable: { color: colors.greyText, cursor: 'default', textAlign: 'center', padding: '0.5rem' }
}

const MemberCoverageDetails = ({ content, isFreeTrialGracePeriod }) => {
    const context = useContext(AdminContext);
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [isDowngradeModalOpen, setIsDowngradeModalOpen] = useState(false);
    const { subscription, membership, subscriberNumber, invalidMembershipNumber } = context.memberDetails;
    const { cancelledSubscriptionGracePeriod } = subscription || {};
    const termEndDate = formatDateMMDDYYYY(subscription.termEndDate);
    const startDate = formatDateMMDDYYYY(subscription.startDate);
    const productType = subscription.productType === FREE_UPPER_CASE ? BASIC : capitalizeFirstLetter(subscription.productType);
    const membershipExpiryDate = membership?.membershipExpiryDate ? formatDateMMDDYYYY(membership.membershipExpiryDate) : notAvailableMessage;
    const membershipSince = membership?.membershipSince ? formatDateMMDDYYYY(membership.membershipSince) : notAvailableMessage;
    const membershipId = context.memberDetails?.membershipId ? context.memberDetails.membershipId : NONE;
    const { complete, protect } = context.ratePlanIds;
    const outstandingBalance = subscription.outstandingBalance || '';
    const shouldDisableLinks = outstandingBalance;
    const disableDowngradeLink = (!membership && productType === PROTECT) || (invalidMembershipNumber && productType === PROTECT) || (membership && membership?.membershipStatus === CANCELLED && productType === PROTECT);
    const membershipNumberStyle = invalidMembershipNumber ? styles.linksDisable : styles.link;
    const showValidthroughinRed = membership?.membershipStatus === CANCELLED;
    const redirection = {
        upgradeToProtect: invalidMembershipNumber ? '#' : `/admin/basic/upgrade/${protect.monthlyRatePlanId}`,
        upgradeToComplete: invalidMembershipNumber ? '#' : subscription.billingFrequency === 'ANNUALLY' ? `/admin/product/protect/upgrade/${complete.annualRatePlanId}` : `/admin/product/protect/upgrade/${complete.monthlyRatePlanId}`
    }
    const onUpgradeClick = () => {
        switch (productType) {
            case BASIC:
                history.push(`/admin/basic/upgrade/${protect.monthlyRatePlanId}`);
                break;
            case PROTECT:
                subscription.billingFrequency === 'ANNUALLY' ?  history.push(`/admin/product/protect/upgrade/${complete.annualRatePlanId}`) : history.push(`/admin/product/protect/upgrade/${complete.monthlyRatePlanId}`)
                break;
            default:
                break;
        }
    }
    const onDowngrade = () => {
        switch (productType) {
            case COMPLETE:
                subscription.billingFrequency === 'ANNUALLY' ? history.push(`/admin/downgrade/${protect.annualRatePlanId}`) : history.push(`/admin/downgrade/${protect.monthlyRatePlanId}`);
                break;
            case PROTECT:
                history.push(`/admin/downgrade/basic`);
                break;
            default:
                break;
        }
    }
    const onDowngradeClick = () => {
        const { upcomingSubscriptionProductType, subscription: { cancelledSubscriptionGracePeriod } = {} } = context.memberDetails || {};
        if (upcomingSubscriptionProductType != null || cancelledSubscriptionGracePeriod === true) {
            setIsDowngradeModalOpen(true);
        } else {
            onDowngrade();
        }
    }

    const closeModal = () => {
        setIsOpen(false);
    }
    const redirectToCancelSuccess = () => {
        if (productType === BASIC) {
            const searchResult = JSON.parse(localStorage.getItem('searchResult'));
            searchResult.idcMembership = CANCELLED;
            localStorage.setItem('searchResult', JSON.stringify(searchResult));
        }

        history.push('/admin/cancel/success');

    }

    return (
        <>
            <CardWithTitle
                firstGrow={true}
                marginBottom='1rem'
                title='Membership & Coverage'>
                <CardLineItem
                    itemStyle={styles.boldColor}
                    itemKey='Coverage Plan'
                    itemValue={productType}
                />
                <UL>
                    {
                        cancelledSubscriptionGracePeriod === false &&
                        <LI>
                            <Link
                                to='#'
                                text='Cancel'
                                onClick={() => setIsOpen(true)}
                            />
                        </LI>
                    }
                    {
                        productType !== BASIC &&
                        <LI>
                            <Link
                                text='Downgrade'
                                to='#'
                                style={
                                    disableDowngradeLink
                                        ? styles.disabledLink
                                        : styles.cursor
                                }
                                onClick={
                                    disableDowngradeLink
                                        ? () => { }
                                        : onDowngradeClick
                                }
                            />
                        </LI>
                    }
                    {
                        productType !== COMPLETE &&
                        <LI>
                            <Link
                                text='Upgrade'
                                style={
                                    shouldDisableLinks
                                        ? styles.disabledLink
                                        : styles.cursor
                                }
                                onClick={
                                    !(shouldDisableLinks)
                                        ? onUpgradeClick
                                        : ''}
                            />
                        </LI>
                    }
                </UL>
                {
                    subscriberNumber &&
                    <CardLineItem
                        itemKeyStyle={styles.fullWidth}
                        itemKey='Subscriber ID'
                        itemValue={subscriberNumber}
                    />
                }
                {
                    <CardLineItem
                        itemKey='IDC coverage began'
                        itemValue={removeLeadingZerosForSingleDigitDate(startDate)}>
                    </CardLineItem>
                }
                {
                    productType !== BASIC && termEndDate &&
                    <>
                        {
                            subscription?.freeTrialEndDate && !isFreeTrialGracePeriod &&
                            <CardLineItem
                                itemStyle={styles.bold}
                                itemKeyStyle={styles.bold}
                                itemKey={'Expiration Date'}
                                itemValue={removeLeadingZerosForSingleDigitDate(termEndDate)}>
                            </CardLineItem>
                        }
                        {
                            !subscription?.freeTrialEndDate &&
                            <CardLineItem
                                itemStyle={styles.bold}
                                itemKey={'Paid through'}
                                itemValue={removeLeadingZerosForSingleDigitDate(termEndDate)}>
                            </CardLineItem>
                        }
                    </>
                }
                <HorizontalRule
                    style={styles.hr}
                />
                {
                    membershipId &&
                    <>
                        <CardLineItem
                            style={
                                invalidMembershipNumber
                                    ? styles.errorMessageWithBottom
                                    : null
                            }
                            itemKey='AAA Membership #'
                            itemValue={formatMembershipId(membershipId)}>
                        </CardLineItem>
                        {
                            invalidMembershipNumber &&
                            <CardLineItem
                                style={styles.errorMessage}
                                itemKey='(Invalid)'>
                            </CardLineItem>
                        }
                        <Link
                            to='/admin/edit-login-and-personal-details'
                            text={`${membership || (!membership && invalidMembershipNumber) ? 'Edit' : 'Attach'}  AAA Membership #`}
                            style={{ textAlign: 'right', marginBottom: '1rem' }}
                        />
                    </>
                }
                {
                    membership && membershipSince &&
                    <CardLineItem
                        itemKey='Member since'
                        itemValue={removeLeadingZerosForSingleDigitDate(membershipSince)}
                    />
                }
                {
                    membership && membershipExpiryDate &&
                    <CardLineItem
                        style={showValidthroughinRed ?
                            styles.boldWithRed
                            : null}
                        itemStyle={
                            showValidthroughinRed ? null : styles.bold}
                        itemKey= {membership?.membershipStatus === CANCELLED ? 'Cancelled' : 'Valid through'}
                        itemValue={removeLeadingZerosForSingleDigitDate(membershipExpiryDate)}
                    />
                }
                {
                    productType === COMPLETE &&
                    <>
                        {
                            !outstandingBalance && content.descriptionCompletePage &&
                            <ContainerWithBorder
                                style={{ border: `1px solid ${colors.successMessage}` }}>
                                <RichText
                                    text={content.descriptionCompletePage}
                                    options={options}
                                />
                            </ContainerWithBorder>
                        }
                    </>
                }
                {
                    productType === PROTECT &&
                    <>
                        {
                            !outstandingBalance && content.descriptionProtectPage &&
                            <ContainerWithBorder
                                style={{ border: `1px solid ${colors.successMessage}` }}>
                                <RichText
                                    text={content.descriptionProtectPage}
                                    options={options}
                                />
                                <Link
                                    style={membershipNumberStyle}
                                    text='Upgrade to Complete'
                                    to={redirection.upgradeToComplete}
                                />
                            </ContainerWithBorder>
                        }
                    </>
                }
                {
                    productType === BASIC &&
                    <>
                        {
                            !outstandingBalance && content.descriptionBasicPlan &&
                            <ContainerWithBorder
                                style={{ border: `1px solid ${colors.successMessage}` }}>
                                <RichText
                                    text={content.descriptionBasicPlan}
                                    options={options}
                                />
                                <Link
                                    style={membershipNumberStyle}
                                    text='Upgrade to Protect'
                                    to={redirection.upgradeToProtect}
                                />
                            </ContainerWithBorder>
                        }
                    </>
                }
                {
                    !membership &&
                    <ContainerWithBorder
                        style={styles.nonAaaMemberBox}>
                        <RichText
                            text={content.descriptionNonAaaMember}
                            options={nonAaaMemberTextOptions}
                        />
                    </ContainerWithBorder>
                }
            </CardWithTitle>
            <CancelPlanModal
                isOpen={isOpen}
                productType={productType}
                closeModal={closeModal}
                redirectToCancelSuccess={redirectToCancelSuccess}
                onDowngradeClick={onDowngrade}
                isFreeTrialUser={subscription?.freeTrialEndDate}
            />
            <DowngradePlanModal
                isOpen={isDowngradeModalOpen}
                closeModal={() => setIsDowngradeModalOpen(false)}
                onDowngradeClick={onDowngrade}
            />
        </>
    )
};

export default MemberCoverageDetails;