import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import ZipCodeService from '../../services/ZipCodeService';
import { colors, mobileOnly } from '../../styles/common';
import AskZipCodePage from '../AskZipCodePage';
import Link from '../elements/Link';

const BottomButtonSection = styled.div`
    position: fixed;
    width: 100%;
    bottom: 0;
    background: ${colors.pureWhite};
    z-index: 5;
    padding: 1rem 0rem;
    ${props => props.mobileOnly && css`
        ${mobileOnly}
    `}
`;

const fixedBottomButtonStyle = {
    fontWeight: '500',
    fontSize: '19px',
    lineHeight: '25px',
    textAlign: 'center',
    color: `${colors.pureWhite}`,
    background: `${colors.darkBlue}`,
    padding: '12px',
    cursor: 'pointer',
    display: 'block',
    outline: 'none',
    border: 'none',
    marginLeft: '1rem',
    borderRadius: '10px',
    width: 'calc(86% - 120px)'
}

const FixedBottomButton = ({ text, to, checkZipCode = false, outOfAreaPageLink = '/out-of-area/signup', clickHandler = () => { } }) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const onEnrollNowClick = () => {
        clickHandler();
        if (checkZipCode) {
            if (!ZipCodeService.getZipCookie()) {
                displayModal();
            } else {
                history.push(to);
            }
        }
    }
    const displayModal = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const addZipCodeData = (zip) => {
        ZipCodeService.checkBundleEligibility().then((response) => {
            if (!response.serviceable) {
                history.push(outOfAreaPageLink, { response: response });
            } else {
                onEnrollNowClick();
            }
        });
    }

    return (
        <>
            <BottomButtonSection mobileOnly='true'>
                <Link text={text} style={fixedBottomButtonStyle} to={checkZipCode ? '#' : to} onClick={() => onEnrollNowClick()} />
            </BottomButtonSection>
            <AskZipCodePage
                id="zip_code_modal"
                addZipCodeData={(zip) => { addZipCodeData(zip); }}
                showModal={showModal}
                closeModal={closeModal}
                openZipForm={displayModal}
            />
        </>
    );
}

export default FixedBottomButton;
