import React, { Fragment, useContext } from 'react';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import RichText from '../elements/RichText';
import { BLOCKS } from '@contentful/rich-text-types';
import UnorderedListText from '../elements/UnorderedListText';
import Card from '../elements/Card';
import InfoText from '../elements/InfoText';
import ResponsiveIncludedPageLayout, { ResponsiveIncludedPageContent } from '../layout/ResponsiveIncludedPageLayout';
import Title from '../elements/Title';
import styled from 'styled-components';
import { colors } from '../../styles/common';
import PlanDetailsContent from '../landing-page/PlanDetailsContent';
import Link from '../elements/Link';
import DataContext from '../contexts/DataContext';
import PlanDetailsTabbedView from '../landing-page/PlanDetailsTabbedView';

const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children}/>;

const UnorderedListStyle = ({ children, ...props }) => <UnorderedListText {...props} text={children}/>;

const options = {
    renderNode: {
        [BLOCKS.UL_LIST]: (node, children) => <UnorderedListStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</UnorderedListStyle>,
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle style={{marginTop: '0.5rem', marginBottom: '0.5rem'}} desktopFontSize='17px' mobileFontSize='14px'>{children}</ParagraphStyle>,
    },
};

const titleStyle = {
    fontSize: '1.5em'
}

const PlanDetailContentSection = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: center;  
`;

const upgradeStyle = {
    fontSize: '1.5em',
    marginLeft: '1rem'
}

const planDetailStyle = {
    fontSize: '10px', 
    marginRight: '2rem',
};

const linkStyle = {
    fontSize: '14px', 
    textAlign: 'center'
}

const WhatsIncluded = ({content, planType, sections, redirectToUpgradeFlow}) => {
    const context = useContext(DataContext);
    const paymentMode = context.product?.paymentMode;
    if (planType === 'protect') {
        sections = sections.filter(section => section.fields.title !== 'Basic');
    }
    return (
        <>
            <ResponsiveIncludedPageLayout>
                <ResponsiveIncludedPageContent>
                    <InfoText desktopOnly='true'>
                        <PlanDetailContentSection>
                        {
                            sections.map((section, index) =>
                            <Fragment key={index}>
                                <PlanDetailsContent style={planDetailStyle} borderRed='true' key={section.sys.id} currentPlanType={planType} planType={section.fields.title} 
                                                        amount={section.fields.amount} amountAnnual={section.fields.amountAnnual} amountPeriod={section.fields.amountPeriod} amountInfo={section.fields.amountInfo} 
                                                        buttonText={section.fields.buttonText} insuranceInfo={section.fields.insuranceInfo} totalInsurance={section.fields.totalInsurance} dataList={section.fields.description} paymentMode={paymentMode} isWhatsIncludedPage={true}
                                                        redirectToUpgradeFlow={redirectToUpgradeFlow}/>
                            </Fragment> 
                            )
                        }
                        </PlanDetailContentSection>
                        <Link style={{...linkStyle, textDecoration: 'underline'}} textalign="center" extraleftspace="3.5rem" extratopspace="2.5rem" text="Learn more about our plan details" to="/plan-details" />
                        <Link style={{...linkStyle, textDecoration: 'underline'}} textalign="center" extraleftspace="3.5rem" extratopspace="1rem" extrabottomspace="1.5rem" text="Back to My Account Home" to="/my-account" />
                    </InfoText>
                    <InfoText mobileOnly='true'>
                        {planType === 'basic' && 
                            <>
                                <Card style={{background: colors.pureWhite}}>
                                    <RichText text={content.basic.description} options={options}></RichText>
                                    <DynamicParagraphText style={{color: colors.red}} text='We recommend Upgrading for better protection.'/>
                                </Card>
                                <Title style={upgradeStyle}>Upgrade to one of our advanced paid plans</Title>
                            </>
                        }
                        {planType === 'protect' && 
                            <Card style={{background: colors.pureWhite}}>
                                <Title style={titleStyle}>Protect Coverage: Your Current Plan.</Title>
                                <RichText text={content.protect.description} options={options}></RichText>
                            </Card>
                        }                        
                        <PlanDetailsTabbedView isUpgradeFlow={true} backgroundColor={colors.pureWhite} sections={sections} planType={planType} redirectToUpgradeFlow={redirectToUpgradeFlow}/>
                        <Link style={linkStyle} textalign="center" extraleftspace="3.5rem" extratopspace="2rem" text="Learn more about our plan details" to="/plan-details" />
                        <Link style={linkStyle} textalign="center" extraleftspace="3.5rem" extratopspace="2rem" extrabottomspace="5.5rem" text="Back to My Account Home" to="/my-account" />                
                    </InfoText>
                </ResponsiveIncludedPageContent>
            </ResponsiveIncludedPageLayout>            
        </>
    )
};

export default WhatsIncluded;