import React from 'react'
import LabeledInput from '../elements/LabeledInput';
import { AutocompleteInput } from '../elements/AutocompleteInput';
import MaskedLabeledInput from '../elements/MaskedLabeledInput';

export const ManualBillingAddressFields = (props) => {
    const { values, filteredErrors, handleBlur, ...inputHandlers } = props;
    return (
        <div onBlur={handleBlur}>
            <LabeledInput type="text" style={{ marginTop: '1rem' }} name="billingLineOne" label="Billing Address" value={values.billingLineOne} error={filteredErrors.billingLineOne} {...inputHandlers} />
            <LabeledInput type="text" name="additionalBillingInfo" label="Billing Address 2 (Optional – Apt., Suite, Bldg.)" value={values.additionalBillingInfo} error={filteredErrors.additionalBillingInfo} {...inputHandlers} />
            <LabeledInput type="text" name="billingCity" label="Billing City" value={values.billingCity} error={filteredErrors.billingCity} {...inputHandlers} />
            <AutocompleteInput type="text" name="billingState" label="Billing State" value={values.billingState} error={filteredErrors.billingState} {...inputHandlers} />
            <MaskedLabeledInput mask="99999" maskChar="" type="text" name="billingZipCode" label="Billing Zip Code" value={values.billingZipCode} error={filteredErrors.billingZipCode} {...inputHandlers} />
        </div>
    )
}
