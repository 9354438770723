import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../../../styles/common';
import { COMPLETE, PROTECT } from '../../../common/Constants';

const TopSection = styled.div`
    position: relative;
`;

const TopSectionBar = styled.div`
    height: 25px; 
    margin-top: -1px;
`;

const MonitoringTag = styled.div`
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    top: 13px;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    position: absolute;
    ${props => props.backgroundcolor && css`
        background: ${props.backgroundcolor};
    `}
    ${props => props.color && css`
        color: ${props.color};
    `}
`;
const ProductChartTabbedViewTopSection = ({ ...props }) => {
    const {
        selectedProduct,
        fields: { productMonitoringTag } = {},
        getTabBackgroundColorBasedOnTitle
    } = props || {};

    const topSectionBarStyle = () => {
        return {
            backgroundColor: getTabBackgroundColorBasedOnTitle(selectedProduct)
        }
    }
    const getMonitoringTagTextColor = (title) => {
        if (title === PROTECT) {
            return colors.darkBlue;
        } else if (title === COMPLETE) {
            return colors.pureWhite;
        }
        return null;
    }
    const getMonitoringTagBgColor = (title) => {
        if (title === PROTECT) {
            return colors.sandyBrown;
        } else if (title === COMPLETE) {
            return colors.tomato;
        }
        return null;
    }
    return (
        <TopSection>
            <TopSectionBar
                style={topSectionBarStyle()}
            />
            <MonitoringTag
                color={getMonitoringTagTextColor(selectedProduct)}
                backgroundcolor={getMonitoringTagBgColor(selectedProduct)}>
                {productMonitoringTag}
            </MonitoringTag>
        </TopSection>
    );
}
export default ProductChartTabbedViewTopSection;