import React from "react";
import { Api } from "../../configuration/ApplicationContext";
import Spinner from '../elements/Spinner';
import ContentfulIds from '../../configuration/ContentfulIds';
import RichText from '../elements/RichText';
import Title from "../elements/Title";
import Section from "../elements/Section";
import { subscriptionApplicationIdSessionStorage } from '../common/Helpers';
import withPageTitle from "../layout/withPageTitle";
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from '../layout/ResponsiveSignupFlowLayout';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import Span from '../elements/Span';
import { MARKS, BLOCKS, INLINES } from "@contentful/rich-text-types";
import SubmitButton from '../elements/SubmitButton';
import Content from '../layout/Content';
import BaseBundleSignup from "./BaseBundleSignup";
import Link from "../elements/Link";
import withPageMetaTags from "../layout/withPageMetaTags";

const titleStyle = {
  lineHeight: 'unset'
};
const buttonStyle = {
  margin: '29px 0px 37px 0px'
};
const linkStyle = { display: 'inline-block', fontSize: '14px' };
const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText desktopFontSize='17px' mobileFontSize='14px' {...props} text={children} id="rich-text" />;
const SpanText = ({ children, ...props }) => <Span {...props} text={children} />;
const options = {
  renderMark: {
    [MARKS.BOLD]: text => <SpanText style={{ fontWeight: '600' }}>{text}</SpanText>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle >{children}</DynamicParagraphStyle>
  },
};

class AlreadyHaveAAALogin extends BaseBundleSignup {

  async componentDidMount() {
    this.loadContent(ContentfulIds.BundleFlow.AlreadyHaveAAALogin);
  }

  onLoginToAAAClick = async () => {
    const subscriptionApplicationId = sessionStorage.getItem(subscriptionApplicationIdSessionStorage);
    const { productId } = this.state || {};
    if (subscriptionApplicationId) {
      await Api.login(this.generateIntent(this.props.match.params.type), {
        'subscription-application-id': subscriptionApplicationId,
        'product-id': productId
      });
    }
  }

  generateIntent = (key) => {
    const redirectionMap = new Map()
      .set('non-member', `non-member-flow-login`)
      .set('bundle', `bundle-flow-login`);
    return redirectionMap.get(key);
  }
  setSubDescriptionOptions = (email, productId) => {
    const subDescriptionOptions = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle >{children}</DynamicParagraphStyle>,
        [INLINES.HYPERLINK]: (node) => <Link style={linkStyle} to={`/${this.props.match.params.type}/checkout/${productId}/submit-new-email?email=${email}`} text={node.content[0].value} />
      },
    };
    return subDescriptionOptions;
  }

  render() {
    const { content, email, productId } = this.state || {};
    if (!content) {
      return <Spinner />
    }
    return (
      <Content>
        <ResponsiveSignupFlowLayout>
          <SignUpFlowFormContent>
            <Title style={titleStyle}>{content.title}</Title>
            <RichText text={content.description} options={options} />
            <SubmitButton style={buttonStyle} text={content.linkText} onClick={this.onLoginToAAAClick} ></SubmitButton>
            <RichText text={content.subDescription} options={this.setSubDescriptionOptions(email, productId)} />
            <Section text={<RichText text={content.contactUs} options={options} />}></Section>
          </SignUpFlowFormContent>
          <SignUpFlowExtraContent />
        </ResponsiveSignupFlowLayout>
      </Content>
    );
  }
}

export default withPageMetaTags(withPageTitle(AlreadyHaveAAALogin, "Already Have AAA Login"), { noindex: true });