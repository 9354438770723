import React from 'react';
import Content from "../layout/Content";
import ResponsiveMyAccountLayout from '../layout/ResponsiveMyAccountLayout';
import Title from '../elements/Title';
import Spinner from '../elements/Spinner';
import ContentfulIds from '../../configuration/ContentfulIds';
import MemberCoverageDetails from './admin-member-account/MemberCoverageDetails';
import MemberPaymentDetails from './admin-member-account/MemberPaymentDetails';
import MemberPersonalDetails from './admin-member-account/MemberPersonalDetails';
import BaseMyAccount from '../BaseMyAccount';
import AdminContext from '../contexts/AdminContext';
import { deleteIndividualPromoCodeNameValueFromSessionStorage, removeMemberTypeFromSessionStorage, removeSubscriptionApplicationIdSessionStorage, sortDataContainingDatesInDescendingOrder } from '../common/Helpers';
import NonMemberPersonalDetails from './admin-member-account/NonMemberPersonalDetails';
import NonMemberCoverageDetails from './admin-member-account/NonMemberCoverageDetails';
import Notification from '../notifications/Notification';
import Breadcrumbs from '../common/Breadcrumbs';
import AdminOutstandingBalance from './admin-member-account/AdminOutstandingBalance';
import Masonry from 'react-masonry-css';
import MemberExperianAlerts from './admin-member-account/MemberExperianAlerts';
import { AdminApi } from '../../configuration/ApplicationContext';
import { CANCELLED } from '../common/Constants';

const breadCrumbRoutes = [
    {
        to: '/admin/member-account', label: ''
    }
]
export default class AdminMemberAccountPage extends BaseMyAccount {
    static contextType = AdminContext;

    async componentDidMount() {
        this.removeDataFromSessionStorage();
        await this.context.getMemberDetails();
        this.loadContent(ContentfulIds.AdminMemberAccountPage);
        this.determinePageType();
        this.setNameInBreadCrumb();
        this.checkIsExpired();
        this.context.removePromoCodeData();
        this.getExperianAlerts();
    }
    removeDataFromSessionStorage = () => {
        removeSubscriptionApplicationIdSessionStorage();
        removeMemberTypeFromSessionStorage();
        deleteIndividualPromoCodeNameValueFromSessionStorage();
    }

    getExperianAlerts = async () => {
        if (this.context.searchResult?.userId) {
            let response = await AdminApi.get(`/api/users/${this.context.searchResult.userId}/experian-alerts`);
            this.setState({
                experianAlerts: response
            });
        } else {
            this.setState({
                experianAlerts: []
            });
        }
    }

    checkIsExpired = () => {
        const { notifications } = this.props.notificationContext;
        const expiredNotification = notifications.filter(notification => {
            return notification.notificationType === 'PAYMENT_EXPIRED';
        });
        if (expiredNotification && expiredNotification.length) {
            this.setState({
                isExpired: true,
                creditCardExpiryDate: expiredNotification[0].properties.creditCardExpiryDate
            });
        }
    }

    setNameInBreadCrumb() {
        const { searchResult } = this.context || {};
        breadCrumbRoutes[0].label = searchResult.displayName;
    }

    render() {
        const { content, isExpired, creditCardExpiryDate, pageType, experianAlerts } = this.state || {};
        const { notifications } = this.props.notificationContext;
        const {
            subscription,
            personalInformation,
            upcomingSubscriptionProductType
        } = this.context.memberDetails || {};
        const isFreeTrialGracePeriod = upcomingSubscriptionProductType && subscription?.freeTrialEndDate;
        const { searchResult } = this.context || {};
        if (!content || (Object.keys(searchResult).length <= 0) || !experianAlerts) {
            return <Spinner />;
        }
        return (
            <Content
                desktopStyles={{ marginTop: 0 }}>
                <Breadcrumbs
                    isCustomerFacing={false}
                    breadCrumbRoutes={breadCrumbRoutes}
                />
                {
                    notifications.map(notification => {
                        return <Notification
                            key={notification.notificationType}
                            {...notification} {...this.props}
                        />
                    })
                }
                {
                    searchResult.idcMembership !== 'None' &&
                    <Title
                        style={{ marginTop: '3rem' }}>
                        {personalInformation?.displayName}
                    </Title>
                }
                {
                    searchResult.idcMembership === 'None' &&
                    <Title
                        style={{ marginTop: '3rem' }}>
                        {searchResult.displayName}
                    </Title>
                }
                <ResponsiveMyAccountLayout>
                    {
                        searchResult.idcMembership !== 'None' && subscription &&
                        <Masonry
                            breakpointCols={3}
                            className="masonry-grid"
                            columnClassName="masonry-grid_column">
                            <>
                                <AdminOutstandingBalance
                                    content={content}
                                />
                                <MemberPersonalDetails
                                    notifications={notifications}
                                />
                                <MemberExperianAlerts
                                    experianAlerts={experianAlerts}
                                />
                            </>
                            <MemberCoverageDetails
                                content={content}
                                isFreeTrialGracePeriod={isFreeTrialGracePeriod}
                            />
                            <MemberPaymentDetails
                                content={content}
                                pageType={pageType}
                                isExpired={isExpired}
                                upcomingSubscriptionProductType={upcomingSubscriptionProductType}
                                creditCardExpiryDate={creditCardExpiryDate}
                            />
                        </Masonry>
                    }
                    {
                        (searchResult.idcMembership === 'None' || searchResult.idcMembership === CANCELLED) &&
                        <>
                            <NonMemberPersonalDetails
                                searchResult={searchResult}
                                notifications={notifications}
                            />
                            <NonMemberCoverageDetails
                                content={content}
                                searchResult={searchResult}
                            />
                        </>
                    }
                </ResponsiveMyAccountLayout>
            </Content >
        )
    }
}
