import React, { Component } from 'react';
import { ContentfulService, segmentAnalytics } from '../../configuration/ApplicationContext';
import ContentfulIds from '../../configuration/ContentfulIds';
import RedirectionService from '../../services/RedirectionService';
import DataContext from '../contexts/DataContext';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import Spinner from '../elements/Spinner';
import SubmitButton from '../elements/SubmitButton';
import Title from '../elements/Title';
import Content from '../layout/Content';
import ResponsiveSignupFlowLayout, { SignUpFlowExtraContent, SignUpFlowFormContent } from '../layout/ResponsiveSignupFlowLayout';
import withPageTitle from '../layout/withPageTitle';

class AAAIdcSubscriptionCompletePage extends Component {
    static contextType = DataContext;

    async componentDidMount() {
        this.checkUserStateAndRedirect();
        await this.context.refreshContext();
        this.trackSegmentEvent();
        this.loadContent();     
    }

    trackSegmentEvent = async () => {
        segmentAnalytics.track('AAA-SUF-Basic-Confirmed', {
            memberId: this.context.userDetails.userId
        });
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.AAAIdcSignupFlow.FreeSubscriptionCompletionPage);
        this.setState({content: response.fields});
    }

    checkUserStateAndRedirect() {
        const userDetails = this.context.userDetails || {};
        if (userDetails.subscription) {
            this.props.history.push(RedirectionService.getConditionalRenderURL(null, userDetails));
        }
    }

    setUpIdChampion = () => {
        segmentAnalytics.track('AAA-SUF-Basic-Start-Monitoring', {
            memberId: this.context.userDetails.userId,
        });
        window.location.href = process.env.REACT_APP_EXPERIAN_SSO_URL;
    }

    render() {
        const { content } = this.state || {};
        if (!content) {
            return <Spinner />;
        }
        return (
            <Content>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent style={{paddingBottom:'30px'}}>
                        <Title>{content.title}</Title>
                        <DynamicParagraphText desktopFontSize='17px' mobileFontSize='14px' text={content.message} />
                        <DynamicParagraphText style={{fontWeight: 500}} desktopFontSize='17px' mobileFontSize='17px' text={content.subText} />
                        <SubmitButton mobileOnly="true" style={{marginTop: '2rem'}} text={content.mobileButtonText} onClick={this.setUpIdChampion} />
                        <SubmitButton desktopOnly="true" style={{marginTop: '2rem'}} text={content.buttonText}  onClick={this.setUpIdChampion} />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent/>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageTitle(AAAIdcSubscriptionCompletePage, "");

