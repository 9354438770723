import React from 'react';
import Content from '../layout/Content';
import { AdminApi } from '../../configuration/ApplicationContext';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from '../layout/ResponsiveSignupFlowLayout';
import Title from '../elements/Title';
import moment from 'moment';
import AdminContext from '../contexts/AdminContext';
import BasePersonalDetailPage from '../BasePersonalDetailPage';
import PersonalInfoForm from '../forms/PersonalInfoForm/PersonalInfoForm';
import Spinner from '../elements/Spinner';
import { buildUrl, formatName, removeSpacesFromMembershipNumber, setValidationNotification } from '../common/Helpers';
import Breadcrumbs from '../common/Breadcrumbs';
import { AdminNotificationTypes } from '../notifications/AdminNotifications';
import Notification from '../notifications/Notification';
import { MembershipNotification } from '../MembershipNotification';
import { ApiNotification } from '../common/ApiNotification'
const breadCrumbRoutes = [
    {
        to: '/admin/member-account', label: ''
    },
    {
        to: '/admin/edit-login-and-personal-details', label: 'Edit Sign In & Personal Details'
    }
]
export class EditLoginAndPersonalDetailsPage extends BasePersonalDetailPage {
    static contextType = AdminContext;
    constructor(props) {
        super(props)
        this.state = {
            apiErrors: [],
            relaxRequiredValidationsForFields: []
        }
    }

    async componentDidMount() {
        this.loadContent();
        this.isEmailVerified();
        this.createNoEmailNotification();
        this.setNameInBreadCrumb();
        this.populateAddress(this.context.memberDetails?.personalInformation);
        this.makeAAAMembershipFieldOptionalForNonMember();
        await this.props.notificationContext.getNotifications();
    }
    makeAAAMembershipFieldOptionalForNonMember = () => {
        const { memberDetails: { membershipId } = {} } = this.context || {};
        const { relaxRequiredValidationsForFields } = this.state || {};
        let optionalFields = [...relaxRequiredValidationsForFields];
        if (membershipId == null) {
            if (optionalFields !== undefined) {
                optionalFields.push('membershipNumber');
            } else {
                optionalFields = ['membershipNumber'];
            }
            this.setState({ relaxRequiredValidationsForFields: optionalFields });
        }
    }

    createNoEmailNotification = () => {
        const { notifications } = this.props.notificationContext || {};
        const findNotification = notifications.find(notification => notification.notificationType === AdminNotificationTypes.ADD_EMAIL_ADDRESS);
        if (findNotification) {
            const notifications = setValidationNotification('No known email address. Please request an email address and add it if possible, which will create a AAA Digital account for this Member.', 'info');
            this.setState({
                notifications,
                relaxRequiredValidationsForFields: ['email']
            });
        }

    }
    setNameInBreadCrumb = () => {
        const { personalInformation } = this.context.memberDetails || {};
        breadCrumbRoutes[0].label = personalInformation?.displayName;
    }

    isEmailVerified = () => {
        const { notifications } = this.props.notificationContext;
        const isEmailVerified = notifications.find(notification => notification.notificationType === AdminNotificationTypes.VERIFY_EMAIL_ADDRESS);
        const isEmailNotAvailable = notifications.find(notification => notification.notificationType === AdminNotificationTypes.ADD_EMAIL_ADDRESS);
        this.setState({
            isEmailVerified,
            isEmailNotAvailable
        })
    }

    onSubmit = async (formData) => {
        const { addNotification } = this.props.notificationContext;
        if (Object.keys(formData.errors).length > 0) {
            return;
        }
        this.setState({
            customErrorMessages: [],
            loading: true
        });
        const { firstName, middleName, lastName, email, dob, phoneNumber, additionalHomeInfo, additionalBillingInfo, membershipNumber } = formData.values;
        const billingAddressSameAsHomeAddress = this.state.billingAddressSameAsHomeAddress;
        const billingAddress = { ...this.state.billingAddress, lineTwo: additionalBillingInfo };
        const homeAddress = { ...this.state.homeAddress, lineTwo: additionalHomeInfo };
        const apiPayload = {
            firstName,
            middleName,
            lastName,
            email,
            dob: dob !== '' ? moment(dob).format('YYYY-MM-DD') : '',
            phoneNumber: phoneNumber.replace(/[^\d]/g, ''),
            homeAddress,
            billingAddress: billingAddressSameAsHomeAddress ? homeAddress : billingAddress,
            billingAddressSameAsHomeAddress
        };
        const aaaLoginIdentifier = this.context.searchResult.aaaLoginIdentifier || '';
        const userId = this.context.searchResult.userId || '';
        const params = {};
        params['aaaLoginIdentifier'] = aaaLoginIdentifier;
        params['userId'] = userId;
        params['membershipNumber'] = removeSpacesFromMembershipNumber(membershipNumber);
        const response = await AdminApi.put(buildUrl(`/api/personal-information`, params), apiPayload);
        if (!response) {
            return;
        }
        if (response.validationErrors) {
            let apiErrors = response.validationErrors.forEach(apiError => {
                switch (apiError.property) {
                    case 'digitalAccount':
                    case 'email': {
                        const notifications = setValidationNotification(apiError.message, 'error')
                        this.setState({
                            notifications
                        });
                        break;
                    }
                    case 'subscription': {
                        const {
                            setNotification,
                            customErrorMessages
                        } = ApiNotification.alreadyActiveSubscription;
                        const notifications = setNotification;
                        this.setState({
                            notifications,
                            customErrorMessages
                        });
                        break;
                    }
                    case 'offlineUser': {
                        const {
                            setNotification,
                            customErrorMessages
                        } = ApiNotification.digitalAccount;
                        const notifications = setNotification;
                        this.setState({
                            notifications,
                            customErrorMessages
                        });
                        break;
                    }
                    case 'cancelledMembershipNumber': {
                        const {
                            setNotification,
                            customErrorMessages
                        } = ApiNotification.cancelledMembershipNumber;
                        const notifications = setNotification;
                        this.setState({
                            notifications,
                            customErrorMessages
                        });
                        break;
                    }
                    case 'membershipNumber': {
                        const notifications = MembershipNotification(apiError);
                        this.setState({
                            notifications,
                        });
                        break;
                    }
                    default:
                        return [apiError.message]
                }
            }, null);
            this.setState({
                apiErrors,
                loading: false
            });
            return;
        } else if (response.message) {
            this.setState({
                apiErrors: [response.message],
                loading: false
            });
            return;
        }
        const searchResult = JSON.parse(localStorage.getItem('searchResult')) || {};
        searchResult.firstName = firstName;
        searchResult.middleName = middleName;
        searchResult.lastName = lastName;
        this.context.updateState({ searchResult });
        localStorage.setItem('searchResult', JSON.stringify(searchResult));
        await this.props.notificationContext.getNotifications();
        await addNotification(AdminNotificationTypes.PERSONAL_INFORMATION_UPDATE, {
            name: formatName(firstName, middleName, lastName),
        });
        this.props.history.push('/admin/member-account');
    };

    clearCustomErrorMessage = (fieldName) => {
        this.setState({
            customErrorMessages: {},
            notifications: (fieldName === 'email' && this.state?.customErrorMessages?.email) ? [] : this.state.notifications
        })
    }

    render() {
        const { personalInformation, membershipId, loginEmail } = this.context.memberDetails || {};
        const { content, notifications, relaxRequiredValidationsForFields, customErrorMessages, loading } = this.state || {};
        if (!content || !personalInformation || loading) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{ marginTop: '0rem' }}>
                <Breadcrumbs isCustomerFacing={false} breadCrumbRoutes={breadCrumbRoutes}></Breadcrumbs>
                {notifications && notifications.map(notification => {
                    return <Notification key={notification.notificationType} {...notification} {...this.props} />
                })}
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title>{content.title}</Title>
                        <PersonalInfoForm onSubmit={this.onSubmit} onSelectAddress={this.onSelectAddress}
                            onSelectBillingAddress={this.onSelectBillingAddress} isEditPersonalInfoFromAdmin='true'
                            onBillingAddressSameAsHomeAddress={this.onBillingAddressSameAsHomeAddress}
                            apiErrors={this.state.apiErrors} personalInformation={personalInformation}
                            isEmailVerified={this.state.isEmailVerified} isEmailNotAvailable={this.state.isEmailNotAvailable}
                            onCancel={() => { this.props.history.push('/admin/member-account'); this.resetMessage() }}
                            updateMembershipNumber={true}
                            relaxRequiredValidationsForFields={relaxRequiredValidationsForFields}
                            membershipId={membershipId}
                            customErrorMessages={customErrorMessages}
                            clearCustomError={this.clearCustomErrorMessage}
                            loginEmail={loginEmail}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        )
    }
}

export default EditLoginAndPersonalDetailsPage