import styled, { css } from 'styled-components';
import { mobile, desktop, mobileOnly, desktopOnly, commonPropStyles } from '../../styles/common';

const Title = styled.h1`
    font-weight: 500;
    font-style: normal;
    font-size: 36px;
    line-height: 32px;
    margin-bottom: 2rem;
    ${mobile`
        font-size: 24px;
    `}
    ${props => props.mobileOnly && css`
        ${mobileOnly}
    `}
    ${props => props.desktopOnly && css`
        ${desktopOnly}
    `}
    ${commonPropStyles.mobile}
    ${commonPropStyles.desktop}
    ${props => props.marginTopChild && css`
      ${desktop`
        &:not(:nth-child(1)) {
          margin-top: 50px;
        }
      `}
    `};
    
`;

export default Title;