import React, { useContext } from 'react';
import DynamicParagraphText from '../../elements/DynamicParagraphText';
import UnorderedListText from '../../elements/UnorderedListText';
import AdminContext from '../../contexts/AdminContext';
import { absoluteValue, capitalizeFirstLetter, formatCurrency, formatDateMMDDYYYY, removeLeadingZerosForSingleDigitDate } from '../../common/Helpers';
import CardWithTitle from '../../elements/CardWithTitle';
import CardLineItem from '../../elements/CardLineItem';
import RichText from '../../elements/RichText';
import { BLOCKS } from '@contentful/rich-text-types';
import { colors } from '../../../styles/common';
import StringPlaceholderService from '../../../services/StringPlaceholderService';
import Currency from '../../common/Currency';
import Link from '../../elements/Link';

const linkStyle = { margin: '2rem 0 0 0', fontWeight: 'bold', fontSize: '16px', textAlign: 'center', color: colors.darkBlue };
const redColor = { color: colors.red };

const UnorderedListStyle = ({ children, ...props }) => <UnorderedListText {...props} text={children} />;
const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText style={{ marginTop: '2rem' }} {...props} text={children} />;
const options = {
    renderNode: {
        [BLOCKS.UL_LIST]: (node, children) => <UnorderedListStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</UnorderedListStyle>,
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</ParagraphStyle>,
    },
};

const AdminOutstandingBalance = ({ content }) => {
    const context = useContext(AdminContext);
    const subscription = context?.memberDetails?.subscription || {};

    const previousSubscriptionProductType = context?.memberDetails?.lastCancelledSubscriptionProductType || '';
    const dueDate = formatDateMMDDYYYY(subscription?.dueDate);
    const coverageDaysLeft = subscription.coverageDaysLeft;
    const coverageDateUpTo = formatDateMMDDYYYY(subscription.coverageDateUpTo);
    const outstandingBalance = subscription?.outstandingBalance || '';
    const creditCardNumber = subscription?.defaultPaymentMethod?.creditCardNumber;
    const missedPaymentDeadline = coverageDaysLeft <= 0;
    const properties = {
        dueDate: removeLeadingZerosForSingleDigitDate(dueDate),
        coverageDaysLeft: coverageDaysLeft,
        outstandingBalance: formatCurrency(absoluteValue(outstandingBalance)) || '',
        creditCardType: subscription?.defaultPaymentMethod?.creditCardType || '',
        creditCardNumber: creditCardNumber?.substr(creditCardNumber.length - 4) || '',
        productType: capitalizeFirstLetter(subscription?.productType),
        coverageDateUpTo: removeLeadingZerosForSingleDigitDate(coverageDateUpTo)
    };
    const paymentMissedOutstandingDescriptionProperties = {
        dueDate: removeLeadingZerosForSingleDigitDate(dueDate),
        outstandingBalance: formatCurrency(absoluteValue(outstandingBalance)) || '',
        creditCardType: subscription?.defaultPaymentMethod?.creditCardType || '',
        creditCardNumber: creditCardNumber?.substr(creditCardNumber.length - 4) || '',
        productType: capitalizeFirstLetter(previousSubscriptionProductType)
    };
    const setSubContentValue =(contentData)=>{
        contentData.content.forEach(subContent => {
          if (subContent.value) {
              subContent.value = StringPlaceholderService.replace(subContent.value, properties)
          }
      })
  }
    content.outstandingBalanceDescription.content.forEach(ourstandingContent => {
        setSubContentValue(ourstandingContent);
    });

    content.outstandingBalanceDescWithoutCreditCardDetail.content.forEach(ourstandingContent => {
        setSubContentValue(ourstandingContent);
    });
    
    const setSubPaymentContent = (paymentDescription) =>{
        paymentDescription.content.forEach(subPaymentContent => {
            if (subPaymentContent.value) {
                subPaymentContent.value = StringPlaceholderService.replace(subPaymentContent.value, paymentMissedOutstandingDescriptionProperties)
            }
        })
    }
    content.paymentMissOutstandingDescription.content.forEach(paymentDescription => {
        setSubPaymentContent(paymentDescription);
    });

    content.paymentMissOutstandingDescWithoutCc.content.forEach(paymentDescription => {
        setSubPaymentContent(paymentDescription);
    });
 
    if (outstandingBalance <= 0) {
        return null;
    }

    return (
        <>
            <CardWithTitle firstGrow={true} showRedBorder={true} showWhiteBackgroundWithRedText={true} title='Outstanding Balance'>
                <CardLineItem itemStyle={redColor} itemKey='Due Date' itemValue={removeLeadingZerosForSingleDigitDate(dueDate)}></CardLineItem>
                <CardLineItem itemStyle={redColor} itemKey='Balance Due' itemValue={<Currency value={outstandingBalance} />}></CardLineItem>
                {!missedPaymentDeadline &&
                    <>
                        <CardLineItem itemStyle={redColor} itemKey='Days left in Grace Period' itemValue={coverageDaysLeft}></CardLineItem>
                        <RichText text={creditCardNumber ? content.outstandingBalanceDescription : content.outstandingBalanceDescWithoutCreditCardDetail} options={options} />
                    </>
                }
                {missedPaymentDeadline &&
                    <>
                        <CardLineItem itemStyle={redColor} itemKey={content.gracePeriodPassedText} ></CardLineItem>
                        <RichText text={creditCardNumber ? content.paymentMissOutstandingDescription : content.paymentMissOutstandingDescWithoutCc} options={options} />
                    </>
                }
                <Link style={linkStyle} text={content.outstandingBalanceLink} to='/admin/pay-outstanding-balance' />
            </CardWithTitle>
        </>
    )
};

export default AdminOutstandingBalance;