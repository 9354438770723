import React from "react";
import styled from 'styled-components';
import {desktop, mobile} from './../../styles/common';
import FaqQuestions from './FaqQuestions';
import FaqCategory from './FaqCategory';
import RichText from "../elements/RichText";
import { colors } from "./../../styles/common";

const FaqsDetails = styled.div`
    display: inline-block;
    width:85%;
    padding-left: 7%;
    padding-right: 8%;
    padding-bottom: 5%;
    justify-content: center;
    ${ mobile`
    padding-bottom: 30%;
    flex-direction: column;
 `} 
`;

const Category = styled.section`
    width: 100%;
    ${desktop`
        padding: 0px 80px 0px 0px;
    `}
    box-sizing: border-box;
    ${mobile`
        width: 100%;
    `}
`;

const CategoryList = styled.div`
    width: 100%;
`;

const UL = styled.ul`
    list-style-type: none;
    padding: 0;
`;

const LI = styled.li`
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline;
    align-items: baseline;
    cursor: pointer;
    ${mobile`
    width: 100%;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 8px;
   `}
`;
const MobileFaqQuestions= styled.div`
   ${desktop`
      display:none;
   `}
   ${mobile`
     display:flex;
   `}
`;
const DesktopFaqQuestions= styled.div`
  ${desktop`
    display: inline;
    width: 100%;
    paddingBottom: '0rem';
    paddingTop:'0.5rem';
  `}
  ${mobile`
   display:none;
   `}
`;
const FaqDetails = ({ content, currentCategory, questionIdParam, ...props }) => {
    return (
    <FaqsDetails>
        <Category>
            <CategoryList>
                <UL>
                    {content.sections.map((category) => {
                        return (
                            <LI key={category.fields.title} onClick={() => props.selectCategory(category)}>
                                <FaqCategory category={category} currentCategory={currentCategory} />
                                {(currentCategory === category) && 
                                <MobileFaqQuestions>
                                    <FaqQuestions currentCategory={currentCategory}  questionIdParam={questionIdParam}/>
                                </MobileFaqQuestions>
                                }                        
                                {(currentCategory === category) && 
                                <DesktopFaqQuestions>
                                    <FaqQuestions currentCategory={currentCategory}  questionIdParam={questionIdParam}/>
                                </DesktopFaqQuestions>
                                }
                            </LI>
                        );
                    })}                   
                </UL>                
            </CategoryList>           
        </Category>
        <RichText text={content.disclaimer} />
    </FaqsDetails>
    );
  };

  export default FaqDetails;