import React from 'react';
import Section from '../elements/Section';
import Card from '../elements/Card';
import ReactMarkdown from 'react-markdown';
import { colors } from '../../styles/common';
const PaymentPastDue = ({ content, dynamicContent, isUpgrade }) => {
    return (
        <>
            <Card style={{backgroundColor: 'white', border: `1px solid ${colors.red}`, padding: '1rem 1rem'}}>
                <Section style={{color: `${colors.red}`, fontWeight: '500'}} text={content.title}></Section>
                {isUpgrade && <Section style={{fontWeight: 'bold'}} text={content.subTitle}></Section>}
                <ReactMarkdown source={dynamicContent} />
            </Card>
        </>
    );
};

export default PaymentPastDue;