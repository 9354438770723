import styled from 'styled-components'
import {colors} from '../../styles/common'

const Card = styled.div`
    margin-top: 1.5rem;
    padding: 2rem 3rem;
    box-sizing: border-box;
    background: ${colors.lightGrey};
`;

export default Card;
