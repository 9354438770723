import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import React from 'react';
import { ContentfulService } from '../../../../configuration/ApplicationContext';
import ContentfulIds from '../../../../configuration/ContentfulIds';
import RedirectionService from '../../../../services/RedirectionService';
import { SERVICE_COVERAGE_SELECTED, START_SIGNUP_FLOW, trackEvent } from '../../../../services/SegmentService';
import { colors } from '../../../../styles/common';
import BaseProductOffering from "../../../BaseProductOffering";
import Breadcrumbs from '../../../common/Breadcrumbs';
import { ANNUALLY, BASIC_LOWER_CASE, NON_AAA, PROTECT, SIGNUP, CANCELLED, AAA_MEMBER } from '../../../common/Constants';
import { buildUrl, checkLoginWithAAA, getMemberTypeFromSessionStorage, getRatePlanIds } from '../../../common/Helpers';
import { dynamicSignUpFlowSteps } from '../../../common/Steps';
import DataContext from "../../../contexts/DataContext";
import DynamicParagraphText from '../../../elements/DynamicParagraphText';
import InfoText from '../../../elements/InfoText';
import RichText from '../../../elements/RichText';
import SignupFlowStepProgress from '../../../elements/SignUpFlowStepProgress';
import Spinner from '../../../elements/Spinner';
import Content from '../../../layout/Content';
import withPageTitle from "../../../layout/withPageTitle";
import withZipGate from "../../../layout/withZipGate";
import ProductChart from './ProductChart';

const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText
    {...props}
    text={children}
    style={{ textAlign: 'justify', margin: 0, color: colors.verylightGrey, lineHeight: '20px', paddingTop: '1rem' }}
/>;
var options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) =>
            <ParagraphStyle
                anchor={true}
                desktopOnly='true'
                desktopFontSize='12px'
                mobileFontSize='12px'
            >
                {children}
            </ParagraphStyle>,
        [INLINES.HYPERLINK]: (node, next) => {
            return <a href={node.data.uri}>{node.content[0].value}</a>;
        }
    },
};


class ChooseYourPlanPage extends BaseProductOffering {
    static contextType = DataContext;
    state = {
        productPageURL: '/product',
        checkoutPageURL: '/checkout',
        products: [],
        selectedProduct: null
    };
    componentDidMount() {
        this.setFlowValueInRedirectParamsInSessionStorage(SIGNUP);
        this.checkUserStateAndRedirect();
        this.setHideBasicInState();
        this.handleRedirectionBasedOnSelectedPlan();
        this.loadAndFilterProductChartContent(ContentfulIds.ChooseYourPlanPage);
        this.loadPlans(ContentfulIds.ProductOfferingTitle);
        trackEvent(START_SIGNUP_FLOW);
        this.setBreadCrumbs();
    }
    setHideBasicInState = () => {
        this.setState({
            hideBasic: new URLSearchParams(this.props.location.search).get('hide-basic') === 'true'
        });
    }
    handleRedirectionBasedOnSelectedPlan = async () => {
        const { ratePlanIds } = this.context || {};
        const planType = sessionStorage.getItem('planType');
        const paymentMode = sessionStorage.getItem('paymentMode');
        const ratePlanRedirectionMap = getRatePlanIds(ratePlanIds);
        if (planType && planType !== '' && paymentMode && paymentMode !== '') {
            const redirectTo = ratePlanRedirectionMap.get(planType)[paymentMode];
            const productId = redirectTo.includes('basic-coverage') ? `${BASIC_LOWER_CASE}` : redirectTo.split('/').pop();
            sessionStorage.removeItem('planType');
            sessionStorage.removeItem('paymentMode');
            this.handlePageRedirection(productId, planType, paymentMode);
        } else {     
            const queryString = document.location.search;       
            let url = (this.props.match.params.id || `${ratePlanRedirectionMap.get(PROTECT)[ANNUALLY]}`) + queryString ;
            let params = {};
            const locationUrl = new URLSearchParams(this.props.location.search);
            const alreadySelectedProduct = locationUrl.get('product');
            const alreadySelectedPaymentMode = locationUrl.get('payment-mode');
            if (locationUrl.get('product') !== null) {
                params['product'] = locationUrl.get('product');
            }
            if (locationUrl.get('payment-mode') !== null) {
                params['payment-mode'] = locationUrl.get('payment-mode');
            }
            this.setState({ alreadySelectedProduct, alreadySelectedPaymentMode });
            if (locationUrl.get('hide-basic') === 'true') {
                params['hide-basic'] = true;
            }
            this.props.history.replace(buildUrl(url, params));
        }
    }
    handlePageRedirection = (productId, selectedPlan, paymentMode) => {
        const memberType = getMemberTypeFromSessionStorage();
        let params = {};
        if (productId !== BASIC_LOWER_CASE) {
            params.paymentMode = paymentMode;
        }
        params.plan = selectedPlan;
        const { userDetails } = this.context || {};
        const membershipStatus = userDetails?.membership?.membershipStatus;
        if (checkLoginWithAAA() && memberType === AAA_MEMBER && (membershipStatus === CANCELLED || userDetails.invalidMembershipNumber)) {
            this.props.history.push('/inactive-membership');
        } else if (checkLoginWithAAA() || memberType === NON_AAA) {
            this.props.history.push(`/checkout/${productId}/personal-info`);
        } else {
            this.props.history.push(buildUrl(`/login-with-aaa/${productId}`, params));
        }
    }
    loadAndFilterProductChartContent = async (contentfulId) => {
        const productChart = await ContentfulService.getEntries({ 'sys.id': contentfulId, include: 5 });
        const productChartContent = productChart.items[0].fields;
        const { hideBasic } = this.state || {};
        if (hideBasic) {
            productChartContent.sections = productChartContent.sections.filter(section => section.fields.title.toLowerCase() !== BASIC_LOWER_CASE)
        }
        this.setState({ productChartContent });
    }
    checkUserStateAndRedirect = () => {
        const { userDetails } = this.context || {};
        if (userDetails.subscription) {
            this.props.history.replace(RedirectionService.getConditionalRenderURL(null, userDetails));
        }
    }

    setBreadCrumbs = () => {
        const breadCrumbRoutes = [
            {
                to: '/', label: 'Home'
            }
        ];
        this.setState({
            breadCrumbRoutes
        });
    }

    onProductSelectToCreateSubscription = (productName, paymentMode) => {
        const { products } = this.state || {};
        if (!products) {
            return;
        }
        const productsByPaymentMode = products.filter(product => product.paymentMode.toLowerCase() === paymentMode.toLowerCase());
        const selectedProduct = productsByPaymentMode.find(product => product.name.toLowerCase() === productName.toLowerCase());
        this.context.updateState({ product: selectedProduct });
        trackEvent(SERVICE_COVERAGE_SELECTED, {
            memberId: this.context.userDetails.userId,
            tierSelected: productName,
            subscriptionOption: paymentMode
        });
        const productId = productName.toLowerCase() === BASIC_LOWER_CASE ? `${BASIC_LOWER_CASE}` : selectedProduct?.id;
        if (!productId) {
            return;
        }
        this.handlePageRedirection(productId, productName, paymentMode);
    }

    render() {
        const { breadCrumbRoutes, productChartContent, alreadySelectedProduct, products } = this.state || {};
        if (products.length === 0 || !productChartContent) {
            return <Spinner />;
        }
        return (
            <Content>
                <InfoText mobileOnly={true}>
                    <Breadcrumbs breadCrumbRoutes={breadCrumbRoutes} showOneBreadcrumb={true}></Breadcrumbs>
                </InfoText>
                <SignupFlowStepProgress
                    currentStep={1}
                    steps={dynamicSignUpFlowSteps(false, true, { productId: this.props.match.params.id })}
                />
                <ProductChart
                    backgroundcolor={colors.pureWhite}
                    customStyle={true}
                    productchartsectionpadding='0px'
                    fields={productChartContent}
                    choosePlan={true}
                    alreadySelectedProduct={alreadySelectedProduct}
                    onProductSelectToCreateSubscription={this.onProductSelectToCreateSubscription}
                />
                <RichText
                    text={productChartContent.joinAaaText}
                    options={options}
                />
            </Content>
        );
    }
}

export default withZipGate(withPageTitle(ChooseYourPlanPage, "Choose Your Plan"), { noindex: true });