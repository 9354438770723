import { ANNUALLY, FREE_UPPER_CASE, MONTHLY } from "../components/common/Constants";

class UtilsService {
    static mapProductsToArray = (products) => {
        const mappedProducts = [];
        products.forEach(product => {
            if (product.name === FREE_UPPER_CASE) {
                mappedProducts.push({
                    name: product.name?.charAt(0) + product.name?.slice(1).toLowerCase(),
                    contentfulProductOfferingId: product.contentfulProductOfferingId,
                    paymentMode: ANNUALLY,
                    id: product?.id,
                    displayName: product?.displayName
                });
                mappedProducts.push({	
                    name: product.name?.charAt(0) + product.name?.slice(1).toLowerCase(),	
                    contentfulProductOfferingId: product.contentfulProductOfferingId,	
                    paymentMode: MONTHLY,	
                    id: product?.id,
                    displayName: product?.displayName	
                });
            } else {
                mappedProducts.push({
                    name: product.name?.charAt(0) + product.name?.slice(1).toLowerCase(),
                    paymentMode: MONTHLY,
                    ...product.monthlyRatePlan,
                    contentfulProductOfferingId: product.contentfulProductOfferingId,
                    totalRefundAmount: product?.totalRefundAmount,
                    displayName: product?.displayName
                });
                mappedProducts.push({
                    name: product.name?.charAt(0) + product.name?.slice(1).toLowerCase(),
                    paymentMode: ANNUALLY,
                    ...product.annualRatePlan,
                    contentfulProductOfferingId: product.contentfulProductOfferingId,
                    totalRefundAmount: product?.totalRefundAmount,
                    displayName: product?.displayName
                });
            }
        });
        return mappedProducts;
    }

    static mapProductToArray = (product) => {
        const mappedProducts = [];
        mappedProducts.push({
            name: product.name?.charAt(0) + product.name?.slice(1).toLowerCase(),
            paymentMode: MONTHLY,
            ...product.monthlyRatePlan,
            contentfulProductOfferingId: product.contentfulProductOfferingId,
            displayName: product?.displayName
        });
        mappedProducts.push({
            name: product.name?.charAt(0) + product.name?.slice(1).toLowerCase(),
            paymentMode: ANNUALLY,
            ...product.annualRatePlan,
            contentfulProductOfferingId: product.contentfulProductOfferingId,
            displayName: product?.displayName
        });
        return mappedProducts;
    }

    static mapBundleProductsToArray = (products) => {
        const mappedBundleProducts = [];
        products.forEach(product => {
            mappedBundleProducts.push({
                name: product.name?.charAt(0) + product.name?.slice(1).toLowerCase(),
                paymentMode: ANNUALLY,
                ...product.annualRatePlan,
                contentfulProductOfferingId: product.contentfulProductOfferingId,
                displayName: product?.displayName
            });
        });
        return mappedBundleProducts;
    }
}


export default UtilsService;