
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Title from "../elements/Title";
import DynamicParagraphText from "../elements/DynamicParagraphText";
import Button from "../elements/Button";
import OverlayCard from "../elements/OverlayCard";
import styled, { css } from 'styled-components'
import { colors, desktopOnly, mobileOnly } from '../../styles/common';
import RichText from "../elements/RichText";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Link from "../elements/Link";
import ImageAsset from "../common/ImageAsset";
import { variation } from "../../configuration/ApplicationContext";

const Container = styled.section`
    position: relative;
`;

const ImageLayout = styled.div`
   ${props => props.mobileOnly && css`
    ${mobileOnly};
   `}

   ${props => props.desktopOnly && css`
     ${desktopOnly}
   `};
`;

const styles = {
    title: { color: colors.pureWhite, marginBottom: '0.5rem', marginTop: '0', fontStyle: 'normal', fontWeight: 500, fontSize: '48px', lineHeight: '55px' },
    description: { fontSize: '24px', color: colors.pureWhite, marginTop: '1rem', paddingRight: '4em' },
    button: { textAlign: 'center', width: '12rem', backgroundColor: colors.darkBlue, color: colors.pureWhite, fontSize: '19px' },
    mobileOverlay: { width: '67%', color: colors.pureWhite, height: 'auto', left: 0, padding: '10px', top: '50%', transform: 'translate(0%, -50%)' },
    mobileTitle: { fontSize: '24px', lineHeight: 'unset', color: colors.pureWhite, margin: 0 },
    desktopOverlay: { top: '18%', width: '680px', position: 'absolute' },
    mobileOverlayDescription: { fontSize: '11px', color: colors.pureWhite, lineHeight: 'unset' },
    mobileOverlayButton: { textAlign: 'center', width: '10rem', backgroundColor: colors.darkBlue, color: colors.pureWhite, fontSize: '12px', lineHeight: 'unset' },
    mobileImage: { width: '100%', background: `linear-gradient(to left, ${colors.darkBlue} 0%, ${colors.darkRed} 100%) left bottom no-repeat`, backgroundSize: '100% 10px' },
    paragraph: { color: colors.snow, paddingTop: '10px' },
    joinAAAHyperLink: { display: 'inline-block', fontSize: '17.5px', textDecoration: 'underline', color: colors.snow },
    joinAAAParagraphMobile: { color: colors.snow, fontWeight: '500' },
    joinAAAHyperLinkStyle: { display: 'inline', fontSize: '10.5px', textDecoration: 'underline', color: colors.snow }
};

const joinAAAOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) =>
            <DynamicParagraphText
                style={styles.paragraph}
                desktopFontSize='17.5px'
                mobileFontSize='10.5px' text={children}
            />,
        [INLINES.HYPERLINK]: (node) =>
            <Link
                style={styles.joinAAAHyperLink}
                to={node.data.uri}
                text={node.content[0].value}
            />
    },
};

const joinAAAOptionsMobileStyle = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) =>
            <DynamicParagraphText
                style={styles.joinAAAParagraphMobile}
                mobileFontSize='10.5px' text={children}
            />,
        [INLINES.HYPERLINK]: (node) =>
            <Link
                style={styles.joinAAAHyperLinkStyleMobile}
                to={node.data.uri}
                text={node.content[0].value}
            />
    },
};

const CarouselContent = ({ section, onSignup }) => {
    const description = variation.freeTrial === true ? section.fields.freeTrialDescription : section.fields.description;
    const buttonText = variation.freeTrial === true ? section.fields.freeTrialButtonText : section.fields.buttonText;
    return (
        <Container
            key={section.sys.id}
            style={{ position: 'relative' }}>
            <ImageLayout
                desktopOnly='true'>
                <ImageAsset
                    width='100%'
                    image={section.fields.image}
                />
            </ImageLayout>
            {
                section.fields.imageMobile.fields &&
                <ImageLayout
                    mobileOnly='true'
                    style={{ position: 'relative' }}>
                    <ImageAsset
                        width='100%'
                        image={section.fields.imageMobile}
                        style={styles.mobileImage}
                    />
                    <OverlayCard
                        mobileOnly='true'
                        style={styles.mobileOverlay}>
                        <Title
                            style={styles.mobileTitle}>
                            {section.fields.title}
                        </Title>
                        <DynamicParagraphText
                            style={styles.mobileOverlayDescription}
                            text={description}
                        />
                        <Button
                            style={styles.mobileOverlayButton}
                            text={buttonText}
                            onClick={() => onSignup("Home Page | Hero CTA Clicked")}
                        />
                        <RichText
                            text={section.fields.joinAaaText}
                            options={joinAAAOptionsMobileStyle}
                        />
                    </OverlayCard>
                </ImageLayout>
            }
            <OverlayCard
                desktopOnly='true'
                style={styles.desktopOverlay}>
                <Title
                    style={styles.title}>
                    {section.fields.title}
                </Title>
                <DynamicParagraphText
                    style={styles.description}
                    text={description}
                />
                <Button
                    style={styles.button}
                    text={buttonText}
                    onClick={() => onSignup("Home Page | Hero CTA Clicked")}
                />
                <RichText
                    text={section.fields.joinAaaText}
                    options={joinAAAOptions}
                />
            </OverlayCard>
        </Container>
    );
}

export default CarouselContent;