import React from 'react';
import { Api, ContentfulService, segmentAnalytics } from '../configuration/ApplicationContext';
import ContentfulIds from '../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from './layout/ResponsiveSignupFlowLayout';
import Card from './elements/Card';
import Content from './layout/Content';
import Title from './elements/Title';
import ParagraphText from './elements/ParagraphText';
import PersonalInfoForm from './forms/PersonalInfoForm/PersonalInfoForm';
import SignupFlowStepProgress from './elements/SignUpFlowStepProgress';
import moment from 'moment';
import DataContext from './contexts/DataContext';
import Spinner from './elements/Spinner';
import withPageTitle from "./layout/withPageTitle";
import UtilsService from '../services/UtilsService';
import { checkIsLoginProcessCompleted, addAmobeeTag, subscriptionApplicationIdSessionStorage, buildUrl, getMemberTypeFromSessionStorage } from './common/Helpers';
import Summary from './summary/Summary';
import RichText from './elements/RichText';
import { dynamicSignUpFlowSteps } from './common/Steps';
import { createSummaryItems } from './summary/SummaryItems';
import { AAA_USER, BASIC, BASIC_LOWER_CASE, CANCELLED, FREE, NON_AAA } from './common/Constants';
import BaseComponent from './BaseComponent';
import { AMOBEE_SUF_NEW_FREE_MEMBER_TAG_URL } from './common/Constants';
import InfoText from './elements/InfoText';
import Breadcrumbs from './common/Breadcrumbs';
import withPageMetaTags from './layout/withPageMetaTags';

class PersonalInfoPage extends BaseComponent {
    static contextType = DataContext;
    state = {
        apiErrors: [],
        isLoading: false,
    };

    componentDidMount() {
        this.checkUserStateAndRedirect(true);
        this.getProductsAndSelectProduct();
        this.loadContent();
        this.populateAddress();
        this.trackEvent();
        this.applyPromoCodeFromSessionStorage();
    }

    trackEvent = () => {
        if (this.context.isLoggedIn) {
            const { userDetails: { userId, membershipId, membership } } = this.context || {};
            segmentAnalytics.track('Continue SUF PAST SSO', {
                memberId: userId,
                userName: membership?.email,
                AAA_membership_id: membershipId,
                name: membership?.displayName,
                phoneNumber: membership?.phoneNumber,
                address: membership?.address
            });
        }
    }

    setBreadCrumbs = (selectedProduct) => {
        if (!selectedProduct) {
            return;
        }
        const chooseYourPlanPageUrl = `/product/${this.props.match.params.ratePlanId}`;
        let params = {};
        params['product'] = Object.keys(selectedProduct).length === 0 ? BASIC : selectedProduct.name;
        if (Object.keys(selectedProduct).length !== 0) {
            params['payment-mode'] = selectedProduct.paymentMode;
        }
        const breadCrumbRoutes = [
            {
                to: buildUrl(chooseYourPlanPageUrl, params), label: 'Choose Your Plan'
            }
        ];
        this.setState({
            breadCrumbRoutes
        });
    }

    getProductsAndSelectProduct = async (validateAddress = false) => {
        let params = {};
        const { userDetails } = this.context || {};
        const membershipStatus = userDetails?.membership?.membershipStatus;
        if (this.context.isLoggedIn) {
            params.shouldCheckAddress = validateAddress;
            params.isFreeTrialUser = this.context.variation.freeTrial === true;
            if (membershipStatus === CANCELLED || userDetails.invalidMembershipNumber) {
                params.isNonAaaMember = true;
            }
        } else {
            params.isNonAaaMember = true;
            const subscriptionApplicationId = sessionStorage.getItem(subscriptionApplicationIdSessionStorage);
            if (subscriptionApplicationId) {
                params['subscription-application-id'] = subscriptionApplicationId;
            }
        }
        const zuoraRatePlansURL = buildUrl('/api/products/zuora-rate-plans', params);
        const ratePlanResponse = await Api.get(zuoraRatePlansURL);
        const mappedProducts = UtilsService.mapProductsToArray(ratePlanResponse.products);
        const foundProduct = mappedProducts.find(product => product.id === this.props.match.params.ratePlanId);
        const selectedProduct = foundProduct ? foundProduct : {};
        this.context.updateState({ product: selectedProduct });
        localStorage.setItem('productsWithoutPromoCodes', JSON.stringify(ratePlanResponse.products));
        this.setState({
            selectedProduct,
        });
        this.setBreadCrumbs(selectedProduct);
    }

    async populateAddress() {
        if (this.context.isLoggedIn) {
            const { userDetails: { personalInformation, membership } } = this.context || {};
            this.setState({
                homeAddress: personalInformation ? personalInformation?.homeAddress : membership?.address
            });
        } else {
            const personalInformation = await this.context.getPersonalInformation();
            this.setState({
                homeAddress: personalInformation?.homeAddress
            });
        }
    }

    onSubmit = async (data) => {
        this.setState({
            apiErrors: []
        });
        const { firstName, middleName, lastName, email, dob, phoneNumber, additionalHomeInfo } = data.values;
        const homeAddress = { ...this.state.homeAddress, lineTwo: additionalHomeInfo };
        if (Object.keys(data.errors).length > 0) {
            return;
        }
        const apiPayload = {
            firstName,
            middleName,
            lastName,
            email,
            dob: moment(dob).format('YYYY-MM-DD'),
            phoneNumber: phoneNumber.replace(/[^\d]/g, ''),
            homeAddress,
            billingAddress: null,
            billingAddressSameAsHomeAddress: true
        };
        this.setState({
            isLoading: true,
        });
        const response = this.context.isLoggedIn ?
            await Api.post('/api/personal-information', apiPayload) :
            await Api.post('/api/bundle/subscription-applications', {
                id: sessionStorage.getItem(subscriptionApplicationIdSessionStorage) || null,
                personalInformation: apiPayload,
            });
        if (!response) {
            this.setState({
                isLoading: false,
            });
            return;
        }
        if (response.validationErrors) {
            const apiErrors = response.validationErrors.map(apiError => {
                if (apiError.message === "MigratedUser already exist.") {
                    let userDetails = this.context.userDetails;
                    userDetails.personalInformation = apiPayload
                    this.context.updateState({
                        userDetails: userDetails
        });
            this.props.history.push(`/migrated-user-account-already-exist`);
            return;
           }
                return apiError.message
            });
            this.setState({
                apiErrors,
                isLoading: false,
            });
            return;
        }
        if (!this.context.isLoggedIn) {
            sessionStorage.setItem(subscriptionApplicationIdSessionStorage, response.id);
        }
        await this.getProductsAndSelectProduct();
        await segmentAnalytics.checkForOptOutStatus(email);
        segmentAnalytics.track('Submit Personal Information', {
            memberId: this.context.userDetails.userId
        });
        segmentAnalytics.track('Member Identification', {
            memberId: this.context.userDetails.userId,
            userName: response.email,
            AAA_membership_id: this.context.userDetails?.membership?.membershipId,
            name: response.firstName + ' ' + response.lastName,
            phoneNumber: response.phoneNumber,
            homeAddress: response.homeAddress
        });
        let userDetails = this.context.userDetails;
        if (!this.context.isLoggedIn) {
            userDetails.personalInformation = response.personalInformation
        } else {
            userDetails.personalInformation = response;
        }
        this.context.updateState({
            userDetails: userDetails
        });
        const addressResponse = await Api.get('/api/personal-information/validate-address');
        if (!addressResponse) {
            this.props.history.push(`/checkout/${this.props.match.params.ratePlanId}/personal-info/address-info`);
            return;
        }
        if (this.props.match.params.ratePlanId !== BASIC_LOWER_CASE) {
            const response = await this.getProductsAndSelectProduct(true);
            response ? this.props.history.push(`/checkout/${this.props.match.params.ratePlanId}/personal-info/address-info`) : this.props.history.push(`/checkout/${this.props.match.params.ratePlanId}/payment`);
        } else {
            // create basic subscription
            await Api.post('/api/subscriptions/basic-coverage');
            segmentAnalytics.track('SUF New Free Member', {
                memberId: this.context.userDetails.userId,
                userName: email,
                AAA_membership_id: this.context.userDetails.membershipId,
                name: response.displayName,
                phoneNumber,
                address: homeAddress,
                tierSelected: FREE,
                MemberType: AAA_USER
            });
            addAmobeeTag(AMOBEE_SUF_NEW_FREE_MEMBER_TAG_URL);
            this.props.history.push('/subscription-complete');
        }
    };
    onSelectAddress = (homeAddress) => {
        this.setState({
            homeAddress
        });
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.PersonalInformationForm);
        this.setState({ content: response.fields });
    }

    render() {
        const isLoginProcessCompleted = checkIsLoginProcessCompleted();
        const {
            content,
            selectedProduct,
            isLoading,
            isPromoCodeProcessing
        } = this.state;
        const { membership } = this.context.userDetails;
        const personalInformation = this.context.userDetails.personalInformation ? this.context.userDetails.personalInformation : this.context.personalInformation;
        const { ratePlanId } = this.props.match.params;
        if (!content || !selectedProduct || isLoading || isPromoCodeProcessing) {
            return <Spinner />;
        }
        return (
            <Content>
                <InfoText mobileOnly={true}>
                    <Breadcrumbs
                        breadCrumbRoutes={this.state.breadCrumbRoutes}
                        showOneBreadcrumb={true}
                    />
                </InfoText>
                <SignupFlowStepProgress
                    currentStep={getMemberTypeFromSessionStorage() != NON_AAA ? 3 : 2}
                    steps={
                        dynamicSignUpFlowSteps(
                            ratePlanId === BASIC_LOWER_CASE,
                            true,
                            {
                                productId: this.props.match.params.ratePlanId
                            }
                        )
                    }
                />
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title
                            style={{ marginTop: '1rem' }}>
                            {content.title}
                        </Title>
                        <ParagraphText>
                            {content.description}
                        </ParagraphText>
                        <PersonalInfoForm
                            onSubmit={this.onSubmit}
                            personalInformation={personalInformation}
                            onSelectAddress={this.onSelectAddress}
                            membership={membership}
                            apiErrors={this.state.apiErrors}
                            content={content}
                            isSubmitDisabled={isLoading}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        {
                            ratePlanId !== BASIC_LOWER_CASE &&
                            <Card>
                                <Summary
                                    product={selectedProduct}
                                    title={`Identity Champion ${selectedProduct.name}`}
                                    flags={
                                        {
                                            showFreeTrialDisclosure: this.context.isLoggedIn && this.context.variation.freeTrial === true
                                        }
                                    }
                                    items={createSummaryItems(selectedProduct, this.context)}
                                />
                            </Card>
                        }
                        {
                            ratePlanId === BASIC_LOWER_CASE &&
                            <Card>
                                <RichText
                                    text={content.plansInclude}
                                />
                            </Card>
                        }
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageMetaTags(withPageTitle(PersonalInfoPage, "Personal Information"), { noindex: true });