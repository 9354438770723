import React, { useContext } from 'react';
import CardWithTitle from '../elements/CardWithTitle';
import CardLineItem from '../elements/CardLineItem';
import moment from 'moment';
import Link from '../elements/Link';
import RichText from '../elements/RichText';
import { BLOCKS } from '@contentful/rich-text-types';
import UnorderedListText from '../elements/UnorderedListText';
import { colors } from '../../styles/common';
import { removeLeadingZerosForSingleDigitDate, formatCurrency, capitalizeFirstLetter, absoluteValue } from '../common/Helpers';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import DataContext from '../contexts/DataContext';
import StringPlaceholderService from '../../services/StringPlaceholderService';
import Currency from '../common/Currency';

const redColor = { color: colors.red };

const linkStyle = {
    margin: '2rem 0 0 0',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'center',
    color: colors.blue
}

const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText style={{ marginTop: '2rem' }} {...props} text={children} />;

const UnorderedListStyle = ({ children, ...props }) => <UnorderedListText {...props} text={children} />;

const options = {
    renderNode: {
        [BLOCKS.UL_LIST]: (node, children) => <UnorderedListStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</UnorderedListStyle>,
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</ParagraphStyle>,
    },
};

const OutstandingBalance = ({ content }) => {
    const context = useContext(DataContext);
    const subscription = context?.userDetails?.subscription;
    const dueDate = moment(subscription?.dueDate)?.format('M/D/YYYY') || '';
    const outstandingBalance = subscription?.outstandingBalance || '';
    const creditCardNumber = subscription?.defaultPaymentMethod?.creditCardNumber;

    const properties = {
        dueDate: removeLeadingZerosForSingleDigitDate(dueDate),
        outstandingBalance: formatCurrency(absoluteValue(outstandingBalance)) || '',
        creditCardType: subscription?.defaultPaymentMethod?.creditCardType || '',
        creditCardNumber: creditCardNumber?.substr(creditCardNumber.length - 4) || '',
        productType: capitalizeFirstLetter(subscription?.productType)
    };

    const setPrValue = (ct) =>{
        ct.content.forEach(pr => {
            if (pr.value) {
                pr.value = StringPlaceholderService.replace(pr.value, properties)
            }
        })
    }

     content.outstandingBalanceDescription.content.forEach(ct => {
        setPrValue(ct);
    });

    content.outstandingBalanceDescWithoutCreditCardDetail.content.forEach(ct => {
        setPrValue(ct);
    });

    if (outstandingBalance <= 0) {
        return null;
    }

    return (
        <>
            <CardWithTitle marginBottom='1rem' title='Your Outstanding Balance'>
                <CardLineItem itemStyle={redColor} itemKey='Due Date' itemValue={dueDate}></CardLineItem>
                <CardLineItem itemStyle={redColor} itemKey='Balance Due' itemValue={<Currency value={outstandingBalance} />}></CardLineItem>
                <RichText text={creditCardNumber ? content.outstandingBalanceDescription : content.outstandingBalanceDescWithoutCreditCardDetail} options={options} />
                <Link style={linkStyle} text={content.outstandingBalanceLink} to='/pay-outstanding-balance?notification=true' />
            </CardWithTitle>
        </>
    )
};

export default OutstandingBalance;