import BaseApiService from './BaseApiService';
class AdminApiService extends BaseApiService {
  constructor(baseUrl) {
    super();
    this.baseUrl = `${baseUrl}/admin`;
  }

  async login() {
    window.location.href = this.resolve(`/api/login`);
  }
}
export default AdminApiService;
