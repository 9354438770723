import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { colors, mobile } from '../../styles/common';
import Span from '../elements/Span';
import Link from '../elements/Link';
import { AdminApi } from '../../configuration/ApplicationContext';
import RichText from '../elements/RichText';
import AnchorLink from "./../elements/AnchorLink";
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import { isValueString } from '../common/Helpers';

const NotificationStyleComponent = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    font-size: 17px;
    align-items: center;
    margin-bottom: 5px;
    line-height: 1.5;
    ${mobile`
        font-size: 14px;
    `}
    ${props => props.severity && props.severity === 'success' && css`
        background: ${colors.successBackground};
        color: ${colors.successMessage};
    `};
    ${props => props.severity && props.severity === 'error' && css`
        background: ${colors.errorBackground};
        color: ${colors.darkRed};
    `};
    ${props => props.severity && props.severity === 'info' && css`
        background: ${colors.infoBackground};
        color: ${colors.titleText};
    `};
`;

const CloseIcon = styled.span`
    width: 5%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    padding: 0.5rem 1rem;
    align-self: start;
    &::after {
        content: "\\00d7";
        ${props => props.severity && props.severity === 'success' && css`
            background: ${colors.successBackground};
            color: ${colors.successMessage};
        `};
        ${props => props.severity && props.severity === 'error' && css`
            background: ${colors.errorBackground};
            color: ${colors.darkRed};
        `};
        ${props => props.severity && props.severity === 'info' && css`
            background: ${colors.infoBackground};
            color: ${colors.titleText};
        `};
        font-weight: 500;
    }        
    ${props => props.hideCancelIcon && css`
        display: none;
    `};
`;

const LinkSectionComponent = styled.div`
    width: 95%;
    padding: 1rem;
`;
const LinkDiv = styled.div`
`;

const linkStyle = {
    fontSize: '13px',
    fontWeight: 'bold',
    color: colors.darkRed,
    marginTop: '0.5rem',
    display: 'inline-block'
};
const Notification = (props) => {
    const [showNotification, setShowNotification] = useState(props.showNotification);
    const sendBackendCall = async (url) => {
        await AdminApi.get(url);
    }
    const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText desktopFontSize='17px' mobileFontSize='14px' id="rich-text" {...props} text={children} />;
    const CallToActionStyle = ({ children, ...props }) => <Span {...props} text={children} />;

    const setOptions = () => {
        const options = {
            renderMark: {
                [MARKS.CODE]: text => <CallToActionStyle style={{ fontSize: '13px' }}>{text}</CallToActionStyle>
            },
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle style={{ margin: 0 }}>{children}</DynamicParagraphStyle>,
                [INLINES.HYPERLINK]: (node) => getHyperLinkType(node, props.showHyperLinkInNotification, props.openInNewTab)
            },
        };
        return options;
    }

    const getHyperLinkType = (node, showHyperLinkInNotification, openInNewTab) => {
        if (showHyperLinkInNotification)
            return <AnchorLink style={linkStyle} target={openInNewTab ? '_blank' : '_self'} rel="noopener noreferrer" href={node.data.uri} desktoponly='true' text={node.content[0].value} />
        else
            return <Link style={linkStyle} to={node.data.uri} text={node.content[0].value} />
    }

    return (
        <>
            {
                showNotification &&
                <NotificationStyleComponent
                    severity={props.severity}>
                    <LinkSectionComponent>
                        {
                            (props.displayTextAsString === true || isValueString(props.message))
                                ? <Span text={props.message}>
                                </Span>
                                : <RichText text={props.message} options={setOptions()}
                                />
                        }
                        {
                            props.dateOn &&
                            <Span
                                style={{ fontWeight: 'bold' }}
                                text={`${props.dateOn}.`}>
                            </Span>
                        }
                        {
                            props.link &&
                            <LinkDiv>
                                <Link
                                    style={props.link.overrideStyles
                                        ? { ...linkStyle, ...props.link.overrideStyles }
                                        : linkStyle}
                                    text={props.link.text}
                                    to={props.link.url}
                                />
                            </LinkDiv>
                        }
                        {
                            props.callToAction &&
                            <Link
                                style={linkStyle}
                                text={props.callToAction.text}
                                onClick={(url) => { sendBackendCall(props.callToAction.url) }}
                            />
                        }
                    </LinkSectionComponent>
                    <CloseIcon
                        severity={props.severity}
                        hideCancelIcon={props.hideCancelIcon}
                        onClick={() => { setShowNotification(false); }}>
                    </CloseIcon>
                </NotificationStyleComponent>
            }
        </>
    );
};

export default Notification;
