import { ContentfulService, AdminApi, segmentAnalytics } from '../../configuration/ApplicationContext';
import { AAA_USER, ANNUALLY, CANCELLED, FREE_UPPER_CASE, NON_AAA_MEMBER, NON_AAA_USER } from '../common/Constants';
import { buildUrl, capitalizeFirstLetter, getMemberTypeValue, subscriptionApplicationIdSessionStorage, validateInputAddress } from '../common/Helpers';
import BaseAdminComponent from './BaseAdminComponent';

export default class BaseAdminUpgradeTierCheckout extends BaseAdminComponent {
    async loadPlans() {
        const userType = this.props.match.params.userType;
        if (userType !== NON_AAA_MEMBER) {
            if (!this.context.memberDetails) {
                return;
            }
            const {memberDetails} = this.context;
            let zuoraRatePlansURL = `/api/products/users/${this.context.searchResult.userId}/zuora-rate-plans?subscriptionAction=${this.state.subscriptionAction}`;
            if ((!memberDetails?.membership || memberDetails?.membership.membershipStatus === CANCELLED) 
                  && this.state.subscriptionAction !== 'CREATE') {
                zuoraRatePlansURL += '&isNonAaaMember=true'
                this.setState({
                    isNonAaaMemberParam: true
                });
            }
            const response = await AdminApi.get(`${zuoraRatePlansURL}`);
            response.products = response.products.find(product => product.name !== 'FREE'
                && (product.annualRatePlan.id === this.props.match.params.productId
                    || product.monthlyRatePlan.id === this.props.match.params.productId));
            this.selectProduct(response.products, this.props.match.params.productId);
        } else {
            this.loadPlansForNonAaaMember();
        }
    }
    loadPlansForNonAaaMember = async () => {
        let params = {};
        params.isNonAaaMember = true;
        const subscriptionApplicationId = sessionStorage.getItem(subscriptionApplicationIdSessionStorage);
        if (subscriptionApplicationId) {
            params['subscription-application-id'] = subscriptionApplicationId;
        }
        const zuoraRatePlansUrl = buildUrl('/api/products/zuora-rate-plans', params);
        const response = await AdminApi.get(zuoraRatePlansUrl);
        if (!response) {
            return;
        }
        localStorage.setItem('productsWithoutPromoCodes', JSON.stringify(response.products));
        response.products = response.products.find(product => product.name !== FREE_UPPER_CASE
            && (product.annualRatePlan.id === this.props.match.params.productId
                || product.monthlyRatePlan.id === this.props.match.params.productId));
        this.selectProduct(response.products, this.props.match.params.productId);
    }

    async loadZuoraForm() {
        const zuoraConfiguration = await AdminApi.get('/api/orders/payment-form');
        this.setState({ zuoraConfiguration })
    }

    selectProduct(product, productId) {
        const selectedProduct = product.annualRatePlan.id === productId ? product.annualRatePlan : product.monthlyRatePlan;
        selectedProduct.paymentMode = product.annualRatePlan.id === productId ? 'Annually' : 'Monthly';
        selectedProduct.name = capitalizeFirstLetter(product.name);
        selectedProduct.contentfulProductOfferingId = product.contentfulProductOfferingId;
        selectedProduct.totalRefundAmount = product.totalRefundAmount;
        this.setState({ product: selectedProduct });
        this.context.updateState({ product: selectedProduct });
    }

    async loadContent(contentfulId) {
        const response = await ContentfulService.getEntry(contentfulId);
        this.setState({ content: response.fields })
        this.setState({ originalContent: JSON.parse(JSON.stringify(response.fields)) })

    }

    loadPersonalInformation() {
        const userType = this.props.match.params.userType;
        const searchResult = JSON.parse(localStorage.getItem('searchResult'));
        const personalInfo = userType !== NON_AAA_MEMBER
            ? this.context.memberDetails?.personalInformation || {}
            : searchResult.personalInformation || {};
        const billingAddressSameAsHomeAddress = personalInfo.billingAddress ? personalInfo.billingAddressSameAsHomeAddress : true;
        personalInfo.billingAddress = personalInfo.billingAddress ? personalInfo.billingAddress : personalInfo.homeAddress;
        this.setState({
            personalInfo,
            billingAddressSameAsHomeAddress
        });
    }


    onSelectAddress = (address) => {
        const personalInfo = this.state.personalInfo;
        personalInfo.billingAddress = address;
        if (!validateInputAddress(address)) {
            this.setState({
                personalInfo,
                checkoutError: "Please enter valid billing address"
            });
        } else {
            this.setState({
                personalInfo,
                checkoutError: ''
            });
        }
    }

    setBreadCrumb = () => {
        const { personalInformation } = this.context.memberDetails || {};
        const breadCrumbRoutes = [
            {
                to: '/admin/member-account', label: personalInformation?.displayName
            },
            {
                to: `${this.state.productPageURL}/${this.props.match.params.productId}`, label: 'Upgrade'
            },
            {
                to: `${this.state.checkoutPageURL}/${this.props.match.params.productId}`, label: 'Payment'
            }
        ]
        this.setState({ breadCrumbRoutes });
    }

    prepopulateFields() {
        const { subscription } = this.context.memberDetails || {};
        this.setState({
            prepopulateFields: {
                creditCardHolderName: subscription?.defaultPaymentMethod?.cardHolderName || ''
            }
        });
    }

    trackPaymentSuccessEvent = (eventName) => {
        const { personalInformation} = this.context.memberDetails || {};
        const { membershipId, creationSource} = this.context.memberDetails;
        const memberType = getMemberTypeValue(creationSource);
        segmentAnalytics.track(eventName, {
            oneLoginID: this.context.getLoginId(),
            memberId: this.context.memberDetails.userId,
            firstName: personalInformation.firstName,
            lastName: personalInformation.lastName,
            email: personalInformation.email,
            homeAddress: personalInformation.homeAddress,
            city: personalInformation.homeAddress.city,
            state: personalInformation.homeAddress.state,
            zip: personalInformation.homeAddress.zipCode,
            phoneNumber: personalInformation.phoneNumber,
            dob: personalInformation.dob,
            aaaMemberNumber: membershipId,
            tierSelected: this.context.product.name,
            subscriptionOption: `${this.context.product.paymentMode === ANNUALLY ? 'Annual' : this.context.product.paymentMode}`,
            MemberType: memberType ? memberType : membershipId ? AAA_USER : NON_AAA_USER
        });
    }

    onApplyPromoCode = async (promoCodeData) => {
        this.setState({ promoCodeData });
        if (promoCodeData.products) {
            const product = promoCodeData.products.find(product => product.name !== 'FREE'
                && (product.annualRatePlan.id === this.props.match.params.productId
                    || product.monthlyRatePlan.id === this.props.match.params.productId));
            this.selectProduct(product, this.props.match.params.productId);
        }
        const originalContent = JSON.parse(JSON.stringify(this.state.originalContent))
        this.setState({ content: originalContent })
    }
}