import React from 'react';
import NotificationContext from './NotificationContext';
import { Api, ContentfulService } from '../../configuration/ApplicationContext';
import ContentfulIds from '../../configuration/ContentfulIds';
import BaseNotificationProvider from './BaseNotificationProvider';
import DataContext from './DataContext';

class NotificationProvider extends BaseNotificationProvider {
    static contextType = DataContext;
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            content: {}
        };
    }

    async componentDidMount() {
        this.loadContent();
        this.getNotifications();
    }

    getNotifications = async () => {
        if (this.context.isLoggedIn) {
            const response = await Api.get('/api/users/account-notifications');
            if (response) {
                response.notifications = response?.notifications?.map(({notificationType, metadata}) => {
                    return this.buildNotification(notificationType, metadata, false);
                });
                this.setState({
                    notifications: response.notifications
                });
            }    
        }
    }
    
    loadContent = async () => {
        const response = await ContentfulService.getEntry(ContentfulIds.Notifications);
        this.setState({ content: response.fields });
    }
    
    render() {
        const { notifications } = this.state;
        const contextValue = {
            notifications,
            getNotifications: () => this.getNotifications(),
            addNotification: (notificationType, metadata) => this.addNotification(notificationType, metadata),
            removeNotification: (notificationType) => this.removeNotification(notificationType),
            clearNotifications: () => this.clearNotifications()
        };

        return(
            <NotificationContext.Provider value={contextValue}>
                {this.props.children}
            </NotificationContext.Provider>
        )
    }
}

export default NotificationProvider;