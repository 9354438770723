import React, { Component } from "react";
import Spinner from './elements/Spinner';
import Title from "./elements/Title";
import withPageTitle from "./layout/withPageTitle";
import { Api, ContentfulService } from "../configuration/ApplicationContext";
import ContentfulIds from "../configuration/ContentfulIds";
import Content from "./layout/Content";
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent } from "./layout/ResponsiveSignupFlowLayout";
import DynamicParagraphText from "./elements/DynamicParagraphText";
import RichText from "./elements/RichText";
import SubmitButton from "./elements/SubmitButton";
import InfoText from "./elements/InfoText";
import Link from "./elements/Link";
import AnchorLink from "./elements/AnchorLink";

const mobileTitleStyle = {
  lineHeight: '25px',
  fontSize: '24px',
}

const desktopTitleStyle = {
  lineHeight: '48px',
}

const descriptionStyle = {
  lineHeight: '26px',
}

const linkStyle = {
  display: 'inline-block',
}

class VerifyIdentityPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionsResponse: [],
    };
  }

  async componentDidMount() {
    this.loadContent();
    localStorage.removeItem('verifyIdentity');
  }

  async loadContent() {
      const joinNowClicked = localStorage.getItem('joinNowClicked');
      const response = await ContentfulService.getEntry(joinNowClicked ? ContentfulIds.VerifyIdentityJoinNowPage : ContentfulIds.VerifyIdentityLoginPage);
      this.setState({
          content: response.fields
      });
  }

  retrieveQuestions = async () => {
    this.setState({
      isSubmitDisabled: true
    });
    const response = await Api.get('/api/users/security-questions');
    if (response && response.validationErrors) {
      this.props.history.push('/verify-identity-failure?attempts_exceed=true')
    } else if (response) {
      const questions = response.map((data, index) => {
        return {
          ...data,
          number: index + 1,  
        }
      });
      localStorage.removeItem('joinNowClicked');
      localStorage.setItem('verifyIdentity', JSON.stringify({
        questions,
        answers: [],
      }));
      this.setState(
        {
          questions
        }
      );
      this.props.history.push('/question/1');
    }
    this.setState({
      isSubmitDisabled: false
    });
  }
  
  render() {
    const {content, isSubmitDisabled} = this.state;
    if (!content) {
        return <Spinner />
    }
    return (
      <Content>
        <ResponsiveSignupFlowLayout>
            <SignUpFlowFormContent>
                <Title mobileStyles={mobileTitleStyle} desktopStyles={desktopTitleStyle} style={{marginBottom: '1.5rem'}}>{content.title}</Title>
                <DynamicParagraphText style={descriptionStyle} desktopFontSize='17px' mobileFontSize='14px' text={<RichText text={content.description} />} />
                <InfoText>
                    By proceeding, you are agreeing to our <Link style={linkStyle} target="_blank" text="Terms & Conditions" to="/terms-and-conditions" />
                     &nbsp;and <AnchorLink style={linkStyle} target="_blank" text="Privacy Policy" href="https://calstate.aaa.com/privacy-policy" />
                </InfoText>
                <SubmitButton style={{marginTop: '2rem'}} text={content.linkText} onClick={this.retrieveQuestions} disabled={isSubmitDisabled} />
            </SignUpFlowFormContent>
        </ResponsiveSignupFlowLayout>
    </Content>
    );
  }
}

export default withPageTitle(VerifyIdentityPage, "Verify Identity");