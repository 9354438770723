import moment from 'moment';
import { removeSpacesFromMembershipNumber } from '../../common/Helpers';

const validate = (values, dirty, relaxRequiredValidationsForFields, updateMembershipNumber, homeAddress, manualAddressEntry, billingAddressSameAsHomeAddress) => {
    Object.keys(values).forEach(key => {
        if (values[key]?.trim) {
            values[key] = values[key]?.trim()
        }       
    });
    let errors = {};
    // First name validation
    if(!values.firstName) {
        errors.firstName = 'Please enter First Name.';
    } else if (values.firstName && values.firstName.match(/[^a-zA-Z\s]/)) {
        errors.firstName = 'Please enter Valid First Name. Only alphabets and spaces allowed';
    } else if (values.firstName.length < 1 || values.firstName.length >= 31) {
        errors.firstName = 'Please ensure First Name should be between 1 and 30 characters.';
    }
    // Middle name validation
    if (values.middleName && values.middleName.length >= 31) {
        errors.middleName = 'Please ensure Middle Name should be 30 characters max.';
    } else if (values.middleName && values.middleName.match(/[^a-zA-Z\s]/)) {
        errors.middleName = 'Please enter Valid Middle Name. Only alphabets and spaces allowed';
    } 
    // Last name validation
    if(!values.lastName) {
        errors.lastName = 'Please enter Last Name.';
    } else if (values.lastName && values.lastName.match(/[^a-zA-Z\s'-]+/)) {
        errors.lastName = 'Please enter Valid Last Name. No Numbers and special characters allowed except - , \'and spaces';
    } else if (values.lastName.length < 1 || values.lastName.length >= 31) {
        errors.lastName = 'Please ensure Last Name should be between 1 and 30 characters.';
    } 
    // Membership Validation
    const numberRegex = /^[0-9 ]*$/;
    if (values.hasOwnProperty('membershipNumber')) {
        if (!relaxRequiredValidationsForFields.includes('membershipNumber') && !values.membershipNumber) {
            errors.membershipNumber = 'Please enter Membership number.';
        } else if(values.membershipNumber && removeSpacesFromMembershipNumber(values.membershipNumber).length !== 16 || !numberRegex.test(values.membershipNumber)){
            errors.membershipNumber = updateMembershipNumber ?
             'This is not a valid Membership Number – Please enter 16-digit number, with no letters or special characters.' 
             : 'Please enter a valid membership number.';
        }    
    }
    
    // Email validation
    const emailRegex = /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})+$/;
    if (!relaxRequiredValidationsForFields.includes('email') && !values.email) {
        errors.email = 'Please enter Email.';
    }
    else if (values.email && !emailRegex.test(values.email)) {
        errors.email = "Please enter a valid email address.";
    }
    // Retype email validation
    if (values.email && dirty?.email && !values.retypeEmail) {
        errors.retypeEmail = 'Please Retype Email.';
    }
    else if (values.retypeEmail && !emailRegex.test(values.retypeEmail)) {
        errors.retypeEmail = "Please enter a valid email address.";
    } else if (dirty?.email && values?.email && values?.retypeEmail) {
        const email = values.email.toLowerCase();
        const retypeEmail = values.retypeEmail.toLowerCase();
    
        if (email !== retypeEmail) {
            errors.retypeEmail = "Email and Retype Email should match.";
        }
    }
    
    // Home address validation
    if (!values.homeAddress && !manualAddressEntry) {
        errors.homeAddress = 'Please enter Home Address.';
    } else if (homeAddress && Object.keys(homeAddress).length > 0 && !manualAddressEntry) {
        if (!homeAddress.city || homeAddress.city === '') {
            errors.homeAddress = 'City must not be empty';
        } else if (!homeAddress.state || homeAddress.state === '') {
            errors.homeAddress = 'State must not be empty';
        } else if (!homeAddress.zipCode || homeAddress.zipCode === '') {
            errors.homeAddress = 'Zipcode must not be empty';
        }
    }
    //Manual Address entry & billing address validation 
    const cityRegex = /^[A-Za-z]+(?:[ ][a-zA-Z]+)*$/;
    const manualAddressEntryCheck = (manualAddressEntry || manualAddressEntry === undefined);
    if(manualAddressEntryCheck){
    if (!values.lineOne ) {
        errors.lineOne = 'Please enter Home Address.';
    }
    if (!values.city ) {
        errors.city = 'City must not be empty';
    }
    if(values.city && !cityRegex.test(values.city)){
        errors.city = 'Please enter a valid city name';
    }
    if (!values.state) {
        errors.state = 'State must not be empty';
    }
    if (!values.zipCode) {
        errors.zipCode = 'Zipcode must not be empty';
    } 
    if (!values.billingLineOne && !billingAddressSameAsHomeAddress) {
        errors.billingLineOne = 'Please enter Billing Address.';
    }
    if (!values.billingCity && !billingAddressSameAsHomeAddress) {
        errors.billingCity = 'Billing City must not be empty';
    }
    if (!values.billingState && !billingAddressSameAsHomeAddress) {
        errors.billingState = 'Billing State must not be empty';
    }
    if (!values.billingZipCode && !billingAddressSameAsHomeAddress) {
        errors.billingZipCode = 'Billing Zip Code must not be empty';
    }
}
    // Apt info validation
    const aptFieldRegex = /^[a-z0-9 .#-]+$/i;
    const aptSpecialCharRegex =  /^[.#-]+$/i;
    if (values.additionalHomeInfo) {
        if(!aptFieldRegex.test(values.additionalHomeInfo)){
            errors.additionalHomeInfo = 'Please enter valid Apt info.';
        } else if(aptSpecialCharRegex.test(values.additionalHomeInfo)) {
            errors.additionalHomeInfo = 'Please enter valid Apt info.';
        }    
    }
    //additional billing Apt info validation
    if (values.additionalBillingInfo) {
        if (!aptFieldRegex.test(values.additionalBillingInfo)) {
            errors.additionalBillingInfo = 'Please enter valid Apt info.';
        } else if (aptSpecialCharRegex.test(values.additionalBillingInfo)) {
            errors.additionalBillingInfo = 'Please enter valid Apt info.';
        }
    }
    // Billing address validation
    if (values.hasOwnProperty('billingAddress') && !values.billingAddress) {
        errors.billingAddress = 'Please enter Billing Address.';
    }
    // Phone number validation
    const phoneNoRegex = /\d{3}[\s.-]?\d{3}[\s.-]?\d{4}/;
    if (!values.phoneNumber) {
        errors.phoneNumber = "Phone Number is required.";
    } else if (values.phoneNumber && !values.phoneNumber.match(phoneNoRegex)) {
        errors.phoneNumber = "Please enter a valid Phone Number.";
    }
    // Date of birth validation
    if (!values.dob) {
        errors.dob = 'Please enter Date of Birth.';
    } else {
        const formats = ['MM/DD/YYYY', 'M/D/YYYY', 'M/DD/YYYY', 'MM/D/YYYY', 'MM:DD:YYYY', 'M:D:YYYY', 'M:DD:YYYY', 'MM:D:YYYY', 'MM.DD.YYYY', 'M.D.YYYY', 'M.DD.YYYY', 'MM.D.YYYY', 'MM DD YYYY', 'M D YYYY', 'M DD YYYY', 'MM D YYYY', 'MM-DD-YYYY', 'M-D-YYYY', 'M-DD-YYYY', 'MM-D-YYYY','MMDDYYYY','MMM DD YYYY', 'MMM D YYYY', 'MMMM DD YYYY', 'MMMM D YYYY'];
        let isValid = false;
        let formatUsed = '';
        let formattedDate;
        // Format date according to given formats
        formats.every(format => {
            if (moment(values.dob, format, true).isValid()) {
                formatUsed = format;
                isValid = true;
                return false;
            }
            return true;
        });
        if (!isValid) {
            errors.dob = 'Please enter valid date';
        } else {
            formattedDate = moment(values.dob, formatUsed, true);
            const todayDate = moment(moment().format(formatUsed), formatUsed, true);
            if(!todayDate.isAfter(formattedDate)) {
                errors.dob = 'Date should not be future date';
            } else if(todayDate.isAfter(formattedDate)) {
                const eighteenYearsAgo = todayDate.subtract(18, "years");
                if (!eighteenYearsAgo.isAfter(formattedDate)) {
                    errors.dob = 'Age should be minimum 18 years'; 
                }
            } 
            values.dob = formattedDate.format('MM/DD/YYYY');
            var parts = values?.dob?.split("/");
            if(parts) {
                var year = parseInt(parts[2], 10);
                if (year <= 1900) {
                    errors.dob = 'DOB year should be after 1900';
                } 
            }
        }
    }
    return errors;
};

export default validate;