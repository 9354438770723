import React, {Component} from 'react';
import {Api} from '../configuration/ApplicationContext';
import { META_DESCRIPTION } from './common/Constants';
import Spinner from './elements/Spinner';
import withPageMetaTags from './layout/withPageMetaTags';
import withPageTitle from './layout/withPageTitle';

class SigninPage extends Component {
    componentDidMount() {
        Api.login();
    }

    render() {
        return <Spinner />;
    }
}

export default withPageMetaTags(withPageTitle(SigninPage, 'Sign Up'), {description: META_DESCRIPTION.SIGN_UP});
