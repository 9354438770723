import React from 'react';
import { Api, ContentfulService } from '../configuration/ApplicationContext';
import CheckoutForm from './forms/CheckoutForm';
import ContentfulIds from '../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, {
    SignUpFlowExtraContent,
    SignUpFlowFormContent
} from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import SignupFlowStepProgress from './elements/SignUpFlowStepProgress';
import Title from './elements/Title';
import Summary from './summary/Summary';
import Spinner from './elements/Spinner';
import DataContext from './contexts/DataContext';
import { addAmobeeTag, buildUrl, deleteIndividualPromoCodeNameValueFromSessionStorage, getMemberTypeFromSessionStorage, redirectParamSessionStorage, subscriptionApplicationIdSessionStorage } from './common/Helpers';
import BaseCheckout from './BaseCheckout';
import withPageTitle from "./layout/withPageTitle";
import Breadcrumbs from './common/Breadcrumbs';
import InfoText from './elements/InfoText';
import Notification from './notifications/Notification';
import { dynamicSignUpFlowSteps } from './common/Steps';
import { createSummaryItems } from './summary/SummaryItems';
import UtilsService from '../services/UtilsService';
import { AAA_USER, AMOBEE_PAYMENT_INFO_SUBMITTED_TAG_URL, CANCELLED, FREE_UPPER_CASE, NON_AAA, NON_AAA_USER } from './common/Constants';
import { PAYMENT_INFO_SUBMITTED, SUF_PROMO_CODE_SUCCESSFUL, trackEvent } from '../services/SegmentService';
import Card from './elements/Card';

declare var Z: any; //Zuora
const notificationStyle = {
    marginBottom: '1rem',
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '0px',
    marginTop: '60px'
};
class CheckoutPage extends BaseCheckout {
    static contextType = DataContext;
    state = {
        zuoraConfiguration: null,
        billingAddressSameAsHomeAddress: true,
        validateExistingAccount: false
    };

    componentDidMount() {
        this.checkUserStateAndRedirect(true);
        this.validateExistingAccountAndRedirect('non-member');
        this.checkProductAvailable();
        this.loadZuoraForm();
        this.loadContent();
        this.setBreadCrumbs();
        this.context.removePromoCodeData();
        this.loadPersonalInformation();
    }

    checkProductAvailable() {
        if (!this.context.product) {
            this.loadPlans();
        }
    }
    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.CheckoutPage);
        this.setState({ content: response.fields })
        this.setState({ originalContent: JSON.parse(JSON.stringify(response.fields)) })
    }

    loadPersonalInformation = async () => {
        let personalInfo;
        if (this.context.isLoggedIn) {
            personalInfo = this.context.userDetails.personalInformation;
            personalInfo.billingAddress = this.context.userDetails.personalInformation.homeAddress;
        } else {
            personalInfo = await this.context.getPersonalInformation();
            personalInfo.billingAddress = personalInfo.homeAddress;
        }
        this.setState({ personalInfo });
    }

    onApplyPromoCode = async (promoCodeData) => {
        this.setState({ promoCodeData });
        if (promoCodeData.products) {
            const product = promoCodeData.products.find(product => product.name !== FREE_UPPER_CASE
                && (product.annualRatePlan.id === this.props.match.params.productId
                    || product.monthlyRatePlan.id === this.props.match.params.productId));
            this.selectProduct(product, this.props.match.params.productId);
            this.trackSuccessfulPromoCodeEvent(promoCodeData.promoCode);
        }
        const originalContent = JSON.parse(JSON.stringify(this.state.originalContent))
        this.setState({ content: originalContent })
    }

    trackSuccessfulPromoCodeEvent = (promoCode) => {
        const { product } = this.context || {};
        const { userId, membershipId } = this.context.userDetails || {};
        const personalInfo = this.context.userDetails.personalInformation || this.state.personalInfo;
        trackEvent(SUF_PROMO_CODE_SUCCESSFUL, {
            memberId: userId,
            userName: personalInfo.email,
            AAA_membership_id: membershipId,
            name: personalInfo.displayName,
            phoneNumber: personalInfo.phoneNumber,
            address: personalInfo.homeAddress,
            promoCode,
            tierSelected: product.name,
            subscriptionOption: product.paymentMode
        });
    }

    setBreadCrumbs = () => {
        const breadCrumbRoutes = [
            {
                to: `/checkout/${this.props.match.params.productId}/personal-info`, label: 'Personal Information'
            }
        ];
        this.setState({
            breadCrumbRoutes
        });
    }

    generateZuoraPlansURL = () => {
        let params = {};
        if (!this.context.isLoggedIn) {
            params.isNonAaaMember = true;
            const subscriptionApplicationId = sessionStorage.getItem(subscriptionApplicationIdSessionStorage);
            if (subscriptionApplicationId) {
                params['subscription-application-id'] = subscriptionApplicationId;
            }
        } else {
            const { userDetails } = this.context || {};
            const membershipStatus = userDetails?.membership?.membershipStatus;
            params.isNonAaaMember = membershipStatus === CANCELLED || userDetails.invalidMembershipNumber;
        }
        return buildUrl('/api/products/zuora-rate-plans', params);
    }

    submit = async (data) => {
        const { id, lastModified, createdOn, ...billingAddress } = this.state.personalInfo?.billingAddress || {};

        if (Object.keys(data.errors).length > 0 || !data.values.billingAddress) {
            this.setState({
                checkoutError: data.errors.billingAddress,
            });
            return;
        }

        this.setState({
            checkoutError: ""
        });

        window.orderSuccess = async (refId) => {
            const { promoCodeRatePlanId, promoCode } = this.context.promoCodeData || {};
            this.setState({
                isSubmitDisabled: true
            });
            const ratePlanId = this.context.product.id;
            const paymentMethodId = refId;
            const { userDetails } = this.context || {};
            const membershipStatus = userDetails?.membership?.membershipStatus;
            const freeTrialUser = this.context.isLoggedIn && this.context.variation.freeTrial === true;
            const isNonAaaMember = (!this.context.isLoggedIn || membershipStatus === CANCELLED || userDetails.invalidMembershipNumber);
            if (!paymentMethodId || !ratePlanId) {
                this.setState({
                    isSubmitDisabled: false
                });
                return;
            }
            const response = await Api.post('/api/orders', {
                ratePlanId,
                paymentMethodId,
                billingAddress,
                promoCodeRatePlanId,
                promoCode,
                nonAaaMember: isNonAaaMember,
                freeTrialUser,
                subscriptionApplicationId: sessionStorage.getItem(subscriptionApplicationIdSessionStorage)
            });
            if (!response) {
                this.setState({ isSubmitDisabled: false });
                return;
            }
            if (response.validationErrors) {
                await this.context.refreshContext();
                this.props.history.push('/my-account');
            } else {
                trackEvent(PAYMENT_INFO_SUBMITTED, {
                    memberId: this.context.userDetails.userId,
                    tierSelected: this.context.product.name,
                    subcriptionOption: this.context.product.paymentMode,
                    MemberType: isNonAaaMember ? NON_AAA_USER : AAA_USER
                });
                addAmobeeTag(AMOBEE_PAYMENT_INFO_SUBMITTED_TAG_URL);
                sessionStorage.removeItem(redirectParamSessionStorage);
                sessionStorage.removeItem('isLoginWithAAA');
                sessionStorage.removeItem(subscriptionApplicationIdSessionStorage);
                deleteIndividualPromoCodeNameValueFromSessionStorage();
                this.props.history.push('/subscription-complete');
            }
        };

        window.orderFailure = (failureMessage) => {
            this.setState({
                checkoutError: failureMessage,
                zuoraConfiguration: null,
                isSubmitDisabled: false
            }, () => {
                this.loadZuoraForm();
            });
        };
        Z.submit();
    };

    render() {
        const {
            breadCrumbRoutes,
            content,
            checkoutError,
            personalInfo,
            billingAddressSameAsHomeAddress,
            isSubmitDisabled,
            zuoraConfiguration,
            validateExistingAccount
        } = this.state;
        const selectedProduct = this.context.product;
        const { notifications } = this.props.notificationContext;
        const isFreeTrialUser = this.context.isLoggedIn && this.context.variation.freeTrial === true;
        const { userDetails } = this.context || {};
        const membershipStatus = userDetails?.membership?.membershipStatus;
        const isNonAaaMember = (!this.context.isLoggedIn || membershipStatus === CANCELLED || userDetails.invalidMembershipNumber);
        if (!zuoraConfiguration || !content || !selectedProduct || !validateExistingAccount || isSubmitDisabled) {
            return <Spinner />;
        }
        return (
            <>
                <InfoText
                    style={notificationStyle}
                    mobileOnly='true'>
                    {
                        notifications.map(notification => {
                            return <Notification
                                key={notification.notificationType}
                                {...notification}
                                {...this.props}
                            />
                        }
                        )
                    }
                </InfoText>
                <Content
                    style={{ marginTop: '0px' }}>
                    <InfoText
                        style={notificationStyle}
                        desktopOnly='true'>
                        {
                            notifications.map(notification => {
                                return <Notification
                                    key={notification.notificationType}
                                    {...notification}
                                    {...this.props}
                                />
                            }
                            )
                        }
                    </InfoText>
                    <InfoText
                        mobileOnly={true}>
                        <Breadcrumbs
                            breadCrumbRoutes={breadCrumbRoutes}
                            showOneBreadcrumb={true}
                        />
                    </InfoText>
                    <SignupFlowStepProgress
                        currentStep={getMemberTypeFromSessionStorage() != NON_AAA ? 4 : 3}
                        steps={
                            dynamicSignUpFlowSteps(
                                false,
                                true,
                                {
                                    productId: this.props.match.params.productId
                                }
                            )
                        }
                    />
                    <ResponsiveSignupFlowLayout>
                        <SignUpFlowFormContent>
                            <Title
                                style={{ marginTop: '1rem' }}>
                                {content.title}
                            </Title>
                            <CheckoutForm
                                zuoraConfiguration={zuoraConfiguration}
                                content={content}
                                onSubmit={this.submit}
                                checkoutError={checkoutError}
                                personalInfo={personalInfo}
                                onSelectAddress={this.onSelectAddress}
                                selectedProduct={selectedProduct}
                                billingAddressSameAsHomeAddress={billingAddressSameAsHomeAddress}
                                freeTrial={isFreeTrialUser}
                                hidePromoCode={isFreeTrialUser}
                                isSubmitDisabled={isSubmitDisabled}
                                onApplyPromoCode={this.onApplyPromoCode}
                                promoCodeOptions={
                                    {
                                        validateURL: `/api/promo-codes/validate?isNonAaaMember=${isNonAaaMember}`,
                                        zuoraRatePlanURL: this.generateZuoraPlansURL(),
                                        mapProductsFunc: UtilsService.mapProductsToArray
                                    }
                                }
                            />
                        </SignUpFlowFormContent>
                        <SignUpFlowExtraContent>
                            <Card>
                                <Summary
                                    title={`Identity Champion ${selectedProduct.name}`}
                                    product={selectedProduct}
                                    flags={{ showFreeTrialDisclosure: isFreeTrialUser }}
                                    items={createSummaryItems(selectedProduct, this.context)}                                    
                                />                               
                            </Card>
                        </SignUpFlowExtraContent>
                    </ResponsiveSignupFlowLayout>
                </Content>
            </>
        );
    }
}

export default withPageTitle(CheckoutPage, "Place Order");
