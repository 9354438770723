import React, { useState } from 'react'
import { NonManualAddressFields } from './NonManualAddressFields';
import { ManualAddressFields } from './ManualAddressFields';
import Link from '../elements/Link';
import { ManualBillingAddressFields } from './ManualBillingAddressFields';
import InfoText from '../elements/InfoText';
import CheckboxInput from '../elements/CheckboxInput';

export const AddressFields = (props) => {
    const [showAdditionalHomeInfo, setShowAdditionalHomeInfo] = useState(false);
    const [showAdditionalBillingInfo, setshowAdditionalBillingInfo] = useState(false);
    const { values, onSelectAddress, manualAddressEntry, setManualAddressEntry, manualAddressLinkShow, onSelectBillingAddress, billingAddressSameAsHomeAddress, setBillingAddressSameAsHomeAddress, onBillingAddressSameAsHomeAddress, showBillingCheck } = props;
    const handleBlur = () => {
        const { lineOne, city, state, zipCode } = values;
        onSelectAddress({ lineOne, city, state, zipCode, countryCode: 'US' });
    }
    const handleBillingAddress = () => {
        const { billingLineOne, billingCity, billingState, billingZipCode } = values;
        onSelectBillingAddress({ lineOne: billingLineOne, city: billingCity, state: billingState, zipCode: billingZipCode, countryCode: 'US' });
    }
    return (
        <>
            { manualAddressEntry ?
                <> <ManualAddressFields handleBlur={handleBlur} {...props} />
                   {showBillingCheck && <InfoText style={{ marginTop: '1rem' }}>
                        <CheckboxInput name="isHomeAdress" checked={billingAddressSameAsHomeAddress} onChange={() => { setBillingAddressSameAsHomeAddress(!billingAddressSameAsHomeAddress); onBillingAddressSameAsHomeAddress(!billingAddressSameAsHomeAddress) }} />
                        Billing address same as home address
                    </InfoText>
                   }
                    {!billingAddressSameAsHomeAddress && <ManualBillingAddressFields handleBlur={handleBillingAddress} {...props} />}
                </> :
                <NonManualAddressFields showAdditionalBillingInfo={showAdditionalBillingInfo} setshowAdditionalBillingInfo={setshowAdditionalBillingInfo} showAdditionalHomeInfo={showAdditionalHomeInfo} setShowAdditionalHomeInfo={setShowAdditionalHomeInfo} {...props} />
            }
            { manualAddressLinkShow && <Link to='#' extratopspace="0.5rem" extrabottomspace="0.5rem" text={manualAddressEntry ? "← Enter Address Using Autocomplete" : "Enter Address Manually"} onClick={() => setManualAddressEntry(!manualAddressEntry)} />}

        </>
    )
}
