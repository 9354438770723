import React, { Component } from "react";
import Spinner from './elements/Spinner';
import Title from "./elements/Title";
import withPageTitle from "./layout/withPageTitle";
import { ContentfulService } from "../configuration/ApplicationContext";
import ContentfulIds from "../configuration/ContentfulIds";
import Content from "./layout/Content";
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent } from "./layout/ResponsiveSignupFlowLayout";
import DynamicParagraphText from "./elements/DynamicParagraphText";
import RichText from "./elements/RichText";
import SubmitButton from "./elements/SubmitButton";

const mobileTitleStyle = {
  lineHeight: '25px',
  fontSize: '24px',
}

const desktopTitleStyle = {
  lineHeight: '48px',
}

const descriptionStyle = {
  lineHeight: '26px',
}

class VerifyIdentityFailurePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const attemptsExceed = params.get('attempts_exceed');
    if (attemptsExceed) {
      this.setState({
        attemptsExceed: true,
      });
    }
    this.loadContent();
    localStorage.removeItem('verifyIdentity');
  }

  async loadContent() {
      const response = await ContentfulService.getEntry(ContentfulIds.VerifyIdentityFailurePage);
      this.setState({
          content: response.fields
      });
  }
  
  render() {
    const {content, attemptsExceed} = this.state || {};
    if (!content) {
        return <Spinner />
    }
    return (
      <Content>
        <ResponsiveSignupFlowLayout>
            <SignUpFlowFormContent>
                <Title mobileStyles={mobileTitleStyle} desktopStyles={desktopTitleStyle} style={{marginBottom: '1.5rem'}}>{content.title}</Title>
                {attemptsExceed && <DynamicParagraphText style={descriptionStyle} desktopFontSize='17px' mobileFontSize='14px' text={<RichText text={content.description} />} />}
                {!attemptsExceed && <SubmitButton style={{marginTop: '2rem'}} text={content.linkText} onClick={() => this.props.history.push('/verify-identity')} />}  
            </SignUpFlowFormContent>
        </ResponsiveSignupFlowLayout>
    </Content>
    );
  }
}

export default withPageTitle(VerifyIdentityFailurePage, "Verify Identity Failure");