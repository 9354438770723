import React from 'react';
import styled, { css } from 'styled-components';
import { colors, mobile } from '../../styles/common';
import Title from '../elements/Title';
import Button from '../elements/Button';
import PlanDetailData from './PlanDetailData';
import {ANNUALLY, BASIC, PROTECT } from '../common/Constants'
import { variation } from '../../configuration/ApplicationContext';

const PlanDetailsContainer = styled.div`
    max-width: 438px;
    width: 32%;
    text-align: center;
    background: white;
    
    ${mobile`
       width: 100%
    `}
    ${props => props.borderRed && css`
        :first-child {
            border: 5px solid ${colors.darkRed};
        }
    `}
    ${props => props.isWhatsIncludedPage && css`
        :not(:first-child) {
            border: 1px solid ${colors.greyborder};
        }
    `}
    ${props => props.borderGrey && css`
        border: 1px solid ${colors.greyborder};
        border-top: none;    
    `}
    ${props => props.showTwoSections && css`
        margin-right: 2rem;    
    `}

`;
const SectionStyle = styled.section`
    font-size: 17px;
    ${props => props.styles && css`
        styles: ${props.styles};
    `}
`;

const SpanStyle = styled.span`
    font-size: 20px;    
`;

const UpperSection = styled.div`
    padding: 23px 10px;
    color: ${colors.pureWhite};
    ${props => props.planType && props.planType === 'Basic' && css`
        background: ${colors.skyBlue};
    `}
    ${props => props.planType && props.planType === 'Complete' && css`
        background: ${colors.darkBlue};
    `}
    ${props => props.planType && props.planType === 'Protect' && css`
        background: ${colors.mediumBlue};
    `}
`;

const titleStyle = {
    textAlign: 'center',
    width: '100%',
    color: `${colors.pureWhite}`,
    margin: 0,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: "36px",
    lineHeight: '35px',
    paddingBottom: '15px'
};

const MiddleSection = styled.div`
    padding: 1em 0.625em 0em 0.625em;
    text-align: center;
    ${props => !props.isWhatsIncludedPage && css`
        min-height: 142px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    `}
    ${props => props.isWhatsIncludedPage && css`
        min-height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1em 0.625em 1em 0.625em;
    `}
    ${props => props.isWhatsIncludedPage && props.planType === 'Basic' && css`
        background: ${colors.skyBlue};
    `}
    ${props => props.isHideBackgroundForBasicAndProtectPlan && props.planType === 'Basic' && css`
        background: ${colors.skyBlue};
    `}
    ${props => props.isHideBackgroundForBasicAndProtectPlan && props.planType === 'Protect' && css`
        background: ${colors.mediumBlue};
    `}
    ${props => props.styles && css`
        styles: ${props.styles};
    `}
`;

const MiddleDescSection = styled.div`
    min-height: 142px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const DataSection = styled.div`
    font-size: 1.5em;
    font-weight: 900;
    padding: 0em 0.625em 1em;
    background: ${colors.pureWhite};
`;

const amountStyle = {
    fontWeight: '500',
    fontSize: '40px',
    lineHeight: '35px',
    textAlign: 'center',
}

const amountPeriodStyle = {
    fontSize: '1.25em',
    padding: '2px',
    marginTop:'1px'
}

const amountPeriodStyleForAAA = {
    fontSize: '2em',
    padding: '2px',
    marginTop:'8px'
}

const amountInfoStyle = {
    fontSize: '10px',
    padding: '0.5rem 0rem'
}

const insuranceTotalStyle = {
    fontWeight: 400,
    fontSize: '1.25rem',
    padding: 0
}

const insuranceStyles = {
    fontSize: '2em'
}
const planInfoStyle = {
    color:colors.pureWhite,
    fontSize:'24px'  
}

const insuranceInfoStyle = {
    fontSize: '1.875em',
    padding: 0
}

const buttonStyle = {
    margin: '0.5rem auto 0px',
    padding: '0.5rem 1rem',
    width: 'fit-content',
    background: colors.darkBlue,
    color: colors.pureWhite
}

const currentPlanStyle = {
    fontSize: '40px',
    lineHeight: '35px',
    textAlign: 'center',
    marginTop: '1rem',
    padding: '6px'
}

const freeTrialStyle = {
    lineHeight: '40px',
    textAlign: 'center'
}

const basicCurrentPlanStyle = {
    color: colors.pureWhite,
    ...currentPlanStyle
};

const PlanDetailsContent = ({ ...props }) => {
    sessionStorage.setItem('paymentMode', props.paymentMode || 'Annually')
    const { planType, currentPlanType, amount, amountAnnual, amountPeriod, amountInfo, totalInsurance, insuranceInfo, extraTotalInsurance, extraInsuranceInfo, dataList, freeTrialDataList, onSignup, style, paymentMode, isWhatsIncludedPage, isHideBackgroundForBasicAndProtectPlan, redirectToUpgradeFlow, AAAIdcProductOfferingPage, isAAAIdcFlow = false, borderGrey = false, onChooseProduct } = props;
    const amountTextStyle = `${planType === BASIC ? colors.skyBlue : planType === PROTECT ? colors.mediumBlue : colors.darkBlue}`;
    const planDetailDataList = (props.showTrialSection === true && variation.freeTrial === true) ? (freeTrialDataList || dataList) : dataList;
    const freeTrial = {
        textStyle: {color: amountTextStyle, ...freeTrialStyle},
        buttonText: `${planType === BASIC ? `Choose ${planType}` : 'Start Free Trial'}`,
        buttonAltText: `${planType === BASIC ? `Choose ${planType}` : `Start Free Trial - ${planType} plan`}`,
        amountPeriod: `${planType === BASIC ? amountPeriod : (paymentMode === ANNUALLY ? 'per year¹' : 'per month¹')}`
    };
    return (
        <PlanDetailsContainer style={style} borderRed={props?.borderRed} borderGrey={borderGrey} isWhatsIncludedPage={isWhatsIncludedPage} showTwoSections={props.showTwoSections}>
            <UpperSection planType={props.planType}>
                {!isWhatsIncludedPage &&
                    <>
                        <Title style={titleStyle}>{planType}</Title>
                        <SectionStyle style={insuranceTotalStyle}>{totalInsurance}</SectionStyle>
                        <SectionStyle style={{ fontSize: '1.25rem' }}>{insuranceInfo}</SectionStyle>
                        <SectionStyle style={insuranceTotalStyle}>{extraTotalInsurance}</SectionStyle>
                        <SectionStyle style={{ fontSize: '1.25rem' }}>{extraInsuranceInfo}</SectionStyle>
                    </>
                }
                {isWhatsIncludedPage &&
                    <>
                        <Title style={titleStyle}>{planType}</Title>
                        <SectionStyle style={insuranceStyles}>{totalInsurance}</SectionStyle>
                        <SectionStyle style={insuranceInfoStyle}>{insuranceInfo}</SectionStyle>
                    </>
                }
            </UpperSection>
            <MiddleSection isWhatsIncludedPage={isWhatsIncludedPage} planType={planType} isHideBackgroundForBasicAndProtectPlan={isHideBackgroundForBasicAndProtectPlan}>
                {isWhatsIncludedPage &&
                    <>
                        {planType === 'Basic' &&
                            <>
                                <SectionStyle style={{ ...amountStyle, ...planInfoStyle}}>{amount}</SectionStyle>
                                <SectionStyle style={{...planInfoStyle, ...amountInfoStyle}}>{amountPeriod}</SectionStyle>
                                {currentPlanType && <SectionStyle style={basicCurrentPlanStyle}>{'My Current Plan'}</SectionStyle>}
                            </>
                        }
                        {planType !== 'Basic' &&
                            <>
                                <SectionStyle style={{...amountStyle, ...planInfoStyle, color: amountTextStyle }}>{paymentMode === 'Annually' ? `${amountAnnual}/year` : `${amount}/month`}</SectionStyle>
                                <SectionStyle style={ amountInfoStyle }>{amountInfo}</SectionStyle>
                                {currentPlanType === 'basic' &&
                                    <Button style={buttonStyle} text={'Upgrade Now'} onClick={() => redirectToUpgradeFlow(planType.toLowerCase())} />
                                }
                                {currentPlanType === 'protect' && planType === 'Protect' &&
                                    <SectionStyle style={currentPlanStyle}>{'My Current Plan'}</SectionStyle>
                                }
                                {currentPlanType === 'protect' && planType === 'Complete' &&
                                    <Button style={buttonStyle} text={'Upgrade Now'} onClick={() => redirectToUpgradeFlow(planType.toLowerCase())} />
                                }
                            </>
                        }
                    </>
                }
                {!isWhatsIncludedPage &&
                    <>
                        {props.showTrialSection === true && variation.freeTrial === true && 
                        <>
                        <MiddleDescSection>
                            {
                                planType !== BASIC && 
                                <>
                                    <SectionStyle style={{ ...amountStyle }}>
                                        <SpanStyle style={freeTrial.textStyle}>Try 1 month free, </SpanStyle>
                                        <SpanStyle style={freeTrialStyle}>then</SpanStyle>
                                    </SectionStyle>
                                </>
                            }
                            <SectionStyle style={{ color: amountTextStyle, ...amountStyle }}>{paymentMode === ANNUALLY ? amountAnnual : amount}</SectionStyle>
                            <SectionStyle style={isAAAIdcFlow ? amountPeriodStyleForAAA : amountPeriodStyle}>{freeTrial.amountPeriod}</SectionStyle>
                            <SectionStyle style={amountInfoStyle}>{amountInfo}</SectionStyle>                
                        </MiddleDescSection>
                        <Button aria-label={freeTrial.buttonAltText} title={freeTrial.buttonAltText} style={buttonStyle} text={freeTrial.buttonText} onClick={() => AAAIdcProductOfferingPage ? onChooseProduct(planType) : onSignup(`Home Page | ${planType} CTA Clicked`, planType)} />
                        </>
                        }
                        {(props.showTrialSection === false || props.showTrialSection === undefined || variation.freeTrial === false) &&
                        <>
                            <SectionStyle style={{ color: amountTextStyle, ...amountStyle }}>{paymentMode === ANNUALLY ? amountAnnual : amount}</SectionStyle>
                            <SectionStyle style={isAAAIdcFlow ? amountPeriodStyleForAAA : amountPeriodStyle}>{paymentMode === ANNUALLY && planType !== BASIC ? 'per year*' : amountPeriod}</SectionStyle>
                            <SectionStyle style={amountInfoStyle}>{amountInfo}</SectionStyle>
                            {AAAIdcProductOfferingPage ? <Button style={buttonStyle} text={`Choose ${planType}`} onClick={() => onChooseProduct(planType)} />
                            : <Button style={buttonStyle} text={`Choose ${planType}`} onClick={() => onSignup(`Home Page | ${planType} CTA Clicked`, planType)} />
                        }
                        </>
                        }
                    </>
                }
            </MiddleSection>
            <DataSection>
                <PlanDetailData alignText='left' data={planDetailDataList} isWhatsIncludedPage={isWhatsIncludedPage} />
            </DataSection>
        </PlanDetailsContainer>
    );
};

export default PlanDetailsContent;