import React,{useContext} from 'react';
import Title from '../../elements/Title';
import LineItem from '../../elements/LineItem';
import Section from '../../elements/Section';
import HorizontalRule from '../../elements/HorizontalRule';
import { createAdminSummaryItems } from './AdminSummaryItems';
import { formatDateMMDDYYYY, capitalizeFirstLetter } from '../../common/Helpers';
import AdminContext from '../../contexts/AdminContext';
import { FREE } from '../../common/Constants';

const AdminSummary = ({ title, product, isPaidTierUpgrade, subscription, isProductUpgraded, isOutstandingBalance = false, isDowngradeFlow, isUpdatePaymentMethodPage, showPromoFromSubscription = false, flags={} }) => {
    const context = useContext(AdminContext);
    const promoCodeData = context.promoCodeData;
    let { topSection, middleSection, grandTotal, bottomSection, totalPaidAmount, basicTopSection, basicMiddleSection, outstandingBalanceSection, outstandingBalanceTotalSection, promoCode } = createAdminSummaryItems(product, isPaidTierUpgrade, subscription, isProductUpgraded, isUpdatePaymentMethodPage, showPromoFromSubscription, promoCodeData, flags);
    const termEndDate = subscription?.termEndDate ? formatDateMMDDYYYY(subscription?.termEndDate) : null;
    const subscriptionPlan = subscription?.productType ? capitalizeFirstLetter(subscription?.productType) : null;
    const promoCodePrice = flags.showPaidInvoiceAmount ? subscription?.paidInvoiceAmount?.promoCode : subscription?.promoCode || product?.promoCode;
    const refundContent = `No refund issued – ${subscriptionPlan} Coverage will continue until ${termEndDate}, when account will downgrade to ${product.displayName}.`
    const italicStyle = { fontStyle: 'italic' };
    if (isUpdatePaymentMethodPage) {
        bottomSection = bottomSection.filter(({ key }) => key === 'Next Payment Date');
    }
    if (!product?.actualPrice?.tax) {
        middleSection = middleSection.filter(section => section.key !== 'Taxes');    
    }
    return (
        <>
            <Title desktopOnly='true' desktopStyles={{ 'font-size': '27px' }} style={{ marginBottom: '1.5rem' }}>{title}</Title>
            { !isOutstandingBalance &&
                <>
                    { product.name !== FREE &&
                        <>
                            <Section text={topSection.map(({ key, value, itemStyle }) => <LineItem key={key} itemStyle={itemStyle} itemKey={key} itemValue={value}></LineItem>)}></Section>
                            <Section text={middleSection.map(({ key, style, value }) => <LineItem key={key} style={style} itemKey={key} itemValue={value}></LineItem>)}></Section>
                            {promoCodeData && promoCodePrice && <Section desktopStyles={{paddingTop: 'unset', fontStyle: 'italic'}} text={promoCode.map(({key, style, value}) => <LineItem key={key} style={style} itemKey={key} itemValue={value}></LineItem>)}></Section>}

                        </>
                    }
                    { product.name === FREE &&
                        <>
                            <Section text={basicTopSection.map(({ key, value, itemStyle }) => <LineItem key={key} itemStyle={itemStyle} itemKey={key} itemValue={value}></LineItem>)}></Section>                           
                            <Section text={basicMiddleSection.map(({ key, style, value }) => <LineItem key={key} style={style} itemKey={key} itemValue={value}></LineItem>)}></Section>
                        </>
                    }
                    { product.name !== FREE &&
                        <>
                            <HorizontalRule></HorizontalRule>
                            { !isProductUpgraded && <Section text={grandTotal.map(({ key, style, value }) => <LineItem key={key} style={style} itemKey={key} itemValue={value}></LineItem>)}></Section>}
                            { isProductUpgraded && <Section text={totalPaidAmount.map(({ key, style, value }) => <LineItem key={key} style={style} itemKey={key} itemValue={value}></LineItem>)}></Section>}
                            <Section text={bottomSection.map(({ key, style, value }) => <LineItem key={key} style={style} itemKey={key} itemValue={value}></LineItem>)}></Section>
                        </>
                    }
                    { isDowngradeFlow && <Section style={italicStyle} text={refundContent}></Section>}
                </>
            }
            {
                isOutstandingBalance &&
                <>
                    <Section text={topSection.map(({ key, value, itemStyle }) => <LineItem key={key} itemStyle={itemStyle} itemKey={key} itemValue={value}></LineItem>)}></Section>
                    <Section text={outstandingBalanceSection.map(({ key, style, value }) => <LineItem key={key} style={style} itemKey={key} itemValue={value}></LineItem>)}></Section>
                    <Section text={outstandingBalanceTotalSection.map(({ key, style, value }) => <LineItem key={key} style={style} itemKey={key} itemValue={value}></LineItem>)}></Section>
                </>
            }
        </>
    );
};

export default AdminSummary;