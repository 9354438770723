import React from 'react';
import ResponsiveSignupFlowLayout, { SignUpFlowExtraContent, SignUpFlowFormContent } from '../../layout/ResponsiveSignupFlowLayout';
import Title from '../../elements/Title';
import RichText from '../../elements/RichText';
import Content from '../../layout/Content';
import Spinner from '../../elements/Spinner';
import Card from '../../elements/Card';
import Summary from '../../summary/Summary';
import ContentfulIds from '../../../configuration/ContentfulIds';
import { capitalizeFirstLetter, formatName } from '../../common/Helpers';
import { ContentfulService } from '../../../configuration/ApplicationContext';
import { createBundleSummaryItems } from '../../summary/BundleSummaryItems';
import AdminContext from '../../contexts/AdminContext';
import Link from '../../elements/Link';
import Breadcrumbs from '../../common/Breadcrumbs';
import AdminBaseBundle from './AdminBaseBundle';

const contentStyle = { marginTop: '0rem' };
const linkStyle = { marginTop: '2rem' };
export default class AdminBundleSubscriptionComplete extends AdminBaseBundle {
    static contextType = AdminContext;

    componentDidMount() {
        this.getNofitications();
        this.loadContent();
        this.setBreadCrumb();
        this.loadPersonalInformation();
        this.loadProductsAndSelectProduct();
    }

    getNofitications = async () => {
        await this.props.notificationContext.getNotifications();
    }
    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.Admin.Bundle.SubscriptionSuccessPage);
        this.setState({ content: response.fields });
    }

    setBreadCrumb = () => {
        const breadCrumbRoutes = [
            {
                to: `#`, label: 'New AAA Digital + IDC Signup'
            },
            {
                to: `/admin/bundle/${this.props.match.params.id}/subscription-complete`, label: 'Sign Up Successful'
            }
        ];
        this.setState({ breadCrumbRoutes });
    }

    render() {
        const { content, breadCrumbRoutes, personalInfo, selectedProduct } = this.state || {};
        if (!content || !selectedProduct) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={contentStyle}>
                <Breadcrumbs breadCrumbRoutes={breadCrumbRoutes} isCustomerFacing={false}> </Breadcrumbs>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title>{content.title}</Title>
                        <RichText text={content.description} />
                        <Link to='/admin/member-account' style={linkStyle} text={`${formatName(personalInfo?.firstName, personalInfo?.middleName, personalInfo?.lastName)}'s Details`} />
                        <Link to={`/admin/search?login-id=${this.context.getLoginId()}`} style={linkStyle} text={`Back to Dashboard`} />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        <Card>
                            <Summary product={selectedProduct} title={`Identity Champion ${capitalizeFirstLetter(selectedProduct.name)}`} items={createBundleSummaryItems(selectedProduct, this.context)}></Summary>
                        </Card>
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

