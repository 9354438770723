import { Api, ContentfulService } from "../../configuration/ApplicationContext";
import StringPlaceholderService from "../../services/StringPlaceholderService";
import BaseLogin from "../BaseLogin";
import { subscriptionApplicationIdSessionStorage } from "../common/Helpers";

export default class BaseBundleSignup extends BaseLogin {
    loadContent = async (contentfulId) => {
        const response = await ContentfulService.getEntry(contentfulId);
        const query = new URLSearchParams(this.props.location.search);
        const email = query.get('email');
        const productId = this.props.match.params.id;
        const properties = {
            email
        };
        response.fields.description.content.forEach(ct => {
            ct.content.forEach(pr => {
                if (pr.value) {
                    pr.value = StringPlaceholderService.replace(pr.value, properties)
                }
            })
        })
        this.setState({ productId, email, content: response.fields });
    }

    onSubmit = async (data) => {
        const subscriptionApplicationId = sessionStorage.getItem(subscriptionApplicationIdSessionStorage);
        const { productId } = this.state || {};
        this.setState({
            apiErrors: [],
            isSubmitDisabled: true
        });
        if (Object.keys(data.errors).length > 0) {
            this.setState({ isSubmitDisabled: false });
            return;
        }
        const { email } = data.values;
        const response = await Api.patch(`/api/bundle/subscription-applications/${subscriptionApplicationId}/personal-information/${email}`, {});
        if (!response) {
            this.setState({
                isSubmitDisabled: false
            });
            return;
        }
        if (response.validationErrors) {
            const apiErrors = response.validationErrors.map(apiError => {
                return apiError.message
            });
            this.setState({
                apiErrors,
                isLoading: false,
            });
            return;
        } else {
            let userDetails = this.context.userDetails;
            userDetails.personalInformation = response.personalInformation;
            this.context.updateState({
                userDetails: userDetails
            });
        }
        this.props.history.push(this.getRedirectionMapUrl(productId, this.props.match.params.type));
    }

    getRedirectionMapUrl = (productId, key) => {
        const redirectionMap = new Map()
            .set('non-member', `/checkout/${productId}/payment`)
            .set('bundle', `/bundle/checkout/${productId}/payment`);
        return redirectionMap.get(key);
    }
}