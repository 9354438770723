import React, { useContext } from 'react';
import Span from '../../elements/Span';
import DynamicParagraphText from '../../elements/DynamicParagraphText';
import { formatDateMMDDYYYY, removeLeadingZerosForSingleDigitDate, formatMembershipId, capitalizeFirstLetter } from '../../common/Helpers';
import { colors } from '../../../styles/common';
import CardWithTitle from '../../elements/CardWithTitle';
import CardLineItem from '../../elements/CardLineItem';
import HorizontalRule from '../../elements/HorizontalRule';
import ContainerWithBorder from '../../elements/ContainerWithBorder';
import RichText from '../../elements/RichText';
import { MARKS, BLOCKS } from '@contentful/rich-text-types';
import Link from '../../elements/Link';
import { BASIC, CANCELLED, COMPARE_PLANS, FREE_UPPER_CASE, NONE } from '../../common/Constants'
import AdminContext from '../../contexts/AdminContext';

const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;
const BoldStyle = ({ children, ...props }) => <Span {...props} text={children} />;
const options = {
    renderMark: {
        [MARKS.BOLD]: text => <BoldStyle style={{ color: `${colors.successMessage}`, display: 'block', fontWeight: 'bold', textAlign: 'center' }}>{text}</BoldStyle>
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle style={{ margin: 0 }} desktopFontSize='16px' mobileFontSize='14px'>{children}</ParagraphStyle>,
    },
};
const styles = {
    errorMessage: { color: colors.darkRed, fontWeight: 500 } ,
    invalid: {textAlign : 'left', fontSize : '16px'}
}

const NonMemberCoverageDetails = ({
    content,
    searchResult
}) => {
    const context = useContext(AdminContext);
    const { lastCancelledSubscriptionProductType, lastCancelledSubscriptionTermEndDate, lastCancelledSubscriptionTermStartDate } = context.memberDetails || {};
    const {
        membershipSince,
        membershipExpiryDate,
        membershipId,
        idcMembership,
        membershipStatus,
        invalidMembershipNumber,
        userId
    } = searchResult;
    return (
        <CardWithTitle
            firstGrow={true}
            marginBottom='1rem'
            title='Membership & Coverage'
        >
            <CardLineItem
                itemStyle={{ fontWeight: 'bold', color: `${colors.successMessage}` }}
                itemKey='Coverage Plan'
                itemValue={idcMembership === CANCELLED ? `None (${capitalizeFirstLetter(idcMembership)})` : 'None'}
            />
            {idcMembership === CANCELLED &&
                <>
                    <CardLineItem
                        itemKey='Previous Plan'
                        itemValue={lastCancelledSubscriptionProductType === FREE_UPPER_CASE ? BASIC : capitalizeFirstLetter(lastCancelledSubscriptionProductType)}
                    />
                    <CardLineItem
                        itemKey='IDC coverage began'
                        itemValue={
                            removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(lastCancelledSubscriptionTermStartDate))
                        }
                    />
                    <CardLineItem
                        itemKey='IDC coverage ended'
                        itemValue={
                            removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(lastCancelledSubscriptionTermEndDate))
                        }
                    />
                    {(membershipStatus !== CANCELLED && membershipId) ?
                        <Link
                            to={`/admin/personal-info?action=rejoin`}
                            text='Reactivate'
                            style={{ textAlign: 'right' }}
                        /> : ''
                    }
                </>
            }
            {(userId && idcMembership === NONE) || invalidMembershipNumber || (idcMembership === NONE && membershipStatus !== CANCELLED)  ?
                <Link
                    to='/admin/personal-info'
                    text={invalidMembershipNumber ? 'Add Valid Membership # to Sign Up Member' : 'Sign Up Member'}
                    style={invalidMembershipNumber ? styles.invalid : { textAlign: 'right'  }}
                /> : ''
            }
            <HorizontalRule
                style={{ width: '50%', marginTop: '2rem', marginBottom: '2rem' }}
            />
            <CardLineItem
                style={invalidMembershipNumber || membershipStatus === CANCELLED ? styles.errorMessage : null}
                itemKey='AAA Membership #'
                itemValue={membershipId ? formatMembershipId(membershipId) : NONE}
            />
            {invalidMembershipNumber &&
                <CardLineItem
                    style={styles.errorMessage}
                    itemKey='Membership # invalid'
                />
            }
            {(!invalidMembershipNumber && membershipId) &&
                <>
                    {membershipSince &&
                        <CardLineItem
                            itemKey='Member since'
                            itemValue={removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(membershipSince))}
                        />
                    }
                    {membershipExpiryDate &&
                        <CardLineItem
                            style={membershipStatus === CANCELLED ? styles.errorMessage : null}
                            itemKey={membershipStatus === CANCELLED ? 'Membership expired' : 'Valid through'}
                            itemValue={removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(membershipExpiryDate))}>
                        </CardLineItem>
                    }

                    {idcMembership === CANCELLED &&
                        <>
                            <ContainerWithBorder
                                style={{ border: `1px solid ${colors.successMessage}` }}>
                                <RichText
                                    text={content.rejoinDescription}
                                    options={options}
                                />
                                <Link
                                    to={`/admin/personal-info?action=rejoin`}
                                    style={{ textAlign: 'center', padding: '0.5rem' }}
                                    text='Reactivate to Upgrade'
                                />
                            </ContainerWithBorder>
                        </>
                    }
                    {(userId && idcMembership === NONE) || invalidMembershipNumber || (idcMembership === NONE && membershipStatus !== CANCELLED) ?
                        <>
                            <ContainerWithBorder
                                style={{ border: `1px solid ${colors.successMessage}` }}>
                                <RichText
                                    text={content.descriptionNonePlan}
                                    options={options}
                                />
                                <Link
                                    to='/admin/personal-info'
                                    style={{ textAlign: 'center', padding: '0.5rem' }}
                                    text='Sign Up Member'
                                />
                            </ContainerWithBorder>
                        </> : ''
                    }
                </>}
        </CardWithTitle>
    )
};

export default NonMemberCoverageDetails;