import { Api } from "../configuration/ApplicationContext";
import RedirectionService from "../services/RedirectionService";
import BasePromoCodeComponent from "./BasePromoCodeComponent";
import { CANCELLED_IDC_USER, FREE_UPPER_CASE, IDC_USER, KEYCLOAK_USER, NON_BUNDLE_USER, OFFLINE_USER } from "./common/Constants";
import { subscriptionApplicationIdSessionStorage } from "./common/Helpers";

export default class BaseComponent extends BasePromoCodeComponent {
    checkUserStateAndRedirect = (checkSubscription = false) => {
        const userDetails = this.context.userDetails || {};
        const condition = checkSubscription ? userDetails.subscription : !userDetails.subscription;
        if (condition) {
            this.props.history.push(RedirectionService.getConditionalRenderURL(null, userDetails));
        }
    }

    checkUserStateAndRedirectForAAAJoinFlow = (checkSubscription = false) => {
        if (!this.context.isLoggedIn) {
            Api.login();
        }
        const {userDetails} = this.context || {};
        if(!userDetails?.aaajoinFlowUserFirstAccess && userDetails?.subscription?.productType !== FREE_UPPER_CASE) {
            this.checkUserStateAndRedirect(checkSubscription);
        }
    }

    getRedirectionMapUrl = (productId, email, pageType, key) => {
        const redirectionMap = new Map()
            .set(OFFLINE_USER, `/${pageType}/checkout/${productId}/account-already-exist?email=${email}`)
            .set(IDC_USER, `/${pageType}/checkout/${productId}/idc-account-already-exist?email=${email}`)
            .set(NON_BUNDLE_USER, `/${pageType}/checkout/${productId}/aaa-membership-exist?email=${email}`)
            .set(KEYCLOAK_USER, `/${pageType}/checkout/${productId}/aaa-login?email=${email}`)
            .set(CANCELLED_IDC_USER,`/${pageType}/checkout/${productId}/cancelled-account-already-exist?email=${email}`);
        return redirectionMap.get(key);
    }

    validateExistingAccountAndRedirect = async (pageType) => {
        const subscriptionApplicationId = sessionStorage.getItem(subscriptionApplicationIdSessionStorage);
        const productId = this.props.match.params.productId;
        if(!subscriptionApplicationId) {
            this.setState({validateExistingAccount: true});
            return;
        }
        const updatedPersonalInformation = await Api.get(`/api/bundle/subscription-applications/${subscriptionApplicationId}`);
        if (!updatedPersonalInformation) {
            this.setState({ validateExistingAccount: true });
            return;
        }
        const email = updatedPersonalInformation.personalInformation.email;
        const response = await Api.get(`/api/bundle/subscription-applications/${subscriptionApplicationId}/validate`);
        if (!response) {
            this.setState({ validateExistingAccount: true });
            return;
        }
        if (response.validationErrors) {
            response.validationErrors.forEach(apiError => {
                const redirectTo = this.getRedirectionMapUrl(productId, email, pageType, apiError.property);
                if (redirectTo) {
                    this.props.history.push(redirectTo);    
                } else {
                    this.setState({ validateExistingAccount: true });
                }
            });
        } else {
            this.setState({ validateExistingAccount: true });
        }
    }

    shouldCheckForRedirection = (isAAAJoinFlowUpgradePage = false) => {
        if (!this.context.isLoggedIn) {
            Api.login();
        }
        const {aaajoinFlowUserFirstAccess, subscription} = this.context.userDetails;
           if(aaajoinFlowUserFirstAccess && subscription?.productType === FREE_UPPER_CASE) {
               if(isAAAJoinFlowUpgradePage) {
                   return;
               }
               let userDetails = this.context.userDetails;
               userDetails.aaajoinFlowUserFirstAccess = false;
               this.context.updateState({ 
                   userDetails
               });
              return;
           }
        this.checkUserStateAndRedirect(true);
    }
}