import React, { Fragment, useContext } from 'react';
import Link from '../elements/Link';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Breadcrumb from '../common/Breadcrumb';
import NotificationContext from '../contexts/NotificationContext';
import AdminContext from '../contexts/AdminContext';

const styles = {
    link: { fontWeight: 'normal' }
};
const Breadcrumbs = ({ breadCrumbRoutes = [], isCustomerFacing = true, showOneBreadcrumb }) => {
    const history = useHistory();
    const notificationContext = useContext(NotificationContext);
    const context = useContext(AdminContext);
    let routes = [];
    if (showOneBreadcrumb) {
        routes = [
            ...breadCrumbRoutes
        ];
    } else if (isCustomerFacing) {
        routes = [
            {
                label: 'Dashboard', to: '', externalTo: process.env.REACT_APP_EXPERIAN_SSO_URL,
            },
            {
                to: '/my-account', label: 'My Account'
            },
            ...breadCrumbRoutes
        ];
    } else {
        routes = [
            {
                to: `/admin/search?login-id=${context.getLoginId()}`, label: 'Subscriber Search'
            },
            ...breadCrumbRoutes
        ];
    }
    if (!routes.length) {
        return null;
    }

    const redirectToPage = async (url) => {
        await notificationContext.getNotifications();
        history.push(url);
    }

    return (
        <Breadcrumb
            isCustomerFacing={isCustomerFacing}
            showOneBreadcrumb={showOneBreadcrumb}>
            {
                routes.map(({ to, label, externalTo }, index) => (
                    <Fragment
                        key={index}>
                        {
                            externalTo &&
                            <Link
                                to='#'
                                key={externalTo}
                                style={styles.link}
                                text={label}
                                onClick={() => window.location.href = externalTo}
                            />
                        }
                        {
                            !externalTo && typeof (to) === 'string' &&
                            <RouterLink
                                to='#'
                                onClick={() => redirectToPage(to)}
                                key={to}>
                                {label}
                            </RouterLink>
                        }
                        {
                            !externalTo && typeof (to) === 'object' &&
                            <Link
                                to={
                                    {
                                        pathname: to.pathname,
                                        state: to.state
                                    }
                                }
                                style={styles.link}
                                text={label}
                            />
                        }
                    </Fragment>
                ))
            }
        </Breadcrumb>
    );
}

export default Breadcrumbs