import React from "react";
import styled from "styled-components";
import { mobile, colors } from "../../styles/common";
import Title from "../elements/Title";
import DynamicParagraphText from "../elements/DynamicParagraphText";
import Link from "../elements/Link";
import RichText from "../elements/RichText";
import { BLOCKS } from "@contentful/rich-text-types";

const ProtectMyIDSection = styled.div`
    display: block;
    width: 50%;
    box-sizing: border-box;
    color: ${colors.titleText};
    padding: 3rem 0rem;
    ${mobile`
        padding: 10px;
        width: 100%;
    `}
`;

const TitleMobileStyle = { fontSize: '27px', lineHeight: '36px' };
const TitleDesktopStyle = { fontSize: '36px', lineHeight: '48px' };


const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;
const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle style={{ fontSize: '17px' }} >{children}</DynamicParagraphStyle>,
    },
};

const ProtectMyID = ({ content }) => {
    return (
        <ProtectMyIDSection>
            <Title desktopStyles={TitleDesktopStyle} mobileStyles={TitleMobileStyle}>{content.title}</Title>
            <RichText text={content.description} options={options} />
            <Link style={{ fontSize: '17px' }} textalign="center" to="#" onClick={() => (window.open('https://www.aaa.com/experianidtheft/', '_blank'))} extratopspace="2rem" extrabottomspace="1rem" text={content.linkText} />
        </ProtectMyIDSection>
    );
};

export default ProtectMyID;