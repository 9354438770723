import React from 'react';
import SubmitButton from './elements/SubmitButton';
import ContentfulIds from '../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Summary from './summary/Summary';
import Card from './elements/Card';
import DataContext from './contexts/DataContext';
import DynamicParagraphText from './elements/DynamicParagraphText';
import Title from './elements/Title';
import Spinner from './elements/Spinner';
import { BLOCKS } from '@contentful/rich-text-types';
import RichText from './elements/RichText';
import Section from './elements/Section';
import UpgradeSuccess from './UpgradeSuccess';
import withPageTitle from "./layout/withPageTitle";
import Breadcrumbs from "./common/Breadcrumbs";
import InfoText from './elements/InfoText';
import ReactMarkdown from 'react-markdown';
import { createSummaryItems } from './summary/SummaryItems';

const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</ParagraphStyle>,
    },
};

const breadCrumbRoutes = [
    {
        to: '/paid/product/upgrade/success', label: 'Upgrade'
    }
];

class PaidProductUpgradeSuccesfulPage extends UpgradeSuccess {
    static contextType = DataContext;

    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.context.refreshContext();
        this.loadContent(ContentfulIds.UpgradeSuccessfulPage);
        await this.props.notificationContext.getNotifications();
    }

    render() {
        const { content, description, subDescription } = this.state;
        const { product, userDetails: { subscription } } = this.context;
        const flags = { showRefundAmount: true, showCreditFromPreviousSubscription: true, showBottomSection: true, showPaidInvoiceAmount: true };
        if (!content || !subscription) {
            return <Spinner />;
        }
        return (
            <>
                <Breadcrumbs
                    breadCrumbRoutes={breadCrumbRoutes}
                />
                <Content>
                    <ResponsiveSignupFlowLayout>
                        <SignUpFlowFormContent>
                            <Title
                                desktopOnly='true'>
                                {content.title}
                            </Title>
                            <Title
                                mobileOnly='true'
                                style={{ marginTop: '2rem' }}>
                                {content.title}
                            </Title>
                            <InfoText
                                mobileOnly='true'>
                                <RichText
                                    text={description}
                                    options={options}
                                />
                            </InfoText>
                            <Section
                                mobileOnly='true'
                                text={
                                    <Summary
                                        flags={flags}
                                        product={product}
                                        subscription={subscription}
                                        title={'Identity Champion Plan'}
                                        content={content}
                                        items={
                                            createSummaryItems(product,
                                                this.context,
                                                {
                                                    showRefundAmount: true,
                                                    showCreditFromPreviousSubscription: true
                                                }
                                            )}
                                    />
                                }
                            />
                            <InfoText
                                desktopOnly='true'>
                                <RichText
                                    text={description}
                                    options={options}
                                />
                            </InfoText>
                            <RichText
                                text={subDescription}
                                options={options}
                            />
                            <ReactMarkdown
                                source={content.footerDescription}
                            />
                            <SubmitButton
                                style={{ marginTop: '2rem', marginBottom: '5rem' }}
                                text="Continue to Dashboard"
                                onClick={() => window.location.href = process.env.REACT_APP_EXPERIAN_SSO_URL}
                            />
                        </SignUpFlowFormContent>
                        <SignUpFlowExtraContent>
                            <Card>
                                <Summary
                                    flags={flags}
                                    product={product}
                                    subscription={subscription}
                                    title={'Identity Champion Plan'}
                                    content={content}
                                    items={
                                        createSummaryItems(product,
                                            this.context,
                                            {
                                                showRefundAmount: true,
                                                showCreditFromPreviousSubscription: true,
                                                showPromoFromSubscription: true,
                                                showPaidInvoiceAmount: true
                                            }
                                        )
                                    }
                                />
                            </Card>
                        </SignUpFlowExtraContent>
                    </ResponsiveSignupFlowLayout>
                </Content>
            </>
        );
    }
}

export default withPageTitle(PaidProductUpgradeSuccesfulPage, "Upgrade Successful");
