import React from 'react';
import ProductOffering from './products/ProductOffering';
import Content from './layout/Content';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from './layout/ResponsiveSignupFlowLayout';
import ContentfulIds from '../configuration/ContentfulIds';
import Summary from './summary/Summary';
import Spinner from './elements/Spinner';
import Card from './elements/Card';
import DataContext from './contexts/DataContext';
import BaseProductOffering from './BaseProductOffering';
import withPageTitle from "./layout/withPageTitle";
import Breadcrumbs from './common/Breadcrumbs';
import { createSummaryItems } from './summary/SummaryItems';
import ProductChart from './v1/product-chart/customer/ProductChart';
import Section from './elements/Section';
import HorizontalRule from './elements/HorizontalRule';
import RichText from './elements/RichText';
import { colors, mobile } from '../styles/common';
import { AAA_MEMBER, BASIC_LOWER_CASE, CANCELLED, NON_AAA, UPGRADE } from './common/Constants';
import styled from 'styled-components';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import DynamicParagraphText from './elements/DynamicParagraphText';
import {removeRedirectValueFromLocalStorage } from './common/Helpers';
const ProductChartContainer = styled.div`
    padding-bottom: 40px;
    ${mobile`	
        margin-top: -20px;	
    `}   
`;

const styles = {
    title: { textAlign: 'center', fontSize: '17px', fontWeight: 500 },
    hr: { backgroundColor: colors.pureBlack, marginBottom: '20px' },
    paragraph: { textAlign: 'justify', margin: 0, color: colors.verylightGrey, lineHeight: '20px', paddingTop: '1rem' }
};
const mobileStyles = {
    paragraph: { fontStyle: 'italic', fontSize: '14px', lineHeight: '22px', paddingBottom: '2rem' }
};
const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText
    {...props}
    text={children}
    style={styles.paragraph}
/>;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) =>
            <ParagraphStyle
                anchor={true}
                desktopFontSize='12px'
                mobileStyles={mobileStyles.paragraph}
            >
                {children}
            </ParagraphStyle>,
        [INLINES.HYPERLINK]: (node, next) => {
            return <a
                href={node.data.uri}>
                {node.content[0].value}
            </a>;
        }
    },
};

class ProductUpgradePage extends BaseProductOffering {
    static contextType = DataContext;
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            selectedProduct: null,
            paymentMode: '',
            productPageURL: '/product/basic/upgrade',
            checkoutPageURL: '/upgrade/checkout',
            updateCoveragePage: true,
        };
    }

    componentDidMount() {
        this.setFlowValueInRedirectParamsInSessionStorage(UPGRADE);
        removeRedirectValueFromLocalStorage();
        this.checkUserStateAndRedirect();
        this.setBreadCrumbs();
        this.loadPlans(ContentfulIds.UpgradeBasicPlanPage);
        this.loadAndFilterProductChartContent(ContentfulIds.CustomerCompareOurPlans);
        this.applyPromoCodeFromSessionStorage();
    }
    
    checkUserStateAndRedirect() {
        const { subscription } = this.context.userDetails || {};
        if (!subscription) {
            this.props.history.push('/product/');
        } else if (subscription.productType !== 'FREE') {
            this.props.history.push('/my-account');
        }
    }

    setBreadCrumbs = () => {
        const breadCrumbRoutes = [
            {
                to: `/product/basic/upgrade/${this.props.match.params.id}`, label: 'Upgrade'
            }
        ];
        this.setState({
            breadCrumbRoutes
        });
    }

    onDeclineUpgrade = () => {
        this.props.notificationContext.clearNotifications();
    }
    setSelectedProductInTabToProductTierButton = (productName) => {
        const { products, selectedProduct } = this.state || {};
        if (productName.toLowerCase() !== BASIC_LOWER_CASE) {
            const newlySelectedProductFromTabbedView = products.find(
                product => product.paymentMode === selectedProduct.paymentMode && product.name.toLowerCase() === productName.toLowerCase()
            );
            this.onSelectProduct(newlySelectedProductFromTabbedView);
        }
    }
    render() {
        const {
            products,
            paymentMode,
            selectedProduct,
            content,
            updateCoveragePage,
            breadCrumbRoutes,
            productChartContent,
            isPromoCodeProcessing
        } = this.state || {};
        const {
            userDetails: {
                membership
            } = {}
        } = this.context || {};
        if (!selectedProduct || !content || isPromoCodeProcessing) {
            return <Spinner />;
        }
        return (
            <>
                <Breadcrumbs breadCrumbRoutes={breadCrumbRoutes}></Breadcrumbs>
                <Content>
                    <ResponsiveSignupFlowLayout>
                        <SignUpFlowFormContent>
                            <ProductOffering
                                products={products}
                                selectedProduct={selectedProduct}
                                content={content}
                                onSelectProduct={this.onSelectProduct}
                                paymentMode={paymentMode}
                                onSelectPaymentMode={this.onSelectPaymentMode}
                                hidePromoCode={true}
                                onSubmit={this.onSubmit}
                                updateCoveragePage={updateCoveragePage}
                                onDeclineUpgrade={this.onDeclineUpgrade}
                            />
                        </SignUpFlowFormContent>
                        <SignUpFlowExtraContent>
                            <Card>
                                <Summary
                                    product={selectedProduct}
                                    title={`If you upgrade to ${selectedProduct.name}`}
                                    items={createSummaryItems(selectedProduct, this.context)}
                                    flags={{ showBottomSection: true }}
                                />
                            </Card>
                        </SignUpFlowExtraContent>
                    </ResponsiveSignupFlowLayout>
                    <ProductChartContainer>
                        <HorizontalRule
                            style={styles.hr}
                            mobileonly='true'
                        />
                        <Section
                            mobileOnly='true'
                            text='Compare Plans'
                            style={styles.title}
                        />
                        <ProductChart
                            showDescription={false}
                            showTitle={false}
                            backgroundcolor={colors.pureWhite}
                            fields={productChartContent}
                            defaultMembership={membership !== null 
                                && membership?.membershipStatus !== CANCELLED ? AAA_MEMBER : NON_AAA}
                            hideToggleSection={true}
                            alreadySelectedProduct={selectedProduct.name}
                            disableCta={true}
                            switchTab={true}
                            selectedProductInTab={this.setSelectedProductInTabToProductTierButton}
                        />
                        <RichText
                            text={productChartContent.joinAaaText}
                            options={options}
                        />
                    </ProductChartContainer>
                </Content>
            </>
        );
    }
}

export default withPageTitle(ProductUpgradePage, "Upgrade");
