import React from 'react';
import styled from 'styled-components';
import Span from './Span';
import { mobile } from '../../styles/common';

const CardLineItemStyleComponent = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 1rem;
    ${mobile`
        font-size: 14px;
    `}
`;

const CardLineItem = (props) => {
    return (
        <CardLineItemStyleComponent style={props.style}>
            <Span style={props.itemKeyStyle} text={props.itemKey}></Span>
            <Span style={props.itemStyle} text={props.itemValue}></Span>
        </CardLineItemStyleComponent>
    );
  };

export default CardLineItem;