import React, {Fragment} from 'react';
import {Api, ContentfulService, segmentAnalytics, Toast} from '../../configuration/ApplicationContext';
import ContentfulIds from '../../configuration/ContentfulIds';
import RedirectionService from '../../services/RedirectionService';
import BaseProductOffering from '../BaseProductOffering';
import DataContext from '../contexts/DataContext';
import Spinner from '../elements/Spinner';
import Content from '../layout/Content';
import ResponsiveSignupFlowLayout, {SignUpFlowFormContent} from '../layout/ResponsiveSignupFlowLayout';
import withPageTitle from '../layout/withPageTitle';
import ProductOffering from '../products/ProductOffering';
import InfoText from '../elements/InfoText';
import styled from 'styled-components';
import PlanDetailsContent from '../landing-page/PlanDetailsContent';
import moment from 'moment';
import Disclaimer from '../landing-page/Disclaimer';
import {colors} from '../../styles/common';
import cookie from 'react-cookies';
import AnchorLink from '../elements/AnchorLink';

const planDetailStyle = {
    fontSize: '10px',
    marginRight: '2rem',
};

const PlanDetailContentSection = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: center;  
`;
const linkStyle = { 
    paddingTop: '3rem', 
    paddingBottom: '2rem', 
    backgroundColor: `${colors.lightGrey}`, 
    textAlign: 'center', 
    textDecoration: 'underline', 
    display: 'block', 
    color: `${colors.darkBlue}` 
}

class AAAIdcProductOfferingPage extends BaseProductOffering {
    static contextType = DataContext;
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            selectedProduct: null,
            paymentMode: 'Monthly',
            showOptions: false,
            productPageURL: '/choose/product',
            checkoutPageURL: '/product/checkout'
        };
    }

    async componentDidMount() {
        const loggedIn = await this.loginIfNotAuthenticated();
        if (loggedIn === true) {
            this.deleteRedirectUriCookie();
            this.checkUserStateAndRedirect();
            await this.buildAndSavePersonalInfoObj();
            this.trackSegmentEvent();
            this.loadPlanDetailsContent(ContentfulIds.OldLandingPage);
        }
    }

    //FIXME code is copy-pasted for a hotfix.  Should be consolidated
    async loginIfNotAuthenticated() {
        if (this.context.isLoggedIn === true) {
            return true;
        }
        const loginResponse = await Api.get("/api/users/user/logged-in", true);
        if (loginResponse && loginResponse.status === 200) {
            return true;
        }
        await Api.login(); //FIXME should use an implicit intent that's passed here rather than relying on redirect_uri that's populated elsewhere
    }

    trackSegmentEvent = async () => {
        segmentAnalytics.track('AAA-SUF-See-Plans', {
            memberId: this.context.userDetails.userId
        });
    }
    deleteRedirectUriCookie() {
        cookie.remove('redirect_uri', { path: '/' })
    }
     validateAddress = async(address = {}) => {
        var response = await Api.get(`/api/clubs/zip/${address.zipCode}`);
        if ( !response.serviceable ) {
            Toast.showErrorNotification('IDC is not supported at your Address at this point, please contact the AAA Helpdesk for support!');
            return false;
        }
        return true;
    }

    buildAndSavePersonalInfoObj = async () => {
        const { membership: { firstName, middleName, lastName, email, dob, phoneNumber, address } } = this.context.userDetails || {};
        var { personalInformation } = this.context.userDetails || {};

        if (!this.validateAddress(personalInformation?.homeAddress || address)) {
            return; // FIXME Anti-pattern with multiple return points but its current state and needed for hotfix
        }

        if (!personalInformation) {
            const homeAddress = {
                lineOne: address.lineOne,
                city: address.city,
                state: address.state,
                countryCode: address.countryCode,
                zipCode: address.zipCode,
                lineTwo: address.lineTwo || ''
            };
            const apiPayload = {
                firstName,
                middleName: middleName || '',
                lastName,
                email,
                dob: dob ? moment(dob).format('YYYY-MM-DD') : null,
                phoneNumber,
                homeAddress,
                billingAddress: null,
                billingAddressSameAsHomeAddress: true
            };
            const response = await Api.post('/api/personal-information', apiPayload);
            if (!response || response.validationErrors) {
                return; // FIXME Anti-pattern with multiple return points but its current state and needed for hotfix
            }
            personalInformation = response;
        }

        let userDetails = this.context.userDetails;
        userDetails.personalInformation = personalInformation;
        this.context.updateState({
            userDetails: userDetails
        });
        await this.loadPlans(ContentfulIds.AAAIdcSignupFlow.ProductOfferingPage);
        segmentAnalytics.checkForOptOutStatus(email);
    }

    async loadPlanDetailsContent(contentfulId) {
        const landingPageContent = await ContentfulService.getEntries({ 'sys.id': contentfulId, include: 5 });
        const planDetailContent = landingPageContent.items[0].fields.sections.find(section => section.sys.contentType.sys.id === 'sectionsGetTrustedProtection');
        const disclaimerContent = landingPageContent.items[0].fields.sections.find(section => section.sys.contentType.sys.id === 'titleMessageLink');
        this.setState({
            planDetailsContent: planDetailContent.fields.sections || {},
            planDetailsLinkText: planDetailContent.fields.linkText,
            disclaimerContent: disclaimerContent
        });
    }

    checkUserStateAndRedirect() {
        const userDetails = this.context.userDetails || {};
        if (userDetails.subscription) {
            this.props.history.push(RedirectionService.getConditionalRenderURL(null, userDetails));
        }
    }

    onChooseProduct = (productName) => {
        this.setState({ isSubmitDisabled: true });
        const { paymentMode } = this.state || {};
        if (productName === 'Basic') {
            this.onSelectFreeProduct();
        } else {
            const productsByPaymentMode = this.state.products.filter(product => product.paymentMode === paymentMode);
            const selectedProduct = productsByPaymentMode.find(product => product.name === productName);
            this.context.updateState({ product: selectedProduct });
            segmentAnalytics.track(`AAA-SUF-Select-${selectedProduct.displayName}`, {
                memberId: this.context.userDetails.userId,
                eventLabel: `${selectedProduct.displayName}-${selectedProduct.paymentMode === 'Annually' ? 'Annual' : selectedProduct.paymentMode}`
            });    
            this.props.history.push({
                pathname: this.state.checkoutPageURL + `/${selectedProduct.id}`
            });
        }
    }

    onSelectFreeProduct = async () => {
        segmentAnalytics.track('AAA-SUF-Select-Basic', {
            memberId: this.context.userDetails.userId
        });
        await Api.post('/api/subscriptions/basic-coverage');
        this.props.history.push('/idc-join-complete');
    }

    onSelectPaymentMode = (event, paymentMode) => {
        event.stopPropagation();
        this.setState({ paymentMode });
    }

    onSelectProductTab = (product) => {
        if (product === 'basic') {
            this.setState({ showOptions: false });
        } else {
            this.setState({ showOptions: true });
        }
    }

    render() {
        const { products, paymentMode, content, planDetailsContent, planDetailsLinkText, showOptions, disclaimerContent, isSubmitDisabled } = this.state;
        if (!content || !planDetailsContent || !planDetailsLinkText || !products) {
            return <Spinner />;
        }
        return (
            <Content style={{ padding: 0 }}>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <ProductOffering products={products}
                            content={content} onSelectProduct={this.onSelectProduct}
                            paymentMode={paymentMode} onSelectPaymentMode={this.onSelectPaymentMode}
                            onSubmit={this.onSubmit}
                            isAAAIdcFlow={true}
                            hideSelectProduct={true}
                            showOptions={showOptions}
                            onChooseProduct={this.onChooseProduct}
                            onSelectProductTab={this.onSelectProductTab}
                            isSubmitDisabled={isSubmitDisabled}
                            planDetailsLinkText={planDetailsLinkText}
                            planDetailsContent={planDetailsContent}></ProductOffering>
                    </SignUpFlowFormContent>
                </ResponsiveSignupFlowLayout>
                <InfoText desktopOnly='true' style={{ paddingTop: '1.5rem' }}>
                    <PlanDetailContentSection>
                        {
                            planDetailsContent.map((section, index) =>
                                <Fragment key={index}>
                                    <PlanDetailsContent style={planDetailStyle} key={section.sys.id} planType={section.fields.title}
                                        amount={section.fields.amount} amountAnnual={section.fields.amountAnnual} amountPeriod={section.fields.amountPeriod} amountInfo={section.fields.amountInfo}
                                        buttonText={section.fields.buttonText} insuranceInfo={section.fields.insuranceInfo} totalInsurance={section.fields.totalInsurance} dataList={section.fields.description} paymentMode={paymentMode}
                                        isSubmitDisabled={isSubmitDisabled}
                                        AAAIdcProductOfferingPage={true} isAAAIdcFlow={true} isWhatsIncludedPage={false} onChooseProduct={this.onChooseProduct}
                                    />
                                </Fragment>
                            )
                        }
                    </PlanDetailContentSection>
                    <AnchorLink target="_blank" rel="noopener noreferrer" href='/plan-details' desktopStyles={linkStyle} text={planDetailsLinkText}></AnchorLink>
                </InfoText>
                <Disclaimer section={disclaimerContent} />
            </Content>
        );
    }
}

export default withPageTitle(AAAIdcProductOfferingPage, "Product Offerings");