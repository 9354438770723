import React from 'react'
import Section from './elements/Section';
import Button from './elements/Button';
import { colors, commonPropStyles } from '../styles/common';
import styled, { css } from 'styled-components';

const JoinNowStyle = styled.div`
    ${props => props.styles && css`
        styles: ${props.styles};
    `}
    ${commonPropStyles.mobile}
    ${commonPropStyles.desktop}
`;
const mobileStyle = {
  fontSize: '20px'
}
const desktopStyle = {
  fontSize: '36px'
}
const sectionStyle = {
  textAlign: 'center', 
  color: `${colors.darkBlue}`, 
  marginTop: '10px' 
}
const buttonStyle = {
  margin: '3rem auto', 
  width: '207px',
  height:'52px',
  background: `${colors.darkBlue}`, 
  color: `${colors.pureWhite}` 
}
const mobileButtonStyle = {
  margin: '0.5rem auto 1.5rem', 
  width: '126px',
  height:'32px',
  fontSize:'16px',
  background: `${colors.darkBlue}`, 
  color: `${colors.pureWhite}`,
  lineHeight:'unset'
}
const JoinNowSection = ({onSignup, buttonText, ...props}) => {
    return (
        <JoinNowStyle {...props}>
            {/* <Section text='Ready to get started?' desktopStyles={desktopStyle} mobileStyles={mobileStyle} style={sectionStyle}></Section>
            <Button onClick={onSignup} mobileStyles={mobileButtonStyle} desktopStyles={buttonStyle} text={buttonText || 'Enroll Now'} /> */}
        </JoinNowStyle>
    )
}

export default JoinNowSection;
