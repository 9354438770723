import React from 'react';
import { formatCurrency } from './Helpers';

const Currency = ({value, asterik = false}) => 
    (
        <>
            {formatCurrency(value)}{asterik && '*'}
        </>
    );
    
export default Currency;