import React from 'react';
import styled from 'styled-components';
import Section from '../../../elements/Section';
import { colors } from '../../../../styles/common';
import AnchorLink from '../../../elements/AnchorLink';
import Link from '../../../elements/Link';
import { useHistory } from 'react-router';

const DialogueBoxContent = styled.div`
      width: 85%;
      background: white; 
      margin: 20px auto;
      height: 572px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 6px;
`;
const styles = {
    description: { textAlign: 'center', fontWeight: 'bold', fontSize: '30px', lineHeight: '39px', padding: '0 20px' },
    link: { padding: '0 20px', paddingTop: '20px', fontSize: '18px', lineHeight: '32px', textAlign: 'center', textDecoration: 'underline', color: `${colors.pureBlack}` }
};

const BasicPlanUnavailableDialogueBox = ({ ...props }) => {
    const history = useHistory();
    const {
        diaogueBoxContent: { onlyAvailableToAAAMembers } = {}
    } = props || {};
    const textIncludesHttp = onlyAvailableToAAAMembers.Basic.learnMore.url.includes('http');
    const redirectToUrl = () => {
        history.push(onlyAvailableToAAAMembers.Basic.learnMore.url);
    }
    return (
        <DialogueBoxContent>
            <Section
                style={styles.description}
                text={onlyAvailableToAAAMembers.Basic.titleText}
            />
            {
                textIncludesHttp === true &&
                <AnchorLink
                    style={styles.link}
                    text={onlyAvailableToAAAMembers.Basic.learnMore.text}
                    href={onlyAvailableToAAAMembers.Basic.learnMore.url}
                />
            }
            {
                textIncludesHttp === false &&
                <Link
                    style={styles.link}
                    text={onlyAvailableToAAAMembers.Basic.learnMore.text}
                    to='#'
                    onClick={redirectToUrl}
                />
            }
        </DialogueBoxContent>
    );
};

export default BasicPlanUnavailableDialogueBox;