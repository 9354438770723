import React, { Component } from 'react';
import { ContentfulService, Api } from '../configuration/ApplicationContext';
import ContentfulIds from '../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Title from './elements/Title';
import RichText from './elements/RichText';
import Spinner from './elements/Spinner';
import Link from './elements/Link';
import withPageTitle from "./layout/withPageTitle";
import withPageMetaTags from './layout/withPageMetaTags';
import DynamicParagraphText from './elements/DynamicParagraphText';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { removeLeadingZerosForSingleDigitDate, formatDateMMDDYYYY } from './common/Helpers';
import { CANCELLED, MONTHLY } from './common/Constants';
import DataContext from './contexts/DataContext';
import StringPlaceholderService from "../services/StringPlaceholderService";
import AnchorLink from './elements/AnchorLink';
import { FREE_UPPER_CASE, RENEWAL_DAYS } from './common/Constants';
import { colors } from '../styles/common';

const desktopStyles = {
    paragraph: { fontSize: '17px' },
    title: { lineHeight: '48px' },
    button: { width: '425px', minWidth: '425px', fontSize: '19px', padding: '12px 0', borderRadius: '10px', background: colors.darkBlue, color: colors.pureWhite, textAlign: 'center' }
};
const mobileStyles = {
    link: { fontSize: '14px' },
    button: { fontSize: '19px', padding: '12px 0', borderRadius: '10px', background: colors.darkBlue, color: colors.pureWhite, textAlign: 'center' },
    linkText: { textAlign: 'center' }
};

const DynamicParagraphStyle = ({ children, ...props }) =>
    <DynamicParagraphText
        id="rich-text"
        {...props}
        text={children}
    />;

class GraceMembershipPage extends Component {
    static contextType = DataContext;
    componentDidMount() {
        this.loadContent();
    }

    async loadContent() {
        const { userDetails } = this.context || {};
        const membershipStatus = userDetails?.membership?.membershipStatus;
        const subscription = userDetails?.subscription;
        if (membershipStatus && membershipStatus === CANCELLED) {
            if (subscription?.productType === FREE_UPPER_CASE && userDetails?.graceMembershipNumber) {
                const response = await ContentfulService.getEntry(ContentfulIds.InterstitialPage.GraceMembership);
                this.setState({ content: response.fields });
            } else if (this.context.userDetails?.creationSource !== 'NON_AAA' && subscription?.productType !== FREE_UPPER_CASE) {
                const response = await ContentfulService.getEntry(ContentfulIds.InterstitialPage.RenewMembershiWithPaidSubscription);
                this.setState({ content: response.fields });
            }
        } else if (!membershipStatus && subscription?.productType === FREE_UPPER_CASE) {
            const response = await ContentfulService.getEntry(ContentfulIds.InterstitialPage.InvalidMembershipBasicSubscription);
            this.setState({
                content: response.fields,
            });
        }
    }

    setOptions = () => {
        const { userDetails } = this.context || {};
        const options = {
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) =>
                    <DynamicParagraphStyle
                        desktopStyles={desktopStyles.paragraph}
                        style={{ padding: '10px 0px' }}>
                        {children}
                    </DynamicParagraphStyle>,
                [INLINES.HYPERLINK]: (node, next) =>
                    <Link
                        to='#'
                        onClick={() => this.handleRedirection(node.data.uri)}
                        mobileStyles={mobileStyles.link}
                        text={node.content[0].value}
                    />
            },
            renderText: (text) => {
                const { subscription } = userDetails || {};
                const properties = {
                    membershipExpDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(userDetails?.membership?.membershipExpiryDate)),
                    daysLeftForRenewal: subscription?.daysLeftForRenewal,
                    nonMemberPrice: subscription?.basePrice?.price,
                    renewalDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(subscription?.termEndDate)),
                    paymentMode: subscription?.billingFrequency?.toLowerCase() === MONTHLY.toLowerCase() ? 'month' : 'year'
                };
                return StringPlaceholderService.replace(text, properties)
            }
        };
        return options;
    }

    handleRedirection = async (url) => {
        if (url.includes('https')) {
            window.open(url, '_self');
            return;
        }
        this.props.history.push(url);
    }

    render() {
        const { content } = this.state || {};
        if (!content) {
            return <Spinner />;
        }
        const { subscription, membership } = this.context.userDetails || {};
        const isBasicSubscriptionInvalidMembership = subscription?.productType === FREE_UPPER_CASE && !membership?.membershipStatus;
        return (
            <Content>
                <Title
                    style={{ marginTop: '1rem' }}
                    desktopStyles={desktopStyles.title}>
                    {content.title}
                </Title>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent
                        style={{ padding: 0 }}>
                        <RichText
                            text={content.description}
                            options={this.setOptions()}
                        />
                        {
                            subscription?.daysLeftForRenewal < RENEWAL_DAYS &&
                            <RichText
                                text={content.subDescription2}
                                options={this.setOptions()}
                            />
                        }
                        {
                            subscription?.daysLeftForRenewal >= RENEWAL_DAYS &&
                            <RichText
                                text={content.subDescription}
                                options={this.setOptions()}
                            />
                        }
                        <AnchorLink
                            href='https://mwg.aaa.com/my-account'
                            target="_self"
                            text={isBasicSubscriptionInvalidMembership ? 'Add Valid Membership Number' : 'Renew AAA Membership'}
                            desktopStyles={desktopStyles.button}
                            mobileStyles={mobileStyles.button}
                        />
                        <AnchorLink
                            extratopspace="2rem"
                            extraleftspace={isBasicSubscriptionInvalidMembership ? '0' : '5.5rem'}
                            href={process.env.REACT_APP_EXPERIAN_SSO_URL}
                            mobileStyles={mobileStyles.linkText}
                            target="_self"
                            text={content.linkText}
                        />
                        <RichText
                            text={content.contactUs}
                            options={this.setOptions()}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageMetaTags(withPageTitle(GraceMembershipPage, "Grace Membership"), { noindex: true });