import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import React from "react";
import styled from "styled-components";
import { colors, mobile } from "../../styles/common";
import AnchorLink from "../elements/AnchorLink";
import DynamicParagraphText from "../elements/DynamicParagraphText";
import ParagraphText from "../elements/ParagraphText";
import RichText from "../elements/RichText";
import Section from "../elements/Section";
import { blogUrl } from "../../configuration/ApplicationContext";

const CyberNewsSection = styled.div`
    height: auto;
    width: 100%;
    margin-bottom: 3rem;
    ${mobile`
        flex-direction: column-reverse;
        padding-bottom: 3rem;
        height: auto;
        margin-bottom: 8rem;
    `} 
`;

const CyberNewsContentSection = styled.div`
    margin: 0;
    flex:1;
`;

const CyberNewsDescriptionSection = styled.div`
    padding: 1rem 1.5rem 0rem 1.5rem;
    margin: 10px;
    flex:1;
    ${mobile`
      padding: 1rem 1.5rem 0rem 1.5rem;
      margin: 10px;  
      display: flex;
      flex-direction: column;
    `}
`;
const BackgroundImageSection = styled.div`
    width: auto;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: -webkit-linear-gradient(right, 
      rgba(255,255,255,0) 10%, 
      rgba(255,255,255,0) 20%,
      rgba(255,255,255,0) 80%,
      rgba(255,255,255,1) 100%
    ), url(${props => props.src});
    ${mobile`
      height: 301.64px;
      background-size: auto 100%;
      background-image: -webkit-linear-gradient(bottom, 
        rgba(255,255,255,0.8) 10%, 
        rgba(255,255,255,0) 20%,
        rgba(255,255,255,0) 80%,
        rgba(255,255,255,0) 100%
      ), url(${props => props.src});
     `} 
`;

const mobileTitleStyle = { fontSize: '24px', lineHeight: '29px' };
const desktopTitleStyle = { fontSize: '36px', lineHeight: '48px' };
const titleStyle = { fontWeight: 'normal', color: colors.darkBlue, margin: 0 };
const desktopSubTitleStyle = { fontSize: '14px', fontWeight: 500, lineHeight: '19px' };
const mobileSubTitleStyle = { fontSize: '12px', lineHeight: '16px' };
const descriptionStyle = { fontSize: '20px', lineHeight: '30px', color: colors.myrtleColor, padding: '2rem 0rem' };
const mobileDescriptionStyles = { fontSize: '14px', lineHeight: '24px' };
const buttonStyle = { width: '300px', padding: '0 rem 0.68rem', borderRadius: '10px', background: colors.darkBlue, color: colors.pureWhite, textAlign: 'center' };
const desktopButtonStyle = { fontSize: '19px', lineHeight: '25px' };
const mobileButtonStyle = { fontSize: '16px', lineHeight: '21px', margin: '0 auto' };

const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;
const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText id="rich-text" {...props} text={children} />;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle style={{ padding: '0px 0px' }}>{children}</DynamicParagraphStyle>
    }
};

const CyberNewsBlog = ({ fields}) => {
    const { footer, subTitle1, title2, subTitle3, basicTitle,  basicDescription,  protectTitle,  protectDescription,  buttonText2, jsonContent } = fields;
    return (
        <CyberNewsSection>
            <CyberNewsDescriptionSection>
            <RichText
                mobileStyles={mobileSubTitleStyle}
                options={options}
                desktopStyles={desktopSubTitleStyle}
                text={subTitle1} />
            <RichText
                mobileStyles={mobileSubTitleStyle}
                options={options}
                desktopStyles={desktopSubTitleStyle}
                text={title2} />
            <RichText
                mobileStyles={mobileSubTitleStyle}
                options={options}
                desktopStyles={desktopSubTitleStyle}
                text={subTitle3} />
            <RichText
                mobileStyles={mobileSubTitleStyle}
                options={options}
                desktopStyles={desktopSubTitleStyle}
                text={basicTitle} />
            <RichText
                mobileStyles={mobileSubTitleStyle}
                options={options}
                desktopStyles={desktopSubTitleStyle}
                text={basicDescription} />
            <RichText
                mobileStyles={mobileSubTitleStyle}
                options={options}
                desktopStyles={desktopSubTitleStyle}
                text={protectTitle} />
            <RichText
                mobileStyles={mobileSubTitleStyle}
                options={options}
                desktopStyles={desktopSubTitleStyle}
                text={protectDescription} />
            <AnchorLink
                text={buttonText2}
                style={buttonStyle}
                mobileStyles={mobileButtonStyle}
                desktopStyles={desktopButtonStyle}
                href={`${jsonContent.visitProtectMyIDUrl}`} />
            <RichText
                mobileStyles={mobileSubTitleStyle}
                options={options}
                desktopStyles={desktopSubTitleStyle}
                text={footer} />
            </CyberNewsDescriptionSection>
        </CyberNewsSection>
    );

};

export default CyberNewsBlog ;