import React, { Component } from "react";
import { ContentfulService, segmentAnalytics } from "../configuration/ApplicationContext";
import Spinner from './elements/Spinner';
import ContentfulIds from '../configuration/ContentfulIds';
import WhatsIncluded from "./myaccount/WhatsIncluded";
import withPageMetaTags from './layout/withPageMetaTags';
import styled from "styled-components";
import { mobile } from "../styles/common";
import Title from "./elements/Title";
import DataContext from "./contexts/DataContext";
import withPageTitle from "./layout/withPageTitle";
import Breadcrumbs from "./common/Breadcrumbs";

const Content = styled.div`
    padding: 0px 60px;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 60px;
    box-sizing: border-box;
    ${mobile`
        padding: 0px;
        margin-top: 20px;
    `}
`;

const titleStyle = {
  marginTop: '3.5rem',
  paddingTop: '3rem',
  textAlign: 'center'
}


class WhatsIncludedPage extends Component {
  static contextType = DataContext;
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {
    this.loadContent();
    this.setBreadCrumbs();
    await this.props.notificationContext.getNotifications();
  }

  async loadContent() {
    const contentTypesMap = {};
    for await (const [key, value] of  Object.entries(ContentfulIds.WhatsIncludedPages)) {
      const response = await ContentfulService.getEntry(value);
      contentTypesMap[key] = response.fields;
    }
    this.setState({
      contentTypesMap
    });
    const content = await ContentfulService.getEntries({'sys.id': ContentfulIds.OldLandingPage, include: 5});
    const planDetailContent = content.items[0].fields.sections.find(section => section.sys.contentType.sys.id === 'sectionsGetTrustedProtection');
    this.setState({
      sections: planDetailContent.fields.sections || {}
    });
  }

  setBreadCrumbs = () => {
    const {planType } = this.props.match.params;
    const breadCrumbRoutes = [
      {
          to: `/whats-included/${planType}`, label: 'What\'s Included'
      }
    ];
    this.setState({
      breadCrumbRoutes
    });
  }

  redirectToUpgradeFlow = (plan) => {
    const {planType } = this.props.match.params;
    if (planType === 'basic') {
      segmentAnalytics.track('My Account | Plan Comparison | Basic Member Click Upgrade', {
        tierSelected: 'Protect'
      }); 
    }
    if (planType === 'protect') {
      segmentAnalytics.track(planType === 'basic' ? 'My Account | Plan Comparison | Basic Member Click Upgrade' : 'My Account | Plan Comparison | Protect Member Click Upgrade', {
        tierSelected: 'Complete'
      });    
    }
    const { ratePlanIds, userDetails } = this.context;
    const protectAnnualPlanId = ratePlanIds?.protect?.annualRatePlanId || '';
    const completeAnnualPlanId = ratePlanIds?.complete?.annualRatePlanId || '';
    const productType = userDetails?.subscription?.productType;
    if (plan === 'protect') {
      this.props.history.push(`/product/basic/upgrade/${protectAnnualPlanId}`);
    } else if (plan === 'complete' && productType === 'PROTECT') {
      this.props.history.push(`/product/protect/upgrade/${completeAnnualPlanId}`);
    } else if (plan === 'complete' && productType === 'FREE') {
      this.props.history.push(`/product/basic/upgrade/${completeAnnualPlanId}`);
    }
  }

  render() {
      const { contentTypesMap, breadCrumbRoutes, sections } = this.state;
      const {planType } = this.props.match.params;
      if (!['basic', 'protect'].includes(planType)) {
        return null;
      }
      if (!contentTypesMap || !planType || !sections ) {
        return <Spinner />
      }
      return (
          <>
            <Breadcrumbs breadCrumbRoutes={breadCrumbRoutes}></Breadcrumbs>
            <Content>
                <Title desktopOnly='true'>My Account: What's Included</Title>
                <Title mobileOnly='true' style={titleStyle}>My Account: What's Included</Title>
                <WhatsIncluded content={contentTypesMap} planType={planType} sections={sections} redirectToUpgradeFlow={this.redirectToUpgradeFlow}></WhatsIncluded>
            </Content>
          </>
      );
  }
}

export default withPageMetaTags(withPageTitle(WhatsIncludedPage, "What's Included"), { noindex: true });