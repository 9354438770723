import cookie from 'react-cookies';
import { Api } from './../configuration/ApplicationContext';

class ZipCodeService {
  static setZipCode(zip) {
    cookie.save('idc_zipcode', `${zip}`, { path: '/' });
  }

  static checkIdcEligibility() {
    const zipCode = ZipCodeService.getZipCookie();
    return Api.get(`/api/clubs/zip/${zipCode}`).then((response) => {
      return response;
    }).catch(() => {
        return null;
    });
  }

  static checkBundleEligibility() {
    const zipCode = ZipCodeService.getZipCookie();
    if(zipCode) {
      return Api.get(`/api/clubs/zip/bundle-flow/${zipCode}`).then((response) => {
        return response;
      }).catch(() => {
          return null;
      });
    } return null;
  }

  static getZipCookie() {
    return cookie.load('idc_zipcode');
  }

  static onChange(fn) {
    ZipCodeService.listeners = ZipCodeService.listeners || [];
    ZipCodeService.listeners.push(fn);
  }
}

export default ZipCodeService;
