import { checkIsLoginProcessCompleted, getMemberTypeFromSessionStorage } from "./Helpers";
import { variation } from '../../configuration/ApplicationContext';
import { NON_AAA } from "./Constants";

export const bundleSignUpFlowSteps = [
    {
        number: 1,
        name: 'Choose Plan',
    },
    {
        number: 2,
        name: 'Personal Info',
    },
    {
        number: 3,
        name: 'Payment',
    },
    {
        number: 4,
        name: 'Validate Email',
    },
    {
        number: 5,
        name: 'Create Password',
    }
];

export const dynamicSignUpFlowSteps = (hidePaymentStep = false, shouldStepsClickable = false, data = {}) => {
    const loginProcessCompleted = checkIsLoginProcessCompleted();
    const steps = [
        {
            number: 1,
            name: variation.productPageType === 'UPSELL' ? 'Coverage Level' : 'Choose Plan',
            linkUrl: `/product/${data.productId}`,
            shouldStepsClickable
        }
    ];
    getMemberTypeFromSessionStorage() != NON_AAA && steps.push({
        number: 2,
        name: 'Sign In with AAA',
        shouldStepsClickable: false
    });
    steps.push({
        number: getMemberTypeFromSessionStorage() != NON_AAA ? 3 : 2,
        name: 'Personal Info',
        linkUrl: `/checkout/${data.productId}/personal-info`,
        shouldStepsClickable
    });
    !hidePaymentStep && steps.push({
        number: getMemberTypeFromSessionStorage() != NON_AAA ? 4 : 3,
        name: 'Payment',
        linkUrl: `/checkout/${data.productId}/payment`,
        shouldStepsClickable
    });

    return steps;
};

export const setupMonitoringSteps = (monitoringSteps) => {
    const defaultStep = [
        {
            number: 1,
            text: 'Verify your Social Security Number¹'
        },
        {
            number: 2,
            text: 'Answer personal verification questions'
        },
        {
            number: 3,
            text: 'Set up and explore your dashboard'
        }
    ];
    return monitoringSteps || defaultStep;
};
