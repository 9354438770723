import React from "react";
import styled from "styled-components";
import Title from "../elements/Title";
import { mobile, colors } from "../../styles/common";
import { BLOCKS } from "@contentful/rich-text-types";
import RichText from "../elements/RichText";
import DynamicParagraphText from "../elements/DynamicParagraphText";
import StringPlaceholderService from "../../services/StringPlaceholderService";
import withPageMetaTags from "../layout/withPageMetaTags";
import withPageTitle from "../layout/withPageTitle";

const OutofAreaSignupSucessSection = styled.div`
    display: block;
    width: 50%;
    box-sizing: border-box;
    padding: 3rem 0rem 0rem 0rem;
    color: ${colors.titleText};
    ${mobile`
        width: 100%;
        padding: 10px;
    `}
`;
const TitleDesktopStyle = { fontSize: '36px', lineHeight: '48px' };
const TitleMobileStyle = { fontSize: '27px', lineHeight: '36px' };

const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;
const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle style={{ fontSize: '17px' }} >{children}</DynamicParagraphStyle>,
    },
};

const OutofAreaSignupSucess = ({ content, zipCode, email}) => {
    const properties = {
        email: email, 
        zipCode: zipCode
    };
    content.description.content.forEach(ct => {
        ct.content.forEach(pr => {
            if (pr.value) {
                pr.value = StringPlaceholderService.replace(pr.value, properties)
            }
        })
    })
    return (

        <OutofAreaSignupSucessSection>
            <Title desktopStyles={TitleDesktopStyle} mobileStyles={TitleMobileStyle}>{content.title}</Title>
            <RichText text={content.description} options={options} />
        </OutofAreaSignupSucessSection>

    );
};


export default withPageMetaTags(withPageTitle(OutofAreaSignupSucess, "Out of Area Success Page"), { noindex: true });