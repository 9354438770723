import React, { useState, useEffect, useContext } from 'react';
import { ContentfulService, segmentAnalytics } from '../../../configuration/ApplicationContext';
import ContentfulIds from '../../../configuration/ContentfulIds';
import Content from '../../layout/Content';
import RichText from '../../elements/RichText';
import Title from '../../elements/Title';
import DynamicParagraphText from '../../elements/DynamicParagraphText';
import UnorderedListText from '../../elements/UnorderedListText';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import Link from '../../elements/Link';
import AdminContext from '../../contexts/AdminContext';
import Spinner from '../../elements/Spinner';
import { useLocation } from 'react-router-dom';
import AdminNotificationContext from '../../contexts/AdminNotificationContext';
import cookie from 'react-cookies';
import { deleteIndividualPromoCodeNameValueFromSessionStorage, removeMemberTypeFromSessionStorage, removeSubscriptionApplicationIdSessionStorage } from '../../common/Helpers';

const styles = {
    paragraph: { marginTop: '3rem' },
    link: { marginTop: '3rem', width: '50%', fontWeight: 500, textAlign: 'left' },
    title: { fontSize: '32px', marginTop: 'unset' }
};
const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;

const UnorderedListStyle = ({ children, ...props }) => <UnorderedListText {...props} text={children} />;

const options = {
    renderNode: {
        [BLOCKS.UL_LIST]: (node, children) => <UnorderedListStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</UnorderedListStyle>,
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</ParagraphStyle>,
        [INLINES.HYPERLINK]: (node, next) => {
            return <Link style={{ display: 'inline' }} to={node.data.uri} text={node.content[0].value} />;
        }
    },
};

const MemberSearchPage = () => {
    const context = useContext(AdminContext);
    const [content, setContent] = useState(null);
    const location = useLocation();
    const notificationContext = useContext(AdminNotificationContext);

    useEffect(() => {
        async function loadContent() {
            const response = await ContentfulService.getEntry(ContentfulIds.MemberSearchPage);
            setContent(response.fields);
        }
        loadContent();
    }, []);

    useEffect(() => {
        localStorage.removeItem('searchResult');
        removeSubscriptionApplicationIdSessionStorage();
        removeMemberTypeFromSessionStorage();
        deleteIndividualPromoCodeNameValueFromSessionStorage();
        context.updateState({ searchResult: {} });
        notificationContext.clearNotifications();
        context.removePromoCodeData();
    }, []);

    useEffect(() => {
        const loginId = new URLSearchParams(location.search).get("login-id");
        if (loginId) {
            cookie.save('loginId', loginId);
            context.updateState({ loginId });
        }
    }, [location]);

    const processNonAAAMember = () => {
        segmentAnalytics.track('Sales-Tool-Start-New-Signup', {
            oneLoginID: context.loginId
        });
        localStorage.removeItem('searchResult');
        context.updateState({ searchResult: {} });
    }
    if (!content || context.isSearching) {
        return <Spinner />;
    }

    return (
        <Content
            style={{ marginTop: '1rem' }}>
            <Title
                style={styles.title}>
                {content.title}
            </Title>
            <RichText
                text={content.description}
                options={options}
            />
            <DynamicParagraphText
                style={styles.paragraph}
                text={
                    <RichText
                        text={content.subDescription}
                        options={options}
                    />
                }
            />
            <Link
                style={styles.link}
                to='/admin/personal-info-non-member'
                text={content.linkText}
                textalign="center"
                onClick={processNonAAAMember}
            />
            <DynamicParagraphText
                desktopFontSize='16px'
                mobileFontSize='14px'
                text={content.footerDescription}
            />
        </Content>
    );
}

export default MemberSearchPage;
