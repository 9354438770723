import React from 'react';
import styled, {css} from 'styled-components';
import { mobile, desktop, colors, commonPropStyles, desktopOnly } from '../../styles/common';

const ParagraphTextComponent = styled.p`
    font-style: normal;
    font-weight: normal;	
    font-size: 14px;
    
    ${props => props.desktopFontSize && css`
      ${desktop`
        font-size: ${props.desktopFontSize};
      `}
    `};
    ${props => props.mobileFontSize && css`
      ${mobile`
        font-size: ${props.mobileFontSize};
      `}
    `};
    ${props => props.anchor && css`
        a {
          color: ${colors.darkBlue};
          font-weight: 500;
        }
    `};
    ${props => props.textdecoration && css`
        a {
          text-decoration: ${props.textdecoration}
        }
    `};
    ${props => props.desktopOnly && css`
        ${desktopOnly}
    `}
    ${commonPropStyles.mobile}
    ${commonPropStyles.desktop}
`;

const DynamicParagraphText = ({text, ...props}) => {
    return (
        <ParagraphTextComponent {...props}>{text}</ParagraphTextComponent>
    )
  }
  
export default DynamicParagraphText;