import React, { Component } from 'react';
import AdminContext from '../contexts/AdminContext';
import Content from '../layout/Content';
import { AdminApi, ContentfulService } from '../../configuration/ApplicationContext';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from '../layout/ResponsiveSignupFlowLayout';
import Title from '../elements/Title';
import moment from 'moment';
import Spinner from '../elements/Spinner';
import { buildUrl, formatName, getSubscriptionApplicatonIdFromSessionStorage, removeMemberTypeFromSessionStorage, removeSpacesFromMembershipNumber, setMemberTypeInSessionStorage, subscriptionApplicationIdSessionStorage } from '../common/Helpers';
import Breadcrumbs from '../common/Breadcrumbs';
import ContentfulIds from '../../configuration/ContentfulIds';
import ParagraphText from '../elements/ParagraphText';
import AdminPersonalInfoForm from './forms/AdminPersonalInfoForm';
import Notification from '../notifications/Notification';
import { MembershipNotification } from '../MembershipNotification';
import { ApiNotification } from '../common/ApiNotification'
import { NON_AAA, NON_AAA_MEMBER, REJOIN, SIGNUP } from '../common/Constants';
class AdminPersonalInfoPage extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            apiErrors: [],
            billingAddressSameAsHomeAddress: true,
            showAAAMembershipNumber: true,
            urlParams: {}
        };
    }

    async componentDidMount() {
        this.removeDataFromSessionStorage();
        await this.context.getMemberDetails();
        this.checkUserType();
        this.checkRejoinFlow()
        this.loadContent();
        this.populateAddress();
        this.setBreadCrumb();
    }
    removeDataFromSessionStorage = () => {
        removeMemberTypeFromSessionStorage();
    }
    checkUserType = () => {
        const userType = this.props.match.params.userType;
        this.setState({ userType, showAAAMembershipNumber: userType !== NON_AAA_MEMBER });
    }
    getContentFulID = (key) => {
        const contentFulMap = new Map()
            .set(REJOIN, ContentfulIds.Admin.RejoinFlow.PersonalInfoPage)
            .set(SIGNUP, ContentfulIds.Admin.PersonalInfoPage);
        return contentFulMap.get(key);
    }
    checkRejoinFlow = () => {
        const params = new URLSearchParams(this.props.location.search).get('action');
        if (params === REJOIN) {
            this.setState({
                reactivateUser: true,
                showAAAMembershipNumber: true,
                urlParams: {
                    action: params
                }
            })
            const { searchResult } = this.context;
            const updatedSearchResult = {
                ...searchResult,
                setPhone: true,
                setDob: true
            };
            this.context.updateState({ searchResult: updatedSearchResult });
            localStorage.setItem('searchResult', JSON.stringify(updatedSearchResult));
        }
    }
    setBreadCrumb = () => {
        const breadCrumbRoutes = [];
        const { reactivateUser } = this.state || {};
        const { displayName } = this.context.searchResult || {};
        if (displayName !== undefined) {
            breadCrumbRoutes.push({ to: '/admin/member-account', label: `${displayName}` });
        }
        breadCrumbRoutes.push({
            to: buildUrl('/admin/personal-info', this.state.urlParams), label: `${reactivateUser ? 'Reactivate 1:' : 'Signup 1'} Personal Details`
        });
        this.setState({ breadCrumbRoutes });
    }

    populateAddress() {
        this.setState({
            homeAddress: this.context.searchResult.address,
            billingAddress: this.context.searchResult.address
        });
    }

    onSubmit = async (data) => {
        if (Object.keys(data.errors).length > 0) {
            return;
        }
        this.setState({
            apiErrors: [],
            customErrorMessages: [],
        });
        const { firstName, middleName, lastName, email, dob, phoneNumber, additionalHomeInfo, additionalBillingInfo, membershipNumber } = data.values;
        const { aaaLoginIdentifier, membershipId, attributeNationalId, attributeMembershipId, loginEmail } = this.context.searchResult || {};
        const homeAddress = { ...this.state.homeAddress, lineTwo: additionalHomeInfo };
        const billingAddressSameAsHomeAddress = this.state.billingAddressSameAsHomeAddress;
        const billingAddress = { ...this.state.billingAddress, lineTwo: additionalBillingInfo };
        const { showAAAMembershipNumber, userType } = this.state || {};
        if (Object.keys(data.errors).length > 0) {
            return;
        }
        const apiPayload = {
            personalInformationRequest: {
                firstName,
                middleName,
                lastName,
                email,
                dob: moment(dob).format('YYYY-MM-DD'),
                phoneNumber: phoneNumber.replace(/[^\d]/g, ''),
                homeAddress,
                billingAddress,
                billingAddressSameAsHomeAddress
            },
            membershipNumber: showAAAMembershipNumber ? removeSpacesFromMembershipNumber(membershipNumber) : membershipId,
            aaaLoginIdentifier,
            attributeNationalId,
            attributeMembershipId,
            loginEmail
        };
        this.setState({ isLoading: true });
        const response = userType === NON_AAA_MEMBER
            ? await AdminApi.post('/api/bundle/subscription-applications', {
                id: getSubscriptionApplicatonIdFromSessionStorage() || null,
                personalInformation: apiPayload.personalInformationRequest
            })
            : await AdminApi.post('/api/users', apiPayload);
        if (!response) {
            this.setState({ isLoading: false });
            return;
        }
        if (response.validationErrors) {
            let apiErrors = response.validationErrors.filter(apiError => {
                switch (apiError.property) {
                    case 'digitalAccount':
                    case 'subscription': {
                        const { setNotification, customErrorMessages } = ApiNotification.alreadyActiveSubscription;
                        const notifications = setNotification;
                        this.setState({
                            notifications,
                            customErrorMessages
                        });
                        break;
                    }
                    case 'membershipNumber': {
                        const notifications = MembershipNotification(apiError);
                        this.setState({
                            notifications,
                        });
                        break;
                    }
                    case 'cancelledMembershipNumber': {
                        const { setNotification } = ApiNotification.cancelledMembershipNumber;
                        const customErrorMessages = ApiNotification.cancelledMembershipNumber.customErrorMessages;
                        const notifications = setNotification;
                        this.setState({
                            notifications,
                            customErrorMessages
                        });
                        break;
                    }
                    case 'address': {
                        const { setNotification, customErrorMessages } = ApiNotification.address;
                        const notifications = setNotification;
                        this.setState({
                            notifications,
                            customErrorMessages
                        });
                        break;
                    }
                    default:
                        return [apiError.message]
                }
            }, null);
            this.setState({
                apiErrors,
                isLoading: false
            });
        } else {
            if (response.message && !response.personalInformationRequest) {
                this.setState({
                    apiErrors: [response.message],
                    isLoading: false
                });
                return;
            }
            if (userType === NON_AAA_MEMBER) {
                setMemberTypeInSessionStorage(NON_AAA);
                sessionStorage.setItem(subscriptionApplicationIdSessionStorage, response.id);
                const {
                    personalInformation
                } = response || {};
                const {
                    firstName,
                    middleName,
                    lastName,
                    dob,
                    homeAddress,
                    phoneNumber,
                    billingAddress,
                    billingAddressSameAsHomeAddress
                } = personalInformation || {};
                const searchResult = {
                    email,
                    personalInformation,
                    firstName,
                    middleName,
                    lastName,
                    dob,
                    address: homeAddress,
                    phone: phoneNumber,
                    homeAddress,
                    billingAddress,
                    billingAddressSameAsHomeAddress,
                    idcMembership: 'basic',
                    displayName: formatName(firstName, middleName, lastName)
                };
                const memberDetails = {
                    personalInformation
                };
                this.context.updateState({
                    memberDetails,
                    searchResult
                });
                localStorage.setItem('searchResult', JSON.stringify(searchResult));
                this.setState({
                    isLoading: false
                });
                this.props.history.push(`/admin/${NON_AAA_MEMBER}/checkout/product/${this.context.ratePlanIds.complete.annualRatePlanId}`);
            } else {
                let memberDetails = this.context.memberDetails || {};
                memberDetails.personalInformation = response.personalInformationRequest;
                memberDetails.membershipId = this.context.searchResult.membershipId;
                this.context.updateState({
                    memberDetails: memberDetails
                });
                const searchResult = JSON.parse(localStorage.getItem('searchResult'));
                searchResult.idcMembership = 'basic';
                localStorage.setItem('searchResult', JSON.stringify(searchResult));

                const planType = localStorage.getItem('RedirectionPlanType');
                if (planType) {
                    localStorage.removeItem('RedirectionPlanType');
                    this.props.history.push(`/admin/product/${planType}`);
                } else {
                    this.props.history.push(buildUrl(`/admin/product/${this.context.ratePlanIds.complete.annualRatePlanId}`, this.state.urlParams));
                }
            }
        }
    };

    onSelectAddress = (homeAddress) => {
        this.setState({
            homeAddress
        });
    }

    onSelectBillingAddress = (billingAddress) => {
        this.setState({
            billingAddress
        });
    }

    onBillingAddressSameAsHomeAddress = (billingAddressSameAsHomeAddress) => {
        this.setState({ billingAddressSameAsHomeAddress });
    }

    async loadContent() {
        const params = new URLSearchParams(this.props.location.search).get('action') || 'signup';
        const response = await ContentfulService.getEntry(this.getContentFulID(params));
        this.setState({ content: response.fields });
    }

    clearCustomErrorMessage = (fieldName) => {
        this.setState({
            customErrorMessages: {},
            notifications: (fieldName === 'email' && this.state?.customErrorMessages?.email) ? [] : this.state.notifications
        })
    }
    render() {
        const {
            loginEmail
        } = this.context.memberDetails || {};
        const {
            content,
            showAAAMembershipNumber,
            notifications,
            customErrorMessages,
            breadCrumbRoutes,
            reactivateUser,
            isLoading,
            userType
        } = this.state || {};
        if (!content || isLoading) {
            return <Spinner />;
        }
        return (
            <Content
                desktopStyles={{ marginTop: '0rem' }}>
                <Breadcrumbs
                    isCustomerFacing={false}
                    breadCrumbRoutes={breadCrumbRoutes}
                />
                {
                    notifications && notifications.map(notification => {
                        return <Notification
                            key={notification.notificationType}
                            {...notification}
                            {...this.props}
                        />
                    })
                }
                <Title
                    style={{ marginTop: '1rem' }}>
                    {content.title}
                </Title>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <ParagraphText>
                            {content.description}
                        </ParagraphText>
                        <AdminPersonalInfoForm
                            onSubmit={this.onSubmit}
                            onSelectAddress={this.onSelectAddress}
                            onSelectBillingAddress={this.onSelectBillingAddress}
                            onBillingAddressSameAsHomeAddress={this.onBillingAddressSameAsHomeAddress}
                            billingAddressSameAsHomeAddress={this.state.billingAddressSameAsHomeAddress}
                            showAAAMembershipNumber={showAAAMembershipNumber}
                            showBottomText={true}
                            apiErrors={this.state.apiErrors}
                            personalInformation={this.context.searchResult}
                            content={content}
                            updateMembershipNumber={true}
                            customErrorMessages={customErrorMessages}
                            clearCustomError={this.clearCustomErrorMessage}
                            loginEmail={loginEmail}
                            showLoginEmail={reactivateUser === true ? true : false}
                            disableDob={reactivateUser === true ? true : false}
                            cancelRoute={userType === NON_AAA_MEMBER ? '/admin/search' : '/admin/member-account'}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default AdminPersonalInfoPage;
