import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { colors, desktop, mobile } from '../../../../styles/common';
import { BASIC, NON_AAA } from '../../../common/Constants';
import BasicPlanUnavailableDialogueBox from './BasicPlanUnavailableDialogueBox';
import ProductChartBottomSection from './ProductChartBottomSection';
import ProductChartMiddleSection from './ProductChartMiddleSection';
import ProductChartUpperSection from './ProductChartUpperSection';

const ProductChartSectionsStyle = styled.div`
    padding: 15px 40px;
    display: flex;
    gap: 1rem;
    justify-content: center;
    ${mobile`
        display: ${props => props.displayproductchartsectioninmobile};
        width: 100%;
        gap: 1px;
    `}
    ${props => props.productchartsectionpadding && css`
        padding: ${props.productchartsectionpadding};
    `}
    ${props => props.productchartsectionwidth && css`
        width: ${props.productchartsectionwidth};
    `}
`;
const OverlayContainer = styled.div`
    background-color: rgba(36, 36, 36, 0.6);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
`;
const DialogueBoxContainer = styled.div`
    position: absolute;
    top: 10%
`;
const ProductChartSection = styled.div`
    width: 32%;
    text-align: center;
    background: white;
    border-radius: 10px;
    position: relative;
    ${mobile`
        width: 34%;
    `}
    ${desktop`
        box-shadow: 0px 3px 25px 8px rgba(0, 0, 0, 0.1);
    `}
`;
const MiddleSection = styled.div`
    min-height: ${props => props.minheight};
    ${mobile`
        background: ${colors.searhRowBackgroundColor}
    `}
`;
const ProductChartSections = ({ ...props }) => {
    const [middleSectionHeight, setMiddleSectionHeight] = useState();
    const {
        sections,
        onSignup,
        showUpperSection = true,
        showMiddleSection = true,
        showBottomSection = true,
        showBasicNotAvailableOverlayDialogBox = true,
        displayProductChartSectionInMobile = 'none',
        memberType,
        disableCta,
        onProductSelectToCreateSubscription
    } = props || {};
    if (!sections) {
        return null;
    }
    const setMiddleSectionHeightDynamically = (ref) => {
        if (!ref) return;
        const height = ref.getBoundingClientRect().height;
        if (middleSectionHeight === undefined || middleSectionHeight < height) {
            setMiddleSectionHeight(height);
        }
    }

    return (
        <ProductChartSectionsStyle
            displayproductchartsectioninmobile={displayProductChartSectionInMobile}
            {...props}>
            {
                sections.map(section =>
                    <ProductChartSection
                        key={section.sys.id}
                    >
                        {
                            showUpperSection === true &&
                            <ProductChartUpperSection
                                {...section.fields}
                            />
                        }
                        {
                            showMiddleSection === true &&
                            <MiddleSection
                                minheight={`${middleSectionHeight}px`}>
                                {
                                    showBasicNotAvailableOverlayDialogBox === true &&
                                    memberType === NON_AAA
                                    && section.fields.title === BASIC &&
                                    <OverlayContainer>
                                        <DialogueBoxContainer>
                                            <BasicPlanUnavailableDialogueBox
                                                diaogueBoxContent={section.fields.commonDataDetails}
                                            />
                                        </DialogueBoxContainer>
                                    </OverlayContainer>
                                }
                                <ProductChartMiddleSection
                                    {...section.fields}
                                    onSignup={onSignup}
                                    choosePlan={props.choosePlan}
                                    memberType={memberType}
                                    disableCta={disableCta}
                                    onProductSelectToCreateSubscription={onProductSelectToCreateSubscription}
                                    setMiddleSectionHeightDynamically={setMiddleSectionHeightDynamically}
                                />
                            </MiddleSection>
                        }
                        {
                            showBottomSection === true &&
                            <ProductChartBottomSection
                                {...section.fields}
                            />
                        }
                    </ProductChartSection>
                )
            }
        </ProductChartSectionsStyle>
    );
}
export default ProductChartSections;