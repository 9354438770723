import React, { useEffect, useRef } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { Element } from 'react-scroll';
import styled from 'styled-components';
import RichText from "../elements/RichText";
import { mobile } from './../../styles/common';

import FaqQuestionsItem from "./FaqQuestionsItem";


const Question = styled.section`
    margin: 20px 0px 20px 60px;
    ${mobile`
        margin: 0px 0px 0px 60px;
        width: 100%;
    `}
`;

const QuestionList = styled.div`
    margin: 28px 0;
    ${mobile`
       margin-top: 5px;
   `}
`;
const ScrollableAccordionItemPanel = styled(AccordionItemPanel)`
    scroll-margin-top: 125px;
    margin: 0px 0px 0px 21px;
    font-size: 14px;
    padding-top: 2rem;
`
const spanStyle = { 
    scrollMarginTop: '120px'
}

const accordianExpand = (event) => {
    const ele = document.querySelector(`#accordion__panel-${event[0]}`);
    ele.scrollIntoView(true);
}

const FaqQuestions = ({ category,currentCategory, questionIdParam, ...props }) => {
    console.log("currentCategory" + currentCategory);
    const spanRef = useRef();
    
    return (
    <>
        <span style={spanStyle} ref={spanRef}></span>
        <Question>
            <RichText text={currentCategory?.fields?.description} />         
        </Question>
    </>
    );
};

export default FaqQuestions;