import React from 'react';
import styled, { css } from 'styled-components';
import { colors, desktop, mobile } from '../../styles/common';

const ToggleSwitchInput = styled.label`
    position: relative;
    padding: 0 20px;
`;

const ToggleInput = styled.input.attrs({ type: 'checkbox' })`
    display: none;

    &:checked + span {
        &::before {
            ${mobile`
                left: 27px;
                ${props => props.mobiletoggleinputcheckedspanbeforestyle && css`
                    ${props.mobiletoggleinputcheckedspanbeforestyle}
                `}
            `}
            ${desktop`
                left: 27px;
                ${props => props.desktoptoggleinputcheckedspanbeforestyle && css`
                    ${props.desktoptoggleinputcheckedspanbeforestyle}
                `}
            `}
        }
    }
`;

const Slider = styled.span`
    display: flex;
    cursor: pointer;
    width: 50px;
    height: 25px;
    border-radius: 100px;
    background-color: ${colors.darkBlue};
    position: relative;
    transition: background-color 0.2s;
    ${props => props.desktopsliderspanstyle && css`
           ${props.desktopsliderspanstyle}
    `}
    ${mobile`
        width: 45px;
        height: 22px;
        ${props => props.mobilesliderspanstyle && css`
           ${props.mobilesliderspanstyle}
        `}
    `}

    &::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 21px;
        height: 21px;
        border-radius: 45px;
        transition: .2s;
        background: ${colors.pureWhite};
        box-shadow: 0 2px 4px 0 rgba(0,35,11,.2);
        ${props => props.desktopsliderspanbeforestyle && css`
           ${props.desktopsliderspanbeforestyle}
        `}
        ${mobile`
            width: 17px;
            height: 17px;
            ${props => props.mobilesliderspanbeforestyle && css`
                 ${props.mobilesliderspanbeforestyle}
            `}
    `}
    }
`;

const ToggleSwitch = ({ onChange, isChecked, ...props }) => (
    <ToggleSwitchInput>
        <ToggleInput
            onChange={onChange}
            defaultChecked={isChecked}
            checked={isChecked}
            {...props}
        />
        <Slider
            {...props}
        />
    </ToggleSwitchInput>
)

export default ToggleSwitch;