import React from 'react';

const AdminNotificationContext = React.createContext({
    notifications: [],
    addNotification: () => {},
    removeNotification: () => {},
    getNotifications: () => {},
    clearNotifications: () => {}
});

export default AdminNotificationContext;