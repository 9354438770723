import React, { useContext } from 'react';
import CardWithTitle from '../elements/CardWithTitle';
import { segmentAnalytics } from '../../configuration/ApplicationContext';
import CardLineItem from '../elements/CardLineItem';
import Link from '../elements/Link';
import { colors, notAvailableMessage } from '../../styles/common';
import Currency from '../common/Currency';
import InfoText from '../elements/InfoText';
import { constructCreditCardExpiryDate, removeLeadingZerosForSingleDigitDate, formatDate, formatCurrency, absoluteValue, formatDateMMDDYYYY, capitalizeFirstLetter } from '../common/Helpers';
import StringPlaceholderService from '../../services/StringPlaceholderService';
import RichText from '../elements/RichText';
import { BLOCKS } from '@contentful/rich-text-types';
import { MyAccountPageType } from './MyAccountPageType';
import DataContext from '../contexts/DataContext';
import { useHistory } from 'react-router-dom';
import HorizontalRule from '../elements/HorizontalRule';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import { ACTIVE, CANCELLED, FREE_UPPER_CASE, PROTECT_UPPER_CASE } from '../common/Constants';

const linkStyle = {
    fontSize: '16px',
    color: colors.blue,
    display: 'inline'
}
const ParagraphStyle = {
    fontSize: '16px',
    fontStyle: 'italic'
}

const boldStyle = { fontWeight: 'bold' };
const italicStyle = { fontStyle: 'italic' };

const hrStyle = { width: '50%', marginTop: '2rem', marginBottom: '2rem' };
const redBoldColor = {
    fontWeight: 500,
    color: colors.red,
};
const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText id="rich-text" style={ParagraphStyle} {...props} text={children} />;
const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return <DynamicParagraphStyle > {children} </DynamicParagraphStyle>
        }
    },
};

const PaymentDetails = ({ content, pageType, upcomingSubscriptionProductType }) => {
    const context = useContext(DataContext);
    const history = useHistory();
    const subscription = context?.userDetails?.subscription;
    const membershipExpiryDate = context?.userDetails?.membership?.membershipExpiryDate;
    const nextBillingDate = formatDateMMDDYYYY(subscription?.termEndDate);
    const freeTrialEndDate = formatDateMMDDYYYY(subscription?.freeTrialEndDate);
    const isFreeTrialGracePeriod = freeTrialEndDate && upcomingSubscriptionProductType;
    const lastBillingDate = formatDateMMDDYYYY(subscription?.lastPaymentDate);
    const formattedMembershipDate = membershipExpiryDate ? formatDateMMDDYYYY(membershipExpiryDate) : notAvailableMessage;
    const { creditCardNumber, creditCardType } = subscription.defaultPaymentMethod || {};
    const { lastPaymentAmount, nextPaymentAmount, actualPrice, basePrice, discount, billingFrequency, upcomingSubscriptionBillingFrequency, promoCodeName, promoCode, customFields, cancelledSubscriptionGracePeriod } = subscription || {};
    const billingPlan = upcomingSubscriptionProductType && upcomingSubscriptionProductType !== FREE_UPPER_CASE ? upcomingSubscriptionBillingFrequency : billingFrequency;
    const lastFourCardNumberDigit = creditCardNumber?.substr(creditCardNumber.length - 4);
    const nonMemberDiscountApplied = promoCode && customFields?.Description?.value.includes('Gig');
    const { expirationYear, expirationMonth } = subscription?.defaultPaymentMethod || {};
    const creditCardExpiryDate = formatDate(constructCreditCardExpiryDate(expirationYear, expirationMonth));
    const hideNextPaymentDetail = cancelledSubscriptionGracePeriod === true || upcomingSubscriptionProductType === FREE_UPPER_CASE;

    const options = {
        renderText: (text) => {
            const properties = {
                formattedMembershipDate: removeLeadingZerosForSingleDigitDate(formattedMembershipDate),
                memberDiscount: formatCurrency(absoluteValue(subscription.discount?.price)) || '',
                priceWithoutDiscount: formatCurrency(absoluteValue(subscription.basePrice?.total)) || '',
                priceWithDiscount: formatCurrency(absoluteValue(subscription.actualPrice?.total)) || '',
                planType: subscription?.billingFrequency === 'ANNUALLY' ? 'year' : 'month',
                billingFrequency: billingPlan === 'ANNUALLY' ? 'year' : 'month',
                asteriskSymbol: context?.userDetails?.membership && !nonMemberDiscountApplied ? '*' : '',
                doubleAsteriskSymbol: context?.userDetails?.membership && !nonMemberDiscountApplied ? '**' : '',
                productType: capitalizeFirstLetter(subscription.productType),
                freeTrialEndDate
            };
            return StringPlaceholderService.replace(text, properties)
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return <DynamicParagraphStyle > {children} </DynamicParagraphStyle>
            }
        }
    };

    if (!subscription.defaultPaymentMethod) {
        return null;
    }

    const onClick = () => {
        segmentAnalytics.track('My Account | View Update Payment Screen', {
            memberId: context.userDetails.userId
        });
        history.push('/update-payment');
    }
    let disclaimerText = membershipExpiryDate &&
        (context?.userDetails?.membership?.membershipStatus === ACTIVE
            || (context?.userDetails?.membership?.membershipStatus === CANCELLED
                && context?.userDetails?.creationSource !== 'NON_AAA')
        )
        ? content.paymentMessage
        : (nonMemberDiscountApplied
            ? content.paymentMsgGracePeriodWithNonMemberDiscount
            : content.paymentMessageGracePeriod);

    return (
        <>
            <CardWithTitle title='Payment Details'>
                {pageType !== MyAccountPageType.BASIC && !hideNextPaymentDetail &&
                    <>
                        <CardLineItem itemKeyStyle={boldStyle} itemStyle={boldStyle} itemKey='Next Payment Date' itemValue={removeLeadingZerosForSingleDigitDate(nextBillingDate)} />
                        <HorizontalRule style={hrStyle} />
                        <CardLineItem itemKey={`Base Price ${billingPlan === 'MONTHLY' ? '(Monthly)' : '(Annual)'}`} itemValue={<>{basePrice ? <Currency value={basePrice?.price} /> : 'N/A'}</>} />
                        {actualPrice && actualPrice?.tax > 0 && <CardLineItem itemKey='Taxes' itemValue={<Currency value={actualPrice?.tax} />} />}
                        {discount && Math.abs(discount?.price) > 0 && <CardLineItem itemKeyStyle={italicStyle} itemStyle={italicStyle} itemKey='AAA Member Discount*' itemValue={<>{discount ? <Currency value={discount?.price} /> : 'N/A'}</>} />}
                        {promoCodeName && promoCode && <CardLineItem itemKeyStyle={italicStyle} itemStyle={italicStyle} itemKey={nonMemberDiscountApplied ? `${customFields.Description.value}` : `${promoCodeName} Promo`} itemValue={<><Currency value={promoCode?.price} /></>} />}
                        <CardLineItem itemKeyStyle={boldStyle} itemStyle={boldStyle} itemKey='Next Payment Total' itemValue={<><Currency value={nextPaymentAmount} /></>} />
                    </>
                }

                {!hideNextPaymentDetail && <HorizontalRule style={hrStyle} />}
                {[MyAccountPageType.COMPLETE, MyAccountPageType.PROTECT, MyAccountPageType.PAYMENT_METHOD_EXPIRING, MyAccountPageType.BASIC].includes(pageType) &&
                    <CardLineItem itemStyle={{ ...boldStyle, fontSize: '15px' }} itemKey='Payment Method' itemValue={creditCardType + ' **** **** **** ' + lastFourCardNumberDigit} />
                }
                {pageType === MyAccountPageType.PAYMENT_METHOD_EXPIRED &&
                    <>
                        <CardLineItem itemStyle={{ ...boldStyle, fontSize: '15px', ...redBoldColor }} itemKey='Payment Method' itemValue={creditCardType + ' **** **** **** ' + lastFourCardNumberDigit} />
                        <CardLineItem itemStyle={{ ...boldStyle, fontSize: '15px', ...redBoldColor, ...italicStyle }} itemValue={'Expired ' + creditCardExpiryDate} />
                    </>
                }
                <CardLineItem itemStyle={boldStyle} itemValue={<Link to='#' style={linkStyle} text={content.updatePaymentMethodLink} onClick={onClick} />} />
                {isFreeTrialGracePeriod &&
                    <>
                        <CardLineItem itemStyle={{ ...boldStyle, fontSize: '15px' }} itemKey='Payment Term' itemValue={billingFrequency === 'MONTHLY' ? 'Monthly' : 'Annual'} />
                        {billingFrequency === 'MONTHLY' && subscription?.productType === PROTECT_UPPER_CASE && <CardLineItem itemStyle={boldStyle} itemValue={<Link to={'/whats-included/protect'} style={linkStyle} text="Switch to Annual (Saves 20%)" />} />}
                    </>
                }
                {!freeTrialEndDate &&
                    <>
                        <HorizontalRule style={hrStyle} />
                        <CardLineItem itemKey='Last Payment Date' itemValue={removeLeadingZerosForSingleDigitDate(lastBillingDate)} />
                        <CardLineItem itemKey='Last Payment' itemValue={<Currency value={lastPaymentAmount} />} />
                    </>
                }
                {/* {!hideNextPaymentDetail &&
                    <>
                        <HorizontalRule style={hrStyle} />
                        <InfoText style={{ marginBottom: '1rem' }}>
                            {nonMemberDiscountApplied && <RichText text={content.paymentMessageNonMemberDiscount} options={options} />}
                            <RichText text={disclaimerText} options={options} />
                        </InfoText>
                    </>
                } */}
            </CardWithTitle>
        </>
    )
};

export default PaymentDetails;