import React from 'react';
import AdminHeader from './AdminHeader';
import withPageTitle from "./withPageTitle";

const withAdminHeader = (WrappedComponent) => {
    const WrappedComponentWithAdminRouting = withPageTitle(WrappedComponent, "Admin");
    return (props) => (
        <>
            <AdminHeader />
            <WrappedComponentWithAdminRouting {...props} />
        </>
    );
};

export default withAdminHeader;
