import React from 'react';
import ContentfulIds from '../../configuration/ContentfulIds';
import Content from '../layout/Content';
import Spinner from '../elements/Spinner';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from '../layout/ResponsiveSignupFlowLayout';
import Card from '../elements/Card';
import BaseAdminProductOffering from './BaseAdminProductOffering';
import AdminProductOffering from './AdminProductOffering';
import AdminSummary from './summary/AdminSummary';
import AdminContext from '../contexts/AdminContext';
import Breadcrumbs from '../common/Breadcrumbs';
import ProductChart from '../v1/product-chart/customer/ProductChart';
import { colors } from '../../styles/common';
import { AAA_MEMBER, CANCELLED, NON_AAA } from '../common/Constants';
import RichText from '../elements/RichText';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import DynamicParagraphText from '../elements/DynamicParagraphText';

const styles = {
    paragraph: { textAlign: 'justify', margin: 0, color: colors.verylightGrey, lineHeight: '20px', paddingTop: '1rem' }
}

const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText
    {...props}
    text={children}
    style={styles.paragraph}
/>;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) =>
            <ParagraphStyle
                anchor={true}
                desktopOnly='true'
                desktopFontSize='12px'
            >
                {children}
            </ParagraphStyle>,
        [INLINES.HYPERLINK]: (node, next) => {
            return <a
                href={node.data.uri}>
                {node.content[0].value}
            </a>;
        }
    },
};
export default class PaidTierProductUpgradePage extends BaseAdminProductOffering {
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            selectedProduct: null,
            paymentMode: '',
            productPageURL: '/admin/product/protect/upgrade',
            checkoutPageURL: '/admin/product/protect/upgrade/checkout',
            subscriptionAction: 'UPGRADE'
        }
    }

    async componentDidMount() {
        await this.context.getMemberDetails();
        this.setBreadCrumb();
        this.loadPlans(ContentfulIds.Admin.MemberAccountIds.UpgradeCompletePlanPage);
        this.loadProductChartContent(ContentfulIds.Admin.ChooseYourPlanPage);
    }

    render() {
        const {
            products,
            paymentMode,
            selectedProduct,
            content,
            breadCrumbRoutes,
            productChartContent
        } = this.state;
        const {
            subscription,
            membership
        } = this.context.memberDetails || {};
        if (!selectedProduct || !content || !productChartContent) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{ marginTop: 0 }}>
                <Breadcrumbs
                    isCustomerFacing={false}
                    breadCrumbRoutes={breadCrumbRoutes}
                />
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <AdminProductOffering
                            products={products}
                            selectedProduct={selectedProduct}
                            content={content}
                            onSelectProduct={this.onSelectProduct}
                            paymentMode={paymentMode}
                            onSelectPaymentMode={this.onSelectPaymentMode}
                            onSubmit={this.onSubmit}
                            hideSelectProduct={true}
                            subscription={subscription}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        <Card>
                            <AdminSummary
                                isPaidTierUpgrade={true}
                                product={selectedProduct}
                                title={`If Subscriber upgrades to ${selectedProduct.name}`}
                            />
                        </Card>
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
                <ProductChart
                    showTitle={false}
                    showDescription={false}
                    backgroundcolor={colors.pureWhite}
                    fields={productChartContent}
                    defaultMembership={membership !== null && membership?.membershipStatus !== CANCELLED ? AAA_MEMBER : NON_AAA}
                    hideToggleSection={true}
                    openComparePlansLinkInNewTab={true}
                    disableCta={true}
                />
                <RichText
                    text={productChartContent.joinAaaText}
                    options={options}
                />

            </Content>
        );
    }
}
