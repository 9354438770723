import React, { Component } from 'react';
import { ContentfulService } from '../configuration/ApplicationContext';
import LandingContent from './layout/LandingContent';
import ResponsiveLandingPageLayout from './layout/ResponsiveLandingPageLayout';
import Spinner from './elements/Spinner';
import ContentfulIds from '../configuration/ContentfulIds';
import Title from './elements/Title';
import RichText from './elements/RichText';
import { colors, mobile } from '../styles/common';
import styled from 'styled-components';
import withZipGate from './layout/withZipGate';
import withPageTitle from "./layout/withPageTitle";
import withPageMetaTags from './layout/withPageMetaTags';
import { getPromoCodeNameFromRedirectParams } from './common/Helpers';

const styles = {
    title: { textAlign: 'center', color: `${colors.blue}`, paddingTop: '30px', margin: '0' },
    landing: { background: `${colors.lightGrey}`, }
};
const InsuranceSection = styled.div`
    padding: 20px 65px;
    ${mobile`
        padding: 0 20px 56px 20px;
    `}
`
class InsurancePage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.loadContent();
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.InsurancePage);
        this.setState({
            content: response.fields
        });
    }

    render() {
        const { content } = this.state;
        if (!content) {
            return <Spinner />
        }
        return (
            <LandingContent
                promocodename={getPromoCodeNameFromRedirectParams()}
                style={styles.landing}>
                <ResponsiveLandingPageLayout>
                    <Title
                        style={styles.title}>
                        {content.title}
                    </Title>
                    <InsuranceSection>
                        <RichText
                            text={content.description}
                            style={{ fontSize: '14px' }}
                        />
                    </InsuranceSection>
                </ResponsiveLandingPageLayout>
            </LandingContent>
        );
    }
}

export default withPageMetaTags(withPageTitle(withZipGate(InsurancePage), "Insurance"), { noindex: true });