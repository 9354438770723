import get from 'lodash/get';

class StringPlaceholderService {
  static replace(content, properties) {
    const replacedContent = content.replace(/\$\{(.+?)\}/g, (all) => {
      const key = all.substring(2, all.length - 1);
      return get(properties, key);
    });
    return replacedContent;
  }
}

export default StringPlaceholderService;
