import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import SubmitButton from '../elements/SubmitButton';
import ContentfulIds from '../../configuration/ContentfulIds';
import { colors, mobile, desktop } from '../../styles/common';
import Modal, { ModalProvider, BaseModalBackground } from "styled-react-modal";
import InfoText from '../elements/InfoText';
import RichText from '../elements/RichText';
import { AdminApi, ContentfulService, segmentAnalytics } from '../../configuration/ApplicationContext';
import AdminContext from '../contexts/AdminContext';
import { BASIC, CANCELLED, FREE_UPPER_CASE, PROTECT } from '../common/Constants';
import { capitalizeFirstLetter, formatDateMMDDYYYY, mapDisclaimerToProperty, removeLeadingZerosForSingleDigitDate } from '../common/Helpers';

const StyledModal = Modal.styled`
  background-color: ${colors.pureWhite};
  ${desktop`	
    width: 650px;
    height: 641px;
  `}	
  ${mobile`	
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  `}
`;

const FadingBackground = styled(BaseModalBackground)`
  transition: opacity ease 200ms; 
  z-index: 100;
`;

const ModalContent = styled.div`
    padding: 5.125rem 8.375rem 0rem 7.06rem;
`;

const Title = styled.div`
    font-size: 1.375rem;
    font-weight: 500;
    padding-top: 1rem;
    padding-bottom: 2rem;
    color: ${colors.titleText};
`;

const SubmitButtonStyled = styled(SubmitButton)`
    font-weight: 500;
    font-size: 19px;
    line-height: 25px;
    text-align: center;
    color: ${colors.pureWhite};
    background: ${colors.darkBlue};
    margin-top: 1.6875rem;
    width: 100%;
    ${props => props.disabled && css`
      opacity: 0.5; 
      cursor: default;
    `};
`;

const descriptionStyle = {
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '1.5rem'
};

const customButtonStyle = {
  background: colors.pureWhite,
  border: `2px solid ${colors.darkBlue}`,
  color: colors.darkBlue
};

const closeIconStyle = {
  cursor: 'pointer',
  paddingRight: '2rem',
  paddingTop: '1rem',
  float: 'right',
  color: colors.searchResultsHeaderColor
};

class CancelPlanModal extends Component {
  static contextType = AdminContext;
  
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {
    await this.loadContent();
  }

  async loadContent() {
    const { upcomingSubscriptionProductType, subscription } = this.context.memberDetails || {};
    const contentKey = upcomingSubscriptionProductType !== null ? 'downgrade' : 'normal';
    const response = await ContentfulService.getEntry(this.getContent(contentKey));
    this.setState({ content: response.fields, pageType: contentKey });
    const properties = {
      upcomingSubscription: upcomingSubscriptionProductType === FREE_UPPER_CASE ? 'Basic' : capitalizeFirstLetter(upcomingSubscriptionProductType),
      upcomingSubscriptionDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(subscription.termEndDate))
    };
    mapDisclaimerToProperty(response.fields.description, properties)
  }
  getContent = (key) => {
    const content = new Map()
        .set('normal', ContentfulIds.AdminCancelPlanPage.NormalFlow)
        .set('downgrade', ContentfulIds.AdminCancelPlanPage.OverrideDowngradeWithCancel);
    return content.get(key);
  }

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  }

  cancelPlan = async () => {
    const { personalInformation } = this.context.memberDetails || {};
    this.setState({disabled: true});
    const response = await AdminApi.post(`/api/users/${this.context.searchResult.userId}/cancel`);
    if (response && this.props.isFreeTrialUser) {
      const searchResult = JSON.parse(localStorage.getItem('searchResult'));
      searchResult.idcMembership =  CANCELLED;
      localStorage.setItem('searchResult', JSON.stringify(searchResult));
    }
    segmentAnalytics.track('Sales-Tool-Member-Cancellation', {
      oneLoginID: this.context.getLoginId(),
      firstName: personalInformation.firstName,
      lastName: personalInformation.lastName,
      email: personalInformation.email,
      homeAddress: personalInformation.homeAddress,
      city: personalInformation.homeAddress.city,
      state: personalInformation.homeAddress.state,
      zipCode: personalInformation.homeAddress.zipCode,
      phoneNumber: personalInformation.phoneNumber,
      dob: personalInformation.dob,
      aaaMemberNumber: this.context.memberDetails.membershipId
    });
    this.props.redirectToCancelSuccess();
  }

  render() {
    const { content, disabled, pageType } = this.state;
    const { membership, invalidMembershipNumber } = this.context.memberDetails || {};
    if (!content) {
      return null;
    }
    const hideDowngradeLink = this.props.productType === BASIC || (this.props.productType === PROTECT && invalidMembershipNumber) || (membership && membership?.membershipStatus === CANCELLED && this.props.productType === PROTECT);
    return (
      <ModalProvider backgroundComponent={FadingBackground}>
        <StyledModal
          isOpen={this.props.isOpen}
        >
          <InfoText style={closeIconStyle} onClick={this.props.closeModal}>X</InfoText>
          <ModalContent>
            <Title>{content.title}</Title>
            <InfoText style={descriptionStyle}>
              <RichText text={content.description}/>
              {membership && <RichText text={content.subDescription} />}
              <RichText text={content.contactUs} />
            </InfoText>
            {pageType === 'normal' &&
            <>
              <SubmitButtonStyled style={customButtonStyle} text='No - Return to Member Summary' onClick={this.props.closeModal}/>
              {!hideDowngradeLink && <SubmitButtonStyled style={customButtonStyle} text='No - Downgrade Account' onClick={this.props.onDowngradeClick} />}
              <SubmitButtonStyled text='Yes - Cancel Account' onClick={this.cancelPlan} disabled={disabled}/>
            </>
            }
            {pageType === 'downgrade' &&
            <>
              <SubmitButtonStyled style={customButtonStyle} text="No - Don't Take Any Action" onClick={this.props.closeModal}/>
              <SubmitButtonStyled text='Yes - Start Cancellation' onClick={this.cancelPlan} disabled={disabled}/>
            </>
            }
          </ModalContent>
        </StyledModal>
      </ModalProvider>
    );
  }
}

CancelPlanModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isBasic: PropTypes.bool.isRequired
};

export default CancelPlanModal;
