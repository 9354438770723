import React from 'react';
import styled, {css}  from 'styled-components';
import { colors } from '../../styles/common';

const RadioButtonWrapper = styled.section`
    margin-bottom: 1rem;
    margin-top: 1rem;
    align-items: center;
    display: flex;
    ${props => props.disabled && css`
        color: ${colors.darkGrey}
    `}
`;

const RadioButtonStyleComponent = styled.input`
    font-size: 17px;
    font-weight: normal;
    line-height: 23px;
    align-self: start;
    color:${colors.darkBlue};
`;

const RadioButton = ({ ...props }) => {
    return (
        <RadioButtonWrapper {...props}>   
            <RadioButtonStyleComponent type='radio' {...props}/>{props.label}
        </RadioButtonWrapper> 
    )
}

export default RadioButton;
