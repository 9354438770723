import React from "react";
import { Api, ContentfulService } from "../../configuration/ApplicationContext";
import Spinner from './../elements/Spinner';
import ContentfulIds from '../../configuration/ContentfulIds';
import styled from "styled-components";
import { desktop, colors } from "../../styles/common";
import Title from "./../elements/Title";
import withPageTitle from "./../layout/withPageTitle";
import RichText from "../elements/RichText";
import DynamicParagraphText from "../elements/DynamicParagraphText";
import SubmitButton from "../elements/SubmitButton";
import { BLOCKS } from "@contentful/rich-text-types";
import BaseComponent from "../BaseComponent";
import InfoText from "../elements/InfoText";
import CheckboxInput from "../elements/CheckboxInput";
import WelcomeSteps from "../elements/WelcomeSteps";
import { setupMonitoringSteps } from "../common/Steps";
import Notification from "../notifications/Notification";
import { setValidationNotification } from "../common/Helpers";
import Content from "../layout/Content";
import ReactMarkdown from "react-markdown";
import DataContext from '../contexts/DataContext';
import { TERMS_AND_CONDITIONS_ACCEPTED, trackEvent } from '../../services/SegmentService';
import moment from "moment";
import { AAA_USER } from "../common/Constants";
const ContentBox = styled.div`
    ${desktop`
        width: 60%;
    `}
`;
const ParagraphStyle = ({ children, ...props }) =>
    <DynamicParagraphText {...
        props}
        text={children}
    />;
const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) =>
            <ParagraphStyle
                style={{ color: colors.titleText }}
                mobileStyles={{ fontSize: '14px', lineHeight: '26px', padding: '0rem 1rem' }}
                desktopStyles={{ fontSize: '17px' }}
                anchor={true}
                textdecoration='none'>
                {children}
            </ParagraphStyle>
    },
};

const style = {
    getStartedText: { fontWeight: 'bold', margin: '30px auto 15px auto', fontSize: '17px' },
    tncDescription: { lineHeight: '25px', marginLeft: '10px' },
    checkboxCustomErrorMessage: { color: colors.darkRed, fontSize: '14px', marginBottom: '1rem' },
    checkboxErrorStyle: { display: 'flex', border: `2px solid ${colors.darkRed}`, padding: '1rem' },
    checkboxStyle: { margin: '25px auto', display: 'flex' },
    link: { textDecoration: 'none', color: colors.darkBlue, fontWeight: 500 }
};
const desktopStyle = {
    title: { lineHeight: '48px', fontSize: '36px' },
    submitButton: { fontSize: '19px', margin: '40px 0px' },
    infoText: { marginTop: '2rem' },
    content: { margin: '60px 0px' }
};
const mobileStyle = {
    title: { marginBottom: '1.5rem', lineHeight: '32px', fontSize: '24px', padding: '0rem 1rem' },
    description: { padding: '0rem 1rem' },
    getStartedText: { padding: '0rem 1rem' },
    tncAccepted: { padding: '0rem 1rem' },
    submitButton: { fontSize: '19px', width: '96%', margin: '0 auto' },
    disclaimer: { padding: '0rem 1rem' },
    content: { paddingLeft: '0px', paddingRight: '0px', paddingTop: 0 },
    checkboxCustomErrorMessage: { padding: '0rem 1rem', marginBottom: '20px' }
};

class AutoEnrollmentWelcomePage extends BaseComponent {
    static contextType = DataContext;
    state = {
        checked: false,
        notifications: []
    };

    async componentDidMount() {
        this.monitoringSteps();
        this.loadContent();
    }

    monitoringSteps = () => {
        const steps = [
            {
                number: 1,
                text: 'Accept Terms & Conditions'
            },
            {
                number: 2,
                text: 'Verify your Social Security Number¹ and answer personal verification questions'
            },
            {
                number: 3,
                text: 'Set up and explore your dashboard'
            }
        ];
        this.setState({ steps });
    }
    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.AAAJoinFlowAutoEnrollment);
        this.setState({ content: response.fields });
    }

    clearValidations = () => {
        if (this.state.checked) {
            this.setState({
                notifications: [],
                showNotification: false,
                showError: false
            })
        }
    }

    onSubmit = async () => {
        const { userDetails: { userId } } = this.context || {};
        if (!this.state.checked) {
            const notifications = setValidationNotification('Please click the \“I have read and agree...\” checkbox to continue.', 'error');
            this.setState({
                notifications,
                showNotification: true,
                showError: true
            });
        } else {
            this.clearValidations();
            this.setState({ isSubmitDisabled: true, isProcessing: true });
            trackEvent(TERMS_AND_CONDITIONS_ACCEPTED, {
                user_id: this.context.userDetails.userId,
                event_created: moment(),
                source_system: 'IDC Website',
                MemberType : AAA_USER
            });
            const response = null;
            if (!response) {
                this.setState({ isSubmitDisabled: false, isProcessing: false });
                return;
            }
            if (response.validationErrors) {
                this.setState({ isSubmitDisabled: false, isProcessing: false });
                await this.context.refreshContext();
                this.props.history.push('/my-account');
            } else {
                window.location.href = process.env.REACT_APP_EXPERIAN_SSO_URL;
            }
        }
    }

    updateTermsAndCondition = () => {
        this.setState({
            checked: !this.state.checked
        }, () => {
            this.clearValidations();
        });
    }
    render() {
        const { content, checked, notifications, showError, steps, isSubmitDisabled, isProcessing } = this.state || {};
        if (!content || isProcessing) {
            return <Spinner />;
        }
        return (
            <Content
                desktopStyles={desktopStyle.content}
                mobileStyles={mobileStyle.content}>
                <InfoText
                    desktopStyles={desktopStyle.infoText}>
                    {
                        notifications.map(notification => {
                            return <Notification
                                key={notification.notificationType}
                                {...notification}
                                {...this.props}
                            />
                        })
                    }
                </InfoText>
                <ContentBox>
                    <Title
                        mobileStyles={mobileStyle.title}
                        desktopStyles={desktopStyle.title}>
                        {content.subTitle}
                    </Title>
                    <DynamicParagraphText
                        mobileStyles={mobileStyle.description}
                        desktopFontSize='17px'
                        mobileFontSize='14px'
                        text={
                            <RichText text={content.description}
                            />
                        }
                    />
                    <DynamicParagraphText
                        style={style.getStartedText}
                        mobileStyles={mobileStyle.getStartedText}
                        text={
                            <RichText text={content.subDescription}
                            />
                        }
                    />
                    <WelcomeSteps
                        steps={setupMonitoringSteps(steps)}
                    />
                    <InfoText
                        mobileStyles={mobileStyle.tncAccepted}
                        style={showError ? style.checkboxErrorStyle : style.checkboxStyle}>
                        <CheckboxInput
                            name="isTnCAccepted"
                            checked={checked}
                            onChange={() => { this.updateTermsAndCondition() }}
                        />
                        <ReactMarkdown
                            source={content.footerDescription}
                            className="margin-0"
                            renderers={
                                {
                                    link: props => <a
                                        href={props.href}
                                        style={style.link}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {props.children}
                                    </a>
                                }
                            }
                        />
                    </InfoText>
                    {
                        showError &&
                        <DynamicParagraphText
                            text="Please click checkbox to continue"
                            mobileStyles={mobileStyle.checkboxCustomErrorMessage}
                            style={style.checkboxCustomErrorMessage}
                        />
                    }
                    <SubmitButton
                        mobileStyles={mobileStyle.submitButton}
                        desktopStyles={desktopStyle.submitButton}
                        text={content.linkText}
                        disabled={isSubmitDisabled}
                        onClick={this.onSubmit} />
                    <RichText
                        text={content.contactUs}
                        options={options}
                    />
                </ContentBox>
            </Content>
        );
    }
}

export default withPageTitle(AutoEnrollmentWelcomePage, "Auto Enrollment Page");
