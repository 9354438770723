import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import JoinNowSection from '../JoinNowSection';
import RichText from '../elements/RichText';
import { BLOCKS } from '@contentful/rich-text-types';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import { commonPropStyles } from '../../styles/common';

const DisclaimerSection = styled.div`
    display: block;
`;
const PlanDisclaimerSection = styled.div`
    ${commonPropStyles.mobile}
`;
const mobileDisclaimerStyle = {
    padding: '1rem 2rem 0rem 1rem'
}
const desktopDisclaimerStyle = {
    padding: '12px 40px 0px 30px'
}
const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle anchor={true} desktopFontSize='16px' mobileFontSize='14px' desktopStyles={desktopDisclaimerStyle} mobileStyles={mobileDisclaimerStyle} >{children}</ParagraphStyle>,
    },
};
const subDescriptionOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle anchor={true} desktopFontSize='16px' mobileFontSize='14px' style={{ margin: 0 }} desktopStyles={{ padding: '0px 40px 40px 28px' }} mobileStyles={{ padding: '1rem 2rem 12rem 1rem' }}  >{children}</ParagraphStyle>,
    },
};

const PlanDetailsDisclaimer = (props) => {
    const history = useHistory();
    const {
        disclaimer
    } = props || {};
    const joinNow = () => {
        history.push(`/product/`);
    };
    const paddingBottom = { paddingBottom: '0rem' };
    return (
        <PlanDisclaimerSection
            mobileStyles={paddingBottom}>
            {/* <JoinNowSection
                onSignup={joinNow}
                buttonText={'Enroll Now'}
            /> */}
            <DisclaimerSection
                id="first_disclaimer"
                style={{ scrollMarginTop: '10rem' }}>
                <RichText
                    text={disclaimer.description}
                    options={options}
                />
            </DisclaimerSection>
            <DisclaimerSection
                id="second_disclaimer"
                style={{ scrollMarginTop: '15rem' }}>
                <RichText
                    text={disclaimer.subDescription?.content[0]}
                    options={subDescriptionOptions}
                />
            </DisclaimerSection>
        </PlanDisclaimerSection>
    )
}

export default PlanDetailsDisclaimer;