import React, { useEffect } from 'react';
import { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { colors, commonPropStyles, desktopOnly, mobile, mobileOnly } from '../../../../styles/common';
import { getMemberTypeFromSessionStorage } from '../../../common/Helpers';
import Content from '../../../layout/Content';
import CompareOurPlansDataDesktop from './CompareOurPlansDataDesktop';
import CompareOurPlansDataMobile from './CompareOurPlansDataMobile';

const Table = styled.table`
    width: 100%;
    border: 0;
    border-collapse: collapse;
`;
const Body = styled.tbody`
`;
const Row = styled.tr`
    height: 77px;
    width: 100%;
    ${props => props.mobileOnly && css`
        ${mobileOnly}
    `}
    ${props => props.desktopOnly && css`
        ${desktopOnly}
    `}
    ${mobile`
        height: 40px;
    `}
`;
const RowElement = styled.td`
    font-size: 20px;
    line-height: 22px;
    width: 25%;
    ${commonPropStyles.mobile}
    ${commonPropStyles.desktop}
`;

const desktopStyles = {
    title: { fontSize: '16px', lineHeight: '22px' },
    mainTitle: { fontWeight: 500 }
}
const mobileStyles = {
    mainTitle: { fontSize: '16px', lineHeight: '21px', fontWeight: 400, paddingLeft: '1rem' },
};
const styles = {
    content: { marginTop: '0', padding: '0', marginBottom: '3rem', color: colors.darkBlue },
    iconStyle: { color: colors.textColor, fontSize: '16px', height: '1rem', width: '1rem', paddingRight: '10px', paddingLeft: '10px', float: 'left' },
    title: { color: colors.textColor, display: 'block', fontWeight: 'normal', overflow: 'hidden', margin: 0 },
    productRowElement: { textAlign: 'center' }
};
const CompareOurPlansData = (props) => {
    const {
        content: {
            commonData
        } = {},
        scrolledToBottom
    } = props || {};
    const memberType = getMemberTypeFromSessionStorage();
    const setBackgroundColor = (index) => {
        return {
            background: index % 2 === 0 ? colors.snowLight : colors.searhRowBackgroundColor
        };
    }
    const isBottom = (el) => {
        if (window.innerHeight <= 1130) {
            return el.getBoundingClientRect().bottom <= 350;
        }
    }
    const isNotBottom = (el) => {
        return el.getBoundingClientRect().bottom >= 335;
    }
    useEffect(() => {
        const trackScrolling = () => {
            const wrappedElement = document.getElementById('table');
            if (isBottom(wrappedElement)) {
                scrolledToBottom(true);
            }
            if (isNotBottom(wrappedElement)) {
                scrolledToBottom(false);
            }
        };
        document.addEventListener('scroll', trackScrolling);
        return () => {
            document.removeEventListener('scroll', trackScrolling);
        }

    }, [scrolledToBottom]);
    return (
        <Content
            style={styles.content}>
            <Table
                id='table'>
                <Body>
                    {
                        commonData.map((section, sectionIndex) =>
                            <Fragment
                                key={`section${sectionIndex}`}>
                                <Row>
                                    <RowElement
                                        colSpan='4'
                                        desktopStyles={desktopStyles.mainTitle}
                                        mobileStyles={mobileStyles.mainTitle}>
                                        {section.name}
                                    </RowElement>
                                </Row>
                                {
                                    section.data.map((subSection, subSectionIndex) =>
                                        <Fragment
                                            key={`subsection${subSectionIndex}`}>
                                            <Row
                                                desktopOnly='true'
                                                style={setBackgroundColor(subSectionIndex)}
                                                key={`subsectiondesktop${subSectionIndex}`}>
                                                <CompareOurPlansDataDesktop
                                                    subSection={subSection}
                                                    memberType={memberType}
                                                />
                                            </Row>
                                            <CompareOurPlansDataMobile
                                                subSection={subSection}
                                                memberType={memberType}
                                            />
                                        </Fragment>
                                    )
                                }

                            </Fragment>
                        )
                    }
                </Body>
            </Table>
        </Content>
    );
}

export default CompareOurPlansData;
