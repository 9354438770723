const validate = (values, dirty) => {
    Object.keys(values).forEach(key => {
        if (values[key]?.trim) {
            values[key] = values[key]?.trim()
        }
    });
    let errors = {};
    const zipCodeRegex =/^[0-9]+$/;
    if (!values.zipCode) {
        errors.zipCode = 'Please enter zip code.';
    } else if (!zipCodeRegex.test(values.zipCode)) {
        errors.zipCode = "Please enter a valid zip code.";
    }
    // Email validation
    const emailRegex = /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})+$/;
    if (values.isEmail) {
        if (!values.email) {
            errors.email = 'Please enter Email.';
        }
        if (values.email && !emailRegex.test(values.email)) {
            errors.email = "Please enter a valid email address.";
        }
    }
    return errors;
};

export default validate;