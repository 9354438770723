
import React from "react";
import styled from 'styled-components';
import {mobile} from './../../styles/common';
import { colors } from "./../../styles/common";
import Title from "../elements/Title";

const HeaderSection = styled.div`
    text-align: center;
    ${mobile`
    background: ${colors.darkBlue};
    padding: 0.5rem 0rem;
   `}
`;

const mobileTitleStyle = {
    fontSize: '24px',
    textAlign: 'center',
    letterSpacing: '0.01em',
    marginBottom: '1rem',
    color: `${colors.pureWhite}` 
};
  
const desktopTitleStyle = {
    fontSize: '36px',
    letterSpacing: '1px',
    marginBottom: '2.5rem',
    color: `${colors.darkBlue}`   
};

const Image = styled.img`
    width: 85%;
    background-color: ${colors.pureWhite} !important;
    background: linear-gradient(to left,${colors.darkBlue} 0%,${colors.darkRed} 100%) left bottom no-repeat;
    background-size: 100% 8px;
    padding: 0px 0px 8px 0px;
    ${mobile`
        display: none;
    `}
`;

const FaqHeader = ({content}) => {
    return (
        <HeaderSection>
          <Title mobileStyles={mobileTitleStyle} desktopStyles={desktopTitleStyle} style={{ textAlign: "center", width: "100%", marginTop: '0px', paddingTop: '1em' }}>{content.title}</Title>
          <Image src={content.headerImage.fields.file.url} />
       </HeaderSection>
    );
  };

  export default FaqHeader;