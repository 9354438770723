import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import adminPortalLogoText from '../../assets/admin-portal-logo-text.svg';
import adminPortalLogo from '../../assets/admin-portal-logo.svg';
import { colors, desktopOnly, mobile } from '../../styles/common';
import Link from '../elements/Link';
import { AdminApi } from '../../configuration/ApplicationContext';
import SearchInput from '../elements/SearchInput';
import AdminContext from '../contexts/AdminContext';
import { useHistory } from 'react-router-dom';
import cookie from 'react-cookies';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${mobile`
    background: ${colors.pureWhite};
    height: 60px;
    padding: 0px 15px;
  `}    
`;


const AdminHeaderStyle = styled.div`
    height: 81px;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 50px;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid ${colors.mediumGrey};
    ${mobile`
      background: ${colors.pureWhite};
      height: 60px;
      padding: 0px 15px;
    `}    
`;

const DesktopHeaderImage = styled.img`
    height: 60px;
`;

const HeaderLogo = styled.div`
    display: flex;
    align-items: flex-end;
    width: 40%;
    z-index: 1;
`;

const DesktopMenuItem = styled.div`
    ${desktopOnly}
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const styles = {
    desktopMenu: { color: `${colors.darkBlue}`, lineHeight: '25px', cursor: 'pointer', fontSize: '13px', marginRight: 'unset', fontWeight: 500 }
};
const AdminHeader = () => {
    const context = useContext(AdminContext);
    const [error, setError] = useState(null);
    const [isSubmitDisabled, setSubmitDisabled] = useState(false);
    const history = useHistory();
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const isDone = React.useRef(false);

    const onLogout = async () => {
        await AdminApi.get("/api/users/logout");
        setIsLoggedIn(false);
        cookie.remove('loginId');
        history.push("/admin/logout/success");
    }

    const onLogin = async () => {
        AdminApi.login();
    }

    const onSubmit = async (ref) => {
        //const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/; NOSONAR
        const emailRegex = /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})+$/;
        const digitRegex = /^\d{16}$/;
        const phoneNumberRegrex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
        ref.current.value = ref.current.value.replace(/\s/g, '');
        if (!ref.current.value) {
            setError('Please enter search keyword');
            return;
        } else if (!(emailRegex.test(ref.current.value) || digitRegex.test(ref.current.value) || (phoneNumberRegrex.test(ref.current.value) && (ref.current.value.replace(/[^\d]/g, '').length === 10 || (ref.current.value.replace(/[^\d]/g, '').length === 11))))) {
            setError('Please enter a valid email address, membership number or phone number.');
            return;
        } else {
            setError(null);
        }

        if (phoneNumberRegrex.test(ref.current.value)) {
            ref.current.value = ref.current.value.replace(/[^\d]/g, '');
            if (ref.current.value.length === 11 && ref.current.value.charAt(0) === '1') {
                ref.current.value = ref.current.value.substring(1);
            }
            else if (ref.current.value.length === 11 && ref.current.value.charAt(0) !== '1') {
                setError('Please enter a valid country code with phone number.');
                return;
            }
        }

        context.updateState({
            isSearching: true
        });
        setSubmitDisabled(true);
        const response = await AdminApi.get(`/api/users?keyword=${ref?.current?.value}`);
        context.updateState({
            searchResults: response || [],
            isSearching: false
        });
        if (response && ref) {
            setSubmitDisabled(false);
            response.length <= 0 ? history.push(`/admin/search/noresults?keyword=${ref?.current?.value}`) : history.push(`/admin/search/results?keyword=${ref?.current?.value}`);
            return;
        }
    }

    useEffect(() => {
        async function checkLoggedInUser() {
            const response = await AdminApi.get("/api/users/logged-in", true);
            if (!isDone.current) {
                setIsLoggedIn((response && response.status === 200) ? true : false);
            }
        }
        checkLoggedInUser();
        return () => {
            isDone.current = true;
        };
    }, []);


    const onEnterPressed = async (event, ref) => {
        const code = event.keyCode || event.which;
        if (code === 13) {
            await onSubmit(ref);
        }
    }

    return (
        <>
            <Container>
                <AdminHeaderStyle>
                    <HeaderLogo>
                        <DesktopHeaderImage
                            src={adminPortalLogo}
                        />
                        <DesktopHeaderImage
                            src={adminPortalLogoText}
                        />
                    </HeaderLogo>
                    <DesktopMenuItem>
                        {
                            !isLoggedIn &&
                            <Link
                                text="Login"
                                style={styles.desktopMenu}
                                to='#'
                                onClick={onLogin}
                            />
                        }
                        {
                            isLoggedIn &&
                            <Link
                                text="Logout"
                                style={styles.desktopMenu}
                                to='#'
                                onClick={onLogout}
                            />
                        }
                    </DesktopMenuItem>
                </AdminHeaderStyle>
                {
                    isLoggedIn &&
                    <SearchInput
                        placeholder="Search"
                        onSubmit={onSubmit}
                        onKeyPress={onEnterPressed}
                        error={error}
                        disabled={isSubmitDisabled}
                        keyword={params.get('keyword')}
                    />
                }
            </Container>
        </>
    );
};

export default AdminHeader;