import React from 'react';
import { Api, ContentfulService, segmentAnalytics } from '../configuration/ApplicationContext';
import CheckoutForm from './forms/CheckoutForm';
import ContentfulIds from '../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, {
    SignUpFlowExtraContent,
    SignUpFlowFormContent
} from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Card from './elements/Card';
import Title from './elements/Title';
import Summary from './summary/Summary';
import Spinner from './elements/Spinner';
import DataContext from './contexts/DataContext';
import { buildUrl, getMemberTypeValue, redirectParamSessionStorage, removeRedirectValueFromLocalStorage } from './common/Helpers';
import BaseUpgradeTierCheckout from './BaseUpgradeTierCheckout';
import { validateInputAddress } from './common/Helpers';
import withPageTitle from "./layout/withPageTitle";
import Breadcrumbs from "./common/Breadcrumbs";
import InfoText from './elements/InfoText';
import { createSummaryItems } from './summary/SummaryItems';
import Notification from './notifications/Notification';
import UtilsService from '../services/UtilsService';
import { NotificationTypes } from './notifications/Notifications';


declare var Z: any; //Zuora

const notificationStyle = { marginBottom: '1rem', paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px', marginTop: '60px' };
class UpgradeTierCheckoutPage extends BaseUpgradeTierCheckout {
    static contextType = DataContext;

    constructor(props) {
        super(props);

        this.state = {
            zuoraConfiguration: null,
            isNewPaymentMethod: false,
            isPaymentMethodInvalid: false
        };
    }

    async componentDidMount() {
        this.setBreadCrumbs();
        this.loadPlans();
        this.loadZuoraForm();
        this.loadContent()
        this.setSubscription();
        this.loadPersonalInformation();
    }

    setSubscription() {
        this.setState({ subscription: this.context.userDetails.subscription });
    }
    async loadZuoraForm() {
        const zuoraConfiguration = await Api.get('/api/orders/payment-form');
        this.setState({ zuoraConfiguration });
        if (this.state.isPaymentMethodInvalid) {
            this.props.notificationContext.addNotification(NotificationTypes.INVALID_PAYMENT_METHOD);
        }
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.MyAccountIds.UpgradeTierCheckoutPage);
        const upgradeResponse = await ContentfulService.getEntry(ContentfulIds.UpgradeBasicPlanPage);
        this.setState({
            content: response.fields,
            upgradePageContent: upgradeResponse.fields,
            originalContent: JSON.parse(JSON.stringify(response.fields))
        });
    }

    setBreadCrumbs = () => {
        const breadCrumbRoutes = [
            {
                to: `/product/protect/upgrade/${this.props.match.params.productId}`, label: 'Upgrade'
            },
            {
                to: `/upgrade/product/checkout/${this.props.match.params.productId}`, label: 'Payment'
            }
        ];
        this.setState({
            breadCrumbRoutes
        });
    }

    loadPersonalInformation() {
        const personalInfo = this.context.userDetails.personalInformation || '';
        const billingAddressSameAsHomeAddress = personalInfo.billingAddress ? personalInfo.billingAddressSameAsHomeAddress : true;
        personalInfo.billingAddress = personalInfo.billingAddress ? personalInfo.billingAddress : personalInfo.homeAddress;
        this.setState({
            personalInfo,
            billingAddressSameAsHomeAddress
        });
    }

    submit = (data) => {
        const ratePlanId = this.context.product.id;
        const { promoCodeRatePlanId, promoCode } = this.context.promoCodeData || {};
        const isNewPaymentMethod = this.state.isNewPaymentMethod;
        const { id, createdOn, lastModified, ...billingAddress } = this.state.personalInfo?.billingAddress || {};
        if (this.state.isNewPaymentMethod) {
            if (Object.keys(data.errors).length > 0 || !data.values.billingAddress) {
                this.setState({
                    checkoutError: data.errors.billingAddress,
                    isSubmitDisabled: false
                });
                return;
            }

            this.setState({
                checkoutError: "",
                isPaymentMethodInvalid: false
            });

            window.orderSuccess = async (refId) => {
                this.setState({
                    isSubmitDisabled: true
                });
                const paymentMethodId = refId;
                this.upgradeOrder(ratePlanId, paymentMethodId, isNewPaymentMethod, billingAddress, promoCodeRatePlanId, promoCode);
            };

            window.orderFailure = (failureMessage) => {
                this.setState({
                    checkoutError: failureMessage,
                    zuoraConfiguration: null,
                    isSubmitDisabled: false
                }, () => {
                    this.loadZuoraForm();
                });
            };
            Z.submit();
        } else {
            this.setState({ isSubmitDisabled: true });
            const paymentMethodId = this.state.subscription.defaultPaymentMethod.id;
            this.upgradeOrder(ratePlanId, paymentMethodId, isNewPaymentMethod, billingAddress, promoCodeRatePlanId, promoCode);
        }
    };

    upgradeOrder = async (ratePlanId, paymentMethodId, isNewPaymentMethod, billingAddress, promoCodeRatePlanId, promoCode) => {
        const { userDetails } = this.context || {};
        const nonAaaMember = this.isNonMemberRateApplicable(userDetails);
        const response = await Api.post('/api/subscriptions/upgrade-coverage', {
            ratePlanId,
            paymentMethodId,
            isNewPaymentMethod,
            billingAddress,
            promoCodeRatePlanId,
            promoCode,
            nonAaaMember
        });
        if (!response) {
            this.setState({
                isSubmitDisabled: false
            });
            return;
        }
        if (response.validationErrors) {
            const declinedPayment = response.validationErrors.find(errors => errors.property === 'paymentDeclined');
            if (declinedPayment) {
                this.setState({
                    isSubmitDisabled: false,
                    zuoraConfiguration: null,
                    isPaymentMethodInvalid: true
                }, async () => {
                    this.loadZuoraForm();
                });
            } else {
                await this.context.refreshContext();
                this.props.history.push('/my-account');
            }
        } else {
            segmentAnalytics.track('My Account| Protect Member Upgrades & Pays Succesfully', {
                memberId: this.context.userDetails.userId,
                tierSelected: this.context.product.name,
                paymentOption: this.context.product.paymentMode,
                MemberType: getMemberTypeValue(this.context.userDetails.creationSource)
            });
            sessionStorage.removeItem(redirectParamSessionStorage);
            removeRedirectValueFromLocalStorage();
            this.props.history.push('/paid/product/upgrade/success');
        }
    }


    onSelectAddress = (address) => {
        const personalInfo = this.state.personalInfo;
        personalInfo.billingAddress = address
        if (!validateInputAddress(address)) {
            this.setState({
                personalInfo,
                checkoutError: "Please enter valid billing address"
            });
        } else {
            this.setState({
                personalInfo,
                checkoutError: ''
            });
        }
    }

    setNewPaymentMethod = (value) => {
        if (value) {
            this.loadZuoraForm();
            segmentAnalytics.track('My Account| Upgrading Protect Member Changes Payment Info', {
                memberId: this.context.userDetails.userId,
                tierSelected: this.context.product.name,
                paymentOption: this.context.product.paymentMode
            });
        }
        this.setState({ isNewPaymentMethod: value });
    }
    onDeclineUpgrade = () => {
        this.props.notificationContext.clearNotifications();
    }

    generateZuoraPlansURL = () => {
        let params = {};
        const { userDetails } = this.context || {};
        params.isNonAaaMember = this.isNonMemberRateApplicable(userDetails);
        return buildUrl('/api/products/zuora-rate-plans', params);
    }


    render() {
        const {
            zuoraConfiguration,
            content,
            checkoutError,
            personalInfo,
            product,
            subscription,
            isNewPaymentMethod,
            billingAddressSameAsHomeAddress,
            breadCrumbRoutes,
            isSubmitDisabled
        } = this.state;
        if (!zuoraConfiguration || !content || !product || !subscription || isSubmitDisabled) {
            return <Spinner />;
        }
        const { notifications } = this.props.notificationContext;
        const { userDetails } = this.context || {};
        const isNonAaaMember = this.isNonMemberRateApplicable(userDetails);
        return (
            <>
                <InfoText
                    style={notificationStyle}
                    mobileOnly='true'>
                    {
                        notifications.map(notification => {
                            return <Notification
                                key={notification.notificationType}
                                {...notification}
                                {...this.props} />
                        })
                    }
                </InfoText>
                <Breadcrumbs
                    breadCrumbRoutes={breadCrumbRoutes}
                />
                <Content>
                    <InfoText
                        style={notificationStyle}
                        desktopOnly='true'>
                        {
                            notifications.map(notification => {
                                return <Notification
                                    key={notification.notificationType}
                                    {...notification}
                                    {...this.props} />
                            }
                            )
                        }
                    </InfoText>
                    <ResponsiveSignupFlowLayout>
                        <SignUpFlowFormContent>
                            <Title
                                style={{ marginTop: '3rem' }}>
                                {content.title}
                            </Title>
                            <CheckoutForm
                                zuoraConfiguration={zuoraConfiguration}
                                content={content}
                                onSubmit={this.submit}
                                checkoutError={checkoutError}
                                personalInfo={personalInfo}
                                onSelectAddress={this.onSelectAddress}
                                selectedProduct={product}
                                isCardDetailsPresent={true}
                                hideTermsAndConditions={true}
                                isUpgradeCheckoutPage={true}
                                upgradePageContent={this.state.upgradePageContent}
                                subscription={subscription}
                                isNewPaymentMethod={isNewPaymentMethod}
                                setNewPaymentMethod={this.setNewPaymentMethod}
                                onApplyPromoCode={this.onApplyPromoCode}
                                promoCodeOptions={
                                    {
                                        validateURL: `/api/promo-codes/validate?isNonAaaMember=${isNonAaaMember}`,
                                        zuoraRatePlanURL: this.generateZuoraPlansURL(),
                                        mapProductsFunc: UtilsService.mapProductsToArray
                                    }
                                }
                                isUpgradeTierCheckoutPage={true}
                                onDeclineUpgrade={this.onDeclineUpgrade}
                                billingAddressSameAsHomeAddress={billingAddressSameAsHomeAddress}
                                isSubmitDisabled={isSubmitDisabled}
                            />
                        </SignUpFlowFormContent>
                        <SignUpFlowExtraContent>
                            <Card>
                                <Summary
                                    product={product}
                                    title={`${product.name} Protection`}
                                    items={createSummaryItems(product, this.context, { showRefundAmount: true, showBottomSection: true })}
                                    flags={{ showRefundAmount: true, showBottomSection: true }}
                                 />
                            </Card>
                        </SignUpFlowExtraContent>
                    </ResponsiveSignupFlowLayout>
                </Content>
            </>
        );
    }
}

export default withPageTitle(UpgradeTierCheckoutPage, "Upgrade");
