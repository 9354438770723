import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles/common';
import Section from '../../elements/Section';

const MiddleSection = styled.div`
    justify-content: center;
    padding-top: 2.5rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
`;
const Title = styled.h1`
    font-size: 36px;
    margin: 0;
    font-weight: 500;
    letter-spacing: 1px;
    color: ${colors.pureBlack};
`;
const Button = styled.input`
    background: ${colors.darkBlue};
    border: 2px solid ${colors.darkBlue};
    padding-top: 10px;
    padding-bottom: 10px;
    width: 273px;
    border-radius: 10px;
    font-size: 22px;
    font-weight: 500;
    color: ${colors.pureWhite};
    cursor: pointer;
    margin: 0 auto;
    max-width: 100%;
    display: block;
`;
const style = {
    annualAmount: { color: colors.mediumBlue, cursor: 'pointer', fontWeight: 500, fontSize: '35px' },
    textDecoration: { textDecoration: 'line-through red', fontStyle: 'bold', paddingTop: '1rem', fontWeight: '500' },
    productDescription: { fontSize: '16px', lineHeight: '26px', paddingTop: '1rem', paddingLeft: '5rem', paddingRight: '5rem', fontFamily: 'Smart', fontWeight: 'normal' }
};

const BundleProductChartMiddleSection = ({ ...props }) => {
    const { title, commonDataDetails, annualAmount, trackEventsAndRedirect } = props || {};

    return (
        <MiddleSection>
            <Title>{title}</Title>
            {
                <Section
                    style={style.textDecoration}
                    text={`${commonDataDetails.oldAnnualAmount}`}
                />
            }
            {
                <Section
                    style={style.annualAmount}
                    text={`${annualAmount}`}
                />
            }
            <Section
                desktopStyles={style.productDescription}
                text={commonDataDetails.productDescription}
            />

            <Button
                type='button'
                value={`Choose ${title}`}
                onClick={() => { trackEventsAndRedirect() }}
            />
        </MiddleSection>
    );
}
export default BundleProductChartMiddleSection;