import React, { Fragment } from 'react';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { colors } from '../../../../styles/common';
import Section from '../../../elements/Section';
import ToolTip from '../../../tooltip/ToolTip';
import RichText from '../../../elements/RichText';
import { BASIC_LOWER_CASE } from '../../../common/Constants';
import HorizontalRule from '../../../elements/HorizontalRule';
import ProductChartTabbedViewPricingSection from './ProductChartTabbedViewPricingSection';
import Link from '../../../elements/Link';

const ProductFeature = styled.div`
    display: flex;
`;

const ProductFeaturesSection = styled.div`
    text-align: left;
    padding: 30px;
`;

const DisclaimerSection = styled.div`
    font-size: 14px;
    font-style: italic;
    padding: 0 25px;
`;

const styles = {
    tooltip: { fontSize: '14px' },
    icon: { width: '14px', paddingTop: '13px' },
    productFeature: { fontSize: '15px', fontWeight: '500', margin: '0px 10px', paddingBottom: '5px', color: colors.textColor },
    horizontalRule: { width: '80%', backgroundColor: colors.pureBlack },
    link: { fontSize: '16px', lineHeight: '21px', paddingBottom: '1.5rem',textAlign: 'center', textDecoration: 'underline', color: `${colors.darkBlue}` },
};

const ProductChartTabbedViewPricingAndFeatureSection = ({ ...props }) => {
    const {
        section,
        selectedProduct,
        disclaimer,
        disableCta,
        linkText,
        memberType,
        onProductSelect
    } = props || {};
    return (
        <Fragment>
            <ProductChartTabbedViewPricingSection
                {...section.fields}
                onProductSelect={onProductSelect}
                selectedProduct={selectedProduct}
                memberType={memberType}
                disableCta={disableCta}
            />
            <HorizontalRule
                style={styles.horizontalRule}
            />
            section.fields.productFeatures.remove
            <ProductFeaturesSection>
                {section.fields.productFeatures.map((feature, index) =>
                  feature.name !='30GB Secure Cloud Storage' &&  <ProductFeature
                        key={index}>
                        <FontAwesomeIcon
                            data-tip
                            data-for={`feature ${index}`}
                            style={styles.icon}
                            icon={faQuestionCircle}
                        />
                        <ToolTip
                            fontStyle={styles.tooltip}
                            textcolor={colors.pureWhite}
                            backgroundcolor={colors.textColor}
                            id={`feature ${index}`}
                            content={feature.description}
                        />
                        <Section
                            style={styles.productFeature}
                            dangerouslySetInnerHTML={{ __html: feature.name }}
                        />
                    </ProductFeature>
                )}
            </ProductFeaturesSection>
            <Link
                to='/plan-details'
                style={styles.link}
                text={linkText}
            />
            {
                selectedProduct.toLowerCase() !== BASIC_LOWER_CASE
                &&
                <DisclaimerSection>
                    <RichText
                        text={disclaimer}
                    />
                </DisclaimerSection>
            }
        </Fragment>
    );
}
export default ProductChartTabbedViewPricingAndFeatureSection;