import React, { useEffect, useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import desktopLogo from '../../assets/desktop-logo.png';
import myAccountLogo from '../../assets/my-account-logo.png';
import mobileLogo from '../../assets/mobile-logo.png';
import { colors, desktopOnly, mobile, mobileOnly } from '../../styles/common';
import Button from '../elements/Button';
import Link from '../elements/Link';
import { useHistory } from 'react-router-dom';
import { Api, segmentAnalytics } from '../../configuration/ApplicationContext';
import MobileMenuItem from '../landing-page/MobileMenuItem';
import DataContext from '../contexts/DataContext';
import InfoText from '../elements/InfoText';
import AskZipCodePage from './../AskZipCodePage';
import ZipCodeService from "../../services/ZipCodeService";
import AnchorLink from "../elements/AnchorLink";
import Spinner from '../elements/Spinner';
import { COMPARE_PLANS } from '../common/Constants'
import PromoCodeNotifications from '../notifications/PromoCodeNotifications';
import { getPromoCodeNameFromRedirectParams } from '../common/Helpers';

const HeaderWrapper = styled.div`
    height: 128px;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0px 60px;
    box-sizing: border-box;
    background: ${colors.pureWhite};
    ${mobile`
      position: fixed;
      border-bottom: 1px solid ${colors.mediumGrey};
      top: ${props => props.topmargin};
      width:100%;
      background: ${colors.pureWhite};
      height: ${props => props.mobileheight};
      padding: 0px;
      z-index: 90;
    `}    
`;

const GradientStyle = styled.div`
    ${desktopOnly}
    width: auto;
    height: 10px;
    left: 0px;
    top: 0px;
    background: linear-gradient(45deg, red, blue);
      
`;

const HeaderContent = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    ${props => props.subscription && css`
        align-items: center;
    `}
    ${mobile`
        padding: 0 15px;
    `}
`;

const DesktopHeaderImage = styled.img`
    ${desktopOnly}
    height: 162px;
`;

const MyAccountHeaderImage = styled.img`
    ${desktopOnly}
    height: 132px;
`;

const MobileHeaderImage = styled.img`
    ${mobileOnly}
    height: 38px;
    padding: 15px 0 0 0;
`;

const DesktopMenuItem = styled.div`
    ${desktopOnly}
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const desktopMenuStyle = {
    color: `${colors.darkBlue}`,
    fontSize: '19px',
    lineHeight: '25px',
    cursor: 'pointer',
    fontWeight: 'normal',
    marginRight: '3rem'
};

const HeaderLogo = styled.a`
    width: 40%;
    display: inline-block;
    z-index: 25;
`;

const nameStyle = {
    fontSize: '14px',
    color: colors.titleText,
    textAlign: 'center'
}

const linkDesktopStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: '0.75rem'
}

const infoTextDesktopStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '0.5rem'
}

const desktopMenuButtonStyle = {
    fontSize: '19px',
    background: `${colors.darkBlue}`,
    width: 'auto',
    padding: '8px 40px',
    color: `${colors.pureWhite}`
}

const defaultRedirectionLinks = {
    outOfAreaPageLink: '/out-of-area/signup',
    headerCtaLink: '/product/',
    headerLogoLink: '/'
}

const headerDefaultStaticItems = [{
    displayName: COMPARE_PLANS,
    linkTo: '/plan-details'
}, {
    displayName: 'FAQs',
    linkTo: '/faqs'
}]

const defaultMobileHeaderItems = [{
    displayName: COMPARE_PLANS,
    linkTo: '/plan-details'
// }, {
//     displayName: 'Sign In',
//     linkTo: '#'
// }, {
//     displayName: 'Contact Us',
//     linkTo: '/contact-us'
}, {
    displayName: 'FAQs',
    linkTo: '/faqs'
}]

const defaultSegmentEvents = {
    signInClickEvent: 'Header | Sign In Click',
    joinNowClickEvent: 'Header | Join CTA Clicked',
    planDetailsClickEvent: 'Header | Plan Details Click',
    zipGatePopupEvent: 'Zipgate | Pops Up',
    zipEnteredEvent: 'Zipgate | ZIP entered',
    zipRejectedEvent: 'Zipgate | ZIP rejected',
    zipClearedEvent: 'Zipgate | ZIP cleared'
};

const Header = ({
    isSignupFlow,
    showTopGradient,
    checkZipCodeEligibility = ZipCodeService.checkIdcEligibility,
    headerStaticItems = headerDefaultStaticItems,
    mobileHeaderItems = defaultMobileHeaderItems,
    redirectionLinks = defaultRedirectionLinks,
    segmentEvents = defaultSegmentEvents,
    isAaaJoinUpsellFlow = false,
    showPromoCodeNotification = false,
    hideBorderBottom = false,
    showMyAccountOnHeader = false
  
}) => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const verificationCode = params.get('verification-code');
    const context = useContext(DataContext);
    const { membershipStatus } = context.userDetails.membership || {};
    const history = useHistory();
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [name, setName] = useState('');
    const [subscription, setSubscription] = useState(null);
    const { graceMembershipNumber } = context.userDetails.graceMembershipNumber || {};
    const [showModal, setShowModal] = useState(false);
    const [userLastActionSignUp, setUserLastActionSignUp] = useState(true);
    const promoCodeName = getPromoCodeNameFromRedirectParams();
    const isDone = React.useRef(false);
    useEffect(() => {
        async function redirectToLoginIfVerificationCodePresent() {
            if (verificationCode) {
                return await Api.login('low-confidence-login', {
                    verificationCode,
                });
            }
        }
        redirectToLoginIfVerificationCodePresent();
    }, [verificationCode]);

    const onLogin = async () => {
        setUserLastActionSignUp(false);
        segmentAnalytics.track(segmentEvents.signInClickEvent);
        return Api.login();
    };

    const onSignup = () => {
        setUserLastActionSignUp(true);
        localStorage.setItem('joinNowClicked', true);
        if (checkIfOpenZipGateModal()) {
            displayModal();
        } else {
            segmentAnalytics.track(segmentEvents.joinNowClickEvent);
            history.push(redirectionLinks.headerCtaLink);
        } 
    };

    const onPlanDetailsClick = (planDetailsLink) => {
        segmentAnalytics.track(segmentEvents.planDetailsClickEvent);
        history.push(planDetailsLink);
    }

    const onLogout = async () => {
        localStorage.clear();
        sessionStorage.clear();
        await Api.get("/api/users/logout");
        setIsLoggedIn(false);
        history.push("/");
    }

    const displayModal = () => {
        setShowModal(true);
        segmentAnalytics.track(segmentEvents.zipGatePopupEvent);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const addZipCodeData = (zip) => {
        segmentAnalytics.track(segmentEvents.zipEnteredEvent, { zipcode: zip });
        checkZipCodeEligibility().then((response) => {
            if (!response.serviceable) {
                segmentAnalytics.track(segmentEvents.zipRejectedEvent, { zipcode: zip });
                history.push(redirectionLinks.outOfAreaPageLink, { response: response });
            } else {
                segmentAnalytics.track(segmentEvents.zipClearedEvent, { zipcode: zip });
                userLastActionSignUp ? onSignup() : onLogin();
            }
        });
    }

    const checkIfOpenZipGateModal = () => {
        return !ZipCodeService.getZipCookie();
    }

    useEffect(() => {
        async function checkLoggedInUser() {
            const response = await Api.get("/api/users/user/logged-in", true);
            if (response?.status === 403) {
                sessionStorage.removeItem("isAlreadyLoggedIn");
            }
            if (!Object.keys(context.userDetails).length && response && response.status === 200) {
                setIsLoggedIn(true);
                sessionStorage.setItem("isAlreadyLoggedIn", true);
            }
            if (Object.keys(context.userDetails).length && response) {
                isDone.current = false;
            }
            if (!isDone.current) {
                setIsLoggedIn((response && response.status === 200) ? true : false);
                const { personalInformation = null, subscription = null } = context.userDetails || {};
                if (personalInformation) {
                    setSubscription(subscription);
                }
            }
        }
        checkLoggedInUser();
        return () => {
            isDone.current = true;
        };
    }, [context.userDetails]);

    if (verificationCode) {
        return <Spinner />
    }
    if (isLoggedIn === null) {
        return null;
    }
    return (       
        <>
            {
                showTopGradient  === true  && 
                <GradientStyle
                     desktoponly={true}
                />
            }
            {
                showPromoCodeNotification === true && promoCodeName && <PromoCodeNotifications
                    desktoponly={true}
                />
            }
            <HeaderWrapper
                topmargin={0}
                mobileheight={showPromoCodeNotification === true && promoCodeName ? '165px' : '60px'}
                isSignupFlow={isSignupFlow}
                isAaaJoinUpsellFlow={isAaaJoinUpsellFlow}
                subscription={subscription}
                hideborderbottom={hideBorderBottom}
                membershipStatus={membershipStatus}>
                {
                    showPromoCodeNotification === true && promoCodeName && <PromoCodeNotifications
                        mobileonly={true}
                    />
                }
                <HeaderContent
                    subscription={subscription}>
                    <HeaderLogo
                        href={
                            subscription
                                ? process.env.REACT_APP_EXPERIAN_SSO_URL
                                : redirectionLinks.headerLogoLink}>
                        {
                            (isSignupFlow || isAaaJoinUpsellFlow) &&
                            <>
                                <DesktopHeaderImage
                                    src={desktopLogo}
                                />
                                <MobileHeaderImage
                                    src={mobileLogo}
                                />
                            </>
                        }
                        {
                            (!isSignupFlow && !isAaaJoinUpsellFlow) &&
                            <>
                                {
                                    !isLoggedIn &&
                                    <DesktopHeaderImage
                                        src={desktopLogo}
                                    />
                                }
                                {
                                    isLoggedIn &&
                                    <MyAccountHeaderImage
                                        src={myAccountLogo}
                                    />
                                }
                                <MobileHeaderImage
                                    src={mobileLogo}

                                />
                            </>
                        }
                    </HeaderLogo>
                    {
                        !isLoggedIn &&
                        <>
                            {
                                !isSignupFlow &&
                                <DesktopMenuItem>
                                    {
                                        headerStaticItems.map(item => <Link
                                            key={item.displayName}
                                            text={item.displayName}
                                            style={desktopMenuStyle}
                                            to={
                                                item.displayName === COMPARE_PLANS
                                                    ? '#'
                                                    : `${item.linkTo}`
                                            }
                                            onClick={
                                                item.displayName === COMPARE_PLANS
                                                    ? () => onPlanDetailsClick(item.linkTo)
                                                    : () => { }} />)
                                    }
                                    <Button
                                     style={desktopMenuButtonStyle}
                                        text="Sign In"
                                        onClick={onLogin}                                       
                                        to='#'
                                    />
                                    {/* <Button
                                        style={desktopMenuButtonStyle}
                                        text="Enroll Now"
                                        onClick={onSignup}
                                    /> */}
                                </DesktopMenuItem>
                            }
                            <MobileMenuItem
                                // onSignup={onSignup}
                                onLogin={onLogin}
                                onPlanDetails={onPlanDetailsClick}
                                isLoggedIn={false}
                                subscription={subscription}
                                isSignupFlow={isSignupFlow}
                                promoCodeName={promoCodeName}
                                mobileHeaderItems={mobileHeaderItems}
                            />
                        </>
                    }
                    {
                        !subscription && isLoggedIn &&
                        <>
                            {
                                !isSignupFlow &&
                                <DesktopMenuItem>
                                    {
                                        headerStaticItems.map(item => <Link
                                            key={item.displayName}
                                            text={item.displayName}
                                            style={desktopMenuStyle}
                                            to={
                                                item.displayName === COMPARE_PLANS
                                                    ? '#'
                                                    : `${item.linkTo}`
                                            } onClick={
                                                item.displayName === COMPARE_PLANS
                                                    ? () => onPlanDetailsClick(item.linkTo)
                                                    : () => { }} />)
                                    }
                                    <Link
                                        text="My Dashboard"
                                        style={desktopMenuStyle}
                                        to='/my-account'
                                    />
                                    <Link
                                        text="Logout"
                                        onClick={onLogout}
                                        style={desktopMenuStyle}
                                        to='#'
                                    />
                                </DesktopMenuItem>
                            }
                            <MobileMenuItem
                                // onSignup={onSignup}
                                onLogin={onLogin}
                                onPlanDetails={onPlanDetailsClick}
                                onLogout={onLogout}
                                isLoggedIn={true}
                                subscription={subscription}
                                isSignupFlow={isSignupFlow}
                                mobileHeaderItems={mobileHeaderItems}
                            />
                        </>
                    }
                    {
                        subscription && isLoggedIn && (!isSignupFlow && !isAaaJoinUpsellFlow) &&
                        <>
                            <DesktopMenuItem
                                style={linkDesktopStyle}>
                                <InfoText
                                    desktopOnly='true'>
                                    <InfoText
                                        style={infoTextDesktopStyle}>
                                        {
                                            showMyAccountOnHeader ?
                                                <Link
                                                    text="My Account"
                                                    style={{ ...desktopMenuStyle, fontSize: '13px', marginRight: '2rem', fontWeight: 500 }}
                                                    to='/my-account'
                                                />
                                                :
                                                <AnchorLink
                                                    text="MY ACCOUNT"
                                                    href={process.env.REACT_APP_EXPERIAN_SSO_URL}
                                                    style={{ ...desktopMenuStyle, lineHeight: '15px', color:'#000000', fontSize: '12px', marginRight: '2rem', fontWeight: 700 }}
                                                />
                                        }
                                        <Link
                                            text="LOG OUT"
                                            to='#'
                                            onClick={onLogout}
                                            style={{ ...desktopMenuStyle, lineHeight: '15px',color:' #5F6B77', fontSize: '12px', marginRight: 'unset', marginLeft: 'auto', fontWeight: 700 }}
                                        />
                                    </InfoText>
                                    <InfoText
                                        style={nameStyle}>
                                        {name}
                                    </InfoText>
                                </InfoText>
                            </DesktopMenuItem>
                            <MobileMenuItem
                                // onSignup={onSignup}
                                onLogin={onLogin}
                                isLoggedIn={true}
                                onLogout={onLogout}
                                name={name}
                                subscription={subscription}
                                isSignupFlow={isSignupFlow}
                                showMyAccountOnHeader={showMyAccountOnHeader}
                            />
                        </>
                    }
                    <AskZipCodePage
                        id="zip_code_modal"
                        addZipCodeData={(zip) => { addZipCodeData(zip); }}
                        showModal={showModal}
                        closeModal={closeModal}
                        openZipForm={displayModal}
                    />
                </HeaderContent>
            </HeaderWrapper>
        </>
    );
};

export default Header;