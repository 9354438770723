import React, { useContext, useEffect, useState } from 'react';
import Title from '../elements/Title';
import LineItem from '../elements/LineItem';
import Section from '../elements/Section';
import HorizontalRule from '../elements/HorizontalRule';
import RichText from '../elements/RichText';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import { BLOCKS } from '@contentful/rich-text-types';
import DataContext from '../contexts/DataContext';
import moment from 'moment';
import StringPlaceholderService from '../../services/StringPlaceholderService';
import { removeLeadingZerosForSingleDigitDate } from '../common/Helpers';
import ContentfulIds from '../../configuration/ContentfulIds';
import { ContentfulService } from '../../configuration/ApplicationContext';
import { CANCELLED, MONTHLY } from '../common/Constants';

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphText desktopFontSize='17px' mobileFontSize='14px' text={children} />,
    },
};

const Summary = ({title, product, content, items = [], flags = {}}) => {
    const context = useContext(DataContext);
    const [freeTrialDisclaimerContent, setFreeTrialDisclaimerContent] = useState();
    const promoCodeData = context.promoCodeData;
    const { actualPrice, actualPriceWithoutPromoCode, totalRefundAmount } = product || {};
    const subscription = context?.userDetails?.subscription || {};
    const promoCodePrice = flags.showPaidInvoiceAmount ? subscription?.paidInvoiceAmount?.promoCode : product?.promoCode;
    const nonMemberPromoApplied = promoCodeData?.promoCodeCustomDescription.includes('Gig');
    const freeTrialAmount = Math.abs(subscription?.paidInvoiceAmount?.freeTrialAmount) || Math.abs(product?.freeTrialAmount);
    const freeTrialEndDate = subscription?.freeTrialEndDate || product?.freeTrialEndDate;
    const membershipStatus = context?.userDetails?.membership?.membershipStatus;
    const isNonAaaMember = (!context.isLoggedIn || membershipStatus === CANCELLED || context?.userDetails?.invalidMembershipNumber);
    let showTaxLineItem = actualPrice?.tax;
    if (Object.keys(subscription).length === 0 && freeTrialAmount > 0) {
        showTaxLineItem = actualPriceWithoutPromoCode?.tax;
    }
    const properties = {
        actualAmount: freeTrialAmount,
        paymentMode: product?.paymentMode === MONTHLY ? 'month' : 'year',
        paymentStartDate: removeLeadingZerosForSingleDigitDate(moment(freeTrialEndDate)?.format('MM/DD/YYYY'))
    }
    let {topSection, middleSection, grandTotal, bottomSection, refundAmount, totalPaidAmount, paymentMode, promoCode } = items;
    if (!showTaxLineItem) {
        middleSection = middleSection.filter(section => section.key !== 'Taxes');
    }

    useEffect(() => {
        async function loadFreeTrialDisclaimerContent() {
            const response = await ContentfulService.getEntry(ContentfulIds.FreeTrialDisclaimer);
            response.fields.description.content.forEach(ct => {
                ct.content.forEach(pr => {
                    if (pr.value) {
                        pr.value = StringPlaceholderService.replace(pr.value, properties)
                    }
                })
            });
            setFreeTrialDisclaimerContent(response.fields.description);
        }
        loadFreeTrialDisclaimerContent();
    }, [product]); 

    content?.freeTrialCallToAction && content.freeTrialCallToAction.content.forEach(ct => {
        ct.content.forEach(pr => {
            if (pr.value) {
                pr.value = StringPlaceholderService.replace(pr.value, properties)
            }
        })
    });

    return (
        <>
            <Title desktopOnly='true' desktopStyles={{'font-size': '27px'}} style={{marginBottom: '1.5rem'}}>{title}</Title>
            <Section text={topSection.map(({key, value}) => <LineItem key={key} itemStyle={{fontWeight: 'bold'}} itemKey={key} itemValue={value}></LineItem>)}></Section>
            <Section text={middleSection.map(({key, style, value}) => <LineItem key={key} style={style} itemKey={key} itemValue={value}></LineItem>)}></Section>
            {promoCodeData && promoCodePrice && <Section desktopStyles={{paddingTop: 'unset', fontStyle: 'italic'}} text={promoCode.map(({key, style, value}) => <LineItem key={key} style={style} itemKey={nonMemberPromoApplied ? promoCodeData.promoCodeCustomDescription : key} itemValue={value}></LineItem>)}></Section>}
            {totalRefundAmount && flags.showRefundAmount && <Section text={refundAmount.map(({key, style, value}) => <LineItem key={key} style={style} itemKey={key} itemValue={value}></LineItem>)}></Section>}
            <HorizontalRule></HorizontalRule>
            {!flags.showCreditFromPreviousSubscription && grandTotal && <Section text={grandTotal.map(({key, style, value}) => <LineItem key={key} style={style} itemKey={key} itemValue={value}></LineItem>)}></Section>}
            {flags.showCreditFromPreviousSubscription && totalPaidAmount && <Section text={totalPaidAmount.map(({key, style, value}) => <LineItem key={key} style={style} itemKey={key} itemValue={value}></LineItem>)}></Section>}
            {flags.isSubscriptionComplete &&
                <>
                    {flags.showPaidInvoiceAmount && 
                        <>
                            {paymentMode && <Section text={paymentMode.map(({key, style, value}) => <LineItem key={key} itemKey={key} itemValue={value}></LineItem>)}></Section>}
                            {bottomSection && <Section text={bottomSection.map(({key, style, value}) => <LineItem key={key} itemKey={key} itemValue={value}></LineItem>)}></Section>}
                             {!isNonAaaMember && <Section text={<RichText text={content.callToAction} options={options}></RichText>}></Section>}
                        </>
                    }
                    {!flags.showPaidInvoiceAmount && <Section text={<RichText text={content.freeTrialCallToAction} options={options}></RichText>}></Section>}
                </>
            }
            {flags.showBottomSection && bottomSection && <Section text={bottomSection.map(({key, style, value}) => <LineItem key={key} itemKey={key} itemValue={value}></LineItem>)}></Section>}
            {flags.showFreeTrialDisclosure && freeTrialDisclaimerContent && <RichText text={freeTrialDisclaimerContent} />}
        </>
    );
};

export default Summary;