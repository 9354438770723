import React, { useContext } from 'react';
import CardWithTitle from '../elements/CardWithTitle';
import CardLineItem from '../elements/CardLineItem';
import { capitalizeFirstLetter } from '../common/Helpers';
import { BASIC, COMPLETE, PROTECT, CANCELLED, FREE } from '../common/Constants';
import moment from 'moment';
import Link from '../elements/Link';
import RichText from '../elements/RichText';
import { BLOCKS } from '@contentful/rich-text-types';
import UnorderedListText from '../elements/UnorderedListText';
import { colors } from '../../styles/common';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import { removeLeadingZerosForSingleDigitDate } from '../common/Helpers';
import DataContext from '../contexts/DataContext';
import { Api } from '../../configuration/ApplicationContext';
import AnchorLink from '../elements/AnchorLink';

const boldStyle = { fontWeight: 'bold' };

const linkStyle = {
    marginBottom: '1rem',
    fontWeight: 'bold',
    fontSize: '16px',
    color: colors.blue
}

const boldWithRed = { fontWeight: 'bold', color: colors.darkRed }

const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;

const UnorderedListStyle = ({ children, ...props }) => <UnorderedListText {...props} text={children} />;

const options = {
    renderNode: {
        [BLOCKS.UL_LIST]: (node, children) => <UnorderedListStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</UnorderedListStyle>,
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</ParagraphStyle>,
    },
};

const CoverageDetails = ({ content }) => {
    const context = useContext(DataContext);
    const subscription = context?.userDetails?.subscription;
    const termEndDate = subscription.termEndDate ? moment(subscription.termEndDate)?.format('MM/DD/YYYY') : null;
    const membershipExpiryDate = context?.userDetails?.membership?.membershipExpiryDate ? context.userDetails.membership.membershipExpiryDate : "N/A";
    const formattedMembershipDate = moment(membershipExpiryDate)?.format('MM/DD/YYYY') || '';
    const productType = subscription.productType === 'FREE' ? 'Basic' : capitalizeFirstLetter(subscription.productType);
    const membershipStatus = context?.userDetails?.membership?.membershipStatus;
    const userCreationSource = context?.userDetails?.creationSource;
    const invalidMembershipNumber = context?.userDetails?.invalidMembershipNumber;

    const handleRedirectionForInvalidMembership = async () => {
        await Api.put('/api/users/attach-membership-number');
        await Api.login();
    }

    return (
        <CardWithTitle
            marginBottom='1rem'
            title='Coverage Details'>
            <CardLineItem
                itemStyle={boldStyle}
                itemKey='Coverage Level'
                itemValue={productType}
            />
            {
                termEndDate &&
                <CardLineItem
                    itemStyle={boldStyle}
                    itemKey='Coverage active through'
                    itemValue={removeLeadingZerosForSingleDigitDate(termEndDate)}
                />
            }
            {
                membershipExpiryDate !== 'N/A' && membershipStatus !== CANCELLED &&
                <CardLineItem
                    itemStyle={boldStyle}
                    itemKey='AAA Membership active through'
                    itemValue={removeLeadingZerosForSingleDigitDate(formattedMembershipDate)}
                />
            }
            {
                membershipStatus === CANCELLED &&
                (subscription.productType === 'FREE'
                    || (userCreationSource !== 'NON_AAA' && subscription.productType !== FREE.toUpperCase())) &&
                <CardLineItem
                    style={boldWithRed}
                    itemStyle={boldWithRed}
                    itemKey={ `AAA Membership Expired ${removeLeadingZerosForSingleDigitDate(formattedMembershipDate)}` }
                />
            }
            {
                productType === PROTECT &&
                <>
                    {
                        membershipStatus === CANCELLED &&
                        <AnchorLink
                            href='https://mwg.aaa.com/my-account'
                            target="_self"
                            text='Renew AAA Membership'
                            style={linkStyle}
                        />
                    }
                    {/* {
                        membershipExpiryDate === 'N/A' && !invalidMembershipNumber &&
                        <AnchorLink
                            href='https://mwg.aaa.com/membership/join-aaa'
                            target="_blank"
                            text={content.joinAaaLinkText}
                            style={linkStyle}
                        />
                    } */}
                    {
                        membershipExpiryDate === 'N/A' && invalidMembershipNumber &&
                        <Link
                            to='#'
                            onClick={handleRedirectionForInvalidMembership}
                            text='Enter AAA Membership'
                            style={linkStyle}
                        />
                    }
                </>
            }
            {
                productType === COMPLETE &&
                <>
                    {
                        membershipStatus === CANCELLED &&
                        <AnchorLink
                            href='https://mwg.aaa.com/my-account'
                            target="_self"
                            text='Renew AAA Membership'
                            style={linkStyle}
                        />
                    }
                    {/* {
                        membershipExpiryDate === 'N/A' && !invalidMembershipNumber &&
                        <AnchorLink
                            href='https://mwg.aaa.com/membership/join-aaa'
                            target="_blank"
                            text={content.joinAaaLinkText}
                            style={linkStyle}
                        />
                    } */}
                    {
                        membershipExpiryDate === 'N/A' && invalidMembershipNumber &&
                        <Link
                            to='#'
                            onClick={handleRedirectionForInvalidMembership}
                            text='Enter AAA Membership'
                            style={linkStyle}
                        />
                    }
                    <RichText
                        text={content.descriptionCompletePage}
                        options={options}
                    />
                </>
            }
            {
                productType === BASIC &&
                <>
                    <RichText
                        text={content.descriptionBasicPlan}
                        options={options}
                    />
                </>
            }
        </CardWithTitle>
    )
};

export default CoverageDetails;