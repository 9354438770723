import React from 'react';
import { Api, ContentfulService, segmentAnalytics } from '../configuration/ApplicationContext';
import CheckoutForm from './forms/CheckoutForm';
import ContentfulIds from '../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, {
    SignUpFlowExtraContent,
    SignUpFlowFormContent
} from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Title from './elements/Title';
import Spinner from './elements/Spinner';
import DataContext from './contexts/DataContext';
import UpdatePaymentSummary from './update-payment-summary/UpdatePaymentSummary';
import PaymentPastDue from './outstanding-balance/PaymentPastDue';
import { NotificationTypes } from './notifications/Notifications';
import StringPlaceholderService from '../services/StringPlaceholderService';
import moment from 'moment';
import RedirectionService from '../services/RedirectionService';
import BaseCheckout from './BaseCheckout';
import Breadcrumbs from './common/Breadcrumbs';
import { CANCELLED, FREE_UPPER_CASE } from './common/Constants';
import { removeRedirectValueFromLocalStorage } from './common/Helpers';

declare var Z: any; //Zuora

export default class PayOutstandingBalancePage extends BaseCheckout {
    static contextType = DataContext;
    state = {
        zuoraConfiguration: null
    };

    componentDidMount() {
        removeRedirectValueFromLocalStorage();
        this.checkUserOutstandingStateAndRedirect();
        this.willShowNotification();
        this.loadZuoraForm();
        this.loadContent()
        this.loadPersonalInformation();
        this.prepopulateFields();
        this.setSubscription();
    }

    checkUserOutstandingStateAndRedirect() {
        const { outstandingBalance } = this.context.userDetails?.subscription || {};
        if (outstandingBalance <= 0) {
            this.props.history.push(RedirectionService.getConditionalRenderURL(null, this.context.userDetails));
        }
    }

    willShowNotification() {
        const {
            subscription: {
                subscriptionStatus,
                outstandingBalance,
                productType
            } = {},
            membership
        } = this.context.userDetails || {};
        const { membershipStatus } = membership || {};
        const isLoginRedirect = membershipStatus === CANCELLED && productType !== FREE_UPPER_CASE && outstandingBalance > 0 && subscriptionStatus === CANCELLED
        this.setState({
            showPaymentPastDue: new URLSearchParams(this.props.location.search).get('notification'),
            isUpgrade: productType === FREE_UPPER_CASE,
            isLoginRedirect,
            redirectToUpgrade: new URLSearchParams(this.props.location.search).get('upgrade')
        });
    }

    setSubscription() {
        this.setState({ subscription: this.context.userDetails.subscription });
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.PayOutstandingBalancePage);
        this.setState({ content: response.fields });
        this.loadDynamicContent(response.fields);
    }

    loadDynamicContent(content) {
        const {
            dueDate,
            outstandingBalance,
            defaultPaymentMethod: {
                creditCardNumber,
                creditCardType
            } = {}
        } = this.context.userDetails.subscription || {};
        const withoutUpgradeCondition = creditCardNumber ? content.section.fields.descriptionWithoutUpgrade : content.section.fields.descriptionWithoutUpgradeCreditCardDetail;
        const withUpgradeCondition = creditCardNumber ? content.section.fields.description : content.section.fields.descriptionWithoutCreditCardDetail;
        const description = this.state.isLoginRedirect ? withoutUpgradeCondition : withUpgradeCondition;
        const properties = {
            dueDate: moment(dueDate)?.format('M/D/YYYY'),
            outstandingBalance: outstandingBalance?.toFixed(2),
            creditCardType,
            creditCardNumber: creditCardNumber?.substr(creditCardNumber.length - 4),
        }
        this.setState({
            dynamicContent: StringPlaceholderService.replace(description, properties)
        });
    }

    submit = async (data) => {
        const completeAnnualPlanId = this.context?.ratePlanIds?.complete?.annualRatePlanId || '';
        const { id, createdOn, lastModified, ...billingAddress } = this.state.personalInfo?.billingAddress || {};
        const { addNotification, removeNotification, clearNotifications } = this.props.notificationContext;
        if (Object.keys(data.errors).length > 0 || !data.values.billingAddress) {
            this.setState({
                checkoutError: data.errors.billingAddress
            });
            return;
        }

        this.setState({
            checkoutError: ""
        });

        window.orderSuccess = async (refId) => {
            this.setState({
                isSubmitDisabled: true
            });
            await removeNotification(NotificationTypes.PAYMENT_PAST_DUE);
            const paymentMethodId = refId;
            segmentAnalytics.track('My Account| Pay Outstanding Balance Success AAA Member', {
                memberId: this.context.userDetails.userId,
                numberOfDaysRecivedAlert: this.context.userDetails.subscription.dueDays,
                AAAMembershipId: this.context.userDetails?.membership?.membershipId,
                datePaidOutstandingBalance: this.context.userDetails.subscription.lastPaymentDate
            });
            await clearNotifications();
            let response = await Api.put('/api/subscriptions/payment-details', { paymentMethodId, billingAddress });
            if (!response) {
                this.setState({
                    isSubmitDisabled: false
                });
                return;
            }
            if (response.validationErrors) {
                const apiErrors = response.validationErrors.map(apiError => {
                    return apiError.message
                });
                this.setState({ checkoutError: apiErrors, zuoraConfiguration: null, isSubmitDisabled: false }, () => { this.loadZuoraForm(); });
            } else {
                let redirectTo = '';
                if (this.state.redirectToUpgrade && this.context.product.name === 'Protect') {
                    redirectTo = `/product/protect/upgrade/${completeAnnualPlanId}`;
                } else if (this.state.redirectToUpgrade && this.context.product.name?.toUpperCase() === 'FREE') {
                    redirectTo = `/product/basic/upgrade/${completeAnnualPlanId}`;
                } else if (!this.state.isUpgrade || this.context.product.name === 'Complete') {
                    await addNotification(NotificationTypes.PAID_OUTSTANDING_BALANCE);
                    redirectTo = '/my-account';
                } else if (this.context.product.name === 'Protect') {
                    await addNotification(NotificationTypes.PAID_OUTSTANDING_BALANCE_UPGRADE);
                    redirectTo = `/product/upgrade/${completeAnnualPlanId}`;
                }
                this.props.history.push(redirectTo);
            }
        };

        window.orderFailure = (failureMessage) => {
            this.setState({
                checkoutError: failureMessage,
                zuoraConfiguration: null,
                isSubmitDisabled: false
            }, () => {
                this.loadZuoraForm();
            });
        };
        Z.submit();
    };

    render() {
        const { membershipStatus } = this.context.userDetails.membership || {};
        const {
            zuoraConfiguration,
            content,
            checkoutError,
            personalInfo,
            prepopulateFields,
            showPaymentPastDue,
            dynamicContent,
            subscription,
            isUpgrade,
            billingAddressSameAsHomeAddress,
            isSubmitDisabled
        } = this.state || {};
        const { product } = this.context;
        if (!zuoraConfiguration || !content || !product || !subscription || isSubmitDisabled) {
            return <Spinner />;
        }
        return (
            <>
                {
                    membershipStatus !== CANCELLED &&
                    <Breadcrumbs />
                }
                <Content>
                    <ResponsiveSignupFlowLayout>
                        <SignUpFlowFormContent>
                            <Title
                                desktopStyles={{ marginTop: '0' }}
                                mobileStyles={{ marginTop: '3rem' }}>
                                {content.title}
                            </Title>
                            {
                                showPaymentPastDue &&
                                <PaymentPastDue
                                    content={content.section.fields}
                                    dynamicContent={dynamicContent}
                                    isUpgrade={isUpgrade}
                                />
                            }
                            <CheckoutForm
                                zuoraConfiguration={zuoraConfiguration}
                                prepopulateFields={prepopulateFields}
                                content={content}
                                onSubmit={this.submit}
                                checkoutError={checkoutError}
                                personalInfo={personalInfo}
                                onSelectAddress={this.onSelectAddress}
                                selectedProduct={product}
                                hideTermsAndConditions={true}
                                isPaymentOutstandingBalancePage={true}
                                subscription={subscription}
                                billingAddressSameAsHomeAddress={billingAddressSameAsHomeAddress}
                                upgradeClicked={true}
                                isSubmitDisabled={isSubmitDisabled}
                            />
                        </SignUpFlowFormContent>
                        <SignUpFlowExtraContent>
                            <UpdatePaymentSummary
                                page={`payOutstandingBalance`}
                                data={subscription}
                                title={`Your Outstanding Balance`}
                            />
                        </SignUpFlowExtraContent>
                    </ResponsiveSignupFlowLayout>
                </Content>
            </>
        );
    }
}

