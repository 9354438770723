import React from "react";
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import RichText from '../elements/RichText';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import { colors, desktopOnly } from "../../styles/common";
import styled, {css} from "styled-components";
import { variation } from "../../configuration/ApplicationContext";

const DisclaimerSection = styled.div`
    padding: 28px 40px;
    background: ${colors.lightGrey};
    ${props => props.desktopOnly && css`
        ${desktopOnly}
    `}
`;
const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} style={{ textAlign: 'justify', margin: 0, color: colors.verylightGrey, backgroundColor: colors.lightGrey }} />;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle desktopFontSize='12px' mobileFontSize='12px'>{children}</ParagraphStyle>,
        [INLINES.HYPERLINK]: (node, next) => {
            // eslint-disable-next-line react/jsx-no-target-blank 
            // add rel="noopener" or rel="noreferrer" for avoid security risk.
            return <a href={node.data.uri} target={'_blank'} rel="noopener noreferrer">{node.content[0].value}</a>;
        }
    },
};

const Disclaimer = ({ section, showTrialSection = false, desktopOnly = false }) => {
    if (!section.fields.description?.content) {
        return null;
    }
    return (
        <DisclaimerSection desktopOnly={desktopOnly}>
            { (showTrialSection === false || showTrialSection === undefined || variation.freeTrial === false) && <RichText text={section.fields.description?.content[0]} options={options} />}
            { showTrialSection === true && variation.freeTrial === true && <RichText text={section.fields.subDescription} options={options} />}
        </DisclaimerSection>
    );
};

export default Disclaimer;
