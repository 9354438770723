import React, { useEffect } from 'react';
import { segmentAnalytics } from "./configuration/ApplicationContext";
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import withHeaderAndFooter from './components/layout/withHeaderAndFooter';
import LandingPage from "./components/LandingPage";
import DataProvider from './components/contexts/DataProvider';
import UserRoutes from './routes/UserRoutes';
import AdminRoutes from './routes/AdminRoutes';
import AccessibilityPage from './components/AccessibilityPage';
import OutofAreaSignupPage from './components/OutofAreaSignupPage';
import OutofAreaZipCodePage from './components/OutofAreaTryZipCodePage';
import OutofAreaSignupSuccessPage from './components/OutofAreaSignupSuccessPage';
import FaqsPage from './components/marketing-faq/FaqsPages';
import PlanDetailsPage from './components/PlanDetailsPage';
import TermsAndConditionsPage from "./components/TermsAndConditionsPage";
import ContactUsPage from './components/ContactUsPage';
import Zendesk from "react-zendesk";
import InsurancePage from './components/InsurancePage';
import LogoutPage from "./components/LogoutPage";
import SigninPage from "./components/SigninPage";
import OrderCompleteCallbackPage from "./components/OrderCompleteCallbackPage";
import QuestionsPage from './components/QuestionsPage';
import cookie from 'react-cookies';
import VerifyIdentityPage from './components/VerifyIdentityPage';
import VerifyIdentitySuccessPage from './components/VerifyIdentitySuccessPage';
import VerifyIdentityFailurePage from './components/VerifyIdentityFailurePage';
import PrivacyRightsPage from './components/PrivacyRightsPage';
import LoginFailurePage from './components/LoginFailurePage';
import EmailAlreadyInUse from './components/EmailAlreadyInUse';
import SomethingWentWrong from './components/SomethingWentWrong';
import {
    getIndividualPromoCodeNameValueFromSessionStorage,
    redirectParameters,
    redirectParamSessionStorage,
    setIndividualPromoCodeNameValueDirectlyInSessionStorage
} from './components/common/Helpers';

const zendeskSettings = {
    "contactOptions": {
        "enabled": true,
        "contactButton": {
            "*": "Get in touch"
        },
        "chatLabelOnline": {
            "*": "Chat with an agent"
        },
        "chatLabelOffline": {
            "*": "Get in touch"
        },
        "contactFormLabel": {
            "*": "Submit a general request"
        },
        "mobile": {
            "labelVisible": true
        }
    },
    "helpCenter": {
        "originalArticleButton": false,
        "title": {
            "en-US": "Search for help",
            "*": "Have your say"
        }
    },
    "contactForm": {
        "title": {
            "en-US": "We would love to help! Choose an option below.",
            "*": "Contact us"
        },
        "selectTicketForm": {
            "*": "For urgent issues, call us at (844) 432-4267"
        }
    },
    "launcher": {
        "label": {
            "en-US": "Need more help?",
            "*": "Need help?"
        },
        "chatLabel": {
            "en-US": "Help?",
            "*": "Need help?"
        },
        "mobile": {
            "labelVisible": false
        }
    }
};

function CreateUserRoutes(data) {
    const { location } = data?.props || {};
    if (location.pathname.startsWith('/choose/product')) {
        const uri = location?.pathname + location?.search;
        saveRedirectUri(uri);
    }
    return (
        <DataProvider>
            <UserRoutes />
        </DataProvider>
    );
}

function saveRedirectUri(uri) {
    cookie.save('redirect_uri', uri, { path: '/' });
}

function captureRedirectParams(search) {
    const params = new URLSearchParams(search);
    const redirectParams = {};
    for (const [key, value] of params) {
        redirectParams[key.toLowerCase()] = value.toLowerCase();
    }
    if (Object.keys(redirectParams).length) {
        if (getIndividualPromoCodeNameValueFromSessionStorage()) {
            redirectParams[redirectParameters.promoCode] = redirectParams[redirectParameters.promoCode] !== undefined
                ? redirectParams[redirectParameters.promoCode]
                : getIndividualPromoCodeNameValueFromSessionStorage();
        }
        sessionStorage.setItem(redirectParamSessionStorage, JSON.stringify(redirectParams));
        redirectParams[redirectParameters.promoCode] && setIndividualPromoCodeNameValueDirectlyInSessionStorage(redirectParams[redirectParameters.promoCode] && redirectParams[redirectParameters.promoCode]);
    }
}

function Routes() {
    const history = useHistory();
    captureRedirectParams(history.location.search);
    useEffect(() => {
        return history.listen((location) => {
            segmentAnalytics.page(location.pathname)
        });
    }, [history]);

    return (
        <Switch>
            <Route exact strict path="/" component={withHeaderAndFooter(LandingPage, false, true, true, false, { showPromoCodeNotification: true })} />
            <Route exact strict path="/product" component={withHeaderAndFooter(LandingPage, false, true, true, false, { showPromoCodeNotification: true })} />
            <Route exact strict path="/login-failure" component={withHeaderAndFooter(LoginFailurePage, true, false)} />
            <Route exact strict path="/email-in-use" component={withHeaderAndFooter(EmailAlreadyInUse, true, false)} />
            <Route exact strict path="/something-went-wrong" component={withHeaderAndFooter(SomethingWentWrong, true, false)} />
            <Route exact strict path="/privacy-rights" component={withHeaderAndFooter(PrivacyRightsPage, false, true, false, false, { showPromoCodeNotification: true })} />
            <Route exact strict path="/privacy-rights/token-verification" component={withHeaderAndFooter(PrivacyRightsPage, false, true, false, false, { showPromoCodeNotification: true })} />
            <Route exact strict path="/verify-identity" component={withHeaderAndFooter(VerifyIdentityPage, true, false)} />
            <Route exact strict path="/verify-identity-success" component={withHeaderAndFooter(VerifyIdentitySuccessPage, true, false)} />
            <Route exact strict path="/verify-identity-failure" component={withHeaderAndFooter(VerifyIdentityFailurePage, true, false)} />
            <Route exact strict path="/question/:id" component={withHeaderAndFooter(QuestionsPage, true, false)} />
            <Route exact strict path="/out-of-area/signup" component={withHeaderAndFooter(LandingPage, false, true, true, false, { showPromoCodeNotification: true })} />
            <Route exact strict path="/out-of-area/zip-code" component={withHeaderAndFooter(OutofAreaZipCodePage, false, true, false, false, { showPromoCodeNotification: true })} />
            <Route exact strict path="/out-of-area/signup/success" component={withHeaderAndFooter(OutofAreaSignupSuccessPage, false, true, false, false, { showPromoCodeNotification: true })} />
            <Route exact strict path="/plan-details" component={(withHeaderAndFooter(PlanDetailsPage, false, true, true, false, { showPromoCodeNotification: true }))} />
            <Route exact strict path="/faqs" component={withHeaderAndFooter(FaqsPage, false, true, false, false, { showPromoCodeNotification: true })} />
            <Route exact path="/faqs/categories/:categoryId" component={withHeaderAndFooter(FaqsPage, false, true, false, false, { showPromoCodeNotification: true })} />
            <Route exact path="/faqs/categories/:categoryId/questions/:questionId" component={withHeaderAndFooter(FaqsPage, false, true, false, false, { showPromoCodeNotification: true })} />
            <Route exact strict path="/terms-and-conditions" component={withHeaderAndFooter(TermsAndConditionsPage, false, true, false, false, { showPromoCodeNotification: true })} />
            <Route exact strict path="/insurance-terms" component={withHeaderAndFooter(InsurancePage, false, true, false, false, { showPromoCodeNotification: true })} />
            <Route exact strict path="/contact-us" component={withHeaderAndFooter(ContactUsPage, false, true, false, false, { showPromoCodeNotification: true })} />
            <Route exact strict path="/accessibility" component={withHeaderAndFooter(AccessibilityPage)} />
            <Route exact strict path="/logout" component={LogoutPage} />
            <Route exact strict path="/signin" component={SigninPage} />
            <Route exact strict path="/order-complete" component={OrderCompleteCallbackPage} />
            <Route path="/admin" component={AdminRoutes} />
            <Route path="/" component={(props) => <CreateUserRoutes props={props} />} />
        </Switch>
    );
}

function App() {
    return (
        <BrowserRouter>
            <Zendesk defer zendeskKey="22603a67-7bea-4c4a-839d-1f67a197c608" {...zendeskSettings} />
            <Routes />
        </BrowserRouter>
    )
}

export default App;