import { Fragment } from "react";
import React from 'react';
import styled, { css } from "styled-components";
import { colors, commonPropStyles } from "../../../../styles/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolTip from "../../../tooltip/ToolTip";
import Title from "../../../elements/Title";
import { BASIC, COMPLETE, NON_AAA, PROTECT } from "../../../common/Constants";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
const RowElement = styled.td`
    font-size: 20px;
    line-height: 22px;
    width: 25%;
    ${commonPropStyles.desktop}
`;
const ProductData = styled.span`
    color: ${props => props.color};
    font-size: 16px;
    line-height: 22px;
    text-align: center;
`;
const CheckIcon = styled.span`
    font-weight: 900;
    font-size: 30px;
    line-height: 48px;
    ${props => props.productname === BASIC && css`
        color: ${colors.skyBlue};
    `}
    ${props => props.productname === BASIC && props.membertype === NON_AAA && css`
        color: ${colors.greyborder};
    `}
    ${props => props.productname === PROTECT && css`
        color: ${colors.mediumBlue};
    `}
    ${props => props.productname === COMPLETE && css`
        color: ${colors.darkBlue};
    `}
`
const styles = {
    iconStyle: { color: colors.textColor, fontSize: '16px', height: '1rem', width: '1rem', paddingRight: '10px', paddingLeft: '10px', float: 'left' },
    title: { color: colors.textColor, display: 'block', fontWeight: 'normal', overflow: 'hidden', margin: 0 },
    productRowElement: { textAlign: 'center' },
    tooltip: { fontSize: '10px' }
};
const desktopStyles = {
    title: { fontSize: '16px', lineHeight: '22px' }
}
const CompareOurPlansDataDesktop = (props) => {
    const {
        subSection,
        memberType
    } = props || {};
    return (
        subSection.name!='30GB Secure Cloud Storage' && <Fragment>
            <RowElement>
             <FontAwesomeIcon
                    data-tip
                    data-for={subSection.name}
                    style={styles.iconStyle}
                    icon={faQuestionCircle}
                />
                <ToolTip
                    id={subSection.name}
                    content={subSection.tooltipText}
                    backgroundcolor={colors.textColor}
                    fontStyle={styles.tooltip}
                    textcolor={colors.pureWhite}
                />
                <Title
                    style={styles.title}
                    desktopStyles={desktopStyles.title}>
                    {subSection.name}
                </Title>
            </RowElement>
            {
                subSection.products.map((product, productIndex) =>
                    <RowElement
                        key={`product${productIndex}`}
                        style={styles.productRowElement}>
                        {
                            product.showCheckBox === false &&
                            <ProductData
                                color={
                                    product.name === BASIC && memberType === NON_AAA
                                        ? colors.greyborder
                                        : colors.textColor
                                }
                                dangerouslySetInnerHTML={{ __html: product.value }}
                            />
                        }
                        {
                            product.showCheckBox === true &&
                            <CheckIcon
                                membertype={memberType}
                                productname={product.name}>
                                &#10004;
                            </CheckIcon>
                        }
                    </RowElement>

                )
            }
        </Fragment>
    );
}
export default CompareOurPlansDataDesktop;