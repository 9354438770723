import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../styles/common';
import SignupFlowStep from './SignUpFlowStep';

const Container = styled.div`
    margin-top: 1rem;
    ${mobile`
        margin-top: 2.5rem;
    `}
`;

const QuestionFlowStepProgress = ({currentStep, questions, desktopHighlightColor}) => {
    return (
        <Container>
            {questions.map(({number}) =>
                <SignupFlowStep step={number} currentStep={currentStep} desktopHighlightColor={desktopHighlightColor}/>
            )}
        </Container>
    );
};

export default QuestionFlowStepProgress;
