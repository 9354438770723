const validate = (values, dirty) => {
    Object.keys(values).forEach(key => {
            values[key] = values[key]?.trim()     
    });
    let errors = {};

    // Home address validation
    if (!values.homeAddress) {
        errors.homeAddress = 'Please enter Home Address.';
    }
    // Apt info validation
    const aptFieldRegex = /^[a-z0-9 .#-]+$/i;
    const aptSpecialCharRegex =  /^[.#-]+$/i;
    if (values.additionalHomeInfo) {
        if(!aptFieldRegex.test(values.additionalHomeInfo)){
            errors.additionalHomeInfo = 'Please enter valid Apt info.';
        } else if(aptSpecialCharRegex.test(values.additionalHomeInfo)) {
            errors.additionalHomeInfo = 'Please enter valid Apt info.';
        }    
    }
    return errors;
};

export default validate
