import React from 'react';
import { AdminApi } from '../../../configuration/ApplicationContext';
import ContentfulIds from '../../../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, {
    SignUpFlowExtraContent,
    SignUpFlowFormContent
} from '../../layout/ResponsiveSignupFlowLayout';
import Content from '../../layout/Content';
import Title from '../../elements/Title';
import Spinner from '../../elements/Spinner';
import AdminContext from '../../contexts/AdminContext';
import BaseAdminUpgradeTierCheckout from '../BaseAdminUpgradeTierCheckout';
import AdminCheckoutForm from '../forms/AdminCheckoutForm';
import AdminSummary from '../summary/AdminSummary';
import Breadcrumbs from '../../common/Breadcrumbs';
import UtilsService from '../../../services/UtilsService';
import InfoText from '../../elements/InfoText';
import Notification from '../../notifications/Notification';
import Card from '../../elements/Card';

declare var Z: any; //Zuora

export default class PaymentPage extends BaseAdminUpgradeTierCheckout {
    static contextType = AdminContext;

    constructor(props) {
        super(props);
        this.state = {
            zuoraConfiguration: null,
            selectedProduct: null,
            productPageURL: '/admin/basic/upgrade',
            checkoutPageURL: '/admin/process-payment',
            subscriptionAction: 'UPGRADE'
        };
    }

    async componentDidMount() {
        await this.context.getMemberDetails();
        this.loadPlans();
        this.setBreadCrumb();
        this.loadZuoraForm();
        this.loadContent(ContentfulIds.Admin.MemberAccountIds.AdminProcessPaymentPage);
        this.setSubscription();
        this.loadPersonalInformation();
    }
    setSubscription() {
        this.setState({ subscription: this.context.memberDetails?.subscription });
    }

    submit = async (data) => {
        const { ...billingAddress } = this.state.personalInfo?.billingAddress || {};
        if (Object.keys(data.errors).length > 0 || !data.values.billingAddress) {
            this.setState({
                checkoutError: data.errors.billingAddress
            });
            return;
        }
        window.orderSuccess = async (refId) => {
            const { promoCodeRatePlanId, promoCode } = this.context.promoCodeData || {};
            this.setState({
                isSubmitDisabled: true
            });
            const ratePlanId = this.context.product.id;
            const paymentMethodId = refId;
            const nonAaaMember = this.state?.isNonAaaMemberParam || false;
            var response = await AdminApi.post(`/api/users/${this.context.searchResult.userId}/upgrade-coverage`, { ratePlanId, paymentMethodId, billingAddress, promoCodeRatePlanId, promoCode, nonAaaMember});
            if (!response) {
                this.setState({
                    isSubmitDisabled: false
                });
                return;
            }
            if (response.validationErrors) {
                const apiErrors = response.validationErrors.map(apiError => {
                    return apiError.message
                });
                this.setState({ checkoutError: apiErrors, zuoraConfiguration: null, isSubmitDisabled: false }, () => { this.loadZuoraForm(); });
            } else {
                this.trackPaymentSuccessEvent(`Sales-Tool-Upgrade-Basic-to-${this.context.product.name}`);
                this.props.history.push('/admin/product/upgrade/success');
            }
        };

        window.orderFailure = (failureMessage) => {
            this.setState({
                checkoutError: failureMessage,
                zuoraConfiguration: null,
                isSubmitDisabled: false
            }, () => {
                this.loadZuoraForm();
            });
        };
        Z.submit();
    };

    render() {
        const { zuoraConfiguration, content, checkoutError, personalInfo, product, subscription, billingAddressSameAsHomeAddress, breadCrumbRoutes, isSubmitDisabled } = this.state;
        const searchResult = JSON.parse(localStorage.getItem('searchResult'));
        const { notifications } = this.props.notificationContext;
        if (!zuoraConfiguration || !product || !subscription || !content || isSubmitDisabled) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{ marginTop: 0 }}>
                <InfoText>
                    {notifications.map(notification => {
                        return <Notification key={notification.notificationType} {...notification} {...this.props} />
                    }
                    )}
                </InfoText>
                <Breadcrumbs isCustomerFacing={false} breadCrumbRoutes={breadCrumbRoutes}></Breadcrumbs>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title style={{ marginTop: '3rem' }}>{content.title}</Title>
                        <AdminCheckoutForm
                            zuoraConfiguration={zuoraConfiguration}
                            content={content}
                            onSubmit={this.submit}
                            checkoutError={checkoutError}
                            personalInfo={personalInfo}
                            onSelectAddress={this.onSelectAddress}
                            selectedProduct={product}
                            isSubmitDisabled={isSubmitDisabled}
                            billingAddressSameAsHomeAddress={billingAddressSameAsHomeAddress}
                            onApplyPromoCode={this.onApplyPromoCode}
                            promoCodeOptions={{
                                validateURL: `/api/promo-codes/users/${searchResult?.userId}/validate`,
                                zuoraRatePlanURL: `/api/products/users/${searchResult?.userId}/zuora-rate-plans?subscriptionAction=${this.state.subscriptionAction}`, mapProductsFunc: UtilsService.mapProductsToArray
                            }}
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        <Card>
                            <AdminSummary
                                product={product}
                                title={`If Subscriber upgrades to ${product.name}`}
                             />
                        </Card>
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}
