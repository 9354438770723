import React, { Component } from "react";
import LandingContent from "./layout/LandingContent";
import ResponsiveLandingPageLayout from "./layout/ResponsiveLandingPageLayout";
import NotYetAvailable from "./out-of-area/NotYetAvailable";
import ZipCodeService from "../services/ZipCodeService";
import Spinner from "./elements/Spinner";
import OutofAreaSignupToNotifyForm from "./forms/OutOfAreaForm/OutofAreaSignupToNotifyForm";
import ProtectMyID from "./out-of-area/ProtectMyId";
import { Api, ContentfulService } from "../configuration/ApplicationContext";
import ContentfulIds from "../configuration/ContentfulIds";
import withPageTitle from "./layout/withPageTitle";
import withPageMetaTags from "./layout/withPageMetaTags";
import { getPromoCodeNameFromRedirectParams } from "./common/Helpers";

class OutofAreaSignupPage extends Component {

    constructor() {
        super();
        this.state = {
            isSubscribeToIdcMailingListing: true
        };
    }
    componentDidMount() {
        this.setContent();
        this.setZipCode();
    }

    async setContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.OutOfPage.OutOfPageSignupToBeNotified);
        this.setState({ content: response.fields.sections });

    }

    setZipCode() {
        const zipCode = ZipCodeService.getZipCookie();
        this.setState({ zipCode: zipCode });
    }

    onSignup = async (data) => {
        const { zipCode, email } = data.values;
        const { isSubscribeToIdcMailingListing } = this.state;
        const interestedPartyDetail = {
            zipCode,
            email,
            isSubscribeToIdcMailingListing
        };
        this.setState({
            apiErrors: [],
            isSubmitDisabled: true
        });
        if (Object.keys(data.errors).length > 0) {
            return;
        }
        await Api.post('/api/interested-parties', interestedPartyDetail);
        this.props.history.push(`/out-of-area/signup/success?email=${email}`);
    }

    handleSubscribe = () => {
        this.setState((prevState) => {
            return {
                isSubscribeToIdcMailingListing: !prevState.isSubscribeToIdcMailingListing
            }
        })
    }

    render() {
        const { zipCode, apiErrors, content, isSubscribeToIdcMailingListing, isSubmitDisabled } = this.state;
        if (!zipCode || !content) {
            return <Spinner />;
        }
        return (
            <LandingContent
                promocodename={getPromoCodeNameFromRedirectParams()}>
                <ResponsiveLandingPageLayout>
                    <NotYetAvailable
                        zipCode={zipCode}
                        content={content[0].fields}
                        isOutOfAreaSignupPage={true}
                    />
                    <OutofAreaSignupToNotifyForm
                        isSignupPage={true}
                        zipCode={zipCode}
                        onSubmit={this.onSignup}
                        apiErrors={apiErrors}
                        content={content[1].fields}
                        isSubscribeToIdcMailingListing={isSubscribeToIdcMailingListing}
                        isSubmitDisabled={isSubmitDisabled}
                        handleSubscribe={this.handleSubscribe}
                    />
                    <ProtectMyID
                        content={content[2].fields}
                    />
                </ResponsiveLandingPageLayout>
            </LandingContent>
        );
    }
}

export default withPageMetaTags(withPageTitle(OutofAreaSignupPage, "Out of Area"), { noindex: true });