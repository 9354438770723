import styled from "styled-components";
import { mobile } from "../../styles/common";
import React from "react";
import FormError from "../elements/FormError";
import LabeledInput from "../elements/LabeledInput";
import SubmitButton from "../elements/SubmitButton";
import PropTypes from 'prop-types';
import useCustomForm from "../../hooks/useCustomForm";
import validate from "../forms/emailForm";

const EmailFormSection = styled.div`
    display: block;
    width: 80%;
    box-sizing: border-box;
    ${mobile`
        width: 100%;
    `}
`;
const buttonStyle = {
    marginBottom: '3em'
}
const EmailForm = ({ initialValues, apiErrors, content, isSubmitDisabled, onSubmit }) => {
    initialValues = {
        email: ''
    };
    const {
        values,
        errors,
        touched,
        submitted,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useCustomForm({ initialValues, validate, onSubmit });

    const inputHandlers = {
        onChange: handleChange,
        onBlur: handleBlur,
        extratopspace: '0.5rem',
        extraTopLabelSpace: '0.75rem'
    };

    const filteredErrors = submitted ? errors : Object.keys(errors)
        .filter(key => touched[key])
        .reduce((obj, key) => {
            obj[key] = errors[key];
            return obj;
        }, {});

    const handleFormSubmit = (event) => {
        event.preventDefault();
        handleSubmit();
    }

    return (
        <EmailFormSection>
            <form onSubmit={handleFormSubmit} noValidate>
                <FormError showErrors={submitted} errors={filteredErrors} message="Please fill out the fields highlighted below" />
                <FormError showErrors={submitted} errors={apiErrors} />
                <LabeledInput label="Email" type="text" name="email" error={filteredErrors.email} value={values.email} {...inputHandlers} />
                <SubmitButton style={buttonStyle} text={content.linkText} disabled={isSubmitDisabled} />
            </form>
        </EmailFormSection>
    );
}
EmailForm.propTypes = {
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func.isRequired
};

EmailForm.defaultProps = {
    initialValues: {
        email: ''
    }
};

export default EmailForm;