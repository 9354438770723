import React from 'react';
import styled, { keyframes } from "styled-components";
import {colors} from '../../styles/common';

const SPINNER_WIDTH = '60px';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerSection = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 5px solid ${colors.mediumGrey};
  border-left: 5px solid ${colors.mediumGrey};
  border-right: 5px solid ${colors.mediumGrey};
  border-bottom: 5px solid transparent;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: ${SPINNER_WIDTH};
  height: ${SPINNER_WIDTH};
  margin: auto;
  border-radius: 50%;
`;

const WrapSpinner = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0,0,0,0.5);
`;

const Spinner = () => {
  return (
    <WrapSpinner>
      <SpinnerSection />
    </WrapSpinner>
  );
}

export default Spinner;
