import React, { useState, Fragment } from 'react'
import styled, { css } from 'styled-components';
import { colors, mobile } from '../../../../styles/common';
import Title from '../../../elements/Title';
import Spinner from "../../../elements/Spinner";
import InfoText from '../../../elements/InfoText';
import { BLOCKS } from '@contentful/rich-text-types';
import DynamicParagraphText from '../../../elements/DynamicParagraphText';
import RichText from '../../../elements/RichText';
import PlanDetailsDisclaimer from '../../../plan-details/PlanDetailsDisclaimer';
import ProductChartSections from '../../product-chart/customer/ProductChartSections';
import ProductChartToggleButtonSection from '../../product-chart/customer/ProductChartToggleButtonSection';
import { getMemberTypeFromSessionStorage, getPromoCodeNameFromRedirectParams, setMemberTypeInSessionStorage } from '../../../common/Helpers';
import { AAA_MEMBER } from '../../../common/Constants';
import CompareOurPlansData from './CompareOurPlansData';

const PlanDetailsContentSection = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: flex-end; 
    ${mobile`
        justify-content: center;
    `}
`;
const PlanDetailHeader = styled.div`
    background: ${colors.pureWhite};
    ${props => !props.removeSticky && css`
        position: sticky;
    `}
    top: 0;
    z-index: 10;
    ${props => props.removeSticky && css`
        position: relative;
    `}
    ${mobile`
        top: ${props => props.mobiletop};
    `}  
`;
const styles = {
    title: { textAlign: 'center', color: `${colors.darkBlue}`, fontWeight: '500' },
    paragraph: { textAlign: 'center', margin: '1rem' }
};
const desktopStyles = {
    title: { fontSize: '40px', paddingTop: '15px' },
    paragraph: { paddingBottom: '2rem' },
    subHeading: { textAlign: 'center', color: colors.textColor, fontSize: '22px', paddingBottom: '1rem', margin: 'auto' },
};
const mobileStyles = {
    title: { fontSize: '25px', margin: 0 },
    subHeading: { textAlign: 'center', color: colors.textColor, fontSize: '14px', padding: '1rem 2rem 0.5rem', lineHeight: 'unset', margin: 'auto' }
};
const ParagraphStyle = ({ children, ...props }) =>
    <DynamicParagraphText
        {...props}
        text={children}
    />;
const subTitleOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) =>
            <ParagraphStyle
                id="rich-text"
                anchor={true}
                desktopFontSize='18px'
                mobileFontSize='14px'
                desktopStyles={desktopStyles.paragraph}
                style={styles.paragraph}>
                {children}
            </ParagraphStyle>
    },
};

const CompareOurPlans = (props) => {
    const {
        productChartContent,
        disclaimer,
        onProductSelectToCreateSubscription,
        joinAaaText
    } = props || {};

    const [reachBottom, setReachBottom] = useState(false);
    const selectedMemberTypeInSessionStorageOrDefaultAaaMember = getMemberTypeFromSessionStorage() || AAA_MEMBER;
    const promoCodeName = getPromoCodeNameFromRedirectParams();
    const [memberType, setMemberType] = useState(selectedMemberTypeInSessionStorageOrDefaultAaaMember);
    const scrolledToBottom = (status) => {
        setReachBottom(status);
    }
    const setMemberTypeInSessionStorageAndInState = (memberType) => {
        setMemberTypeInSessionStorage(memberType);
        setMemberType(memberType);
    }
    if (!productChartContent) {
        return <Spinner />
    }
    return (
        <Fragment>
            {/* <Title
                style={styles.title}
                mobileStyles={mobileStyles.title}
                desktopStyles={desktopStyles.title}>
                {productChartContent.title}
            </Title> */}
            {/* <InfoText
                desktopStyles={desktopStyles.subHeading}
                mobileStyles={mobileStyles.subHeading}>
                {productChartContent.description}
            </InfoText> */}
            <RichText
                text={joinAaaText}
                options={subTitleOptions}
            />
            <PlanDetailHeader
                mobiletop={promoCodeName ? '155px' : '50px'}
                removeSticky={reachBottom}>
                {/* <ProductChartToggleButtonSection
                    memberType={memberType}
                    setMemberType={setMemberTypeInSessionStorageAndInState}
                /> */}
                <PlanDetailsContentSection>
                    <ProductChartSections
                        sections={productChartContent.sections}
                        showBottomSection={false}
                        productchartsectionwidth='75%'
                        memberType={memberType}
                        displayProductChartSectionInMobile='flex'
                        productchartsectionpadding='0'
                        showBasicNotAvailableOverlayDialogBox={false}
                        choosePlan={true}
                        onProductSelectToCreateSubscription={onProductSelectToCreateSubscription}
                    />
                </PlanDetailsContentSection>
            </PlanDetailHeader>
            <CompareOurPlansData
                content={productChartContent}
                scrolledToBottom={scrolledToBottom}
            />
            <PlanDetailsDisclaimer
                disclaimer={disclaimer}
            />
        </Fragment>
    )

}
export default CompareOurPlans;