/* eslint-disable no-undef,class-methods-use-this */
import { Api } from './ApplicationContext';
import cookie from 'react-cookies';

class SegmentAnalytics {
    constructor(segmentApiKey) {
        this.email = null;
        this.segmentApiKey = segmentApiKey;
    }

    page() {
        if (this.analyticsAreAvailable()) {
            if (window.initialAnalyticsPageLoadCall) {
              // Loading segment via "loadSegmentIO" also calls analytics.page()
              // To avoid double tracking the initial page load, this is set to true initially on library load and set to false here
              window.initialAnalyticsPageLoadCall = false;
              return;
            }
            var segmentcookie = cookie.load('segment_disabled') === '1';
            if (segmentcookie) {
              delete window.analytics;
            } else {
              analytics.page("",document.title,"","",()=>{});
            }
        }
    }

    track(action, eventMetadata) {
        if (this.analyticsAreAvailable()) {
            analytics.track(action, eventMetadata);
        }
    }

    identify(action, eventMetadata) {
        if (this.analyticsAreAvailable()) {
            analytics.identify(action, eventMetadata);
        }
    }

    analyticsAreAvailable() {
        return window.analytics;
    }

    async checkForOptOutStatus(email) {
        if (email === this.email) {
            return;
        }
        this.email = email;
        try {
          const optOutStatus = await Api.get(`/api/privacy-options?email=${email}`);
          const isSegmentAlreadyDisabled = cookie.load('segment_disabled');
          if (optOutStatus.opted_out === true) {
            if(!isSegmentAlreadyDisabled || isSegmentAlreadyDisabled === '0') {
              window.DNS_OPTOUT.disableSegmentIOTracking();
              window.DNS_OPTOUT.loadSegmentIO(this.segmentApiKey,false);
              window.location.reload();
            }
          } else {
            window.DNS_OPTOUT.enableSegmentIOTracking();
            window.DNS_OPTOUT.loadSegmentIO(this.segmentApiKey,false);
            analytics.page("",document.title,"","",()=>{});
            // Loading segment via "loadSegmentIO" also calls analytics.page()
            // To avoid double tracking the initial page load, this is set to true initially and reset to false by SegmentAnalytics.js
            window.initialAnalyticsPageLoadCall = true;
          }
          return optOutStatus.opted_out;
        } catch (e) {
          return true; // Assume opted out / worst case
        }
    }
}
export default SegmentAnalytics;