import { Api, ContentfulService } from '../configuration/ApplicationContext';
import { BASIC_MEMBER_BROWSES_HIGHER_COVERAGE, BASIC_MEMBER_SELECTS_HIGHER_COVERAGE, PROTECT_MEMBER_SELECTS_COMPLETE_COVERAGE, SERVICE_COVERAGE_SELECTED, trackEvent, UPGRADE_OPTION_BROWSED } from '../services/SegmentService';
import UtilsService from './../services/UtilsService';
import BaseComponent from './BaseComponent';
import { CANCELLED, FREE, PROTECT } from './common/Constants';
import { buildUrl, getRedirectParamFromSessionStorage, redirectParamSessionStorage } from './common/Helpers';

export default class BaseProductOffering extends BaseComponent {
    async loadPlans(contentfulId) {
        let params = {};
        if (this.state.shouldCheckAddress === false) {
            params.shouldCheckAddress = false;
        }
        const { userDetails } = this.context || {};
        if (this.isNonMemberRateApplicable(userDetails)) {
            params.isNonAaaMember = true;
        } else if (this.context.variation.freeTrial === true && (Object.keys(userDetails).length === 0 || !userDetails?.subscription)) {
            params.isFreeTrialUser = true;
        }
        const url = buildUrl('/api/products/zuora-rate-plans', params);
        const response = await Api.get(url);
        localStorage.setItem('productsWithoutPromoCodes', JSON.stringify(response.products));
        response.products = response.products.filter(product => product.name.toUpperCase() !== FREE.toUpperCase());
        this.mapProductsToArray(response.products);
        this.loadContent(response.products, contentfulId);
        if (this.props.match.params.id) {
            this.onLoadSelectProduct(this.props.match.params.id);
        }
    }

    onApplyPromoCode = async (promoCodeData) => {
        this.setState(promoCodeData);
        if (promoCodeData.products) {
            await this.mapProductsToArray(promoCodeData.products);
            await this.onLoadSelectProduct(this.props.match.params.id);
        }
    }

    setHideBasicInState() {
        const query = new URLSearchParams(this.props.location.search);
        this.setState({
            hideBasic: query.get('hide-basic'),
        });
    }

    isNonMemberRateApplicable = userDetails =>
        userDetails
        && Object.keys(userDetails).length > 0
        && (!userDetails.membershipId
            || (!userDetails.membership && userDetails.invalidMembershipNumber)
            || userDetails.membership?.membershipStatus === CANCELLED
        );

    async loadContent(products, contentfulId) {
        const contentObject = {};
        const title = await ContentfulService.getEntry(contentfulId);
        for await (const product of products) {
            const content = await ContentfulService.getEntry(product.contentfulProductOfferingId);
            contentObject[product.name] = content.fields;
        }
        this.setState({ content: { title: title.fields, main: contentObject } });
    }

    mapProductsToArray(products) {
        const mappedProducts = UtilsService.mapProductsToArray(products);
        this.setState({ products: mappedProducts });
    }

    setRatePlanIds() {
        const { ratePlanIds } = this.context;
        const protectAnnualRatePlanId = ratePlanIds?.protect?.annualRatePlanId || '';
        const completeAnnualRatePlanId = ratePlanIds?.complete?.annualRatePlanId || '';
        const protectMonthlyRatePlanId = ratePlanIds?.protect?.monthlyRatePlanId || '';
        const completeMonthlyRatePlanId = ratePlanIds?.complete?.monthlyRatePlanId || '';
        const redirectionMap = {
            Protect: {
                Monthly: `/product/${protectMonthlyRatePlanId}`,
                Annually: `/product/${protectAnnualRatePlanId}`
            },
            Complete: {
                Annually: `/product/${completeAnnualRatePlanId}`,
                Monthly: `/product/${completeMonthlyRatePlanId}`
            },
            Basic: {
                Monthly: 'basic-coverage',
                Annually: 'basic-coverage'
            }
        };
        return redirectionMap;
    }

    onSelectProduct = (selectedProduct) => {
        if (!this.context.userDetails.subscription) {
            trackEvent(UPGRADE_OPTION_BROWSED, {
                memberId: this.context.userDetails.userId,
                tierSelected: selectedProduct.name
            });
        } else if (this.context.userDetails.subscription?.productType.toUpperCase() === FREE.toUpperCase()) {
            trackEvent(BASIC_MEMBER_BROWSES_HIGHER_COVERAGE, {
                memberId: this.context.userDetails.userId,
                tierSelected: selectedProduct.name,
                paymentOption: selectedProduct.paymentMode
            });
        }
        this.setState({ selectedProduct: selectedProduct });
        this.context.updateState({ product: selectedProduct });
        let URL = `${this.state.productPageURL}/${selectedProduct.id}`;
        if (this.state.hideBasic) {
            URL += `?hide-basic=${this.state.hideBasic}`;
        }
        this.props.history.push(URL);
    }

    onSubmit = async () => {
        if (this.state.isSignupFlow) {
            trackEvent(SERVICE_COVERAGE_SELECTED, {
                memberId: this.context.userDetails.userId,
                tierSelected: this.state.selectedProduct.name,
                subscriptionOption: this.context.product.paymentMode
            });
            if (this.state.hideBasic) {
                return this.props.history.push(`/checkout/${this.state.selectedProduct.id}/personal-info`);
            }
            return this.redirectBasedOnLogin();
        }
        if (this.context.userDetails.subscription?.productType.toUpperCase() === FREE.toUpperCase()) {
            trackEvent(BASIC_MEMBER_SELECTS_HIGHER_COVERAGE, {
                memberId: this.context.userDetails.userId,
                tierSelected: this.state.selectedProduct.name,
                paymentOption: this.state.selectedProduct.paymentMode
            });
        } else if (this.context.userDetails.subscription?.productType.toUpperCase() === PROTECT.toUpperCase()) {
            trackEvent(PROTECT_MEMBER_SELECTS_COMPLETE_COVERAGE, {
                memberId: this.context.userDetails.userId,
                tierSelected: this.state.selectedProduct.name,
                paymentOption: this.state.selectedProduct.paymentMode
            });
        }
        this.props.history.push({
            pathname: this.state.checkoutPageURL + `/${this.state.selectedProduct.id}`
        });
    }

    onSelectPaymentMode = (event, paymentMode) => {
        event.stopPropagation();
        this.setState({ paymentMode });
        const productsByPaymentMode = this.state.products.filter(product => product.paymentMode === paymentMode);
        const selectedProduct = productsByPaymentMode.find(product => product.name === this.state.selectedProduct.name);
        this.onSelectProduct(selectedProduct, this.state.productPageURL);
    }

    onLoadSelectProduct(productId) {
        const foundProduct = this.state.products.find(product => product.id === productId);
        let selectedProduct = foundProduct ? foundProduct : {};
        this.setState({ paymentMode: selectedProduct.paymentMode });
        this.setState({ selectedProduct });
        this.context.updateState({ product: selectedProduct });
    }

    loadAndFilterProductChartContent = async (contentfulId) => {
        const { userDetails: { subscription } = {} } = this.context || {};
        const productChart = await ContentfulService.getEntries({ 'sys.id': contentfulId, include: 5 });
        const productChartContent = productChart.items[0].fields;
        if (subscription?.productType?.toUpperCase() === PROTECT.toUpperCase()) {
            productChartContent.sections.shift(); //remove first element aka Basic
        }
        this.setState({ productChartContent });
    }

    setFlowValueInRedirectParamsInSessionStorage = (flow) => {
        let redirectParams = JSON.parse(getRedirectParamFromSessionStorage());
        if (!redirectParams) {
            redirectParams = {
                product: flow
            };
        } else {
            redirectParams['product'] = flow;
        }
        sessionStorage.setItem(redirectParamSessionStorage, JSON.stringify(redirectParams));
    }
}