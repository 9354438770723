import { colors } from "../../styles/common";
import { BASIC, CANCELLED } from "../common/Constants";
import { formatDateMMDDYYYY, formatCurrency, removeLeadingZerosForSingleDigitDate, capitalizeFirstLetter } from "../common/Helpers";

const AdminNotificationTypes = {
    PAYMENT_EXPIRED: 'PAYMENT_EXPIRED',
    PAYMENT_UPDATE_SUCCESS: 'PAYMENT_UPDATE_SUCCESS',
    PERSONAL_INFORMATION_UPDATE: 'PERSONAL_INFORMATION_UPDATE',
    PAID_OUTSTANDING_BALANCE: 'PAID_OUTSTANDING_BALANCE',
    DOWNGRADE_GRACE_PERIOD_EFFECT: 'DOWNGRADE_GRACE_PERIOD_EFFECT',
    UPCOMING_CANCELLED_SUBSCRIPTION: 'UPCOMING_CANCELLED_SUBSCRIPTION',
    ALREADY_CANCELLED_SUBSCRIPTION: 'ALREADY_CANCELLED_SUBSCRIPTION',
    SUBSCRIPTION_DEACTIVATED_PAID_USER:'SUBSCRIPTION_DEACTIVATED_PAID_USER',
    SUBSCRIPTION_DEACTIVATED_FREE_USER:'SUBSCRIPTION_DEACTIVATED_FREE_USER',
    ADD_EMAIL_ADDRESS: 'ADD_EMAIL_ADDRESS',
    VERIFY_EMAIL_ADDRESS: 'VERIFY_EMAIL_ADDRESS',
    LOW_CONFIDENCE_MIGRATION_ACTIVATION_PROCESS_INCOMPLETE: 'LOW_CONFIDENCE_MIGRATION_ACTIVATION_PROCESS_INCOMPLETE',
    INVAID_PROMO_ERROR: 'INVAID_PROMO_ERROR',
    PROMO_CODE_SUCCESS: 'PROMO_CODE_SUCCESS',
    FREE_TRIAL: 'FREE_TRIAL',
    INVALID_MEMBERSHIP_NUMBER: 'INVALID_MEMBERSHIP_NUMBER',
    HUNDRED_PERCENTAGE_PROMO_FOR_UPGRADE: 'HUNDRED_PERCENTAGE_PROMO_FOR_UPGRADE',
    NO_SUBSCRIPTION_CANCELLED_MEMBERSHIP_NUMBER: 'NO_SUBSCRIPTION_CANCELLED_MEMBERSHIP_NUMBER',
    BASIC_SUBSCRIPTION_CANCELLED_GRACE_MEMBERSHIP_NUMBER: 'BASIC_SUBSCRIPTION_CANCELLED_GRACE_MEMBERSHIP_NUMBER',
    GRACE_PERIOD_WITH_PAID_SUBSCRIPTION_AND_CANCELLED_MEMBERSHIP: 'GRACE_PERIOD_WITH_PAID_SUBSCRIPTION_AND_CANCELLED_MEMBERSHIP'
}

const adminMappedNotification = (notificationType, metadata = {}, additionalProperties) => {
    const { hasNoKnownActiveAAAMembership } = additionalProperties || {};
    switch (notificationType) {
        case AdminNotificationTypes.PAYMENT_EXPIRED:
            return {
                contentfulMessageId: 'paymentMethodExpired',
                severity: 'error',
                link: {
                    text: 'Update Payment Method',
                    url: '/admin/update-payment'
                },
                properties: {
                    creditCardExpiryDate: metadata.expirationMonth + '/' + metadata.expirationYear
                }
            };
        case AdminNotificationTypes.LOW_CONFIDENCE_MIGRATION_ACTIVATION_PROCESS_INCOMPLETE:
            return {
                contentfulMessageId: 'lowConfidenceMigrationActivationPending',
                severity: 'error',
                hideCancelIcon: true
            };
        case AdminNotificationTypes.PAYMENT_UPDATE_SUCCESS:
            return {
                contentfulMessageId: 'paymentMethodUpdateSuccess',
                severity: 'success',
            }
        case AdminNotificationTypes.PERSONAL_INFORMATION_UPDATE:
            return {
                contentfulMessageId: 'personalInformationUpdate',
                severity: 'success',
                properties: {
                    name: metadata?.name,
                }
            }
        case AdminNotificationTypes.PAID_OUTSTANDING_BALANCE:
            return {
                contentfulMessageId: 'paidOutstandingBalance',
                severity: 'success',
            }
        case AdminNotificationTypes.DOWNGRADE_GRACE_PERIOD_EFFECT:
            return {
                contentfulMessageId: 'downgradeGracePeriodEffect',
                severity: 'info',
                hideCancelIcon: true,
                dateOn: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(metadata?.subscriptionChangeDate)),
                properties: {
                    currentCoverage: metadata?.currentCoverage,
                    upcomingCoverage: metadata?.upcomingCoverage
                }
            }
        case AdminNotificationTypes.UPCOMING_CANCELLED_SUBSCRIPTION:
            return {
                contentfulMessageId: 'cancelledSubscriptionMembershipExpirationInEffect',
                severity: 'info',
                hideCancelIcon: true,
                properties: {
                    cancelledCoverage: metadata?.cancelledCoverage,
                    expiryDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(metadata?.expiryDate))
                }
            }
        case AdminNotificationTypes.HUNDRED_PERCENTAGE_PROMO_FOR_UPGRADE:
            return {
                contentfulMessageId: 'invalid100PercentPromoCodeForUpgrade',
                severity: 'error',
                properties: {
                    promoCode: metadata.promoCode
                }
            }
        case AdminNotificationTypes.ALREADY_CANCELLED_SUBSCRIPTION:
            return {
                contentfulMessageId: hasNoKnownActiveAAAMembership ? 'cancelledSubscriptionAndHasNoActiveMembership' : 'alreadyCancelledSubscription',
                severity: 'error',
                hideCancelIcon: true,
                properties: {
                    expiryDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(metadata?.expiryDate))
                },
            }
         case AdminNotificationTypes.SUBSCRIPTION_DEACTIVATED_PAID_USER:
                return {
                    contentfulMessageId: 'deactivateUsersNotification',
                    severity: 'error',
                    hideCancelIcon: true,
                    properties: {
                    expiryDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(metadata?.expiryDate))
                    },
                    }
            case AdminNotificationTypes.SUBSCRIPTION_DEACTIVATED_FREE_USER:
                return {
                    contentfulMessageId: 'subscriptionDeactivateForFreeUser',
                    severity: 'error',
                    hideCancelIcon: true,
                    properties: {
                    expiryDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(metadata?.expiryDate))
                    },
                    }
        case AdminNotificationTypes.ALREADY_CANCELLED_SUBSCRIPTION:
            return {
                contentfulMessageId: hasNoKnownActiveAAAMembership ? 'cancelledSubscriptionAndHasNoActiveMembership' : 'alreadyCancelledSubscription',
                severity: 'error',
                hideCancelIcon: true,
                properties: {
                expiryDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(metadata?.expiryDate))
                },
            }
        case AdminNotificationTypes.ADD_EMAIL_ADDRESS:
            return {
                contentfulMessageId: 'addEmailAddress',
                severity: 'info',
                hideCancelIcon: true,
                link: {
                    text: 'Add E-mail Address',
                    url: '/admin/edit-login-and-personal-details',
                    overrideStyles: { color: colors.darkBlue },
                }
            }
        case AdminNotificationTypes.VERIFY_EMAIL_ADDRESS:
            return {
                contentfulMessageId: 'verifyEmailAddress',
                severity: 'error',
                hideCancelIcon: true,
                callToAction: {
                    text: 'Send Another Confirmation E-mail to ' + metadata?.email,
                    url: `/api/users/${metadata?.aaaLoginIdentifier}/send-verify-email`,
                    email: metadata?.email
                }
            }
        case AdminNotificationTypes.INVAID_PROMO_ERROR:
            return {
                contentfulMessageId: 'invalidPromoCodeError',
                severity: 'error',
                properties: {
                    promoCode: metadata.promoCode
                }
            }
        case AdminNotificationTypes.PROMO_CODE_SUCCESS:
            return {
                contentfulMessageId: 'promoCodeSuccess',
                severity: 'success',
                properties: {
                    promoCode: metadata.promoCode,
                    promoCodeDescription: metadata.campaign
                }
            }
        case AdminNotificationTypes.FREE_TRIAL:
            return {
                contentfulMessageId: metadata?.upcomingCoverage === BASIC ? 'basicDowngradeFreeTrialGracePeriod' : 'freeTrial',
                severity: 'info',
                properties: {
                    freeTrialEndDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(metadata?.freeTrialEndDate)),
                    nextPaymentAmount: formatCurrency(metadata?.planRate),
                    planType: metadata?.planType,
                    productName: capitalizeFirstLetter(metadata?.productType)
                },
                hideCancelIcon: true
            }
        case AdminNotificationTypes.INVALID_MEMBERSHIP_NUMBER:
            return {
                contentfulMessageId: 'invalidMembershipNumberText',
                severity: 'error',
                hideCancelIcon: true,
                link: {
                    text: 'Update Account with Valid AAA Membership Number',
                    url: '/admin/edit-login-and-personal-details'
                },
            }
        case AdminNotificationTypes.NO_SUBSCRIPTION_CANCELLED_MEMBERSHIP_NUMBER:
            return {
                contentfulMessageId:'invalidOrCancelledMembership',
                severity: 'error',
                properties: {
                    membershipStatus: metadata?.membershipStatus.toLowerCase(),
                    membershipRenew : metadata?.membershipStatus === CANCELLED ? 'renew' : 'add a valid' 
                }
            }
        case AdminNotificationTypes.BASIC_SUBSCRIPTION_CANCELLED_GRACE_MEMBERSHIP_NUMBER:
            return {
                contentfulMessageId: 'gracePeriodWithBasicSubscriptionandCancelledMem',
                severity: 'error',
                properties: {
                    gracePeriodEndDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(metadata?.basicSubscriptionGracePeriodEndDate))  
                }
            }  
        case AdminNotificationTypes.GRACE_PERIOD_WITH_PAID_SUBSCRIPTION_AND_CANCELLED_MEMBERSHIP:
            return {
                contentfulMessageId: 'gracePeriodWithPaidSubscriptionAndCancelledMem',
                severity: 'error',
                properties: {
                    termEndDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(metadata?.termEndDate))
                }
            }  
        default:
            return {}
    }
};

export { AdminNotificationTypes, adminMappedNotification };