import { capitalizeFirstLetter, formatCurrency, formatDateMMDDYYYY, removeLeadingZerosForSingleDigitDate } from "../common/Helpers";

const NotificationTypes = {
    PAYMENT_ABOUT_TO_EXPIRED: 'PAYMENT_ABOUT_TO_EXPIRED',
    PAYMENT_EXPIRED: 'PAYMENT_EXPIRED',
    PAYMENT_UPDATE_SUCCESS: 'PAYMENT_UPDATE_SUCCESS',
    PAID_OUTSTANDING_BALANCE: 'PAID_OUTSTANDING_BALANCE',
    PAYMENT_PAST_DUE: 'PAYMENT_PAST_DUE',
    PAID_OUTSTANDING_BALANCE_UPGRADE: 'PAID_OUTSTANDING_BALANCE_UPGRADE',
    PERSONAL_INFORMATION_UPDATE: 'PERSONAL_INFORMATION_UPDATE',
    UPCOMING_CANCELLED_SUBSCRIPTION: 'UPCOMING_CANCELLED_SUBSCRIPTION',
    DOWNGRADE_GRACE_PERIOD_EFFECT: 'DOWNGRADE_GRACE_PERIOD_EFFECT',
    INVAID_PROMO_ERROR: 'INVAID_PROMO_ERROR',
    PROMO_CODE_SUCCESS: 'PROMO_CODE_SUCCESS',
    ACCOUNT_SETUP_COMPLETE: 'ACCOUNT_SETUP_COMPLETE',
    INVALID_PAYMENT_METHOD: 'INVALID_PAYMENT_METHOD',
    FREE_TRIAL: 'FREE_TRIAL',
    HUNDRED_PERCENTAGE_PROMO_FOR_UPGRADE: 'HUNDRED_PERCENTAGE_PROMO_FOR_UPGRADE',
    BASIC_SUBSCRIPTION_CANCELLED_GRACE_MEMBERSHIP_NUMBER: 'BASIC_SUBSCRIPTION_CANCELLED_GRACE_MEMBERSHIP_NUMBER',
    INVALID_MEMBERSHIP_NUMBER: 'INVALID_MEMBERSHIP_NUMBER',
    GRACE_PERIOD_WITH_PAID_SUBSCRIPTION_AND_CANCELLED_MEMBERSHIP: 'GRACE_PERIOD_WITH_PAID_SUBSCRIPTION_AND_CANCELLED_MEMBERSHIP'
}

const mappedNotification = (notificationType, metadata = {}, additionalProperties = {}) => {
    switch (notificationType) {
        case NotificationTypes.PAYMENT_ABOUT_TO_EXPIRED:
            return {
                contentfulMessageId: 'paymentMethodAboutToExpire',
                severity: 'error',
                link: {
                    text: 'Update Payment Method',
                    url: '/update-payment'
                },
                properties: {
                    creditCardType: metadata.creditCardType,
                    creditCardNumber: metadata?.creditCardNumber?.substr(metadata?.creditCardNumber.length - 4),
                    creditCardExpiryDate: metadata.expirationMonth + '/' + metadata.expirationYear
                }
            };
        case NotificationTypes.PAYMENT_EXPIRED:
            return {
                contentfulMessageId: 'paymentMethodExpired',
                severity: 'error',
                properties: {
                    creditCardExpiryDate: metadata.expirationMonth + '/' + metadata.expirationYear
                }
            };
        case NotificationTypes.PAYMENT_UPDATE_SUCCESS:
            return {
                contentfulMessageId: 'paymentMethodUpdateSuccess',
                severity: 'success'
            }
        case NotificationTypes.FREE_TRIAL:
            return {
                contentfulMessageId: 'freeTrial',
                severity: 'info',
                properties: {
                    freeTrialEndDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(metadata?.freeTrialEndDate)),
                    nextPaymentAmount: formatCurrency(metadata?.planRate),
                    planType: metadata?.planType
                },
                hideCancelIcon: true
            }
        case NotificationTypes.PAID_OUTSTANDING_BALANCE:
            return {
                contentfulMessageId: 'paidOutstandingBalance',
                severity: 'success',
            }
        case NotificationTypes.PAYMENT_PAST_DUE:
            return {
                contentfulMessageId: 'pastDueOutstandingBalance',
                severity: 'error',
                link: {
                    text: 'Update Payment Method and Pay Balance',
                    url: '/pay-outstanding-balance?notification=true'
                },
                properties: {
                    productType: capitalizeFirstLetter(metadata.productType)
                }
            }
        case NotificationTypes.PAID_OUTSTANDING_BALANCE_UPGRADE:
            return {
                contentfulMessageId: 'paidOutstandingBalanceUpgrade',
                severity: 'success',
            }
        case NotificationTypes.PERSONAL_INFORMATION_UPDATE:
            return {
                contentfulMessageId: 'personalInformationUpdate',
                severity: 'success'
            }
        case NotificationTypes.UPCOMING_CANCELLED_SUBSCRIPTION:
            return {
                contentfulMessageId: 'cancelledSubscriptionMembershipExpirationInEffect',
                severity: 'info',
                hideCancelIcon: true,
                properties: {
                    currentCoverage: metadata?.cancelledCoverage,
                    expiryDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(metadata?.expiryDate))
                }
            }
        case NotificationTypes.DOWNGRADE_GRACE_PERIOD_EFFECT:
            return {
                contentfulMessageId: 'downgradeGracePeriodEffect',
                severity: 'info',
                dateOn: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(metadata?.subscriptionChangeDate)),
                properties: {
                    currentCoverage: metadata?.currentCoverage,
                    upcomingCoverage: metadata?.upcomingCoverage
                }
            }
        case NotificationTypes.INVAID_PROMO_ERROR:
            return {
                contentfulMessageId: 'invalidPromoCodeError',
                severity: 'error',
                properties: {
                    promoCode: metadata.promoCode
                }
            }
        case NotificationTypes.HUNDRED_PERCENTAGE_PROMO_FOR_UPGRADE:
            return {
                contentfulMessageId: 'invalid100PercentPromoCodeForUpgrade',
                severity: 'error',
                properties: {
                    promoCode: metadata.promoCode
                }
            }
        case NotificationTypes.PROMO_CODE_SUCCESS:
            return {
                contentfulMessageId: 'promoCodeSuccess',
                severity: 'success',
                properties: {
                    promoCode: metadata.promoCode,
                    promoCodeDescription: metadata.campaign
                }
            }
        case NotificationTypes.ACCOUNT_SETUP_COMPLETE:
            return {
                contentfulMessageId: 'accountSetupComplete',
                severity: 'success',
            }
        case NotificationTypes.INVALID_PAYMENT_METHOD:
            return {
                contentfulMessageId: 'invalidPaymentMethod',
                severity: 'error',
                hideCancelIcon: true
            }
        case NotificationTypes.BASIC_SUBSCRIPTION_CANCELLED_GRACE_MEMBERSHIP_NUMBER:
                return {
                    contentfulMessageId: 'gracePeriodWithBasicSubscriptionandCancelledMem',
                    severity: 'error',
                    openInNewTab: false,
                    showHyperLinkInNotification: true,
                    properties: {
                        membershipExpiryDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(metadata?.membershipExpiryDate))  
                    }
            }    
        case NotificationTypes.INVALID_MEMBERSHIP_NUMBER:
            return {
                contentfulMessageId: 'invalidMembershipWithBasicSubscription',
                severity: 'error',
                openInNewTab: false,
                showHyperLinkInNotification: true,
            }
        case NotificationTypes.GRACE_PERIOD_WITH_PAID_SUBSCRIPTION_AND_CANCELLED_MEMBERSHIP:
            return {
                contentfulMessageId: 'gracePeriodWithPaidSubscriptionAndCancelledMem',
                severity: 'error',
                showHyperLinkInNotification: true,
                openInNewTab: false,
                properties: {
                    nonMemberPrice: metadata.nonMemberPrice,
                    termEndDate: removeLeadingZerosForSingleDigitDate(formatDateMMDDYYYY(metadata.termEndDate)),
                    planType: metadata.planType
                }
            }
        default:
            return {}
    }
};

export { NotificationTypes, mappedNotification };