import { Component } from "react";
import { AdminApi, ContentfulService } from "../../configuration/ApplicationContext";
import StringPlaceholderService from "../../services/StringPlaceholderService";
import { NON_AAA_MEMBER } from "../common/Constants";
export default class BaseAdminBundleSignup extends Component {
    loadContent = async (contentfulId) => {
        const response = await ContentfulService.getEntry(contentfulId);
        const email = new URLSearchParams(this.props.location.search).get('email');
        const productId = this.props.match.params.id;
        const properties = {
            email
        };
        response.fields.description.content.forEach(ct => {
            ct.content.forEach(pr => {
                if (pr.value) {
                    pr.value = StringPlaceholderService.replace(pr.value, properties)
                }
            })
        })
        this.setState({ productId, email, content: response.fields });
    }

    onSubmit = async (data) => {
        const subscriptionApplicationId = this.props.match.params.subscriptionApplicationId;
        const userType = this.props.match.params.userType;
        const completeAnnualRatePlanId = this.context.ratePlanIds.complete.annualRatePlanId;
        this.setState({
            apiErrors: [],
            isSubmitDisabled: true
        });
        if (Object.keys(data.errors).length > 0) {
            this.setState({ isSubmitDisabled: false });
            return;
        }
        const { email } = data.values;
        const response = await AdminApi.patch(`/api/bundle/subscription-applications/${subscriptionApplicationId}/personal-information/${email}`, {});
        if (!response) {
            this.setState({
                isSubmitDisabled: false
            });
            return;
        }
        if (response.validationErrors) {
            const apiErrors = response.validationErrors.map(apiError => {
                return apiError.message
            });
            this.setState({
                apiErrors,
                isLoading: false,
            });
            return;
        }
        userType === NON_AAA_MEMBER
            ? this.props.history.push(`/admin/${userType}/checkout/product/${completeAnnualRatePlanId}`)
            : this.props.history.push(`/admin/bundle/${subscriptionApplicationId}/choose-plan`);
    }

    setBreadCrumb = (page) => {
        const subscriptionApplicationId = this.props.match.params.subscriptionApplicationId;
        const userType = this.props.match.params.userType;
        const email = new URLSearchParams(this.props.location.search).get('email');
        const personalInfoUrl = userType === NON_AAA_MEMBER
            ? `/admin/${userType}/personal-information`
            : '/admin/bundle/personal-info';
        const currentPageUrl = userType === NON_AAA_MEMBER
            ? `/admin/${userType}/checkout/${subscriptionApplicationId}/${page}?email=${email}`
            : `/admin/bundle/${subscriptionApplicationId}/${page}?email=${email}`;
        const breadCrumbRoutes = [
            {
                to: personalInfoUrl, label: 'New AAA Digital + IDC Sign Up 1: Personal Info'
            },
            {
                to: currentPageUrl, label: 'Email Issue'
            }
        ];
        this.setState({ breadCrumbRoutes });
    }

    getSearchResults = async () => {
        this.setState({ isSearching: true });
        const { email } = this.state || {};
        if (!email) {
            return;
        }
        const response = await AdminApi.get(`/api/users?keyword=${email}`);
        this.setState({ isSearching: false });
        if (response) {
            if (response.length === 1) {
                const result = response[0];
                this.context.updateState({ memberDetails: null, searchResult: result });
                localStorage.setItem('searchResult', JSON.stringify(result));
                await this.context.getMemberDetails();
            }
            response.length > 1 ? this.props.history.replace(`/admin/search/results?keyword=${email}`) : this.props.history.replace('/admin/member-account');
            return;
        }
    }
}