import React from 'react';
import moment from 'moment';
import Currency from '../common/Currency';
import { colors } from '../../styles/common';

export function updatePaymentSummaryItems(data) {
    const { actualPrice, coverageThrough } = data || {};
    return {
        nextBilingData: [
            {
                key: 'Next Payment Date',
                value: moment(coverageThrough)?.format('M/D/YYYY'),
            },
            {
                key: 'Next Payment',
                value: <><Currency value={actualPrice?.total} />*</>,
            }
        ],
        outstandingBalanceSection: [
            { 
                key: 'Due Date',
                value: data?.dueDate ? moment(data?.dueDate)?.format('M/D/YYYY') : 'N/A',
                style: {color: `${colors.red}`, fontWeight: 'bold'}
            },
            { 
                key: 'Balance Due',
                value: <Currency value={data?.outstandingBalance}/>,
                style: {color: `${colors.red}`, fontWeight: 'bold'}
            }
        ]
    };
}