import React, { useState } from "react";
import { mobileOnly, colors } from "../../styles/common";
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from "styled-components";
import Section from "../elements/Section";
import Link from "../elements/Link";
import Button from "../elements/Button";
import AnchorLink from "../elements/AnchorLink";
import { COMPARE_PLANS } from '../common/Constants'

const MobileMenuItemContainer = styled.div`
    ${mobileOnly}
    width: 60%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    align-items: center;
`;

const MenuList = styled.div`
    position: absolute;
    top: ${props => props.top};
    z-index: 2;
    background: ${colors.pureWhite};
    width: 100%;
    height: auto;
    margin-right: -15px;
    box-shadow: 0 2px 10px 0 #dedede;

`;
const Menu = styled.div`
    padding: 0px 15px;
`;

const mobileMenuStyle = {
    padding: '15px 0px',
    color: `${colors.darkBlue}`,
    fontSize: '18px',
    marginLeft: '30px',
    lineHeight: '24px',
    cursor: 'pointer',
    fontWeight: 'normal'
};

const iconStyle = {
    marginBottom: '0.65rem',
    marginRight: '0.65rem',
    width: '1.6rem',
    height: '1.6rem'
};

const mobileHeaderButtonStyle = {
    marginBottom: '20px',
    fontSize: '19px',
    background: `${colors.darkBlue}`,
    width: 'auto',
    padding: '8px 40px',
    marginLeft: '30px',
    borderRadius: '10px',
    color: `${colors.pureWhite}`
}

const MobileMenuItem = ({ onSignup, onLogin, isLoggedIn, onLogout, name, subscription, isSignupFlow, onPlanDetails, mobileHeaderItems, ...props }) => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const onMenuItemClick = (linkName) => {
        if (linkName === COMPARE_PLANS) {
            onPlanDetails();
        } 
    }
    return (
        <MobileMenuItemContainer>
            {
                !isSignupFlow &&
                <FontAwesomeIcon
                    style={iconStyle}
                    icon={faBars}
                    onClick={() => setIsMenuOpened(!isMenuOpened)}
                />
            }
            {
                isMenuOpened &&
                <MenuList
                    top={props.promoCodeName ? '165px' : '60px'}>
                    <Menu>
                        {
                            !isLoggedIn &&
                            <>
                                {
                                    !isSignupFlow &&
                                    <>
                                        {
                                            mobileHeaderItems.map(item => <Link
                                                key={item.displayName}
                                                text={item.displayName}
                                                style={mobileMenuStyle}
                                                to={item.linkTo}
                                                onClick={() => onMenuItemClick(item.displayName)}
                                            />)
                                        }
                                        {/* <Button
                                            style={mobileHeaderButtonStyle}
                                            text="Enroll Now"
                                            onClick={onSignup}
                                        /> */}
                                        <Button
                                        style={mobileHeaderButtonStyle}
                                        text ="Sign In"
                                        onClick={onLogin}
                                        />
                                    </>
                                }
                            </>
                        }
                        {
                            !subscription && isLoggedIn &&
                            <>
                                {
                                    !isSignupFlow &&
                                    <>
                                        <Link
                                            text={COMPARE_PLANS}
                                            style={mobileMenuStyle}
                                            to='/plan-details'
                                            onClick={onPlanDetails}
                                        />
                                        <Link
                                            text='My Dashboard'
                                            style={mobileMenuStyle}
                                            to='/my-account'
                                        />
                                        {/* <Link
                                            text='Contact Us'
                                            style={mobileMenuStyle}
                                            to='/contact-us'
                                        /> */}
                                        <Link
                                            text='FAQs'
                                            style={mobileMenuStyle}
                                            to='/faqs'
                                        />
                                        <Link
                                            text='Logout'
                                            style={mobileMenuStyle}
                                            onClick={onLogout}
                                        />
                                    </>
                                }
                            </>
                        }
                        {
                            subscription && isLoggedIn &&
                            <>
                                {
                                    props.showMyAccountOnHeader
                                        ? <Link
                                            text="My Account"
                                            style={mobileMenuStyle}
                                            to='/my-account'
                                        />
                                        : <AnchorLink
                                            text="MY ACCOUNT"
                                            style={{...mobileMenuStyle,  color:'#000000', fontWeight: 600 }}
                                            href={process.env.REACT_APP_EXPERIAN_SSO_URL}
                                        />

                                }
                                <Link
                                    text='LOG OUT'
                                    style={{...mobileMenuStyle,  color:' #5F6B77', fontWeight: 600 }}
                                    to='#'
                                    onClick={onLogout}
                                />
                            </>
                        }
                    </Menu>
                </MenuList>
            }
        </MobileMenuItemContainer>
    );
};

export default MobileMenuItem;
