import React, { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors, desktop } from '../../styles/common';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import TabHeader from './TabHeader';
import Section from '../elements/Section';
import Button from '../elements/Button';
import PlanDetailData from './PlanDetailData';
import { capitalizeFirstLetter } from '../common/Helpers';
import SubmitButton from '../elements/SubmitButton';
import ParagraphText from '../elements/ParagraphText'
import { variation } from '../../configuration/ApplicationContext';
import { BASIC } from '../common/Constants';

const TabsSection = styled.div`
    background: ${colors.pureWhite};
    padding-top: 30px;
    ${desktop`
        display: none;
    `};
`;
const MobileToggleButtonSection = styled.div`
    display: flex;
    justify-content: center;
    ${desktop`
    display: none;
`};
`;
const ToggleContainer = styled.div`
    border-radius: 49px;
    background: ${colors.lightGrey};
    margin-bottom: 20px;
    width: 90%;
    padding:8px;
`;
const ToggleButton = styled.button`
    border: none;
    ${({ active }) => active ? `
    background:  ${colors.darkBlue};
    color:${colors.pureWhite}
    `: `
    background:none;
    color:${colors.pureBlack}
    font-weight: 600;
    `}
`
const ButtonStyle = {
    fontSize: '18px',
    borderRadius: '100px',
    display: 'inline-block',
    padding: '1.1rem 0.5rem',
    width: '50%',
    border: 'none',
}
const tabStyle = {
    flexGrow: 1,
    marginTop: '-1px'
};

let tabListStyle = {
    display: 'flex',
    marginBottom: 'unset',
}

const infoTextStyle = {
    paddingTop: '0.5rem',
    paddingBottom: '1.5rem',
    margin: '0 auto',
    textAlign: 'center',
    fontSize: '10px'
}

const discountTextStyle = {
    fontSize: "10px", 
    margin: '0px'
}

const insuranceText = {
    fontWeight: 400,
    fontSize: '11px',
    textAlign: 'center',
    color: colors.pureWhite
}

const insuranceDetailText = {
    fontSize: '14px',
    textAlign: 'center',
}

const buttonStyle = {
    margin: '0 auto',
    backgroundColor: colors.darkBlue,
    color: colors.pureWhite,
    maxWidth: '320px',
    fontSize: '1.19em'
}
const amountStyle = {
    fontWeight: 'normal',
    fontSize: '40px',
    lineHeight: '35px',
    textAlign: 'center'
}
const amountPeriodStyle = {
    fontSize: '1.25em',
    textAlign: 'center'
}
const paragraphStyle = {
    fontSize: "12px",
    textAlign: 'center',
    padding: '0px 15px 40px'
}
const margin = {
    marginTop: '2rem',
    marginBottom: '2rem'
}

const SectionStyle = styled.section`
    font-size: 15px;
    ${props => props.styles && css`
        styles: ${props.styles};
    `}
`;

const DescriptionStyle = styled.div`
    display: block;
    padding: 0.5rem 0rem;
`;

const SpanStyle = styled.span`
    font-size: 20px;
    font-weight: 500;
    ${props => props.styles && css`
        styles: ${props.styles};
    `}
`;

const filterSectionsBasedOnPlanType = (sections, planType = null) => {
    let backgroundColor = '';
    if (planType === 'basic') {
        backgroundColor = colors.mediumBlue;
        sections = sections.filter(section => section.fields.title !== 'Basic');
    } else if (planType === 'protect') {
        backgroundColor = colors.darkBlue;
        sections = sections.filter(section => section.fields.title === 'Complete');
    } else {
        backgroundColor = colors.mediumBlue;
    }
    return {
        backgroundColor,
        sections
    }
}

const getColorBasedOnTitle = (title = null) => {
    let backgroundColor = '';
    if (title === 'Basic') {
        backgroundColor = colors.skyBlue;
    } else if (title === 'Protect') {
        backgroundColor = colors.mediumBlue;
    } else {
        backgroundColor = colors.darkBlue;
    }
    return backgroundColor;
}

const freeTrialTextStyle = {textAlign: 'center'};

const PlanDetailsTabbedView = ({ sections, onSignup, planType, redirectToUpgradeFlow, onMobileToggleChange, onChooseProduct, onSelectProductTab= () => {}, paymentMode, isSubmitDisabled, defaultIndex = 1, isLandingPage = false, isAAAIdcFlow = false, backgroundColor, isUpgradeFlow = false, showBasicPlanMessage = false, ...props }) => {
    const filteredData = filterSectionsBasedOnPlanType(sections, planType);
    sections = filteredData.sections;
    const [backgroundColorText, setBackgroundColorText] = useState({ backgroundColor: isAAAIdcFlow ? colors.skyBlue : (isUpgradeFlow ? colors.darkBlue : filteredData.backgroundColor) });
    const [amountColorText, setAmountColorText] = useState({ color: isAAAIdcFlow ? colors.skyBlue : (isUpgradeFlow ? colors.darkBlue : filteredData.backgroundColor) });
    const [infoText, setInfoText] = useState(sections[1]?.fields.amountInfo || '');
    const determineTextAndBackgroundStyle = (index) => {
        const fields = sections[index].fields;
        setInfoText(fields.amountInfo || '');
        switch (fields.title) {
            case 'Basic':
                setBackgroundColorText({ background: colors.skyBlue });
                setAmountColorText({ color: colors.skyBlue });
                break;
            case 'Protect':
                setBackgroundColorText({ background: colors.mediumBlue });
                setAmountColorText({ color: colors.mediumBlue });
                break;
            case 'Complete':
                setBackgroundColorText({ background: colors.darkBlue });
                setAmountColorText({ color: colors.darkBlue });
                break;
            default:
        }
        onSelectProductTab(fields.title.toLowerCase());   
    }


    const getButtonAttributes = (planType, attributeType) => {
        const freeTrialButtonText = `${planType === BASIC ? `Choose ${planType}` : 'Start Free Trial'}`;
        const normalFlowText = `Choose ${planType}`;
        const freeTrialAltText = `${planType === BASIC ? `Choose ${planType}` : `Start Free Trial - ${planType} plan`}`;
        if (attributeType === 'text') {
            return (props.showTrialSection === true && variation.freeTrial === true) ? freeTrialButtonText : normalFlowText;
        } else if(attributeType === 'alt') {
            return (props.showTrialSection === true && variation.freeTrial === true) ? freeTrialAltText : normalFlowText;
        }
    }
    return (
        <TabsSection planType={planType}>
            <Tabs onSelect={determineTextAndBackgroundStyle} defaultIndex={defaultIndex}>
                <TabList style={{ ...tabListStyle, ...backgroundColorText }}>
                    {sections.map(section =>
                        <Fragment key={section.fields.title}>
                            <Tab key={section.fields.title} style={{ ...tabStyle, 'backgroundColor': getColorBasedOnTitle(section.fields.title) }}>
                                <TabHeader title={section.fields.title} paymentMode={paymentMode} isAAAIdcFlow={isAAAIdcFlow} planType={section.fields.title} />
                            </Tab>
                        </Fragment>
                    )}
                </TabList>
                {sections.map(section =>
                    <TabPanel key={section.fields.title} style={{ background: backgroundColor ? backgroundColor : '' }}>
                        {!isLandingPage && <Section text={`${section.fields.totalInsurance} ${section.fields.insuranceInfo ? section.fields.insuranceInfo : ''}`} style={{ ...insuranceText, ...backgroundColorText }}></Section>}
                        {isLandingPage &&
                            <>
                                {section.fields.title === BASIC &&
                                        <DescriptionStyle style={backgroundColorText}>
                                            <SectionStyle style={insuranceText}>{section.fields.totalInsurance}</SectionStyle>
                                        </DescriptionStyle>
                                }
                                {
                                    section.fields.title !== BASIC &&
                                    <>
                                        <DescriptionStyle style={backgroundColorText}>
                                            <SectionStyle style={insuranceText}>{section.fields.totalInsurance}</SectionStyle>
                                            <SectionStyle style={insuranceDetailText}>{section.fields.insuranceInfo}</SectionStyle>
                                        </DescriptionStyle>
                                        <DescriptionStyle>
                                            <SectionStyle style={insuranceText}>{section.fields.extraTotalInsurance}</SectionStyle>
                                            <SectionStyle style={insuranceDetailText}>{section.fields.extraInsuranceInfo}</SectionStyle>
                                        </DescriptionStyle>

                                    </>
                                }
                            </>
                        }
                        {isAAAIdcFlow &&
                            <>
                                {
                                    planType === 'Basic' &&
                                    <>
                                        <Section text={section.fields.amount} style={{ ...amountColorText, ...amountStyle }}></Section>
                                        <Section text={section.fields.amountPeriod} style={amountPeriodStyle}></Section>
                                    </>
                                }
                            </>
                        }
                        <>
                        { props.showTrialSection === true && variation.freeTrial === true && section.fields.title !== BASIC &&
                            <SectionStyle style={freeTrialTextStyle}>
                                <SpanStyle style={amountColorText}>Try 1 month free, </SpanStyle>
                                <SpanStyle>then</SpanStyle>
                            </SectionStyle>
                        }
                            <Section text={paymentMode === "Annually" ? section.fields.amountAnnual : section.fields.amount} style={{ ...amountColorText, ...amountStyle, paddingTop: '1rem', paddingBottom: '0.5rem' }}></Section>
                            <Section text={paymentMode === "Annually" && planType !== BASIC ? 'per year*' : section.fields.amountPeriod} style={{ ...amountPeriodStyle, paddingTop: '0px' }}></Section>
                            <SectionStyle style={infoTextStyle}>{infoText}</SectionStyle>
                        </>
                        {!isAAAIdcFlow && showBasicPlanMessage && (section.fields.title !== BASIC ?
                            <MobileToggleButtonSection>
                                <ToggleContainer>
                                    <ToggleButton className='toggle-ButtonStyle' style={ButtonStyle} active={paymentMode === 'Monthly'} onClick={() => onMobileToggleChange('Monthly')}>Pay monthly</ToggleButton>
                                    <ToggleButton className='toggle-ButtonStyle' style={{ ...ButtonStyle, float: 'right', padding: '0.6rem 0.3rem 0rem' }} active={paymentMode === 'Annually'} onClick={() => onMobileToggleChange('Annually')}>Pay yearly<ParagraphText style={{ ...discountTextStyle, color: `${paymentMode === "Monthly" ? colors.darkRed : ''}` }}>(Save 5%)</ParagraphText></ToggleButton>
                                </ToggleContainer>
                            </MobileToggleButtonSection>
                            : <ParagraphText style={paragraphStyle}>Upgrade to Protect or Complete plans for enhanced identity protection features.</ParagraphText>
                            )
                        }
                            
                       {isLandingPage && !planType && <Button style={{...buttonStyle}} title={getButtonAttributes(capitalizeFirstLetter(section.fields.title), 'alt')} text={getButtonAttributes(capitalizeFirstLetter(section.fields.title), 'text')} onClick={() => onSignup(`Home Page | ${section.fields.title} CTA Clicked`, section.fields.title)}></Button>}
                       {!isLandingPage && !isUpgradeFlow && <SubmitButton style={{...buttonStyle}} disabled={isSubmitDisabled} title={getButtonAttributes(capitalizeFirstLetter(section.fields.title), 'alt')} text={getButtonAttributes(capitalizeFirstLetter(section.fields.title), 'text')} onClick={() => onChooseProduct(section.fields.title)}/>}
                        <PlanDetailData alignText='center' data={section.fields.description} backgroundColor={backgroundColor}/>
                        {planType && <Button style={{...buttonStyle, ...margin}} text={`Upgrade to ${capitalizeFirstLetter(section.fields.title)}`} onClick={() => redirectToUpgradeFlow(section.fields.title.toLowerCase())} ></Button>}
                    </TabPanel>
                )}
            </Tabs>
        </TabsSection>
    );
};
 
export default PlanDetailsTabbedView;