import React, { Component } from "react";
import Spinner from './elements/Spinner';
import withPageTitle from "./layout/withPageTitle";
import { Api } from "../configuration/ApplicationContext";
import Question from "./Question";
import Content from "./layout/Content";
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent } from "./layout/ResponsiveSignupFlowLayout";
import QuestionFlowStepProgress from "./elements/QuestionFlowStepProgress";
import Breadcrumbs from "./common/Breadcrumbs";

class QuestionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {
    this.retrieveAndSelectQuestion(this.props.match.params.id);
    this.setBreadCrumbs(this.props.match.params.id)
  }

  componentWillReceiveProps(nextProps) {
    this.retrieveAndSelectQuestion(nextProps.match.params.id);
    this.setBreadCrumbs(nextProps.match.params.id)
  }

  setBreadCrumbs(number) {
    const breadCrumbRoutes = [
      {
          to: `/question/${number - 1}`, label: 'Back'
      }
    ];
    this.setState({
      breadCrumbRoutes,
    });
  }

  async retrieveAndSelectQuestion(number) {
    const verifyIdentityData = JSON.parse(localStorage.getItem('verifyIdentity'));
    if (!verifyIdentityData) {
      this.props.history.push('/');
      return;
    }
    let selectedQuestion;
    let selectedAnswer;
    if (number) {
      const filteredQuestionArray = verifyIdentityData.questions.filter(question => question.number === +number);
      selectedQuestion = filteredQuestionArray.length ? filteredQuestionArray[0]: null;
      const filteredAnswerArray = verifyIdentityData.answers.filter(question => question.number === +number);
      selectedAnswer = filteredAnswerArray.length ? filteredAnswerArray[0]: null;
    }
    this.setState({
      questions: verifyIdentityData.questions,
      answers: verifyIdentityData.answers,
      selectedQuestion,
      selectedAnswer,
      number,
    });
  }

  submitAnswers = async () => {
    this.setState({
      isSubmitDisabled: true
    });
    const answers = this.state.answers.map(answer => {
      return {
        QuestionNumber: answer.QuestionNumber,
        AnswerNumber: answer.AnswerNumber,
      };
    });
    const response = await Api.post('/api/users/security-questions', 
      {
        QuestionData: answers
      }
    );
    this.setState({
      isSubmitDisabled: false
    });
    if(response) {
      response.validationErrors ? this.props.history.push('/verify-identity-failure') : this.props.history.push('/verify-identity-success');
    }
  }
  
  onSelectAnswer = (number, selectedQuestioNumber, selectedAnswerNumber) => {
    
    let { answers } = this.state || {};
    answers = answers.filter(answer => answer.number !== number);
    const selectedAnswer = {
      QuestionNumber: selectedQuestioNumber,
      AnswerNumber: selectedAnswerNumber,
      number,
    };
    answers.push(selectedAnswer);
    this.setState({
      answers,
      selectedAnswer,
    });
    const verifyIdentityData = JSON.parse(localStorage.getItem('verifyIdentity'));
    verifyIdentityData.answers = answers;
    localStorage.setItem('verifyIdentity', JSON.stringify(verifyIdentityData));
  }

  onNextQuestion = (question) => {
    if (question) {
      const number = question.number + 1;
      this.retrieveAndSelectQuestion(number);
      this.props.history.push(`/question/${number}`);
    }
  }

  render() {
    const { questions, selectedAnswer, selectedQuestion, breadCrumbRoutes } = this.state || {};
    if (!questions || !selectedQuestion) {
      return <Spinner />;
    }   
    return ( 
      <Content>
        {selectedQuestion.number > 1 && <Breadcrumbs breadCrumbRoutes={breadCrumbRoutes} showOneBreadcrumb={true}></Breadcrumbs>}
        <QuestionFlowStepProgress currentStep={selectedQuestion.number} questions={questions} desktopHighlightColor={true}/>
        <ResponsiveSignupFlowLayout>
            <SignUpFlowFormContent>
                <Question onSelectAnswer={this.onSelectAnswer} question={selectedQuestion} answer={selectedAnswer} submitAnswers={this.submitAnswers} onNextQuestion={this.onNextQuestion} isLastQuestion={questions.length === selectedQuestion.number}></Question>
            </SignUpFlowFormContent>
        </ResponsiveSignupFlowLayout>
      </Content>
    );
  }
}

export default withPageTitle(QuestionsPage, "Questions");