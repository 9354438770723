import React from 'react';
import { Api, ContentfulService, segmentAnalytics } from '../../configuration/ApplicationContext';
import ContentfulIds from '../../configuration/ContentfulIds';
import RedirectionService from '../../services/RedirectionService';
import BaseCheckout from '../BaseCheckout';
import DataContext from '../contexts/DataContext';
import Spinner from '../elements/Spinner';
import Title from '../elements/Title';
import CheckoutForm from '../forms/CheckoutForm';
import Content from '../layout/Content';
import ResponsiveSignupFlowLayout, { SignUpFlowExtraContent, SignUpFlowFormContent } from '../layout/ResponsiveSignupFlowLayout';
import withPageTitle from '../layout/withPageTitle';
import Summary from '../summary/Summary';
import Breadcrumbs from '../common/Breadcrumbs';
import InfoText from '../elements/InfoText';
import Notification from '../notifications/Notification';
import { createSummaryItems } from '../summary/SummaryItems';
import UtilsService from '../../services/UtilsService';

declare var Z: any; //Zuora

const notificationStyle = { 
    marginBottom: '1rem',
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '0px', 
    marginTop: '60px'
};
class AAAIdcCheckoutPage extends BaseCheckout {
    static contextType = DataContext;

    constructor(props) {
        super(props);
        this.state = {
            zuoraConfiguration: null,
            billingAddressSameAsHomeAddress: true,
        };
    }

    async componentDidMount() {
        this.checkUserStateAndRedirect();
        this.checkProductAvailable();
        this.loadZuoraForm();
        this.loadContent();
        this.loadPersonalInformation();
        this.setBreadCrumbs();
        this.context.removePromoCodeData();
        this.trackSegmentEvent();
    }

    trackSegmentEvent = async () => {
        segmentAnalytics.track('AAA-SUF-Payment-Screen', {
            memberId: this.context.userDetails.userId
        });
    }

    checkProductAvailable() {
        if (!this.context.product) {
            this.loadPlans();
        }
    }


    checkUserStateAndRedirect() {
        const userDetails = this.context.userDetails || {};
        if (userDetails.subscription) {
            this.props.history.push(RedirectionService.getConditionalRenderURL(null, userDetails));
        }
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.AAAIdcSignupFlow.CheckoutPage);
        this.setState({ content: response.fields })
        this.setState({originalContent: JSON.parse(JSON.stringify(response.fields))})
    }

    async loadZuoraForm() {
        const zuoraConfiguration = await Api.get('/api/orders/payment-form');
        this.setState({ zuoraConfiguration })
    }

    onSubmit = async (data) => {
        segmentAnalytics.track('AAA-SUF-Payment-Submit Payment', {
            memberId: this.context.userDetails.userId
        });
        const { id, createdOn, lastModified, ...billingAddress } = this.state.personalInfo?.billingAddress || {};
        if (Object.keys(data.errors).length > 0 || !data.values.billingAddress) {
            this.setState({ checkoutError: data.errors.billingAddress });
            return;
        }
        this.setState({ checkoutError: '' });
        window.orderSuccess = async (refId) => {
            const { promoCodeRatePlanId, promoCode } = this.context.promoCodeData || {};
            this.setState({ isSubmitDisabled: true });
            const paymentMethodId = refId;
            const ratePlanId = this.context.product.id;
            const response = await Api.post('/api/orders', { ratePlanId, paymentMethodId, billingAddress, promoCodeRatePlanId, promoCode });
            if (!response) {
                return;
            }
            if (response.validationErrors) {
                await this.context.refreshContext();
                this.props.history.push('/my-account');
            } else {
                this.props.history.push('/subscription-complete?merged-aaa-suf=true');
            }
        };

        window.orderFailure = (failureMessage) => {
            this.setState({
                checkoutError: failureMessage,
                isSubmitDisabled: false,
                zuoraConfiguration: null,
            }, () => {
                this.loadZuoraForm();
            });
        };
        Z.submit();
    };

    async loadPersonalInformation() {
        const personalInfo = this.context.userDetails.personalInformation || {};
        personalInfo.billingAddress = this.context.userDetails.personalInformation.homeAddress;
        this.setState({ personalInfo });
    }

    onApplyPromoCode = async (promoCodeData) => {
        segmentAnalytics.track('AAA-SUF-Promo Code Successful', {
            eventLabel: promoCodeData.promoCode
        });
        this.setState({ promoCodeData });
        if (promoCodeData.products) {
            const product = promoCodeData.products.find(product => product.name !== 'FREE'
            && (product.annualRatePlan.id === this.props.match.params.productId
                || product.monthlyRatePlan.id === this.props.match.params.productId));
            this.selectProduct(product, this.props.match.params.productId);
        }
        const originalContent = JSON.parse(JSON.stringify(this.state.originalContent))
        this.setState({content: originalContent})
    }
    
    setBreadCrumbs = () => {
        const breadCrumbRoutes = [
          {
              to: '/choose/product', label: 'Protection Level'
          }
        ];
        this.setState({
          breadCrumbRoutes
        });
    }

    render() {
        const { zuoraConfiguration, content, checkoutError, personalInfo, isSubmitDisabled, billingAddressSameAsHomeAddress, breadCrumbRoutes } = this.state;
        const selectedProduct = this.context.product;
        const { notifications } = this.props.notificationContext;
        if (!zuoraConfiguration || !selectedProduct || !content) {
            return <Spinner />;
        }
        return (
            <>
                <InfoText style={notificationStyle} mobileOnly='true'>
                    {notifications.map(notification => {
                        return <Notification key={notification.notificationType} {...notification} {...this.props} />
                        }
                    )}
                </InfoText>
                <Content mobileStyles={{paddingTop: 0}}>
                    <InfoText style={notificationStyle} desktopOnly='true'>
                        {notifications.map(notification => {
                            return <Notification key={notification.notificationType} {...notification} {...this.props} />
                            }
                        )}
                    </InfoText>
                    <Breadcrumbs style={{marginTop: '0px !important'}} breadCrumbRoutes={breadCrumbRoutes} showOneBreadcrumb={true}></Breadcrumbs>
                    <ResponsiveSignupFlowLayout>
                        <SignUpFlowFormContent>
                            <Title style={{ marginTop: '2rem' }}>{content.title}</Title>
                            <CheckoutForm zuoraConfiguration={zuoraConfiguration} content={content} onSubmit={this.onSubmit} checkoutError={checkoutError} personalInfo={personalInfo} onSelectAddress={this.onSelectAddress} selectedProduct={selectedProduct} billingAddressSameAsHomeAddress={billingAddressSameAsHomeAddress} isSubmitDisabled={isSubmitDisabled} onApplyPromoCode={this.onApplyPromoCode} promoCodeOptions={{ validateURL: '/api/promo-codes/validate', zuoraRatePlanURL: `/api/products/zuora-rate-plans`, mapProductsFunc: UtilsService.mapProductsToArray}}/>
                        </SignUpFlowFormContent>
                        <SignUpFlowExtraContent>
                            <Summary product={selectedProduct} title={`${selectedProduct.name} Protection`}  items={createSummaryItems(selectedProduct, this.context)}></Summary>
                        </SignUpFlowExtraContent>
                    </ResponsiveSignupFlowLayout>
                </Content>
            </>
        );
    }
}

export default withPageTitle(AAAIdcCheckoutPage, "Place Order");
