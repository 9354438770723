import React from 'react';
import Content from './../../layout/Content';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from './../../layout/ResponsiveSignupFlowLayout';
import ContentfulIds from './../../../configuration/ContentfulIds';
import Spinner from './../../elements/Spinner';
import AdminContext from './../../contexts/AdminContext';
import Card from './../../elements/Card';
import BaseAdminProductOffering from './../BaseAdminProductOffering';
import AdminProductOffering from './../AdminProductOffering';
import AdminSummary from '../summary/AdminSummary';
import Breadcrumbs from '../../common/Breadcrumbs';
import ProductChart from '../../v1/product-chart/customer/ProductChart';
import { colors } from '../../../styles/common';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import DynamicParagraphText from '../../elements/DynamicParagraphText';
import { AAA_MEMBER, CANCELLED, NON_AAA } from '../../common/Constants';
import RichText from '../../elements/RichText';

const styles = {
    title: { textAlign: 'center', fontSize: '17px', fontWeight: 500 },
    hr: { backgroundColor: colors.pureBlack, marginBottom: '20px' },
    paragraph: { textAlign: 'justify', margin: 0, color: colors.verylightGrey, lineHeight: '20px', paddingTop: '1rem' }
};
const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText
    {...props}
    text={children}
    style={styles.paragraph}
/>;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) =>
            <ParagraphStyle
                anchor={true}
                desktopFontSize='12px'
            >
                {children}
            </ParagraphStyle>,
        [INLINES.HYPERLINK]: (node, next) => {
            return <a
                href={node.data.uri}>
                {node.content[0].value}
            </a>;
        }
    },
};

export default class CoverageUpgradePage extends BaseAdminProductOffering {
    static contextType = AdminContext;

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            selectedProduct: null,
            paymentMode: '',
            productPageURL: '/admin/basic/upgrade',
            checkoutPageURL: '/admin/process-payment',
            subscriptionAction: 'UPGRADE'
        };
    }

    async componentDidMount() {
        this.setBreadCrumb();
        this.loadPlans(ContentfulIds.Admin.MemberAccountIds.UpgradeBasicPlanPage);
        this.loadProductChartContent(ContentfulIds.Admin.ChooseYourPlanPage);
    }


    render() {
        const {
            paymentMode,
            selectedProduct,
            content,
            products,
            breadCrumbRoutes,
            productChartContent
        } = this.state;
        const {
            subscription,
            membership
        } = this.context.memberDetails || {};
        if (!selectedProduct || !productChartContent || !content) {
            return <Spinner />;
        }
        return (
            <Content desktopStyles={{ marginTop: '0rem' }}>
                <Breadcrumbs isCustomerFacing={false} breadCrumbRoutes={breadCrumbRoutes}></Breadcrumbs>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <AdminProductOffering products={products} selectedProduct={selectedProduct}
                            content={content} onSelectProduct={this.onSelectProduct}
                            paymentMode={paymentMode} onSelectPaymentMode={this.onSelectPaymentMode}
                            onSubmit={this.onSubmit} hideSelectProduct={false}
                            subscription={subscription}></AdminProductOffering>
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        <Card>
                            <AdminSummary product={selectedProduct} title={`If Subscriber upgrades to ${selectedProduct.name}`} />
                        </Card>
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
                <ProductChart
                    showDescription={false}
                    showTitle={false}
                    backgroundcolor={colors.pureWhite}
                    fields={productChartContent}
                    defaultMembership={membership !== null && membership?.membershipStatus !== CANCELLED ? AAA_MEMBER : NON_AAA}
                    hideToggleSection={true}
                    alreadySelectedProduct={selectedProduct.name}
                    openComparePlansLinkInNewTab={true}
                    disableCta={true}
                />
                <RichText
                    text={productChartContent.joinAaaText}
                    options={options}
                />
            </Content>
        );
    }
}
