import React from 'react';
import styled, { css } from 'styled-components';
import { colors, mobileOnly, desktopOnly, commonPropStyles } from '../../styles/common';

const SubmitInput = styled.input`
font-weight: 500;
font-size: 19px;
line-height: 25px;
text-align: center;
color: ${colors.pureWhite};
background: ${colors.darkBlue};
padding: 12px;
cursor: pointer;
min-width: 320px;
border-radius: 10px;
display: block;
margin: 5px 0;
outline: none;
border: none;
margin-top: 15px;
${props => props.disabled && css`
    opacity: 0.5; 
    cursor: default;
  `};
${props => props.mobileOnly && css`
    ${mobileOnly}
`}
${props => props.desktopOnly && css`
    ${desktopOnly}
`}
${commonPropStyles.mobile}
${commonPropStyles.desktop}
`;

const SubmitButton = ({ type, text, ...props }) => {
    return (
        <SubmitInput value={text} type="submit" {...props} />
    )
}

export default SubmitButton;
