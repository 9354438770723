import React from 'react';
import Content from '../../layout/Content';
import withPageTitle from "../../layout/withPageTitle";
import Breadcrumbs from '../../common/Breadcrumbs';
import Spinner from '../../elements/Spinner';
import AdminContext from '../../contexts/AdminContext';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent } from '../../layout/ResponsiveSignupFlowLayout';
import Title from '../../elements/Title';
import ChooseYourBundleSection from '../../bundle/ChooseYourBundleSection'
import AdminBaseBundle from './AdminBaseBundle';

const styles = {
    content: { marginLeft: '40px', marginRight: '0px', marginTop: '0px !important' },
    title: { marginTop: '1.5rem', marginBottom: '1.5rem' }
}

class AdminChooseBundlePlanPage extends AdminBaseBundle {
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            personalInfoPageURL: '/admin/bundle/personal-info',
            chooseProductPageURL: `/admin/bundle/${this.props.match.params.subscriptionApplicationId}/choose-plan`,
            validateExistingAccount: false
        }
    }

    componentDidMount() {
        this.validateAddressAndExistingAccountAndRedirect();
        this.setBreadCrumb();
    }

    setBreadCrumb = () => {
        const breadCrumbRoutes = [
            {
                to: `${this.state.personalInfoPageURL}`, label: 'New AAA Digital + IDC Signup 1: Personal Info'
            },
            {
                to: `${this.state.chooseProductPageURL}`, label: '2: Choose Plan'
            }
        ]
        this.setState({ breadCrumbRoutes });
    }

    render() {
        const { breadCrumbRoutes, validateExistingAccount } = this.state || {};
        if (!breadCrumbRoutes || !validateExistingAccount) {
            return <Spinner />;
        }
        return (
            <Content
                desktopStyles={styles.content}>
                <Breadcrumbs
                    breadCrumbRoutes={breadCrumbRoutes}
                    isCustomerFacing={false}
                />
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title
                            style={styles.title}>
                            Sign Up 2: Choose Plan
                        </Title>
                        <ChooseYourBundleSection
                            subscriptionApplicationId={this.props.match.params?.subscriptionApplicationId}
                        />
                    </SignUpFlowFormContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageTitle(AdminChooseBundlePlanPage, "Admin Choose Bundle Plan");