import { Component } from 'react';
import { AdminApi } from '../../../configuration/ApplicationContext';
import UtilsService from '../../../services/UtilsService';
import { CANCELLED_IDC_USER, IDC_USER, INSIDE_MWG, NON_BUNDLE_USER, OFFLINE_USER, OUTSIDE_MWG } from '../../common/Constants';
import { validateInputAddress } from '../../common/Helpers';
export default class AdminBaseBundle extends Component {
    loadProductsAndSelectProduct = async () => {
        const ratePlanId = this.context.getBundleRatePlanIdBasedOnPlan(this.props.match.params.id);
        let zuoraRatePlansURL = '/api/bundle/products/zuora-rate-plans';
        const ratePlanResponse = await AdminApi.get(zuoraRatePlansURL);
        const mappedProducts = UtilsService.mapBundleProductsToArray(ratePlanResponse.products);
        const foundProduct = mappedProducts.find(product => product.id === ratePlanId);
        const selectedProduct = foundProduct ? foundProduct : {};
        this.context.updateState({ product: selectedProduct });
        this.setState({
            selectedProduct,
        });
    }

    loadPersonalInformation() {
        const personalInfoData = JSON.parse(localStorage.getItem('searchResult'));
        const personalInfo = this.context.memberDetails?.personalInformation || personalInfoData;
        personalInfo.billingAddress = personalInfo.billingAddress ? personalInfo.billingAddress : personalInfo.homeAddress;
        this.setState({
            personalInfo,
        });
    }

    onSelectAddress = (address) => {
        const personalInfo = this.state.personalInfo;
        personalInfo.billingAddress = address
        if (!validateInputAddress(address)) {
            this.setState({
                personalInfo,
                checkoutError: "Please enter valid billing address"
            });
        } else {
            this.setState({
                personalInfo,
                checkoutError: ''
            });
        }
    }

    setRedirectionMapUrls = (email, key) => {
        const subscriptionApplicationId = this.props.match.params.subscriptionApplicationId;
        const redirectionMap = new Map()
            .set(OUTSIDE_MWG, `/admin/bundle/outside_mwg`,)
            .set(INSIDE_MWG, `/admin/bundle/inside_mwg`,)
            .set(OFFLINE_USER, `/admin/bundle/${subscriptionApplicationId}/account-already-exist?email=${email}`,)
            .set(IDC_USER, `/admin/bundle/${subscriptionApplicationId}/idc-account-already-exist?email=${email}`,)
            .set(NON_BUNDLE_USER, `/admin/bundle/${subscriptionApplicationId}/aaa-membership-exist?email=${email}`)
            .set(CANCELLED_IDC_USER, `/admin/bundle/${subscriptionApplicationId}/cancelled-idc-account-exist?email=${email}`)
        return redirectionMap.get(key);
    }

    validateAddressAndExistingAccountAndRedirect = async () => {
        const subscriptionApplicationId = this.props.match.params.subscriptionApplicationId;
        const updatedPersonalInformation = await AdminApi.get(`/api/bundle/subscription-applications/${subscriptionApplicationId}`);
        if (!updatedPersonalInformation) {
            this.setState({ validateExistingAccount: true });
            return;
        }
        const email = updatedPersonalInformation.personalInformation.email;
        const response = await AdminApi.get(`/api/bundle/subscription-applications/${subscriptionApplicationId}/validate?validateZipCodeAddress=true`);
        if (!response) {
            this.setState({ validateExistingAccount: true });
            return;
        }
        if (response.validationErrors) {
            response.validationErrors.forEach(apiError => {
                const redirectTo = this.setRedirectionMapUrls(email, apiError.property);
                if (redirectTo) {
                    this.props.history.push(redirectTo);
                } else {
                    this.setState({ validateExistingAccount: true });
                }
            });
        } else {
            this.context.updateState({ aaaLoginIdentifier: response.aaaLoginIdentifier });
            this.setState({ validateExistingAccount: true });
        }
    }

}