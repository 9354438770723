import React from 'react';
import BreadcrumbItem from './BreadcrumbItem';
import styled, { css } from 'styled-components';
import { colors } from '../../styles/common';
import { desktopOnly, mobileOnly } from '../../styles/common';

const OrderedList = styled.ol`
    list-style: none;
    display: flex;
    align-items: center;
    padding-left: unset;
    margin-top: 0px;
    padding: 0 60px;
    max-width: 1440px;
    margin: 0 auto;
    padding-bottom: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    z-index: 25;
    border-bottom: 1px solid ${colors.mediumGrey};
    ${props => props.isCustomerFacing === false && css`
        padding: 0px;
        border-bottom: none;
        margin-bottom: 1rem;
    `}
    ${props => props.mobileOnly && css`
        padding: 0 15px;
        background: ${colors.pureWhite};
        padding-bottom: 0.5rem;
        ${mobileOnly}
    `}
    ${props => props.desktopOnly && css`
        ${desktopOnly}
    `}
    ${props => props.showOneBreadcrumb && css `
        padding-left: unset;
        border-bottom: none;
    `}
`;

const Breadcrumb = (props) => {
    let children = React.Children.toArray(props.children)

    children = children.map((child, index) => (
        <BreadcrumbItem key={`breadcrumb_item${index}`}>{child}</BreadcrumbItem>
    ))

    const lastIndex = children.length - 1

    return <>
        <OrderedList desktopOnly='true' isCustomerFacing={props.isCustomerFacing} showOneBreadcrumb={props.showOneBreadcrumb}>
            {children.reduce((acc, child, index) => {
                const notLast = index < lastIndex
                if (notLast) {
                    acc.push(child, '>')
                } else if(props.showOneBreadcrumb) {
                    acc.push('<', child)
                } else {
                    acc.push(child)
                }
                return acc

            }, [])}
        </OrderedList>
        <OrderedList mobileOnly='true' showOneBreadcrumb={props.showOneBreadcrumb}>
            {children.reduce((acc, child, index) => {
                const previousLink = index === lastIndex - 1;
                if (previousLink || props.showOneBreadcrumb) {
                    acc.push('<', child)
                }
                return acc
            }, [])
            }
        </OrderedList>
    </>
}

export default Breadcrumb