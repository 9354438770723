import { Component } from 'react';
import { mappedNotification } from '../notifications/Notifications';
import { adminMappedNotification } from '../notifications/AdminNotifications';
import StringPlaceholderService from '../../services/StringPlaceholderService';
import { isValueString } from '../common/Helpers';
class BaseNotificationProvider extends Component {
    addNotification = async (notificationType, metadata = {}) => {
        let notifications = this.state.notifications.filter(notification => {
            return notification.notificationType !== notificationType;
        });
        this.setState({
            notifications: [...notifications, this.buildNotification(notificationType, metadata, this.state.isAdmin || false)],
        })
    }

    clearNotifications() {
        this.setState({
            notifications: []
        });
    }

    removeNotification = (notificationType) => {
        let notifications = this.state.notifications.filter(notification => {
            return notification.notificationType !== notificationType;
        });
        this.setState({
            notifications: [...notifications],
        })
    }

    buildNotification = (notificationType, metadata = {}, isAdmin = false, additionalProperties = {}) => {
        const { contentfulMessageId, severity, link, properties, hideCancelIcon, dateOn, callToAction, showHyperLinkInNotification = false, openInNewTab = true } = isAdmin ? adminMappedNotification(notificationType, metadata, additionalProperties) || {} : mappedNotification(notificationType, metadata, additionalProperties) || {};
        let message = JSON.parse(JSON.stringify(this.state.content[contentfulMessageId]));
        if (properties) {
            if (isValueString(message)) {
                message = StringPlaceholderService.replace(message, properties);
            } else {
                message && message.content.forEach(ct => {
                    ct.content.forEach(pr => {
                        if (pr.value) {
                            pr.value = StringPlaceholderService.replace(pr.value, properties)
                        }
                    })
                });
            }
        }
        return {
            showNotification: true,
            severity,
            link,
            message,
            notificationType: notificationType,
            properties,
            hideCancelIcon,
            dateOn,
            callToAction,
            showHyperLinkInNotification,
            openInNewTab
        };
    }
}

export default BaseNotificationProvider;