import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { colors, desktop } from '../../../../styles/common';
import TabHeader from '../../../landing-page/TabHeader';
import { ANNUALLY, BASIC_LOWER_CASE, BASIC, COMPLETE, PROTECT, NON_AAA, AAA_MEMBER } from '../../../common/Constants';
import ProductChartTabbedViewTopSection from './ProductChartTabbedViewTopSection';
import ProductChartTabbedViewBasicUnavailableDialogBox from './ProductChartTabbedViewBasicUnavailableDialogBox';
import ProductChartTabbedViewPricingAndFeatureSection from './ProductChartTabbedViewPricingAndFeatureSection';

const ProductChartTabbedSection = styled.div`
    padding-top: 20px;
    background: ${colors.pureWhite};
    ${desktop`
        display: none;
    `}
`;

const styles = {
    tabPanel: { paddingBottom: '20px', position: 'relative' }
};

const ProductChartTabbedView = ({ ...props }) => {
    const {
        sections,
        disclaimer,
        linkText,
        onSignup,
        disableCta,
        onProductSelectToCreateSubscription,
        choosePlan = false,
        memberType,
        selectedProductInTab
    } = props || {};

    const getTabBackgroundColorBasedOnTitle = (title = null) => {
        let backgroundColor = null;
        if (!title) {
            return null;
        }
        if (title.toLowerCase() === BASIC_LOWER_CASE) {
            backgroundColor = memberType === AAA_MEMBER ? colors.skyBlue : colors.darkSlateGray;
        } else if (title.toLowerCase() === PROTECT.toLowerCase()) {
            backgroundColor = colors.mediumBlue;
        } else {
            backgroundColor = colors.darkBlue;
        }
        return backgroundColor;
    }
    useEffect(() => {
        // hacky way to switch tab while switching b/w products on product selection page
        if (props.switchTab === true) {
            const reactTabIdNum = props.alreadySelectedProduct === BASIC ? 0 : (props.alreadySelectedProduct === PROTECT ? 1 : 2);
            const reactTabElementsByName = document.getElementsByName(`react-tab-${reactTabIdNum}`);
            if (reactTabElementsByName !== null) {
                reactTabElementsByName[0].click();
            }
        }
    }, [props.alreadySelectedProduct, props.switchTab]);

    const [selectedProduct, setSelectedProduct] = useState(props.alreadySelectedProduct || PROTECT);
    const disableBasicForNonMember = memberType === NON_AAA && selectedProduct === BASIC;
    const onProductSelect = (paymentMode, htmlElementType = 'Button') => {
        sessionStorage.setItem('paymentMode', paymentMode);
        if (choosePlan === true) {
            onProductSelectToCreateSubscription(selectedProduct, paymentMode);
        } else {
            if (selectedProduct.toLowerCase() === BASIC_LOWER_CASE) {
                onSignup(`Home Page | ${selectedProduct} CTA ${htmlElementType} Clicked | AAA Members`, selectedProduct);
            } else {
                const mode = paymentMode === ANNUALLY ? 'Annual' : paymentMode;
                onSignup(`Home Page | ${selectedProduct} ${mode} CTA ${htmlElementType} Clicked | AAA Members`, selectedProduct);
            }
        }
    }
    const determineBackgroundColor = (index) => {
        const fields = sections[index].fields;
        switch (fields.title.toLowerCase()) {
            case BASIC.toLowerCase():
                setSelectedProduct(BASIC);
                break;
            case PROTECT.toLowerCase():
                setSelectedProduct(PROTECT);
                break;
            case COMPLETE.toLowerCase():
                setSelectedProduct(COMPLETE);
                break;
            default:
                setSelectedProduct(PROTECT);
                break;
        }
        if (props.switchTab === true) {
            selectedProductInTab(fields.title);
        }
    }
    const setTabDefaultIndex = () => {
        const productIndex = sections.find(section => section.fields.title === (props.alreadySelectedProduct || PROTECT));
        if (productIndex !== undefined) {
            return sections.indexOf(productIndex);
        }
        return 0;
    }
    const getTabStyle = (productName) => {
        return {
            flexGrow: 1,
            marginTop: '-1px',
            backgroundColor: getTabBackgroundColorBasedOnTitle(productName),
            borderRadius: productName === selectedProduct ? '15px 15px 0 0' : '0',
            position: 'relative'
        };
    }

    const tabListLiStyle = () => {
        return {
            display: 'flex',
            marginBottom: 'unset',
            borderTopRightRadius: '50%',
            position: 'relative',
            backgroundColor: getTabBackgroundColorBasedOnTitle(selectedProduct)
        }
    }
    return (
        <ProductChartTabbedSection>
            <Tabs
                onSelect={determineBackgroundColor}
                defaultIndex={setTabDefaultIndex()}>
                <TabList
                    style={tabListLiStyle()}>
                    {
                        sections.map((section, index) =>
                            <Tab
                                key={section.fields.title}
                                name={`react-tab-${index}`}
                                style={getTabStyle(section.fields.title)}
                                className="react-tabs__tab-product-chart">
                                <TabHeader
                                    title={section.fields.title}
                                    isActiveTab={section.fields.title === selectedProduct}
                                />
                            </Tab>
                        )
                    }
                </TabList>
                {sections
                    .map(section =>
                        <TabPanel
                            style={styles.tabPanel}
                            key={section.fields.title}>
                            <Fragment>
                                <ProductChartTabbedViewTopSection
                                    {...section}
                                    selectedProduct={selectedProduct}
                                    getTabBackgroundColorBasedOnTitle={getTabBackgroundColorBasedOnTitle}
                                />
                                {
                                    disableBasicForNonMember &&
                                    <ProductChartTabbedViewBasicUnavailableDialogBox
                                        {...section}
                                    />
                                }
                                {
                                    !disableBasicForNonMember &&
                                    <ProductChartTabbedViewPricingAndFeatureSection
                                        section={section}
                                        memberType={memberType}
                                        disclaimer={disclaimer}
                                        disableCta={disableCta}
                                        linkText={linkText}
                                        onProductSelectToCreateSubscription={onProductSelectToCreateSubscription}
                                        onProductSelect={onProductSelect}
                                        selectedProduct={selectedProduct}
                                    />
                                }
                            </Fragment>
                        </TabPanel>
                    )}
            </Tabs>
        </ProductChartTabbedSection>
    );
}
export default ProductChartTabbedView;