import React, { useContext } from 'react';
import CardWithTitle from '../elements/CardWithTitle';
import { colors } from '../../styles/common';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import RichText from '../elements/RichText';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { MyAccountPageType } from './MyAccountPageType';
import DataContext from '../contexts/DataContext';
import { useHistory } from 'react-router-dom';
import ImageAsset from '../common/ImageAsset';
import Button from '../elements/Button';
import AnchorLink from '../elements/AnchorLink';
import { ANNUAL } from '../common/Constants';

const highlightedTextStyle = {
    marginTop: 'unset',
    marginBottom: '0rem',
    color: colors.titleText,
    fontWeight: 400
}
const desktopTitleStyle = { fontSize: '22px', lineHeight: '29px' };
const mobileTitleStyle = { fontSize: '17px', lineHeight: '25px' };
const buttonStyle = { backgroundColor: colors.darkBlue, color: colors.pureWhite, padding: '0.375rem 2.06rem', width: 'auto', margin: '0 auto', fontSize: '19px' };
const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;
const SubDescParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle desktopFontSize='16px' mobileFontSize='14px'>{children}</ParagraphStyle>
    },
};

const titleOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphStyle mobileStyles={mobileTitleStyle} desktopStyles={desktopTitleStyle} style={highlightedTextStyle} desktopFontSize='16px' mobileFontSize='14px'>{children}</ParagraphStyle>
    },
};

const subDescriptionOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <SubDescParagraphStyle style={{ lineHeight: '18px', paddingTop: '1rem' }} desktopFontSize='12px' mobileFontSize='12px'>{children}</SubDescParagraphStyle>,
        [INLINES.HYPERLINK]: (node) => <AnchorLink style={{ color: colors.darkBlue, display: 'inline' }} href={node.data.uri} text={node.content[0].value} />
    },
};

const UpgradesAvailable = ({ content, pageType }) => {
    const context = useContext(DataContext);
    const history = useHistory();
    const { outstandingBalance } = context?.userDetails?.subscription || {};
    const { billingFrequency } = context?.userDetails?.subscription || {};

    const upgradeSection = pageType === MyAccountPageType.BASIC ? (content?.sections[0].fields || {}) : (content?.sections[1].fields || {});
    if (pageType === MyAccountPageType.COMPLETE) {
        return null;
    }
    const onButtonClick = () => {
        let redirectUrl = '';
        const { ratePlanIds: { complete, protect } = {} } = context || {};
        if (outstandingBalance > 0) {
            redirectUrl = '/pay-outstanding-balance?notification=true&upgrade=true';
        } else {
            if (pageType === MyAccountPageType.BASIC) {
                redirectUrl = `/product/basic/upgrade/${protect.monthlyRatePlanId}`;
            } else if (pageType === MyAccountPageType.PROTECT) {
                billingFrequency==='ANNUALLY' ?
                redirectUrl = `/product/protect/upgrade/${complete.annualRatePlanId}`
                : redirectUrl = `/product/protect/upgrade/${complete.monthlyRatePlanId}`;
            }
        }
        history.push(redirectUrl);
    }
    return (
        <CardWithTitle
            showtitle={false}
            textalign='center'
            backgroundcolor={colors.searhRowBackgroundColor}
            border='none'>
            <ImageAsset
                image={upgradeSection.image}
            />
            <RichText
                text={upgradeSection.contactUs}
                options={titleOptions}
            />
            <RichText
                text={upgradeSection.description}
                options={options}
            />
            <Button
                text={upgradeSection.linkText}
                style={buttonStyle}
                onClick={() => onButtonClick()}
            />
            <RichText
                text={upgradeSection.subDescription}
                options={subDescriptionOptions}
            />
        </CardWithTitle>
    )
};

export default UpgradesAvailable;