import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ZipCode from './input-mask/ZipCodeComponent';
import styled from 'styled-components';
import SubmitButton from './elements/SubmitButton';
import { ContentfulService } from "../configuration/ApplicationContext";
import ContentfulIds from '../configuration/ContentfulIds';
import { colors, mobile, desktop } from '../styles/common';
import Modal, { ModalProvider, BaseModalBackground } from "styled-react-modal";
import ZipCodeService from '../services/ZipCodeService';

const Header = styled.div`
    width: 100%;
    font-weight: bold;
    font-size: 27px;
    line-height: 36px;
    text-align: center;
    color: $black;
    margin: 30px 0px;
    border-bottom: 1px solid $white;
`;

const ModalContent = styled.div`
    width: 100%;
    height: 100%;
    font-size: 15px;
`;

const Description = styled.div`
    background: ${colors.pureWhite};
    color: $brownish-grey;
    flex: 0.5;
    ${desktop`	
        margin: 0 120px;
        font-size: 20px;
        line-height: 26px;
    `}	
    ${mobile`	
        margin: 20px 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;	
    `}
    
`;


const ActionField = styled.div`
  background: ${colors.pureWhite};
  ${desktop`	
      margin: 18px 120px;	
  `}	
  ${mobile`	
      margin: 20px 20px;	
  `}
  input {
    width: 100%;
    height:50px;
    color: #0e368d;
    border: 1px solid #d3d3d3;
    padding: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.5;
  }
  button {
    font-weight: bold;
    height: 100%;
    ${desktop`	
        width: 400px;
    `}	
    ${mobile`	
        width: 100%;	
    `}
  }
`;

const SubmitButtonStyled = styled(SubmitButton)`
    fontWeight: 500;
    fontSize: 19px;
    lineHeight: 25px;
    textAlign: center;
    border-radius: 10px !important;
    color: ${colors.pureWhite} !important;
    background: #003087 !important;
    width: 400px;
    height: 53.09px;
    margin: 20px 0
    ${desktop`	
        width: 400px;
    `}	
    ${mobile`	
        width: 100%;
    `}
`;

const styles = {
  imageStyled: {
    display: 'block', margin: '0 auto', height: '100px'
  }
};

const EmptyLogoDiv = styled.div`
    height: 100px
`;

const ZipCodeModalForm = styled.form`
    width: 100%
`;


const StyledModal = Modal.styled`
  background-color: ${colors.pureWhite};
  ${desktop`	
    width: 650px;
    height: 500px;
  `}	
  ${mobile`	
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  `}
`;

const FadingBackground = styled(BaseModalBackground)`
  transition: opacity ease 200ms; 
  z-index: 100;
`;


class AskZipCodePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zipCode: ''
    };
  }
  componentDidMount() {
    this.loadContent();
  }

  async loadContent() {
    const response = await ContentfulService.getEntries({ 'sys.id': ContentfulIds.EnterZipCode, include: 5 });
    this.getAsset(response.items[0].fields.image.sys.id);
    this.setState({
      content: response.items[0].fields
    });
  }

  getAsset(id) {
    const params = '?fm=webp';
    ContentfulService.getAsset(id).then((response) => {
      const imageSrc = `https:${response.fields.file.url}${params}`;
      this.setState({ imageSrc });
    });
  }

  handleZipCodeChange(e) {
    this.setState({
      zipCode: e.target.value
    });
  }

  addZipCodeData(zip, e) {
    this.setState({
      isSubmitDisabled: true
    });
    e.preventDefault();
    ZipCodeService.setZipCode(zip);
    this.props.addZipCodeData(zip);
  }

  render() {
    const { content, imageSrc } = this.state;
    if (!content && !imageSrc) {
      return <></>;
    }
    const { showModal } = this.props;

    const bodySection = (
      <>
        <Description>
          {content.description.content[0].content[0].value}
        </Description>
        <ActionField>
          <ZipCode
            value={this.state.zipCode}
            placeholder="Enter Zip Code"
            onChange={(e) => { this.handleZipCodeChange(e); }}
          />
          <SubmitButtonStyled
            disabled={this.state.zipCode.length !== 5 || this.state.isSubmitDisabled}
            text={content.linkText}
          />
        </ActionField>
      </>
    );

    const headerSection = (
      <Header>
        {
          imageSrc ?
            <img
              src={imageSrc}
              alt="AAA Logo"
              style={styles.imageStyled}
            /> :
            <EmptyLogoDiv
            />
        }
        {content.title}
      </Header>
    );

    const modelBodyContent = (
      <ZipCodeModalForm
        onSubmit={(e) => { this.addZipCodeData(this.state.zipCode, e); }}>
        {headerSection}
        <ModalContent>
          {bodySection}
        </ModalContent>
      </ZipCodeModalForm>
    );
    return (
      <ModalProvider
        backgroundComponent={FadingBackground}
      >
        <StyledModal
          isOpen={showModal}
        >
          {modelBodyContent}
        </StyledModal>
      </ModalProvider>
    );
  }
}

AskZipCodePage.propTypes = {
  showModal: PropTypes.bool.isRequired,
  addZipCodeData: PropTypes.func.isRequired,
  openZipForm: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  closeModal: PropTypes.func.isRequired
};

export default AskZipCodePage;
