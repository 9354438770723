import React, { Component } from 'react';
import { Api, ContentfulService, segmentAnalytics } from '../configuration/ApplicationContext';
import CheckoutForm from './forms/CheckoutForm';
import ContentfulIds from '../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, {
    SignUpFlowExtraContent,
    SignUpFlowFormContent
} from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Title from './elements/Title';
import Summary from './summary/Summary';
import Spinner from './elements/Spinner';
import DataContext from './contexts/DataContext';
import { addAmobeeTag, buildUrl, checkIsLoginProcessCompleted, redirectParamSessionStorage, removeRedirectValueFromLocalStorage, subscriptionApplicationIdSessionStorage } from './common/Helpers';
import { validateInputAddress } from './common/Helpers';
import withPageTitle from "./layout/withPageTitle";
import Breadcrumbs from './common/Breadcrumbs';
import UtilsService from '../services/UtilsService';
import { createSummaryItems } from './summary/SummaryItems';
import { AMOBEE_BASIC_MEMBER_UPGRADES_AND_PAYS_SUCCESSFULLY_TAG_URL, CANCELLED, FREE } from './common/Constants';
import InfoText from './elements/InfoText';
import Notification from './notifications/Notification';
import BaseProductOffering from './BaseProductOffering';
import { NotificationTypes } from './notifications/Notifications';
import Card from './elements/Card';

declare var Z: any; //Zuora

const notificationStyle = { marginBottom: '1rem', paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px', marginTop: '60px' };
class UpgradeCheckoutPage extends BaseProductOffering {
    static contextType = DataContext;

    constructor(props) {
        super(props);
        this.state = {
            zuoraConfiguration: null,
            isPaymentMethodInvalid: false
        };
    }

    async componentDidMount() {
        await this.loadPlans();
        this.loadZuoraForm();
        this.setBreadCrumbs();
        this.loadContent()
        this.loadPersonalInfo();
    }

    async loadPlans() {
        let params = {};
        const isNonMemberRateApplicable = this.isNonMemberRateApplicable(this.context.userDetails);
        if (isNonMemberRateApplicable) {
            params.isNonAaaMember = true;
        }
        this.setState({
            isNonMemberRateApplicable
        })
        const url = buildUrl('/api/products/zuora-rate-plans', params);
        const response = await Api.get(url);
        if (!response) {
            return;
        }
        localStorage.setItem('productsWithoutPromoCodes', JSON.stringify(response.products));
        response.products = response.products.filter(product => product.name !== 'FREE');
        const mappedProducts = UtilsService.mapProductsToArray(response.products);
        const productSelected = mappedProducts.find(product => product.id === this.props.match.params.id);
        this.setState({ product: productSelected, isNonMemberRateApplicable: isNonMemberRateApplicable });
        this.context.updateState({ product: productSelected });
    }

    async loadZuoraForm() {
        const zuoraConfiguration = await Api.get('/api/orders/payment-form');
        this.setState({ zuoraConfiguration });
        if (this.state.isPaymentMethodInvalid) {
            this.props.notificationContext.addNotification(NotificationTypes.INVALID_PAYMENT_METHOD);
        }
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.MyAccountIds.UpgradeTierCheckoutPage);
        const upgradeResponse = await ContentfulService.getEntry(ContentfulIds.UpgradeBasicPlanPage);
        this.setState({
            content: response.fields,
            upgradePageContent: upgradeResponse.fields,
            originalContent: JSON.parse(JSON.stringify(response.fields)),
        })
    }

    loadPersonalInfo() {
        const personalInfo = this.context.userDetails.personalInformation || '';
        const billingAddressSameAsHomeAddress = personalInfo.billingAddress ? personalInfo.billingAddressSameAsHomeAddress : true;
        personalInfo.billingAddress = personalInfo.billingAddress ? personalInfo.billingAddress : personalInfo.homeAddress;
        this.setState({
            personalInfo,
            billingAddressSameAsHomeAddress
        });
    }

    onApplyPromoCode = async (promoCodeData) => {
        this.setState({ promoCodeData });
        if (promoCodeData.products) {
            const mappedProducts = UtilsService.mapProductsToArray(promoCodeData.products);
            const productSelected = mappedProducts.find(product => product.name !== FREE && product.id === this.props.match.params.id);
            this.setState({ product: productSelected });
            this.context.updateState({ product: productSelected });
        }
        const originalContent = JSON.parse(JSON.stringify(this.state.originalContent));
        this.setState({ content: originalContent });
    }

    setBreadCrumbs = () => {
        const breadCrumbRoutes = [
            {
                to: `/product/basic/upgrade/${this.props.match.params.id}`, label: 'Upgrade'
            },
            {
                to: `/upgrade/checkout/${this.props.match.params.id}`, label: 'Payment'
            }
        ];
        this.setState({
            breadCrumbRoutes
        });
    }

    onDeclineUpgrade = () => {
        this.props.notificationContext.clearNotifications();
    }

    onSelectAddress = (address) => {
        const personalInfo = this.state.personalInfo;
        personalInfo.billingAddress = address
        if (!validateInputAddress(address)) {
            this.setState({
                personalInfo,
                checkoutError: "Please enter valid billing address"
            });
        } else {
            this.setState({
                personalInfo,
                checkoutError: ''
            });
        }
    }

    submit = async (data) => {
        const { id, createdOn, lastModified, ...billingAddress } = this.state.personalInfo?.billingAddress || {};
        if (Object.keys(data.errors).length > 0 || !data.values.billingAddress) {
            this.setState({ checkoutError: data.errors.billingAddress });
            return;
        }

        this.setState({
            checkoutError: "",
            isPaymentMethodInvalid: false
        }, () => {
            this.props.notificationContext.clearNotifications();
        });

        window.orderSuccess = async (refId) => {
            const { promoCodeRatePlanId, promoCode } = this.context.promoCodeData || {};
            this.setState({
                isSubmitDisabled: true
            });
            let nonAaaMember = this.state.isNonMemberRateApplicable;
            const response = await Api.post('/api/subscriptions/upgrade-coverage', {
                ratePlanId: this.context.product.id,
                paymentMethodId: refId,
                billingAddress,
                promoCodeRatePlanId,
                promoCode,
                nonAaaMember
            });
            if (!response) {
                this.setState({ isSubmitDisabled: false });
                return;
            }
            if (response.validationErrors) {
                const declinedPayment = response.validationErrors.find(errors => errors.property === 'paymentDeclined');
                if (declinedPayment) {
                    this.setState({
                        isSubmitDisabled: false,
                        zuoraConfiguration: null,
                        isPaymentMethodInvalid: true
                    }, async () => {
                        this.loadZuoraForm();
                    });
                } else {
                    await this.context.refreshContext();
                    this.props.history.push('/my-account');
                }   
            } else {
                segmentAnalytics.track('My Account| Basic Member Upgrades & Pays Succesfully', {
                    memberId: this.context.userDetails.userId,
                    tierSelected: this.context.product.name,
                    paymentOption: this.context.product.paymentMode
                });
                addAmobeeTag(AMOBEE_BASIC_MEMBER_UPGRADES_AND_PAYS_SUCCESSFULLY_TAG_URL);
                sessionStorage.removeItem(redirectParamSessionStorage);
                removeRedirectValueFromLocalStorage();
                this.props.history.push('/upgrade/success');
            }
        };
        window.orderFailure = (failureMessage) => {
            this.setState({
                checkoutError: failureMessage,
                zuoraConfiguration: null,
                isSubmitDisabled: false
            }, () => {
                this.loadZuoraForm();
            });
        };
        Z.submit();
    };

    generateZuoraPlansURL = () => {
        let params = {};
        const { userDetails } = this.context || {};
        const membershipStatus = userDetails?.membership?.membershipStatus;
        params.isNonAaaMember = membershipStatus === CANCELLED || userDetails.invalidMembershipNumber;
        return buildUrl('/api/products/zuora-rate-plans', params);
    }

    render() {
        const { content, checkoutError, zuoraConfiguration, personalInfo, billingAddressSameAsHomeAddress, breadCrumbRoutes, isSubmitDisabled, isNonMemberRateApplicable } = this.state;
        const { product } = this.context;
        const { notifications } = this.props.notificationContext;
        if (!zuoraConfiguration || !content || !product || isSubmitDisabled) {
            return <Spinner />;
        }
        return (
            <>
                <InfoText
                    style={notificationStyle}
                    mobileOnly='true'>
                    {
                        notifications.map(notification => {
                            return <Notification
                                key={notification.notificationType}
                                {...notification}
                                {...this.props} />
                        })
                    }
                </InfoText>
                <Breadcrumbs
                    breadCrumbRoutes={breadCrumbRoutes}
                />
                <Content>
                    <InfoText
                        style={notificationStyle}
                        desktopOnly='true'>
                        {
                            notifications.map(notification => {
                                return <Notification
                                    key={notification.notificationType}
                                    {...notification}
                                    {...this.props}
                                />
                            }
                            )
                        }
                    </InfoText>
                    <ResponsiveSignupFlowLayout>
                        <SignUpFlowFormContent>
                            <Title
                                desktopStyles={{ marginTop: '0' }}
                                mobileStyles={{ marginTop: '3rem' }}>
                                Payment
                            </Title>
                            <CheckoutForm
                                zuoraConfiguration={zuoraConfiguration}
                                content={content}
                                onSubmit={this.submit}
                                checkoutError={checkoutError}
                                personalInfo={personalInfo}
                                onSelectAddress={this.onSelectAddress}
                                selectedProduct={product}
                                hideTermsAndConditions={true}
                                isUpgradeCheckoutPage={true}
                                onDeclineUpgrade={this.onDeclineUpgrade}
                                upgradePageContent={this.state.upgradePageContent}
                                promoCodeOptions={
                                    {
                                        validateURL: `/api/promo-codes/validate?isNonAaaMember=${isNonMemberRateApplicable}`,
                                        zuoraRatePlanURL: this.generateZuoraPlansURL(),
                                        mapProductsFunc: UtilsService.mapProductsToArray
                                    }
                                }
                                onApplyPromoCode={this.onApplyPromoCode}
                                billingAddressSameAsHomeAddress={billingAddressSameAsHomeAddress}
                                isSubmitDisabled={isSubmitDisabled}
                            />
                        </SignUpFlowFormContent>
                        <SignUpFlowExtraContent>
                            <Card>
                                <Summary
                                    product={product}
                                    title={`${product.name} Protection`}
                                    items={createSummaryItems(product, this.context)} flags={{ showBottomSection: true }}
                                />
                            </Card>
                        </SignUpFlowExtraContent>
                    </ResponsiveSignupFlowLayout>
                </Content>
            </>
        );
    }
}

export default withPageTitle(UpgradeCheckoutPage, "Upgrade");
