import React from 'react';
import styled, {css} from 'styled-components';
import Input from './Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToolTip from '../tooltip/ToolTip';
import { colors } from '../../styles/common';

export const Label = styled.label`
    font-weight: normal;
    font-style: normal;
    font-size: 17px;
    padding: 5px 0;
    display: block;   
    margin-top: ${props => props.extratoplabelspace};
    ${props => props.hide && css`
        display: none; 
    `}
`;


const StyledInput = styled(Input)`
  ${props => props.hasError && css`
      border-color: #ff0000; 
  `}
  ${props => props.dirty && css`
      border-color: ${colors.darkBlue}; 
  `}
  ${props => props.error && css`
      border-color: ${colors.red}; 
  `}
  ${props => props.warning && css`
      border-color: ${colors.red};
  `}
`;


const LabeledInput = ({label, ...props}) => {
    return (
        <>
            <Label htmlFor={props.name} {...props}>{label}&nbsp;
                {props.tooltipIcon && 
                    <>
                       <FontAwesomeIcon data-tip data-for="date-tooltip" style={{color: '#D7D8D9'}} icon={ props.tooltipIcon } />
                       <ToolTip id="date-tooltip" content="We need your Date of Birth to confirm your identity and configure your identity protection services."/> 
                    </>
                }
            </Label>
            <StyledInput {...props} disabled={props.disabled} /> 
        </>
    );
};

export default LabeledInput;
