import React from 'react';
import styled, {css} from 'styled-components';
import { commonPropStyles } from '../../styles/common';

const SpanStyleComponent = styled.span`
    ${props => props.styles && css`
        styles: ${props.styles};
    `}
    ${commonPropStyles.mobile}
    ${commonPropStyles.desktop}
`;

const Span = ({text, ...props}) => {
    return (
        <SpanStyleComponent {...props}>{text}</SpanStyleComponent>
    )
  }

export default Span;