import React, {Component} from 'react';
import {Api, ContentfulService} from '../configuration/ApplicationContext';
import ContentfulIds from '../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, {SignUpFlowFormContent, SignUpFlowExtraContent} from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Title from './elements/Title';
import RichText from './elements/RichText';
import Spinner from './elements/Spinner';
import Link from './elements/Link';
import InfoText from './elements/InfoText';
import withPageTitle from "./layout/withPageTitle";
import styled from 'styled-components';
import {desktop} from '../styles/common';
import withPageMetaTags from './layout/withPageMetaTags';

const SignupFlowContent = styled(SignUpFlowFormContent)`
    ${desktop`
        padding: 0px 50px 0px 0px;
    `}
`

class MembershipNumberPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.loadContent()
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.MembershipNumberPage);
        this.setState({content: response.fields});
    }

    callMembershipAPI = async () => {
        await Api.put('/api/users/attach-membership-number');
        await Api.login();
    }

    render() {
        const { content} = this.state;
        if (!content) {
            return <Spinner />;
        }
        return (
            <Content>
                <ResponsiveSignupFlowLayout>
                    <SignupFlowContent>
                        <Title style={{marginTop: '1rem'}}>{content.title}</Title>
                        <RichText text={content.description}></RichText>
                        <Link style={{marginTop: '2rem'}} text={content.linkText} to="#" onClick={this.callMembershipAPI}/>
                        <InfoText style={{marginTop: '2rem'}}>Having trouble?</InfoText>
                        <Link style={{marginTop: '1rem'}} text={'Contact Us'} to="/contact-us"/>
                    </SignupFlowContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageMetaTags(withPageTitle(MembershipNumberPage, "Membership Number"), { noindex: true });