import moment from 'moment';
import { AAA_USER, BASIC, COMPLETE, MEMBER_TYPE, NON_AAA, NON_AAA_USER, PLAN_TYPE, PROTECT } from './Constants';
import StringPlaceholderService from '../../services/StringPlaceholderService';

export const capitalizeFirstLetter = value =>
    value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase();

export const removeLeadingZerosForSingleDigitDate = value =>
    value?.replace(/(^|\/)0+/g, "$1");

export const convertPhoneNumberToMaskedValue = value =>
    value?.replace(/\D+/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');

export const formatPhoneNumber = value =>
    value?.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

export const formatCurrency = value =>
    (value < 0 ? '-' : '') + '$' + (value == 0 ? Math.abs(value) : Math.abs(value).toFixed(2))

export const absoluteValue = value => Math.abs(value)

export const lastDayOfMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
}

export const constructCreditCardExpiryDate = (year, month) => {
    return moment([year, month - 1, lastDayOfMonth(year, month)]);
}

export const formatDate = (date) => {
    return date.format('M/YYYY');
}

export const validateInputAddress = (address) => {
    const keys = ['lineOne', 'city', 'state', 'countryCode', 'zipCode'];
    const isValid = Object.keys(address).every(key => {
        if (keys.includes(key) && (typeof address[key] === 'undefined' || address[key].includes('undefined') || address[key] === '')) {
            return false;
        }
        return true;
    });
    return isValid;
}

export const convertStringToDateAndFormat = (dateString) => {
    return removeLeadingZerosForSingleDigitDate(moment(dateString)?.format('MM/DD/YYYY'));
}

export function convertFirstLetterToUpperCase(value) {
    return value?.charAt(0).toUpperCase() + value?.slice(1);
}

export const formatName = (firstName, middleName, lastName) => {
    return `${firstName ? convertFirstLetterToUpperCase(firstName) : ''} ${middleName ? convertFirstLetterToUpperCase(middleName) : ''} ${lastName ? convertFirstLetterToUpperCase(lastName) : ''}`;
}

export const formatMembershipId = (membershipId) => {
    return membershipId.replace(/^(.{3})(.{3})(.{9})(.*)$/, "$1 $2 $3 $4")
}

export const formatDateMMDDYYYY = (date) => {
    if (!date) {
        return null;
    }
    return moment(date).format('MM/DD/YYYY');
}

export const dateSuffix = (date) => {
    var j = date % 10,
        k = date % 100;
    if (j === 1 && k !== 11) {
        return date + "st";
    }
    if (j === 2 && k !== 12) {
        return date + "nd";
    }
    return date + "th";
}

export const removeSpacesFromMembershipNumber = (membershipId) => {
    return membershipId ? membershipId.replace(/(?!\d) +(?=\d)/g, "") : '';
}

export const formatDateMMYY = (date) => {
    return moment(date).format('MM/YYYY');
}

export const redirectParamSessionStorage = 'redirect-params';

export const redirectParameters = {
    promoCode: 'promo-code',
    plan: 'plan',
    interval: 'interval',
    monthlyPromo: 'monthly-promo',
    annualPromo: 'annual-promo',
    redirect: 'redirect'
};

export const subscriptionApplicationIdSessionStorage = 'subscription-application-id';

export const setValidationNotification = (message, severity) => {
    const notifications = [{
        showNotification: true,
        message: message,
        hideCancelIcon: true,
        severity: severity
    }];
    return notifications;
}

export const checkLoginWithAAA = () => {
    return sessionStorage.getItem('isLoginWithAAA') === 'true';
}
export const checkIsLoginProcessCompleted = () => {
    return sessionStorage.getItem('loginProcessCompleted') === 'true';
}

export const getRatePlanBasedOnRedirectParams = ({ protect, complete }) => {
    let selectedPlan;
    const ratePlanMap = new Map()
        .set('protect', {
            monthly: protect.monthlyRatePlanId,
            annual: protect.annualRatePlanId
        }).set('complete', {
            monthly: complete.monthlyRatePlanId,
            annual: complete.annualRatePlanId
        }).set('free', 'free');
    const redirectParams = sessionStorage.getItem(redirectParamSessionStorage);
    if (redirectParams) {
        const parsedRedirectParams = JSON.parse(redirectParams);
        const ratePlanValue = ratePlanMap.get(parsedRedirectParams.plan);
        if (ratePlanValue) {
            selectedPlan = ratePlanValue === 'free' ? 'basic' : ratePlanValue[parsedRedirectParams.interval];
        }
    }
    return selectedPlan;
}

export const buildUrl = (url, params) => {
    const esc = encodeURIComponent;
    const queryString = params ? Object.keys(params).map(k => esc(k) + '=' + esc(params[k])).join('&') : '';
    return queryString === '' ? `${url}` : url.indexOf('?') >= 0 ? `${url}&${queryString}` : `${url}?${queryString}`;
}

export const formatDates = (date, fromFormat, toFormat) => {
    if (!date) {
        return null;
    }
    return removeLeadingZerosForSingleDigitDate(moment(date, fromFormat).format(toFormat));
}

export const sortDataContainingDatesInDescendingOrder = (data, dateFieldName, dateFormat) => {
    return data.sort((obj1, obj2) => moment(obj2[dateFieldName], dateFormat).diff(moment(obj1[dateFieldName], dateFormat)));
}

export const getRatePlanIds = ({ protect, complete }) => {
    return new Map()
        .set(PROTECT, {
            Monthly: `/product/${protect?.monthlyRatePlanId}`,
            Annually: `/product/${protect?.annualRatePlanId}`
        }).set(COMPLETE, {
            Monthly: `/product/${complete?.monthlyRatePlanId}`,
            Annually: `/product/${complete?.annualRatePlanId}`
        }).set(BASIC, {
            Monthly: '/basic-coverage',
            Annually: '/basic-coverage'
        });
}

export const analyticsAreAvailable = () => {
    return window.analytics;
}

export const addAmobeeTag = (url) => {
    if (analyticsAreAvailable()) {
        var image = document.createElement('img');
        image.border = "0";
        image.src = url;
        document.body.appendChild(image);
    }
}

export const isValueString = (value) => {
    return typeof (value) === 'string';
}

export const mapDisclaimerToProperty = (disclaimerContent, properties) => {
    disclaimerContent && disclaimerContent.content.forEach(ct => {
        ct.content.forEach(pr => {
            if (pr.value) {
                pr.value = StringPlaceholderService.replace(pr.value, properties)
            }
        })
    });
}

export const getMemberTypeFromSessionStorage = () => {
    return sessionStorage.getItem(MEMBER_TYPE);
}

export const setMemberTypeInSessionStorage = (memberType) => {
    sessionStorage.setItem(MEMBER_TYPE, memberType);
}

export const removeMemberTypeFromSessionStorage = () => {
    sessionStorage.removeItem(MEMBER_TYPE)
}

export const setPlanTypeInSessionStorage = (planType) => {
    sessionStorage.setItem(PLAN_TYPE, planType);
}

export const setSubscriptionApplicationIdInSessionStorage = (subscriptionApplicationId) => {
    sessionStorage.setItem(subscriptionApplicationIdSessionStorage, subscriptionApplicationId);
}

export const removeSubscriptionApplicationIdSessionStorage = () => {
    sessionStorage.removeItem(subscriptionApplicationIdSessionStorage);
}

export const getSubscriptionApplicatonIdFromSessionStorage = () => {
    return sessionStorage.getItem(subscriptionApplicationIdSessionStorage);
}

export const getRedirectParamFromSessionStorage = () => {
    return sessionStorage.getItem(redirectParamSessionStorage);
}

export const getPromoCodeNameFromRedirectParams = () => {
    const redirectParamFromSessionStorage = getRedirectParamFromSessionStorage();
    return getIndividualPromoCodeNameValueFromSessionStorage()
        && redirectParamFromSessionStorage
        && JSON.parse(redirectParamFromSessionStorage)[redirectParameters.promoCode];
}

export const removePromoCodeNameFromRedirectParams = () => {
    const redirectParamFromSessionStorage = getRedirectParamFromSessionStorage();
    const redirectParams = redirectParamFromSessionStorage && JSON.parse(redirectParamFromSessionStorage);
    delete redirectParams[redirectParameters.promoCode];
    sessionStorage.setItem(redirectParamSessionStorage, JSON.stringify(redirectParams));
}
export const setIndividualPromoCodeNameValueInSessionStorage = () => {
    sessionStorage.setItem(redirectParameters.promoCode, getPromoCodeNameFromRedirectParams());
}
export const deleteIndividualPromoCodeNameValueFromSessionStorage = () => {
    sessionStorage.removeItem(redirectParameters.promoCode);
}
export const getIndividualPromoCodeNameValueFromSessionStorage = () => {
    return sessionStorage.getItem(redirectParameters.promoCode);
}
export const setIndividualPromoCodeNameValueDirectlyInSessionStorage = (promoCode) => {
    sessionStorage.setItem(redirectParameters.promoCode, promoCode);
}

export const sentenceCase = (str) => {
    if ((str === null) || (str === ''))
        return false;
    else
        str = str.toString();

    return str.replace(/\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() +
                txt.substr(1).toLowerCase();
        });
}

export const convertFirstWordToUpperCase = (str) => {
    if (!str) {
        return str;
    }
    const firstWord = str.split(' ').shift().toUpperCase();
    const removedFirstWord = str.substring(str.indexOf(' ') + 1);
    return firstWord.concat(' ', removedFirstWord);
}

export const formatSsn = (ssn) => {
    return ssn.replace(/^(.{3})(.{2})(.*)$/, "$1-$2-$3");
}

export const setRedirectValueInLocalStorage = (value) => {
    localStorage.setItem(redirectParameters.redirect, value);
}
export const getRedirectValueFromLocalStorage = () => {
    return localStorage.getItem(redirectParameters.redirect);
}
export const removeRedirectValueFromLocalStorage = () => {
    localStorage.removeItem(redirectParameters.redirect);
}
export const getMemberTypeValue = (creationSource) => {
    return creationSource ? creationSource.toLowerCase() === NON_AAA ? NON_AAA_USER : AAA_USER : creationSource;
}

export const appendCommaDelimiterToArray = (data) => {
    return data && data.length > 0
        ? data.join(', ')
        : '';
}

export const getSocialMediaNamesMap = (key) => {
    const socialMediaMap = new Map()
        .set('facebook', 'Facebook')
        .set('twitter', 'Twitter')
        .set('instagram', 'Instagram')
        .set('linkedin', 'LinkedIn')
    if (socialMediaMap.get(key) === undefined)
        return key;
    else
        return socialMediaMap.get(key);
}
