import React from 'react';
import styled from 'styled-components';
import { colors, mobile } from '../../../../styles/common';
import { AAA_MEMBER, ANNUALLY, BASIC_LOWER_CASE, BASIC, MONTHLY, NON_AAA } from '../../../common/Constants';
import Section from '../../../elements/Section';

const MiddleSection = styled.div`
    justify-content: center;
    padding: 2.375rem 1rem 0rem 1rem;
    ${mobile`
        padding: 1rem 0.5rem 0rem 0.5rem;
    `}
`;
const Title = styled.h1`
    font-size: 36px;
    line-height: 35px;
    margin: 0;
    font-weight: 500;
    color: ${colors.pureBlack};
    ${mobile`
        font-size: 20px;
        line-height: 35px;
    `}
`;
const Button = styled.input`
    background: ${colors.darkBlue};
    border: 2px solid ${colors.darkBlue};
    padding-top: 10px;
    padding-bottom: 10px;
    width: 273px;
    border-radius: 10px;
    font-size: 22px;
    font-weight: 500;
    color: ${colors.pureWhite};
    cursor: pointer;
    margin: 0 auto;
    max-width: 100%;
    display: block;
    ${mobile`
        font-size: 10px;
        line-height: 13px;
        padding-top: 3px;
        padding-bottom: 3px;
        border-radius: 3px;
    `}
`;
const style = {
    amountDescription: { fontStyle: 'italic' },
    annualAmount: { textDecoration: 'underline', color: colors.darkBlue, cursor: 'pointer', fontWeight: 500 },
    disabledCtaAmount: { fontSize: '22px', fontWeight: 500 }
};
const mobileStyles = {
    productDescription: { fontSize: '10px', lineHeight: '12px', paddingBottom: '0.5rem', paddingTop: '0rem' },
    amountDescription: { fontSize: '10px', lineHeight: '12px', paddingTop: '0.5rem', paddingBottom: '0rem' },
    annualAmount: { fontSize: '11px', lineHeight: '12px' }
};
const desktopStyles = {
    productDescription: { fontSize: '16px', lineHeight: '25px', paddingTop: '1rem' },
    amountDescription: { fontSize: '16px', lineHeight: '25px', paddingTop: '1.5rem', },
    annualAmount: { fontSize: '22px', lineHeight: '29px' }
};
const ProductChartMiddleSection = ({ ...props }) => {
    const {
        title,
        commonDataDetails: {
            mobile
        },
        productDescription,
        monthlyAmount,
        annualAmount,
        amountDescription,
        memberType,
        nonAaaMemberDetails,
        onSignup,
        disableCta,
        onProductSelectToCreateSubscription,
        setMiddleSectionHeightDynamically
    } = props || {};
    const middleSectionData = {
        annualAmount: memberType === AAA_MEMBER ? annualAmount : nonAaaMemberDetails.annualAmount,
        monthlyAmount: memberType === AAA_MEMBER ? monthlyAmount : nonAaaMemberDetails.monthlyAmount
    };
    // const onCTAClick = (paymentMode, htmlElementType = 'Button') => {
    //     const productName = title;
    //     sessionStorage.setItem('paymentMode', paymentMode);
    //     if (props.choosePlan === true) {
    //         onProductSelectToCreateSubscription(productName, paymentMode);
    //     } else {
    //         if (productName.toLowerCase() === BASIC_LOWER_CASE) {
    //             memberType === AAA_MEMBER && onSignup(`Home Page | ${productName} CTA ${htmlElementType} Clicked | AAA Members`, productName);
    //         } else {
    //             const mode = paymentMode === ANNUALLY ? 'Annual' : paymentMode;
    //             onSignup(`Home Page | ${productName} ${mode} CTA ${htmlElementType} Clicked | AAA Members`, productName);
    //         }
    //     }
    // }
    // const monthlyAmountSection = disableCta ?
    //     <Section
    //         style={style.disabledCtaAmount}
    //         text={`${middleSectionData.monthlyAmount} ${title !== BASIC ? '/ month' : ''}`}
    //     /> :
    //     <Button
    //         type='button'
    //         style={
    //             {
    //                 display: memberType === NON_AAA && title === BASIC ? 'none' : 'block'
    //             }
    //         }
    //         value={`${middleSectionData.monthlyAmount} ${title !== BASIC ? '/ month' : ''}`}
    //         onClick={() => { onCTAClick(MONTHLY, 'Button') }}
    //     />
    return (
        <MiddleSection
            ref={setMiddleSectionHeightDynamically}>
            <Title>{title}</Title>
            <Section
                desktopOnly='true'
                desktopStyles={desktopStyles.productDescription}
                text={productDescription}
            />
            <Section
                mobileOnly='true'
                mobileStyles={mobileStyles.productDescription}
                text={mobile.productDescription}
            />
            {/* {
                monthlyAmountSection
            } */}
        </MiddleSection>
    );
}
export default ProductChartMiddleSection;