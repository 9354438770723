import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import BasicPlanUnavailableDialogueBox from './BasicPlanUnavailableDialogueBox';

const OverlayTab = styled.div`
    position: absolute;
    top: 24px;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(36,36,36,0.6);
    z-index: 0;
    ${props => props.isActiveTab && css`
        border-radius: 15px 15px 0 0;
    `}
`;

const DialogueBoxContainer = styled.div`
    z-index: 2;
    position: relative;
`;

const ProductChartTabbedViewBasicUnavailableDialogBox = ({ ...props }) => {
    const {
        fields: { commonDataDetails } = {}
    } = props || {};

    return (
        <Fragment>
            <OverlayTab />
            <DialogueBoxContainer>
                <BasicPlanUnavailableDialogueBox
                    diaogueBoxContent={commonDataDetails}
                />
            </DialogueBoxContainer>
        </Fragment>
    );
}
export default ProductChartTabbedViewBasicUnavailableDialogBox;