import React, { Fragment, useEffect, useState } from 'react';
import InfoText from './elements/InfoText';
import styled from 'styled-components';
import RadioButton from './elements/RadioButton';
import DynamicParagraphText from './elements/DynamicParagraphText';
import SubmitButton from './elements/SubmitButton';

const QuestionDetail = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
`;

const questionStyle = {
    fontWeight: 400,
    marginBottom: 'unset',
    fontSize: '17px'
};

const optionStyle = {
    fontSize: '17px'
}

const submitButtonStyle = {
    width: '50%'
}

const Question = ({onSelectAnswer, answer, question, onNextQuestion, isLastQuestion, submitAnswers}) => {
    const [selectedAnswer, setSelectedAnswer] = useState({});
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (answer) {
            setDisabled(false);
            setSelectedAnswer(answer);
        } else {
            setDisabled(true);
            setSelectedAnswer({});
        }    
    }, [answer]);

    const selectAnswer = (event, question, answerOption) => {
        event.stopPropagation();
        setSelectedAnswer(answerOption);
        setDisabled(false);
        const { number, QuestionNumber } = question || {};
        const { AnswerNumber } = answerOption || {};
        onSelectAnswer(number, QuestionNumber, AnswerNumber);
    }

    const nextQuestion = (question) => {
        if (isLastQuestion) {
            submitAnswers();
        } else {
            setSelectedAnswer({});
            onNextQuestion(question);
        }
    }

    return (
        <QuestionDetail>
            <Fragment >
                <DynamicParagraphText style={questionStyle} text={question.Question}></DynamicParagraphText>
                <InfoText>
                    {question.AnswerOption.map(answerOption => <RadioButton name={`question_${question.QuestionNumber}`} label={answerOption.Answer} style={optionStyle} checked={answerOption.AnswerNumber === selectedAnswer.AnswerNumber} onChange={(event) => selectAnswer(event, question, answerOption)}/>)}
                </InfoText>
            </Fragment>
            <SubmitButton style={submitButtonStyle} text={isLastQuestion ? "Finish" : "Next"} disabled={disabled} onClick={() => nextQuestion(question)} /> 
        </QuestionDetail>
    )
};

export default Question;