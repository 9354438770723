import React, { Component } from 'react';
import AdminContext from './AdminContext';
import { capitalizeFirstLetter, getSubscriptionApplicatonIdFromSessionStorage } from '../common/Helpers';
import { AdminApi } from '../../configuration/ApplicationContext';
import Spinner from '../elements/Spinner';
import cookie from 'react-cookies';

class AdminProvider extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            searchResults: [],
            isSearching: false,
            searchResult: {},
            ratePlanIds: null,
            memberDetails: {},
            promoCodeData: null
        };
    }

    componentDidMount() {
        this.getRatePlanIds();
        this.getMemberDetails();
        this.getPromoCodeData();
    }

    getMemberDetails = async () => {
        const searchResult = JSON.parse(localStorage.getItem('searchResult'));
        if (!searchResult) {
            return;
        }
        this.setState({
            searchResult
        });
        let data = {};
        this.setState({
            searchResult
        });
        if (searchResult.idcMembership && searchResult.idcMembership !== 'None') {
            const aaaLoginIdentifier = searchResult.aaaLoginIdentifier || ' ';
            const userId = searchResult.userId || ' ';

            //TODO: remove this fix later
            const subscriptionApplicationId = getSubscriptionApplicatonIdFromSessionStorage();
            const url = subscriptionApplicationId
                ? `/api/users/non-member/user-details?subscriptionApplicationId=${subscriptionApplicationId}`
                : `/api/users/user-details?aaaLoginIdentifier=${aaaLoginIdentifier}&userId=${userId || ""}`;
            const response = await AdminApi.get(url);
            if (response) {
                data.memberDetails = response;
                searchResult.userId = response.userId;
                localStorage.setItem('searchResult', JSON.stringify(searchResult));
                const { basePrice, actualPrice, discount, termEndDate, billingFrequency, productType } = data.memberDetails.subscription || {};
                if (data.memberDetails && data.memberDetails.subscription) {
                    data.product = {
                        basePrice,
                        actualPrice,
                        discount,
                        coverageThrough: termEndDate,
                        name: capitalizeFirstLetter(productType),
                        paymentMode: capitalizeFirstLetter(billingFrequency),
                        tax: actualPrice?.tax,
                        nextPaymentAmountPreviewWithPromocode: true
                    };
                }
                this.setState(data);
                this.setState({
                    searchResult
                });
            }
        }
    }

    getRatePlanIds = async () => {
        if (!this.state?.ratePlanIds) {
            const response = await AdminApi.get('/api/products/rate-plans');
            if (response) {
                this.setState({
                    ratePlanIds: response
                });
            }
        }
    }
    getBundleRatePlanIdBasedOnPlan = (plan) => {
        const { protect, complete } = this.state.ratePlanIds || {};
        const bundleRatePlanMap = new Map()
            .set('protect', protect?.annualBundleRatePlanId)
            .set('complete', complete?.annualBundleRatePlanId);
        return bundleRatePlanMap.get(plan);
    }

    updateState = (values) => {
        this.setState(values);
    }

    getLoginId = () => {
        return cookie.load('loginId');
    }
    getPromoCodeData = () => {
        let promoCodeData = localStorage.getItem('promoCodeData');
        if (promoCodeData !== 'undefined' || !promoCodeData) {
            promoCodeData = JSON.parse(promoCodeData);
            this.setState({
                promoCodeData,
            });
        } else {
            this.setState({
                promoCodeData: null,
            });
        }
    }

    setPromoCodeData = (promoCodeData) => {
        localStorage.setItem('promoCodeData', JSON.stringify(promoCodeData));
        this.setState({
            promoCodeData,
        });
    }

    removePromoCodeData = () => {
        localStorage.removeItem('promoCodeData');
        this.setState({
            promoCodeData: null,
        });
    }
    render() {
        const { searchResults, isSearching, memberDetails, searchResult, ratePlanIds, product, loginId, promoCodeData, aaaLoginIdentifier } = this.state;
        if ((isSearching && !searchResult) || (Object.keys(searchResult)?.length > 0 && searchResult?.idcMembership !== 'None' && !memberDetails) || !ratePlanIds) {
            return <Spinner />;
        }
        const contextValue = {
            isSearching,
            searchResults,
            memberDetails,
            searchResult,
            ratePlanIds,
            promoCodeData,
            product,
            loginId,
            aaaLoginIdentifier,
            updateState: (values) => this.updateState(values),
            getMemberDetails: async (membershipId) => this.getMemberDetails(membershipId),
            getBundleRatePlanIdBasedOnPlan: (planType) => this.getBundleRatePlanIdBasedOnPlan(planType),
            refreshContext: async () => this.refreshContext(),
            getLoginId: () => this.getLoginId(),
            setPromoCodeData: (promoCodeData) => this.setPromoCodeData(promoCodeData),
            removePromoCodeData: () => this.removePromoCodeData(),
        };
        return (
            <AdminContext.Provider value={contextValue}>
                {this.props.children}
            </AdminContext.Provider>
        )
    }
}

export default AdminProvider;