import React from 'react';
import { appendCommaDelimiterToArray, formatDates, getSocialMediaNamesMap } from '../../common/Helpers';
import CardLineItem from '../../elements/CardLineItem';
import CardWithTitle from '../../elements/CardWithTitle';
import InfoText from '../../elements/InfoText';
import Link from '../../elements/Link';

const styles = {
    viewFullAlert: { textAlign: 'right', fontSize: '16px' },
    compromisedCard: { marginTop: '-1rem' },
    itemKey: { width: '67%', marginRight: '2%' },
    itemValue: { width: '20%' },
    compromisedItemKey: { width: '67%', marginRight: '2%' },
    compromisedItemValue: { width: '31%', textAlign: 'right' }
};
const MemberExperianAlerts = ({ experianAlerts }) => {
    if (!experianAlerts) {
        return null;
    }

    return (
        <CardWithTitle
            firstGrow={true}
            title='Experian Alerts'>
            {
                !experianAlerts.length &&
                <InfoText>
                    No Alerts Reported
                </InfoText>
            }
            {
                experianAlerts.length > 0 && experianAlerts.slice(0, 5).map(({ type, date, details, showDetailsLink, compromisedProperties }, index) =>
                    <>
                        <CardLineItem
                            key={index}
                            itemKey={
                                <>
                                    {type}
                                    {
                                        showDetailsLink === true &&
                                        <>
                                            {
                                                compromisedProperties && compromisedProperties.length > 0 ||
                                                    details && details.socialMedia
                                                    ? ':'
                                                    : ''
                                            }
                                            {
                                                details && details.socialMedia
                                                    ? ` ${getSocialMediaNamesMap(details.socialMedia)}`
                                                    : ''
                                            }
                                            {
                                                <CardLineItem
                                                    itemKey={
                                                        `${compromisedProperties
                                                            && compromisedProperties.length > 0
                                                            ? `Compromised ${appendCommaDelimiterToArray(compromisedProperties.map(({ propertyName }) => propertyName))}`
                                                            : ''
                                                        }`
                                                    }
                                                />
                                            }
                                        </>
                                    }

                                </>
                            }
                            itemValue={
                                <>
                                    {formatDates(date, 'YYYYMMDD', 'MM/DD/YYYY')}
                                    {
                                        showDetailsLink === true &&
                                        <Link
                                            to={
                                                {
                                                    pathname: `/admin/experian/cyber-agent-alert`,
                                                    state: {
                                                        compromisedProperties,
                                                        experianAlerts,
                                                        details,
                                                        type
                                                    }
                                                }

                                            }
                                            style={styles.viewFullAlert}
                                            text='View Full Alert'
                                        />
                                    }
                                </>
                            }
                            itemStyle={styles.compromisedItemValue}
                            itemKeyStyle={styles.itemKey}
                        />
                    </>
                )
            }
            {
                experianAlerts.length >= 6 &&
                <Link
                    to={
                        {
                            pathname: `/admin/experian-alerts`,
                            state: {
                                experianAlerts
                            }
                        }
                    }
                    style={{ textAlign: 'center', padding: '0.5rem' }}
                    text='More Experian Alerts'
                />
            }
        </CardWithTitle>
    )
};

export default MemberExperianAlerts;