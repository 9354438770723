import React from 'react';
import styled, { css } from 'styled-components';
import { mobile, desktop } from '../../styles/common';

const OrderedListTextComponent = styled.ul`
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    line-height: 26px;
    font-weight: 500;
    ${props => props.hideCustomCheckBullet && css`
        list-style: none;
        padding-left: 20px;
        li {
          padding: 0px 10px 20px 0px;
        }
    `}
    ${props => props.styles && css`
      styles: ${props.styles};
    `}
    ${props => props.removeListPadding && css`
      li {
        padding: 0px !important;
      }
   `};

    ${props => props.desktopFontSize && css`
    ${desktop`
      font-size: ${props.desktopFontSize};
    `}    
  `};
  ${props => props.mobileFontSize && css`
    ${mobile`
      font-size: ${props.mobileFontSize};
      margin-bottom: 0px;
    `}
  `};
`;

const OrderedList = ({ text, ...props }) => {
  return (
    <OrderedListTextComponent {...props}>{text}</OrderedListTextComponent>
  )
}

export default OrderedList;