import React, { Component } from 'react';
import { ContentfulService } from '../configuration/ApplicationContext';
import LandingContent from './layout/LandingContent';
import ResponsiveLandingPageLayout from './layout/ResponsiveLandingPageLayout';
import Spinner from './elements/Spinner';
import ContentfulIds from '../configuration/ContentfulIds';
import Title from './elements/Title';
import RichText from './elements/RichText';
import { mobile } from '../styles/common';
import styled from 'styled-components';
import { BLOCKS } from "@contentful/rich-text-types";
import DynamicParagraphText from './elements/DynamicParagraphText';
import withPageTitle from './layout/withPageTitle';
import withPageMetaTags from './layout/withPageMetaTags';
import { META_DESCRIPTION } from './common/Constants';
import { getPromoCodeNameFromRedirectParams } from './common/Helpers';


const ContactUsSection = styled.div`
	padding: 20px 30px ;
	${mobile`
		padding: 0 20px;
	`}
`
const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText id="rich-text" {...props} text={children} />;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle style={{ padding: '10px 0px' }}>{children}</DynamicParagraphStyle>
    }
};

class ContactUsPage extends Component {
    state = {};

    componentDidMount() {
        this.loadContent();
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.ContactUsPage);
        this.setState({
            content: response.fields,
        });
    }

    render() {
        const { content } = this.state;
        if (!content) {
            return <Spinner />;
        }
        return (
            <LandingContent
                promocodename={getPromoCodeNameFromRedirectParams()}>
                <ResponsiveLandingPageLayout>
                    <ContactUsSection>
                        <Title
                            style={{ paddingTop: '30px', margin: '0' }}>
                            {content.title}
                        </Title>
                        <RichText
                            text={content.description}
                            style={{ fontSize: '16px' }}
                            options={options}
                        />
                    </ContactUsSection>
                </ResponsiveLandingPageLayout>
            </LandingContent>
        );
    }
}

export default withPageMetaTags(withPageTitle(ContactUsPage, 'Contact Us'), { description: META_DESCRIPTION.CONTACT_US });
