import React from 'react';
import Title from '../elements/Title';
import LineItem from '../elements/LineItem';
import Section from '../elements/Section';
import { updatePaymentSummaryItems } from './UpdatePaymentSummaryItems';
import { colors } from '../../styles/common';
import TransparentCard from '../elements/TransparentCard';
const UpdatePaymentSummary = ({ title, data, page }) => {
    const { nextBilingData, outstandingBalanceSection } = updatePaymentSummaryItems(data);
    return (
        <TransparentCard>
            <Title desktopOnly='true' desktopStyles={{ 'font-size': '22px' }} style={{ margin: '0px', backgroundColor: colors.backgroundGrey, padding: '15px' }}>{title}</Title>
            <Title mobileOnly='true' mobieStyles={{ fontSize: '22px' }} style={{ marginBottom: '0px', paddingLeft: '15px' }}>{title}</Title>
            {page === 'updatePaymentMethod' && <Section text={nextBilingData.map(({ key, value }) => <LineItem style={{ padding: '0px 15px' }} key={key} itemKey={key} itemValue={value} itemStyle={{fontWeight: 'bold'}}></LineItem>)}></Section>}
            {page === 'payOutstandingBalance' &&  <Section text={outstandingBalanceSection.map(({ key, style, value }) => <LineItem style={{ padding: '0px 15px' }} key={key} itemKey={key} itemValue={value} itemStyle={style}></LineItem>)}></Section>}
        </TransparentCard>
    );
};

export default UpdatePaymentSummary;