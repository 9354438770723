import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import React from 'react';
import { ContentfulService } from '../../configuration/ApplicationContext';
import ContentfulIds from '../../configuration/ContentfulIds';
import StringPlaceholderService from '../../services/StringPlaceholderService';
import DataContext from '../contexts/DataContext';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import Link from '../elements/Link';
import RichText from '../elements/RichText';
import Section from '../elements/Section';
import Spinner from '../elements/Spinner';
import Title from '../elements/Title';
import Content from '../layout/Content';
import ResponsiveSignupFlowLayout, { SignUpFlowExtraContent, SignUpFlowFormContent } from '../layout/ResponsiveSignupFlowLayout';
import withPageMetaTags from '../layout/withPageMetaTags';
import withPageTitle from '../layout/withPageTitle';
import BaseBundleSignup from './BaseBundleSignup';
import EmailForm from './EmailForm';

const linkStyle = { display: 'inline-block', fontSize: '14px' };
const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText id="rich-text" {...props} text={children} />;

const contactUsOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle >{children}</DynamicParagraphStyle>
    },
};
class BundleCreateNewAccount extends BaseBundleSignup {
    static contextType = DataContext;
    componentDidMount() {
        this.loadContent();
    }

    loadContent = async () => {
        const response = await ContentfulService.getEntry(ContentfulIds.BundleFlow.BundleCreateNewAccount);
        const query = new URLSearchParams(this.props.location.search);
        const email = query.get('email');
        const productId = this.props.match.params.id;
        const properties = {
            email
        };
        response.fields.description.content.forEach(ct => {
            ct.content.forEach(pr => {
                if (pr.value) {
                    pr.value = StringPlaceholderService.replace(pr.value, properties)
                }
            })
        });
        response.fields.subDescription.content.forEach(ct => {
            ct.content.forEach(pr => {
                if (pr.value) {
                    pr.value = StringPlaceholderService.replace(pr.value, properties)
                }
            })
        });
        this.setState({ productId, email, content: response.fields });
    }

    setOptions = () => {
        const options = {
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle>{children}</DynamicParagraphStyle>,
                [INLINES.HYPERLINK]: (node) => <Link style={linkStyle} onClick={this.onLogin} text={node.content[0].value} />
            },
        };
        return options;
    }

    render() {
        const { content, apiErrors, isSubmitDisabled } = this.state || {};
        if (!content) {
            return <Spinner />;
        }
        return (
            <Content>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent>
                        <Title style={{ marginTop: '1rem' }}>{content.title}</Title>
                        <RichText text={content.description} options={this.setOptions()} />
                        <EmailForm content={content} isSubmitDisabled={isSubmitDisabled} apiErrors={apiErrors} onSubmit={this.onSubmit} />
                        <RichText text={content.subDescription} options={this.setOptions()} />
                        <Section text={<RichText text={content.contactUs} options={contactUsOptions} />}></Section>
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent />
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageMetaTags(withPageTitle(BundleCreateNewAccount, "Bundle Create New Account"), { noindex: true });