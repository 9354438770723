import styled from 'styled-components';
import {colors} from '../../styles/common';

const InlineMessage = styled.span`
  font-size: 14px;
  color: ${colors.darkBlue};
  line-height: 26px;
`;

export default InlineMessage;
