import { toast } from 'react-toastify';

export default class ToastService {
    constructor() {
        toast.configure();
    }
    
    showErrorNotification(message) {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true
        });
    }
}