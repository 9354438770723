import React from "react";
import styled from "styled-components";
import Title from "../../elements/Title";
import { colors, mobile } from "../../../styles/common";
import DynamicParagraphText from "../../elements/DynamicParagraphText";
import useCustomForm from "../../../hooks/useCustomForm";
import validate from "./outOfAreaFormValidation";
import FormError from "../../elements/FormError";
import LabeledInput from "../../elements/LabeledInput";
import PropTypes from 'prop-types';
import SubmitButton from "../../elements/SubmitButton";
import CheckboxInput from "../../elements/CheckboxInput";
import InfoText from "../../elements/InfoText";
import Span from "../../elements/Span";
import { BLOCKS } from "@contentful/rich-text-types";
import RichText from "../../elements/RichText";

const OUtofAreaSignupNotifySection = styled.div`
    display: block;
    width: 50%;
    box-sizing: border-box;
    padding: 3rem 0rem 0rem 0rem;
    color: ${colors.titleText};
    ${mobile`
        width: 100%;
        padding: 10px;
    `}
`;

const styles = {
    titleDesktop: { fontSize: '36px', lineHeight: '48px' },
    titleMobile: { fontSize: '27px', lineHeight: '36px' },
    submit: { background: `${colors.darkBlue}`, color: `${colors.pureWhite}`, borderRadius: '10px' }
};

const OutofAreaSignupToNotifyForm = ({ initialValues, apiErrors, zipCode, isSignupPage, content, onSubmit, isSubscribeToIdcMailingListing, handleSubscribe, isSubmitDisabled }) => {
    initialValues = {
        zipCode: zipCode,
        email: '',
        isEmail: isSignupPage,
    };

    const {
        values,
        errors,
        touched,
        submitted,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useCustomForm({ initialValues, validate, onSubmit });

    const inputHandlers = {
        onChange: handleChange,
        onBlur: handleBlur,
        extratopspace: '0.5rem',
        extraTopLabelSpace: '0.75rem'
    };

    const filteredErrors = submitted ? errors : Object.keys(errors)
        .filter(key => touched[key])
        .reduce((obj, key) => {
            obj[key] = errors[key];
            return obj;
        }, {});

    const handleFormSubmit = (event) => {
        event.preventDefault();
        handleSubmit();
    }

    const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText {...props} text={children} />;
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle style={{ fontSize: '17px' }} >{children}</DynamicParagraphStyle>,
        },
    };

    return (
        <OUtofAreaSignupNotifySection>
            <Title
                desktopStyles={styles.titleDesktop}
                mobileStyles={styles.titleMobile}>
                {content.title}
            </Title>
            <RichText
                text={content.description}
                options={options}
            />
            <form
                onSubmit={handleFormSubmit}
                noValidate>
                <FormError
                    showErrors={submitted}
                    errors={filteredErrors}
                    message="Please fill out the fields highlighted below"
                />
                <FormError
                    showErrors={submitted}
                    errors={apiErrors}
                />
                <LabeledInput
                    maxLength="5"
                    label="Zip Code"
                    type="text"
                    name="zipCode"
                    error={filteredErrors.zipCode}
                    value={values.zipCode}
                    {...inputHandlers}
                />
                {
                    isSignupPage &&
                    <>
                        <LabeledInput
                            label="Email (required)"
                            type="email"
                            name="email"
                            error={filteredErrors.email}
                            value={values.email}
                            {...inputHandlers}
                        />
                        <InfoText
                            style={{ padding: '2rem 0rem', display: 'flex' }}>
                            <CheckboxInput
                                name="agreedToTerms"
                                checked={isSubscribeToIdcMailingListing}
                                onChange={() => { handleSubscribe() }}
                            />
                            <Span
                                text={content.footerDescription}
                            />
                        </InfoText>
                    </>
                }
                <SubmitButton
                    text={content.linkText}
                    style={styles.submit}
                    mobileStyles={{ margin: '10px auto' }}
                    disabled={isSubmitDisabled}
                />
            </form>
        </OUtofAreaSignupNotifySection>
    );
};

OutofAreaSignupToNotifyForm.propTypes = {
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func.isRequired
};

OutofAreaSignupToNotifyForm.defaultProps = {
    initialValues: {
        zipCode: '',
        email: '',
        isEmail: false
    }
};

export default OutofAreaSignupToNotifyForm;