import React from 'react';
import styled, { css } from 'styled-components';
import RichText from '../elements/RichText';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { colors, mobile, desktop } from '../../styles/common';
import UnorderedListText from '../elements/UnorderedListText';
import Span from '../elements/Span';
import OrderedList from '../elements/OrderedList';

const PlanDetailDataContainer = styled.div`
    text-align: ${props => props.alignText};
    ${props => props.backgroundColor && css`
        background: ${props.backgroundColor};
    `}
    ${props => props.mobileStyles && css`
      ${mobile`
        ${props.mobileStyles}
      `}
    `};
    ${props => props.desktopStyles && css`
    ${desktop`
     ${props.desktopStyles};
    `}
  `};
    
`;

const PlanDetailData = ({ data, desktopStyles, mobileStyles, liPadding, ulStyle, fontWeight, ...props }) => {
    const UnorderedItalicStyle = ({ children, ...props }) => <span style={{ color: `${colors.contentGrey}` }}>{children}</span>;
    let UnorderedListStyle = ({ children, ...props }) => <UnorderedListText  showCustomCheckBullet={true} {...props} text={children} />;
    let OrderedListStyle = ({ children, ...props }) => <OrderedList hideCustomCheckBullet={true}  {...props} text={children} />;
    const SpanStyle = ({ children, ...props }) => <Span text={children}></Span>;
    const options = {
        renderMark: {
            [MARKS.ITALIC]: text => <UnorderedItalicStyle>{text}</UnorderedItalicStyle>
        },
        renderNode: {
            [BLOCKS.UL_LIST]: (node, children) => <UnorderedListStyle liPadding={liPadding || '0.8rem 0rem'} ulStyle={ulStyle} fontWeight={fontWeight} desktopFontSize='16px' mobileFontSize='15px'>{children}</UnorderedListStyle>,
            [BLOCKS.OL_LIST]: (node, children) => <OrderedListStyle desktopFontSize='16px' mobileFontSize='15px'>{children}</OrderedListStyle>,
            [BLOCKS.PARAGRAPH]: (node, children) => <SpanStyle >{children}</SpanStyle>
        },
    };    
    if (props.isWhatsIncludedPage) {
        UnorderedListStyle = ({ children, ...props }) => <UnorderedListText showCustomCheckBullet={true} {...props} text={children} />;
        OrderedListStyle = ({ children, ...props }) => <OrderedList hideCustomCheckBullet={true} {...props} text={children} />;
        options.renderNode = {
                [BLOCKS.UL_LIST]: (node, children) => <UnorderedListStyle desktopFontSize='1em' mobileFontSize='1em'>{children}</UnorderedListStyle>,
                [BLOCKS.OL_LIST]: (node, children) => <OrderedListStyle desktopFontSize='1em' mobileFontSize='1em'>{children}</OrderedListStyle>,
                [BLOCKS.PARAGRAPH]: (node, children) => <SpanStyle >{children}</SpanStyle>
        };
    }

    return (
        <PlanDetailDataContainer alignText={props.alignText} desktopStyles={desktopStyles} mobileStyles={mobileStyles} backgroundColor={props.backgroundColor}>
            <RichText text={data} options={options} />
        </PlanDetailDataContainer>
    );
};

export default PlanDetailData;