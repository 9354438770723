import React from 'react';
import styled from 'styled-components';
import MaskedInput from './MaskedInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToolTip from '../tooltip/ToolTip';

const Label = styled.label`
    font-weight: normal;
    font-style: normal;
    font-size: 17px;
    padding: 5px 0;
    display: block;   
    margin-top: ${props => props.extraTopLabelSpace};
`;

const MaskedLabeledInput = ({label, ...props}) => {
    return (
        <>
            <Label htmlFor={props.name} extraTopLabelSpace={props.extraTopLabelSpace}>{label}&nbsp;
                {props.tooltipIcon && <FontAwesomeIcon data-tip data-for="date-tooltip" style={{color: '#D7D8D9'}} icon={ props.tooltipIcon } />}
                <ToolTip id="date-tooltip" content="We need your Date of Birth to confirm your identity and configure your identity protection services."/> 
            </Label>
            <MaskedInput {...props} />
        </>
    );
};

export default MaskedLabeledInput;
