import React, { Component } from "react";
import { ContentfulService, Api } from "../configuration/ApplicationContext";
import Spinner from './elements/Spinner';
import ContentfulIds from '../configuration/ContentfulIds';
import RichText from './elements/RichText';
import styled from "styled-components";
import { mobile } from "../styles/common";
import Title from "./elements/Title";
import withPageTitle from "./layout/withPageTitle";
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from './layout/ResponsiveSignupFlowLayout';
import DynamicParagraphText from './elements/DynamicParagraphText';
import { MARKS , BLOCKS} from "@contentful/rich-text-types";
import Link from './elements/Link';
import StringPlaceholderService from "../services/StringPlaceholderService";

const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText id="rich-text" {...props} text={children} />;
const ParagraphStyle = ({children, ...props}) => <DynamicParagraphText  desktopFontSize='17px' mobileFontSize='14px' {...props} text={children}/>;

const options = {
    renderMark: {
      [MARKS.BOLD]: text => <ParagraphStyle style={{fontWeight:'600'}}>{text}</ParagraphStyle>,
      [BLOCKS.text]: (node, children) => <DynamicParagraphStyle >{children}</DynamicParagraphStyle>,
    },
};

const Content = styled.div`
    padding: 0px 60px;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 60px;
    box-sizing: border-box;
    ${mobile`
        padding: 0 30px;
        margin-top: 20px;
    `}
`;

const titleStyle = {
  marginTop: '3.5rem',
  paddingTop: '3rem',
}

const linkStyle = {
    fontSize: '14px',  
}

class EmailAlreadyInUse extends Component {
  constructor(props) {
    super(props);
    this.state = {
        content: null,
    }
  }
  async componentDidMount() {
    this.loadContent();
  }

  async loadContent() {
    const query = new URLSearchParams(this.props.location.search);
    const email = query.get('email');
    const properties = {
      email,
    };
    const response = await ContentfulService.getEntry(ContentfulIds.EmailAlreadyInUse);
    response.fields.sections[0].fields.description.content.forEach(ct => {
        ct.content.forEach(pr => {
          if(pr.value) {
            pr.value = StringPlaceholderService.replace(pr.value, properties)
          }     
        })
    });
    this.setState({
      content: response.fields
    });
   }

  onLogout = async () => {
    await Api.get("/api/users/logout");
  }

  onLoginClick=async ()=>{
    const {search} = window.location;
    const params = new URLSearchParams(search);
    const code = params.get('code');
    await this.onLogout();
    return await Api.login('low-confidence-login', {
      verificationCode: code,
    });
  }
 
  render() {
    const { content } = this.state;
    if (!content) {
      return <Spinner />
    }
    return (
      <Content>
        <ResponsiveSignupFlowLayout>
          <SignUpFlowFormContent>
            <Title mobileOnly='true' style={titleStyle} >{content.title}</Title>
            <Title desktopOnly='true'>{content.title}</Title>
            <RichText text={content.sections[0].fields.description} options={options} />
            <Link style={linkStyle} onClick={this.onLoginClick}  extratopspace="2rem" text={content.mobileShortDescription}  />
          </SignUpFlowFormContent>
          <SignUpFlowExtraContent />
        </ResponsiveSignupFlowLayout>
      </Content>
    );
  } 
}

export default withPageTitle(EmailAlreadyInUse, "Email Already In Use");