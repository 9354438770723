import React from 'react';
import ProductFeatureContent from './ProductFeatureContent';
import styled from 'styled-components';
import { colors, mobile } from '../../styles/common';
import ReactMarkdown from 'react-markdown';

const ProductFeatureSection = styled.div`
    background: #003087;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
`;

const ProductFeatureContentSection = styled.div`
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    ${mobile`
        flex-direction: column;
    `}
`;

const SourceSection = styled.div`
    color: ${colors.pureWhite};
    text-align: center;
    font-size: 12px;
    ${mobile`	
        font-size: 9px;
    `}
    p {
        margin: 0;
    }
`;

const ProductFeature = ({ fields , ...props }) => {
    const { sections } = fields;
    return (
        <ProductFeatureSection>
            <ProductFeatureContentSection>
                {
                    sections.map((section, index) =>
                        <ProductFeatureContent key={section.sys.id} logo={section.fields.image.fields.file.url} text={section.fields.title} lineOne={section.fields.lineOne} lineTwo={section.fields.lineTwo} index={index} sectionLength={sections.length} {...props}/>
                    )
                }
            </ProductFeatureContentSection>
            <SourceSection>
                <ReactMarkdown source={fields.footerText} />
            </SourceSection>
        </ProductFeatureSection>
    );
};

export default ProductFeature;