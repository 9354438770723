import React from 'react';
import SubmitButton from './elements/SubmitButton';
import { ContentfulService } from '../configuration/ApplicationContext';
import ContentfulIds from '../configuration/ContentfulIds';
import ResponsiveSignupFlowLayout, { SignUpFlowFormContent, SignUpFlowExtraContent } from './layout/ResponsiveSignupFlowLayout';
import Content from './layout/Content';
import Summary from './summary/Summary';
import moment from 'moment';
import Card from './elements/Card';
import Section from './elements/Section';
import DataContext from './contexts/DataContext';
import DynamicParagraphText from './elements/DynamicParagraphText';
import Title from './elements/Title';
import Spinner from './elements/Spinner';
import withPageTitle from "./layout/withPageTitle";
import { createSummaryItems } from './summary/SummaryItems';
import BaseComponent from './BaseComponent';
import { ANNUALLY, FREE_UPPER_CASE } from './common/Constants';
import { AAA_SUF_PAID_START_MONITORING, AAA_SUF_PAYMENT_SUCCESSFUL, SETUP_ID_CHAMPION_CLICKED, trackEvent } from '../services/SegmentService';
import RichText from './elements/RichText';
import StringPlaceholderService from '../services/StringPlaceholderService';
import { removeLeadingZerosForSingleDigitDate } from './common/Helpers';
import WelcomeSteps from './elements/WelcomeSteps';
import { setupMonitoringSteps } from './common/Steps';
import ReactMarkdown from 'react-markdown';
import { BLOCKS } from '@contentful/rich-text-types';

const DynamicParagraphStyle = ({ children, ...props }) => <DynamicParagraphText id="rich-text" {...props} text={children} />;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <DynamicParagraphStyle desktopFontSize="16px" mobileFontSize="14px">{children}</DynamicParagraphStyle>
    }
};
class SubscriptionCompletePage extends BaseComponent {
    static contextType = DataContext;

    async componentDidMount() {
        window.history.pushState(null, null, this.props.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        };
        this.checkUserStateAndRedirect(true);
        await this.context.refreshContext();
        this.trackMergedAAASUFSegmentEvent();
        this.retrieveProductAndLoadContent(this.context.userDetails.subscription);
        sessionStorage.removeItem('isLoginWithAAA');
    }

    trackMergedAAASUFSegmentEvent = async () => {
        if (this.context.isLoggedIn) {
            const params = new URLSearchParams(this.props.location.search);
            const mergedAAASUF = params.get('merged-aaa-suf');
            if (mergedAAASUF) {
                const { product } = this.context || {};
                trackEvent(AAA_SUF_PAYMENT_SUCCESSFUL, {
                    memberId: this.context.userDetails.userId,
                    eventLabel: `${product.name}-${product.paymentMode === ANNUALLY ? 'Annual' : product.paymentMode}`
                });
            }
        }
    }
    retrieveProductAndLoadContent = async (subscription) => {
        const properties = {
            freeTrialEndDate: removeLeadingZerosForSingleDigitDate(moment(subscription?.freeTrialEndDate)?.format('MM/DD/YYYY'))
        }
        const response = await ContentfulService.getEntry(subscription?.productType !== FREE_UPPER_CASE ? ContentfulIds.PaidSubscriptionCompletionPage : ContentfulIds.FreeSubscriptionCompletionPage);
        subscription?.productType !== FREE_UPPER_CASE && response.fields.freeTrialMessage.content.forEach(ct => {
            ct.content.forEach(pr => {
                if (pr.value) {
                    pr.value = StringPlaceholderService.replace(pr.value, properties)
                }
            })
        });
        this.setState({ content: response.fields });
    }

    setUpIdChampion = () => {
        const params = new URLSearchParams(this.props.location.search);
        const mergedAAASUF = params.get('merged-aaa-suf');
        if (mergedAAASUF) {
            trackEvent(AAA_SUF_PAID_START_MONITORING, {
                memberId: this.context.userDetails.userId
            });
        } else {
            trackEvent(SETUP_ID_CHAMPION_CLICKED, {
                memberId: this.context.userDetails.userId,
                tierSelected: this.context.product?.name,
                subcriptionOption: this.context.product?.paymentMode
            });
        }
        window.location.href = process.env.REACT_APP_EXPERIAN_SSO_URL;
        this.context.removePromoCodeData();
    }

    render() {
        const { content } = this.state || {};
        let { product } = this.context || {};
        const isFreeTrialUser = this.context.userDetails?.subscription?.freeTrialEndDate != null;
        if (!content) {
            return <Spinner />;
        }
        return (
            <Content>
                <ResponsiveSignupFlowLayout>
                    <SignUpFlowFormContent
                        style={{ paddingBottom: '30px' }}>
                        <Title>
                            {content.title}
                        </Title>
                        <DynamicParagraphText
                            desktopFontSize='16px'
                            mobileFontSize='14px'
                            text={content.message}
                        />
                        {
                            isFreeTrialUser &&
                            <RichText
                                text={content.freeTrialMessage}
                                options={options}
                            />
                        }
                        {
                            product &&
                            product.name !== 'Free' &&
                            <Section
                                mobileOnly='true'
                                text={
                                    <Summary
                                        product={product}
                                        title={'Identity Champion Plan'}
                                        content={content}
                                        flags={{ isSubscriptionComplete: true, showPaidInvoiceAmount: !isFreeTrialUser }}
                                        items={createSummaryItems(product, this.context, { isSubscriptionComplete: true, showPromoFromSubscription: true, showPaidInvoiceAmount: true })}
                                    />
                                }
                            />
                        }
                        <DynamicParagraphText
                            style={{ fontWeight: 'bold', marginBottom: '2rem' }}
                            desktopFontSize='16px'
                            mobileFontSize='14px'
                            text={content.subText}
                        />
                        <WelcomeSteps
                            steps={setupMonitoringSteps()}
                        />
                        <SubmitButton
                            mobileOnly="true"
                            style={{ margin: '1rem 0rem' }}
                            text={content.mobileButtonText}
                            onClick={this.setUpIdChampion}
                        />
                        <SubmitButton
                            desktopOnly="true"
                            style={{ marginTop: '2rem' }}
                            text={content.buttonText}
                            onClick={this.setUpIdChampion}
                        />
                        <ReactMarkdown
                            source={content.legalText}
                            className="paragraph"
                        />
                    </SignUpFlowFormContent>
                    <SignUpFlowExtraContent>
                        {
                            product && product.name !== 'Free' && <Card>
                                <Summary
                                    product={product}
                                    title={'Identity Champion Plan'}
                                    content={content}
                                    flags={
                                        {
                                            isSubscriptionComplete: true,
                                            showPaidInvoiceAmount: !isFreeTrialUser
                                        }
                                    }
                                    items={
                                        createSummaryItems(
                                            product,
                                            this.context,
                                            {
                                                isSubscriptionComplete: true,
                                                showPromoFromSubscription: true,
                                                showPaidInvoiceAmount: true
                                            }
                                        )
                                    }
                                />
                            </Card>
                        }
                    </SignUpFlowExtraContent>
                </ResponsiveSignupFlowLayout>
            </Content>
        );
    }
}

export default withPageTitle(SubscriptionCompletePage, "");

