import React, { useContext } from 'react';
import CardWithTitle from '../../elements/CardWithTitle';
import Link from '../../elements/Link';
import moment from 'moment';
import InfoText from '../../elements/InfoText';
import { colors } from '../../../styles/common';
import { formatPhoneNumber, removeLeadingZerosForSingleDigitDate } from '../../common/Helpers';
import AdminContext from '../../contexts/AdminContext';
import { useHistory } from 'react-router-dom';
import AddressDisplayComponent from '../../elements/AddressDisplayComponent';
import { AdminNotificationTypes } from '../../notifications/AdminNotifications';

const marginBottom = { marginBottom: '1rem' };
const marginTop = { marginTop: '1rem' };
const MemberPersonalDetails = ({notifications} ) => {
    const context = useContext(AdminContext);
    const history = useHistory();
    const {personalInformation, loginEmail} = context.memberDetails;
    const dob = personalInformation?.dob ? removeLeadingZerosForSingleDigitDate(moment(personalInformation.dob).format('MM/DD/YYYY')) : '';
    const isEmailVerified = notifications.find(notification => notification.notificationType === AdminNotificationTypes.VERIFY_EMAIL_ADDRESS)
    return (
        <>
            <CardWithTitle firstGrow={true} title='Sign In & Personal Details' callToAction={<Link style={{ color: colors.blue }} onClick={(membershipId) => history.push('/admin/edit-login-and-personal-details')} text='Edit' />}>
                <InfoText>{personalInformation.displayName}</InfoText>
                <>  
                    { isEmailVerified &&
                        <InfoText style={{wordBreak: 'break-word', color: colors.darkRed, fontWeight: 500, ...marginBottom } }>{ `${isEmailVerified?.callToAction?.email} (unconfirmed)`}</InfoText>
                    }
                    { !isEmailVerified &&  
                        (
                            <> 
                                <InfoText style={{wordBreak: 'break-all', ...marginBottom}}>sign in email: {loginEmail || 'N/A'}</InfoText>
                                <InfoText style={{wordBreak: 'break-all', ...marginBottom}}>comms email: {personalInformation.email || 'N/A'}</InfoText>
                            </>
                         )
                    }
                </>
                <AddressDisplayComponent address={personalInformation.homeAddress} />
                <InfoText >{formatPhoneNumber(personalInformation.phoneNumber)}</InfoText>
                {!personalInformation.billingAddressSameAsHomeAddress &&
                    <>
                        <InfoText style={marginTop}>Billing Address:</InfoText>
                        <AddressDisplayComponent address={personalInformation.billingAddress} />
                    </>
                }
                <InfoText style={marginTop}>Date of Birth: { dob }</InfoText>
            </CardWithTitle>
        </>
    )
};

export default MemberPersonalDetails;