import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { colors, desktop } from '../../styles/common';
import Span from '../elements/Span'

  const CloseIcon = styled.div`
  ${desktop`
    display:none;
`}
    display: flex;
    position: absolute;
    top: 3px;
    color:${props => props.textcolor ? props.textcolor :colors.greyborder};
    right: 9px;
    font-weight:500;
    font-size:${props => props.fontStyle ? '10px' : '13px'};
`;
const ToolTip = (props) => {
    const {id, content, textcolor, backgroundcolor, fontStyle} = props;
    return (
        <ReactTooltip
            id={id}
            className="tooltip"
            effect='solid'
            place={'top'}
            border={true}
            textColor={textcolor}
            backgroundColor={backgroundcolor}
            type={'light'}>
                <CloseIcon {...props}>X</CloseIcon>
                <Span mobileStyles={fontStyle} text={content}/> 
        </ReactTooltip>
    )
};

export default ToolTip;

