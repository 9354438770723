import React, {Component} from 'react';
import {ContentfulService} from '../configuration/ApplicationContext';
import Content from './layout/Content';
import ResponsiveLandingPageLayout from './layout/ResponsiveLandingPageLayout';
import Spinner from './elements/Spinner';
import ContentfulIds from '../configuration/ContentfulIds';
import Title from './elements/Title';
import Link from './elements/Link'; 
import RichText from './elements/RichText';
import {colors, mobile ,desktop} from '../styles/common';
import styled from 'styled-components';
import withPageTitle from "./layout/withPageTitle";
import Breadcrumbs from './common/Breadcrumbs';
import SubmitButton from './elements/SubmitButton';
import { MARKS } from "@contentful/rich-text-types";
import DataContext from './contexts/DataContext';
import StringPlaceholderService from '../services/StringPlaceholderService';
import { removeLeadingZerosForSingleDigitDate, formatCurrency, absoluteValue, formatDateMMDDYYYY} from './common/Helpers';

const mobileTitleStyle = {
    paddingTop: '40px'
}

const linkStyle = {
    color: colors.blue,
    display: 'inline' 
}

const breadCrumbRoutes = [
    {
        to: '/auto-renewal-terms', label: 'Auto-Renewal Terms & Cancellation Information'
    }
];

const SpanStyle = ({ children, ...props }) => <Link to='/contact-us' style={linkStyle} text={children} />;
const options = {
    renderMark: {
        [MARKS.CODE]: text => <SpanStyle>{text}</SpanStyle>
    }
};

const AutoRenewalTermsPageContent = styled.div`
    ${desktop`
        width: 50%;
        font-size: 17px;
    `}
    ${mobile`
        font-size: 14px;
    `}
`;

class AutoRenewalTermsPage extends Component {
    static contextType = DataContext;
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.loadContent();
    }

    async loadContent() {
        const response = await ContentfulService.getEntry(ContentfulIds.AutoRenewalTerms);
        this.setState({
            content: response.fields
        });
        this.loadDynamicContent(response.fields);
    }

    loadDynamicContent(content) {
        const { userDetails } = this.context || {};
        const subscription = userDetails?.subscription;
        const membershipExpiryDate = userDetails?.membership?.membershipExpiryDate;
        const formattedMembershipDate = formatDateMMDDYYYY(membershipExpiryDate);
        const properties = {
            formattedMembershipDate: removeLeadingZerosForSingleDigitDate(formattedMembershipDate),
            priceWithoutDiscount: formatCurrency(absoluteValue(subscription.basePrice?.total)) || '',
            planType: subscription?.billingFrequency === 'ANNUALLY' ? 'year' : 'month'
        };
        content.description.content.forEach(ct => {
            ct.content.forEach(pr => {
                if (pr.value) {
                    pr.value = StringPlaceholderService.replace(pr.value, properties)
                }
            })
        });
        content.subDescription.content.forEach(ct => {
            ct.content.forEach(pr => {
                if (pr.value) {
                    pr.value = StringPlaceholderService.replace(pr.value, properties)
                }
            })
        });
        this.setState({
            content,
            membershipExpiryDate 
        });
    }
    
    render() {
        const {content} = this.state;
        if (!content) {
            return <Spinner />
        }
        const autoRenewalTermsDescription = this.state.membershipExpiryDate ? content.description : content.subDescription;
        return (
            <>
                <Breadcrumbs breadCrumbRoutes={breadCrumbRoutes}></Breadcrumbs>
                <Content style={{ marginTop:'30px', marginBottom: '4rem' }} >
                    <ResponsiveLandingPageLayout>
                        <Title mobileStyles={mobileTitleStyle} >{content.title}</Title>
                            <AutoRenewalTermsPageContent>
                                <RichText text={autoRenewalTermsDescription} options={options}/>
                                <SubmitButton style={{marginTop: '2rem'}} mobileStyles={{ minWidth: '290px',width:'100%'}} text={content.footerDescription} onClick={() => this.props.history.push('/my-account')} />
                            </AutoRenewalTermsPageContent>
                    </ResponsiveLandingPageLayout>
                </Content>
            </>    
        );
    }
}

export default withPageTitle(AutoRenewalTermsPage, 'Auto Renewal Terms & Cancellation Information');