
const validate = (values) => {
    Object.keys(values).forEach(key => {
        if(values[key]?.trim) {
            values[key] = values[key]?.trim();
        }
    });
    let errors = {};
   
    // Billing address validation
    if (values.hasOwnProperty('billingAddress') && !values.billingAddress) {
        errors.billingAddress = 'Please enter Billing Address.';
    }
    return errors;
};

export default validate;