import React from 'react';
import ContentfulIds from '../../configuration/ContentfulIds';
import Content from '../layout/Content';
import Spinner from '../elements/Spinner';
import FormError from '../elements/FormError';
import BaseAdminProductOffering from './BaseAdminProductOffering';
import AdminContext from '../contexts/AdminContext';
import Breadcrumbs from '../common/Breadcrumbs';
import { AdminApi, segmentAnalytics } from '../../configuration/ApplicationContext';
import RichText from '../elements/RichText';
import Title from '../elements/Title';
import ProductChart from '../v1/product-chart/customer/ProductChart';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import DynamicParagraphText from '../elements/DynamicParagraphText';
import { colors } from '../../styles/common';
import { AAA_USER, BASIC, FREE } from '../common/Constants';

const styles = {
    title: { color: `${colors.titleText}`, margin: 0, fontWeight: 'normal', fontSize: '32px', lineHeight: '43x', paddingBottom: '2rem' },
};
const ParagraphStyle = ({ children, ...props }) => <DynamicParagraphText
    {...props}
    text={children}
    style={{ textAlign: 'justify', margin: 0, color: colors.verylightGrey, lineHeight: '20px', paddingTop: '1rem' }}
/>;
var options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) =>
            <ParagraphStyle
                anchor={true}
                desktopOnly='true'
                desktopFontSize='12px'
                mobileFontSize='12px'
            >
                {children}
            </ParagraphStyle>,
        [INLINES.HYPERLINK]: (node, next) => {
            return <a href={node.data.uri}>{node.content[0].value}</a>;
        }
    },
};
export default class AdminNonMemberProductOfferingPage extends BaseAdminProductOffering {
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            apiErrors: [],
            products: [],
            selectedProduct: null,
            productPageURL: '/admin/non-member/product',
            checkoutPageURL: '/admin/non-member/product/checkout',
            submitted: false,
            subscriptionAction: 'CREATE'
        }
    }

    componentDidMount() {
        this.setBreadCrumb();
        this.loadPlans(ContentfulIds.Admin.SignUpFlow.ProductOfferingPage);
        this.loadProductChartContent(ContentfulIds.Admin.ChooseYourPlanPage);
    }

    onSubmitProduct = async (ratePlanId) => {
        this.setState({
            isSubmitDisabled: true
        });
        const { personalInformation } = this.context.memberDetails || {};
        if (ratePlanId === BASIC.toLowerCase()) {
            let resp = await AdminApi.post(`/api/users/${this.context.searchResult.userId}/subscription`, { ratePlanId });
            if (!resp) {
                return;
            }
            if (resp.validationErrors) {
                const apiErrors = resp.validationErrors.map(apiError => {
                    return apiError.message
                });
                this.setState({ apiErrors, submitted: true });
            } else {
                segmentAnalytics.track('Sales-Tool-New-Signup-Select-Basic-Plan', {
                    oneLoginID: this.context.getLoginId(),
                    memberId: this.context.memberDetails.userId,
                    firstName: personalInformation.firstName,
                    lastName: personalInformation.lastName,
                    email: personalInformation.email,
                    homeAddress: personalInformation.homeAddress,
                    city: personalInformation.homeAddress.city,
                    state: personalInformation.homeAddress.state,
                    zip: personalInformation.homeAddress.zipCode,
                    phoneNumber: personalInformation.phoneNumber,
                    dob: personalInformation.dob,
                    aaaMemberNumber: this.context.memberDetails.membershipId,
                    tierSelected: FREE,
                    MemberType: AAA_USER
                });
                this.props.history.push('/admin/non-member/subscription-complete');
            }
        } else {
            const { selectedProduct } = this.state || {};
            segmentAnalytics.track(`Sales-Tool-New-Signup-Select-${selectedProduct.displayName}-Plan`, {
                oneLoginID: this.context.getLoginId(),
                memberId: this.context.memberDetails.userId,
                firstName: personalInformation.firstName,
                lastName: personalInformation.lastName,
                email: personalInformation.email,
                homeAddress: personalInformation.homeAddress,
                city: personalInformation.homeAddress.city,
                state: personalInformation.homeAddress.state,
                zip: personalInformation.homeAddress.zipCode,
                phoneNumber: personalInformation.phoneNumber,
                dob: personalInformation.dob,
                aaaMemberNumber: this.context.memberDetails.membershipId,
                tierSelected: selectedProduct.displayName,
                subscriptionOption: `${selectedProduct.paymentMode === 'Annually' ? 'Annual' : selectedProduct.paymentMode}`
            });
            this.props.history.push({ pathname: this.state.checkoutPageURL + `/${ratePlanId}` });
        }
    }

    setBreadCrumb = () => {
        const breadCrumbRoutes = [
            {
                to: '/admin/search/noresults', label: 'New AAA Digital + IDC Signup'
            },
            {
                to: '/admin/personal-info-non-member', label: '1: Personal Details'
            },
            {
                to: `${this.state.productPageURL}/${this.props.match.params.id}`, label: '2: Options'
            }
        ];
        this.setState({ breadCrumbRoutes });
    }
    onProductSelectToCreateSubscription = (productName, paymentMode) => {
        const { products } = this.state || {};
        if (!products) {
            return;
        }
        const selectedProduct = products.find(product =>
            product.displayName.toLowerCase() === productName.toLowerCase()
            && product.paymentMode.toLowerCase() === paymentMode.toLowerCase()
        );
        this.setState({ selectedProduct });
        const productId = productName.toLowerCase() === BASIC.toLowerCase() ? `${BASIC.toLowerCase()}` : selectedProduct?.id;
        if (!productId) {
            return;
        }
        this.props.history.push(`/admin/non-member/product/${productId}`);
        this.onSubmitProduct(productId);
    }
    render() {
        const {
            products,
            submitted,
            apiErrors,
            breadCrumbRoutes,
            productChartContent,
            isSubmitDisabled
        } = this.state;
        if (products.length == 0 || !productChartContent || isSubmitDisabled) {
            return <Spinner />;
        }
        return (
            <Content
                desktopStyles={{ marginTop: 0 }}>
                <Breadcrumbs
                    isCustomerFacing={false}
                    breadCrumbRoutes={breadCrumbRoutes}
                />
                <FormError
                    showErrors={submitted}
                    errors={apiErrors}
                />
                <Title
                    style={styles.title}>
                    {productChartContent.title}
                </Title>
                <ProductChart
                    backgroundcolor={colors.pureWhite}
                    showTitle={false}
                    showDescription={false}
                    openComparePlansLinkInNewTab={true}
                    customStyle={true}
                    productchartsectionpadding='0px'
                    fields={productChartContent}
                    choosePlan={true}
                    hideToggleSection={true}
                    onProductSelectToCreateSubscription={this.onProductSelectToCreateSubscription}
                />
                <RichText
                    text={productChartContent.joinAaaText}
                    options={options}
                />
            </Content>
        );
    }
}
