import React from 'react';
import styled, {css} from 'styled-components';
import {mobile, desktop} from '../../styles/common';

const MyAccountLayoutContainer = styled.div`
    display: flex;
    flex-flow: wrap;
    ${desktop`
        ${props => props.alignItems === 'flex-start' && css`
            align-items: flex-start;
        `}
    `}
    ${mobile`
        flex-direction: column;
    `}
    margin-bottom: 4rem;
`;

const ResponsiveMyAccountLayout  = ({ children, ...props}) => {
    return (
        <MyAccountLayoutContainer {...props}>
            {children}
        </MyAccountLayoutContainer>
    )
};

export default ResponsiveMyAccountLayout;
