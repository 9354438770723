import React from 'react';
import { colors } from '../../styles/common';
import { UNKNOWN } from '../common/Constants';
import { convertStringToDateAndFormat, formatDates, formatPhoneNumber, sentenceCase, appendCommaDelimiterToArray, getSocialMediaNamesMap, convertFirstWordToUpperCase } from '../common/Helpers';
import CardLineItem from '../elements/CardLineItem';
import CardWithTitle from '../elements/CardWithTitle';

const styles = {
    key: { width: '30%', fontWeight: 'bold', textAlign: 'right', marginRight: '2%' },
    value: { width: '68%' },
    cardLine: { marginBottom: 0 },
    unknown: { color: colors.darkRed, fontStyle: 'italic', width: '68%', fontWeight: 500 },
    keyWithWidth40: { width: '40%', fontWeight: 'bold', textAlign: 'right', marginRight: '2%' },
    valueWithWidth58: { width: '58%' },
    unknownWithWidth58: { color: colors.darkRed, fontStyle: 'italic', width: '58%', fontWeight: 500 },
};
const ExperianCyberAgentAlertDetail = ({ subscriberName, data }) => {
    const details = data.details;
    const { sexOffenderRecord } = details;
    const sexOffenderRecordAddress = sexOffenderRecord?.address2 ?
        sexOffenderRecord?.address1 + " " + sexOffenderRecord?.address2
        : sexOffenderRecord?.address1;
    const setTitle = (data) => {
        switch (data.type) {
            case 'Social Media Monitoring':
                return `${data.type}: ${getSocialMediaNamesMap(data.details.socialMedia)}`;
            default: return data.type;
        }
    }
    const spaceAfterSubscriber = Object.keys(details).length > 3 || (sexOffenderRecord && Object.keys(sexOffenderRecord)?.length) > 3

    return (
        <CardWithTitle
            title={setTitle(data)}>
            <>
                {
                    subscriberName &&
                    <>
                        <CardLineItem
                            itemKey='Subscriber:'
                            itemValue={subscriberName}
                            itemKeyStyle={
                                data.type.includes('Financial')
                                    ? styles.keyWithWidth40
                                    : styles.key
                            }
                            itemStyle={
                                data.type.includes('Financial')
                                    ? styles.valueWithWidth58
                                    : styles.value
                            }
                            style={styles.cardLine}
                        />
                        {
                            spaceAfterSubscriber === true && <CardLineItem
                            />
                        }

                    </>
                }
                {
                    details.firstName &&
                    <CardLineItem
                        itemKey='First name:'
                        itemValue={sentenceCase(details.firstName)}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            details.firstName === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.lastName &&
                    <CardLineItem
                        itemKey='Last name:'
                        itemValue={sentenceCase(details.lastName)}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            details.lastName === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.address &&
                    <CardLineItem
                        itemKey='Address:'
                        itemValue={details.address}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            details.address === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.city &&
                    <CardLineItem
                        itemKey='City:'
                        itemValue={sentenceCase(details.city)}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            details.city === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.state &&
                    <CardLineItem
                        itemKey='State:'
                        itemValue={details.state}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            details.state === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.zip &&
                    <CardLineItem
                        itemKey='Postal Code:'
                        itemValue={details.zip}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            details.zip === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.moveDate &&
                    <CardLineItem
                        itemKey='Move Date:'
                        itemValue={
                            details.moveDate === UNKNOWN
                                ? UNKNOWN
                                : formatDates(details.moveDate, 'YYYYMM', 'M/YYYY')}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            details.moveDate === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.moveType &&
                    <CardLineItem
                        itemKey='Move Type:'
                        itemValue={details.moveType}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            details.moveType === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.matchFlag &&
                    <CardLineItem
                        itemKey='Move Flag:'
                        itemValue={details.matchFlag}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            details.matchFlag === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.dateFound &&
                    <CardLineItem
                        itemKey='Date Found:'
                        itemValue={
                            details.dateFound === UNKNOWN
                                ? UNKNOWN
                                : convertStringToDateAndFormat(details.dateFound)
                        }
                        itemKeyStyle={styles.key}
                        itemStyle={
                            details.dateFound === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.aliasFirstName &&
                    <CardLineItem
                        itemKey='Alias First Name:'
                        itemValue={sentenceCase(details.aliasFirstName)}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            details.aliasFirstName === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.aliasLastName &&
                    <CardLineItem
                        itemKey='Alias Last Name:'
                        itemValue={sentenceCase(details.aliasLastName)}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            details.aliasLastName === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.source && data.type !== 'Social Media Monitoring' &&
                    <CardLineItem
                        itemKey='Source:'
                        itemValue={sentenceCase(details.source)}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            details.source === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.reason &&
                    <CardLineItem
                        itemKey='Reason:'
                        itemValue={sentenceCase(details.reason)}
                        itemKeyStyle={styles.keyWithWidth40}
                        itemStyle={
                            details.reason === UNKNOWN
                                ? styles.unknownWithWidth58
                                : styles.valueWithWidth58
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.financialAccountInstitutionName &&
                    <CardLineItem
                        itemKey='Financial Institution Name:'
                        itemValue={sentenceCase(details.financialAccountInstitutionName)}
                        itemKeyStyle={styles.keyWithWidth40}
                        itemStyle={
                            details.financialAccountInstitutionName === UNKNOWN
                                ? styles.unknownWithWidth58
                                : styles.valueWithWidth58
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.financialAccountInstitutionPhone &&
                    <CardLineItem
                        itemKey='Financial Institution Phone Number:'
                        itemValue={formatPhoneNumber(details.financialAccountInstitutionPhone)}
                        itemKeyStyle={styles.keyWithWidth40}
                        itemStyle={
                            details.financialAccountInstitutionPhone === UNKNOWN
                                ? styles.unknownWithWidth58
                                : styles.valueWithWidth58
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.inquiryDate &&
                    <CardLineItem
                        itemKey='Inquiry Date:'
                        itemValue={
                            details.inquiryDate === UNKNOWN
                                ? UNKNOWN
                                : convertStringToDateAndFormat(details.inquiryDate)
                        }
                        itemKeyStyle={styles.keyWithWidth40}
                        itemStyle={
                            details.inquiryDate === UNKNOWN
                                ? styles.unknownWithWidth58
                                : styles.valueWithWidth58
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.accountOpenedDate &&
                    <CardLineItem
                        itemKey='Accounts Opened Date:'
                        itemValue={
                            details.accountOpenedDate === UNKNOWN
                                ? UNKNOWN
                                : convertStringToDateAndFormat(details.accountOpenedDate)
                        }
                        itemKeyStyle={styles.keyWithWidth40}
                        itemStyle={
                            details.accountOpenedDate === UNKNOWN
                                ? styles.unknownWithWidth58
                                : styles.valueWithWidth58
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.notificationId &&
                    <CardLineItem
                        itemKey='Notification ID:'
                        itemValue={details.notificationId}
                        itemKeyStyle={styles.keyWithWidth40}
                        itemStyle={
                            details.notificationId === UNKNOWN
                                ? styles.unknownWithWidth58
                                : styles.valueWithWidth58
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.datePosted &&
                    <CardLineItem
                        itemKey='Date Posted:'
                        itemValue={details.datePosted === UNKNOWN
                            ? UNKNOWN
                            : formatDates(details.datePosted, 'YYYYMMDD', 'MM/DD/YYYY')}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            details.datePosted === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    details.risks &&
                    <CardLineItem
                        itemKey='Risks:'
                        itemValue={appendCommaDelimiterToArray(details.risks)}
                        itemKeyStyle={styles.key}
                        itemStyle={styles.value}
                        style={styles.cardLine}
                    />
                }
                {
                    sexOffenderRecord?.offenderName &&
                    <CardLineItem
                        itemKey='Offender name:'
                        itemValue={sentenceCase(sexOffenderRecord?.offenderName)}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            sexOffenderRecord?.offenderName === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    sexOffenderRecordAddress &&
                    <CardLineItem
                        itemKey='Addess:'
                        itemValue={`${sentenceCase(sexOffenderRecordAddress)}`}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            sexOffenderRecordAddress === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    sexOffenderRecord?.city &&
                    <CardLineItem
                        itemKey='City:'
                        itemValue={sentenceCase(sexOffenderRecord.city)}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            sexOffenderRecord.city === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    sexOffenderRecord?.state &&
                    <CardLineItem
                        itemKey='State:'
                        itemValue={sexOffenderRecord.state}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            sexOffenderRecord.state === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    sexOffenderRecord?.zip &&
                    <>
                        <CardLineItem
                            itemKey='Postal Code:'
                            itemValue={sentenceCase(sexOffenderRecord.zip)}
                            itemKeyStyle={styles.key}
                            itemStyle={
                                sexOffenderRecord.zip === UNKNOWN
                                    ? styles.unknown
                                    : styles.value
                            }
                            style={styles.cardLine}
                        />
                        <CardLineItem
                        />
                    </>
                }
                {
                    sexOffenderRecord?.sex &&
                    <CardLineItem
                        itemKey='Sex:'
                        itemValue={sentenceCase(sexOffenderRecord.sex)}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            sexOffenderRecord.sex === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    sexOffenderRecord?.race &&
                    <CardLineItem
                        itemKey='Race:'
                        itemValue={sentenceCase(sexOffenderRecord.race)}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            sexOffenderRecord.race === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    sexOffenderRecord?.height &&
                    <CardLineItem
                        itemKey='Height:'
                        itemValue={sexOffenderRecord.height.substring(0, 2) + " " + sexOffenderRecord.height.substring(2)}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            sexOffenderRecord.height === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    sexOffenderRecord?.weight &&
                    <>
                        <CardLineItem
                            itemKey='Weight:'
                            itemValue={`${sentenceCase(sexOffenderRecord.weight)} lbs`}
                            itemKeyStyle={styles.key}
                            itemStyle={
                                sexOffenderRecord.weight === UNKNOWN
                                    ? styles.unknown
                                    : styles.value
                            }
                            style={styles.cardLine}
                        />
                        <CardLineItem
                        />
                    </>
                }
                {
                    sexOffenderRecord?.eye &&
                    <CardLineItem
                        itemKey='Eye Color:'
                        itemValue={sentenceCase(sexOffenderRecord.eye)}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            sexOffenderRecord.eye === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    sexOffenderRecord?.hair &&
                    <CardLineItem
                        itemKey='Hair Color:'
                        itemValue={sentenceCase(sexOffenderRecord.hair)}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            sexOffenderRecord.hair === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    sexOffenderRecord?.offenseDesc &&
                    <CardLineItem
                        itemKey='Offense:'
                        itemValue={sentenceCase(sexOffenderRecord.offenseDesc)}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            sexOffenderRecord.offenseDesc === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    sexOffenderRecord?.offenseDate &&
                    <CardLineItem
                        itemKey='Offense Date:'
                        itemValue={
                            sexOffenderRecord.offenseDate === UNKNOWN
                                ? UNKNOWN
                                : formatDates(sexOffenderRecord.offenseDate, 'YYYYMMDD', 'MM/DD/YYYY')
                        }
                        itemKeyStyle={styles.key}
                        itemStyle={
                            sexOffenderRecord.offenseDate === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
                {
                    sexOffenderRecord?.convictionDate &&
                    <>
                        <CardLineItem
                            itemKey='Conviction Date:'
                            itemValue={sexOffenderRecord.convictionDate === UNKNOWN
                                ? UNKNOWN
                                : formatDates(sexOffenderRecord.convictionDate, 'YYYYMMDD', 'MM/DD/YYYY')
                            }
                            itemKeyStyle={styles.key}
                            itemStyle={
                                sexOffenderRecord.convictionDate === UNKNOWN
                                    ? styles.unknown
                                    : styles.value
                            }
                            style={styles.cardLine}
                        />
                        <CardLineItem
                        />
                    </>
                }
                {
                    sexOffenderRecord?.sourceName &&
                    <CardLineItem
                        itemKey='Source Name:'
                        itemValue={convertFirstWordToUpperCase(sentenceCase(sexOffenderRecord.sourceName))}
                        itemKeyStyle={styles.key}
                        itemStyle={
                            sexOffenderRecord.sourceName === UNKNOWN
                                ? styles.unknown
                                : styles.value
                        }
                        style={styles.cardLine}
                    />
                }
            </>
        </CardWithTitle>
    );
};

export default ExperianCyberAgentAlertDetail;